/* eslint-disable consistent-return */
import Vue from 'vue';
import {
  mapActions,
  mapMutations,
  mapGetters,
} from '@/store/utils/record-mapper';
import { passive } from '@/utils/helpers/promise';

/**
 * A placeholder is a client-side record that doesn't exist on the server
 * yet. The general concept is that the user can work with it locally before
 * saving it, or they can choose to discard it. The persist action requires
 * a name value.
 *
 * Note: the entity record must include `busy` in initial state.
 *
 * @param {Function} priorToPersist - save off state prior to persist
 * @param {Function} persistApi - server-side persist
 * @param {Function} beforeSaveId - when the server-side persist returns
 * @param {Function} afterSaveId - after the IDs have been swapped
 * @param {Function} beforeClear - before deleting a placeholder
 * @param {Function} createPlaceholder - returns new placeholder object
 * @return {Object} module components for placeholder functionality
 */
export default ({
  multipleMutation = false,
  priorToPersist = () => null,
  persistApi,
  beforeSaveId = () => {},
  afterSaveId = () => {},
  beforeClear = () => {},
  createPlaceholder,
}) => {
  // Used to generate unique placeholder IDs
  let placeholderIdCounter = 0;

  return {
    getters: {
      ...mapGetters({
        // Used for create undo/redo
        persisted: ({ status, id }) => id > 0 && status !== 'deleted',
      }),
    },
    mutations: {
      persistPlaceholder: (state, { id, payload: newId }) => {
        const rec = state.records[id];
        Vue.delete(state.records, id);
        rec.id = newId;
        Vue.set(state.records, newId, rec);
      },
      clearPlaceholder: (state, id) => {
        Vue.delete(state.records, id);
      },
      ...mapMutations({
        busy: (state) => {
          state.busy = true;
        },
        notBusy: (state) => {
          state.busy = false;
        },
      }),
    },
    actions: {
      ...mapActions({
        /**
         * The returned promise here is before the
         * entire operation completes, this allows for
         * the new ID to be known in the UI before the
         * reactive data propagates.
         */
        async persistPlaceholder(
          { state, commit, dispatch, id, rootGetters },
          name,
        ) {
          if (!name) {
            Vue.util.warn('New placeholder must have a name');
            commit('clearPlaceholder');
            return;
          }
          const priorState = priorToPersist({ state, rootGetters, id });
          commit('busy');
          commit('changeName', name);
          const promise = passive();
          persistApi({ state, name })
            .then(async ({ headers }) => {
              const newId = Number(headers.id);
              // Important to resolve the action promise immediately when the
              // new ID is ready, so that a component vnode can be updated
              promise.resolve(newId);
              await Vue.nextTick(); // defer to give time for the passive callbacks to run
              beforeSaveId({ state, commit, dispatch, newId });
              commit('persistPlaceholder', newId);
              await afterSaveId({
                state,
                commit,
                dispatch,
                oldId: id,
                priorState,
              });
              commit('notBusy', newId, { recordMap: false });
            })
            .catch((e) => {
              commit('notBusy');
              promise.reject(e);
            });
          return promise;
        },
        clearPlaceholder({ state, commit, dispatch, id, rootGetters }) {
          beforeClear({ state, commit, dispatch, id, rootGetters });
          commit('clearPlaceholder');
        },
      }),
      /**
       * NP - No auto cleanup here, calling code responsible for saving or clearing
       * this placeholder record.
       */
      newPlaceholder({ commit, rootState, rootGetters }, initial) {
        const newEntity = createPlaceholder({
          initial,
          rootState,
          rootGetters,
        });
        if (newEntity) {
          placeholderIdCounter += 1;
          const id = placeholderIdCounter * -1; // negative id for placeholders
          // TODO Remove when all modules using records
          if (multipleMutation) {
            commit('records', [{ ...newEntity, id }]);
          } else {
            commit('record', { ...newEntity, id });
          }
          return id;
        }
      },
    },
  };
};
