import {
  computed,
  inject,
  onScopeDispose,
  provide,
  shallowRef,
  triggerRef,
  unref,
} from 'vue-demi';

const useFullContentAreaSymbol = Symbol('useFullContentArea');

export function provideFullContentArea() {
  const enabledRefs = shallowRef(new Set());
  const enabled = computed(() =>
    Array.from(enabledRefs.value).some(({ value }) => value),
  );

  function registerRef(enabledRef) {
    enabledRefs.value.add(enabledRef);
    triggerRef(enabledRefs);

    onScopeDispose(() => {
      enabledRefs.value.delete(enabledRef);
      triggerRef(enabledRefs);
    });
  }

  provide(useFullContentAreaSymbol, { enabled, registerRef });
}

/**
 * Provides control over the available content area.
 * If `enabled` is `true`, the header and sidebar are hidden.
 *
 * @param {boolean|Ref<boolean>} enable A value or ref indicating
 *   if the full content area should be available for route components.
 * @returns A ref indicating if the full content area is available for route components.
 */
export function useFullContentArea(enable) {
  const { enabled, registerRef } = inject(useFullContentAreaSymbol);
  registerRef(computed(() => Number(unref(enable))));
  return enabled;
}
