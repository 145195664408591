<template>
  <div class="task-description">
    <CollapsePanel
      v-click-outside="onClickOutside"
      :is-open="
        !showCollapseButton || (showCollapseButton && isDescriptionVisible)
      "
      :collapsed-height="`${collapsedHeight}px`"
      @open="onToggleDescription"
    >
      <span
        v-show="!showingTKOEditor"
        ref="mardownContent"
        class="pseudo-textarea h-typo-formatting"
        :class="{
          'pseudo-textarea--disabled': disabled,
          placeholder: !content,
        }"
        v-html="markdownContent"
        @mousedown.left="mouseDown"
        @mouseup.left="mouseUp"
      ></span>
      <MarkdownEditor
        ref="markdownEditor"
        v-show="showingTKOEditor"
        v-model="content"
        :data-identifier="dataIdentifier"
        :showing="showingTKOEditor"
        :auto-focus-at-end="true"
        :placeholder="placeholder"
        @blur="onBlur"
      />
    </CollapsePanel>
    <CollapseButton
      v-if="showCollapseButton"
      :class="{ 'collapse-button--see-more': !isDescriptionVisible }"
      :is-open="isDescriptionVisible"
      @click="onToggleDescription"
    />
  </div>
</template>

<script>
import marked from 'marked';
import { useLocalStorage } from '@vueuse/core';
import MarkdownEditor from '@widgets/MarkdownEditor';
import CollapsePanel from '@widgets/CollapsePanel';
import CollapseButton from '@widgets/CollapsePanel/CollapseButton';

const LOCAL_STORAGE_KEY = 'task-description/isDescriptionVisible';

export default {
  name: 'TaskDescription',
  components: {
    MarkdownEditor,
    CollapsePanel,
    CollapseButton,
  },
  props: {
    value: { type: String, required: true },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    dataIdentifier: { type: String, default: '' },
  },
  setup() {
    const isDescriptionVisible = useLocalStorage(LOCAL_STORAGE_KEY, false);

    function onToggleDescription() {
      isDescriptionVisible.value = !isDescriptionVisible.value;
    }

    return {
      isDescriptionVisible,
      onToggleDescription,
    };
  },
  data() {
    return {
      content: '',
      observer: null,
      intersectingAtTop: false,
      collapsedHeight: 144,
      showCollapseButton: false,
      currentTimeDown: 0,
    };
  },
  computed: {
    markdownContent() {
      return marked(this.content || this.placeholder);
    },
    cssVars() {
      return {
        '--task-desc-editor-height': this.value ? 'auto' : '32px',
      };
    },
    showingTKOEditor: {
      get() {
        return this.isEditing;
      },
      set(newVal) {
        this.$emit('update:isEditing', newVal);
      },
    },
  },
  mounted() {
    this.content = this.value;
    this.checkContentHeight();
  },
  beforeDestroy() {
    if (this.content === this.value) {
      return;
    }
    // defensive: in case user is closing panel directly
    this.$emit('change', this.content);
  },
  methods: {
    mouseDown() {
      this.currentTimeDown = new Date().getTime();
    },
    mouseUp(event) {
      if (this.currentTimeDown < 0) {
        return;
      }
      const currentTimeUp = new Date().getTime();
      if (currentTimeUp - this.currentTimeDown < 200) {
        this.onShowTKOEditor(event);
      }
      this.currentTimeDown = 0;
    },
    async checkContentHeight() {
      await this.$nextTick();

      const markdownContent = this.$refs.mardownContent;
      if (markdownContent) {
        this.showCollapseButton =
          markdownContent.clientHeight > this.collapsedHeight;
      }
    },
    onShowTKOEditor(evt) {
      const panelBodyElement = this.$parent.$refs.panelBody;
      const panelBodyPosition = panelBodyElement
        ? panelBodyElement.scrollTop
        : 0;
      if (this.disabled || evt.target.nodeName === 'A') {
        return;
      }
      this.showingTKOEditor = true;
      this.isDescriptionVisible = true;

      if (!this.showCollapseButton) {
        return;
      }
      setTimeout(() => {
        this.$emit('show-markdown', panelBodyPosition);
      }, 0);
    },
    onClickOutside() {
      this.showingTKOEditor = false;
      this.checkContentHeight();
    },
    onBlur(value) {
      if (this.value === value) {
        return;
      }
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss" scoped>
$hover-bg: #f5f7fa;
$focus-outline: #4461d7;
$placeholder-color: #6d6f80;
$text-color: #0b0e1f;

.task-description {
  position: relative;
  margin-left: -8px;
  margin-right: -8px;

  .collapsed.partial {
    &::after {
      height: 120px;
      opacity: 1;
      background-image: linear-gradient(to top, white, transparent) !important;
      z-index: 50;
    }
  }

  ::v-deep .collapse.partial {
    .collapse__slot {
      margin-bottom: 16px;
    }
  }

  span.pseudo-textarea {
    color: $text-color;
    border: none;
    min-height: 40px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    left: 0;
    right: 0;
    font-size: 14px;
    line-height: 24px;
    cursor: text;
    border: 1px solid transparent;
    pointer-events: all;
    user-select: text;
    &.placeholder {
      color: $placeholder-color;
    }

    &:hover {
      background-color: $hover-bg;
    }

    &--disabled {
      cursor: default;
      user-select: text;
    }
  }

  ::v-deep .w-easy-markdown-editor {
    border-collapse: separate;
    margin-top: 16px;

    .w-easy-markdown-editor__wrapper {
      margin-bottom: 0;
      background-color: $hover-bg;
      border: 1px solid $focus-outline;
      border-radius: 8px;
      border-top: none;
    }

    .editor-toolbar {
      border-left: none;
      border-right: none;
      // border-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-color: $focus-outline;
      background-color: $hover-bg;
      position: sticky;
      top: 0;
      z-index: 999;

      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 10px;
        right: -3px;
        top: -5px;
        background: white;
        transform: rotate(-40deg);
      }

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        right: -1px;
        top: -5px;
        background: transparent;
        border-top: 1px solid $focus-outline;
        border-right: 1px solid $focus-outline;
        border-top-right-radius: 6px;
        pointer-events: none;
      }

      .w-easy-markdown-editor__button:first-child {
        &:before {
          content: '';
          position: absolute;
          width: 4px;
          height: 10px;
          left: -13px;
          top: -12px;
          background: white;
          transform: rotate(40deg);
        }

        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          left: -12px;
          top: -7px;
          background: transparent;
          border-top: 1px solid $focus-outline;
          border-left: 1px solid $focus-outline;
          border-top-left-radius: 7px;
          pointer-events: none;
        }
      }
    }

    .CodeMirror {
      border: none;
      border-radius: 8px;
      color: $text-color;
      font-size: 14px;
      background-color: $hover-bg;
      padding: 10px 4px;
      min-height: var(--task-desc-editor-height);
      height: var(--task-desc-editor-height);
      transition: all 0.25ms ease-in-out;
    }

    .cm-resize-handle {
      bottom: 12px;
      right: 12px;
    }
  }

  ::v-deep .collapsed {
    pointer-events: none;

    &.partial::after {
      background: initial;
    }

    &:after {
      opacity: 0.4;
    }
  }
}
</style>
