<script>
import { toRefs, h } from 'vue-demi';
import { provideSteps } from './useSteps';

export default {
  name: 'FSteps',
  props: {
    current: {
      type: Number,
      default: null,
    },
  },
  setup(props, { slots }) {
    const { current } = toRefs(props);
    provideSteps({
      current,
    });

    return () => {
      let index = 0;
      const steps = slots.default?.().map((step) => {
        if (step.tag && !step.componentInstance) {
          return {
            ...step,
            componentOptions: {
              ...step.componentOptions,
              propsData: {
                ...step.componentOptions.propsData,
                // eslint-disable-next-line no-plusplus
                index: index++,
              },
            },
          };
        }
        return step;
      });

      const line = h('div', {
        class:
          'absolute h-0.5 bg-[color:var(--token-c-stepper-color-border)] left-0 right-0 top-1/2 -mt-px',
      });

      const children = [line, steps];

      return h(
        'div',
        { class: 'flex justify-between w-full relative h-8 mb-8' },
        children,
      );
    };
  },
};
</script>
