<template>
  <v-popover
    class="dependency-status-picker"
    trigger="click"
    :open.sync="isOpen"
    :auto-hide="!statusSelected"
    popover-class="dependency-popover-wrapper"
    placement="top"
    offset="0"
    popover-arrow-class="hidden"
    boundaries-element="body"
    @apply-hide="$emit('toggle', false)"
  >
    <slot />
    <template slot="popover">
      <DependencyStatusPickerItem
        :selected="type === 'blocked'"
        :name="$t('Blocked by')"
        type="blocked"
        @click.native="changeStatus('start')"
      >
        <template #description>
          {{ $t("This task can't") }}
          <b>{{ $t('start') }}</b>
          {{ $t('until the dependency is completed') }}
        </template>
      </DependencyStatusPickerItem>
      <DependencyStatusPickerItem
        :selected="type === 'waiting'"
        :name="$t('Waiting on')"
        type="waiting"
        @click.native="changeStatus('complete')"
      >
        <template #description>
          {{ $t("This task can't") }}
          <b>{{ $t('be completed') }}</b>
          {{ $t('until the dependency is completed') }}
        </template>
      </DependencyStatusPickerItem>
    </template>
  </v-popover>
</template>
<script>
import DependencyStatusPickerItem from './DependencyStatusPickerItem';

export default {
  name: 'DependencyStatusPicker',
  props: {
    open: { type: Boolean, default: false },
    type: { type: String, default: '' },
  },
  components: {
    DependencyStatusPickerItem,
  },
  data() {
    return {
      statusSelected: false,
    };
  },
  methods: {
    changeStatus(status) {
      this.isOpen = false;
      this.$emit('change-status', status);
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(newVal) {
        this.$emit('update:open', newVal);
      },
    },
  },
};
</script>
<style lang="scss">
.dependency-popover-wrapper .w-popover__inner {
  width: 320px;
}
</style>
