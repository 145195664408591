export default [
  {
    path: '/people/:userId(\\d+)',
    component: () => import('./RouteUser'),
    children: [
      {
        path: 'integrations/:category(.+)?',
        component: () => import('./RouteUserIntegrations'),
      },
    ],
  },
];
