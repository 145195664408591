<template>
  <SearchInput
    ref="input"
    :value="value"
    @input="$emit('input', $event)"
    @enter="$emit('enter', $event)"
    :placeholder="placeholder"
    :debounce-timeout="debounceTimeout"
    :identifier-prefix="identifierPrefix"
  >
    <template #extra>
      <div class="p-1.5 pl-0 h-full inline-flex select-none">
        <VPopover
          ref="popover"
          placement="bottom-end"
          class=""
          popover-arrow-class="hidden"
          :offset="filterPopoverOffset"
          :open.sync="isFilterDropdownOpen"
        >
          <button
            class="
              inline-flex
              items-center
              flex-none
              min-w-0
              border-none
              select-none
              transition-colors
              h-[28px]
              justify-center
              px-2.5
              py-1.5
              rounded-full
              text-xs
              bg-transparent
            "
            :data-identifier="
              identifierPrefix && `${identifierPrefix}__filter-dropdown__toggle`
            "
            :class="[
              'hover:bg-bg',
              ...(isFilterDropdownOpen ? ['bg-bg'] : ['bg-transparent']),
            ]"
            @click="toggleFilterDropdown"
          >
            <span class="text-text truncate font-xs">
              {{ activeFilterOption.label }}
            </span>
            <!-- icon-chevron-down -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox=".17 .67 11.67 6.67"
              class="fill-current ml-3 transform transition-transform"
              :class="[isFilterDropdownOpen && 'rotate-180']"
              width="12px"
              height="8px"
            >
              <path
                d="M5.61108 7.175.325652 1.90298c-.214151-.21034-.214151-.55047 0-.76081L.649157.824414c.214151-.210343.560433-.210343.774593 0L5.99837 5.40274 10.573.82889c.2142-.210344.5604-.210344.7746 0l.3235.31775c.2141.21035.2141.55048 0 .76082L6.38567 7.17948c-.21415.20586-.56044.20586-.77459-.00448Z"
              />
            </svg>
          </button>
          <template #popover>
            <PopoverMenuContainer class="w-auto">
              <ul class="m-0 p-0">
                <PopoverMenuActionItem
                  v-for="filterOption in filterOptions"
                  :key="filterOption.id"
                  @click="$emit('update:filter', filterOption.id)"
                  :active="filterOption.id === filter"
                  v-close-popover
                  :data-identifier="
                    identifierPrefix &&
                    `${identifierPrefix}__filter-dropdown__option`
                  "
                >
                  <template #icon>
                    <!-- TODO: Replace these SVGs with the new icon package/component (when available) and drive off `icon` property on `filterOptions` prop -->

                    <!-- icon-everything -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 15 15"
                      v-if="filterOption.id === 'everything'"
                      width="16px"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.417 1.25H1.25v4.167h4.167V1.25ZM0 0v6.667h6.667V0H0Zm13.75 1.25H9.583v4.167h4.167V1.25ZM8.333 0v6.667H15V0H8.333ZM5.417 9.583H1.25v4.167h4.167V9.583ZM0 8.333V15h6.667V8.333H0Zm13.75 1.25H9.583v4.167h4.167V9.583Zm-5.417-1.25V15H15V8.333H8.333Z"
                        clip-rule="evenodd"
                      />
                    </svg>

                    <!-- icon-project -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16.7 12.5"
                      v-else-if="filterOption.id === 'projects'"
                      width="16px"
                    >
                      <path
                        d="M6.351 1.042 8.134 2.82c.195.195.461.305.738.305h6.261c.289 0 .522.233.522.521v7.292c0 .287-.233.52-.522.52H1.567c-.289 0-.523-.233-.523-.52V1.563c0-.288.234-.521.523-.521h4.784ZM1.567 0C.702 0 0 .7 0 1.563v9.375c0 .863.702 1.562 1.567 1.562h13.566c.865 0 1.567-.699 1.567-1.562V3.646c0-.863-.702-1.563-1.567-1.563H8.872L7.09.305C6.895.11 6.629 0 6.351 0H1.567Z"
                      />
                    </svg>

                    <!-- icon-task -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16.11 16.1"
                      v-else-if="filterOption.id === 'tasks'"
                      width="16px"
                    >
                      <path
                        d="M8.055 0C3.606 0 0 3.604 0 8.05c0 4.446 3.606 8.049 8.055 8.049s8.055-3.603 8.055-8.049S12.504 0 8.055 0Zm0 15.06c-3.854 0-7.015-3.118-7.015-7.01 0-3.852 3.119-7.011 7.015-7.011 3.854 0 7.015 3.117 7.015 7.011 0 3.852-3.12 7.01-7.015 7.01Zm4.599-8.924-5.861 5.811c-.153.151-.399.15-.551-.003l-2.79-2.809c-.151-.153-.15-.399.003-.551l.277-.274c.152-.152.399-.151.55.002l2.241 2.256 5.308-5.261c.153-.152.4-.151.551.002l.275.276c.151.152.15.4-.003.551Z"
                      />
                    </svg>
                  </template>
                  <template #title>
                    {{ filterOption.label }}
                  </template>
                </PopoverMenuActionItem>
              </ul>
            </PopoverMenuContainer>
          </template>
        </VPopover>
      </div>
    </template>
  </SearchInput>
</template>

<script>
import { computed, ref, shallowRef, Vue2 as Vue } from 'vue-demi';
import { VPopover } from 'v-tooltip';
import { isFunction } from 'lodash-es';

import PopoverMenuActionItem from '@/platform/components/side-nav/nav-items/PopoverMenuActionItem.vue';
import PopoverMenuContainer from '@/platform/components/side-nav/nav-items/PopoverMenuContainer.vue';
import SearchInput from '@/platform/components/side-nav/SearchInput.vue';

export default {
  name: 'FilterableSearchInput',
  components: {
    PopoverMenuActionItem,
    PopoverMenuContainer,
    SearchInput,
    VPopover,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search',
    },
    filter: {
      type: String,
      required: false,
      default: undefined,
    },
    filterOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    debounceTimeout: {
      type: Number,
      required: false,
      default: 350,
    },
    identifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const input = ref(null);
    const isFilterDropdownOpen = ref(false);
    const filterPopoverOffset = shallowRef(0);

    const activeFilterOptionId = computed(() => {
      if (!Array.isArray(props.filterOptions) || !props.filterOptions.length) {
        return undefined;
      }

      const filter = props.filterOptions.find((o) => o.id === props.filter);
      if (!filter) {
        const defaultFilterId = Object.values(props.filterOptions)[0].id;

        if (!defaultFilterId) {
          return undefined;
        }

        emit('update:filter', defaultFilterId);

        return defaultFilterId;
      }

      return filter.id;
    });

    const activeFilterOption = computed(() => {
      return props.filterOptions.find(
        (o) => o.id === activeFilterOptionId.value,
      );
    });

    function focus() {
      if (!(input.value instanceof Vue || !isFunction(input.value.focus))) {
        return;
      }

      input.value.focus();
    }

    const openFilterDropdown = () => {
      isFilterDropdownOpen.value = true;
    };

    const closeFilterDropdown = () => {
      isFilterDropdownOpen.value = false;
    };

    const toggleFilterDropdown = () => {
      isFilterDropdownOpen.value = !isFilterDropdownOpen.value;
    };

    return {
      input,
      isFilterDropdownOpen,
      filterPopoverOffset,
      focus,
      activeFilterOption,
      openFilterDropdown,
      closeFilterDropdown,
      toggleFilterDropdown,
    };
  },
};
</script>

<style lang="scss" scoped>
// TODO: Native Tailwind class isn't working
.rotate-180 {
  --tw-rotate: 180deg;
  transform: rotate(var(--tw-rotate));
}
</style>
