export const DEFAULT_MODAL_Z_INDEX = 1300;

export function getModalZIndex() {
  // Vue Modals don't have the concept of stacking so we need to manually manage z-index based on open modals (TKO + Vue)
  const stackedDivs = document.querySelectorAll(
    '.w-modal-dialog, div.lbox, .quick--view-overlay, .f-modal',
  );
  const zIndex = DEFAULT_MODAL_Z_INDEX + stackedDivs.length * 10 + 30;
  return zIndex;
}
