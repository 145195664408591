/* eslint-disable no-param-reassign,no-return-assign,consistent-return */
import Vue from 'vue';
import moment from 'moment';
import { lsRecordsSyncer } from '@/store/utils/localstorage';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { mapMutations, mapGetters } from '@/store/utils/record-mapper';
import placeholder from '@/store/utils/placeholder';
import api from '@/services/api';
import actions from './actions';
import tasks from './tasks';

const recordDefault = {
  id: 0,
  name: '',
  status: '',
  milestoneId: 0,
  complete: false,
  collapsed: false,
  uncompletedCount: 0,
  completedCount: 0,
  projectId: 0,
  position: 0,
  busy: false,
  lockdownId: 0,
  pinned: false,
};

export const watchers = [
  lsRecordsSyncer({
    prefix: (id) => `taskList-${id}-`,
    mutation: 'tasklist/lsRecord',
    fields: {
      collapsed: false,
    },
  }),
];

const today = () => moment.tz(moment.tz.guess()).startOf('day');

// Placeholder functionality is mixed in
const ph = placeholder({
  persistApi: ({ state, name }) =>
    api.post(`/projects/${state.projectId}/tasklists.json`, {
      'todo-list': { name, 'milestone-id': 0 },
      addToTop: false,
    }),
  createPlaceholder: ({ initial, rootGetters }) => {
    if (!initial.projectId) {
      Vue.util.warn(
        'Cannot create a placeholder tasklist without a parent project',
      );
      return;
    }
    const newPosition =
      Math.max(
        0,
        ...rootGetters['project/tasklists/ordered'](initial.projectId).map(
          ({ position }) => position,
        ),
      ) + 1;
    return {
      ...recordDefault,
      ...initial,
      name: 'New task list',
      position: newPosition,
    };
  },
});

export default {
  modules: {
    tasks,
  },
  namespaced: true,
  state: {
    records: {},
    sort: 'startdate',
    sortOrder: 'desc',
    allTaskLists: [],
  },
  getters: {
    ...ph.getters,
    findAllByMilestone: (state) => (mstoneId) =>
      Object.values(state.records)
        .filter(
          ({ status, milestoneId }) =>
            status !== 'deleted' && milestoneId === mstoneId,
        )
        .map(({ id }) => id),
    findByMilestone:
      (state, { findAllByMilestone }) =>
      (mstoneId) =>
        findAllByMilestone(mstoneId)[0] || 0,
    // Position in the same way it is passed to reposition API
    position: (state) => (taskListId) => {
      const pId = state.records[taskListId].projectId;
      const list = Object.values(state.records)
        .filter(({ projectId }) => projectId === pId)
        .sort((a, b) => a.position - b.position)
        .map(({ id }) => id);
      return list.indexOf(taskListId) + 1;
    },
    ...mapGetters({
      tasks: (state, getters, { task }) => {
        if (state && state.id) {
          const tasksByTasklist = task.tasksByTasklist[state.id] || [];
          return tasksByTasklist
            .map((id) => task.records[id])
            .filter(Boolean)
            .filter(({ status }) => status !== 'deleted')
            .sort((a, b) => a.order - b.order);
        }
        return [];
      },
      tasksRows: (state, getters, { task }) => {
        if (state && state.id) {
          const tasksByTasklist = task.tasksByTasklist[state.id] || [];
          return tasksByTasklist
            .map((id) => task.records[id])
            .filter(Boolean)
            .filter(({ status }) => status !== 'deleted');
        }
        return [];
      },
      canDragTasks: (state, getters, { task }) =>
        Object.values(task.records)
          .filter((t) => t.taskListId === state.id)
          .filter(({ status }) => !['deleted', 'completed'].includes(status))
          .every(({ canEdit }) => canEdit),
      progress: (state, getters, rootState, rootGetters) => {
        const proportional = getters
          .tasks(state.id)
          .map(({ id }) => rootGetters['task/progressProportional'](id))
          .reduce(
            (total, curr) => [total[0] + curr[0], total[1] + curr[1]],
            [0, 0],
          );
        const total = proportional[0] + proportional[1];
        return total ? Math.floor((proportional[0] / total) * 100) : 0;
      },
      resolveRange: (state, getters, { task, milestone }, rootGetters) => {
        const { diff } = rootGetters['project/workingWeek'](state.projectId);
        const resolved = Object.values(task.records)
          .filter((t) => t.taskListId === state.id)
          .filter(({ status }) => status !== 'deleted')
          .map(({ id }) => rootGetters['task/resolveRange'](id));

        // Milestone also impacts tasklist range
        if (state.milestoneId) {
          const mstone = milestone.records[state.milestoneId];
          if (mstone && mstone.deadline.isValid()) {
            resolved.push({
              startDate: mstone.deadline,
              dueDate: mstone.deadline,
            });
          }
        }

        const start =
          resolved
            .map(({ startDate }) => startDate)
            .reduce(
              (min, curr) => (!min || curr.isBefore(min) ? curr : min),
              null,
            ) || today();
        const due =
          resolved
            .map(({ dueDate }) => dueDate)
            .reduce(
              (max, curr) => (!max || curr.isAfter(max) ? curr : max),
              null,
            ) || today();
        const duration = Math.max(diff(due.toDate(), start.toDate()), 0) + 1;
        return { startDate: start, dueDate: due, duration };
      },
      startDate: (state, getters) => getters.resolveRange(state.id).startDate,
      highestOrder: (state, getters, { task }) =>
        Math.max(
          ...Object.values(task.records)
            .filter((t) => !t.parentTaskId && t.taskListId === state.id)
            .map(({ order }) => order),
        ),
    }),
  },
  mutations: {
    ...ph.mutations,
    record(state, tasklist) {
      const withDefaults = typedDefaults(recordDefault, tasklist);
      withDefaults.busy = withDefaults.busy || false;
      const existing = state.records[tasklist.id];
      const newRec = existing ? { ...existing, ...withDefaults } : withDefaults;
      Vue.set(state.records, newRec.id, newRec);
    },
    records(state, tasklists) {
      const hash = {};
      tasklists.forEach((tasklist) => {
        const withDefaults = typedDefaults(recordDefault, tasklist);
        withDefaults.busy = withDefaults.busy || false;
        const existing = state.records[tasklist.id];
        hash[tasklist.id] = existing
          ? { ...existing, ...withDefaults }
          : withDefaults;
      });
      state.records = { ...state.records, ...hash };
    },
    reposition(state, { id, position }) {
      const pId = state.records[id].projectId;
      const list = Object.values(state.records)
        .filter(({ projectId, id: rid }) => projectId === pId && rid !== id)
        .sort((a, b) => a.position - b.position);
      list.splice(position - 1, 0, state.records[id]);
      list.forEach((record, index) => {
        record.position = index + 1;
      });
    },
    ...mapMutations({
      lsRecord: (state, lsRecord) => {
        Vue.assign(state, lsRecord);
      },
      toggleCollapsed: (state) => {
        state.collapsed = !state.collapsed;
      },
      changeName: (state, newName) => {
        state.name = newName;
      },
      delete: (state) => {
        state.status = 'deleted';
      },
      undelete: (state) => {
        state.status = 'reopened';
      },
      unlinkMilestone: (state) => {
        state.milestoneId = 0;
      },
      linkMilestone: (state, milestoneId) => {
        state.milestoneId = milestoneId;
      },
    }),
    sort: (state, val) => {
      state.sort = val;
    },
    sortOrder: (state, val) => {
      state.sortOrder = val;
    },
    setAllTaskLists: (state, val) => {
      state.allTaskLists = val;
    },
  },
  actions: {
    ...ph.actions,
    ...actions,
  },
};
