<script>
import { computed, nextTick, shallowRef, watch } from 'vue-demi';
import moment from 'moment';
import { VPopover } from 'v-tooltip';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import { isSameMonth } from './helpers';

export default {
  components: {
    VPopover,
    CommonIcon,
  },
  props: {
    monthYear: {
      type: Object,
      default: null,
      validator: (prop) =>
        prop === null || (moment.isMoment(prop) && prop.isValid()),
    },
  },
  emits: ['update:monthYear', 'monthYearMenuVisibilityChange'],

  setup(props, { emit }) {
    const isOpen = shallowRef(false);

    const monthRefs = shallowRef([]);
    const monthYear = computed({
      get() {
        return props.monthYear;
      },
      set(value) {
        emit('update:monthYear', value);
      },
    });

    async function selectMonth(month) {
      monthYear.value = month;
      isOpen.value = false;
    }

    const MONTH_COUNT = 12;

    function isSelected(date) {
      return isSameMonth(monthYear.value, date);
    }

    // Get a list of the preceding 12 months and the following 12 months
    const months = computed(() => {
      const numbers = [
        ...new Set(
          new Array(MONTH_COUNT + 1)
            .fill(null)
            .flatMap((_, index) => [index * -1, index])
            .sort((a, b) => a - b),
        ),
      ];
      return numbers.map((month) =>
        monthYear.value.clone().startOf('month').add({ month }),
      );
    });

    watch(
      isOpen,
      async () => {
        emit('monthYearMenuVisibilityChange', isOpen.value);
        if (!isOpen.value) {
          return;
        }
        await nextTick();
        const selectedIndex = months.value.findIndex((month) =>
          isSelected(month),
        );
        const monthRef = monthRefs.value[selectedIndex];
        monthRef?.scrollIntoView();
      },
      { flush: 'post' },
    );

    return {
      isOpen,
      monthRefs,
      selectMonth,
      isSelected,
      months,
      moment,
    };
  },
};
</script>

<template>
  <VPopover
    :open.sync="isOpen"
    ref="popover"
    boundaries-element="body"
    :auto-hide="false"
    popover-arrow-class="hidden"
  >
    <template #default="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <template #popover>
      <div
        class="max-h-80 w-48 overflow-auto bg-default shadow-2"
        :style="{
          '--lsds-a-color-primary-default': '#3C55BD',
          '--lsds-a-color-surface-emphasis-selected': '#D8E0FF',
        }"
      >
        <div class="m-2 flex flex-col items-stretch gap-2">
          <div
            v-for="month in months"
            ref="monthRefs"
            :key="month.format('YYYY-MM')"
            role="button"
            :aria-selected="isSelected(month)"
            class="rounded-md p-2 hover:bg-surface-hover"
            :class="{
              '!bg-[var(--lsds-a-color-surface-emphasis-selected)]':
                isSelected(month),
              'font-semibold': isSelected(month),
              'text-primary': isSelected(month),
            }"
            @click.stop.prevent="selectMonth(month, $event)"
          >
            <div class="flex items-center !justify-between text-body-1">
              {{ moment(month).format('MMMM YYYY') }}
              <CommonIcon
                v-if="isSelected(month)"
                id="check"
                class="text-[var(--lsds-a-color-primary-default)]"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </VPopover>
</template>
