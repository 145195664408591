var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.tooltip,
  }),expression:"{\n    content: tooltip,\n  }"}],class:[
    'chip',
    _vm.chipSize,
    {
      'has-icon': Boolean(_vm.icon),
      'has-image': Boolean(_vm.image),
      'has-label': Boolean(_vm.label),
      'has-background': Boolean(_vm.background),
      'has-remove': Boolean(_vm.hasRemoveListener),
      interactive: _vm.interactive,
    } ],style:({ backgroundColor: _vm.background, color: _vm.color }),on:{"click":_vm.handleClick}},[(_vm.image)?_c('img',{attrs:{"src":_vm.image}}):_vm._e(),_vm._v(" "),(_vm.icon)?_c('CommonIcons',{attrs:{"id":_vm.icon}}):_vm._e(),_vm._v(" "),(_vm.label)?_c('span',{staticClass:"chip__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.hasRemoveListener)?_c('button',{staticClass:"chip__remove",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleRemove.apply(null, arguments)}}},[_vm._v("\n    ×\n  ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }