<template>
  <SectionBlock :title="$t('Time Logs')" :small="true">
    <template>
      <TimeLogsCount v-if="hasLoggedTime" slot="icon" :task="task" />
      <ButtonText
        v-if="hasLoggedTime"
        slot="accessory"
        @click="isLogsVisible = !isLogsVisible"
      >
        <span>{{ toggleButtontext }}</span>
        <CommonIcons color="#4461D7" :id="toggleButtonChevron" />
      </ButtonText>
    </template>
    <CollapsePanel :is-open="isLogsVisible">
      <div class="time-logs">
        <table v-if="entries.length">
          <thead>
            <tr>
              <th>
                {{ $t('Who') }}
              </th>
              <th width="auto">
                {{ $t('Description') }}
              </th>
              <th>
                {{ $t('Date') }}
              </th>
              <th>
                {{ $t('Time') }}
              </th>
              <th>
                {{ $t('Billable') }}
              </th>
              <th>
                {{ $t('Billed') }}
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="entry in entries" :key="entry.id">
              <td>
                <div
                  class="time-logs__assignee"
                  :style="{
                    backgroundColor: entry.color,
                  }"
                  v-tooltip="{
                    content: `${entry.userFirstName} ${entry.userLastName}`,
                  }"
                >
                  {{ entry.initials }}
                </div>
              </td>
              <td>
                <div
                  class="time-logs__description"
                  v-tooltip="{
                    content:
                      entry.description.length > 14 ? entry.description : null,
                  }"
                >
                  {{ entry.description }}
                </div>
              </td>
              <td>{{ entry.formattedDate }}</td>
              <td>
                <div class="time-logs__time">{{ entry.time }}</div>
              </td>
              <td class="centered">
                <input
                  type="checkbox"
                  :disabled="entry.isBilled || !entry.canEdit"
                  :checked="entry.isBillable"
                  @click="toggleBillable(entry.id, !entry.isBillable)"
                />
              </td>
              <td class="centered">
                <CommonIcons
                  class="icon"
                  :id="entry.isBilled ? 'alert-success' : 'close-light'"
                />
              </td>
              <td>
                <MenuPopover
                  :no-arrow="true"
                  placement="top-end"
                  :boundaries="'body'"
                  :offset="0"
                  v-if="!entry.isBilled && entry.canEdit"
                >
                  <button class="context-menu-button">
                    <CommonIcons id="ellipsis-v" />
                  </button>
                  <template #menu>
                    <MenuElement
                      :text="$t('Edit')"
                      @click="onLogEdit(entry)"
                      v-close-popover
                    />
                    <MenuElement
                      :text="$t('Delete')"
                      @click="onLogDelete(entry)"
                    />
                  </template>
                </MenuPopover>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CollapsePanel>
    <LogTimeLoggerPopover
      :task="task"
      v-on="$listeners"
      :open.sync="isLogtimeOpen"
      :disabled="disabled"
      :source="'task_details_footer'"
    >
      <ButtonText v-if="canEdit" underline @click.native="onLogTimeClicked">
        + {{ hasLoggedTime ? $t('Log more time') : $t('Log time on task') }}
      </ButtonText>
    </LogTimeLoggerPopover>
  </SectionBlock>
</template>

<script>
import { defineComponent, ref, computed, Vue2 as Vue, watch } from 'vue-demi';
import { useLocalStorage } from '@vueuse/core';
import moment from 'moment';
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import CollapsePanel from '@widgets/CollapsePanel';
import { v2 as v2Fetcher, v3Url } from '@teamwork/fetcher';
import LogTimeLoggerPopover from '@widgets/LogTimeLoggerPopover/LogTimeLoggerPopover';
import MenuPopover from '@widgets/Menu/MenuPopover';
import MenuElement from '@widgets/Menu/MenuElement';
import TimeEntryModel from 'timeEntryModel';
import api from '@/services/api';
import { strToColor } from '@/utils/helpers/color';
import { initials } from '@/utils/helpers/strings';
import useStore from '@/platform/composables/useStore';

import useExperimentA15 from '@/platform/composables/useExperimentA15';
import usePendo from '@/platform/composables/usePendo';

import TimeLogsCount from '../TimeLogsCount';
import SectionBlock from '../SectionBlock';
import ButtonText from '../ButtonText';
import { useLocalization } from '@/platform/composables/useLocalization';

export default defineComponent({
  name: 'TimeLogs',
  components: {
    CollapsePanel,
    CommonIcons,
    TimeLogsCount,
    SectionBlock,
    ButtonText,
    LogTimeLoggerPopover,
    MenuPopover,
    MenuElement,
  },
  props: {
    task: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { dateFormat } = useLocalization();

    const { EXP_A15_COMMON_METRICS } = useExperimentA15();
    const { trackPendoEvent } = usePendo();

    const isLogtimeOpen = ref(false);
    const entries = ref([]);
    const isLoading = ref(false);

    const isLogsVisible = useLocalStorage('task-detail/show-timelogs', true);
    const toggleButtontext = computed(() =>
      isLogsVisible.value ? Vue.t('Hide') : Vue.t('Show'),
    );
    const toggleButtonChevron = computed(() =>
      isLogsVisible.value ? 'angle-up' : 'angle-down',
    );
    const canEdit = computed(() => {
      return (
        props.task &&
        props.task.userPermissions &&
        props.task.userPermissions.canEdit &&
        props.task.userPermissions.canLogTime
      );
    });

    const hasLoggedTime = computed(() => {
      return props.task.timeTotals && props.task.timeTotals.loggedMinutes > 0;
    });

    const fetchTimeLogs = async () => {
      if (!props.task.id || !isLogsVisible.value || isLoading.value) {
        return;
      }
      isLoading.value = true;

      try {
        const { timeEntries } = await v2Fetcher(
          `/tasks/${props.task.id}/time_entries`,
          null,
          ({ data }) => data,
        );

        if (timeEntries) {
          entries.value = timeEntries.map((entry) => {
            return {
              ...entry,
              time: `${entry.hours > 0 ? `${entry.hours}h ` : ''}${
                entry.minutes > 0 ? ` ${entry.minutes}m` : ''
              }`,
              formattedDate: moment(entry.date).format(dateFormat.value),
              color: strToColor(`${entry.userFirstName}${entry.userLastName}`),
              initials: initials(entry.userFirstName, entry.userLastName),
            };
          });
        }
      } catch (e) {
        store.dispatch('notifications/flashes/error', {
          title: Vue.t('Error'),
          msg: Vue.t('Cannot load time logs'),
        });
      }
      isLoading.value = false;
    };

    const toggleBillable = (logId, isBillable) => {
      try {
        api.put(v3Url(`time/${logId}`), {
          timelog: { isBillable },
        });
      } catch (e) {
        store.dispatch('notifications/flashes/error', {
          title: Vue.t('Error'),
          msg: Vue.t('Something went wrong, please try again'),
        });
      }
    };

    const onLogEdit = (entry) => {
      store.dispatch('modals/tko/open', {
        id: 'addOrEditTimeEntry',
        args: {
          timeEntry: new TimeEntryModel(entry),
        },
      });
    };

    const onLogTimeClicked = () => {
      trackPendoEvent({
        eventName: 'TASK_DETAILS_EVENT',
        commonMetrics: EXP_A15_COMMON_METRICS,
        metadata: {
          event_action: 'log_time_clicked',
          event_source: 'task_details_footer',
        },
      });
    };

    const onLogDelete = (entry) => {
      store.dispatch('modals/open', {
        name: 'confirm-modal',
        props: {
          title: Vue.t('Confirm Delete?'),
          text: Vue.t('Delete this time log?⁣'),
          cancelMessage: Vue.t('Cancel'),
          confirmMessage: Vue.t('Yes'),
          dataIdentifier: 'time-log-confirm-modal',
          callback: async (result) => {
            if (result.OK) {
              try {
                api.delete(v3Url(`time/${entry.id}`));
              } catch (e) {
                store.dispatch('notifications/flashes/error', {
                  title: Vue.t('Error'),
                  msg: Vue.t('Something went wrong, please try again'),
                });
              }
            }
          },
        },
      });
    };

    watch(
      () => [
        isLogsVisible.value,
        props.task,
        props.task.timeTotals ? props.task.timeTotals.loggedMinutes : 0,
      ],
      () => {
        fetchTimeLogs();
      },
      { immediate: true, deep: true },
    );

    return {
      isLogsVisible,
      isLogtimeOpen,
      entries,
      canEdit,
      hasLoggedTime,
      isLoading,
      toggleButtontext,
      toggleButtonChevron,
      fetchTimeLogs,
      toggleBillable,
      onLogEdit,
      onLogDelete,
      onLogTimeClicked,
    };
  },
});
</script>

<style lang="scss" scoped>
.time-logs {
  margin-bottom: 8px;

  table {
    width: 100%;
    margin: 10px 0;

    th {
      font-size: 14px;
      line-height: 24px;
      color: #6d6f80;
      padding: 8px;
    }

    td {
      padding: 12px;
      box-shadow: inset 0 1px 0 0 #f5f7fa;

      &:not(:first-child) {
        box-shadow: inset 1px 1px 0 0 #f5f7fa;
      }

      &:last-child {
        box-shadow: inset 0px 1px 0 0 #f5f7fa;
      }
    }

    th,
    td {
      &.centered {
        text-align: center;
      }

      &:last-of-type {
        padding: 0;
      }
    }
  }

  &__description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
  }

  &__time {
    width: 50px;
  }

  &__assignee {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    font-size: 13px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .context-menu-button {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #f5f7fa;
    background: transparent;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
