<template>
  <div
    class="
      py-[64px]
      text-center
      h-full
      flex flex-col
      items-center
      justify-center
      min-h-[214px]
    "
  >
    <div class="flex-shrink-0">
      <ProjectsPanelWithStarsImage class="mt-300" />
    </div>
    <p
      class="
        text-text text-[14px]
        leading-default
        px-200
        mb-300
        pt-4
        mx-auto
        w-full
        flex flex-col
        items-center
      "
    >
      <span class="font-semibold block mb-2 w-44">
        {{ $t('No Recent Projects') }}
      </span>

      <small class="w-52 text-[#646d7d] text-[13px] font-base">
        {{ $t('All your recent project activity would be shown here') }}
      </small>
    </p>
  </div>
</template>

<script>
import ProjectsPanelWithStarsImage from '@/assets/images/blankslates/ProjectsPanelWithStars.svg';

export default {
  components: {
    ProjectsPanelWithStarsImage,
  },

  setup(props, { emit }) {
    const handleClick = () => emit('click');

    return {
      handleClick,
    };
  },
};
</script>
