<template>
  <div class="w-date-input" :class="{ 'is-open': isOpen }">
    <v-popover
      placement="bottom"
      :open.sync="isOpen"
      boundaries-element="viewport"
      :popover-arrow-class="popoverArrowClass"
      :popover-class="popoverClass"
      :offset="offset"
      :disabled="disabled"
      @apply-show="$emit('toggle', true)"
      @apply-hide="$emit('toggle', false)"
    >
      <FInput
        ref="textInputRef"
        class="cursor-pointer"
        readonly
        :clearable="clearable && Boolean(date)"
        :clickable="true"
        :data-identifier="dataIdentifier"
        :disabled="disabled"
        :invalid="invalid"
        :model-value="formattedDisplayDate"
        :placeholder="computedPlaceholder"
        :size="size"
        :variant="variant"
        @input="onDateChange"
      >
        <template #prepend v-if="hasIcon">
          <CommonIcons
            class="calendar-icon"
            id="calendar"
            :color="disabled ? '#8f96b3' : null"
          />
        </template>
      </FInput>

      <template slot="popover">
        <DatePicker
          v-if="isOpen"
          :value="dateAsMoment"
          :shortcuts="computedShortcuts"
          v-bind="{ ...pickerOptions }"
          @input="onDateChange"
        />
      </template>
    </v-popover>
  </div>
</template>

<script>
import { ref, computed } from 'vue-demi';
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import DatePicker from '@widgets/DatePicker';
import { FInput } from '@fragments';
import { ensureMoment, formatDate } from '@/utils/helpers/date';
import { useLocalization } from '@/platform/composables/useLocalization';
import useTranslations from '@/platform/composables/useTranslations';

// @vue/component
export default {
  name: 'DateInput',
  display: 'DateInput',
  model: {
    prop: 'date',
    event: 'change',
  },
  components: { DatePicker, CommonIcons, FInput },
  props: {
    clearable: { type: Boolean, default: false },
    dataIdentifier: { type: String, default: null },
    date: { type: [Object, String], default: null },
    disabled: { type: Boolean, default: false },
    format: { type: String, default: '' },
    hasIcon: { type: Boolean, default: true },
    noArrow: { type: Boolean, default: false },
    pickerOptions: { type: Object, default: null },
    popoverClass: { type: String, default: '' },
    size: { type: String, default: FInput.props.size.default },
    shortcuts: { type: Array, default: null },
    showDefaultShortcuts: { type: Boolean, default: false },
    variant: { type: String, default: FInput.props.variant.default },
    invalid: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const textInputRef = ref(null);
    const { dateFormat } = useLocalization();
    const $t = useTranslations();
    const popoverArrowClass = computed(() =>
      props.noArrow ? 'hidden' : undefined,
    );
    const offset = computed(() => (props.noArrow ? 1 : undefined));
    const computedShortcuts = computed(() => {
      if (props.shortcuts) {
        return props.shortcuts;
      }
      if (!props.showDefaultShortcuts) {
        return [];
      }

      return [
        { label: $t('Today'), span: 0 },
        { label: $t('+[0] Day', 1), span: 1 },
        { label: $t('+[0] Days', 7), span: 7 },
        { label: $t('None') },
      ];
    });

    const dateAsMoment = computed(() =>
      props.date ? ensureMoment(props.date, 'YYYYMMDD') : null,
    );

    // use specified format or user locale by default
    const displayFormat = computed(() => props.format || dateFormat.value);

    // placeholder should be same as display format unless it has an specific placeholder
    const computedPlaceholder = computed(() =>
      props.placeholder ? props.placeholder : displayFormat.value.toLowerCase(),
    );

    const formattedDisplayDate = computed(() =>
      props.date ? formatDate(dateAsMoment.value, displayFormat.value) : '',
    );

    function onDateChange(date) {
      isOpen.value = false;
      emit('change', date);
    }

    function onDateClear() {
      isOpen.value = false;
      emit('change', null);
    }

    function focus() {
      // FInput has a focus method
      textInputRef.value.focus();
      isOpen.value = true;
    }

    return {
      computedShortcuts,
      dateAsMoment,
      displayFormat,
      formattedDisplayDate,
      isOpen,
      offset,
      computedPlaceholder,
      popoverArrowClass,
      focus,
      onDateChange,
      onDateClear,
    };
  },
};
</script>
