<template>
  <div class="progress-popover">
    <p :class="[{ 'has-value': progress > 0 }]">
      {{ $t('Set progress') }}:
      <span>{{ progress }}%</span>
    </p>

    <ProgressSlider
      :value="progress"
      :max="maxRange"
      :disabled="!canEditProgress"
      @change="(value) => $emit('change', value)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProgressSlider from '@widgets/ProgressSlider/ProgressSlider';

export default {
  name: 'TaskProgressBar',
  components: { ProgressSlider },
  props: {
    task: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      userId: ({ user }) => user.id,
      userType: (state) => state.user.userType,
    }),
    isCollaborator() {
      return this.userType === 'collaborator';
    },
    maxRange() {
      return (this.task.predecessorIds &&
        this.task.predecessorIds.length > 0) ||
        (this.task.subTaskIds && this.task.subTaskIds.length > 0)
        ? 90
        : 100;
    },
    isAssignedToMe() {
      if (this.task.assignees) {
        return this.task.assignees.some((item) => item.id === this.userId);
      }
      return false;
    },
    canEditProgress() {
      if (this.task.status === 'completed') {
        return false;
      }
      // this is different than task.userPermissions.canEdit
      // https://support.teamwork.com/projects/using-teamwork/working-with-collaborators
      if (this.isCollaborator && !this.isAssignedToMe) {
        return false;
      }
      return true;
    },
    progress() {
      return this.task ? this.task.progress : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

.progress-popover {
  padding: 0;
  p {
    font-size: 14px;
    margin-bottom: 12px;

    &.has-value {
      span {
        color: $indigo-30;
        font-weight: 600;
      }
    }
  }
}
</style>
