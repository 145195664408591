<template>
  <div
    v-if="!disablePopover"
    class="ellipsis-label"
    v-resize.content-box="handleResize"
    :style="{ 'text-align': textAlign, '--lines': lines }"
    v-tooltip="{
      container: attachToTrigger ? false : 'body',
      classes: `tooltip w-tooltip break-normal ${tooltipClass}`,
      placement: placement,
      content: label,
    }"
  >
    <div ref="label" class="ellipsis-label-label">
      {{ label }}
    </div>
  </div>
  <div
    v-else
    class="ellipsis-label"
    v-resize.content-box="handleResize"
    :style="{ 'text-align': textAlign }"
  >
    <div
      ref="label"
      :style="{ 'text-align': textAlign, '--lines': lines }"
      :class="{ 'multi-line': lines > 1, 'ellipsis-label-label': lines === 1 }"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
import resize from '@/utils/directives/resize';

export default {
  name: 'EllipsisLabel',
  props: {
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notFocusable: {
      type: Boolean,
      default: false,
    },
    attachToTrigger: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: 'left',
    },
    placement: {
      type: String,
      default: 'top-center',
    },
    lines: {
      type: Number,
      default: 1,
    },
    tooltipClass: {
      type: String,
      default: '',
    },
  },
  directives: {
    resize,
  },
  data() {
    return {
      showPopover: false,
    };
  },
  computed: {
    disablePopover: (vm) => !vm.showPopover || vm.disabled,
  },
  methods: {
    handleResize() {
      if (this.$refs.label) {
        const el = this.$refs.label;
        if (el.offsetWidth < el.scrollWidth) {
          this.showPopover = true;
        } else {
          this.showPopover = false;
          if (this.notFocusable) {
            this.removeFocusable();
          }
        }
      }
    },
    removeFocusable() {
      this.$refs.label.removeAttribute('aria-describedby');
      this.$refs.label.removeAttribute('tabIndex');
    },
  },
};
</script>

<style lang="scss">
@import './tko/src/styles/variables/variables';

.ellipsis-label-label {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multi-line {
  white-space: wrap;
  overflow: hidden;
  -webkit-line-clamp: var(--lines);
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.ellipsis-label {
  overflow: hidden;

  &--popover {
    padding: $padding--small $padding--medium;
    color: white;
    background: black;
    border-radius: $border-radius--default;
  }
  .trigger {
    white-space: initial;
    width: 100%;
    max-width: 100%;
    -webkit-line-clamp: var(--lines);
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
  }
}

.max-width {
  max-width: 400px;
  text-align: center;
}
</style>
