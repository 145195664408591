var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.columnsLevel1.length > 0)?_c('v-popover',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.$t('Add or remove columns'),
    placement: 'top',
    boundariesElement: _vm.boundariesElement,
  }),expression:"{\n    content: $t('Add or remove columns'),\n    placement: 'top',\n    boundariesElement: boundariesElement,\n  }"}],staticClass:"TableViewColumnPicker",attrs:{"open":_vm.openLevel1,"auto-hide":!_vm.openLevel2,"boundaries-element":_vm.boundariesElement,"placement":"bottom-end","popover-arrow-class":"hidden"},on:{"update:open":function($event){_vm.openLevel1=$event}},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('div',{staticClass:"TableViewColumnPicker__title"},[_vm._v(_vm._s(_vm.$t('Customize view')))]),_vm._v(" "),_c('TableViewColumnPickerFieldItems',{attrs:{"columns":_vm.columnsLevel1,"data-identifier-prefix":_vm.dataIdentifierPrefix},on:{"on-show-edit-custom-field":_vm.editCustomFieldModal,"on-column-reorder":_vm.onColumnReorder,"on-drag-start":_vm.onDragStart,"on-drag-end":_vm.onDragEnd}},[(_vm.columnsLevel2.length > 0)?_c('v-popover',{class:{
          TableViewColumnPicker__more: true,
          'TableViewColumnPicker__more--open': _vm.openLevel2,
        },attrs:{"open":_vm.openLevel2,"delay":{ hide: 300 },"boundaries-element":".TableViewColumnPicker","placement":"left-end","popover-arrow-class":"hidden","trigger":"manual","offset":"4"},on:{"update:open":function($event){_vm.openLevel2=$event}},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('TableViewColumnPickerFieldItems',{attrs:{"secondary":"","columns":_vm.columnsLevel2,"data-identifier-prefix":_vm.dataIdentifierPrefix},on:{"on-show-edit-custom-field":_vm.editCustomFieldModal,"on-column-reorder":_vm.onColumnReorder},nativeOn:{"mouseenter":function($event){_vm.openLevel2 = true},"mouseleave":function($event){return _vm.closeLevel2Menu.apply(null, arguments)}}})]},proxy:true}],null,false,3537715515)},[_vm._v(" "),_c('TableViewColumnPickerItem',{attrs:{"data-identifier":_vm.dataIdentifierPrefix ? (_vm.dataIdentifierPrefix + "-more") : undefined},nativeOn:{"mouseenter":function($event){_vm.openLevel2 = true},"mouseleave":function($event){return _vm.closeLevel2Menu.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.$t('More...'))+"\n          "),_c('CommonIcon',{attrs:{"id":"chevron-right-solid"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.canManageCustomFields && _vm.canAccessCustomFields)?_c('TableViewColumnPickerItem',{directives:[{name:"close-popover",rawName:"v-close-popover"}],attrs:{"line-over":""}},[_c('button',{staticClass:"TableViewColumnPicker__add-custom-field",attrs:{"data-identifier":"column-picker-add-custom-field"},on:{"click":_vm.openAddCustomFieldModal}},[_c('span',{staticClass:"plus-sign"},[_c('CommonIcon',{attrs:{"id":"add"}})],1),_vm._v("\n          "+_vm._s(_vm.$t('Add field'))+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.canResetColumns)?_c('TableViewColumnPickerItem',{attrs:{"line-over":""},scopedSlots:_vm._u([{key:"toggle-switch",fn:function(){return [_c('ToggleSwitch',{attrs:{"reversed-order":true,"label":_vm.$t('Shared view'),"tip":_vm.canUpdateColumnDefaults
                ? _vm.$t('Save as the shared view for everyone on this project')
                : _vm.$t('See the shared view for everyone on this project')},model:{value:(_vm.canAutoResetColumns),callback:function ($$v) {_vm.canAutoResetColumns=$$v},expression:"canAutoResetColumns"}})]},proxy:true}],null,false,1865622383)}):_vm._e()],1)]},proxy:true}],null,false,494556383)},[_vm._v(" "),_c('div',{class:{
      TableViewColumnPicker__trigger: true,
      'TableViewColumnPicker__trigger--open': _vm.openLevel1,
    },attrs:{"data-identifier":_vm.dataIdentifierPrefix ? (_vm.dataIdentifierPrefix + "-add-columns") : undefined}},[_c('CommonIcon',{attrs:{"id":"add"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }