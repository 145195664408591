/* eslint-disable no-param-reassign */
import Vue from 'vue';
import lscache from 'lscache';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { unifyAssignedTo } from '@/utils/helpers/responsible';
import { remove } from '@/utils/helpers/array';
import { mapMutations } from '@/store/utils/record-mapper';

import recordDefault from './recordDefault';

// Standardise Map Task Response formats
const uniformat = (task) => ({
  name: task.content,
  taskListId: task['todo-list-id'],
  numDependencies: task['has-dependencies'],
  numEstMins: task['estimated-mins'],
  dueDateFromMilestone: !!(
    task['due-date'] &&
    task['due-date'].length &&
    task['due-date-base'] === ''
  ),
  boardColumnId: (task.boardColumn && task.boardColumn.id) || 0,
  tagIds: (task.tags && task.tags.map(({ id }) => id)) || [],
  ...task,
  // Map all assignees together
  assignedTo: unifyAssignedTo(task),
});

const recordsMut = (state, list) => {
  const hash = {};
  const subtasksHash = {};
  const byTasklist = {};
  list.forEach((rec) => {
    // Ensure minimal data included for initial load
    rec.busy = rec.busy || false;
    // Override this value if a record is reloaded
    rec.completedThisSession = false;
    // Map to a single format
    const task = typedDefaults(recordDefault, uniformat(rec));
    // LS Enrichment
    task.expanded =
      lscache.get(`twProjects-task-${rec.id}-displaying-subtasks`) !== false;
    const existing = state.records[rec.id];
    hash[task.id] = existing ? { ...existing, ...task } : task;

    // Reset previous hierarchy
    if (
      existing &&
      (existing.parentTaskId !== task.parentTaskId ||
        existing.taskListId !== task.taskListId)
    ) {
      if (existing.parentTaskId) {
        subtasksHash[existing.parentTaskId] = subtasksHash[
          existing.parentTaskId
        ] || [...(state.subtasks[existing.parentTaskId] || [])];
        remove(subtasksHash[existing.parentTaskId], task.id);
      } else {
        byTasklist[existing.taskListId] = byTasklist[existing.taskListId] || [
          ...(state.tasksByTasklist[existing.taskListId] || []),
        ];
        remove(byTasklist[existing.taskListId], task.id);
      }
    }

    // Assumption right now is that any API triggering the records mutation
    // will return tasks in their display order, this may change in future
    if (task.parentTaskId) {
      subtasksHash[task.parentTaskId] = subtasksHash[task.parentTaskId] || [
        ...(state.subtasks[task.parentTaskId] || []),
      ];
      if (!subtasksHash[task.parentTaskId].includes(task.id)) {
        subtasksHash[task.parentTaskId].push(task.id);
      }
    } else if (task.taskListId) {
      byTasklist[task.taskListId] = byTasklist[task.taskListId] || [
        ...(state.tasksByTasklist[task.taskListId] || []),
      ];
      if (!byTasklist[task.taskListId].includes(task.id)) {
        byTasklist[task.taskListId].push(task.id);
      }
    }
  });
  state.records = { ...state.records, ...hash };
  state.subtasks = { ...state.subtasks, ...subtasksHash };
  state.tasksByTasklist = { ...state.tasksByTasklist, ...byTasklist };
};

export default {
  records: recordsMut,
  removeSubTask: (state, { id, payload: subTaskId }) => {
    if (state.subtasks[id]) {
      remove(state.subtasks[id], subTaskId);
    }
  },
  addSubTask: (
    state,
    { id, payload: { id: subTaskId, positionAfterTask } },
  ) => {
    const subtasks = state.subtasks[id] || [];
    remove(subtasks, subTaskId);
    if (positionAfterTask) {
      subtasks.splice(subtasks.indexOf(positionAfterTask) + 1, 0, subTaskId);
    } else {
      subtasks.push(subTaskId);
    }
    Vue.set(state.subtasks, id, subtasks);
  },
  // Although records mutations adds to the subtasks
  // this will reset, meaning any removed subtasks will be reflected.
  subtasks: (state, { id, payload: list }) => {
    Vue.set(state.subtasks, id, list);
  },
  resetOrderSubtasks(state, id) {
    const subtasks = state.subtasks[id] || [];
    subtasks.forEach((tid, index) => {
      state.records[tid].order = index + 1;
    });
  },
  removeFromTasklist: (state, { id, payload: taskListId }) => {
    if (state.tasksByTasklist[taskListId]) {
      remove(state.tasksByTasklist[taskListId], id);
    }
  },
  addToTasklist: (state, { id, payload: taskListId }) => {
    const byTasklist = state.tasksByTasklist[taskListId] || [];
    byTasklist.push(id);
    Vue.set(state.tasksByTasklist, taskListId, [...new Set(byTasklist)]);
  },
  // Although records mutations adds to the tasksByTasklist
  // this will reset, meaning any removed tasks will be reflected.
  tasksByTasklist: (state, { id: taskListId, payload: list }) => {
    Vue.set(state.tasksByTasklist, taskListId, list);
  },
  placeIn: (state, { id, payload: { parentTaskId, taskListId } }) => {
    const rec = state.records[id];
    if (!rec) {
      return;
    }
    rec.parentTaskId = parentTaskId;
    if (![undefined, rec.taskListId].includes(taskListId)) {
      rec.taskListId = taskListId;
      // Gather all subtasks and update them
      const queue = [...(state.subtasks[id] || [])];
      while (queue.length) {
        const next = queue.pop();
        const subRec = state.records[next];
        if (subRec) {
          subRec.taskListId = taskListId;
        }
        queue.push(...(state.subtasks[next] || []));
      }
    }
  },
  ...mapMutations({
    lsRecord: (state, lsRecord) => {
      Vue.assign(state, lsRecord);
    },
    toggleCollapsed: (state) => {
      state.expanded = !state.expanded;
    },
    changeName: (state, newName) => {
      state.content = newName;
      state.name = newName;
    },
    progress: (state, newProgress) => {
      state.progress = newProgress;
    },
    startDate: (state, startDate) => {
      state.startDate = startDate;
    },
    // Accepts just the dueDate, but also an object with both
    // dueDate and dueDateFromMilestone
    dueDate: (state, dueDate) => {
      if (dueDate && dueDate.dueDate) {
        state.dueDate = dueDate.dueDate;
        state.dueDateFromMilestone = dueDate.dueDateFromMilestone || false;
      } else {
        state.dueDate = dueDate;
        state.dueDateFromMilestone = false;
      }
    },
    orderTask: (state, order) => {
      state.order = order;
    },
    complete: (state, completedThisSession) => {
      state.status = 'completed';
      state.completedThisSession = !!completedThisSession;
      state.progress = 100;
    },
    uncomplete: (state, progress) => {
      state.status = 'reopened';
      state.completedThisSession = false;
      state.progress = progress || 0;
    },
    delete: (state) => {
      state.status = 'deleted';
    },
    undelete: (state) => {
      state.status = 'reopened';
    },
    assignedTo: (state, assignedTo) => {
      state.assignedTo = assignedTo;
    },
  }),
  clearSessionCompleted(state) {
    Object.values(state.records).forEach((rec) => {
      rec.completedThisSession = false;
    });
  },
};
