<template>
  <div
    class="
      flex
      p-4
      items-start
      gap-4
      text-default
      rounded
      max-w-[var(--token-c-alert-size-max-width)]
    "
    :class="alertConfig.alertClasses"
  >
    <component
      class="w-4 h-4 flex-none mt-0.5 fill-current"
      :is="alertConfig.icon"
    />
    <!-- @slot Default slot -->
    <slot>
      <div class="flex flex-col flex-auto items-stretch gap-1">
        <h4 class="m-0 text-default" :class="{ 'font-semibold': message }">
          {{ title }}
        </h4>
        <p v-html="message" class="text-current" />
      </div>
    </slot>
    <div class="flex gap-4 ml-auto">
      <button
        v-if="onUndo"
        type="button"
        class="
          p-2
          -m-2
          outline-none
          text-xs
          bg-transparent
          border-0
          appearance-none
        "
        @click="onUndo"
      >
        {{ $t('Undo') }}
      </button>
      <button
        v-if="dismissible"
        type="button"
        class="p-2 -m-2 outline-none bg-transparent border-0 appearance-none"
        :aria-label="$t('Dismiss alert')"
        @click="$emit('dismiss')"
      >
        <IconClose class="w-4 h-4 fill-current" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue-demi';
import { alertVariants, defaultAlertVariant } from './constants';
import IconInformation from '~icons/tw/alert-information';
import IconSuccess from '~icons/tw/alert-success';
import IconWarning from '~icons/tw/alert-warning';
import IconError from '~icons/tw/alert-error';
import IconClose from '~icons/tw/alert-close';

const alertVariantMap = {
  [alertVariants.information]: {
    icon: 'IconInformation',
    alertClasses: `bg-[color:var(--token-c-alert-color-information)] text-[color:var(--token-c-alert-text-information)]`,
  },
  [alertVariants.success]: {
    icon: 'IconSuccess',
    alertClasses: `bg-[color:var(--token-c-alert-color-success)] text-[color:var(--token-c-alert-text-success)]`,
  },
  [alertVariants.warning]: {
    icon: 'IconWarning',
    alertClasses: `bg-[color:var(--token-c-alert-color-warning)] text-[color:var(--token-c-alert-text-warning)]`,
  },
  [alertVariants.error]: {
    icon: 'IconError',
    alertClasses: `bg-[color:var(--token-c-alert-color-error)] text-[color:var(--token-c-alert-text-error)]`,
  },
};

export default {
  components: {
    IconInformation,
    IconSuccess,
    IconWarning,
    IconError,
    IconClose,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    /**
     * The alert variant.
     */
    variant: {
      type: String,
      default: defaultAlertVariant,
      validator: (value) => Object.values(alertVariants).includes(value),
    },
    /**
     * Whether the user can dismiss the alert or not.
     */
    dismissible: {
      type: Boolean,
      default: true,
    },
    /**
     * Function to trigger if the user clicks the undo button.
     */
    onUndo: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    onMounted(() => {
      if (!props.title && !props.message) {
        throw Error('Must provide `FAlert` with either `title` or `message`');
      }
    });
    const alertConfig = computed(() => alertVariantMap[props.variant]);

    return {
      alertConfig,
    };
  },
};
</script>
