<template>
  <div class="flex flex-col">
    <div class="mb-200">
      <div>
        <AddTeamNavItem class="list-item" />
        <FreeSeatsIncentiveNavItem />
        <UpgradeNavItem v-if="isHybridReady" class="list-item" />
      </div>
      <div class="mb-200 hide-smaller-height" />
      <NavItemsContainer class="flex flex-col flex-none">
        <NotificationsNavItem />
        <HelpCenterNavItem />
        <OtherAppsNavItem />
        <ChatNavItem v-if="shouldShowChat" />
      </NavItemsContainer>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import { useCurrentAccount } from '@teamwork/use';
import useStore from '@/platform/composables/useStore';
import { useHybrid } from '@/platform/composables/useHybrid';

export default {
  components: {
    UpgradeNavItem: () => import('./nav-items/UpgradeNavItem.vue'),
    AddTeamNavItem: () => import('./nav-items/AddTeamNavItem.vue'),
    NavItemsContainer: () => import('./nav-items/NavItemsContainer.vue'),
    NotificationsNavItem: () => import('./nav-items/NotificationsNavItem'),
    HelpCenterNavItem: () => import('./nav-items/HelpCenterNavItem.vue'),
    OtherAppsNavItem: () => import('./nav-items/OtherAppsNavItem.vue'),
    ChatNavItem: () => import('./nav-items/ChatNavItem.vue'),
    FreeSeatsIncentiveNavItem: () =>
      import('./nav-items/FreeSeatsIncentiveNavItem.vue'),
  },
  setup() {
    const store = useStore();
    const account = useCurrentAccount();
    const { isHybridReady } = useHybrid();
    const shouldShowChat = computed(() => {
      return account.value.chatEnabled && store.state.permissions.canAccessChat;
    });

    return {
      shouldShowChat,
      isHybridReady,
    };
  },
};
</script>

<style scoped>
.list-item {
  border-top: 1px solid #2a2f40;
}

.list-item:last-child,
.list-item:only-child {
  border-top: 1px solid #2a2f40;
  border-bottom: 1px solid #2a2f40;
}

@media screen and (max-height: 740px) {
  .hide-smaller-height {
    display: none !important;
  }
}
</style>
