import { computed, isRef } from 'vue-demi';

export default function usePeoplePicker(items) {
  const itemTypes = {
    users: 'users',
    teams: 'teams',
    companies: 'companies',
  };

  const composableName = 'usePeoplePicker';

  if (items) {
    if (!isRef(items)) {
      console.error(`${composableName} - Argument should be reactive or ref`);
    }

    if (!Array.isArray(items.value)) {
      console.error(`${composableName} - Argument should be an array`);
    }
  }

  const peoplePickerItems = computed(() =>
    isRef(items) && Array.isArray(items.value)
      ? items.value.map(
          ({
            assigneeType,
            id,
            name,
            teamLogo,
            teamLogoColor,
            teamLogoIcon,
            lastName,
            firstName,
            avatarUrl,
            isAdmin,
            canAddProjects,
            logoUrl,
          }) => {
            if (assigneeType === itemTypes.teams) {
              const teamsObject = {
                id,
                name,
                siteOwner: false,
                projectAdministrator: false,
                value: `u-${id}`,
              };
              if (teamLogo) {
                teamsObject.image = teamLogo;
              } else if (teamLogoColor && teamLogoIcon) {
                teamsObject.icon = {
                  color: teamLogoColor,
                  name: teamLogoIcon,
                };
              } else {
                teamsObject.value = `t-${id}`;
              }
              return teamsObject;
            }
            if (assigneeType === itemTypes.users) {
              return {
                id,
                name: `${firstName} ${lastName}`,
                firstName,
                lastName,
                image: avatarUrl,
                siteOwner: isAdmin,
                projectAdministrator: canAddProjects,
                value: `u-${id}`,
              };
            }
            if (assigneeType === itemTypes.companies) {
              const companyObject = {
                id,
                name,
                siteOwner: false,
                projectAdministrator: false,
              };
              if (logoUrl) {
                companyObject.image = logoUrl;
                companyObject.value = `u-${id}`;
              } else {
                companyObject.value = `c-${id}`;
              }
              return companyObject;
            }

            return null;
          },
        )
      : [],
  );

  return {
    itemTypes,
    peoplePickerItems,
  };
}
