var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row flex-nowrap items-center"},[_c('LogTimeLoggerPopover',{key:_vm.loggedTime,attrs:{"task":_vm.task,"open":_vm.isPopoverOpen,"disabled":_vm.disabled,"source":'task_details_header',"accelerator-options":_vm.isExpA15Variation
        ? [
            { label: '15m', hours: 0, minutes: 15 },
            { label: '30m', hours: 0, minutes: 30 },
            { label: '1h', hours: 1, minutes: 0 } ]
        : []},on:{"update:open":function($event){_vm.isPopoverOpen=$event},"update":function (time) { return _vm.$emit('update', time); }}}),_vm._v(" "),_c('FButtonGroup',{staticClass:"task-time-logger"},[_c('FButton',{staticClass:"flex items-center h-7 !px-2 leading-none whitespace-nowrap",attrs:{"data-identifier":"twa-task-time-logger","size":"md","align-text":"center","disabled":_vm.disabled},nativeOn:{"click":function($event){return _vm.onLogTimeClicked.apply(null, arguments)}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('CommonIcons',{staticClass:"icon text-xs leading-none",attrs:{"id":"clock","color":"white"}})]},proxy:true}])},[_vm._v("\n      "+_vm._s(_vm.loggedTime === '0h' ? _vm.$t('Log time') : _vm.$t('Log more time'))+"\n    ")]),_vm._v(" "),_c('FButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.buttonProps.tooltip,
        placement: 'bottom',
      }),expression:"{\n        content: buttonProps.tooltip,\n        placement: 'bottom',\n      }"}],staticClass:"h-7 !px-2 leading-none whitespace-nowrap",class:_vm.buttonProps.class,attrs:{"size":"md","test":true,"disabled":_vm.disabled},nativeOn:{"click":function($event){return _vm.timerToggle.apply(null, arguments)}},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('CommonIcons',{staticClass:"icon text-xs leading-none",attrs:{"id":_vm.buttonProps.icon}})]},proxy:true}])},[_vm._v(" "),(_vm.timerState !== _vm.PLAY || _vm.elapsedTime > 0)?[_c('span',{staticClass:"timer-counter"},[_vm._v("\n          "+_vm._s(_vm.formattedElapsedTime)+"\n        ")])]:_vm._e()],2)],1),_vm._v(" "),_c('span',{staticClass:"whitespace-nowrap mx-2"},[_vm._v("\n    "+_vm._s(_vm.loggedTime)+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }