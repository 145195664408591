<template>
  <PopoverMenuItem route="/settings/integrations/all" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.984.5h2.813c.388 0 .703.314.703.702v2.807a.703.703 0 0 1-.703.702h-2.813a.703.703 0 0 1-.703-.702V1.202c0-.388.315-.702.703-.702Zm.704 2.807h1.406V1.904h-1.406v1.403ZM1.203.5h2.813c.388 0 .703.314.703.702v2.807a.703.703 0 0 1-.703.702H1.203A.703.703 0 0 1 .5 4.009V1.202C.5.814.815.5 1.203.5Zm.703 2.807h1.407V1.904H1.905v1.403ZM6.594.5h2.812c.388 0 .703.314.703.702v2.807a.703.703 0 0 1-.703.702H6.594a.703.703 0 0 1-.703-.702V1.202c0-.388.315-.702.703-.702Zm.703 2.807h1.406V1.904H7.297v1.403Zm7.5 2.602h-2.813a.702.702 0 0 0-.703.702v2.807c0 .388.315.702.703.702h2.813a.703.703 0 0 0 .703-.702V6.611a.702.702 0 0 0-.703-.702Zm-.703 2.807h-1.406V7.313h1.406v1.403ZM4.016 5.91H1.203a.702.702 0 0 0-.703.702v2.807c0 .388.315.702.703.702h2.813a.703.703 0 0 0 .703-.702V6.611a.702.702 0 0 0-.703-.702Zm-.704 2.807H1.907V7.313h1.407v1.403ZM9.407 5.91H6.594a.702.702 0 0 0-.703.702v2.807c0 .388.315.702.703.702h2.812a.703.703 0 0 0 .703-.702V6.611a.702.702 0 0 0-.703-.702Zm-.703 2.807H7.297V7.313h1.406v1.403Zm6.094 2.573h-2.813a.703.703 0 0 0-.703.702v2.807c0 .388.315.702.703.702h2.813a.703.703 0 0 0 .703-.702v-2.807a.702.702 0 0 0-.703-.702Zm-.703 2.808h-1.406v-1.404h1.406v1.404ZM4.016 11.289H1.203a.702.702 0 0 0-.703.702v2.807c0 .388.315.702.703.702h2.813a.703.703 0 0 0 .703-.702v-2.807a.702.702 0 0 0-.703-.702Zm-.704 2.808H1.907v-1.404h1.407v1.404Zm6.094-2.808H6.594a.702.702 0 0 0-.703.702v2.807c0 .388.315.702.703.702h2.812a.703.703 0 0 0 .703-.702v-2.807a.703.703 0 0 0-.703-.702Zm-.703 2.808H7.297v-1.404h1.406v1.404Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Apps and integrations') }}
    </template>
  </PopoverMenuItem>
</template>

<script>
import PopoverMenuItem from './PopoverMenuItem.vue';

export default {
  components: {
    PopoverMenuItem,
  },
};
</script>

<style></style>
