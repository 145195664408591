<template>
  <component
    :is="
      !hasQuickView && hasLink && disableSubElementClick ? 'router-link' : 'div'
    "
    class="notifications-list-item"
    :class="{
      'notifications-list-item--loading': loading,
      'notifications-list-item--read': !loading && notification.read,
      'notifications-list-item--unread': !loading && !notification.read,
      'notifications-list-item--background-change':
        !loading && isWholeNotificationClickable,
    }"
    :data-identifier="`${dataIdentifierPrefix}-notification`"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click.prevent="onNotificationClicked"
    v-bind="
      !hasQuickView && hasLink && disableSubElementClick
        ? {
            to: href,
          }
        : {}
    "
  >
    <div class="notifications-list-item__avatar">
      <Avatar :src="avatar" :size="40" />
      <EntityIcon
        v-bind="iconArgs"
        :size="20"
        class="notifications-list-item__avatar-icon"
      />
    </div>

    <div class="notifications-list-item__text">
      <div class="notifications-list-item__text-1" v-if="text1 || loading">
        <template v-if="!loading">
          <div class="notifications-list-item__text-1-inner">
            <div class="notifications-list-item__text-1-text">
              {{ text1 }}
            </div>

            <transition name="fade">
              <IconButton
                v-if="hasQuickView && !disableSubElementClick"
                v-show="shouldShowQuickView"
                v-tooltip="{
                  content: $t('Quick view'),
                  placement: 'top',
                  boundariesElement: 'document.body',
                }"
                icon="quickview"
                @click.native="onQuickViewClicked"
                class="notifications-list-item__text-1-quick-view"
                component="projects-icon"
                color="#646D7D"
                :data-identifier="`${dataIdentifierPrefix}-notification-quick-view`"
                tabindex="-1"
              />
            </transition>
          </div>
        </template>
      </div>

      <div class="notifications-list-item__text-2" v-if="text2 || loading">
        <template v-if="!loading">
          <template v-if="hasLink && !disableSubElementClick">
            <router-link
              :to="href"
              :title="text2"
              @click.native="onLinkClicked"
              :data-identifier="`${dataIdentifierPrefix}-notification-link`"
            >
              {{ text2 }}
            </router-link>
          </template>
          <template v-else>
            <span :title="text2">
              {{ text2 }}
            </span>
          </template>
        </template>
      </div>
      <div class="notifications-list-item__text-3" v-if="text3 || loading">
        <template v-if="!loading">
          <span :title="text3">
            {{ text3 }}
          </span>
        </template>
      </div>
    </div>

    <div class="notifications-list-item__meta">
      <div class="notifications-list-item__meta-inner">
        <template v-if="!loading">
          <span
            class="notifications-list-item__meta-time"
            :title="notification.date.format(`dddd, DD MMM YYYY ${timeFormat}`)"
          >
            {{ notification.date.format(timeFormat) }}
          </span>
        </template>
        <template v-else>
          <span class="notifications-list-item__meta-time" />
        </template>

        <template v-if="!loading">
          <div class="notifications-list-item__meta-status">
            <button
              class="notifications-list-item__meta-status-button"
              @click.stop="onStatusClicked"
              :data-identifier="`${dataIdentifierPrefix}-notification-mark-${
                !notification.read ? 'read' : 'unread'
              }`"
            >
              <ProjectsIcon
                v-tooltip="{
                  content: !notification.read
                    ? $t('Mark as read')
                    : $t('Mark as unread'),
                  placement: 'top',
                  boundariesElement: 'document.body',
                }"
                class="notifications-list-item__meta-status-icon"
                :id="notification.read ? 'check-circle' : 'check-circle-solid'"
                tabindex="-1"
              />
            </button>
          </div>
        </template>
        <template v-else>
          <div class="notifications-list-item__meta-status">
            <div class="notifications-list-item__meta-status-button"></div>
          </div>
        </template>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from 'vuex';
import { computed, inject, shallowRef, unref, onBeforeUnmount } from 'vue-demi';
import { isString } from 'lodash-es';
import ProjectsIcon from '@teamwork/common-icons/dist/v-projects-icon';
import { useCurrentUser } from '@teamwork/use';

import Avatar from '@widgets/Avatar';
import IconButton from '@widgets/IconButton';
import EntityIcon from '@widgets/EntityIcon';
import { useNotificationsManager } from '@/components/sections/Notifications/useNotificationsManager';
import { getLinkForNotificationItem } from '@/utils/helpers/notifications';

export default {
  name: 'NotificationsListItem',
  props: {
    notification: {
      type: Object,
      required: true,
    },
    dataIdentifierPrefix: {
      type: [String, Number],
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /*
     * Determines whether or not entire notification items should be clickable or
     * their sub-elements i.e. quick-view buttons and title links
     */
    disableSubElementClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Avatar,
    IconButton,
    EntityIcon,
    ProjectsIcon,
  },
  setup(props, { emit }) {
    const currentUser = useCurrentUser();
    const NotificationsManager = useNotificationsManager();
    const popoverId = unref(inject('popoverId'));
    const context = inject('context', null);

    const isNotificationHovered = shallowRef(false);

    const avatar = computed(() => {
      if (!props.notification || props.loading) {
        return undefined;
      }

      return (
        (props.notification.user && props.notification.user.avatar) || undefined
      );
    });

    const iconArgs = computed(() => {
      if (!props.notification || props.loading) {
        return {
          itemType: undefined,
          reactionType: undefined,
        };
      }

      return {
        itemType: props.notification.group,
        reactionType: props.notification.reaction,
      };
    });

    const title = computed(() => {
      if (!props.notification || props.loading) {
        return undefined;
      }

      return (
        isString(props.notification.title) && props.notification.title.trim()
      );
    });

    const description = computed(() => {
      if (!props.notification || props.loading) {
        return undefined;
      }

      return (
        isString(props.notification.description) &&
        props.notification.description.trim()
      );
    });

    const href = computed(() => {
      if (!props.notification || props.loading) {
        return undefined;
      }

      return getLinkForNotificationItem(currentUser, props.notification);
    });

    const text1 = computed(() => {
      if (!props.notification || props.loading) {
        return undefined;
      }

      return isString(title.value) ? title.value.trim() : undefined;
    });

    const text2 = computed(() => {
      if (!props.notification || props.loading) {
        return undefined;
      }

      if (href.value) {
        return isString(description.value)
          ? description.value.trim()
          : 'View here';
      }

      return isString(description.value) ? description.value.trim() : undefined;
    });

    const text3 = computed(() => {
      if (!props.notification || props.loading) {
        return undefined;
      }

      const parts = [];
      if (
        props.notification.extra &&
        isString(props.notification.extra.description) &&
        props.notification.extra.description &&
        props.notification.action !== 'reacted'
      ) {
        parts.push(props.notification.extra.description.trim());
      }

      if (props.notification.project && props.notification.project.name) {
        parts.push(props.notification.project.name);
      }

      return parts.join(' - ') || undefined;
    });

    const hasLink = computed(() => {
      return !!href.value;
    });

    const hasQuickView = computed(() => {
      if (!props.notification || props.loading) {
        return false;
      }

      const typesToExclude = [
        'event',
        'integration',
        'status',
        'statusrequest',
        'statusupdate',
        'project',
      ];

      return !typesToExclude.includes(
        props.notification.item.type.toLowerCase(),
      );
    });

    const shouldShowQuickView = computed(() => {
      return isNotificationHovered.value;
    });

    const isWholeNotificationClickable = computed(() => {
      return !!(
        props.disableSubElementClick &&
        (hasQuickView.value || href.value)
      );
    });

    function onQuickViewClicked(event) {
      event?.stopPropagation();

      if (!props.notification || props.loading) {
        return;
      }

      if (!hasQuickView.value) {
        return;
      }

      NotificationsManager.quickView(
        props.notification,
        popoverId,
        context === 'inbox',
      );
    }

    function onQuickViewKeyUp(event) {
      event?.stopPropagation();

      if (event.which === 86) {
        onQuickViewClicked();
      }
    }

    function onLinkClicked(event) {
      event?.preventDefault();
      event?.stopPropagation();

      if (!props.notification || props.loading) {
        return;
      }

      if (!hasLink.value) {
        return;
      }

      NotificationsManager.navigate(
        props.notification,
        event.metaKey || event.ctrlKey,
      );
    }

    function onNotificationClicked(event) {
      event.stopPropagation();

      if (!props.notification || props.loading) {
        return;
      }

      if (!isWholeNotificationClickable.value) {
        return;
      }

      // Note: when the whole notification is clickable, we give preference to quick-view over the link
      if (hasQuickView.value) {
        onQuickViewClicked(event);
        return;
      }

      if (hasLink.value) {
        onLinkClicked(event);
        return;
      }

      // Note: there's some special notifications which don't have a link or a quick-view
      NotificationsManager.access(props.notification);
    }

    function onStatusClicked(event) {
      event?.preventDefault();
      event?.stopPropagation();

      if (!props.notification || props.loading) {
        return;
      }

      NotificationsManager.toggleNotificationRead(props.notification);

      emit('status-click');
    }

    function onMouseEnter() {
      isNotificationHovered.value = true;
      window.addEventListener('keyup', onQuickViewKeyUp);
    }

    function onMouseLeave() {
      isNotificationHovered.value = false;
      window.removeEventListener('keyup', onQuickViewKeyUp);
    }

    onBeforeUnmount(() => {
      window.removeEventListener('keyup', onQuickViewKeyUp);
    });

    return {
      avatar,
      iconArgs,
      href,
      text1,
      text2,
      text3,
      hasQuickView,
      shouldShowQuickView,
      isWholeNotificationClickable,
      hasLink,
      onNotificationClicked,
      onQuickViewClicked,
      onQuickViewKeyUp,
      onLinkClicked,
      onStatusClicked,
      onMouseEnter,
      onMouseLeave,
      popoverId,
    };
  },
  computed: {
    ...mapGetters({
      timeFormat: 'user/timeFormat',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
@import '~@tko/src/styles/mixins/mixins';
@import '~@tko/src/styles/variables/dimensions/_typography.dimensions';
@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

.notifications-list-item {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 4px;
  position: relative;
  border-radius: 4px;
  content-visibility: auto;

  &:last-of-type {
    margin-bottom: 16px;
  }
}

a.notifications-list-item {
  &:hover {
    text-decoration: none;
  }
}

.notifications-list-item--background-change {
  &:hover {
    cursor: pointer;
    background-color: #f5f7fa; // Use 'Neutral 10' design token when available
  }
}

.notifications-list-item__avatar {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.notifications-list-item__avatar-icon {
  box-shadow: 0px 0px 8px rgba(26, 26, 26, 0.1);
  border: 1px solid #ffffff; // Use 'White' design token when available
  position: absolute;
  bottom: -4px;
  right: -4px;
}

.notifications-list-item__text {
  flex-grow: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  min-width: 0;
  margin-right: 16px;
}

.notifications-list-item__text-1 {
  height: 20px;
  display: flex;
  align-items: center;
  color: #0b0e1f; // Use 'Neutral 60' design token when available
}

.notifications-list-item__text-1-inner {
  display: flex;
  position: relative;
}

.notifications-list-item__text-1-text {
  color: #646d7d; // Use 'Neutral 50' design token when available
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  margin-right: 8px;
}

.notifications-list-item__text-1-quick-view {
  position: absolute;
  right: -28px;
  top: -4px;
}

.notifications-list-item__text-2 {
  height: 20px;
  color: #0b0e1f; // Use 'Neutral 60' design token when available
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;

  a {
    color: inherit;
  }

  .notification-icon {
    padding-right: 2px;
  }
}

.notifications-list-item--unread {
  .notifications-list-item__text-1-text,
  .notifications-list-item__text-2 {
    font-weight: 600;
  }
}

.notifications-list-item__text-3 {
  height: 20px;
  margin-bottom: 0;
  color: #646d7d; // Use 'Neutral 50' design token when available
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notifications-list-item__meta {
  display: flex;
  height: 60px;
  align-items: flex-start;
  flex: 0 0 auto;
}

.notifications-list-item__meta-inner {
  display: flex;
  align-items: center;
  height: 20px;
}

.notifications-list-item__meta-time {
  display: inline-flex;
  color: #646d7d; // Use 'Neutral 50' design token when available
  font-style: normal;
  font-weight: normal;
  font-size: $font-size--small;
  line-height: 1;
  flex: 0 0 auto;
}

.notifications-list-item__meta-status {
  display: inline-flex;
  margin-left: 8px;
}

.notifications-list-item__meta-status-button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  width: 14px;
  height: 14px;
}

.notifications-list-item__meta-status-icon {
  width: 14px;
  height: 14px;
  max-width: 14px;
  transition-property: transform, fill;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  fill: #4461d7; // Use 'Indigo 40' design token when available

  &:hover {
    transform: scale(1.2);
    fill: #3c55bd; // Use 'Indigo 50' design token when available
  }
}

a {
  &:hover {
    text-decoration: underline;
  }
}

.notifications-list-item--read {
  .notifications-list-item__meta-status-icon {
    fill: #646d7d; // Use 'Neutral 50' design token when available

    &:hover {
      fill: #8f9bb3; // Use 'Neutral 40' design token when available
    }
  }
}

.notifications-list-item--loading {
  background-color: transparent;

  .notifications-list-item__avatar {
    background: transparent;

    .w-avatar {
      @include skeleton(true);

      background: transparent !important;

      ::v-deep img {
        display: none;
      }
    }

    .w-entity-icon {
      @include skeleton(true);

      position: absolute;
      border: none;
      background: transparent !important;
    }
  }

  .notifications-list-item__text-1 {
    @include skeleton(true);

    width: 33%;
  }

  .notifications-list-item__text-2 {
    @include skeleton(true);

    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .notifications-list-item__text-3 {
    @include skeleton(true);

    width: 66%;
  }

  .notifications-list-item__meta-time {
    @include skeleton(true);

    width: 50px;
    height: 100%;
  }

  .notifications-list-item__meta-status-button {
    @include skeleton(true);

    border-radius: 999999px;

    svg {
      opacity: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
