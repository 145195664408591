import { ref, computed, onMounted } from 'vue-demi';
import api, { API_PREFIXES } from '@/services/api';
import useStore from '@/platform/composables/useStore';
import ProductTours from '../../../data/onboarding/tours/ProductTours';

const productOutcomes = ref([]);
const isLoading = ref(false);
const showCheckList = ref(false);
const outcomesTours = ref([
  {
    outcomeId: 1,
    associatedTours: [1, 2, 6],
  },
  {
    outcomeId: 2,
    associatedTours: [1, 2, 11],
  },
  {
    outcomeId: 3,
    associatedTours: [1, 2, 3],
  },
  {
    outcomeId: 4,
    associatedTours: [1, 2, 4],
  },
  {
    outcomeId: 5,
    associatedTours: [1, 2, 10],
  },
  {
    outcomeId: 6,
    associatedTours: [1, 2, 5],
  },
]);

const precheckedItems = [1, 2];
const minNumberOfChecklistItems = 6;

export default function useOnboardingChecklist() {
  const store = useStore();

  function fetchUserProductOutcomes() {
    isLoading.value = true;
    return api
      .get(
        `${API_PREFIXES.v3}/users/${store.state.user.id}/productoutcomes.json`,
      )
      .then((result) => {
        return result.data.productOutcomes;
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  async function initaliseProductoutcomes() {
    // if you are disabling the checklist / product outcomes. Remove this function in onboardingProjectContainer.js
    productOutcomes.value = await fetchUserProductOutcomes();
  }

  async function dismissOnboardingChecklist() {
    store.commit('preferences/change', {
      prefs: {
        onboardingChecklistDismissed: true,
      },
      scope: 'user',
    });

    await store.dispatch('preferences/persist', {
      prefs: {
        onboardingChecklistDismissed: true,
      },
      scope: 'user',
    });
  }

  function changeShowChecklistStatus(status) {
    showCheckList.value = status;
  }

  const isCheckListEnabled = computed(() => {
    return showCheckList.value;
  });

  const productOutcomeTours = computed(() => {
    const filterByOutcomeId = outcomesTours.value.filter((x) =>
      productOutcomes.value.some((t) => t.id === x.outcomeId),
    );
    return Object.values(ProductTours).filter((x) =>
      filterByOutcomeId.some((t) => t.associatedTours.includes(x.id)),
    );
  });

  const importAndIntegrationTours = computed(() => {
    const tourList = [];
    if (store.state.preferences.user.importOnboardingApp?.length > 0) {
      tourList.push(ProductTours.importData);
    }
    if (store.state.preferences.user.integrateOnboardingApp?.length > 0) {
      tourList.push(ProductTours.integrateApps);
    }
    return tourList;
  });

  const defaultTours = computed(() => {
    const defaultTourList = [];
    defaultTourList.push(
      ProductTours.createProjectTour,
      ProductTours.createTasksTour,
      ProductTours.managingProjectsTour,
    );
    return defaultTourList;
  });

  const allToursWithDefaults = computed(() => {
    const allChecklistProductTours = [...productOutcomeTours.value];
    if (allChecklistProductTours.length <= 0) {
      allChecklistProductTours.push(...defaultTours.value);
    }
    allChecklistProductTours.push(...importAndIntegrationTours.value);
    if (allChecklistProductTours.length < minNumberOfChecklistItems) {
      allChecklistProductTours.push(ProductTours.inviteTeamTour);
    }
    return allChecklistProductTours;
  });

  const checklistItems = computed(() => {
    return allToursWithDefaults.value.map((item) => ({
      ...item,
      completed:
        store.state.preferences.user.productToursCompleted?.includes(item.id) ||
        precheckedItems.includes(item.id),
    }));
  });

  const completedItems = computed(() => {
    return checklistItems.value.filter((x) => x.completed);
  });

  const incompleteItems = computed(() => {
    return checklistItems.value.filter((x) => !x.completed);
  });

  const isChecklistComplete = computed(() => {
    return incompleteItems.value.length <= 0;
  });

  const isChecklistDismissed = computed(() => {
    return store.state.preferences.user.onboardingChecklistDismissed;
  });

  const taskProgress = computed(() => {
    const completed = completedItems.value.length;
    return `${Math.round((completed / checklistItems.value.length) * 100)}%`;
  });

  onMounted(initaliseProductoutcomes);

  const isSmallDisplay = computed(() => {
    return store.state.responsive.width < 992;
  });

  return {
    productOutcomes,
    checklistItems,
    completedItems,
    incompleteItems,
    productOutcomeTours,
    isChecklistComplete,
    taskProgress,
    isLoading,
    isChecklistDismissed,
    dismissOnboardingChecklist,
    initaliseProductoutcomes,
    isSmallDisplay,
    isCheckListEnabled,
    changeShowChecklistStatus,
  };
}
