import Vue from 'vue';
import { getCustomFieldIconByType } from '@/utils/helpers/customFields';

const DEFAULT_SORT = 'name';
const DEFAULT_SORT_MODE = 'asc';

const PROJECT_STATUSES = {
  ALL: 'all',
  CURRENT: 'current',
  ACTIVE: 'active',
  LATE: 'late',
  DELETED: 'deleted',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
  STARRED: 'starred',
  ARCHIVED: 'archived',
};
const statuses = () => [
  { name: Vue.t('Active'), id: 0, icon: 'bolt', key: 'active' },
  { name: Vue.t('Current'), id: 1, icon: 'chart-line', key: 'current' },
  { name: Vue.t('Late'), id: 2, icon: 'error-tw', key: 'late' },
  { name: Vue.t('Upcoming'), id: 3, icon: 'calendar', key: 'upcoming' },
  { name: Vue.t('Completed'), id: 4, icon: 'check-circle', key: 'completed' },
  { name: Vue.t('Archived'), id: 5, icon: 'file-check', key: 'archived' },
];

const CHIP_TYPES = {
  TAG: 0,
  CATEGORY: 1,
  COMPANY: 2,
  HEALTH: 3,
  EXCLUDED_TAG: 4,
  OWNER: 5,
};

const CUSTOM_FIELD_TYPES = {
  TEXT: 'text-short',
  NUMBER: 'number-integer',
  DROPDOWN: 'dropdown',
  STATUS: 'status',
  DATE: 'date',
  URL: 'url',
  CHECKBOX: 'checkbox',
};

const CUSTOM_FIELD_OPERATORS = {
  [CUSTOM_FIELD_TYPES.TEXT]: {
    CONTAINS: 'like',
    MATCHES: 'eq',
    STARTS: 'starts',
    ENDS: 'ends',
    NOT: 'not-like',
  },
  [CUSTOM_FIELD_TYPES.NUMBER]: {
    MATCHES: 'eq',
    SMALLER: 'lt',
    BIGGER: 'gt',
    RANGE: 'range',
  },
  [CUSTOM_FIELD_TYPES.DROPDOWN]: {
    MATCHES: 'eq',
    ANY: 'any',
    NOT: 'not',
  },
  [CUSTOM_FIELD_TYPES.DATE]: {
    ANYTIME: 'anytime',
    YESTERDAY: 'yesterday',
    TODAY: 'today',
    THISWEEK: 'thisweek',
    LASTWEEK: 'lastweek',
    THISMONTH: 'thismonth',
    LASTMONTH: 'lastmonth',
    LAST3MONTHS: 'last3months',
    LAST6MONTHS: 'last6months',
    WITHINPREV: 'withinprev',
    CUSTOM: 'custom',
  },
  [CUSTOM_FIELD_TYPES.STATUS]: {
    MATCHES: 'eq',
    ANY: 'any',
    NOT: 'not',
  },
  [CUSTOM_FIELD_TYPES.URL]: {
    CONTAINS: 'like',
    MATCHES: 'eq',
    STARTS: 'starts',
    ENDS: 'ends',
    NOT: 'not-like',
  },
  [CUSTOM_FIELD_TYPES.CHECKBOX]: {
    MATCHES: 'eq',
  },
};

const SYMBOLS = {
  PARAMS: Symbol('params'),
  FILTER_REPOSITION: Symbol('repositionSavedFilters'),
  ACTIVE_FILTER_NUMBER: Symbol('activeFilterNumber'),
  CUSTOM_FIELDS: Symbol('projectCustomField'),
  PREPARE_PAYLOAD: Symbol('prepareFilterPayload'),
  STATUS_DISPLAY: Symbol('statusAndDisplay'),
  SAVED_FILTER: Symbol('savedFilter'),
  UPDATE_PARAM: Symbol('updateParam'),
  CUSTOM_FIELDS_LIST: Symbol('customFieldsList'),
  FILTER_SYMBOL: Symbol('table-tasks-filter'),
};

const projectHeaders = ({
  budgetAvailable = true,
  companyEnabled = true,
  customFields = [],
  tagsEnabled = true,
  workflowsEnabled = false,
}) =>
  [
    {
      id: 'starred-name',
      name: Vue.t('Project Name'),
      sortKey: 'name',
      width: '400px',
      minWidth: '300px',
      sticky: true,
      draggable: false,
      toggleable: false,
    },
    {
      id: 'owner',
      name: Vue.t('Owner'),
      icon: 'owner-light',
      sortKey: 'ownerName',
      width: '100px',
      resizable: false,
      headerCellCenter: true,
    },
    {
      id: 'company',
      name: Vue.t('Company'),
      icon: 'company-light',
      sortKey: 'companyName',
      width: '122px',
      enabled: companyEnabled,
    },
    {
      id: 'startDate',
      name: Vue.t('Start Date'),
      icon: 'calendar-light',
      sortKey: 'startDate',
      width: '122px',
      resizable: false,
    },
    {
      id: 'endDate',
      name: Vue.t('End Date'),
      icon: 'calendar-light',
      sortKey: 'endDate',
      width: '122px',
      resizable: false,
    },
    workflowsEnabled && {
      id: 'workflow',
      name: Vue.t('Workflow'),
      icon: 'view-column',
      width: '232px',
      tooltip: Vue.t(
        'Define the stages a task goes through across multiple projects and see those tasks together on a combined workflow board. Workflows can be managed from your site settings area.',
      ),
    },
    tagsEnabled && {
      id: 'tags',
      name: Vue.t('Tags'),
      icon: 'tag-light',
      width: '320px',
      tooltip: Vue.t(
        'To see tags that are out of frame hover over the tag cell and scroll or resize the column.',
      ),
    },
    budgetAvailable && {
      id: 'budget',
      name: Vue.t('Budget'),
      icon: 'circle-dollar-light',
      width: '152px',
    },
    {
      id: 'latestActivity',
      name: Vue.t('Latest Activity'),
      icon: 'bolt-light',
      width: '200px',
      sortKey: 'lastActivityDate',
    },
    {
      id: 'health',
      name: Vue.t('Health'),
      icon: 'heartbeat-light',
      sortKey: 'health',
      width: '170px',
      enabled: false,
      resizable: true,
    },
    {
      id: 'dateCreated',
      name: Vue.t('Date Created'),
      icon: 'calendar-light',
      sortKey: 'createdOn',
      width: '200px',
      enabled: false,
      resizable: false,
    },
    {
      id: 'board',
      name: Vue.t('Board'),
      icon: 'columns-light',
      width: '200px',
      enabled: false,
      resizable: false,
    },
    {
      id: 'category',
      name: Vue.t('Category'),
      icon: 'folder-light',
      width: '200px',
      enabled: false,
      sortKey: 'categoryName',
    },
    {
      id: 'latestUpdate',
      name: Vue.t('Latest Update'),
      icon: 'bolt-light',
      width: '200px',
      enabled: false,
    },
    {
      id: 'description',
      name: Vue.t('Description'),
      icon: 'description-tw-alt-light',
      width: '200px',
      enabled: false,
    },
    {
      id: 'lastWorkedOn',
      name: Vue.t('Last Worked On'),
      icon: 'user-edit-light',
      width: '200px',
      enabled: false,
      sortKey: 'lastWorkedOn',
    },
    ...customFields.map((cf) => ({
      id: `custom-field-${cf.id}`,
      name: cf.name,
      icon: getCustomFieldIconByType(cf.type),
      width: '144px',
      order: Number.MAX_SAFE_INTEGER,
      enabled: false,
      customField: cf,
      sortKey: `customField-${cf.id}`,
    })),
  ].filter(Boolean);

export {
  DEFAULT_SORT,
  DEFAULT_SORT_MODE,
  SYMBOLS,
  PROJECT_STATUSES,
  CHIP_TYPES,
  CUSTOM_FIELD_TYPES,
  CUSTOM_FIELD_OPERATORS,
  projectHeaders,
  statuses,
};
