var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content:
      _vm.numAttachments === 1
        ? _vm.$t("[0] file attached", _vm.numAttachments)
        : _vm.$t("[0] files attached", _vm.numAttachments),
    placement: 'top',
    boundariesElement: 'document.body',
  }),expression:"{\n    content:\n      numAttachments === 1\n        ? $t(`[0] file attached`, numAttachments)\n        : $t(`[0] files attached`, numAttachments),\n    placement: 'top',\n    boundariesElement: 'document.body',\n  }"}],staticClass:"table-cell-task-attachments"},[_c('button',{staticClass:"attachment-btn",on:{"click":_vm.openQuickView}},[_c('span',{staticClass:"cursor--pointer attachments-count"},[_c('CommonIcon',{staticClass:"u-common-icon",attrs:{"id":"paperclip-light","color":"#0B0E1F"}}),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.numAttachments)+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }