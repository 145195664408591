<template>
  <div class="flex flex-col gap-3 w-52 p-4">
    <FAvatarMenuItem
      v-for="user in visibleUsers"
      :key="user.id"
      :name="user.name"
      size="sm"
      :has-tooltip="false"
      v-on="
        Boolean($listeners.click)
          ? { click: handleAssigneeClick(user) }
          : undefined
      "
    />
    <template v-if="hiddenUsers.length">
      <div class="bg-divider h-px" />
      <button
        type="button"
        class="text-default font-semibold text-left p-0 bg-transparent border-0"
        @click="$emit('show-more')"
      >
        {{ moreLabel ? moreLabel : `+${hiddenUsers.length} ${$t('more')}` }}
      </button>
    </template>
  </div>
</template>
<script>
import { computed } from 'vue-demi';
import FAvatarMenuItem from '../FAvatarMenuItem/FAvatarMenuItem.vue';

export default {
  name: 'FAvatarMenu',
  components: {
    FAvatarMenuItem,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
      validator(value) {
        if (!value.length) return true;
        let valid = true;
        const ids = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < value.length; i++) {
          if (!value[i].name || !value[i].id) {
            console.error('User is missing required info (name, id)', value[i]);
            valid = false;
          }
          if (ids.includes(value[i].id)) {
            console.error('Duplicated user id', value[i]);
            valid = false;
          }
          ids.push(value[i].id);
        }

        if (!valid) {
          console.error('Invalid users array specified');
        }

        return valid;
      },
    },
    max: {
      type: Number,
      default: 4,
    },
    moreLabel: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const visibleUsers = computed(() => {
      return props.users.slice(0, props.max);
    });

    const hiddenUsers = computed(() => {
      return props.users.slice(props.max);
    });

    const handleAssigneeClick = (user) => {
      emit('click', user);
    };

    return { visibleUsers, hiddenUsers, handleAssigneeClick };
  },
};
</script>
