<template>
  <PopoverMenuActionItem @click="toggleClockInOut" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="20"
        viewBox="0 0 16 20"
      >
        <path
          d="m14.32 6.845.841-.84a.447.447 0 0 0 0-.633l-.632-.632a.447.447 0 0 0-.632 0l-.77.77a7.686 7.686 0 0 0-4.234-1.89V1.786h1.042c.245 0 .446-.201.446-.447V.446c0-.245-.2-.446-.446-.446h-3.87C5.82 0 5.62.2 5.62.446v.893c0 .246.201.447.447.447h1.041v1.838A7.736 7.736 0 0 0 .262 11.31 7.737 7.737 0 0 0 8 19.047a7.737 7.737 0 0 0 6.32-12.203ZM8 17.262a5.95 5.95 0 0 1-5.952-5.953A5.95 5.95 0 0 1 8 5.357a5.95 5.95 0 0 1 5.952 5.952A5.95 5.95 0 0 1 8 17.262Zm.446-4.167h-.892a.448.448 0 0 1-.447-.446v-5.06c0-.245.201-.446.447-.446h.892c.246 0 .447.2.447.446v5.06c0 .245-.201.446-.447.446Z"
        />
      </svg>
    </template>
    <template #title>
      {{ isUserClockedIn ? $t('Clock Out') : $t('Clock In') }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import { computed } from 'vue-demi';
import useStore from '@/platform/composables/useStore';
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';

export default {
  components: {
    PopoverMenuActionItem,
  },
  setup() {
    const store = useStore();
    const isUserClockedIn = computed(() => store.state.user.isClockedIn);

    const toggleClockInOut = () => {
      store.dispatch('user/toggleClockIn');
    };

    return {
      toggleClockInOut,
      isUserClockedIn,
    };
  },
};
</script>

<style></style>
