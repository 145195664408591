<template>
  <div :class="avatarClasses" :style="avatarStyles">
    <div v-if="hasTooltip" :class="tooltipClasses">{{ name }}</div>
    <span v-if="isInitials">
      {{ initials }}
    </span>
    <slot />
    <div v-if="isAvatar" class="w-full h-full rounded-full overflow-hidden">
      <img :src="url" :alt="name" class="w-full object-cover" />
    </div>
    <div v-if="$slots.badge" :class="badgeClasses">
      <slot name="badge" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import { avatarProps, useCommonAvatar } from './avatarCommon';
import { avatarVariants } from './consts';

export default {
  name: 'FAvatar',
  props: { ...avatarProps },
  setup(props, { slots }) {
    const {
      initials,
      avatarClasses,
      tooltipClasses,
      badgeClasses,
      avatarStyles,
    } = useCommonAvatar(props, Boolean(slots.default));

    const isInitials = computed(() => {
      return props.variant === avatarVariants.initials && !slots.default;
    });

    const isAvatar = computed(() => {
      return props.variant === avatarVariants.avatar && !slots.default;
    });

    return {
      initials,
      avatarClasses,
      tooltipClasses,
      badgeClasses,
      avatarStyles,
      isInitials,
      isAvatar,
    };
  },
};
</script>
<style scoped>
.avatar:hover .avatar-tooltip {
  display: block;
}
.avatar-tooltip {
  bottom: var(--token-c-tooltips-size-bottom);
  display: none;
}

.avatar > svg {
  /* the SVG in the designs has a proportion of 2.5 over the width of the avatar */
  width: var(--token-c-tooltips-size-svg-size);
  height: var(--token-c-tooltips-size-svg-size);
}
</style>
