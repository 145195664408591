<template>
  <FFabButton
    class="button-collapse-expand !absolute bottom-[26px] z-10"
    :class="classes"
    size="sm"
    v-tooltip="{
      content: tooltipText,
      placement: 'right',
      classes: 'w-tooltip w-tooltip--controls-without-text',
    }"
    @click.stop="onToggleSidebar"
  >
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :style="{
        transition: 'transform 200ms ease-in-out',
        transform: shouldMinimizeSidebar
          ? `rotate(${0}deg)`
          : `rotate(${180}deg)`,
      }"
    >
      <path
        d="M7.29289 6.38628L1.92545 11.6717C1.7113 11.8859 1.36502 11.8859 1.15087 11.6717L0.827361 11.3482C0.61321 11.134 0.61321 10.7878 0.827361 10.5736L5.48856 5.99898L0.831918 1.42436C0.617767 1.2102 0.617767 0.863918 0.831918 0.649767L1.15542 0.326263C1.36957 0.112112 1.71586 0.112112 1.93001 0.326263L7.29745 5.61169C7.50704 5.82584 7.50704 6.17213 7.29289 6.38628Z"
        fill="#F5F7FA"
      />
    </svg>
  </FFabButton>
</template>

<script>
import { computed } from 'vue-demi';
import { FFabButton } from '@fragments';
import { usePreferences } from '@/platform/composables/usePreferences';
import useTranslations from '@/platform/composables/useTranslations';

export default {
  components: {
    FFabButton,
  },
  setup() {
    const $t = useTranslations();
    const { shouldMinimizeSidebar } = usePreferences();
    const classes = computed(() =>
      shouldMinimizeSidebar.value ? 'left-[72px]' : 'left-[196px]',
    );
    const tooltipText = computed(() =>
      shouldMinimizeSidebar.value ? $t('Expand') : $t('Collapse'),
    );

    function onToggleSidebar() {
      shouldMinimizeSidebar.value = !shouldMinimizeSidebar.value;
    }

    return {
      classes,
      shouldMinimizeSidebar,
      onToggleSidebar,
      tooltipText,
    };
  },
};
</script>

<style scoped>
.button-collapse-expand {
  background-color: #232845 !important;
}

.button-collapse-expand:hover {
  background-color: var(--token-color-primary) !important;
}
</style>
