var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assignees",class:[
    { 'is-disabled': _vm.disabled },
    _vm.canEdit ? 'cursor-pointer' : 'cursor-default' ],style:(_vm.cssVars),on:{"click":function($event){return _vm.$emit('click')}}},[_vm._l((_vm.assigneesTeamsAndCompanies.slice(
      0,
      _vm.visibleAssignees
    )),function(item,key){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.buildTooltipContent(item),
      placement: 'top',
      boundariesElement: 'viewport',
      container: 'body',
    }),expression:"{\n      content: buildTooltipContent(item),\n      placement: 'top',\n      boundariesElement: 'viewport',\n      container: 'body',\n    }"}],key:key,staticClass:"assignee",class:{ 'read-only': _vm.disabled },style:({
      zIndex: key,
      '--assignee-bubble-order': key,
    }),attrs:{"tabindex":"0"}},[(item.assigneeType === 'companies')?[(_vm.isAvatarCompany(item))?_c('img',{attrs:{"src":item.companyLogoURL || item.logoUrl,"title":item.companyName || item.name}}):_c('span',{staticClass:"company",attrs:{"title":item.companyName || item.name}},[_c('CommonIcons',{attrs:{"id":"company","color":"#999"}})],1)]:(item.assigneeType === 'teams')?[(item.teamLogo && item.teamLogo.length)?_c('img',{attrs:{"src":item.teamLogo,"title":item.teamName || item.name}}):_vm._e(),_vm._v(" "),(!item.teamLogo.length)?_c('span',{style:({ backgroundColor: item.teamLogoColor }),attrs:{"title":item.teamName || item.name}},[(item.teamLogoIcon)?_c('CommonIcons',{attrs:{"id":item.teamLogoIcon,"color":"white"}}):_c('span',[_vm._v(_vm._s(item.teamName.substr(0, 2)))])],1):_vm._e()]:[(_vm.hasAvatar(item))?_c('img',{attrs:{"src":item.avatarUrl,"alt":((item.firstName) + " " + (item.lastName))}}):_c('span',{style:({
          backgroundColor: _vm.strToColor(("" + (item.firstName) + (item.lastName))),
        }),attrs:{"title":((item.firstName) + " " + (item.lastName))}},[_vm._v("\n        "+_vm._s(_vm.initials(item))+"\n      ")])]],2)}),_vm._v(" "),(_vm.assigneesTeamsAndCompanies.length > _vm.visibleAssignees)?_c('div',{staticClass:"assignees__popover-extra",class:_vm.canEdit ? 'cursor-pointer' : 'cursor-default',style:({
      zIndex: _vm.assigneesTeamsAndCompanies.length,
      '--assignee-bubble-order': _vm.visibleAssignees,
    })},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.extraAssigneesTooltipContent,
        html: true,
        placement: 'top',
      }),expression:"{\n        content: extraAssigneesTooltipContent,\n        html: true,\n        placement: 'top',\n      }"}],style:({ backgroundColor: '#000' }),attrs:{"tabindex":"0"}},[_vm._v("\n      +"+_vm._s(_vm.assigneesTeamsAndCompanies.length - _vm.visibleAssignees)+"\n    ")])]):_vm._e(),_vm._v(" "),(
      _vm.canEdit &&
      (!_vm.disabled || _vm.showAddButton) &&
      (!_vm.assigneesTeamsAndCompanies.length || _vm.showTriggerAfterPopulated)
    )?_c('div',{style:({
      zIndex: _vm.visibleAssignees + 1,
    })},[_vm._t("trigger",function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('Anyone')))])]},{"hasAssignees":Boolean(_vm.assigneesTeamsAndCompanies.length)})],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }