<template>
  <div>
    <div
      class="w-pubble-chat pubble-app"
      :class="{
        'pubble-app--hide': isDismissed || isHidden,
        'pubble-app--remove-transition': removeTransition,
      }"
      :data-app-id="pubbleAppId"
      :data-app-identifier="pubbleAppId"
      :data-user-name="currentUserName"
      :data-user-email="currentUserEmail"
      :pubble-data-refreshurl="true"
      @mouseover="enableDismiss"
      @mouseleave="disableDismiss"
    ></div>
    <button
      class="pubble-chat-icon-close"
      :class="{
        'pubble-chatify-icon-close--hide': isDismissIconHidden,
        'pubble-chatify-icon-close--remove-transition': removeTransition,
      }"
      :title="$t('Dismiss Pubble Chat')"
      :aria-label="$t('Dismiss Pubble Chat')"
      @click="onDismiss"
    >
      <icon-close />
    </button>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue-demi';
import IconClose from '~icons/tw/close';
import usePendo from '@/platform/composables/usePendo';

export default {
  name: 'PubbleChat',
  components: { IconClose },
  props: {
    currentUserName: {
      type: String,
      default: '',
    },
    currentUserEmail: {
      type: String,
      default: '',
    },
    removeTransition: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const pubbleSrcUrl = 'https://cdn.chatify.com/javascript/loader.js';
    const overlappingClassNames = [
      'w-toaster__content',
      'panel-sm',
      'position-right',
      'filters-holder',
      '_pendo-step-container',
    ];
    const pubbleAppId = 38592;

    const pubbleLoadInterval = ref(null);
    const isHidden = ref(false);
    const isDismissed = ref(false);
    const isDismissible = ref(false);
    const isDismissibleTimeout = ref(false);
    const isChatOpen = ref(false);
    const overlapNotFound = ref(0);
    const pubbleSidebar = ref(null);

    const { trackPendoEvent } = usePendo();

    const loadScript = (
      src,
      async = true,
      defer = false,
      onLoadCallback,
      onErrorCallBack,
    ) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = async;
      script.defer = defer;
      if (onLoadCallback) {
        script.onload = () => onLoadCallback(null, script);
      }
      if (onErrorCallBack) {
        script.onerror = () =>
          onErrorCallBack(new Error(`Script load error for ${src}`));
      }
      document.head.appendChild(script);
    };

    const commonMetrics = [
      'plan_name',
      'page',
      'user_role',
      'days_since_purchase',
    ];

    const onDismiss = () => {
      isDismissed.value = true;

      trackPendoEvent({
        launchDarklyFlagKey: 'projects-pubble-chat-enabled',
        eventName: 'PUBBLE_CHAT_EVENT',
        commonMetrics,
        metadata: {
          event_action: 'chat dismissed',
        },
      });
    };

    const enableDismiss = () => {
      clearTimeout(isDismissibleTimeout.value);
      isDismissible.value = true;
    };

    const disableDismiss = () => {
      isDismissibleTimeout.value = setTimeout(() => {
        isDismissible.value = false;
      }, 3000);
    };

    const handlePubbleEvent = () => {
      if (pubbleSidebar.value.getAttribute('aria-label') === 'Open Livechat') {
        isChatOpen.value = true;
      } else {
        isChatOpen.value = false;
      }
    };

    const addPubbleListeners = () => {
      pubbleSidebar.value = document.getElementById('pb_sidebar');
      if (!pubbleSidebar.value) {
        return;
      }
      clearInterval(pubbleLoadInterval.value);
      pubbleSidebar.value.addEventListener('mousedown', handlePubbleEvent);

      trackPendoEvent({
        launchDarklyFlagKey: 'projects-pubble-chat-enabled',
        eventName: 'PUBBLE_CHAT_EVENT',
        commonMetrics,
        metadata: {
          event_action: 'chat icon displayed',
        },
      });
    };

    const isDismissIconHidden = computed(() => {
      return (
        !isDismissible.value ||
        isDismissed.value ||
        isHidden.value ||
        isChatOpen.value
      );
    });

    watch(overlapNotFound, (n) => {
      if (n > overlappingClassNames.length * 2) {
        isHidden.value = false;
        overlapNotFound.value = 0;
      }
    });

    watch(isChatOpen, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        if (isChatOpen.value === true) {
          trackPendoEvent({
            launchDarklyFlagKey: 'projects-pubble-chat-enabled',
            eventName: 'PUBBLE_CHAT_EVENT',
            commonMetrics,
            metadata: {
              event_action: 'chat opened',
            },
          });
        } else {
          trackPendoEvent({
            launchDarklyFlagKey: 'projects-pubble-chat-enabled',
            eventName: 'PUBBLE_CHAT_EVENT',
            commonMetrics,
            metadata: {
              event_action: 'chat closed',
            },
          });
        }
      }
    });

    onMounted(() => {
      setInterval(() => {
        overlappingClassNames.forEach((className) => {
          if (document.getElementsByClassName(className)[0]) {
            isHidden.value = true;
            overlapNotFound.value = 0;
          } else {
            overlapNotFound.value += 1;
          }
        });
      }, 1000);
    });

    loadScript(pubbleSrcUrl, true, true, () => {
      pubbleLoadInterval.value = setInterval(addPubbleListeners, 500);
    });

    return {
      isDismissed,
      isHidden,
      pubbleAppId,
      enableDismiss,
      disableDismiss,
      isDismissIconHidden,
      onDismiss,
    };
  },
};
</script>

<style lang="scss" scoped>
.pubble-app {
  &.pubble-app--hide {
    display: none !important;
  }

  &.pubble-app--remove-transition {
    .pubble-sb-bounce-in {
      animation: none;
      animation-delay: 0s;
      animation-name: none;
    }

    .pubble-sb-animated {
      animation: none;
      animation-delay: 0s;
      animation-name: none;
    }
  }

  .pubble-cw-powered-container {
    display: none;
  }
}

.pubble-chat-icon-close {
  position: fixed;
  border: 0px solid #f5f7fa;
  padding: 0px;
  background: #3b93f7;
  border-radius: 50%;
  fill: #fff;
  z-index: 9995;
  font-size: 17px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  right: 20px;
  bottom: 77px;
  animation-name: bounce-in;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.pubble-chat-icon-close:hover {
  background: #005ac1;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.pubble-chatify-icon-close--hide {
  display: none;
}

.pubble-chat-icon-close--remove-transition {
  animation: none;
  animation-delay: 0s;
  animation-name: none;
}
</style>
