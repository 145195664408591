import { render, staticRenderFns } from "./ProfileMenuMoreItemsMenu.vue?vue&type=template&id=37361ce4&scoped=true&"
import script from "./ProfileMenuMoreItemsMenu.vue?vue&type=script&lang=js&"
export * from "./ProfileMenuMoreItemsMenu.vue?vue&type=script&lang=js&"
import style0 from "./ProfileMenuMoreItemsMenu.vue?vue&type=style&index=0&id=37361ce4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37361ce4",
  null
  
)

export default component.exports