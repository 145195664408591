<template>
  <div
    :class="[
      'chip',
      chipSize,
      {
        'has-icon': Boolean(icon),
        'has-image': Boolean(image),
        'has-label': Boolean(label),
        'has-background': Boolean(background),
        'has-remove': Boolean(hasRemoveListener),
        interactive,
      },
    ]"
    :style="{ backgroundColor: background, color }"
    @click="handleClick"
    v-tooltip="{
      content: tooltip,
    }"
  >
    <img :src="image" v-if="image" />
    <CommonIcons :id="icon" v-if="icon" />
    <span class="chip__label" v-if="label">{{ label }}</span>
    <slot />

    <button
      class="chip__remove"
      v-if="hasRemoveListener"
      @click.self="handleRemove"
    >
      &times;
    </button>
  </div>
</template>

<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';

/*
PROPS
  label: Label to display inside the chip
  image: Image that will show either in the center if no label is added or on the left
  icon: Icon to show. This accepts any icon name from https://github.com/Teamwork/common-icons/blob/HEAD/BUNDLES.MD
  background: A color to change the chips background, also accepts `transparent`
  color: The color of the text, defaults to `#0B0E1F`
  tooltip: A string that will be used to show a tooltip
  interactive: Whether the chip is clickable
  size: A string representing the size: default, medium, big

EVENTS
  @click: Triggered when the chip is pressed - will be fired only if `interractive` prop is true

SLOTS
  default - this would add content in the chip on the right side of the label
*/

export default {
  name: 'Chip',
  display: 'Chip',
  props: {
    label: { type: String, required: false, default: null },
    image: { type: String, required: false, default: null },
    icon: { type: String, required: false, default: null },
    background: { type: String, required: false, default: '#F5F7FA' },
    color: { type: String, required: false, default: '#0B0E1F' },
    tooltip: { type: String, required: false, default: null },
    interactive: { type: Boolean, required: false, default: false },
    size: { type: String, required: false, default: 'default' },
  },
  components: { CommonIcons },
  methods: {
    handleClick() {
      if (this.interactive) {
        this.$emit('click');
      }
    },
    handleRemove() {
      if (this.hasRemoveListener) {
        this.$emit('remove');
      }
    },
  },
  computed: {
    chipSize() {
      return `size-${this.size}`;
    },
    hasRemoveListener() {
      return this.$listeners && this.$listeners.remove;
    },
  },
};
</script>

<style lang="scss" scoped>
$sizeList: (
  small: 16px,
  default: 24px,
  medium: 32px,
  big: 40px,
);

$sizes: small, default, medium, big;

.chip {
  border-radius: 20px;
  margin-right: 8px;
  color: #0b0e1f;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.interactive {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.7;
    }
  }

  &.has-image {
    overflow: hidden;

    img {
      border-radius: 50%;
    }
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__remove {
    background: none;
    border: none;
    padding: 2px 6px;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.5;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }
  }

  &.has-icon {
    &.has-label {
      .chip__label {
        padding-left: 0;
      }
    }
  }

  &.has-label {
    &.has-remove {
      .chip__label {
        padding-right: 0;
      }
    }
  }

  /* SIZES */
  @each $size in $sizes {
    &.size-#{$size} {
      height: map-get($sizeList, $size);

      .chip__label {
        padding: 0 map-get($sizeList, $size) / 2;
        @if $size == 'small' {
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.has-image {
        min-width: map-get($sizeList, $size);
        img {
          height: map-get($sizeList, $size);
        }
      }

      svg {
        margin: 0 map-get($sizeList, $size) / 4;
      }
    }
  }
}
</style>
