import Vue from 'vue';
import moment from 'moment';
import typedDefaults from '@/utils/helpers/typed-defaults';
import { getDatesInRange } from '@/utils/helpers/date';
import { createRecordLoader, API } from '@/store/utils/loader';
import all from './all';
import actions from './actions';

const defaultTypes = {
  id: 0,
  installationId: 0,
  projectId: 0,
  title: '',
  description: '',
  startedAt: '',
  endedAt: '',
  color: '',
  status: '',
  distributeType: 'distributed',
  createdAt: '',
  createdBy: 0,
  updatedAt: '',
  updatedBy: 0,
  deletedAt: '',
  deletedBy: 0,
  assignedUserID: 0,
  resourceId: '',
  durationMinutes: 0,
  availableDurationMinutes: 0,
  hoursPerDay: 0,
};

function createResourceId(allocation) {
  return JSON.stringify({
    userId: parseInt(allocation.assignedUserID, 10),
    projectId: parseInt(allocation.projectId, 10),
  });
}

function format(allocation) {
  const newAllocation = typedDefaults(defaultTypes, allocation);

  newAllocation.resourceId = createResourceId(newAllocation);
  newAllocation.startedAt = moment
    .tz(allocation.startedAt, moment.tz.guess())
    .startOf('day')
    .toDate();
  newAllocation.endedAt = moment
    .tz(allocation.endedAt, moment.tz.guess())
    .endOf('day')
    .toDate();

  return newAllocation;
}

export default {
  namespaced: true,
  modules: {
    loader: createRecordLoader({
      config: {
        url: (id) => `${API.v3}/allocations/${id}.json`,
      },
    }),
    all,
  },
  state: {
    records: {},
    userIdsByProject: {},
    projectIdsByUser: {},
    ignoreCollisions: false,
    ignoreCollisionsOnce: false,
  },
  mutations: {
    ignoreCollisions(state, payload) {
      state.ignoreCollisions = payload;
    },

    ignoreCollisionsOnce(state, payload) {
      state.ignoreCollisionsOnce = payload;
    },

    records(state, allocations) {
      const newAllocations = {};
      allocations.forEach((allocation) => {
        const withDefaults = typedDefaults(defaultTypes, allocation);
        const existing = state.records[allocation.id];
        newAllocations[allocation.id] = existing
          ? { ...existing, ...withDefaults }
          : withDefaults;
        newAllocations[allocation.id].durationMinutes = allocation.duration;
        newAllocations[allocation.id].availableDurationMinutes =
          allocation.availableDuration;
        newAllocations[allocation.id].resourceId = createResourceId(
          newAllocations[allocation.id],
        );

        delete newAllocations[allocation.id].duration;
        delete newAllocations[allocation.id].availableDuration;
      });

      state.records = { ...state.records, ...newAllocations };
    },

    record(state, allocation) {
      const existing = state.records[allocation.id];
      const withDefaults = typedDefaults(defaultTypes, allocation);
      const newAllocation = existing
        ? { ...existing, ...withDefaults }
        : withDefaults;

      newAllocation.resourceId = createResourceId(newAllocation);
      newAllocation.durationMinutes = allocation.duration;
      newAllocation.availableDurationMinutes = allocation.availableDuration;

      delete newAllocation.duration;
      delete newAllocation.availableDuration;

      Vue.set(state.records, newAllocation.id, newAllocation);
    },

    update(state, { id, updates }) {
      const allUpdates = { ...updates };
      if (allUpdates.duration) {
        allUpdates.durationMinutes = allUpdates.duration;
        delete allUpdates.duration;
      }
      if (allUpdates.availableDuration) {
        allUpdates.availableDurationMinutes = allUpdates.availableDuration;
        delete allUpdates.availableDuration;
      }
      Vue.set(state.records, id, { ...state.records[id], ...allUpdates });
    },

    delete(state, id) {
      Vue.delete(state.records, id);
    },
  },

  actions,

  getters: {
    timelogs:
      (state, getters, { timelog }) =>
      (id) => {
        const { assignedUserID, projectId, startedAt, endedAt } =
          state.records[id];
        const timelogs = Object.values(timelog.records);
        return timelogs.filter((log) => {
          const isMatch =
            log.userId === assignedUserID && log.projectId === projectId;

          if (!isMatch) {
            return false;
          }

          const timeLogged = moment.tz(log.timeLogged, moment.tz.guess());
          const start = moment.tz(startedAt, moment.tz.guess()).startOf('day');
          const end = moment.tz(endedAt, moment.tz.guess()).endOf('day');
          return timeLogged.isBetween(start, end, undefined, '[]');
        });
      },

    formatAllocation: (state) => (id) => format(state.records[id]),

    scheduled: (state, getters) =>
      Object.keys(state.records).map((id) => getters.formatAllocation(id)),

    unavailableOverlap:
      (state, getters, rootState, rootGetters) =>
      (assignedUserID, startedAt, endedAt) => {
        const unavailableDates =
          rootGetters['schedule/unavailableDatesForUser'](assignedUserID);
        const allocationDates = getDatesInRange(startedAt, endedAt);

        return unavailableDates.filter((item) =>
          allocationDates.includes(item),
        );
      },

    workingMinutes:
      (state, { unavailableOverlap }, rootState) =>
      (id, onlyInView = false) => {
        const allocation = state.records[id];
        const {
          startedAt,
          endedAt,
          assignedUserID,
          hoursPerDay,
          durationMinutes,
          availableDurationMinutes,
        } = allocation;
        let start = startedAt;
        let end = endedAt;
        let allocatedMinutes;

        if (onlyInView) {
          const { startDate, endDate } = rootState.schedule;

          if (moment(endedAt).isAfter(endDate)) {
            end = endDate;
          }

          if (moment(startedAt).isBefore(startDate)) {
            start = startDate;
          }
          const allocationDayCount =
            moment(end).diff(moment(start), 'days') + 1;
          const unavailableOverlapCount = unavailableOverlap(
            assignedUserID,
            start,
            end,
          ).length;
          allocatedMinutes =
            hoursPerDay * 60 * (allocationDayCount - unavailableOverlapCount);
        } else {
          // use the API result if it is to display the overall allocation available duration time
          allocatedMinutes = availableDurationMinutes || durationMinutes;
        }

        return allocatedMinutes < 0 ? 0 : allocatedMinutes;
      },
  },
};
