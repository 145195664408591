<template>
  <div class="dependencies-item-task">
    <DateRangePicker
      date-picker-placement="top-end"
      :disabled="isCompleted || !canEdit"
      :due-date="task.dueDate"
      :start-date="task.startDate"
      @change-start-date="updateStartDate"
      @change-due-date="updateDueDate"
    />
    <div class="task-assignee">
      <TaskAssigneePicker
        v-if="task.userPermissions"
        :project-id="task.projectId"
        :task-id="task.id"
        :assignees="task.assignees"
        :disabled="!task.userPermissions.canEdit"
        :company-privacy-ids="companyPrivacyIds"
        :user-privacy-ids="userPrivacyIds"
        @choose-more="onChooseMore"
        @assignees-updated="onAssigneesUpdated"
      />
    </div>
  </div>
</template>
<script>
import DateRangePicker from '@widgets/DateRangePicker';
import { useTaskActions } from '@teamwork/use';
import { computed, getCurrentInstance } from 'vue-demi';
import moment from 'moment';
import updateTaskAssignees from '@/utils/helpers/updateTaskAssignees';

export default {
  name: 'DependenciesItemTask',
  components: {
    DateRangePicker,
    TaskAssigneePicker: () =>
      import('../TaskAssigneePicker/TaskAssigneePicker.vue'),
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isCompleted = computed(() => props.task?.status === 'completed');
    const canEdit = computed(
      () =>
        props.task?.canEdit ||
        (props.task?.userPermissions && props.task?.userPermissions?.canEdit),
    );

    const { updateTask } = useTaskActions();
    const instance = getCurrentInstance();
    const self = instance.proxy;

    function updateStartDate({ date, autoAdjustDueDate = false }) {
      const updatedTask = {
        id: props.task.id,
        startDate: date,
        pushSubtasks: self.$store.state.preferences.user.taskPushSubTasks,
        pushDependents:
          self.$store.state.preferences.user.taskPushDependentTasks,
      };
      if (autoAdjustDueDate) {
        updatedTask.dueDate = date;
      }
      updateTask(updatedTask, props.task);
    }
    function updateDueDate({ date, autoAdjustStartDate = false }) {
      const updatedTask = {
        id: props.task.id,
        dueDate: date,
        pushSubtasks: self.$store.state.preferences.user.taskPushSubTasks,
        pushDependents:
          self.$store.state.preferences.user.taskPushDependentTasks,
      };
      if (autoAdjustStartDate) {
        updatedTask.startDate = date;
      }
      updateTask(updatedTask, props.task);
    }

    const startDate = computed(() =>
      props.predecessor.startDate ? moment(props.predecessor.startDate) : null,
    );

    const dueDate = computed(() =>
      props.predecessor.dueDate ? moment(props.redecessor.dueDate) : null,
    );

    const companyPrivacyIds = computed(() =>
      props.task.lockdown && props.task.lockdown.grantAccessTo
        ? props.task.lockdown.grantAccessTo.companyIds
        : [],
    );

    const userPrivacyIds = computed(() =>
      props.task.lockdown && props.task.lockdown.grantAccessTo
        ? props.task.lockdown.grantAccessTo.userIds
        : [],
    );

    function onAssigneesUpdated(assignees) {
      updateTaskAssignees({
        task: this.task,
        assignees,
        updateTask,
      });
    }

    function updateAssignees() {
      if (!canEdit.value) {
        return;
      }
      self.$store.dispatch('modals/open', {
        name: 'people-picker-modal',
        props: {
          itemType: 'task',
          taskId: props.task.id,
          projectId: props.task.projectId,
          lockdownId: props.task.lockdownId,
          assignedTo: props.task.assignees,
          callback: (assignees) => {
            updateTask(
              {
                id: props.task.id,
                assignedTo: assignees
                  .filter((x) => x.linkedItem.pickerItemType === 'user')
                  .map((x) => ({
                    id: x.id,
                    avatarUrl:
                      x.linkedItem.avatarUrl || x.linkedItem.defaultImageUrl,
                    firstName: x.linkedItem.firstName,
                    lastName: x.linkedItem.lastName,
                  })),
                assignedToCompanies: assignees
                  .filter((x) => x.linkedItem.pickerItemType === 'company')
                  .map((x) => ({
                    companyId: x.companyId / 1,
                    companyLogoURL: x.linkedItem.defaultImageUrl,
                    companyName: x.label,
                  })),
                assignedToTeams: assignees
                  .filter((x) => x.linkedItem.pickerItemType === 'team')
                  .map((x) => ({
                    teamId: x.teamId,
                    teamLogo: x.logo,
                    teamLogoColor: x.color,
                    teamLogoIcon: x.icon,
                    teamName: x.label,
                  })),
              },
              props.task,
            );
          },
        },
      });
    }

    function onChooseMore() {
      updateAssignees();
    }
    return {
      isCompleted,
      canEdit,
      updateStartDate,
      updateDueDate,
      moment,
      startDate,
      dueDate,
      companyPrivacyIds,
      userPrivacyIds,
      onChooseMore,
      onAssigneesUpdated,
    };
  },
};
</script>
<style lang="scss" scoped>
.dependencies-item-task {
  display: flex;
}
.task-assignee {
  margin-right: 16px;
  display: flex;
}
</style>
