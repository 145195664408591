<template>
  <div v-if="isHybridReady">
    <!-- legacy TKO components -->
    <TkoComponent name="section-modals" />
    <ModalContainer />

    <!-- "Next" frame for rendering "Teamwork next" modals -->
    <NextModalFrame />
  </div>
</template>
<script>
import ModalContainer from '@sections/ModalContainer';
import NextModalFrame from '@widgets/NextModalFrame';
import TkoComponent from '@sections/TkoComponent';
import { useHybrid } from '@/platform/composables/useHybrid';

export default {
  components: {
    ModalContainer,
    NextModalFrame,
    TkoComponent,
  },
  setup() {
    const { isHybridReady } = useHybrid();
    return {
      isHybridReady,
    };
  },
};
</script>
