<template>
  <FModal @close="dismiss" v-bind="$props">
    <template #header="{ closeModal }">
      <FModalContent class="flex gap-4 py-6 items-start w-full text-h-04">
        <div class="flex-shrink-0 mt-2">
          <component
            :is="dialogConfig.icon"
            class="w-4 h-4"
            :class="dialogConfig.iconClasses"
          />
        </div>
        <h4 class="font-semibold">{{ title }}</h4>
        <div class="ml-auto" v-if="!persistent && shouldShowCloseButton">
          <FFabButton
            variant="tertiary"
            size="sm"
            @click="closeModal"
            :aria-label="$t('Close dialog')"
          >
            <IconClose class="fill-current" />
          </FFabButton>
        </div>
      </FModalContent>
    </template>
    <p v-html="message" class="text-default text-text pl-8" />
    <template #footer>
      <FButton variant="tertiary" @click="dismiss">
        {{ dismissText }}
      </FButton>
      <FButton :variant="dialogConfig.buttonVariant" @click="confirm">
        {{ confirmText }}
      </FButton>
    </template>
  </FModal>
</template>

<script>
import Vue from 'vue';
import { computed } from 'vue-demi';
import FButton from '../../actions/FButton/FButton.vue';
import FFabButton from '../../actions/FFabButton/FFabButton.vue';
import FModal from '../FModal/FModal.vue';
import FModalContent from '../FModal/FModalContent.vue';
import {
  defaultDialogSize,
  defaultDialogVariant,
  dialogSizes,
  dialogVariants,
} from './constants';
import IconClose from '~icons/tw/dialog-close';
import IconInformative from '~icons/tw/dialog-informative';
import IconWarning from '~icons/tw/dialog-warning';

const dialogVariantMap = {
  [dialogVariants.informative]: {
    buttonVariant: 'primary',
    icon: 'IconInformative',
    iconClasses: `fill-[var(--token-c-dialog-color-informative)]`,
  },
  [dialogVariants.warning]: {
    buttonVariant: 'warning',
    icon: 'IconWarning',
    iconClasses: `fill-[var(--token-c-dialog-color-warning)]`,
  },
};

export default {
  name: 'FDialog',
  components: {
    FButton,
    FFabButton,
    FModal,
    FModalContent,
    IconClose,
    IconInformative,
    IconWarning,
  },
  props: {
    /**
     * Text for confirm button
     */
    confirmText: { type: String, default: () => Vue.t('Confirm') },

    /**
     * Text for dismiss button
     */
    dismissText: { type: String, default: () => Vue.t('Cancel') },
    /**
     * The dialog title.
     */
    title: { type: String, default: '' },

    /**
     * The dialog message.
     */
    message: { type: String, default: '' },

    /**
     * Whether the user can close the dialog or not.
     */
    persistent: { type: Boolean, default: false },

    /**
     * Whether the close button is shown or not
     */
    shouldShowCloseButton: { type: Boolean, default: true },

    /**
     * The size of the modal
     */
    size: {
      type: String,
      default: defaultDialogSize,
      validator: (size) => Object.values(dialogSizes).includes(size),
    },
    /**
     * The dialog variant
     */
    variant: {
      type: String,
      default: defaultDialogVariant,
      validator: (type) => Object.values(dialogVariants).includes(type),
    },
  },
  setup(props, { emit }) {
    const dialogConfig = computed(() => dialogVariantMap[props.variant]);

    const dismiss = () => emit('dismiss');

    const confirm = () => emit('confirm');

    return {
      dialogConfig,
      dismiss,
      confirm,
    };
  },
};
</script>
