<template>
  <li
    :key="`${type}-${id}`"
    class="w-full h-[60px] mb-2 rounded-full outline-none bg-transparent"
    @click="$emit('click', $event)"
    :data-identifier="identifierPrefix && `${identifierPrefix}__list-item`"
  >
    <component
      :is="component"
      v-bind="linkAttrs || {}"
      :class="[
        'w-full',
        'h-full',
        'flex',
        'items-center',
        'p-3',
        'pr-6',
        'outline-none',
        'rounded-full',
        'bg-transparent',
        'focus:bg-palette-neutral-20',
        'hover:bg-palette-neutral-20',
        'transition-colors',
        ...(isClickable ? ['cursor-pointer'] : []),
      ]"
      tabindex="0"
    >
      <div
        class="mr-3 w-[36px] h-[36px] flex-none rounded-full overflow-hidden"
      >
        <slot name="icon">
          <EntityIcon
            :item-type="type"
            :size="36"
            class="fill-current text-text w-full h-full"
          />
        </slot>
      </div>
      <div class="flex flex-1 flex-column min-w-0">
        <div class="w-full flex items-center text-text-secondary text-xs mb-1">
          <span v-if="$slots['line-1-left']" class="flex-1 block truncate">
            <slot name="line-1-left"></slot>
          </span>
          <span
            v-if="$slots['line-1-right']"
            class="flex-none ml-1 block truncate"
          >
            <slot name="line-1-right"></slot>
          </span>
        </div>

        <p
          v-if="$slots['line-2']"
          class="truncate flex-1 text-default text-text font-semibold"
          :class="strikethrough ? ['line-through'] : []"
        >
          <slot name="line-2"></slot>
        </p>
      </div>
    </component>
  </li>
</template>

<script>
import { computed } from 'vue-demi';
import { isEmpty, isString } from 'lodash';

import EntityIcon from '@widgets/EntityIcon';

export default {
  name: 'ListItem',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: '',
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    strikethrough: {
      type: Boolean,
      required: false,
      default: false,
    },
    identifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    EntityIcon,
  },
  setup(props) {
    const linkAttrs = computed(() => {
      const { title, link } = props;

      if (!isString(link) || isEmpty(link)) {
        return null;
      }

      const attrs = {
        to: `/${link}`,
        title,
      };

      return attrs;
    });

    const component = computed(() => {
      return linkAttrs.value ? 'router-link' : 'div';
    });

    const isClickable = computed(() => {
      return component.value === 'router-link';
    });

    return {
      linkAttrs,
      component,
      isClickable,
    };
  },
};
</script>

<style scoped></style>
