/* eslint-disable no-underscore-dangle, import/prefer-default-export */
import { computed, unref } from 'vue-demi';
import { v2Url } from '@teamwork/fetcher';
import { useListLoader } from '@teamwork/use';
import { isUndefined } from 'lodash-es';
import pluralize from 'pluralize';

export const ENTITY_TYPE_TO_PARAM_MAP = Object.freeze({
  project: 'searchProjects',
  task: 'searchTasks',
  tasklist: 'searchTasklists',
  milestone: 'searchMilestones',
  file: 'searchFiles',
  message: 'searchMessages',
  notebook: 'searchNotebooks',
  people: 'searchPeople',
  contact: 'searchPeople',
  user: 'searchPeople',
  person: 'searchPeople',
  company: 'searchCompanies',
  team: 'searchTeams',
  link: 'searchLinks',
  comment: 'searchComments',
});

function responseToItems(response) {
  return response.data.searchResults.map((searchResult) => {
    return {
      ...searchResult,
      id: `${searchResult.type}-${searchResult.id}`,
      entityId: searchResult.id,
      entityType: searchResult.type,
    };
  });
}

export function useQuickSearchResultsLoader({
  params,
  entities: _entities,
  count,
  pageSize = 20,
}) {
  const entities = computed(() => {
    return unref(_entities) || [];
  });

  // eslint-disable-next-line no-underscore-dangle
  const _params = computed(() => {
    const __params = unref(params);
    const _searchTerm = unref(__params.searchTerm);
    const _projectId = unref(__params.projectId);
    const _searchAllProjects = unref(__params.searchAllProjects);

    return {
      returnExtras: true,
      skipES: false,
      searchTerm: !isUndefined(_searchTerm) ? _searchTerm : '',
      projectId: !isUndefined(_projectId) ? _projectId : 0,
      searchAllProjects: !isUndefined(_searchAllProjects)
        ? _searchAllProjects
        : true,
      skipKeyword: true,
      // Note: the current quick search endpoint defaults all these entity query parameters to
      // true, so we need to iterate over them and explicitly set the ones we want to exclude
      // to false
      ...(!entities.value.length
        ? {}
        : Object.entries(ENTITY_TYPE_TO_PARAM_MAP).reduce(
            (result, [entity, param]) => {
              if (result[param]) {
                return result;
              }

              // eslint-disable-next-line no-param-reassign
              result[param] =
                entities.value.includes(pluralize.singular(entity)) ||
                entities.value.includes(pluralize.plural(entity)) ||
                false;

              return result;
            },
            {},
          )),
    };
  });

  const loader = useListLoader({
    url: v2Url('quickSearch'),
    params: _params,
    count,
    responseToItems,
    pageSize,
  });

  return {
    ...loader,
  };
}
