<template>
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-943.000000, -380.000000)"
        :fill="color"
        fill-rule="nonzero"
      >
        <g transform="translate(372.000000, 376.000000)">
          <g transform="translate(571.000000, 4.000000)">
            <!-- eslint-disable max-len -->
            <path
              d="M0.330789387,3.85823193 C0.477227032,3.98374992 0.714664757,3.98374992 0.861133655,3.85823193 L3.46876113,1.61015811 L3.46876113,11.6785709 C3.46876113,11.8560801 3.63666749,12 3.84376144,12 L4.15626176,12 C4.36335572,12 4.53126208,11.8560801 4.53126208,11.6785709 L4.53126208,1.61015811 L7.13888995,3.85823193 C7.28532759,3.98374992 7.52276506,3.98374992 7.66923396,3.85823193 L7.89017169,3.66882992 C8.03660933,3.54331192 8.03660933,3.33979402 7.89017169,3.21427603 L4.26516819,0.0941385001 C4.11873055,-0.0313794941 3.88129276,-0.0313794941 3.73482386,0.0941385001 L0.109851668,3.21427603 C-0.0366172294,3.33979402 -0.0366172294,3.54331192 0.109851668,3.66882992 L0.330789387,3.85823193 Z"
              transform="translate(4.000000, 6.000000) rotate(-180.000000) translate(-4.000000, -6.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: { type: String, default: '#C5CEE0' },
  },
};
</script>
