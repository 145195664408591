<template>
  <div>
    <h2 class="text-text-secondary font-bold pb-1 text-default">
      {{ title }}
    </h2>
    <ul class="bg-white rounded-lg list-none m-0 mb-6 p-0 shadow-sm">
      <li
        v-for="shortcut of shortcuts"
        :key="shortcut.name"
        class="
          m-0
          flex flex-row
          gap-3
          px-4
          py-0
          items-center
          justify-between
          h-12
          border-x-0 border-t-0 border-b border-solid border-default
          last:border-none
        "
      >
        <span class="flex-auto truncate" :title="$t(shortcut.name)">
          {{ shortcut.name }}
        </span>
        <template v-for="(glyph, index) of shortcut.keys">
          <KeyboardShortcutKey
            :glyph="stripGlyph(glyph)"
            :key="glyph"
            v-if="isGlyph(glyph)"
          />
          <span
            class="w-2 inline-flex flex-row items-center justify-center"
            v-else
            :key="index"
          >
            {{ glyph }}
          </span>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import KeyboardShortcutKey from '@/platform/components/side-nav/panels/help-center/common/KeyboardShortcutKey.vue';

export default {
  name: 'KeyboardShortcutList',
  components: { KeyboardShortcutKey },
  props: {
    shortcuts: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup() {
    const isGlyph = (glyph) => glyph.charAt(0) === '[' && glyph.length > 1;
    const stripGlyph = (glyph) => glyph.replace(/[[|\]]/g, '');

    return {
      isGlyph,
      stripGlyph,
    };
  },
};
</script>
