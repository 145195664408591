var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({ref:"root",class:[
    'w-option-picker',
    { 'has-indicators': _vm.showIndicators, 'has-dot': _vm.showDot, block: _vm.block } ]},'div',_vm.$attrs,false),[_c('v-popover',{attrs:{"open":_vm.isOpen,"placement":_vm.placement,"popover-arrow-class":_vm.popoverArrowClass,"offset":_vm.offset,"disabled":_vm.disabled,"popper-options":_vm.popperOptions},on:{"update:open":function($event){_vm.isOpen=$event},"show":function($event){return _vm.$emit('open')}}},[_vm._t("trigger",function(){return [_c('div',{staticClass:"w-option-picker__current",class:[{ open: _vm.isOpen, disabled: _vm.disabled }],style:({
          background: _vm.showDot ? 'transparent' : _vm.currentOption.background,
          color: _vm.currentOption.color,
        }),on:{"click":_vm.togglePopover}},[(_vm.showDot)?_c('span',{staticClass:"w-option-picker__option-dot",style:({ background: _vm.currentOption.dotColor })}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.tooltip }),expression:"{ content: tooltip }"}],ref:"current",class:[
            'w-option-picker__option-label',
            {
              'is-placeholder': _vm.currentOption.isPlaceholder,
              'is-empty': !_vm.currentOption.id,
            } ]},[_vm._v("\n          "+_vm._s(_vm.currentOption.label)+"\n        ")])])]}),_vm._v(" "),_c('template',{slot:"popover"},[_c('div',{staticClass:"w-option-picker__options",style:({
          maxWidth: _vm.maxWidth,
          maxHeight: _vm.maxHeight,
        })},[_vm._t("top"),_vm._v(" "),_vm._l((_vm.options),function(option){return _c('div',{directives:[{name:"close-popover",rawName:"v-close-popover"}],key:option.id,class:[
            'w-option-picker__option',
            {
              selected: option.value === _vm.value,
              hasDot: _vm.showDot,
            } ],style:({ background: option.background, color: option.color }),on:{"click":function($event){return _vm.select(option)}}},[(_vm.showDot)?_c('span',{staticClass:"w-option-picker__option-dot",style:({ background: option.dotColor })}):_vm._e(),_vm._v("\n          "+_vm._s(option.label)+"\n        ")])}),_vm._v(" "),_vm._t("bottom")],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }