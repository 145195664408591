<template>
  <div
    class="
      py-[64px]
      text-center
      h-full
      flex flex-col
      items-center
      justify-center
    "
  >
    <NoSearchResultsImage class="w-[80px] mt-300 flex-shrink-0" />
    <p
      class="
        text-text text-[14px]
        leading-default
        px-200
        mb-300
        pt-4
        mx-auto
        w-full
        flex flex-col
        items-center
      "
    >
      <span class="font-semibold block mb-2 w-44">
        {{ $t('No projects are matching your search') }}
      </span>

      <small class="w-52 text-[#646d7d] text-[13px] font-base">
        <span
          v-html="
            $t(
              'Please try again with a <br />different term, or refine your results <br />with',
            )
          "
        />

        <RouterLink
          :to="advancedSearchLink"
          @click.native="onAdvancedSearchClicked"
          class="
            transition-colors
            font-semibold
            text-text
            hover:underline hover:text-text
          "
          :data-identifier="
            identifierPrefix &&
            `${identifierPrefix}__empty-state-advanced-search`
          "
        >
          {{ $t('advanced search') }}
        </RouterLink>
      </small>
    </p>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import NoSearchResultsImage from '@/assets/images/blankslates/noSearchResults.svg';

export default {
  props: {
    searchTerm: {
      type: String,
      required: false,
      default: '',
    },
    identifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    headerText: {
      type: String,
      required: false,
      default: "We couldn't find anything matching your search",
    },
  },
  components: {
    NoSearchResultsImage,
  },
  setup(props, { emit }) {
    const handleClick = () => emit('click');

    const onAdvancedSearchClicked = () => {
      emit('advanced-search');
    };

    const advancedSearchLink = computed(() => {
      const searchTerm = encodeURIComponent(props.searchTerm);

      return `/search${searchTerm ? `/${searchTerm}` : ''}`;
    });

    return {
      advancedSearchLink,
      handleClick,
      onAdvancedSearchClicked,
    };
  },
};
</script>
