var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{class:[
    'w-toggle-switch',
    {
      'w-toggle-switch--disabled': !_vm.enabled,
      'w-toggle-switch--reversed-order': _vm.reversedOrder,
    } ]},[(!_vm.readOnly)?_c('input',_vm._b({staticClass:"w-toggle-switch__checkbox",attrs:{"type":"checkbox"},on:{"click":_vm.toggle}},'input',{ id: _vm.id, checked: _vm.value, disabled: !_vm.enabled },false)):_vm._e(),_vm._v(" "),(!_vm.readOnly)?_c('span',{class:[
      'w-toggle-switch__toggle',
      {
        'w-toggle-switch__toggle--on': _vm.value,
        'w-toggle-switch__toggle--changing': _vm.changing,
        'w-toggle-switch__toggle--disabled': !_vm.enabled,
      } ],attrs:{"title":_vm.showTitle ? _vm.title : ''}}):_vm._e(),_vm._v(" "),(_vm.readOnly)?_c('superset-icon',{class:("w-toggle-switch__read-only-" + (_vm.value ? 'check' : 'close')),attrs:{"id":_vm.value ? 'check' : 'close'}}):_vm._e(),_vm._v(" "),(_vm.label)?_c('span',{staticClass:"w-toggle-switch__label"},[_c('EllipsisLabel',{attrs:{"label":_vm.label,"attach-to-trigger":_vm.attachTooltipToTrigger}}),_vm._v(" "),(_vm.tip)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.tip,
        classes: 'tooltip w-tooltip w-toggle-switch__tooltip',
        container: false,
      }),expression:"{\n        content: tip,\n        classes: 'tooltip w-tooltip w-toggle-switch__tooltip',\n        container: false,\n      }"}]},[_c('superset-icon',{staticClass:"w-toggle-switch__info-icon",attrs:{"id":"info-circle","color":"#838F9C"}})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }