<template>
  <v-popover placement="top" trigger="hover">
    <div class="timelogs-count">
      <CommonIcons class="icon" id="clock-light" />
      <span class="counter">
        <span>{{ $t('Logged') }}:</span>
        {{ loggedTimeText }}
      </span>
    </div>
    <template slot="popover">
      <div class="timelogs-count__summary">
        <div class="time">
          <span>{{ $t('Logged') }}</span>
          <span>{{ loggedTimeText }}</span>
        </div>
        <div class="time">
          <span>{{ $t('Estimated') }}</span>
          <span>{{ estimatedTimeText }}</span>
        </div>
        <div class="time">
          <span>{{ $t('Billable') }}</span>
          <span>{{ billableTimeText }}</span>
        </div>
        <div class="time">
          <span>{{ $t('Non-billable') }}</span>
          <span>{{ nonbillableTimeText }}</span>
        </div>
        <div class="time">
          <span>{{ $t('Billed') }}</span>
          <span>{{ billedTimeText }}</span>
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { Vue2 as Vue } from 'vue-demi';
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import { formatMinutes } from '@/utils/helpers/time';

export default {
  name: 'TimeLogsCount',
  components: { CommonIcons },
  props: {
    task: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    loggedTimeText() {
      return this.task && this.task.timeTotals
        ? formatMinutes(this.task.timeTotals.loggedMinutes)
        : '0h';
    },
    estimatedTimeText() {
      return this.task && this.task.estimateMinutes
        ? formatMinutes(this.task.estimateMinutes)
        : 0 + Vue.t('hours').charAt(0);
    },
    billableTimeText() {
      return this.task && this.task.timeTotals
        ? formatMinutes(this.task.timeTotals.billableLoggedMinutes)
        : '0h';
    },
    billedTimeText() {
      return this.task && this.task.timeTotals
        ? formatMinutes(this.task.timeTotals.billedloggedMinutes)
        : '0h';
    },
    nonbillableTimeText() {
      return this.task && this.task.timeTotals
        ? formatMinutes(
            this.task.timeTotals.loggedMinutes -
              this.task.timeTotals.billableLoggedMinutes,
          )
        : '0h';
    },
  },
};
</script>

<style lang="scss" scoped>
.timelogs-count {
  color: #0b0e1f;
  border: 1px solid #e1e6ee;
  border-radius: 20px;
  min-height: 28px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .counter {
    margin-left: 4px;

    span {
      font-weight: 600;
    }
  }

  &__summary {
    padding: 16px;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .time {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;

      &:first-child {
        font-weight: 600;
      }
    }
  }

  .icon {
    fill: #646d7d;
  }
}

.v-popover {
  &.open {
    .timelogs-count {
      border-color: #4461d7;
    }

    svg {
      fill: #4461d7;
    }
  }
}
</style>
