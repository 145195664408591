import ko from 'knockout';
import moment from 'moment';

export const TIMER_STATUSES = Object.freeze({
  STARTED: 'started',
  PLAY: 'play',
  PAUSE: 'pause',
  RESUME: 'resume',
  EDITED: 'edited',
  COMPLETE: 'complete',
  DELETED: 'deleted',
  UPDATED: 'updated',
});

export function getTimerStateProps(timerState) {
  const timerButtonStates = {
    play: {
      class: 'is-play',
      tooltip: 'Start timer',
      icon: 'timer-start',
    },
    pause: {
      class: 'is-pause',
      tooltip: 'Pause timer',
      icon: 'timer-pause',
    },
    resume: {
      class: 'is-resume',
      tooltip: 'Resume timer',
      icon: 'timer-start',
    },
  };
  return timerButtonStates[timerState];
}

export function formatElapsedTime(elapsedTime) {
  const date = new Date(null);
  date.setSeconds(elapsedTime);
  const utc = date.toUTCString();
  return utc.substr(utc.indexOf(':') - 2, 8);
}

function getCurrentTimerState(duration, running) {
  const { PLAY, PAUSE, RESUME } = TIMER_STATUSES;

  if (duration === 0 && !running) {
    return PLAY;
  }
  return running ? RESUME : PAUSE;
}

function getSecondsFromLastStarted(lastStartedAt) {
  const now = moment(new Date());
  const end = moment(lastStartedAt);
  return now.diff(end, 'seconds');
}

export function getTimerByTaskId(taskId) {
  const filtered = window.app.timers().find((item) => {
    return ko.unwrap(item.taskId) === taskId;
  });

  const { PLAY } = TIMER_STATUSES;

  if (!filtered) {
    return {
      timerId: 0,
      currentElapsedTime: 0,
      currentTimeState: PLAY,
    };
  }

  const duration = ko.unwrap(filtered.duration);
  const running = ko.unwrap(filtered.running);
  const lastStartedAt = ko.unwrap(filtered.lastStartedAt);

  let currentElapsedTime = duration;

  if (running) {
    currentElapsedTime += getSecondsFromLastStarted(lastStartedAt);
  }

  return {
    timerId: ko.unwrap(filtered.id),
    currentElapsedTime,
    currentTimeState: getCurrentTimerState(duration, running, lastStartedAt),
    totalTime: filtered.totalTime,
  };
}
