import moment from 'moment';
import lscache from 'lscache';

export default [
  {
    mutations: ['schedule/dates'],
    callback: (ctx, type, dates) => {
      lscache.set('schedule-start-date', dates.startDate);
      lscache.set('schedule-end-date', dates.endDate);
    },
  },
  {
    mutations: ['calendarEvent/record'],
    callback: ({ state, dispatch }, type, event) => {
      const eventDate = moment.tz(
        event.start || event.startDate,
        moment.tz.guess(),
      );
      const scheduleStartDate = moment.tz(
        state.schedule.startDate,
        moment.tz.guess(),
      );
      const scheduleEndDate = moment.tz(
        state.schedule.endDate,
        moment.tz.guess(),
      );
      const isSameOrAfter = eventDate.isSameOrAfter(scheduleStartDate);
      const isSameOrBefore = eventDate.isSameOrBefore(scheduleEndDate);

      if (isSameOrAfter && isSameOrBefore) {
        const userIds = event['attending-user-ids'];
        if (state.schedule.people.loader.loader.status === 2 && userIds) {
          dispatch('schedule/people/loadPersonSchedule', userIds.toString());
        }
      }
    },
  },
  {
    mutations: ['schedule/people/person/assignedUserIds'],
    callback: ({ dispatch }) => {
      dispatch('schedule/people/person/reload');
    },
  },
];
