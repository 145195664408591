<template>
  <li
    class="TableViewColumnItem"
    :class="{
      'TableViewColumnItem--line-over': lineOver,
      'TableViewColumnItem--dragging': dragStarted,
    }"
  >
    <div
      v-if="dragStarted"
      class="TableViewColumnItem__drag-placeholder-item"
    />

    <div v-if="column" class="TableViewColumnItem__item">
      <div
        v-if="column.draggable"
        class="TableViewColumnItem__drag-icon"
        :class="dragHandleClass"
      >
        <CommonIcon id="draghandle-tw" color="#8A8D99" />
      </div>
      <div class="TableViewColumnItem__text">
        <CommonIcon
          v-if="column.icon"
          class="TableViewColumnItem__icon"
          :id="column.icon"
        />
        <EllipsisLabel v-if="column.name" :label="column.name" />
      </div>
      <div class="TableViewColumnItem__edit-container">
        <slot name="right-action"></slot>
      </div>
    </div>
    <slot name="toggle-switch"></slot>
    <slot></slot>
  </li>
</template>
<script>
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import EllipsisLabel from '@widgets/EllipsisLabel';

export default {
  name: 'TableViewColumnItem',
  components: {
    CommonIcon,
    EllipsisLabel,
  },
  props: {
    lineOver: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Object,
      required: false,
      default: () => {},
    },
    dragHandleClass: {
      type: String,
      default: '',
    },
    dragStarted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@tko/src/styles/mixins/mixins';

$neutral-20: #f5f7fa;
$neutral-30: #e6e7e8;
$indigo-30: #4461d7;

.TableViewColumnItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 35px;
  position: relative;

  &--line-over {
    border-top: 1px solid #ddd;
  }

  &--dragging {
    position: relative;
    z-index: 100;
  }

  &__drag-placeholder-item {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $indigo-30;

    &::before,
    &::after {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: inherit;
      position: absolute;
      top: -2px;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }

  &__item {
    display: flex;
  }
  &__text {
    display: flex;
    max-width: 120px;
    align-items: center;
    margin-right: 5px;
  }
  &__drag-icon {
    position: absolute;
    left: 2px;
    visibility: hidden;
    cursor: move;
  }
  &__icon {
    font-size: 14px;
    margin-right: 8px;
  }
  &__edit-container {
    width: 24px;
  }

  &:hover {
    background: $neutral-20;

    .TableViewColumnItem__drag-icon {
      visibility: visible;
    }
  }

  ::v-deep .w-toggle-switch {
    &__label {
      margin-right: 20px;
      width: 120px;
      padding-left: 0;

      .ellipsis-label {
        .w-popover__inner {
          top: 10px;
        }
      }
    }

    &__toggle {
      width: 24px;
      height: 10px;
      background-color: $neutral-30;
      box-shadow: none;

      &--on {
        background-color: $indigo-30;
      }

      &:before {
        width: 12px;
        height: 12px;
        top: -1px;
        box-shadow: 0 0 0 1px $neutral-30, 0px 1px 2px rgba(50, 51, 52, 0.1);
      }
    }
  }
}
</style>
