"use strict";

/*
   Project model
*/
define(['knockout', 'knockout-mapping', 'userSavedFilter', 'vueScaffoldingHelper'], function (ko, mapping, UserSavedFilter, vueScaffoldingHelper) {
  var Project;
  Project = class Project {
    /*  ____       _
              / ___|  ___| |_ _   _ _ __
              \___ \ / _ \ __| | | |  _ \
               ___) |  __/ |_| |_| | |_) |
              |____/ \___|\__|\__,_| .__/
                                   |_|
     * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */
    constructor(data) {
      /*  _____                 _     _   _                 _ _
                | ____|_   _____ _ __ | |_  | | | | __ _ _ __   __| | | ___ _ __ ___
                |  _| \ \ / / _ \  _ \| __| | |_| |/ _  |  _ \ / _  | |/ _ \  __/ __|
                | |___ \ V /  __/ | | | |_  |  _  | (_| | | | | (_| | |  __/ |  \__ \ - On...
                |_____| \_/ \___|_| |_|\__| |_| |_|\__,_|_| |_|\__,_|_|\___|_|  |___/
       * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */
      this.OnClickStar = this.OnClickStar.bind(this);
      this.Delete = this.Delete.bind(this);
      this.UndoDelete = this.UndoDelete.bind(this);
      this.Archive = this.Archive.bind(this);
      this.SetOwner = this.SetOwner.bind(this);
      this.OpenTrashCan = this.OpenTrashCan.bind(this);
      this.CopyProject = this.CopyProject.bind(this);
      this.ConvertProject = this.ConvertProject.bind(this);
      this.ViewUpdates = this.ViewUpdates.bind(this);
      this.ViewUpcomingEvents = this.ViewUpcomingEvents.bind(this);
      this.defaultFilters = {};
      this.UpdateData(data); // Hybrid Scaffolding - complete/uncomplete of a project may not always trigger
      // a sufficiently precise event to match, so better to perform
      // a direct data sync

      vueScaffoldingHelper.storeSync(app.hybrid.store, function (state) {
        return state.project.records[data.id];
      }, this, ['status', 'subStatus']);
      return this;
    }

    UpdateData(data) {
      var base, base1, base2, base3, base4, base5, base6, base7, base8, base9, canManageTemplates, defaultFilter, isEnabled, lastOverviewSection, pageName, pageToCheck, permissionToCheck, ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, res, sectionName;
      data.id = parseInt(data.id, 10); // Project data defaults

      if (((ref = data.company) != null ? ref.id : void 0) != null) {
        data.company.id = parseInt(data.company.id, 10);
      }

      if (data.isSelected == null) {
        data.isSelected = false;
      }

      if (data.chatChannelEnabled == null) {
        data.chatChannelEnabled = false;
      } //always set the harvestTimersEnabled


      if (data.harvestTimersEnabled == null) {
        data.harvestTimersEnabled = false;
      } //it may come as "0" or "1" from the API:


      if (typeof data.harvestTimersEnabled !== 'boolean') {
        res = parseInt(data.harvestTimersEnabled, 10);

        if (res) {
          //if res is not NaN: convert the number 0/1 to false/true
          data.harvestTimersEnabled = !!res;
        }
      }

      if ((data != null ? data.announcement : void 0) == null) {
        data.announcement = '';
      }

      if (data.isSampleProject == null) {
        data.isSampleProject = false;
      }

      if (data.isOnBoardingProject == null) {
        data.isOnBoardingProject = false;
      }

      if (data.canEdit == null) {
        data.canEdit = (app.loggedInUser.administrator() || ((ref1 = data.permissions) != null ? ref1.projectAdministrator : void 0)) && app.loggedInUser.inOwnerCompany();
      }

      if (data.cardId == null) {
        data.cardId = 0;
      }

      if (data.startDate == null) {
        data.startDate = "";
      }

      if (data.endDate == null) {
        data.endDate = "";
      }

      if (data.type == null) {
        data.type = "normal";
      }

      if (data.isPrivate == null) {
        data.isPrivate = false;
      }

      if (data.milestones == null) {
        data.milestones = {
          dueDates: {},
          numberOfMilestones: 0
        };
      } //activePages are used throughout the app as '0' or '1' values for true or false so lets update them here in one place


      if (data.activePages != null) {
        ref2 = data.activePages;

        for (pageName in ref2) {
          isEnabled = ref2[pageName];

          if (isEnabled === true) {
            data.activePages[pageName] = '1';
          } else if (isEnabled === false) {
            data.activePages[pageName] = '0';
          }
        }
      }

      if (data.portfolioBoards == null) {
        data.portfolioBoards = [];
      }

      if (((ref3 = data.boardData) != null ? ref3.board : void 0) == null) {
        data.boardData = {
          board: {
            name: "",
            id: 0,
            color: ""
          },
          card: {
            id: 0
          },
          column: {
            name: "",
            id: 0,
            color: "transparent"
          }
        };
      }

      if (data.boardData.column.color === '') {
        data.boardData.column.color = 'transparent';
      }

      data.boardColumn = data.boardData.column;
      data.cardId = parseInt(data.boardData.card.id, 10);

      if ((data != null ? data.dateArchived : void 0) == null) {
        data.dateArchived = '';
      }

      if ((data != null ? data.archivedByUserId : void 0) == null) {
        data.archivedByUserId = 0;
      }

      if ((data != null ? data.archivedByUserName : void 0) == null) {
        data.archivedByUserName = '';
      }

      if ((data != null ? data.endDate : void 0) == null) {
        data.endDate = '';
      }

      if ((data != null ? data.tags : void 0) == null) {
        data.tags = [];
      }

      if ((data != null ? data.people : void 0) == null) {
        data.people = [];
      }

      if (data.category == null) {
        data.category = {
          id: '',
          name: '',
          color: ''
        };
      }

      if (data.category != null) {
        if ((base = data.category).color == null) {
          base.color = '';
        }

        if (data.category.color === '') {
          data.category.color = 'transparent';
        } else {
          data.category.color = app.utility.GetValidHex(data.category.color, false);
        }
      } // handle default settings client side


      if (data.tasksStartPage == null) {
        data.tasksStartPage = 'default';
      } // Bit of a hack - we want 'overview' not 'projectoverview'


      if (data.startPage === 'projectoverview') {
        data.startPage = 'overview';
      } else if (data.startPage === 'notebook') {
        data.startPage = 'notebooks';
      } else if (data.startPage === 'riskregister') {
        data.startPage = 'risks';
      }

      if (data.startPage === 'tasks') {
        if ((ref4 = data.tasksStartPage) === 'default' || ref4 === 'list') {
          data.startPage = 'list';
        } else {
          data.startPage = data.tasksStartPage;
        }
      }

      if ((ref5 = data.startPage) === 'list' || ref5 === 'board' || ref5 === 'gantt' || ref5 === 'table') {
        data.startPage = `tasks/${data.startPage}`;
      }

      if (data.startPage === 'overview') {
        // if defaulting to the start page, check if the user was previously on the activity tab
        // if we have stored a previous selection in memory, then use that as the default overview tab
        if (data.overviewStartPage == null) {
          data.overviewStartPage = 'default';
        }

        if (data.overviewStartPage === 'default') {
          lastOverviewSection = (ref6 = app.cache.get("last-overview-section")) != null ? ref6 : 'summary';

          if (lastOverviewSection === 'summary' || lastOverviewSection === 'activity') {
            data.startPage = `overview/${lastOverviewSection}`;
          } else {
            data.startPage = "overview/summary";
          }
        } else if (data.overviewStartPage === 'summary') {
          data.startPage = 'overview/summary';
        } else if (data.overviewStartPage === 'activity') {
          data.startPage = 'overview/activity';
        }
      } // We need to ensure that the user has access to the currently set project feature
      // If the feature isn't active/enabled then we need to set the default page to Overview
      // If the feature is active but the user doesn't have the right permissions, then set it to Oveview also


      if (data.activePages != null && data.permissions != null && data.startPage !== 'comments') {
        pageToCheck = data.startPage === 'risks' ? 'riskRegister' : data.startPage;

        permissionToCheck = function () {
          switch (pageToCheck) {
            case 'tasks':
            case 'milestones':
            case 'tasks/list':
            case 'tasks/board':
            case 'tasks/gantt':
            case 'tasks/table':
              return "viewTasksAndMilestones";

            case 'messages':
            case 'files':
              return "viewMessagesAndFiles";

            case 'time':
              return "viewTimeLog";

            case 'notebooks':
              return "viewNotebook";

            case 'riskRegister':
              return "viewRiskRegister";

            case 'links':
              return "viewLinks";

            case 'billing':
              return "canAccessInvoiceTracking";

            case 'budget':
              return "canViewProjectBudget";

            case 'forms':
              return "canViewForms";
          }
        }();

        if (pageToCheck.indexOf('overview') === -1 && (data.activePages[pageToCheck] === '0' || !data.permissions[permissionToCheck])) {
          data.startPage = 'overview/summary';
        }
      } // If this is a template, set various edit permissions to false unless they have modifyProjectTemplates permission


      canManageTemplates = app.loggedInUser.administrator() || app.loggedInUser.permissions.canManageProjectTemplates();
      ref8 = (ref7 = data.defaultFilters) != null ? ref7 : {};

      for (sectionName in ref8) {
        defaultFilter = ref8[sectionName];

        if (this.defaultFilters[sectionName] != null) {
          this.defaultFilters[sectionName].Update(defaultFilter);
        } else {
          this.defaultFilters[sectionName] = new UserSavedFilter(defaultFilter);
        }
      } // we need to initialise some defaults for project permissions in case the api doesn't return them


      if (data.permissions != null) {
        if ((base1 = data.permissions).canAddProjectUpdate == null) {
          base1.canAddProjectUpdate = false;
        }

        if ((base2 = data.permissions).canViewProjectUpdate == null) {
          base2.canViewProjectUpdate = false;
        }

        if ((base3 = data.permissions).canViewProjectMembers == null) {
          base3.canViewProjectMembers = false;
        }

        if ((base4 = data.permissions).canViewProjectBudget == null) {
          base4.canViewProjectBudget = false;
        }

        if ((base5 = data.permissions).canAddRisks == null) {
          base5.canAddRisks = false;
        }

        if ((base6 = data.permissions).canAddExpenses == null) {
          base6.canAddExpenses = true; // We don't have a real setting here, but it's set to false for project templates read only
        }

        if ((base7 = data.permissions).viewRiskRegister == null) {
          base7.viewRiskRegister = false;
        }

        if ((base8 = data.permissions).canViewForms == null) {
          base8.canViewForms = false;
        }

        if ((base9 = data.permissions).canAddForms == null) {
          base9.canAddForms = false;
        }
      }

      if (data.update == null) {
        data.update = {
          id: 0,
          health: 0
        };
      }

      if (data.owner == null) {
        data.owner = {
          id: 0,
          fullName: ''
        };
      }

      if (data.projectOwnerId == null) {
        data.projectOwnerId = 0;
      }

      if (data.lastWorkedOn == null) {
        data.lastWorkedOn = '';
      }

      if (data.owner.id !== 0) {
        data.projectOwnerId = data.owner.id;
      }

      if (data.owner.avatarUrl != null) {
        // short term Desk S3 malicious code workaround
        data.owner.avatarUrl = data.owner.avatarUrl.replace(/https:\/\/s3.amazonaws.com\/tw-desk\/i\//gi, "https://tw-desk-files.teamwork.com/i/");
      }

      mapping.fromJS(data, {
        ignore: ['defaultFilters', 'milestones']
      }, this);
      this.milestones = ko.observable(data.milestones);

      if (this.isActive == null) {
        this.isActive = ko.pureComputed(() => {
          return this.status() !== 'archived';
        });
      }
      /*
       * Extras and defaults
       */


      this.isTemplate = ko.pureComputed(() => {
        return this.type() === app.consts.PROJECT_TEMPLATE;
      });
      this.duration = ko.pureComputed(() => {
        var days, ref10, ref9, startDate;

        if (data.type === 'projects-template') {
          startDate = app.consts.DATES.teamworkEpoch().format('YYYYMMDD');
        } else if (((ref9 = this.minMaxAvailableDates) != null ? ref9.suggestedStartDate : void 0) != null) {
          startDate = ko.unwrap(this.minMaxAvailableDates.suggestedStartDate);
        }

        if ((ref10 = this.minMaxAvailableDates) != null ? typeof ref10.deadlinesFound === "function" ? ref10.deadlinesFound() : void 0 : void 0) {
          days = app.utility.getDatesDuration(startDate, this.minMaxAvailableDates.suggestedEndDate(), false);
        } else {
          days = 1;
        }

        return {
          label: days === 1 ? app.tl("[_s] Day", days) : app.tl("[_s] Days", days),
          numDays: days
        };
      });

      if (this.lastView == null) {
        this.lastView = ko.observable(app.cache.get("project-" + this.id() + "-view") || "new");
      }

      this.componentName = 'Project';
      return this;
    }

    OnClickStar() {
      this.starred(!this.starred());
      app.api.put(`./projects/${this.id()}/` + (this.starred() ? "star.json" : "unstar.json"), {}).then(result => {
        var ref;

        if (result.response.STATUS !== "OK") {
          app.flash.Error(app.tl('Oops! Something went wrong, please try again'));
          this.starred(!this.starred());
        } // Tipped.hideAll()


        app.ko.postbox.publish('projects-update-stats', {});
        app.ko.postbox.publish('allTWEvents', {
          eventTime: new Date(),
          itemType: 'project',
          actionType: app.consts.ACTIONTYPE.UPDATED,
          itemId: this.id(),
          subType: this.starred() ? "starred" : "unstarred"
        });

        if (((ref = app.cachedProjects) != null ? ref[this.id()] : void 0) != null) {
          return app.cachedProjects[this.id()].starred(this.starred());
        }
      });
    }

    ViewHistory() {
      if (!app.featureFlip.check('audittrail', {
        unwrap: true
      })) {
        return;
      }

      return app.quickView.show('audit', {
        itemId: this.id(),
        itemType: 'project'
      });
    }

    Edit() {
      if (!this.isActive()) {
        return;
      }

      if (!(this.permissions.projectAdministrator() || app.loggedInUser.administrator())) {
        return;
      }

      if (this.isTemplate()) {
        return app.modal.Show('addOrEditProjectWizard', {
          id: this.id(),
          type: 'projects-template',
          context: 'detail',
          clickOutsideToClose: false
        });
      } else {
        return app.modal.Show('addOrEditProjectWizard', {
          id: this.id(),
          clickOutsideToClose: false
        });
      }
    }

    EditDates() {
      if (!this.isActive()) {
        return;
      }

      if (!(this.permissions.projectAdministrator() || app.loggedInUser.administrator())) {
        return;
      }

      return app.modal.Show('addOrEditProjectWizard', {
        id: this.id(),
        clickOutsideToClose: false
      });
    }

    EditDescription() {
      if (!this.isActive()) {
        return;
      }

      if (!(this.permissions.projectAdministrator() || app.loggedInUser.administrator())) {
        return;
      }

      return app.modal.Show('editAnnouncement', {
        type: 'projectdescription',
        projectId: this.id()
      });
    }

    EditColumn() {
      if (!this.isActive()) {
        return;
      }

      if (!(this.permissions.projectAdministrator() || app.loggedInUser.administrator())) {
        return;
      }

      return app.modal.Show('changeBoardColumn', {
        boardId: this.boardData.board.id(),
        boardType: 'project',
        item: this
      });
    }

    EditCategory() {
      if (!this.isActive()) {
        return;
      }

      if (!(this.permissions.projectAdministrator() || app.loggedInUser.administrator())) {
        return;
      }

      if (this.isTemplate()) {
        return app.modal.Show('addOrEditProjectWizard', {
          id: this.id(),
          tab: 'advanced',
          type: 'projects-template',
          context: 'detail',
          clickOutsideToClose: false
        });
      } else {
        return app.modal.Show('addOrEditProjectWizard', {
          id: this.id(),
          tab: 'advanced',
          clickOutsideToClose: false
        });
      }
    }

    QuickView() {
      return app.quickView.show('project', {
        id: this.id(),
        project: this,
        title: ko.observable(`<a href='#projects/${this.id()}'>` + app.utility.SafeHTML(this.name()) + "</a>"),
        projectId: this.id(),
        isQuickView: true
      });
    }

    Complete() {
      Tipped.hideAll();
      app.api.put(`projects/${this.id()}/complete.json`).then(() => {
        app.flash.Success(app.tl('This project has been marked complete'));

        if (this.subStatus != null) {
          this.subStatus('completed');
        }

        if (app.currentRoute().project != null) {
          return app.ko.postbox.publish('allTWEvents', {
            eventTime: new Date(),
            itemType: 'project',
            actionType: app.consts.ACTIONTYPE.UPDATED,
            itemId: this.id(),
            subType: 'completed'
          });
        } else {
          return app.ko.postbox.publish("projects-updated", {});
        }
      });
    }

    Uncomplete() {
      Tipped.hideAll();
      app.api.put(`projects/${this.id()}/uncomplete.json`).then(() => {
        var end, start, status, today;
        app.flash.Success(app.tl('This project has been marked incomplete'));
        status = 'current';
        today = moment().startOf('day');
        start = moment(this.startDate()).startOf('day');
        end = moment(this.endDate()).startOf('day');

        if (start.diff(today, 'days') > 0) {
          status = 'upcoming';
        } else if (end.diff(today, 'days') < 0) {
          status = 'late';
        }

        if (this.subStatus != null) {
          this.subStatus(status);
        }

        if (app.currentRoute().project != null) {
          return app.ko.postbox.publish('allTWEvents', {
            eventTime: new Date(),
            itemType: 'project',
            actionType: app.consts.ACTIONTYPE.UPDATED,
            itemId: this.id(),
            subType: 'uncompleted'
          });
        } else {
          return app.ko.postbox.publish("projects-updated", {});
        }
      });
    }
    /* _____
               |  _ \(_)___ _ __ | | __ _ _   _
               | | | | / __|  _ \| |/ _  | | | |
               | |_| | \__ \ |_) | | (_| | |_| |
               |____/|_|___/ .__/|_|\__,_|\__, |
                           |_|            |___/
     * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */

    /* __        __         _
               \ \      / /__  _ __| | _____ _ __ ___
                \ \ /\ / / _ \|  __| |/ / _ \  __/ __|
                 \ V  V / (_) | |  |   <  __/ |  \__ \
                  \_/\_/ \___/|_|  |_|\_\___|_|  |___/
     * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */


    BrowseTo() {
      app.GoTo('projects/' + this.urlCode() + '/' + this.lastView());
    }

    Delete(id) {
      var question, title;

      if (!id) {
        id = this.id();
      }

      title = app.tl('Confirm Delete');
      question = app.tl('This project will be moved to the trash can. After [_s] days this project and all attached files will be completely removed.', '30');
      app.modal.Show('confirm', {
        title: title,
        text: question,
        callback: result => {
          if (result.wasCancelled) {
            return;
          }

          return app.api.delete(`projects/${id}.json`).then(result => {
            var returnTo;
            this.status('deleted');
            app.ko.postbox.publish('allTWEvents', {
              eventTime: new Date(),
              itemType: 'project',
              actionType: app.consts.ACTIONTYPE.DELETED,
              itemId: id
            });

            if (app.currentRoute().projectId != null && app.currentRoute().projectId === id) {
              returnTo = `#/projects/${app.currentRoute().projectId}`;
              app.flash.Success(app.tl('Project deleted'), {
                undo: () => {
                  return this.UndoDelete(returnTo);
                }
              });
              return app.GoTo("#");
            } else {
              return app.flash.Success(app.tl('Project deleted'), {
                undo: this.UndoDelete
              });
            }
          });
        }
      });
    }

    UndoDelete(returnTo) {
      var url;
      url = `trashcan/projects/${this.id()}/restore.json`;
      return app.api.put(url, {}).then(result => {
        var infoMsg, ref;
        infoMsg = "";

        if (result.response.STATUS === "OK" && result.response.usersNotAdded != null && result.response.usersNotAdded.length) {
          if (result.response.usersNotAdded.length === 1) {
            infoMsg = app.tl('A client user was removed as they exceeded their project limit');
          } else {
            infoMsg = app.tl('[_s] client users were removed as they exceeded their project limits', result.response.usersNotAdded.length);
          }
        }

        app.ko.postbox.publish('allTWEvents', {
          eventTime: new Date(),
          itemType: 'project',
          actionType: app.consts.ACTIONTYPE.UPDATED,
          itemId: this.id()
        });

        if (((ref = app.cachedProjects) != null ? ref[this.id()] : void 0) != null) {
          app.cachedProjects[this.id()].status('active');
        }

        app.flash.Success(app.tl('Project restored', infoMsg));

        if (typeof returnTo === 'string') {
          return app.GoTo(returnTo);
        }
      });
    }

    Archive() {
      var question, status, title;
      status = ['active', 'inactive'][!!this.isActive() / 1];

      if (status === 'active') {
        title = app.tl('Unarchive this project?');
        question = app.tl('Are you sure you want to unarchive this project?');
      } else {
        title = app.tl('Archive this project?');
        question = app.tl('Are you sure you want to archive this project?');
      }

      return app.modal.Show('confirm', {
        title: title,
        text: question,
        callback: result => {
          if (result.wasCancelled) {
            return;
          }

          return app.api.put(`projects/${this.id()}/archive.json`, {
            status
          }).then(result => {
            var msg;

            if (result.response.STATUS === "OK") {
              app.UpdateProjectAndPermissions(this.id());
              app.ko.postbox.publish("projects-updated", {});

              if (result.response.usersNotAdded != null && result.response.usersNotAdded.length) {
                if (result.response.usersNotAdded.length === 1) {
                  app.flash.Info(app.tl('A client user was removed as they exceeded their project limit'));
                } else {
                  app.flash.Info(app.tl('[_s] client users were removed as they exceeded their project limits', result.response.usersNotAdded.length));
                }
              }

              return app.ko.postbox.publish('allTWEvents', {
                eventTime: new Date(),
                itemType: 'project',
                actionType: app.consts.ACTIONTYPE.UPDATED,
                subType: 'archived',
                itemId: this.id()
              });
            } else if (result.response.STATUS === "UPGRADE_REQUIRED") {
              title = app.tl('Oops, upgrade required!');
              msg = app.tl('You have already reached the maximum number of projects allowed with your current price plan');

              if (app.loggedInUser.inOwnerCompany() && app.loggedInUser.administrator()) {
                app.GoTo('settings/subscription');
              } else {
                title = app.tl('Project limit reached');
                msg = app.tl('Please contact the site owner');
              }

              return app.flash.Error(title, msg);
            }
          });
        }
      });
    }

    SetOwner() {
      return app.modal.Show('changeProjectOwner', {
        projectId: this.id(),
        user: this.owner
      });
    }

    OpenTrashCan() {
      return app.modal.Show('trashCan', {
        item: this
      });
    }

    CopyProject() {
      return app.modal.Show('copyProject', {
        id: this.id()
      });
    }

    ConvertProject() {
      return app.modal.Show('copyProject', {
        id: this.id(),
        saveAsTemplate: true
      });
    }

    ViewUpdates() {
      return app.quickView.show('projectUpdates', {
        projectId: this.id()
      });
    }

    ViewUpcomingEvents() {
      // app.quickView.show 'events-new',
      //     projectId: @projectId
      return app.quickView.show('events-new', {
        title: app.tl('Events'),
        projectId: this.id(),
        // startDate: moment().startOf('day')
        // endDate: moment(@formattedEndDate(), 'YYYYMMDD') if @formattedEndDate()?
        isQuickView: true
      });
    }

    LoadUpdate(opts) {
      var endPoint;
      endPoint = app.prefixApi(`projects/${this.id()}.json?getPermissions=true&getNotificationSettings=true&getActivePages=true&getDateInfo=true&getEmailAddress=true&formatMarkdown=false&includeProjectOwner=true&includeCustomFields=true`, 'API_PREFIX_V2', 'API_PREFIX_V2');
      return app.api.get(endPoint).then(result => {
        return this.UpdateData(result.response.project);
      });
    }

  };
  /*  ____            _
            |  _ \  ___  ___| |_ _ __ ___  _   _
            | | | |/ _ \/ __| __|  __/ _ \| | | |
            | |_| |  __/\__ \ |_| | | (_) | |_| |
            |____/ \___||___/\__|_|  \___/ \__, |
                                           |___/
   * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */
  //<-------------------------------------------------------------->
  //                          Mapping
  //<-------------------------------------------------------------->

  Project.mapping = {
    'projects': {
      'key': function (data) {
        return ko.unwrap(data.id);
      },
      'create': function (options) {
        return new Project(options.data);
      }
    }
  };
  return Project;
});