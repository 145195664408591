export default async (store, { actionType, projectId, itemId }) => {
  if (['edited', 'deleted'].includes(actionType)) {
    const pid =
      projectId ||
      (store.state.milestone.records[itemId] &&
        store.state.milestone.records[itemId].projectId);
    if (pid) {
      await store.dispatch('project/tasklists/changeNotification', pid);
    }
    // If milestones have been linked/unlinked, it will affect tasks
    Object.values(store.state.tasklist.records)
      .filter((tl) => tl.status !== 'deleted' && tl.projectId === pid)
      .forEach(({ id }) => {
        store.dispatch('tasklist/checkTasksForMilestone', id);
      });
  }
};
