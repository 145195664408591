<template>
  <div
    role="switch"
    :aria-checked="checked"
    :aria-disabled="disabled"
    :tabindex="disabled ? -1 : 0"
    class="group outline-none"
    @click="isChecked = !isChecked"
    @keydown.space="isChecked = !isChecked"
  >
    <input
      type="checkbox"
      v-bind="$attrs"
      :checked="isChecked"
      :disabled="disabled"
      tabindex="-1"
      class="peer absolute opacity-0"
    />
    <div :class="switchControl" :style="tokens" />
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import optionPropValidator from '../../../helpers/optionPropValidator';
import { switchSizes, defaultSize } from './constants';

export default {
  name: 'FSwitch',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: defaultSize,
      validator: optionPropValidator(switchSizes, 'size'),
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const change = (value) => emit('change', value);
    const isChecked = computed({
      get() {
        return props.checked;
      },
      set(newVal) {
        if (props.disabled) {
          return;
        }
        change(newVal);
      },
    });

    const tokens = {
      // CSS custom properties from :root are not able to be accessed by pseudo-elements
      // Tokens are made available by mapping them on to their host element
      // Note: a different name from the source token is mandatory
      '--glow-bg-hover': 'var(--token-c-switch-glow-background-hover)',
      '--glow-bg-focus': 'var(--token-c-switch-glow-background-focus)',
      '--glow-bg-active': 'var(--token-c-switch-glow-background-active)',
      '--glow-bg-checked-hover':
        'var(--token-c-switch-glow-background-checked-hover)',
      '--glow-bg-checked-focus':
        'var(--token-c-switch-glow-background-checked-focus)',
      '--glow-bg-checked-active':
        'var(--token-c-switch-glow-background-checked-active)',
    };

    const switchControl = computed(() => [
      // Base
      'relative',
      'cursor-pointer',
      'peer-disabled:cursor-auto',
      props.size === switchSizes.LARGE ? 'h-5 w-10' : 'h-4 w-7',
      'rounded-full',
      'transition-all',
      'bg-[color:var(--token-c-switch-base-background)]',
      'peer-checked:bg-[color:var(--token-c-switch-base-background-checked)]',
      'peer-disabled:bg-[color:var(--token-c-switch-base-background-disabled)]',
      'peer-checked:peer-disabled:bg-[color:var(--token-c-switch-base-background-checked-disabled)]',

      // Glow
      "before:content-['']",
      'before:absolute',
      'before:m-0.5',
      props.size === switchSizes.LARGE
        ? 'before:w-4 before:h-4'
        : 'before:w-3 before:h-3',
      'before:rounded-full',
      'before:transition-all',
      'before:translate-x-0',
      props.size === switchSizes.LARGE
        ? 'before:peer-checked:translate-x-5'
        : 'before:peer-checked:translate-x-3',
      'before:scale-100',
      'before:group-hover:scale-200',
      'before:group-focus:scale-200',
      'before:group-active:scale-200',
      'before:opacity-25',
      'before:peer-disabled:opacity-0',
      'before:bg-transparent',
      'before:group-hover:bg-[color:var(--glow-bg-hover)]',
      'before:group-focus:bg-[color:var(--glow-bg-focus)]',
      'before:group-active:bg-[color:var(--glow-bg-active)]',
      'before:group-hover:peer-checked:bg-[color:var(--glow-bg-checked-hover)]',
      'before:group-focus:peer-checked:bg-[color:var(--glow-bg-checked-focus)]',
      'before:group-active:peer-checked:bg-[color:var(--glow-bg-checked-active)]',

      // Dot
      "after:content-['']",
      'after:absolute',
      'after:m-0.5',
      props.size === switchSizes.LARGE
        ? 'after:w-4 after:h-4'
        : 'after:w-3 after:h-3',
      'after:rounded-full',
      'after:transition-all',
      'after:translate-x-0',
      props.size === switchSizes.LARGE
        ? 'after:peer-checked:translate-x-5'
        : 'after:peer-checked:translate-x-3',
      'after:bg-white',
    ]);

    return {
      tokens,
      switchControl,
      change,
      isChecked,
    };
  },
};
</script>
