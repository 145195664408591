<template>
  <button v-on="$listeners" :class="{ button__underline: underline }">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonText',
  props: {
    underline: {
      type: Boolean,
      default: false,
    },
  },
  display: 'ButtonText',
};
</script>
<style lang="scss" scoped>
button {
  background: transparent;
  margin: 0;
  color: #4461d7;
  font-size: 14px;
  line-height: 20px;
  border: none;
  padding: 0;
}

.button__underline {
  &:hover {
    text-decoration: underline;
  }
}
</style>
