import ko from 'knockout';
import { frameOrigin } from '@/platform/composables/useLightspeedBridge';

export const isInLightspeed = ko.observable(false);
export const lightspeedUiState = ko.observable({});

export function postMessageToLightspeed(name, opts) {
  if (isInLightspeed()) {
    window.parent.postMessage({ name, ...opts }, frameOrigin);
  }
}
