<template>
  <div class="w-full">
    <HelpCenterSectionTitle :title="title" />
    <div class="help-center-items">
      <slot>
        <HelpCenterMenuItem
          v-for="(item, index) in items"
          :key="index"
          :label="item.label"
          :icon="item.icon"
          @click="$emit('menuItemClicked', item)"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import HelpCenterMenuItem from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenuItem.vue';
import HelpCenterSectionTitle from '@/platform/components/side-nav/panels/help-center/common/HelpCenterSectionTitle.vue';

export default {
  name: 'HelpCenterGettingStartedNavItem',
  components: {
    HelpCenterMenuItem,
    HelpCenterSectionTitle,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
};
</script>
<style scoped lang="scss">
.help-center-items {
  & button {
    margin-bottom: 8px;
  }
  & button:last-child {
    margin-bottom: 0;
  }
}
</style>
