<template>
  <div
    @mouseenter="showPopover = true"
    @mouseleave="showPopover = false"
    :class="['table-cell-task-comments', type]"
  >
    <v-popover
      popover-class="comment-popover-outer"
      :open.sync="showPopover"
      trigger="hover focus"
      :placement="openDirection"
      :delay="{ hide: 300, show: 100 }"
      @apply-show="getComments"
      offset="4"
      popover-arrow-class="hidden"
      :boundaries-element="boundariesElement"
      :auto-hide="false"
    >
      <button @click="openQuickView" class="comment-btn">
        <CommonIcon
          class="u-common-icon"
          :id="isUnread ? 'comment-solid' : 'comment-light'"
          :color="isUnread ? '#4ECD97' : '#0B0E1F'"
        />
        <span class="comment-btn__text">
          {{ commentsCount }}
        </span>
      </button>
      <template slot="popover">
        <div
          @mouseenter="showPopover = true"
          @mouseleave="showPopover = false"
          class="sectionContent w-tipped-last-comments comment-popover"
        >
          <p
            v-if="isLoading"
            class="w-tipped-last-comments__loading comment-loader"
          >
            <i class="fa fa-spinner fa-spin" aria-hidden="true" />
            {{ $t('Loading') }}
          </p>

          <p v-else-if="errorMsg && !isLoading">
            {{ errorMsg }}
          </p>

          <div
            v-else
            class="w-tipped-last-comments__comment m--none p--none comment"
          >
            <div class="comment-popover__header">
              <img
                class="comment-popover__avatar"
                v-if="lastComment['author-avatar-url']"
                :src="lastComment['author-avatar-url']"
              />
              <span class="comment-popover__name">
                {{ lastComment['author-firstname'] }}
                {{ lastComment['author-lastname'] }}
              </span>
              <span class="comment-popover__date commentDate">
                {{ `(${formattedDate})` }}
              </span>
              <span
                v-if="
                  lastComment.private == 1 && lastComment['lockdown-id'] > 0
                "
                class="private tipped ml--small"
                v-tooltip="{
                  content: $t('This item is marked private'),
                  placement: 'top',
                  boundariesElement: 'document.body',
                }"
              >
                <CommonIcon id="lock-solid" color="#ED818E" />
              </span>
            </div>

            <div
              v-html="lastComment['html-body']"
              class="comment-popover__body"
            />

            <div class="comment-popover__footer">
              <div
                v-if="commentAttachmentsCount > 0"
                class="comment-popover__attachments"
                v-tooltip="{
                  content: $t(
                    `[0] ${filesText} attached`,
                    commentAttachmentsCount,
                  ),
                  placement: 'top',
                  boundariesElement: boundariesElement,
                }"
              >
                <CommonIcon
                  class="u-common-icon"
                  id="paperclip"
                  color="#6D6F80"
                />
                <span>{{ commentAttachmentsCount }}</span>
              </div>

              <!-- hack to make actions below always aligned to
                  the right if task.numAttachments <= 0 -->
              <div class="spacer" />

              <div>
                <template v-if="lastComment.isRead != 1">
                  <button
                    @click="onMarkAsRead(lastComment)"
                    class="comment-popover__action text-light-gray btn-reset"
                  >
                    {{ $t('Mark as Read') }}
                  </button>
                </template>

                <button
                  @click="openQuickView"
                  class="comment-popover__action text-light-gray btn-reset"
                >
                  {{ $t('View all comments') }}
                </button>
                <button
                  @click="onReplyToComment"
                  class="comment-popover__action btn btn-primary"
                >
                  {{ $t('Reply') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import FileModel from 'fileModel';
import CommentsAPI from '@/platform/data/comments';
import toTaskModel from '@/utils/helpers/toTaskModel';

export default {
  name: 'Comments',
  display: 'Comments',
  components: { CommonIcon },
  data() {
    return {
      isLoading: false,
      showPopover: false,
      lastComment: {},
      errorMsg: '',
    };
  },
  props: {
    item: { type: Object, required: true },
    type: { type: String, required: true },
    openDirection: { type: String, default: 'top-middle' },
  },
  methods: {
    openQuickView() {
      this.showPopover = false;
      this.model.OnQuickView('comments');
    },
    onReplyToComment() {
      this.showPopover = false;
      this.$store.dispatch('modals/tko/open', {
        id: 'addOrEditComment',
        args: {
          item: this.model,
          projectId: this.item.projectId,
          type: this.type,
        },
      });
    },
    async onMarkAsRead(item) {
      try {
        await CommentsAPI.markAsRead({ id: item.id });
        this.$store.dispatch(
          'notifications/flashes/success',
          Vue.t('Comment marked as read'),
        );
        this.showPopover = false;
      } catch (err) {
        this.$store.dispatch(
          'notifications/flashes/error',
          Vue.t('There was an unexpected error, please try again.'),
        );
      }
    },
    async getComments() {
      this.errorMsg = '';
      this.isLoading = true;
      try {
        const { comments } = await CommentsAPI.fetchByType({
          id: this.item.id,
          type: this.itemType,
        });
        this.lastComment = comments[comments.length - 1];
      } catch (err) {
        this.errorMsg = Vue.t(
          'There was an error loading the comments, please try again later.',
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    isUnread() {
      return (
        this.item.meta?.commentStats?.read < this.item.meta?.commentStats?.total
      );
    },
    formattedDate() {
      return moment(this.lastComment.datetime).format(
        'MMM DD, YYYY [at] H:mmA',
      );
    },
    filesText() {
      return this.commentAttachmentsCount > 1 ? 'files' : 'file';
    },
    commentAttachmentsCount() {
      return parseInt(this.lastComment.attachments_count, 10);
    },
    model() {
      return this.type === 'task'
        ? toTaskModel(this.item)
        : new FileModel({
            ...this.item,
            version: this.item.latestFileVersionNo,
          });
    },
    itemType() {
      return this.type === 'task' ? 'tasks' : 'files';
    },
    commentsCount() {
      return this.type === 'task'
        ? this.item.meta?.commentStats?.total
        : this.item.commentsCount;
    },
    boundariesElement() {
      return document.body;
    },
  },
};
</script>

<style lang="scss">
@import '~@tko/src/styles/variables/variables';

$light-gray: #6d6f80;
$darker-indigo: #3c55bd;
$font-size--base: 14px;

.table-cell-task-comments {
  margin-right: 12px;
  color: $light-gray;
  display: flex;
  align-items: center;
  height: 28px;

  .comment-btn {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    background: none;
    border: none;

    &__text {
      font-size: $font-size--h4;
      color: $color--text-tinted-dark;
      margin-left: 3px;
      font-weight: $font-weight--normal;
      text-align: center;
    }
  }

  .u-common-icon {
    font-size: $font-size--btn;
  }
}

.comment-popover-outer {
  .w-popover__inner {
    box-shadow: 0px 4px 12px rgba(11, 14, 31, 0.16);
  }

  .comment-loader {
    min-height: 100px;
  }
}

.comment-popover {
  display: flex;
  justify-content: space-between;
  width: 392px;
  min-height: 80px;
  padding: $padding--large;
  font-size: $font-size--base;

  .btn-reset {
    background: none;
    border: none;
    padding: 0;
  }

  .spacer {
    flex: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $margin--medium;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
  }

  &__name {
    color: $color--text-tinted-dark;
    font-weight: $font-weight--semibold;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 133px;
  }

  &__date {
    color: $light-gray;
  }

  &__body {
    color: $color--text-tinted-dark;
    max-height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: $font-size--base;
    line-height: 20px;
    padding-left: $padding--xx-large;
    padding-right: $padding--large;
    margin-bottom: $margin--large;

    img {
      display: none;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding-left: $padding--xx-large;
  }

  &__attachments {
    font-size: $font-size--base;
    color: $light-gray;
  }

  &__action {
    font-size: $font-size--base;
    margin-right: $margin--medium;

    &:last-of-type {
      margin-right: 0;
    }

    &.text-light-gray {
      color: $color--text-tinted-dark;
      font-weight: $font-weight--normal;

      &:hover,
      &:focus {
        color: $color--text-tinted-dark;
      }
    }
  }
}
</style>
