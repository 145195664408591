<template>
  <div class="flex flex-col gap-2 overflow-y-auto flex-1 mx-6 pb-10">
    <Transition mode="out-in">
      <HelpCenterSearchSkeleton v-if="loading" />
      <div
        v-else-if="!loading && searchResults.length > 0"
        class="flex flex-col gap-2"
        :key="searchTerm"
      >
        <HelpCenterMenuItem
          v-for="result of searchResults"
          :key="result.id"
          :label="result.value"
          icon="file"
          @click="
            $emit(
              'articleClicked',
              result.categorySlug,
              result.articleSlug,
              result.value,
            )
          "
        />
      </div>
      <HelpCenterSearchBlank v-else />
    </Transition>
  </div>
</template>

<script>
import HelpCenterMenuItem from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenuItem.vue';
import HelpCenterSearchBlank from '@/platform/components/side-nav/panels/help-center/common/HelpCenterSearchBlank.vue';
import HelpCenterSearchSkeleton from '@/platform/components/side-nav/panels/help-center/common/HelpCenterSearchSkeleton.vue';

export default {
  name: 'HelpCenterSearchResults',
  components: {
    HelpCenterMenuItem,
    HelpCenterSearchBlank,
    HelpCenterSearchSkeleton,
  },
  props: {
    searchResults: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
