<template>
  <div class="w-full mb-6">
    <div class="help-center-items">
      <HelpCenterMenu
        :title="Vue.t('Getting Started')"
        :items="getItems()"
        @menuItemClicked="
          $parent.$emit(
            'gettingStartedNavItemClicked',
            $event.categorySlug,
            $event.articleSlug,
            $event.label,
          )
        "
      />
    </div>
  </div>
</template>

<script>
import { useCurrentAccount } from '@teamwork/use';
import { computed, Vue2 as Vue } from 'vue-demi';
import HelpCenterMenu from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenu.vue';

export default {
  name: 'HelpCenterGettingStartedNavItem',
  components: {
    HelpCenterMenu,
  },
  props: {
    title: {
      type: String,
      default: 'Getting Started',
    },
  },
  setup() {
    const account = useCurrentAccount();
    const isAccountPaid = computed(() => {
      return account.value?.isPaid;
    });

    return {
      isAccountPaid,
      Vue,
    };
  },
  methods: {
    // A method that returns the menu items based on the account type.
    getItems() {
      if (this.isAccountPaid) {
        return this.menuItemsPaid;
      }
      return this.menuItemsTrial;
    },
  },
  data() {
    return {
      menuItemsTrial: [
        {
          label: 'Create a project',
          categorySlug: 'using-teamwork',
          articleSlug: 'adding-a-project',
        },
        {
          label: 'Project structure',
          categorySlug: 'efficiency',
          articleSlug: 'hierarchy-of-information-in-teamwork',
        },
        {
          label: 'Time tracking',
          categorySlug: 'getting-started',
          articleSlug: 'time-tracking-overview',
        },
        {
          label: 'Reports',
          categorySlug: 'project-reports',
          articleSlug: 'reporting-options-for-teamwork',
        },
        {
          label: 'User types & companies',
          categorySlug: 'people',
          articleSlug: 'license-types',
        },
        {
          label: 'Resource management',
          categorySlug: 'schedule',
          articleSlug: 'resource-scheduling-overview',
        },
        {
          label: 'Invite your team',
          categorySlug: 'using-teamwork',
          articleSlug: 'inviting-people-to-your-teamwork-site',
        },
      ],
      menuItemsPaid: [
        {
          label: 'My work',
          categorySlug: 'my-work',
          articleSlug: 'using-the-my-work-table-view',
        },
        {
          label: 'Create work',
          categorySlug: 'table-view',
          articleSlug: 'adding-tasks-in-table-view',
        },
        {
          label: 'Communicate on work',
          categorySlug: 'comments',
          articleSlug: 'commenting-on-items',
        },
        {
          label: 'Complete work',
          categorySlug: 'project-sections',
          articleSlug: 'completing-and-reopening-tasks',
        },
        {
          label: 'Track time',
          categorySlug: 'getting-started',
          articleSlug: 'time-tracking-overview',
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
h2 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  color: #646d7d;
}
.help-center-items {
  & button {
    margin-bottom: 8px;
  }
  & button:last-child {
    margin-bottom: 0;
  }
}
</style>
