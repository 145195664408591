import { computed, unref } from 'vue-demi';
import {
  buttonSizes,
  defaultButtonSize,
  buttonVariants,
  buttonTypes,
  defaultButtonType,
  buttonAlignments,
} from './consts.js';
import optionPropValidator from '../../../helpers/optionPropValidator';

export const btnProps = {
  active: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: defaultButtonSize,
    validator: optionPropValidator(buttonSizes, 'size'),
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: defaultButtonType,
    validator: optionPropValidator(buttonTypes, 'type'),
  },
};

export const useCommonBtn = (_props) => {
  const buttonClasses = computed(() => {
    const props = unref(_props);

    const classes = [
      `variant-${props.variant}`,
      `size-${props.size}`,
      'items-center',
      'rounded-full',
      'outline-none',
      'font-medium',
      {
        // aspect
        'inline-flex': !props.isFullWidth,
        flex: props.isFullWidth,
        'w-full': props.isFullWidth,

        // Loading
        loading: props.loading,
        relative: props.loading,
        'cursor-default': props.loading,

        // alignment
        'justify-center':
          props.alignText === buttonAlignments.center || props.fab,
        'justify-end': props.alignText === buttonAlignments.right,

        // size
        'h-6': props.size === buttonSizes.sm,
        'w-6': props.size === buttonSizes.sm && props.fab,
        'h-8': props.size === buttonSizes.md,
        'w-8': props.size === buttonSizes.md && props.fab,
        'h-10': props.size === buttonSizes.lg,
        'w-10': props.size === buttonSizes.lg && props.fab,
        'h-12': props.size === buttonSizes.xl,
        'w-12': props.size === buttonSizes.xl && props.fab,

        // paddings
        'px-2': props.size === buttonSizes.sm && !props.fab,
        'py-2': props.size === buttonSizes.lg && !props.fab,
        'px-3': props.size !== buttonSizes.sm,

        // gaps
        'gap-1': props.size === buttonSizes.sm,
        'gap-2': props.size !== buttonSizes.sm,

        // typography
        'text-xs': props.size === buttonSizes.sm,
        'leading-xl': props.size === buttonSizes.sm,
        'text-default': props.size !== buttonSizes.sm,
        'leading-2xl': props.size !== buttonSizes.sm,

        // raised
        'shadow-md': props.raised,
      },
    ];

    if (!props.disabled) {
      classes.push('transition duration-300 ease-in-out');
    }

    // variants
    // Primary
    if (props.variant === buttonVariants.primary) {
      classes.push('border-0');
      classes.push('text-white');
      if (props.disabled) {
        // classes.push('bg-palette-indigo-20');
        classes.push('bg-primary-disabled');
      } else if (!props.loading) {
        classes.push('bg-primary');
        classes.push('hover:bg-primary-strong active:bg-primary-strongest');
        // active state
        if (props.active) {
          classes.push('bg-primary-strongest');
        }
      }
    }

    // Secondary
    if (props.variant === buttonVariants.secondary) {
      classes.push('bg-white border border-solid');
      if (props.disabled) {
        classes.push(
          'text-[color:var(--token-c-button-color-secondary-color-disabled)] border-[color:var(--token-c-button-color-secondary-border-disabled)]',
        );
      } else {
        classes.push('bg-white text-primary border-primary');
        if (!props.loading) {
          classes.push(
            'hover:bg-secondary-strong hover:text-primary-strongest active:bg-secondary-strongest active:text-primary-strongest hover:border-primary-strong active:primary-strongest',
          );
          // active state
          if (props.active) {
            classes.push(
              'bg-secondary-strongest text-primary-strongest primary-strongest',
            );
          }
        }
      }
    }

    // Teritary
    if (props.variant === buttonVariants.tertiary) {
      classes.push(
        'bg-[color:var(--token-c-button-color-tertiary)] border border-solid border-[transparent]',
      );
      if (props.disabled) {
        classes.push(
          'text-[color:var(--token-c-button-color-tertiary-disabled)]',
        );
      } else {
        classes.push('text-[color:var(--token-c-button-color-tertiary-color)]');
        if (!props.loading) {
          classes.push(
            'hover:bg-[color:var(--token-c-button-color-tertiary-background-strong)] active:bg-[color:var(--token-c-button-color-tertiary-background-strongest)] focus-visible:border focus-visible:border-[color:var(--token-c-button-color-tertiary-border-focus)]',
          );
          // active state
          if (props.active) {
            classes.push(
              'bg-[color:var(--token-c-button-color-tertiary-background-strongest)]',
            );
          }
        }
      }
    }

    // Warning
    if (props.variant === buttonVariants.warning) {
      if (props.disabled) {
        classes.push('bg-warning-disabled border-0');
        classes.push('text-white');
      } else {
        classes.push(
          'bg-[color:var(--token-c-button-color-warning)] text-[color:var(--token-c-button-color-warning-color)] border-0',
        );
        if (!props.loading) {
          classes.push(
            'hover:bg-[color:var(--token-c-button-color-warning-strong)] active:bg-[color:var(--token-c-button-color-warning-strongest)]',
          );
          // active state
          if (props.active) {
            classes.push(
              'bg-[color:var(--token-c-button-color-warning-strongest)]',
            );
          }
        }
      }
    }

    // Slate
    if (props.variant === buttonVariants.slate) {
      classes.push('border-0');
      if (props.disabled) {
        classes.push(
          'bg-[color:var(--token-c-button-color-slate-disabled)] text-white',
        );
      } else {
        classes.push('bg-[color:var(--token-c-button-color-slate)] text-white');
      }
    }

    // Ghost
    if (props.variant === buttonVariants.ghost) {
      classes.push('bg-[transparent] border border-solid');
      if (props.disabled) {
        classes.push(
          'text-[color:var(--token-c-button-color-ghost-text-disabled)] border-[color:var(--token-c-button-color-ghost-border-disabled)]',
        );
      } else {
        classes.push('border-[color:var(--token-c-button-color-ghost-border)]');
        if (!props.loading) {
          classes.push('hover:bg-white/10 active:bg-white/20');
          // active state
          if (props.active) {
            classes.push('bg-white/20');
          }
        }
      }
    }

    // Outline
    if (props.variant === buttonVariants.outline) {
      classes.push(
        'border border-solid bg-white text-[color:var(--token-c-button-color-outline-text)]',
      );
      if (props.disabled) {
        classes.push(
          'text-[color:var(--token-c-button-color-outline-disabled)] border-[color:var(--token-c-button-color-outline-disabled)]',
        );
      } else if (!props.loading) {
        classes.push(
          'border-[color:var(--token-c-button-color-outline)] hover:text-[color:var(--token-c-button-color-outline-strong)] hover:border-[color:var(--token-c-button-color-outline-strong)] active:text-[color:var(--token-c-button-color-outline-strongest)] active:border-[color:var(--token-c-button-color-outline-strongest)] focus-visible:border-[color:var(--token-c-button-color-outline-strong)] focus-visible:text-[color:var(--token-c-button-color-outline-strong)]',
        );
        // active state
        if (props.active) {
          classes.push(
            'border-[color:var(--token-c-button-color-outline-strongest)]',
          );
        }
      }
    }

    return classes;
  });

  return { buttonClasses };
};
