import cloneDeep from 'lodash/cloneDeep';
import rawRoutes from '@/router/routes';
import enrichComponents from '@/router/components';
import mapNested from '@/router/map-nested';

/**
 * This code copies the legacy TKO routes into the Vue Router
 *
 * The idea is to allow Vue code to access TKO route metadata
 * in the same fashion as it will when the route is in Vue.
 *
 * @param legacyRouter
 * @param vueRouter
 */
export default (store, legacyRouter, vueRouter) => {
  const legacyRoutes = legacyRouter.configuredRoutes
    .map((route) => ({
      ...route,
      // everything/boards/{boardId} -> /everything/boards/:boardId
      url:
        (route.url[0] === '/' ? '' : '/') +
        route.url
          .replace(/:([A-Za-z0-9]+):/g, ':$1')
          .replace(/{([A-Za-z0-9]+)}/g, ':$1'),
    }))
    // Some routes can be explicitly disabled for the scaffolding
    .filter((route) => route.params.hybrid !== false)
    .map((route) => ({
      path: route.url,
      meta: {
        canViewFullScreen: !!route.params.canViewFullScreen,
        hasSidebar: !!route.params.hasSidebar,
        hasFilter: !!route.params.hasFilter,
        hasHeader: !!route.params.hasHeader,
        // PageName should be manually added in Vue routes
        pageName: route.url.slice(1).split('/', 1)[0],
      },
    }));

  // deep clone so we can modify it.
  const localRoutes = cloneDeep(rawRoutes);
  // Assume last route is the app-layout wildcard
  const topRoute = localRoutes[localRoutes.length - 1];
  topRoute.children = [
    ...topRoute.children.slice(0, -1),
    ...legacyRoutes,
    // last sub route is the wildcard
    topRoute.children[topRoute.children.length - 1],
  ];

  const routes = mapNested(localRoutes, (route) => {
    // Add lazy loading imports for component names
    const output = enrichComponents(route);
    // Pass the store as the first param to `beforeEnter`,
    // if it expects more than 3 params.
    if (output.beforeEnter && output.beforeEnter.length > 3) {
      const handler = output.beforeEnter;
      output.beforeEnter = (...args) => handler(store, ...args);
    }
    return output;
  });
  vueRouter.addRoutes(routes);
};
