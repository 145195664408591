import Vue from 'vue';
import api from '@/services/api';
import tasks from './tasks';

export default {
  namespaced: true,

  modules: { tasks },

  state: {
    records: {},
  },

  mutations: {
    records(state, dataviews) {
      const hash = {};
      dataviews.forEach((dataview) => {
        hash[dataview.id] = { ...dataview };
      });
      state.records = { ...state.records, ...hash };
    },

    record(state, dataview) {
      Vue.set(state.records, dataview.id, dataview);
    },
  },

  actions: {
    async load({ commit }, id) {
      const { data } = await api.get(
        `/me/dataviews.json?getShareData=true&dataviewId=${id}`,
      );

      commit('records', data.dataViews);

      data.dataViews.forEach((dataview) => {
        commit('record', dataview);
      });
    },
  },
};
