<template>
  <ItemPickerPopover
    :open="open"
    :height="height"
    :state="state"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <template #trigger><slot name="trigger" /></template>
    <slot name="header" />
    <ItemPickerSearch
      :key="reRenderSearchKey"
      v-if="open && !stateIs(NO_DATA)"
      :placeholder="searchPlaceholder"
      :search-term="searchTerm"
      @search-change="onSearchChange"
    />
    <div
      v-if="open"
      class="item-picker-content"
      :class="stateIs(LOADING_WITH_DATA) && 'loading_opacity'"
      @mousewheel="checkScrollHeight"
    >
      <slot
        name="content"
        v-if="stateIs(SUCCESS) || stateIs(LOADING_WITH_DATA)"
      ></slot>
      <ItemPickerSkeleton v-if="stateIs(LOADING)" :count="skeletonItemsCount" />
      <ItemPickerNoData
        v-if="stateIs(NO_DATA)"
        :title="noDataTitle"
        :description="noDataDescription"
        :button-text="noDataButtonText"
        @on-add-new="$emit('on-add-new')"
      />
      <ItemPickerNoResult v-if="stateIs(NO_RESULT)" :text="noResultText" />
      <ItemPickerError v-if="stateIs(ERROR)" />
      <Spinner class="spinner" v-if="canLoadMore" :size="15" />
      <div class="item-picker-content__footer">
        <slot name="footer" />
      </div>
    </div>
  </ItemPickerPopover>
</template>
<script>
import Spinner from '@widgets/Spinner';
import ItemPickerPopover from './ItemPickerPopover';
import ItemPickerSearch from './ItemPickerSearch';
import ItemPickerSkeleton from './ItemPickerStates/ItemPickerSkeleton';
import ItemPickerNoResult from './ItemPickerStates/ItemPickerNoResult';
import ItemPickerNoData from './ItemPickerStates/ItemPickerNoData';
import ItemPickerError from './ItemPickerStates/ItemPickerError';

import { ITEM_PICKER_STATES } from '@/utils/helpers/itemPicker';

export default {
  name: 'ItemPicker',
  props: {
    open: { type: Boolean, default: false },
    height: { type: Number, default: 345 },
    state: { type: String, required: true },
    canLoadMore: { type: Boolean, default: false },
    searchTerm: { type: String, required: true },
    searchPlaceholder: { type: String, default: 'Search' },
    reRenderSearchKey: { type: Number, default: 1 },
    noResultText: {
      type: String,
      default: 'No items match your search',
    },
    skeletonItemsCount: { type: Number, default: 5 },
    noDataTitle: { type: String, default: 'No Items' },
    noDataDescription: {
      type: String,
      default: 'You need to create a new item',
    },
    noDataButtonText: {
      type: String,
      default: 'Add an item',
    },
  },
  components: {
    ItemPickerPopover,
    ItemPickerSearch,
    ItemPickerSkeleton,
    ItemPickerNoResult,
    ItemPickerNoData,
    ItemPickerError,
    Spinner,
  },
  setup(props, { emit }) {
    const { SUCCESS, LOADING, NO_DATA, NO_RESULT, ERROR, LOADING_WITH_DATA } =
      ITEM_PICKER_STATES;

    function stateIs(state) {
      return props.state === state;
    }

    function onSearchChange(search) {
      emit('search-change', search);
    }

    function checkScrollHeight(search) {
      emit('check-for-scroll', search);
    }

    return {
      SUCCESS,
      LOADING,
      LOADING_WITH_DATA,
      NO_DATA,
      NO_RESULT,
      ERROR,
      stateIs,
      onSearchChange,
      checkScrollHeight,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '~@tko/src/styles/mixins/mixins';

.item-picker-content {
  margin-top: 16px;
  height: 100%;
  max-height: 295px;
  position: relative;
  overflow: scroll;
  @include scrollbar();

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.loading_opacity {
  opacity: 0.3;
}
.spinner {
  margin: 0 auto;
}
</style>
