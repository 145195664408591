<template>
  <div>
    <ItemPickerSkeleton v-if="recencyState === 'LOADING'" :count="5" />
    <div v-else-if="recents.length">
      <span class="picker-label">{{ $t('Recent tasks') }}</span>
      <div v-for="recent of recents" :key="recent.id">
        <DependencyPickerItem
          :task="recent"
          @add-new-predecessor="addNewPredecessor"
          :selected="recent.actionType === 'completed'"
        />
      </div>
    </div>
    <ItemPickerNoData
      v-else
      class="no-recent-data"
      :title="$t('No recent activity')"
      :description="$t('You don\'t have any recent activity')"
    >
      <template #image>
        <BlankStateImage class="no-recent-data__image" />
      </template>
    </ItemPickerNoData>
  </div>
</template>
<script>
import { useRecentsLoader } from '@teamwork/use';
import { computed } from 'vue-demi';
import ItemPickerNoData from '@widgets/ItemPicker/ItemPickerStates/ItemPickerNoData';
import ItemPickerSkeleton from '@widgets/ItemPicker/ItemPickerStates/ItemPickerSkeleton';
import BlankStateImage from '@/assets/images/blankslates/abstract.svg';
import DependencyPickerItem from './DependencyPickerItem';

export default {
  name: 'DependenciesRecentItems',
  components: {
    DependencyPickerItem,
    ItemPickerNoData,
    BlankStateImage,
    ItemPickerSkeleton,
  },
  props: {
    tasks: { type: Array, default: () => [] },
    excludeTaskIds: { type: Array, default: () => [] },
  },
  setup(props) {
    const params = computed(() => ({
      excludeTaskIds: props.excludeTaskIds?.length
        ? props.excludeTaskIds.join(',')
        : '',
      entities: 'task',
    }));

    const state = useRecentsLoader({
      params,
      count: 5,
      pageSize: 5,
      useElastic: true,
    });

    const formatedItems = computed(() =>
      state.items.value.map((item) => {
        return {
          id: item.entityId,
          name: item.description,
          actionType: item.actionType,
        };
      }),
    );

    const recencyState = computed(() => {
      if (state.loading.value) {
        return 'LOADING';
      }
      return 'SUCCESS';
    });

    return {
      recents: formatedItems,
      recencyState,
    };
  },
  methods: {
    addNewPredecessor(predecessor) {
      this.$emit('add-new-predecessor', predecessor);
    },
  },
};
</script>
<style lang="scss" scoped>
$neutral-60: #0b0e1f;
.picker-label {
  color: $neutral-60;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  margin-left: 12px;
}
.no-recent-data {
  padding-top: 50px;
  &__image {
    width: 80px;
  }
}
</style>
