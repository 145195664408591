import Vue from 'vue';

const marker = '\u2063';

// DEPRECATED
// @vue/component
export default {
  name: 'T',
  computed: {
    // Replace every even slot with a placeholder and
    // build a i18n string
    msg() {
      let replIndex = 0;
      return (
        this.$slots.default
          // eslint-disable-next-line no-plusplus
          .map((slot) => (slot.tag ? `[${replIndex++}]` : slot.text))
          .join('')
          .replace(/[\r\n]/g, '') // ignore carriage returns
          .replace(/[ ]{2,}/g, ' ') // replace multiple spaces with 1
          .trim()
      );
    },
    // Translate with special char replacements
    // and then split by the char
    translated() {
      const i18n = Vue.t(
        this.msg,
        this.$slots.default.filter((slot) => slot.tag).map(() => marker),
      );
      return i18n ? i18n.split(marker) : [];
    },
  },
  created() {
    // eslint-disable-next-line no-console
    console.warn(
      '<T> component is deprecated. Please use `Vue.t` or `$t` functions instead.',
    );
  },
  render(createElement) {
    // Output a span containing the slot nodes, with
    // text nodes i18n-ized
    return createElement(
      'span',
      this.$slots.default.map((slot, index) =>
        slot.tag ? slot : this.translated[index / 2],
      ),
    );
  },
};
