<template>
  <ul class="menu">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'Menu',
  display: 'Menu',
};
</script>

<style lang="scss" scoped>
.menu {
  background: #fff;
  border: 1px solid #f5f7fa;
  border-radius: 8px;
  color: #182026;
  list-style: none;
  margin: 0;
  min-width: 180px;
  padding: 0;
  text-align: left;
  box-shadow: 0px 2px 4px rgba(11, 14, 31, 0.08);
  overflow: hidden;
}
</style>
