var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"resize",rawName:"v-resize.content-box.immediate",value:(_vm.onTableResize),expression:"onTableResize",modifiers:{"content-box":true,"immediate":true}}],ref:"tableElement",staticClass:"TableView"},[_c('div',{staticClass:"TableView__content"},[_c('div',{ref:"topElement"}),_vm._v(" "),_c('div',{class:{
        TableView__header: true,
        'TableView__header--dragging': _vm.resizedColumnId != null,
        'TableView__header--shadow': _vm.showHeaderShadow,
      }},[_c('VueDraggable',{class:{
          TableView__cells: true,
          'TableView__cells--dragging': _vm.resizedColumnId != null,
        },attrs:{"draggable":".TableView__cell--draggable","value":_vm.enabledColumns},on:{"input":_vm.onReorderColumns,"choose":function($event){_vm.draggingHeader = true},"unchoose":function($event){_vm.draggingHeader = false}}},_vm._l((_vm.enabledColumns),function(column){return _c('div',{directives:[{name:"resize",rawName:"v-resize.border-box.immediate",value:(_vm.onCellResizeRAF),expression:"onCellResizeRAF",modifiers:{"border-box":true,"immediate":true}},{name:"resizable",rawName:"v-resizable",value:({
            right: column.resizable,
            onResizeStart: _vm.onManualResizeStart,
            onResize: _vm.onManualResize,
            onResizeEnd: _vm.onManualResizeEnd,
          }),expression:"{\n            right: column.resizable,\n            onResizeStart: onManualResizeStart,\n            onResize: onManualResize,\n            onResizeEnd: onManualResizeEnd,\n          }"}],key:column.id,class:{
            TableView__cell: true,
            'TableView__cell--draggable': column.draggable,
            'TableView__cell--borders': _vm.headerHover,
            'TableView__cell--dragging': _vm.draggingHeader,
            'TableView__cell--center': column.headerCellCenter,
          },style:({
            left: column.left != null ? ((column.left) + "px") : undefined,
            zIndex: column.left != null ? '1' : undefined,
            width:
              _vm.resizedColumnId == null
                ? column.width
                : _vm.resizedColumnId === column.id
                ? _vm.resizedColumnWidth
                : ((column.computedWidth) + "px"),
            minWidth:
              _vm.resizedColumnId == null || _vm.resizedColumnId === column.id
                ? ("max(" + _vm.minColumnWidth + ", " + (column.minWidth) + ")")
                : ((column.computedWidth) + "px"),
            maxWidth:
              _vm.resizedColumnId == null || _vm.resizedColumnId === column.id
                ? ("min(" + _vm.maxColumnWidth + ", " + (column.maxWidth) + ")")
                : ((column.computedWidth) + "px"),
          }),attrs:{"data-column-id":column.id,"data-identifier":_vm.dataIdentifierPrefix
              ? (_vm.dataIdentifierPrefix + "-table-view-" + (column.id) + "-column")
              : undefined},on:{"mouseenter":function($event){_vm.headerHover = true},"mouseleave":function($event){_vm.headerHover = false}}},[_c('div',{class:{
              'TableView__cell-name': true,
              'TableView__cell-name--sortable': Boolean(column.sortKey),
              'TableView__cell-name--sorting': _vm.hasSortApplied(column),
            },attrs:{"title":column.name},on:{"click":function($event){return _vm.sortBy(column)}}},[_vm._t(("header-" + (column.id)),function(){return [_vm._v("\n              "+_vm._s(column.name)+"\n            ")]},{"column":column})],2),_vm._v(" "),(column.tooltip)?_c('ColumnHelp',{attrs:{"text":column.tooltip}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"TableView__cell-sort-arrow",on:{"click":function($event){return _vm.sortBy(column)}}},[(Boolean(column.sortKey))?_c('SortArrow',{class:{
                'TableView__cell-sort-arrow-icon': true,
                'TableView__cell-sort-arrow-icon--ascending': _vm.sort.ascending,
                'TableView__cell-sort-arrow-icon--visible':
                  _vm.hasSortApplied(column),
              },attrs:{"color":(_vm.draggingHeader && '#E5E5E5') ||
                (Boolean(column.sortKey) &&
                  column.sortKey === _vm.sort.key &&
                  '#3C55BD') ||
                '#C5CEE0'}}):_vm._e()],1),_vm._v(" "),(_vm.hasSortApplied(column) || _vm.canEditCustomFields(column))?_c('v-popover',{class:{
              'TableView__cell-buttons': true,
              'TableView__cell-buttons-center': column.headerCellCenter,
            },attrs:{"open-class":"TableView__cell-buttons--open","boundaries-element":_vm.boundariesElement,"placement":"bottom-end","popover-arrow-class":"hidden"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSortApplied(column)),expression:"hasSortApplied(column)"}],staticClass:"TableView__cell-buttons-content-button",on:{"click":_vm.resetSort}},[_vm._v("\n                "+_vm._s(_vm.$t('Clear sort'))+"\n              ")]),_vm._v(" "),(_vm.canEditCustomFields(column))?_c('button',{directives:[{name:"close-popover",rawName:"v-close-popover"}],staticClass:"TableView__cell-buttons-content-button",on:{"click":function($event){return _vm.editCustomFieldModal(column)}}},[_vm._v("\n                "+_vm._s(_vm.$t('Edit field'))+"\n              ")]):_vm._e()]},proxy:true}],null,true)},[_vm._v(" "),_c('button',{staticClass:"TableView__cell-buttons-trigger-button"},[_c('CommonIcon',{staticClass:"TableView__cell-buttons-trigger-icon",attrs:{"id":"chevron-down"}})],1)]):_vm._e()],1)}),0),_vm._v(" "),(!_vm.hideTableViewPicker)?_c('div',{staticClass:"TableView__column-picker"},[_c('TableViewColumnPicker',{staticClass:"TableView__column-picker-component",attrs:{"data-identifier-prefix":_vm.dataIdentifierPrefix
              ? (_vm.dataIdentifierPrefix + "-table-view-column-picker")
              : null}})],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"TableView__body"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }