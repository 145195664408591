export default (cookieName) => {
  if (!cookieName) {
    return '';
  }
  const foundCookie = document.cookie
    .split(';')
    .find((cookie) => cookie.trim().includes(cookieName));

  return foundCookie ? foundCookie.slice(foundCookie.indexOf('=') + 1) : '';
};
