import { validateHex, isColorLight, darken } from '@/utils/helpers/color';

export default {
  namespaced: true,
  state: {
    theme: { id: '0', color: '' },
  },
  mutations: {
    theme(state, payload) {
      state.theme = payload;
    },
  },
  getters: {
    rawColor: (state) => state.theme.color.replace('#', ''),
    validColor: (state, getters) => validateHex(getters.rawColor),
    isLight: (state, getters) =>
      getters.validColor && isColorLight(getters.rawColor),
    themeClass: ({ theme }, getters) => [
      getters.isLight ? 'color-mode--light' : 'color-mode--dark',
      theme.id === 1 ? 'app-header--default-theme' : null,
    ],
    darkTint: (state, getters) => darken(getters.rawColor, 0.75),
    themeCSS: (state, getters) => `
      .app-header, .w-side-nav__block {
        background-color: #${getters.rawColor};
      }
      .w-data-grid th, .w-time-grid th, table.timeGrid th, table.dataGrid th{
        background-color: ${getters.darkTint} !important;
      }
      #navPTW, .app-header__logo, .w-header-titles__account-name,
      .w-header-titles__project-name, .w-header-titles__company-owner,
      .w-side-nav__item a, .w-header-nav__item {
        ${getters.isLight ? 'color: #333 !important;' : ''}
      }
    `,
  },
  actions: {
    ssr({ commit }) {
      const { STATUS, branding } =
        window.initialData['v/1/settings/branding.json'] || {};

      if (STATUS === 'OK') {
        commit('theme', branding.theme);
      }
    },
  },
};
