import {
  computed,
  readonly,
  inject,
  provide,
  shallowRef,
  nextTick,
  Vue2 as Vue,
} from 'vue-demi';

import useStore from '@/platform/composables/useStore';

import ProjectsPanel from '@/platform/components/side-nav/panels/projects/ProjectsPanel.vue';
import SearchPanel from '@/platform/components/side-nav/panels/search/SearchPanel.vue';
import NotificationsPanel from '@/platform/components/side-nav/panels/notifications/NotificationsPanel.vue';
import HelpCenterPanel from '@/platform/components/side-nav/panels/help-center/HelpCenterPanel.vue';
import { usePreferences } from '@/platform/composables/usePreferences';

const sidebarPanelsSymbol = Symbol('useSidebarPanels');

// Register your side panel component with this map
// Ensure your component has a corresponding name property
// It will then be able to be rendered vi the api below within the
// SidebarPanelsContainer
// Simply add a showXXXPanel function to the api and you are
// odd and running
export const sidebarPanelComponents = {
  ProjectsPanel,
  SearchPanel,
  NotificationsPanel,
  HelpCenterPanel,
};

export function provideSidebarPanels() {
  const store = useStore();
  const { sidebarPinnedPanel } = usePreferences();

  const sidebarPanelKey = shallowRef(null);
  const sidebarPinnedPanelKey = sidebarPinnedPanel;
  const isHelpCenterContactFilledOut = shallowRef(false);

  const setHelpCenterContactFilledOut = (value) => {
    isHelpCenterContactFilledOut.value = value;
  };

  const hideActivePanel = (
    event,
    checkQuickView,
    keepAlivePinnedPanel = true,
  ) => {
    // Check if help center form is filled out
    if (isHelpCenterContactFilledOut.value) {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(
          Vue.t(
            'You have unsubmitted feedback. Are you sure you want to close the window?',
          ),
        )
      ) {
        setHelpCenterContactFilledOut(false);
      } else {
        return;
      }
    }

    if (event && checkQuickView) {
      // Without this, clicking within a notification's quick view
      // would also close the notification panel.
      if (store.getters['quickViews/isQuickViewOpen']() > 0) {
        return;
      }

      // We can't solely rely on isQuickViewOpen getter as it will be 0
      // if the user was dismissing the quick view
      const eventPathIds = event?.composedPath()?.map((el) => el.id);
      if (
        eventPathIds.includes('quickViewHolder') ||
        eventPathIds.includes('vueQuickViewPanels')
      ) {
        return;
      }
    }
    if (
      sidebarPanelKey.value === sidebarPinnedPanelKey.value.name &&
      !keepAlivePinnedPanel
    ) {
      sidebarPinnedPanelKey.value = {
        name: sidebarPinnedPanelKey.value.name,
        isOpen: false,
      };
    }
    sidebarPanelKey.value = null;
  };

  const isPanelPinned = (key) =>
    computed(() => {
      const pinnedPanelKey = sidebarPinnedPanelKey.value.name;

      return pinnedPanelKey === key;
    });

  const isPanelActive = (key) =>
    computed(() => {
      const panelKey = sidebarPanelKey.value;

      return panelKey === key || (panelKey && key === '');
    });

  const setActivePanel = (key) => () => {
    if (
      key !== sidebarPanelKey.value &&
      sidebarPanelKey.value !== sidebarPinnedPanelKey.value.name
    ) {
      hideActivePanel();
    }
    nextTick(() => {
      sidebarPanelKey.value = key;
      if (sidebarPinnedPanelKey.value.name === key) {
        sidebarPinnedPanelKey.value = {
          name: sidebarPinnedPanelKey.value.name,
          isOpen: true,
        };
      }
    });
  };

  const showSearchPanel = setActivePanel(SearchPanel.name);
  const showNotificationsPanel = setActivePanel(NotificationsPanel.name);
  const showProjectsPanel = setActivePanel(ProjectsPanel.name);
  const showHelpCenterPanel = setActivePanel(HelpCenterPanel.name);

  const pinPanel = (key) => () => {
    if (sidebarPinnedPanelKey.value.name === key) {
      showProjectsPanel();
      setTimeout(() => {
        sidebarPinnedPanelKey.value = { name: null, isOpen: false };
      });
      return;
    }

    sidebarPinnedPanelKey.value = { name: key, isOpen: true };
  };

  const isProjectsPanelActive = isPanelActive(ProjectsPanel.name);
  const isSearchPanelOpen = isPanelActive(SearchPanel.name);
  const isNotificationsPanelOpen = isPanelActive(NotificationsPanel.name);
  const isHelpCenterPanelOpen = isPanelActive(HelpCenterPanel.name);

  const pinProjectsPanel = pinPanel(ProjectsPanel.name);

  const isProjectsPanelPinned = isPanelPinned(ProjectsPanel.name);
  const isProjectsPanelOpen = computed(() => {
    return (
      (isProjectsPanelPinned.value && sidebarPinnedPanelKey.value.isOpen) ||
      isProjectsPanelActive.value
    );
  });

  const sidebarPinnedPanelOpen = computed(() => {
    return isProjectsPanelPinned.value; // || isProjectsPanelOpen.value;
  });

  const hideProjectsPanel = () => {
    if (isProjectsPanelPinned.value) {
      sidebarPinnedPanelKey.value = {
        name: sidebarPinnedPanelKey.value.name,
        isOpen: false,
      };
    }

    hideActivePanel();
  };

  const tryhideProjectsPanel = () => {
    if (!isProjectsPanelPinned.value) {
      hideActivePanel();
    }
  };

  provide(sidebarPanelsSymbol, {
    sidebarPanelKey: readonly(sidebarPanelKey),
    sidebarPinnedPanelKey: readonly(sidebarPinnedPanelKey),
    sidebarPinnedPanelOpen,

    hideActivePanel,
    hideProjectsPanel,
    tryhideProjectsPanel,

    showSearchPanel,
    showProjectsPanel,
    showNotificationsPanel,
    showHelpCenterPanel,

    isProjectsPanelOpen,
    isSearchPanelOpen,
    isNotificationsPanelOpen,
    isHelpCenterPanelOpen,

    pinProjectsPanel,
    isProjectsPanelPinned,

    setHelpCenterContactFilledOut,
  });
}

export function useSidebarPanels() {
  return inject(sidebarPanelsSymbol);
}
