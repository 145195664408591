<template>
  <div
    class="border border-solid px-3 flex items-center gap-2 rounded"
    :class="wrapperClasses"
    @click="onClick"
  >
    <div
      v-if="$slots.prepend"
      class="inline-flex items-center gap-2"
      :class="{
        'color-[color:var(--token-c-input-color-placeholder)]': !disabled,
        'color-[color:var(--token-c-input-color-placeholder-disabled)]':
          disabled,
      }"
    >
      <slot name="prepend" />
    </div>
    <input
      class="
        w-full
        m-0
        p-0
        border-0
        outline-none
        text-default
        leading-6
        bg-transparent
        appearance-none
        disabled:placeholder-palette-neutral-40
      "
      :class="inputClasses"
      :disabled="disabled"
      :type="type"
      :value="modelValue"
      :required="required"
      ref="inputRef"
      v-bind="$attrs"
      v-on="{ ...$listeners, input: onInput, blur: onBlur, focus: onFocus }"
    />
    <div
      v-if="$slots.append || showClearButton"
      class="inline-flex items-center gap-2"
      :class="{
        'fill-[color:var(--token-c-input-color-placeholder)]': !disabled,
        'fill-[color:var(--token-c-input-color-placeholder-disabled)]':
          disabled,
      }"
    >
      <slot name="append" />
      <button
        v-if="showClearButton"
        type="button"
        class="
          border-0
          p-0
          m-0
          appearance-none
          outline-none
          inline-flex
          items-center
          justify-center
          bg-transparent
        "
        :class="{
          'color-[color:var(--token-c-input-color-text-invalid)]': invalid,
        }"
        @click.stop="onClickClear"
      >
        <IconClear class="fill-current w-4 h-4" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue-demi';
import IconClear from '~icons/tw/input-clear';
import {
  inputSizes,
  inputTypes,
  inputVariants,
  defaultInputSize,
  defaultInputType,
  defaultInputVariant,
} from './consts';
import optionPropValidator from '../../../helpers/optionPropValidator';

const sizeClassesMap = {
  [inputSizes.sm]: 'h-[var(--token-c-input-size-small)]',
  [inputSizes.md]: 'h-[var(--token-c-input-size-medium)]',
  [inputSizes.lg]: 'h-[var(--token-c-input-size-large)]',
};

const variantClassesMap = {
  [inputVariants.outline]: {
    background:
      'bg-[color:var(--token-c-input-color-background-outline-default)]',
    border: {
      default:
        'border-[color:var(--token-c-input-color-border-outline-default)]',
      disabled:
        'border-[color:var(--token-c-input-color-border-outline-disabled)]',
      focus: 'border-[color:var(--token-c-input-color-border-outline-focus)]',
      hover:
        'hover:border-[color:var(--token-c-input-color-border-outline-hover)]',
      invalid:
        'border-[color:var(--token-c-input-color-border-outline-invalid)]',
    },
  },
  [inputVariants.filled]: {
    background:
      'bg-[color:var(--token-c-input-color-background-filled-default)]',
    border: {
      default:
        'border-[color:var(--token-c-input-color-border-filled-default)]',
      disabled:
        'border-[color:var(--token-c-input-color-border-filled-disabled)]',
      focus: 'border-[color:var(--token-c-input-color-border-filled-focus)]',
      hover:
        'hover:border-[color:var(--token-c-input-color-border-filled-hover)]',
      invalid:
        'border-[color:var(--token-c-input-color-border-filled-invalid)]',
    },
  },
};

export default {
  name: 'FInput',
  components: { IconClear },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: String,
      default: defaultInputSize,
      validator: optionPropValidator(inputSizes, 'size'),
    },
    variant: {
      type: String,
      default: defaultInputVariant,
      validator: optionPropValidator(inputVariants, 'variant'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: defaultInputType,
      validator: optionPropValidator(inputTypes, 'type'),
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    focusFirst: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputRef = ref(null);
    const isFocused = ref(false);
    const variant = computed(() => variantClassesMap[props.variant]);
    const sizeClass = computed(() => sizeClassesMap[props.size]);

    const pointerClass = computed(() => {
      return props.clickable ? 'cursor-pointer' : 'cursor-text';
    });

    const borderClasses = computed(() => {
      const { border } = variant.value;
      if (props.disabled) {
        return border.disabled;
      }
      if (isFocused.value) {
        return border.focus;
      }
      if (props.invalid) {
        return border.invalid;
      }
      return [border.default, border.hover];
    });

    const wrapperClasses = computed(() => [
      variant.value.background,
      borderClasses.value,
      pointerClass.value,
    ]);

    const inputClasses = computed(() => {
      return props.disabled
        ? [
            sizeClass.value,
            pointerClass.value,
            'text-[color:var(--token-c-input-color-text-disabled)]',
            'placeholder:color-[color:var(--token-c-input-color-text-placeholder-disabled)]',
          ]
        : [
            sizeClass.value,
            pointerClass.value,
            'text-[color:var(--token-c-input-color-text-default)]',
            'placeholder:color-[color:var(--token-c-input-color-text-placeholder-default)]',
          ];
    });

    const showClearButton = computed(
      () => props.clearable && props.modelValue && !props.disabled,
    );

    function focus() {
      inputRef.value?.focus();
    }

    function onClick(event) {
      focus();
      emit('click', event);
    }

    function onClickClear() {
      emit('input', null);
      emit('clear');
    }

    function onFocus(event) {
      isFocused.value = true;
      emit('focus', event);
    }

    function onBlur(event) {
      isFocused.value = false;
      emit('blur', event);
    }

    function onInput(event) {
      const value = event.currentTarget.value;
      emit('input', value);
    }

    return {
      focus,
      inputClasses,
      inputRef,
      isFocused,
      wrapperClasses,
      showClearButton,
      onBlur,
      onClick,
      onClickClear,
      onFocus,
      onInput,
    };
  },
  mounted() {
    if (this.focusFirst) {
      setTimeout(() => this.focus());
    }
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  -moz-appearance: textfield;
}
</style>
