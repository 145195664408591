var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-y-scroll"},[_c('div',{staticClass:"mx-6 mb-10"},[_c('HelpCenterMenu',{attrs:{"title":_vm.breadcrumb}},[(_vm.isLoading)?_c('HelpCenterSearchSkeleton',{staticClass:"!mt-5"}):_vm._l((_vm.getItems()),function(item,index){return _c('HelpCenterMenuItem',{key:index,attrs:{"label":item.label,"icon":item.icon},on:{"click":function($event){item.icon === 'subcategory'
            ? _vm.$emit(
                'subcategoryClicked',
                item.slug,
                (_vm.breadcrumb + " / " + (item.name))
              )
            : _vm.$emit(
                'articleClicked',
                item.categorySlug,
                item.slug,
                item.title
              )}}})})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }