var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Multiselect',_vm._g(_vm._b({ref:"multiselect",staticClass:"w-select-multi",attrs:{"open-direction":_vm.openDirection,"allow-empty":_vm.allowEmpty,"show-labels":false,"option-height":32},on:{"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([(_vm.$scopedSlots.placeholder)?{key:"placeholder",fn:function(){return [_vm._t("placeholder")]},proxy:true}:null,(_vm.groupId || _vm.$scopedSlots.option)?{key:"option",fn:function(slotOpts){return [(slotOpts.option.$isLabel)?[_vm._t("group",function(){return [_c('strong',[_vm._v(_vm._s(slotOpts.option.$groupLabel))])]},null,slotOpts)]:(!_vm.groupId || !slotOpts.option[_vm.groupId])?[_vm._t("option",function(){return [_vm._v("\n        "+_vm._s(slotOpts.option[_vm.label])+"\n      ")]},null,slotOpts)]:_c('span',{staticClass:"w-select-multi--nested-option"},[_vm._t("option",function(){return [_vm._v("\n        "+_vm._s(slotOpts.option[_vm.label])+"\n      ")]},null,slotOpts)],2)]}}:(_vm.nameOnHover)?{key:"option",fn:function(slotOpts){return [_vm._t("option",function(){return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: slotOpts.option.name,
          container: '.m-add-edit-project',
          delay: { show: 200 },
          classes: 'tooltip w-tooltip',
        }),expression:"{\n          content: slotOpts.option.name,\n          container: '.m-add-edit-project',\n          delay: { show: 200 },\n          classes: 'tooltip w-tooltip',\n        }"}]},[_vm._v("\n        "+_vm._s(slotOpts.option.name)+"\n      ")])]},null,slotOpts)]}}:null,_vm._l((_vm.templatesToCopy),function(name){return {key:name,fn:function(slotOpts){return [_vm._t(name,null,null,slotOpts)]}}}),(_vm.value && _vm.allowEmpty)?{key:"clear",fn:function(slotOpts){return [_vm._t("clear",function(){return [_c('span',{staticClass:"multiselect__clear",attrs:{"title":"Clear"},on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clearAll.apply(null, arguments)}}},[_c('common-icon',{attrs:{"id":"times","color":"'#ccc'"}})],1)]},null,slotOpts)]}}:null,{key:"noResult",fn:function(slotOpts){return [_vm._t("noResult",function(){return [_vm._v("\n      "+_vm._s(_vm.$t('No results found'))+"\n    ")]},null,slotOpts)]}},(!_vm.templatesToCopy.includes('singleLabel') && _vm.$scopedSlots.option)?{key:"singleLabel",fn:function(slotOpts){return [_vm._t("option",null,null,slotOpts)]}}:null,(_vm.canLoadMore && !_vm.$scopedSlots.afterList)?{key:"afterList",fn:function(){return [_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.loadMoreVisible),expression:"loadMoreVisible"}],staticClass:"multiselect__loading-more-results"},[_vm._v("\n      "+_vm._s(_vm.$t('Loading more results...'))+"\n    ")])]},proxy:true}:null],null,true)},'Multiselect',{
    options: _vm.groupedOptions,
    value: _vm.value,
    label: _vm.label,
    trackBy: _vm.trackBy,
    placeholder: _vm.placeholder,
    loading: _vm.loading,
    disabled: _vm.disabled,
    searchable: _vm.searchable,
    internalSearch: _vm.internalSearch,
    id: _vm.id,
    multiple: _vm.multiple,
    closeOnSelect: _vm.closeOnSelect,
    'data-identifier': _vm.dataIdentifier,
  },false),_vm.listenersForSelect))}
var staticRenderFns = []

export { render, staticRenderFns }