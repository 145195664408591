<template>
  <PopoverMenuActionItem @click="logout" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="14"
        viewBox="0 0 18 14"
      >
        <path
          d="M3.792.75h2.734c.215 0 .39.176.39.39v.782c0 .215-.175.39-.39.39H3.792c-.863 0-1.563.7-1.563 1.563v6.25c0 .863.7 1.563 1.563 1.563h2.734c.215 0 .39.175.39.39v.781c0 .215-.175.391-.39.391H3.792a3.126 3.126 0 0 1-3.125-3.125v-6.25C.667 2.15 2.067.75 3.792.75Zm7.522.635-.638.638a.386.386 0 0 0 .007.556l3.682 3.575h-8.1a.392.392 0 0 0-.39.39v.912c0 .215.176.39.39.39h8.1l-3.682 3.571a.392.392 0 0 0-.007.557l.638.638c.153.153.4.153.554 0l5.351-5.339a.391.391 0 0 0 0-.553l-5.351-5.338a.394.394 0 0 0-.554.003Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Logout') }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';
import api from '@/services/api';

export default {
  components: {
    PopoverMenuActionItem,
  },
  setup() {
    async function logout() {
      await api.put('/v/1/logout.json');
      window.location = '/launchpad/logout/projects';
    }

    return {
      logout,
    };
  },
};
</script>
