<template>
  <div class="thumbnails-grid" ref="thumbnails-grid">
    <FileThumbnail
      v-for="(file, index) in files"
      :key="file.id || -index"
      :file="file"
      :task="task"
      @preview-image="previewImage"
      @preview-file="previewFile"
    />

    <button
      class="remaining-images-button"
      v-if="totalFilesCount > count"
      @click="loadMoreFiles"
    >
      <span v-if="loadingFiles">
        <FileLoadingIcon />
      </span>
      <span v-else>
        <template v-if="totalFilesCount - count > 10">
          {{ $t('Show +10 more files') }}
        </template>
        <template v-else>
          {{ $t('Show remaining [0] files', totalFilesCount - count) }}
        </template>
      </span>
    </button>

    <FileUploadMenu
      v-if="canAddFiles"
      :can-upload-file="canUploadFile"
      :is-collaborator="isCollaborator"
      placement="top"
      @on-file-change="onFileChange"
      @select-existing-files="selectExistingFiles"
    >
      <button
        class="upload-button"
        v-tooltip="{
          content: $t('Upload a new file'),
        }"
      >
        <template v-if="isUploading || loadingFiles">
          <FileLoadingIcon />
        </template>
        <CommonIcons v-else id="add" color="#4461D7" />
      </button>
    </FileUploadMenu>
  </div>
</template>

<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import { computed, reactive, shallowRef } from 'vue-demi';
import { useFilesLoader } from '@teamwork/use';
import FileModel from 'fileModel';
import { isDisplayableImage } from '@/utils/helpers/files';
import FileThumbnail from '../FileThumbnail/FileThumbnail.vue';
import FileLoadingIcon from '../FileLoadingIcon/FileLoadingIcon.vue';
import FileUploadMenu from '../FileUploadMenu/FileUploadMenu.vue';

export default {
  name: 'FileUpload',
  components: {
    CommonIcons,
    FileThumbnail,
    FileLoadingIcon,
    FileUploadMenu,
  },
  props: {
    task: { type: Object, required: true },
    canUploadFile: { type: Boolean, required: true },
    isCollaborator: { type: Boolean, required: true },
    canAddFiles: { type: Boolean, required: true },
    isUploading: { type: Boolean, default: false },
  },
  setup(props) {
    const count = shallowRef(10);
    const params = reactive({
      include: 'users',
      getVersions: true,
      taskId: props.task.id,
      getComments: true,
    });
    const {
      items,
      totalCount: totalFilesCount,
      loading: loadingFiles,
      inSync,
    } = useFilesLoader({
      params,
      count,
    });
    const files = computed(() =>
      items.value.map((file) => ({
        ...file,
        // Add some properties for compatibility with tko.
        name: file.displayName,
        uploadedByUserId: file.uploadedBy.id,
        uploadedByUserFirstName: file.uploadedBy.firstName,
        uploadedByUserLastName: file.uploadedBy.lastName,
        changeFollowerIds: file.changeFollowers.userIds
          ? file.changeFollowers.userIds.join(',')
          : '',
      })),
    );

    return {
      files,
      loadingFiles,
      totalFilesCount,
      count,
      inSync,
    };
  },
  watch: {
    isUploading(newVal) {
      if (newVal) {
        this.scrollIntoView();
      }
    },
  },
  methods: {
    loadMoreFiles() {
      if (this.count < this.totalFilesCount) {
        this.count += 10;
      }
    },
    onFileChange(event) {
      this.count = Infinity;
      this.$emit('on-file-change', event);
    },
    selectExistingFiles() {
      this.$emit('select-existing-files');
    },
    previewImage({ file, version }) {
      this.$store.dispatch('modals/tko/open', {
        id: 'previewImages',
        args: {
          item: file,
          version,
          filteredItems: this.files
            .slice()
            .filter((f) => isDisplayableImage(f))
            .map((f) => new FileModel(f)),
          attachedTo: {
            type: 'Task',
            projectId: this.task.projectId,
          },
        },
      });
    },
    previewFile({ file, version }) {
      this.$store.dispatch('modals/tko/open', {
        id: 'previewFile',
        args: {
          file,
          version,
          attachedTo: {
            type: 'Task',
            projectId: this.task.projectId,
          },
        },
      });
    },
    scrollIntoView() {
      this.$refs['thumbnails-grid'].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnails-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px;
}

.remaining-images-button {
  appearance: none;
  background-color: #eef1f5;
  border: none;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
}

.upload-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 62px;
  appearance: none;
  background-color: #fff;
  border: 1px dashed #4461d7;
  border-radius: 4px;
  &:hover {
    background-color: #eaecf4;
  }
}
</style>
