<template>
  <div
    :class="[
      'w-option-picker',
      { 'has-indicators': showIndicators, 'has-dot': showDot, block },
    ]"
    v-bind="$attrs"
    ref="root"
  >
    <v-popover
      :open.sync="isOpen"
      @show="$emit('open')"
      :placement="placement"
      :popover-arrow-class="popoverArrowClass"
      :offset="offset"
      :disabled="disabled"
      :popper-options="popperOptions"
    >
      <slot name="trigger">
        <div
          class="w-option-picker__current"
          @click="togglePopover"
          :class="[{ open: isOpen, disabled }]"
          :style="{
            background: showDot ? 'transparent' : currentOption.background,
            color: currentOption.color,
          }"
        >
          <span
            v-if="showDot"
            class="w-option-picker__option-dot"
            :style="{ background: currentOption.dotColor }"
          />
          <span
            ref="current"
            v-tooltip="{ content: tooltip }"
            :class="[
              'w-option-picker__option-label',
              {
                'is-placeholder': currentOption.isPlaceholder,
                'is-empty': !currentOption.id,
              },
            ]"
          >
            {{ currentOption.label }}
          </span>
        </div>
      </slot>
      <template slot="popover">
        <div
          class="w-option-picker__options"
          :style="{
            maxWidth,
            maxHeight,
          }"
        >
          <slot name="top" />
          <div
            v-for="option in options"
            :class="[
              'w-option-picker__option',
              {
                selected: option.value === value,
                hasDot: showDot,
              },
            ]"
            :style="{ background: option.background, color: option.color }"
            :key="option.id"
            v-close-popover
            @click="select(option)"
          >
            <span
              v-if="showDot"
              class="w-option-picker__option-dot"
              :style="{ background: option.dotColor }"
            />
            {{ option.label }}
          </div>
          <slot name="bottom" />
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import Vue from 'vue';
import vTooltipConfig from '@/utils/plugins/config/v-tooltip';

export default {
  name: 'OptionPicker',
  props: {
    value: { type: [Number, Object, String], default: 0 },
    options: { type: Array, default: () => [] },
    placeholder: { type: String, default: () => Vue.t('Select Option') },
    placeholderBackground: { type: String, default: '#f5f5f5' },
    placeholderColor: { type: String, default: '#2E2E2F' },
    placement: { type: String, default: 'bottom' },
    noArrow: { type: Boolean, default: false },
    showIndicators: { type: Boolean, default: false },
    offset: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    showDot: { type: Boolean, default: false },
    showTooltip: { type: Boolean, default: false },
    maxWidth: { type: String, default: '150px' },
    maxHeight: { type: String, default: '245px' },
    block: { type: Boolean, default: true },
    open: { type: Boolean, default: false },
  },
  data() {
    return {
      isEllipsized: false,
    };
  },
  methods: {
    togglePopover() {
      this.isOpen = !this.isOpen;
    },
    select(option) {
      this.isOpen = false;
      this.$emit('change', option);
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(newVal) {
        this.$emit('update:open', newVal);
      },
    },
    popperOptions: () => ({
      modifiers: {
        hide: { enabled: true },
      },
    }),
    currentOption() {
      const seletedOption = this.options.find(
        (option) => option.id === this.value,
      );

      if (seletedOption != null) {
        return seletedOption;
      }

      return {
        id: '',
        label: this.placeholder,
        background: this.placeholderBackground,
        color: this.placeholderColor,
        isPlaceholder: true,
      };
    },
    tooltip() {
      const currentOption = this.currentOption;
      if (
        currentOption.isPlaceholder ||
        !this.isEllipsized ||
        !this.showTooltip
      ) {
        return null;
      }

      return currentOption.label;
    },
    popoverArrowClass: (vm) =>
      vm.noArrow ? 'hidden' : vTooltipConfig.popover.defaultArrowClass,
  },
  updated() {
    requestAnimationFrame(() => {
      if (this.$refs.current) {
        const { offsetWidth, scrollWidth } = this.$refs.current;
        this.isEllipsized = offsetWidth < scrollWidth;
      }
    });
  },
};
</script>

<style lang="scss">
$self: '.w-option-picker';

.w-option-picker {
  & {
    display: inline-block;
  }

  &.block {
    &,
    .v-popover,
    .v-popover > div {
      display: block;
      width: 100%;
    }
  }

  .v-popover {
    .trigger {
      display: block !important;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    height: 100%;
  }

  &__current,
  &__option {
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    padding: 13px 15px;
    display: flex;
    align-items: center;
  }

  &__current {
    &.disabled {
      cursor: not-allowed;
    }
  }

  &__options {
    padding: 10px;
    box-sizing: content-box;
    overflow-y: auto;
    overflow-x: hidden;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(66, 82, 110, 0.36);
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 6px;
    }
  }

  &__option {
    display: block;
    min-width: 150px;
    margin-bottom: 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.hasDot {
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 4px;
      border-radius: 4px;
      flex-shrink: 0;
    }

    &-label {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.is-placeholder {
        color: #7d7d7d;
      }
    }
  }

  &.has-indicators {
    #{$self}__current {
      position: relative;

      &:before {
        content: '\F107';
        font-size: 18px;
        color: inherit;
        font-family: 'FontAwesome';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.2s ease;
      }

      &.open {
        &:before {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &.has-dot {
    #{$self}__current {
      padding: 13px 0;
      text-align: left;
    }
  }
}
</style>
