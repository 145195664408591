import moment from 'moment';

const initialState = () => ({
  projectData: {},
  step: {},
  id: 0,
  name: '',
  company: null,
  description: '',
  items: [],
  selectedItems: [],
  makeMeCreator: true,
  uncompleteItems: false,
  saveAsTemplate: false,
  initialOffsetDate: moment(),
  dateOffset: 0,
  keepOffWeekends: 0,
  createActivity: false,
  operation: 'copy',
  targetInstallation: {},
  tagIds: '',
  isBillable: true,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    projectData(state, projectData) {
      state.projectData = projectData;
    },
    step(state, step) {
      state.step = step;
    },
    id(state, id) {
      state.id = id;
    },
    name(state, name) {
      state.name = name;
    },
    company(state, company) {
      state.company = company;
    },
    description(state, description) {
      state.description = description;
    },
    isBillable(state, isBillable) {
      state.isBillable = isBillable;
    },
    makeMeCreator(state, makeMeCreator) {
      state.makeMeCreator = makeMeCreator;
    },
    uncompleteItems(state, uncompleteItems) {
      state.uncompleteItems = uncompleteItems;
    },
    items(state, items) {
      state.items = items;
    },
    selectedItems(state, selectedItems) {
      state.selectedItems = selectedItems;
    },
    saveAsTemplate(state, saveAsTemplate) {
      state.saveAsTemplate = saveAsTemplate;
    },
    initialOffsetDate(state, initialOffsetDate) {
      state.initialOffsetDate = initialOffsetDate;
    },
    dateOffset(state, dateOffset) {
      state.dateOffset = dateOffset;
    },
    keepOffWeekends(state, keepOffWeekends) {
      state.keepOffWeekends = keepOffWeekends;
    },
    createActivity(state, createActivity) {
      state.createActivity = createActivity;
    },
    operation(state, operation) {
      state.operation = operation;
    },
    targetInstallation(state, targetInstallation) {
      state.targetInstallation = targetInstallation;
    },
    tagIds(state, tagIds) {
      state.tagIds = tagIds;
    },
    init(state) {
      const s = initialState();
      Object.assign(state, s);
    },
  },
  getters: {
    payload: (state, getters, { account }) => {
      const select = state.selectedItems;
      return {
        toTemplate: state.saveAsTemplate,
        newFromTemplate: false,
        targetDate: moment().format('YYYYMMDD'),
        id: state.id,
        installationId: state.targetInstallation.id || account.id,
        sourceInstallationId: account.id,
        'cloneproject-action': state.operation,
        cloneProjectName: state.name,
        description: state.description,
        copyTasks: select.includes('tasks') ? 'YES' : 'NO',
        copyMilestones: select.includes('milestones') ? 'YES' : 'NO',
        copyMessages: select.includes('messages') ? 'YES' : 'NO',
        copyFiles: select.includes('files') ? 'YES' : 'NO',
        copyLinks: select.includes('links') ? 'YES' : 'NO',
        copyNotebooks: select.includes('notebooks') ? 'YES' : 'NO',
        copyTimelogs: select.includes('timelogs') ? 'YES' : 'NO',
        copyInvoices: select.includes('invoices') ? 'YES' : 'NO',
        copyExpenses: select.includes('expenses') ? 'YES' : 'NO',
        copyRisks: select.includes('risks') ? 'YES' : 'NO',
        copyPeople: select.includes('people') ? 'YES' : 'NO',
        copyBudgets: select.includes('budgets') ? 'YES' : 'NO',
        copyWebhooks: select.includes('webhooks') ? 'YES' : 'NO',
        copyForms: select.includes('forms') ? 'YES' : 'NO',
        copyAutomations: select.includes('automations') ? 'YES' : 'NO',
        daysOffset: state.dateOffset,
        keepOffWeekends: state.keepOffWeekends,
        createActivityLog: state.createActivity ? 'YES' : 'NO',
        createItemsUsingCurrentUser: state.makeMeCreator ? 'YES' : 'NO',
        uncomplete:
          state.uncompleteItems || state.saveAsTemplate ? 'YES' : 'NO',
        copyComments: select.includes('comments') ? 'YES' : 'NO',
        copyFollowers: select.includes('followers') ? 'YES' : 'NO',
        copyProjectRoles: 'YES',
        copyProjectUpdates: 'NO',
        copyProjectPrivacy: 'YES',
        copyLogo: 'YES',
        companyId: state.company ? state.company.id : 0,
        newCompanyName: '',
        tagIds: state.tagIds || '',
        isBillable: state.isBillable,
      };
    },
  },
  actions: {
    restoreSelections({ commit, state, rootState }) {
      const key = state.saveAsTemplate ? 'saveAsTemplate' : 'copyProject';
      const userPrefs = rootState.preferences.user[key];
      commit(
        'selectedItems',
        Object.keys(userPrefs.items).filter((k) => userPrefs.items[k]),
      );
      commit('keepOffWeekends', userPrefs.keepOffWeekends);
      commit('createActivity', userPrefs.createActivity);
      commit('makeMeCreator', userPrefs.makeMeCreator);
      commit('uncompleteItems', userPrefs.uncompleteItems);
    },
    persistSelections({ dispatch, state, rootState }) {
      const key = state.saveAsTemplate ? 'saveAsTemplate' : 'copyProject';
      const oldPrefs = rootState.preferences.user[key];
      const newPrefs = {
        ...oldPrefs,
        items: { ...oldPrefs.items },
        keepOffWeekends: state.keepOffWeekends,
        createActivity: state.createActivity,
        makeMeCreator: state.makeMeCreator,
        uncompleteItems: state.uncompleteItems,
      };
      state.items.forEach(({ id }) => {
        newPrefs.items[id] = state.selectedItems.includes(id);
      });
      dispatch(
        'preferences/change',
        {
          scope: 'user',
          prefs: { [key]: newPrefs },
        },
        { root: true },
      );
      dispatch('preferences/persistChanges', 'user', { root: true });
    },
  },
};
