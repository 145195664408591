<template>
  <SidebarPanel
    class="fixed h-screen font-sans top-0 bottom-0 z-[11] w-[404px] text-text"
    :title="$t('Help Center')"
    @close="close"
  >
    <template #titlePrepend v-if="!!previousState">
      <button
        class="
          border-none
          bg-transparent
          p-0
          mr-2
          w-6
          h-6
          flex
          items-center
          justify-center
        "
        @click="popState"
      >
        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z"
            fill="#8F9BB3"
          />
        </svg>
      </button>
    </template>
    <template #default>
      <div class="flex flex-col w-full h-full">
        <section class="flex flex-col px-6 mt-0 mb-6">
          <SearchInput
            v-model="searchTerm"
            :placeholder="$t('Search knowledge base')"
            :debounce-timeout="600"
            @input="handleSearchInputTyped"
          />
        </section>
        <section class="flex flex-col flex-auto">
          <Transition mode="out-in">
            <HelpCenterDefaultState
              v-if="currentState.id === STATE_DEFAULT"
              @gettingStartedNavItemClicked="handleGettingStartedNavItemClicked"
              @resourcesNavItemClicked="handleResourcesNavItemClicked"
              @menuItemClicked="handleDefaultStateMenuItemClicked"
            />
            <HelpCenterSearchResultsState
              v-else-if="currentState.id === STATE_SEARCH_RESULTS"
              v-bind="currentState.data"
              @articleClicked="handleSearchResultsArticleClicked"
            />
            <HelpCenterWebinarsState
              v-else-if="currentState.id === STATE_WEBINARS"
              @CTAClicked="handleWebinarCTAClicked"
            />
            <HelpCenterKnowledgeBaseState
              v-else-if="currentState.id === STATE_KNOWLEDGE_BASE_CATEGORIES"
              :categories="knowledgeBaseCategories"
              @categoryClicked="handleKnowledgeBaseCategoryClicked"
            />
            <HelpCenterSubcategoriesAndArticlesState
              v-else-if="
                currentState.id ===
                STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES
              "
              v-bind="currentState.data"
              :key="currentState.data.breadcrumb"
              @subcategoryClicked="handleSubcategoryClicked"
              @articleClicked="handleArticleClicked"
            />
            <HelpCenterArticleState
              v-else-if="currentState.id === STATE_KNOWLEDGE_BASE_ARTICLE"
              v-bind="currentState.data"
              :key="currentState.data.title"
              @relatedArticleClicked="handleRelatedArticleClicked"
            />
            <HelpCenterWhatsNewState
              v-else-if="currentState.id === STATE_WHATS_NEW"
              @linkTextClicked="handleWhatsNewItemLinkTextClicked"
            />
            <HelpCenterContactSupportState
              v-else-if="currentState.id === STATE_CONTACT_SUPPORT"
              @backToHelpCenterClicked="handleBackToHelpCenterClicked"
              @validFormSubmitted="handleValidContactFormSubmitted"
              @helpCenterContactFilledOut="handleHelpCenterContactFilledOut"
            />
            <HelpCenterKeyboardShortcutsState
              v-else-if="currentState.id === STATE_KEYBOARD_SHORTCUTS"
            />
          </Transition>
        </section>
      </div>
    </template>
  </SidebarPanel>
</template>

<script>
import { onMounted, ref, shallowRef, computed, Vue2 as Vue } from 'vue-demi';
import SidebarPanel from '@/platform/components/side-nav/panels/SidebarPanel.vue';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';
import SearchInput from '@/platform/components/side-nav/SearchInput.vue';
import HelpCenterDefaultState from '@/platform/components/side-nav/panels/help-center/HelpCenterDefaultState.vue';
import HelpCenterSearchResultsState from '@/platform/components/side-nav/panels/help-center/HelpCenterSearchResultsState.vue';
import HelpCenterWebinarsState from '@/platform/components/side-nav/panels/help-center/HelpCenterWebinarsState.vue';
import HelpCenterKnowledgeBaseState from '@/platform/components/side-nav/panels/help-center/HelpCenterKnowledgeBaseState.vue';
import HelpCenterSubcategoriesAndArticlesState from '@/platform/components/side-nav/panels/help-center/HelpCenterSubcategoriesAndArticlesState.vue';
import HelpCenterArticleState from './HelpCenterArticleState.vue';
import HelpCenterWhatsNewState from '@/platform/components/side-nav/panels/help-center/HelpCenterWhatsNewState.vue';
import HelpCenterContactSupportState from '@/platform/components/side-nav/panels/help-center/HelpCenterContactSupportState.vue';
import HelpCenterKeyboardShortcutsState from '@/platform/components/side-nav/panels/help-center/HelpCenterKeyboardShortcutsState.vue';
import {
  provideKnowledgeBaseApi,
  useKnowledgeBaseApi,
} from '@/platform/composables/useKnowledgeBaseApi';
import { provideWebinarsApi } from '@/platform/composables/useWebinarsApi';
import useStore from '@/platform/composables/useStore';
import usePendo from '@/platform/composables/usePendo';

const STATE_DEFAULT = 'default';
const STATE_SEARCH_RESULTS = 'searchResults';
const STATE_WEBINARS = 'webinars';
const STATE_KNOWLEDGE_BASE_CATEGORIES = 'knowledgeBaseCategories';
const STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES =
  'knowledgeBaseSubcategoriesAndArticles';
const STATE_KNOWLEDGE_BASE_ARTICLE = 'knowledgeBaseArticle';
const STATE_WHATS_NEW = 'whatsNew';
const STATE_CONTACT_SUPPORT = 'contactSupport';
const STATE_KEYBOARD_SHORTCUTS = 'keyboardShortcuts';

export default {
  name: 'HelpCenterPanel',
  components: {
    SidebarPanel,
    SearchInput,
    HelpCenterDefaultState,
    HelpCenterSearchResultsState,
    HelpCenterWebinarsState,
    HelpCenterKnowledgeBaseState,
    HelpCenterSubcategoriesAndArticlesState,
    HelpCenterArticleState,
    HelpCenterWhatsNewState,
    HelpCenterContactSupportState,
    HelpCenterKeyboardShortcutsState,
  },
  setup() {
    provideKnowledgeBaseApi();
    provideWebinarsApi();

    const store = useStore();
    const serviceApi = useKnowledgeBaseApi();
    const { hideActivePanel, isHelpCenterPanelOpen } = useSidebarPanels();
    const { trackPendoEvent } = usePendo();

    const statesHistory = ref([{ id: STATE_DEFAULT, data: null }]);
    const currentState = computed(() => statesHistory.value.at(-1));
    const previousState = computed(() => statesHistory.value.at(-2));
    const knowledgeBaseCategories = ref([]);
    const searchTerm = shallowRef('');
    const searchResults = ref([]);
    const isSearching = shallowRef(false);
    const isLoading = shallowRef(false);
    const isHelpCenterContactFilledOut = shallowRef(false);

    const helpEventFields = ['plan_name', 'user_role', 'page'];

    const close = () => {
      hideActivePanel();
    };

    const pushState = (state, data = null) => {
      statesHistory.value.push({ id: state, data });
    };

    const popState = () => {
      if (isHelpCenterContactFilledOut.value) {
        if (
          // eslint-disable-next-line no-restricted-globals, no-alert
          confirm(
            Vue.t(
              'You have unsubmitted feedback. Are you sure you want to close the window?',
            ),
          )
        ) {
          isHelpCenterContactFilledOut.value = false;
        } else {
          return;
        }
      }
      if (statesHistory.value.length < 2) {
        return;
      }

      const stateIdBeforePop = currentState.value.id;

      statesHistory.value.pop();

      if (stateIdBeforePop === STATE_SEARCH_RESULTS) {
        if (
          statesHistory.value.every(
            (state) => state.id !== STATE_SEARCH_RESULTS,
          ) // No more search results states in statesHistory
        ) {
          searchTerm.value = '';
        } else {
          const lastSearchTerm = statesHistory.value.findLast(
            (state) => state.id === STATE_SEARCH_RESULTS,
          )?.data?.searchTerm;

          if (lastSearchTerm) {
            searchTerm.value = lastSearchTerm;
          }
        }
      }
    };

    const pushArticleState = async (categorySlug, articleSlug, title) => {
      pushState(STATE_KNOWLEDGE_BASE_ARTICLE, {
        isLoading: true,
        title,
      });

      try {
        const result = await serviceApi.getArticleByCategoryAndSlug(
          categorySlug,
          articleSlug,
        );

        currentState.value.data = {
          ...currentState.value.data,
          isLoading: false,
          lastModifiedISO: result.article.updatedAt,
          content: result.article.Contents,
          canonicalURL: result.article.canonicalURL,
          rawRelatedArticles: result.relatedArticles,
        };
      } catch (error) {
        store.dispatch(
          'notifications/flashes/error',
          Vue.t('There was an error loading the article.'),
        );
        popState();
      }
    };

    const pushSubcategoryAndArticlesState = async (slug, breadcrumb) => {
      pushState(STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES, {
        isLoading: true,
        breadcrumb,
      });

      try {
        currentState.value.data = {
          ...currentState.value.data,
          isLoading: false,
          ...(await serviceApi.getSubcategoriesAndArticlesBySlug(slug)),
        };
      } catch (error) {
        store.dispatch(
          'notifications/flashes/error',
          Vue.t('There was an error loading subcategories and articles.'),
        );
        popState();
      }
    };

    const handleSearchInputTyped = async (newTerm) => {
      if (newTerm.trim().length > 0) {
        if (currentState.value.id === STATE_SEARCH_RESULTS) {
          currentState.value.data = {
            searchResults: [],
            searchTerm: newTerm,
            loading: true,
          };
        } else {
          pushState(STATE_SEARCH_RESULTS, {
            searchTerm: newTerm,
            loading: true,
          });
        }

        trackPendoEvent({
          eventName: 'HELP_EVENT',
          commonMetrics: helpEventFields,
          metadata: {
            event_action: 'search_submitted',
          },
        });

        try {
          currentState.value.data = {
            ...currentState.value.data,
            searchResults: await serviceApi.getSearchResults(newTerm),
          };
        } catch (error) {
          store.dispatch(
            'notifications/flashes/error',
            Vue.t('There was an error loading search results.'),
          );
        } finally {
          currentState.value.data = {
            ...currentState.value.data,
            loading: false,
          };
        }
      } else {
        popState();
      }
    };

    const handleSubcategoryClicked = (slug, breadcrumb) => {
      pushSubcategoryAndArticlesState(slug, breadcrumb);

      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'knowledge_base_subcategory_clicked',
          event_label: breadcrumb.split(' / ').at(-1).toLowerCase(),
        },
      });
    };

    const handleKnowledgeBaseCategoryClicked = (categorySlug, categoryName) => {
      pushSubcategoryAndArticlesState(
        categorySlug,
        `Knowledge Base / ${categoryName}`,
      );

      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'knowledge_base_category_clicked',
          event_label: categoryName.toLowerCase(),
        },
      });
    };

    const handleBackToHelpCenterClicked = () => {
      popState();
    };

    const handleArticleClicked = (categorySlug, articleSlug, title) => {
      pushArticleState(categorySlug, articleSlug, title);

      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'knowledge_base_item_clicked',
          event_label: title.toLowerCase(),
        },
      });
    };

    const handleRelatedArticleClicked = (categorySlug, articleSlug, title) => {
      pushArticleState(categorySlug, articleSlug, title);
    };

    const handleSearchResultsArticleClicked = async (
      categorySlug,
      articleSlug,
      title,
    ) => {
      pushArticleState(categorySlug, articleSlug, title);

      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'search_result_clicked',
        },
      });
    };

    const handleGettingStartedNavItemClicked = (
      categorySlug,
      articleSlug,
      title,
    ) => {
      pushArticleState(categorySlug, articleSlug, title);

      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'getting_started_item_clicked',
          event_label: title.toLowerCase(),
        },
      });
    };

    const handleResourcesNavItemClicked = (id, title) => {
      let stateToSet;
      switch (id) {
        case 'webinars':
          stateToSet = STATE_WEBINARS;
          break;
        case 'knowledgeBase':
          stateToSet = STATE_KNOWLEDGE_BASE_CATEGORIES;
          break;
        case 'whatsNew':
          stateToSet = STATE_WHATS_NEW;
          break;
        case 'contactSupport':
          stateToSet = STATE_CONTACT_SUPPORT;
          break;
        default:
          break;
      }

      if (stateToSet) {
        pushState(stateToSet);

        trackPendoEvent({
          eventName: 'HELP_EVENT',
          commonMetrics: helpEventFields,
          metadata: {
            event_action: 'resources_category_clicked',
            event_label: title.toLowerCase(),
          },
        });
      }
    };

    const handleDefaultStateMenuItemClicked = (itemId) => {
      if (itemId === 'keyboardShortcuts') {
        pushState(STATE_KEYBOARD_SHORTCUTS);
      }

      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: `${itemId
            .replace(/([A-Z]+)/g, '_$1')
            .toLowerCase()}_clicked`,
        },
      });
    };

    const handleWhatsNewItemLinkTextClicked = (title) => {
      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'what_new_item_clicked',
          event_label: title.toLowerCase(),
        },
      });
    };

    const handleWebinarCTAClicked = (title, actionText) => {
      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'webinars_item_clicked',
          event_label: title.toLowerCase(),
          action_type: actionText.toLowerCase(),
        },
      });
    };

    const handleHelpCenterContactFilledOut = (val) => {
      isHelpCenterContactFilledOut.value = val;
    };

    const handleValidContactFormSubmitted = () => {
      trackPendoEvent({
        eventName: 'HELP_EVENT',
        commonMetrics: helpEventFields,
        metadata: {
          event_action: 'contact_support_submitted',
        },
      });
    };

    onMounted(async () => {
      try {
        const categories = await serviceApi.getCategories();
        knowledgeBaseCategories.value = categories.filter(
          (category) => category.DisplayOnDocHomepage === true,
        );
      } catch (error) {
        store.dispatch(
          'notifications/flashes/error',
          Vue.t('There was an error loading knowledge base categories.'),
        );
        knowledgeBaseCategories.value = [];
      }
    });

    return {
      STATE_DEFAULT,
      STATE_SEARCH_RESULTS,
      STATE_WEBINARS,
      STATE_KNOWLEDGE_BASE_CATEGORIES,
      STATE_KNOWLEDGE_BASE_SUBCATEGORIES_AND_ARTICLES,
      STATE_KNOWLEDGE_BASE_ARTICLE,
      STATE_WHATS_NEW,
      STATE_CONTACT_SUPPORT,
      STATE_KEYBOARD_SHORTCUTS,
      pushState,
      popState,
      close,
      currentState,
      previousState,
      searchTerm,
      isHelpCenterPanelOpen,
      handleSearchInputTyped,
      handleSubcategoryClicked,
      handleKnowledgeBaseCategoryClicked,
      handleArticleClicked,
      handleRelatedArticleClicked,
      handleSearchResultsArticleClicked,
      handleGettingStartedNavItemClicked,
      handleResourcesNavItemClicked,
      handleDefaultStateMenuItemClicked,
      handleWhatsNewItemLinkTextClicked,
      handleWebinarCTAClicked,
      handleBackToHelpCenterClicked,
      handleValidContactFormSubmitted,
      handleHelpCenterContactFilledOut,
      isSearching,
      isLoading,
      searchResults,
      knowledgeBaseCategories,
    };
  },
};
</script>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
