<template>
  <div
    :class="[
      'task-item-container',
      `level-${level}`,
      { 'has-children': hasChildren, last },
    ]"
  >
    <slot></slot>
    <div
      class="content"
      :style="{
        marginLeft: `${level * 20}px`,
      }"
    >
      <slot name="content"></slot>
    </div>

    <slot name="actions" class="actions"></slot>
  </div>
</template>
<script>
export default {
  name: 'TaskItemConteiner',
  props: {
    level: { type: Number, default: 0 },
    last: { type: Boolean, default: false },
    hasChildren: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';

.task-item-container {
  border: 1px solid #f5f7fa;
  border-bottom: 0;
  padding: 0 8px;
  font-size: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: -1px;

  &:hover {
    background-color: #f5f7fa;
  }

  ::v-deep {
    .task-toggle-button {
      z-index: 3;
    }
  }
  &:last-child {
    border: 1px solid #f5f7fa;
  }
  .content {
    display: flex;
    align-items: center;
    flex-grow: 2;
    overflow: hidden;
  }
  .actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  ::v-deep .assignees {
    width: auto;
  }
}
</style>
