<template>
  <div class="px-6 overflow-auto">
    <h1 class="text-default text-text-secondary font-bold mb-5">
      {{ $t('Knowledge Base') }}
    </h1>
    <IconListItem
      v-for="category in categories"
      :key="category.id"
      icon-size="sm"
      :title="category.name"
      icon-css="bg-[#bba1ff]"
      @click="$emit('categoryClicked', category.slug, category.name)"
    >
      <template #icon>
        <component
          :is="
            category.id in categoryIconMapping
              ? categoryIconMapping[category.id]
              : 'GenericIcon'
          "
        />
      </template>
    </IconListItem>
    <HelpCenterMenuItem
      class="mt-6"
      :is-button="false"
      label="Open in our support website"
      icon="website"
      href="https://support.teamwork.com/projects"
      target="_blank"
    />
  </div>
</template>
<script>
import IconListItem from '@/platform/components/side-nav/panels/help-center/nav-items/IconListItem.vue';
import GettingStartedIcon from '@/assets/images/features/sidenav/gettingStartedIcon.svg';
import UsingTeamworkIcon from '@/assets/images/features/sidenav/usingTeamworkIcon.svg';
import WorkingProjectsIcon from '@/assets/images/features/sidenav/workingProjectsIcon.svg';
import AppsIcon from '@/assets/images/features/sidenav/appsIcon.svg';
import TipsIcon from '@/assets/images/features/sidenav/tipsIcon.svg';
import SettingsIcon from '@/assets/images/features/sidenav/settingsIcon.svg';
import PlanningManagingIcon from '@/assets/images/features/sidenav/planningManagingIcon.svg';
import PricingBillingIcon from '@/assets/images/features/sidenav/pricingBillingIcon.svg';
import AgencyIcon from '@/assets/images/features/sidenav/agencyIcon.svg';
import GenericIcon from '@/assets/images/features/sidenav/genericIcon.svg';
import HelpCenterMenuItem from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenuItem.vue';

export default {
  name: 'HelpCenterKnowledgeBaseState',
  components: {
    IconListItem,
    GettingStartedIcon,
    UsingTeamworkIcon,
    WorkingProjectsIcon,
    AppsIcon,
    TipsIcon,
    SettingsIcon,
    PlanningManagingIcon,
    PricingBillingIcon,
    AgencyIcon,
    GenericIcon,
    HelpCenterMenuItem,
  },
  setup() {
    // Mapped by category ID
    const categoryIconMapping = {
      463: GettingStartedIcon,
      465: UsingTeamworkIcon,
      475: WorkingProjectsIcon,
      467: AppsIcon,
      1146: TipsIcon,
      486: SettingsIcon,
      1141: PlanningManagingIcon,
      1145: PricingBillingIcon,
      3399: AgencyIcon,
    };

    return {
      categoryIconMapping,
    };
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>
