<template>
  <PopoverMenuActionItem @click="openPlanner" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        viewBox="0 0 18 16"
      >
        <path
          d="M9.339 13.598a.485.485 0 0 1-.68 0l-3.542-3.506h-2.22l4.664 4.616c.798.788 2.08.79 2.878 0l4.665-4.616h-2.22l-3.545 3.506ZM12.213.717c-1.188 0-2.311.4-3.213 1.136A5.054 5.054 0 0 0 5.787.717C3.473.717.667 2.569.667 5.795c0 1.214.446 2.347 1.23 3.255H5.7l.974-2.334 1.852 4.112a.52.52 0 0 0 .94.02l1.618-3.236.72 1.438H16.1a4.952 4.952 0 0 0 1.23-3.255C17.333 2.57 14.527.717 12.213.717Zm2.731 7.292h-2.497l-.898-1.797a.52.52 0 0 0-.931 0L9.026 9.399l-1.894-4.21a.522.522 0 0 0-.958.014L5.006 8.009h-1.95c-1.914-2.263-.228-5.73 2.731-5.73 1.01 0 1.66.202 3.213 1.739 1.663-1.644 2.25-1.739 3.213-1.739 2.969 0 4.645 3.467 2.731 5.73Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Success Planner') }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import useStore from '@/platform/composables/useStore';
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';

export default {
  components: {
    PopoverMenuActionItem,
  },
  setup() {
    const store = useStore();

    const openPlanner = () => {
      store.dispatch('quickViews/tko/open', { id: 'viewSuccessReport' });
    };

    return {
      openPlanner,
    };
  },
};
</script>
