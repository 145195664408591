import { v1Url } from '@teamwork/fetcher';
import { useAxios, useRealTimeUpdates } from '@teamwork/use';

export default function usePreferenceActions() {
  const api = useAxios();
  const { socketId } = useRealTimeUpdates();

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  return {
    savePreferences(scope, preferences) {
      return api
        .put(v1Url(`${scope}/prefs`), { prefs: preferences }, config())
        .then(() => undefined);
    },
  };
}
