import SupersetIcon from '@teamwork/common-icons/dist/v-icon';
import sizes from './TeamLogo.scss';

// @vue/component
export default {
  components: { SupersetIcon },
  props: {
    team: { type: Object, required: true },
    margin: {
      type: String,
      default: 'default',
      validator: (val) =>
        [
          'small',
          'small-left',
          'small-right',
          'medium',
          'medium-left',
          'medium-right',
          'large',
          'large-left',
          'default',
        ].includes(val),
    },
    inline: { type: Boolean, default: false },
    size: {
      type: String,
      default: 'default',
      validator: (val) => Object.keys(sizes).includes(val),
    },
  },
  computed: {
    isWhiteLogo: (vm) => !vm.team.logoColor.length,
    backgroundColor: (vm) => (vm.isWhiteLogo ? 'inherit' : vm.team.logoColor),
    borderColor: (vm) => vm.backgroundColor,
    iconStyle: (vm) =>
      `background-color: ${vm.backgroundColor}; border-color: ${vm.borderColor};`,
    baseClassModifiers() {
      const modifiers = [`u-team-logo--${this.size}`];

      if (this.isWhiteLogo) {
        modifiers.push('u-team-logo--white');
      }

      if (this.inline) {
        modifiers.push('u-team-logo--inline');
      }

      modifiers.push(`u-team-logo--spacing-${this.margin}`);

      return modifiers.join(' ');
    },
    logoType() {
      if (this.team.logoUrl && this.team.logoUrl.length) {
        return 'image';
      }

      if (this.team.logoIcon && this.team.logoIcon.length) {
        return 'icon';
      }

      return 'text';
    },
  },
};
