var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-item",on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('div',{staticClass:"task-item__icon",class:{
      'task-item__icon--complete': _vm.item.completed,
      'task-item__icon--hover': _vm.hover && _vm.item.repeatable,
      'task-item__icon--hover--complete':
        _vm.hover && _vm.item.repeatable && _vm.item.completed,
    }},[(_vm.item.completed && _vm.item.repeatable && _vm.hover)?_c('svg',[_c('path',{attrs:{"d":"M12 5V1L7 6L12 11V7C15.31 7 18 9.69 18 13C18 16.31 15.31 19 12 19C8.69 19 6 16.31 6 13H4C4 17.42 7.58 21 12 21C16.42 21 20 17.42 20 13C20 8.58 16.42 5 12 5Z","fill":"#4461D7"}})]):(_vm.item.completed)?_c('CommonIcon',{attrs:{"id":"check"}}):_c('div',[_vm._v(_vm._s(_vm.index + 1))])],1),_vm._v(" "),_c('button',{staticClass:"task-item__text",class:{
      'task-item__text--complete': _vm.item.completed,
      'task-item__text--hover': _vm.hover && _vm.item.repeatable,
      'task-item__text--disabled': _vm.item.id < 3,
    },on:{"click":_vm.click}},[_vm._v("\n    "+_vm._s(_vm.item.description())+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }