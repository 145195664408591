<template>
  <div
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="table-cell-time"
  >
    <div
      class="empty-button"
      v-if="
        minutes === 0 &&
        !isHovering &&
        !timerActive &&
        elapsedTime === 0 &&
        !projectsWorkerBeeLogTimeCtaEnabled
      "
    >
      <SupersetIcon class="empty-state-icon" id="minus-solid" color="#c5cee0" />
    </div>
    <div
      v-if="timerActive || (!timerActive && elapsedTime > 0)"
      class="container"
    >
      <span
        v-tooltip="{
          content:
            timerState === 'pause' ? $t('Pause timer') : $t('Start timer'),
          placement: 'top',
        }"
      >
        <button
          class="btn btn-timer-toggle"
          :class="{ 'btn-pause': timerState === 'pause' }"
          @click="timerToggle"
        >
          <SupersetIcon
            v-if="timerState === 'pause'"
            id="timer-pause-solid"
            color="#fff"
          />
          <SupersetIcon v-else id="timer-start-solid" color="#fff" />
        </button>
      </span>
      <button class="ml--medium btn btn-link" @click="onAddOrEdit()">
        <span
          v-tooltip="{
            content: minutes === 0 ? $t('Log time') : $t('Log more time'),
            placement: 'top',
          }"
        >
          {{ formattedElapsedTime }}
        </span>
      </button>
    </div>
    <div v-else>
      <div
        v-if="isHovering"
        class="container w-14 !h-6"
        :class="[
          'empty-button',
          { 'empty-button-logged-time': elapsedTime === 0 },
        ]"
      >
        <LogTimeButton
          :button-classes="['direction-left', 'is-table-view']"
          icon="clock"
          @click.native="onAddOrEdit(true)"
          :tooltip="minutes === 0 ? $t('Log time') : $t('Log more time')"
          data-identifier="table-view-cell-log-time"
        />
        <LogTimeButton
          :button-classes="['direction-right', 'is-table-view']"
          @click.native="timerToggle"
          :icon="buttonProps.icon"
          :tooltip="$t('Start timer')"
          data-identifier="table-view-cell-start-timer"
          :disabled="timerBtnDisabled"
        />
      </div>
      <template v-else>
        <template v-if="minutes > 0">
          <div v-if="task.userPermissions.canLogTime">
            <button @click="onAddOrEdit()" class="btn btn-reset">
              <span
                v-tooltip="{
                  content: $t('Log More Time'),
                  placement: 'top',
                }"
              >
                {{ formattedTime }}
              </span>
            </button>
          </div>
          <span v-else>
            {{ formattedTime }}
          </span>
        </template>
        <div
          v-else-if="projectsWorkerBeeLogTimeCtaEnabled"
          class="container w-14 !h-6"
          :class="[
            'empty-button',
            { 'empty-button-logged-time': elapsedTime === 0 },
          ]"
        >
          <LogTimeButton
            :button-classes="['direction-left', 'is-table-view']"
            icon="clock"
            @click.native="onAddOrEdit(true)"
            :tooltip="minutes === 0 ? $t('Log time') : $t('Log more time')"
            data-identifier="table-view-cell-log-time"
          />
          <LogTimeButton
            :button-classes="['direction-right', 'is-table-view']"
            @click.native="timerToggle"
            :icon="buttonProps.icon"
            :tooltip="$t('Start timer')"
            data-identifier="table-view-cell-start-timer"
            :disabled="timerBtnDisabled"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  inject,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue-demi';
import Vue from 'vue';
import ko from 'knockout';
import { useRealTimeUpdates } from '@teamwork/use';
import LogTimeButton from '@widgets/TaskDetailWidgets/LogTimeButton';
import SupersetIcon from '@teamwork/common-icons/dist/v-icon';
import { formatMinutes } from '@/utils/helpers/time';
import toTaskModel from '@/utils/helpers/toTaskModel';
import {
  TIMER_STATUSES,
  getTimerByTaskId,
  getTimerStateProps,
  formatElapsedTime,
} from '@/utils/helpers/timer';

import useExperimentA15 from '@/platform/composables/useExperimentA15';
import usePendo from '@/platform/composables/usePendo';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';
import api from '@/services/api';
import { useFeatures } from '@/platform/composables/useFeatures';

// @vue/component
export default {
  name: 'LoggedTime',
  display: 'LoggedTime',
  components: {
    LogTimeButton,
    SupersetIcon,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  props: {
    minutes: { type: Number, required: true, default: 0 },
    task: { type: Object, required: true, default: () => {} },
  },
  computed: {
    formattedTime() {
      return formatMinutes(this.minutes);
    },
  },
  setup(props) {
    const { trackPendoEvent } = usePendo();
    const { EXP_A15_COMMON_METRICS, projectsWorkerBeeLogTimeCtaEnabled } =
      useExperimentA15();
    const { postMessageToLightspeed } = useLightspeedBridge();
    const { newLogTimeModalEnabled } = useFeatures();

    const dispatch = getCurrentInstance().proxy.$store.dispatch;
    const userId = getCurrentInstance().proxy.$store.state.user.id;
    const { currentElapsedTime, currentTimeState, timerId, totalTime } =
      getTimerByTaskId(props.task.id);
    const { STARTED, PAUSE, RESUME, PLAY, EDITED, COMPLETE, DELETED, UPDATED } =
      TIMER_STATUSES;
    const elapsedTime = ref(currentElapsedTime);
    const timerState = ref(currentTimeState);
    const activeTaskTimerId = inject('activeTaskTimerId');
    const setActiveTaskTimerId = inject('setActiveTaskTimerId');
    const timerBtnDisabled = inject('timerBtnDisabled');
    const setTimerBtnDisabled = inject('setTimerBtnDisabled');
    const timerActive = ref(false);
    const subscriptions = ref([]);

    const buttonProps = computed(() => {
      return getTimerStateProps(timerState.value);
    });

    const loggedTime = computed(() => {
      if (props.task && props.task.timeTotals) {
        return formatMinutes(props.task.timeTotals.loggedMinutes);
      }
      return '0h';
    });

    function disposeSubscriptions() {
      subscriptions.value.forEach((subscription, index) => {
        subscription.dispose();
        subscriptions.value.splice(1, index);
      });
    }

    function start() {
      if (timerState.value === PAUSE) {
        return;
      }
      timerState.value = PAUSE;
      setTimerBtnDisabled(true);
      setTimeout(() => {
        const filtered = window.app.timers().find((item) => {
          return ko.unwrap(item.taskId) === props.task.id;
        });
        const startSubscription = filtered.totalTime.subscribe((val) => {
          elapsedTime.value = val;
        });
        subscriptions.value.push(startSubscription);
      }, 750);
      // Need this second delay so the timer has a chance to start before the user starts another.
      setTimeout(() => {
        setTimerBtnDisabled(false);
      }, 2000);
    }

    function stop() {
      if (timerState.value === RESUME) {
        return;
      }
      timerState.value = RESUME;
      disposeSubscriptions();
    }

    function timerToggle() {
      if (timerState.value === PAUSE) {
        timerActive.value = false;
        stop();

        trackPendoEvent({
          eventName: 'TABLE_VIEW_EVENT',
          commonMetrics: EXP_A15_COMMON_METRICS,
          metadata: {
            event_action: 'pause_timer_clicked',
            event_source: 'task_row',
          },
        });
      } else {
        setActiveTaskTimerId(props.task.id);
        timerActive.value = true;

        if (timerState.value === PLAY) {
          trackPendoEvent({
            eventName: 'TABLE_VIEW_EVENT',
            commonMetrics: EXP_A15_COMMON_METRICS,
            metadata: {
              event_action: 'start_timer_clicked',
              event_source: 'task_row',
            },
          });
        } else {
          trackPendoEvent({
            eventName: 'TABLE_VIEW_EVENT',
            commonMetrics: EXP_A15_COMMON_METRICS,
            metadata: {
              event_action: 'resume_timer_clicked',
              event_source: 'task_row',
            },
          });
        }

        start();
      }

      toTaskModel(props.task).ToggleTimer();
    }

    function onLogTimeClick() {
      trackPendoEvent({
        eventName: 'TABLE_VIEW_EVENT',
        commonMetrics: EXP_A15_COMMON_METRICS,
        metadata: {
          event_action: 'log_time_clicked',
          event_source: 'task_row',
        },
      });
    }

    function resetTimer() {
      timerState.value = PLAY;
      if (props.task.id !== activeTaskTimerId.value) {
        disposeSubscriptions();
      } else if (props.task.id === activeTaskTimerId.value) {
        setActiveTaskTimerId(0);
      }
      elapsedTime.value = 0;
    }

    function updateTimerStats(state, oldState) {
      if (state === PAUSE) {
        stop();
      }
      if (state === RESUME) {
        start();
      }
      if (state === STARTED) {
        start();
      }
      if (state === COMPLETE) {
        resetTimer();
      }
      if (state === DELETED) {
        resetTimer();
      }
      if (state === UPDATED) {
        resetTimer();
      }
      if (state === EDITED) {
        if (oldState === 'resume') {
          start();
        }
      }
    }

    function onAddOrEdit(manualLog = false) {
      if (timerState.value === 'pause') {
        timerToggle();
      }

      onLogTimeClick();

      if (manualLog) {
        if (newLogTimeModalEnabled.value) {
          postMessageToLightspeed('twa:open-dialog', {
            dialogName: 'TimeTimelogAddOrEditDialog',
            dialogProps: { task: this.task, openFromTWA: true },
          });
        } else {
          this.$store.dispatch('modals/tko/open', {
            id: 'addOrEditTimeEntry',
            args: {
              task: toTaskModel(this.task),
              projectId: this.task.projectId,
              source: 'table_view_task_row',
            },
          });
        }
      } else {
        const task = props.task;
        const hrs = Math.floor(elapsedTime.value / 3600);
        const mins =
          Math.round(Math.round(elapsedTime.value - hrs * 3600) / 60) || 1;
        const { timerId: newTimerId } = getTimerByTaskId(props.task.id);

        if (newLogTimeModalEnabled.value) {
          api
            .get('/projects/api/v3/me/timers.json', {
              params: {
                ids: newTimerId,
              },
            })
            .then(({ data }) => {
              postMessageToLightspeed('twa:open-dialog', {
                dialogName: 'TimeTimelogAddOrEditDialog',
                dialogProps: {
                  timer: data.timers[0],
                  duration: hrs * 60 + mins,
                  openFromTWA: true,
                },
              });
            })
            .catch(() => {
              this.$store.dispatch('notifications/flashes/error', {
                title: Vue.t('Sorry, an error has occurred. Please try again.'),
              });
            });
        } else {
          dispatch('modals/open', {
            name: 'update-time-log-modal',
            props: {
              projectId: task.project.id,
              projectName: task.project.name,
              taskId: task.id,
              taskName: task.name,
              timerId: newTimerId || 0,
              manualLog,
              timeLog: {
                hours: hrs,
                minutes: mins,
                userId,
                isBillable: true,
                description: '',
              },
            },
          });
        }
      }
    }

    onMounted(() => {
      if (totalTime) {
        const totalTimeSubscription = totalTime.subscribe((val) => {
          elapsedTime.value = val;
        });
        subscriptions.value.push(totalTimeSubscription);
      }

      updateTimerStats(timerState.value);

      if (timerState.value === 'pause') {
        setActiveTaskTimerId(props.task.id);
      }
    });

    onUnmounted(() => {
      if (subscriptions.value.length) {
        disposeSubscriptions();
      }
    });

    useRealTimeUpdates((event) => {
      // reset timer if task or project change
      if (
        (event.type === 'timer' && event.action === EDITED && timerId === 0) ||
        (event.action === DELETED &&
          event.detail === 'usertimer-deleted' &&
          props.task.id === event.taskId)
      ) {
        resetTimer();
      }
      if (event.action === RESUME && event.detail === 'usertimer-resume') {
        // Finds the timer that is active and sets the ID.
        const runningTimer = window.app.timers().find((item) => {
          return item.running();
        });
        if (runningTimer.taskId() === props.task.id) {
          setActiveTaskTimerId(runningTimer.taskId());
          timerActive.value = true;
          start();
        } else {
          timerActive.value = false;
          stop();
        }
      }
      if (
        event.action === PAUSE &&
        event.detail === 'usertimer-pause' &&
        props.task.id === activeTaskTimerId.value
      ) {
        setActiveTaskTimerId(0);
        timerActive.value = false;
        stop();
      }
      if (event.type === 'timer' && event.taskId === props.task.id) {
        updateTimerStats(event.action, currentTimeState);
      }
    });

    watch(activeTaskTimerId, () => {
      if (activeTaskTimerId.value !== props.task.id) {
        timerActive.value = false;
        stop();
      }
    });

    watch(timerState, () => {
      if (timerState.value === PAUSE) {
        setActiveTaskTimerId(props.task.id);
      }
    });

    return {
      buttonProps,
      activeTaskTimerId,
      elapsedTime,
      formattedElapsedTime: computed(() => {
        return formatElapsedTime(elapsedTime.value);
      }),
      loggedTime,
      onAddOrEdit,
      timerActive,
      timerState,
      timerToggle,
      onLogTimeClick,
      timerBtnDisabled,
      projectsWorkerBeeLogTimeCtaEnabled,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
$font-size--base: 14px;
$width--time-input: 49px;
$height--time-input: 32px;

.table-cell-time {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .table-cell-time__edit-icon {
      display: flex;
    }
  }

  &__edit-icon {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 45px;
    height: 100%;
    padding-right: 15px;
    background: linear-gradient(90deg, transparent 0%, #f5f7fa 45%);
    position: absolute;
    right: 0;
    top: 0;
  }

  &__placeholder-text {
    font-size: $font-size--base;
    color: #4461d7;
    background-color: $white;
    width: 100%;
    height: 100%;
    border-radius: $border-radius--default;
    color: $indigo-30;
    font-size: $font-size--btn;
  }

  .empty-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.empty-button-logged-time {
      background: white;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      border-radius: 8px;
    }
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      font-size: 13px;
      width: 100%;
      position: relative;

      &:hover {
        color: inherit;
      }

      &.btn-timer-toggle {
        display: flex;
        height: 20px;
        width: 20px;
        padding: 0;
        border-radius: 50%;
        align-content: center;
        justify-content: center;
        background: #0b0e1f;
        color: white;

        svg {
          display: flex;
          margin: 0;
          height: 8px;
          font-size: 8px;
          margin-top: 5px;
        }

        &.btn-pause {
          background: #e12d42;

          &:hover {
            background: #cb283b;
          }
        }

        &:hover {
          background: #646d7d;
        }

        &:focus {
          outline: none;
        }
      }

      &.btn-link {
        color: #0b0e1f;
        padding: 0;
        width: auto;
      }
    }
  }
}
</style>
