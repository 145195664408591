import Vue from 'vue';

export default {
  createProjectTour: {
    id: 1,
    name: 'create-project',
    description: () => Vue.t('Create a project'),
    type: 'addProject',
    repeatable: false,
  },
  createTasksTour: {
    id: 2,
    name: 'create-tasks',
    description: () => Vue.t('Create tasks'),
    type: 'addTask',
    repeatable: false,
  },
  trackingBillableTime: {
    id: 3,
    name: 'tracking-billable-time',
    description: () => Vue.t('Explore tracking billable time'),
    type: 'productTour',
    projectSpecific: true,
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Track billable time'),
        description: () => `<p>${Vue.t(
          'Make sure your team delivers on budget and is paid for every minute of work.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Over the next 2 minutes, discover how you can:',
            )}</p>
            <ul>
              <li>${Vue.t('Work within a budget')}</li>
              <li>${Vue.t('Set billable rates')}</li>
              <li>${Vue.t('Track every billable minute')}</li>
            </ul>`,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/track-billable-time.svg',
          width: '280px',
          height: '200px',
        },
        routeInProject: true,
        matchIncludes: true,
      },
      {
        name: () => Vue.t('Work within a budget'),
        description: () => `<p>${Vue.t(
          'Set your project budget so that you can stay on track as you start logging your work and time.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Track project progress with an hourly or fixed fee budget.',
            )}</p>
          <p style="padding-top: 25px">${Vue.t(
            'Click on "Add a budget" within your project dashboard to set your project budget.',
          )}</p>
          `,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/work-within-budget.svg',
          width: '280px',
          height: '200px',
        },
        routeInProject: true,
        stepRoute: '/finance/budgets',
        matchIncludes: true,
      },
      {
        name: () => Vue.t('Set billable rates'),
        description: () => `<p>${Vue.t(
          'Set a billable rate for users on this project. As they log their time, this will be tracked against your budget.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              "On project completion, your project invoice will calculate automatically based on users' billable rates and logged time.",
            )}</p>`,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/set-billable-rates.svg ',
          width: '280px',
          height: '200px',
        },
        routeInProject: true,
        stepRoute: '/finance/billing/open',
        matchIncludes: true,
      },
      {
        name: () => Vue.t('Track every billable minute'),
        description: () => `<p>${Vue.t(
          'Log how long you and your team spend on each piece of work with Time Tracking and the Teamwork Timer app.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Start the stopwatch to time tasks as you work, or log time after the task has been completed.',
            )}</p>`,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/track-every-billable-minute.svg',
          width: '280px',
          height: '200px',
        },
        routeInProject: false,
        stepRoute: '/everything/time',
        matchIncludes: true,
      },
    ],
  },
  managingTeamCapacityTour: {
    id: 4,
    name: 'manage-capacity',
    description: () => Vue.t('Explore managing team capacity'),
    type: 'productTour',
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Manage team capacity'),
        description: () => `<p>${Vue.t(
          'Estimate team members availability and allocate tasks the right way.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Over the next 2 minutes, discover how you can:',
            )}</p>
            <ul>
              <li>${Vue.t("Plan your team's workload")}</li>
                <li>${Vue.t('Maximize your team capacity')}</li>
            </ul>`,
        routeInProject: true,
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/manage-team-capacity.svg',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t(`Plan your team's workload`),
        description: () => `<p>${Vue.t(
          "Use the Workload Planner to manage and understand your team's capacity.",
        )}</p>
      <p style="padding-top: 25px">${Vue.t(
        'Drill down to the project or task level, to see who is under- or over-worked, and redistribute tasks accordingly.',
      )}</p>`,
        routeInProject: false,
        stepRoute: '/planning/workload',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/plan-your-teams-workload.svg ',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t('Maximize team capacity'),
        description: () => `<p>${Vue.t(
          "The Utilization Report shows you how to measure your team's available time, so you can feel certain that your projects are efficiently resourced.",
        )}</p>
          <p style="padding-top: 25px">${Vue.t(
            'Learn from past projects or forecast utilization for upcoming projects.',
          )}</p>`,
        routeInProject: false,
        stepRoute: '/reports/utilization',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/maximise-team-capacity.svg',
          width: '280px',
          height: '200px',
        },
      },
    ],
  },
  reportingProjectHealthUtilization: {
    id: 5,
    name: 'reporting-project-health-utilization',
    description: () => Vue.t('Explore reporting'),
    type: 'productTour',
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Track team performance'),
        description: () => `<p>${Vue.t(
          'Report on your project progress and gain insights to help you manage your resources with ease.',
        )}</p>
              <p style="padding-top: 25px">${Vue.t(
                'Over the next 2 minutes, discover how you can:',
              )}</p>
            <ul>
              <li>${Vue.t('Track progress at a glance')}</li>
              <li>${Vue.t("Maximize your team's capacity")}</li>
            </ul>`,
        routeInProject: true,
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/track-team-performance.svg ',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t(`Progress at a glance`),
        description: () => `<p>${Vue.t(
          'The Project Health Report allows you to spot unhealthy projects, identify overdue tasks, and take action to keep your team on track.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'This single report becomes your mission control - where you’ll know all of your project statuses at a single glance.',
            )}</p>`,
        routeInProject: false,
        stepRoute: '/reports/health/',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/progress-at-glance.svg',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t('Maximize team capacity'),
        description: () => `<p>${Vue.t(
          "The Utilization Report shows you how to measure your team's available time, so you can feel certain that your projects are efficiently resourced.",
        )}</p>
              <p style="padding-top: 25px">${Vue.t(
                'Learn from past projects or forecast utilization for upcoming projects.',
              )}</p>`,
        routeInProject: false,
        stepRoute: '/reports/utilization',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/maximise-team-capacity.svg',
          width: '280px',
          height: '200px',
        },
      },
    ],
  },
  managingProjectsTour: {
    id: 6,
    name: 'managing-projects',
    description: () => Vue.t('Explore managing projects'),
    type: 'productTour',
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Manage projects with ease'),
        description: () => `<p>${Vue.t(
          'Make project planning simpler, more efficient, and more impactful. Over the next 2 minutes, discover how you can:',
        )}</p>
            <ul>
              <li>${Vue.t('Track progress at a glance')}</li>
              <li>${Vue.t('Allocate your resources')}</li>
              <li>${Vue.t("Maximize your team's capacity")}</li>
              <li>${Vue.t('Standardize project creation')}</li>
            </ul>`,
        routeInProject: true,
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/manage-projects-with-ease.svg ',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t('Progress at a glance'),
        description: () => `<p>${Vue.t(
          'The Project Health Report allows you to spot unhealthy projects, identify overdue tasks, and take action to keep your team on track.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'This single report becomes your mission control - where you’ll know all of your project statuses at a single glance.',
            )}</p>
            </p>`,
        routeInProject: false,
        stepRoute: '/reports/health',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/progress-at-glance.svg ',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t("Plan your team's workload"),
        description: () => `<p>${Vue.t(
          "Use the Workload Planner to manage and understand your team's capacity.",
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Drill down to the project or task level, to see who is under- or over-worked, and redistribute tasks accordingly.',
            )}</p>
            </p>`,
        routeInProject: false,
        stepRoute: '/planning/workload',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/allocate-your-resources.svg',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t('Maximize team capacity'),
        description: () => `<p>${Vue.t(
          "The Utilization Report shows you how to measure your team's available time, so you can feel certain that your projects are efficiently resourced.",
        )}</p>
              <p style="padding-top: 25px">${Vue.t(
                'Learn from past projects or forecast utilization for upcoming projects.',
              )}</p>`,
        routeInProject: false,
        stepRoute: '/reports/utilization',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/maximise-team-capacity.svg',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t('Standardize project creation'),
        description: () => `<p>${Vue.t(
          'Fire up new, pre-populated projects in seconds.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Use Teamwork Templates for repeatable projects to cut down on manual work, save time, and scale your best practices.',
            )}</p>
            </p>`,
        routeInProject: false,
        stepRoute: '/projects/templates/projects/custom',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/create-instant-projects.svg ',
          width: '280px',
          height: '200px',
        },
      },
    ],
  },
  inviteTeamTour: {
    id: 7,
    name: 'invite-team',
    description: () => Vue.t('Invite your team'),
    type: 'addUser',
    repeatable: true,
  },
  importData: {
    id: 8,
    name: 'import-data',
    description: () => Vue.t('Import your data'),
    type: 'import',
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Import your existing work'),
        description: () => `<p>${Vue.t(
          'Save time and effort when transitioning your projects into Teamwork from your previous project management platform.',
        )}</p>
              <p style = "padding-top: 25px"> ${Vue.t(
                'Use one of our importers to get you up and running with Teamwork.',
              )}</p>
            `,
        routeInProject: false,
        stepRoute: '/settings/import',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/import-existing-work.svg',
          width: '280px',
          height: '200px',
        },
      },
    ],
  },
  integrateApps: {
    id: 9,
    name: 'integrate-apps',
    description: () => Vue.t('Integrate with the apps you love'),
    type: 'integrate',
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Integrate the apps you love'),
        description: () => `<p>${Vue.t(
          'Work your way by connecting the apps your team loves with Teamwork.',
        )}</p>
          <p style="padding-top: 25px">${Vue.t(
            'Choose from a variety of Teamwork-built and third-party integrations available from your Teamwork account.',
          )}</p>
          </p>`,
        routeInProject: false,
        stepRoute: '/settings/integrations',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/integrate-apps-you-love.svg ',
          width: '280px',
          height: '200px',
        },
      },
    ],
  },
  billingClientsForWork: {
    id: 10,
    name: 'billing-clients-for-work',
    description: () => Vue.t('Explore billing clients for work'),
    type: 'productTour',
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Never miss a payment'),
        description: () => `<p>${Vue.t(
          'Log time, store billing information, and never miss a payment. Over the next 2 minutes, discover how you can:',
        )}</p>
            <ul>
              <li>${Vue.t('Track every billable minute')}</li>
              <li>${Vue.t('Set billable rates')}</li>
              <li>${Vue.t('Bill accurately')}</li>
            </ul>`,
        routeInProject: true,
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/never-miss-payment.svg ',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t('Track every billable minute'),
        description: () => `<p>${Vue.t(
          'Log time spent on a project across multiple tasks and team members, including billable and non-billable hours for invoicing.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              "Once the project is complete, it's easy to review the time-logs and generate an invoice for your clients based on these.",
            )}</p>`,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/track-every-billable-minute.svg',
          width: '280px',
          height: '200px',
        },
        routeInProject: false,
        stepRoute: '/everything/time',
        matchIncludes: true,
      },
      {
        name: () => Vue.t('Set billable rates'),
        description: () => `<p>${Vue.t(
          'Set a billable rate for users on this project. As they log their time, this will be tracked against your budget.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              "On project completion, your project invoice will calculate automatically based on users' billable rates and logged time.",
            )}</p>`,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/set-billable-rates.svg ',
          width: '280px',
          height: '200px',
        },
        routeInProject: true,
        stepRoute: '/finance/billing/open',
        matchIncludes: true,
      },
      {
        name: () => Vue.t('Bill accurately'),
        description: () => `<p>${Vue.t(
          'Easily log billable time and expenses for your projects, to allow for transparent project progression for clients, and improved profitability.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'You can log as much time and expenses into a project as you wish, and export your invoices to your accounting system.',
            )}</p>
            </p>`,
        routeInProject: true,
        stepRoute: '/finance/billing/open',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/bill-accurately.svg',
          width: '280px',
          height: '200px',
        },
      },
    ],
  },
  workingWithClients: {
    id: 11,
    name: 'working-with-clients',
    description: () => Vue.t('Explore working with clients'),
    type: 'productTour',
    repeatable: true,
    steps: [
      {
        name: () => Vue.t('Collaborate with your clients'),
        description: () => `<p>${Vue.t(
          'Strengthen alignment, increase transparency, and build foundational relationships.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Over the next 2 minutes, discover how you can:',
            )}</p>
            <ul>
              <li>${Vue.t('Centralize client communications')}</li>
              <li>${Vue.t('Gather project requirements')}</li>
              <li>${Vue.t('Maximize your time')}</li>
            </ul>`,
        routeInProject: true,
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/collaborate-with-your-clients.svg ',
          width: '280px',
          height: '200px',
        },
      },
      {
        name: () => Vue.t('Working with clients'),
        description: () => `<p>${Vue.t(
          'Organize and report on your projects under the clients they relate to by creating a company for each client.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Creating a company allows you to bring your external contractors and client stakeholders into Teamwork for free using our Collaborator and Client user licence types.',
            )}</p>`,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/collaborate-with-clients.svg',
          width: '280px',
          height: '200px',
        },
        routeInProject: false,
        stepRoute: '/people/companies',
        matchIncludes: true,
      },
      {
        name: () => Vue.t('Gather project requirements'),
        description: () => `<p>${Vue.t(
          'Take the manual labour out of gathering the information you need for projects by using Intake Forms.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Send a form link to your customers to fill out, and once the form is completed, a task is created with all the information you need.',
            )}</p>`,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/gather-project-requirements.svg ',
          width: '280px',
          height: '200px',
        },
        routeInProject: true,
        stepRoute: '/forms',
        matchIncludes: true,
      },
      {
        name: () => Vue.t('Maximize your time'),
        description: () => `<p>${Vue.t(
          'Automate your repetitive processes and improve team productivity by minimizing tedious and time-consuming tasks.',
        )}</p>
            <p style="padding-top: 25px">${Vue.t(
              'Choose from a gallery of existing automation templates or build your own customized workflows that address the most frequent bottlenecks you encounter.',
            )}</p>
            </p>`,
        routeInProject: true,
        stepRoute: '/tasks/table',
        matchIncludes: true,
        image: {
          normal:
            'https://twa-prod.teamwork.com/tko/public/images/product-tours/maximize-your-time.svg',
          width: '280px',
          height: '200px',
        },
      },
    ],
  },
};
