<template>
  <li class="menu-element" ref="item">
    <MenuPopover
      trigger="hover"
      popover-arrow-class="hidden"
      :boundaries-element="popoverBoundaries"
      :container="$refs.item"
      :no-arrow="true"
      :no-close-other="true"
      placement="auto-end"
      offset="0px"
    >
      <a v-on="$listeners">
        <span class="menu-element__icon" v-if="icon">
          <CommonIcons :id="icon" :color="iconColor" />
        </span>
        <div class="menu-element__text">{{ text }}</div>
        <span v-if="subtitle" class="menu-element__subtitle">
          {{ subtitle }}
        </span>
        <span class="menu-element__check" v-if="checked">
          <CommonIcons id="alert-success" />
        </span>
        <CommonIcons v-if="hasSubmenu" id="chevron-right" />
      </a>
      <template #menu v-if="hasSubmenu">
        <slot />
      </template>
    </MenuPopover>
  </li>
</template>

<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import MenuPopover from '@widgets/Menu/MenuPopover/MenuPopover';

export default {
  name: 'MenuElement',
  display: 'MenuElement',
  props: {
    text: { type: String, required: true, default: null },
    subtitle: { type: String, default: '' },
    icon: { type: String, required: false, default: null },
    iconColor: { type: String, required: false, default: '#5c7080' },
    checked: { type: Boolean, required: false, default: false },
    hoverColor: { type: String, required: false, default: '#F5F7FA' },
  },
  components: {
    CommonIcons,
    MenuPopover,
  },
  computed: {
    hasSubmenu() {
      return Boolean(this.$slots.default);
    },
    popoverBoundaries() {
      return document.body;
    },
  },
};
</script>

<style lang="scss">
.menu-element {
  .trigger {
    display: block !important;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 24px;
    text-decoration: none;
    user-select: none;
    font-size: 14px;
    line-height: 19px;
    color: inherit;

    &:hover {
      background-color: #f5f7fa;
      cursor: pointer;
      color: inherit;
    }
  }

  &__icon {
    margin-right: 7px;
  }

  &__text {
    word-break: break-word;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
    color: #646d7d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
}
</style>
