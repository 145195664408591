<template>
  <SidebarPanel
    class="fixed h-screen font-sans top-0 bottom-0 z-[11] w-[404px] text-text"
    data-identifier="side-nav-notifications-panel"
    :title="$t('Notifications')"
    @close="close"
  >
    <template #panel-header-actions>
      <FeedbackIcon
        data-identifier="side-nav-notifications-feedback-icon"
        class="mr-2"
      />
      <NotificationsOptionsPopover should-show-mute-option />
    </template>

    <div class="flex flex-col w-full h-full px-2">
      <Notifications
        :loaders="loaders"
        data-identifier-prefix="side-nav"
        :hide-mentions-toggle="true"
        :hide-tab-all="true"
        :show-bell-empty-state-img="true"
        darken-tab-border
        class="flex min-h-0"
      />
    </div>
  </SidebarPanel>
</template>

<script>
import { computed, watch } from 'vue-demi';

import Notifications from '@sections/Notifications/Notifications';
import NotificationsOptionsPopover from '@sections/Notifications/NotificationsOptionsPopover';
import SidebarPanel from '@/platform/components/side-nav/panels/SidebarPanel.vue';

import FeedbackIcon from '@/assets/images/common/feedback.svg';

import { useNotificationsManager } from '@/components/sections/Notifications/useNotificationsManager';
import { useNotificationsUnreadCount } from '@/platform/composables/useNotificationsUnreadCount';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

export default {
  name: 'NotificationsPanel',
  components: {
    Notifications,
    NotificationsOptionsPopover,
    SidebarPanel,
    FeedbackIcon,
  },
  setup() {
    const { hideActivePanel } = useSidebarPanels();
    const { unread, read } = useNotificationsManager();

    const { count: unreadCount } = useNotificationsUnreadCount();

    const loaders = computed(() => {
      return {
        read,
        unread,
      };
    });

    const { isNotificationsPanelOpen } = useSidebarPanels();

    watch(isNotificationsPanelOpen, (isOpen) => {
      // to improve performance of opening notification panel
      // reset the the number of notification items on closing the panel if
      // its greater than one page.
      if (!isOpen) {
        const pageSize = 20;
        if (unread.state.items.value.length > pageSize) {
          unread.reset();
        }

        if (read.state.items.value.length > pageSize) {
          read.reset();
        }
      }
    });

    const close = () => {
      hideActivePanel();
    };

    return {
      close,
      loaders,
      unreadCount,
    };
  },
};
</script>
