export default [
  {
    path: '/people',
    component: () => import('./RoutePeople'),
    children: [
      {
        path: '',
        redirect() {
          return `/people/${
            window.localStorage.getItem(
              'lscache-twProjects-last-people-section',
            ) || 'people'
          }`;
        },
      },
      {
        path: 'companies',
        component: () => import('./RoutePeopleCompanies'),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'people',
        component: () => import('./RoutePeoplePeople'),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'teams',
        component: () => import('./RoutePeopleTeams'),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'statuses',
        component: () => import('./RoutePeopleStatuses'),
        meta: {
          noSpacing: true,
        },
      },
      {
        path: 'loginHistory',
        component: () => import('./RoutePeopleLoginHistory'),
        meta: {
          noSpacing: true,
        },
      },
    ],
  },
  {
    path: '/statuses*',
    redirect: '/people/statuses',
  },
];
