var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","enter-active-class":"duration-300 ease-out","enter-class":"translate-y-4 opacity-0","enter-to-class":"translate-y-0 opacity-100","leave-active-class":"duration-200 ease-in","leave-class":"translate-y-0 opacity-100","leave-to-class":"translate-y-4 opacity-0"}},[_c('div',{staticClass:"\n      f-modal\n      text-left\n      inline-block\n      align-middle\n      bg-white\n      rounded-md\n      overflow-hidden\n      shadow-lg\n      transform\n      transition-all\n      w-full\n    ",class:_vm.modalWidthClass,attrs:{"role":"dialog","aria-modal":"true","aria-labelledby":"modal-headline"}},[_c('div',{staticClass:"bg-white h-full"},[_vm._t("header",function(){return [(_vm.title)?_c('FModalHeader',{attrs:{"close-modal":_vm.closeModal,"persistent":_vm.persistent,"should-show-close-btn":_vm.shouldShowCloseButton,"title":_vm.title}}):_vm._e()]},null,{
          closeModal: _vm.closeModal,
          persistent: _vm.persistent,
          shouldShowCloseButton: _vm.shouldShowCloseButton,
          title: _vm.title,
        }),_vm._v(" "),_vm._t("content",function(){return [_c('FModalContent',{class:{
            'pt-6': !_vm.$slots.header && !_vm.title,
            'pb-6': !_vm.$slots.footer,
          }},[_vm._t("default",null,null,{
              closeModal: _vm.closeModal,
              persistent: _vm.persistent,
            })],2)]},null,{
          closeModal: _vm.closeModal,
          persistent: _vm.persistent,
          shouldShowCloseButton: _vm.shouldShowCloseButton,
        }),_vm._v(" "),(_vm.$slots.footer)?_c('FModalFooter',[_vm._t("footer",null,null,{
            closeModal: _vm.closeModal,
            persistent: _vm.persistent,
          })],2):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }