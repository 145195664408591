<template>
  <div
    ref="datePickerEl"
    class="flex flex-col items-stretch text-body-1"
    :style="{
      '--lsds-a-color-text-subtle': '#64697D',
      '--lsds-a-color-text-disabled': '#8F96B3',
      '--lsds-a-color-border-default': '#EDEFF5',
      '--lsds-c-datepicker-range-color-background-default': '#D8E0FF',
      '--lsds-c-datepicker-range-color-text-default': '#3C55BD',
    }"
  >
    <div class="flex items-center justify-between gap-2 px-2">
      <button
        type="button"
        class="bg-transparent w-6 h-6 border-0"
        @click="navigateMonth(-1)"
      >
        <CommonIcon id="chevron-left" class="!w-3 !h-3" />
      </button>

      <LscDatePickerMonthYearMenu
        :month-year.sync="monthYear"
        location="bottom center"
        offset="8px"
        @monthYearMenuVisibilityChange="
          $emit('monthYearMenuVisibilityChange', $event)
        "
      >
        <template #activator="activator">
          <FButton
            variant="tertiary"
            size="md"
            append-icon="lsi-dropdown"
            v-bind="activator.props"
            @dblclick.prevent.stop="goToToday"
          >
            {{ monthYear.format('MMMM YYYY') }}
            <CommonIcon
              id="chevron-down"
              class="trigger-arrow ml-1 !w-3 !h-3"
            />
          </FButton>
        </template>
      </LscDatePickerMonthYearMenu>
      <button
        type="button"
        class="bg-transparent w-6 h-6 border-0"
        @click="navigateMonth(1)"
      >
        <CommonIcon id="chevron-right" class="!w-3 !h-3" />
      </button>
    </div>
    <div
      class="
        relative
        grid grid-cols-7
        place-items-center
        gap-y-1
        px-3
        pt-2
        after:absolute
        after:top-10
        after:h-px
        after:w-full
        after:border-0
        after:border-b
        after:border-solid
        after:border-[color:var(--lsds-a-color-border-default)]
        after:content-['']
      "
    >
      <div class="contents text-body-2 font-semibold">
        <div
          v-for="(dayName, index) in dayNames"
          :key="dayName + index"
          class="flex h-8 w-full items-center justify-center"
        >
          {{ dayName }}
        </div>
      </div>
      <div class="contents">
        <button
          v-for="day in visibleDays"
          :key="day.toISOString()"
          :aria-selected="isDateSelected(day)"
          :disabled="isDateDisabled(day)"
          type="button"
          class="
            outline-none
            bg-transparent
            border-0
            relative
            z-0
            inline-flex
            h-8
            w-full
            items-center
            justify-center
            text-text
            font-medium
            text-default
            leading-none
            outline-0
            transition-colors
            before:absolute before:inset-0 before:-z-10 before:content-['']
            after:absolute
            after:-z-10
            after:h-7
            after:w-7
            after:rounded-full
            after:bg-transparent
            after:ring-2
            after:ring-[transparent]
            after:content-['']
            hover:after:bg-surface-hover
            focus-visible:after:bg-surface-hover
          "
          :class="{
            'text-[color:var(--lsds-a-color-text-subtle)]': !isSameMonth(
              day,
              monthYear,
            ),
            'before:left-auto before:w-1/2 before:bg-[color:var(--lsds-c-datepicker-range-color-background-default)] after:!ring-[color:var(--lsds-c-datepicker-range-color-background-default)]':
              isStartDate(day) && !isEndDate(day),
            'before:right-auto before:w-1/2 before:bg-[color:var(--lsds-c-datepicker-range-color-background-default)] after:!ring-[color:var(--lsds-c-datepicker-range-color-background-default)]':
              isEndDate(day) && !isStartDate(day),
            'font-semibold !text-[color:var(--lsds-c-datepicker-range-color-text-default)] before:bg-[color:var(--lsds-c-datepicker-range-color-background-default)] after:bg-transparent after:!ring-[color:var(--lsds-c-datepicker-range-color-background-default)] hover:after:!bg-[var(--lsds-c-datepicker-range-color-background-hover)]':
              isBetweenSelected(day),
            '!text-primary after:border after:border-solid after:border-primary-default':
              isSameDay(day, now) && !isDateSelected(day),
            'font-semibold !text-white after:!bg-primary': isDateSelected(day),
            'text-[color:var(--lsds-a-color-text-disabled)] cursor-not-allowed font-normal':
              isDateDisabled(day),
          }"
          @click="select(day)"
        >
          {{ day.date() }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, shallowRef, watchEffect } from 'vue-demi';
import moment from 'moment';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';

import { FButton } from '@fragments/index';
import LscDatePickerMonthYearMenu from './LscDatePickerMonthYearMenu.vue';
import { datePickerSymbol } from './constants';
import { useLocalization } from '@/platform/composables/useLocalization';
import { isSameDay, getToday, isSameMonth } from './helpers';

const DAY_COUNT = 42;

export default {
  components: {
    CommonIcon,
    FButton,
    LscDatePickerMonthYearMenu,
  },
  props: {
    date: {
      default: datePickerSymbol,
      validator(date) {
        return (
          date === datePickerSymbol ||
          date == null ||
          (moment.isMoment(date) && date.isValid())
        );
      },
    },
    /**
     * Used to select a range of dates.
     */
    dates: {
      default: datePickerSymbol,
      validator(dates) {
        return (
          dates === datePickerSymbol ||
          dates == null ||
          (Array.isArray(dates) &&
            dates.length === 2 &&
            (dates[0] == null ||
              (moment.isMoment(dates[0]) && dates[0].isValid())) &&
            (dates[1] == null ||
              (moment.isMoment(dates[1]) && dates[1].isValid())))
        );
      },
    },
    /**
     * Can clear the date.
     */
    clearable: {
      type: Boolean,
      default: true,
    },
    /*
     * The minimum date that can be selected.
     */
    minDate: {
      type: Object,
      default: undefined,
      validator: (date) =>
        date == null || (moment.isMoment(date) && date.isValid()),
      required: false,
    },
    /*
     * The maximum date that can be selected.
     */
    maxDate: {
      type: Object,
      default: undefined,
      validator: (date) =>
        date == null || (moment.isMoment(date) && date.isValid()),
      required: false,
    },
  },
  emits: ['update:date', 'update:dates'],
  setup(props, { emit }) {
    const datePickerEl = shallowRef();
    const { weekStartsOnSunday } = useLocalization();
    const now = moment();

    const hasDateProp = computed(() => props.date !== datePickerSymbol);
    const hasDatesProp = computed(() => props.dates !== datePickerSymbol);

    const date = computed({
      get: () => {
        return hasDateProp.value ? props.date : undefined;
      },
      set: (newDate) => {
        if (!hasDateProp.value) {
          return;
        }
        if (!isSameDay(newDate, props.date)) {
          emit('update:date', newDate);
        }
      },
    });

    const dates = computed({
      get: () => {
        return hasDatesProp.value ? props.dates : undefined;
      },
      set: (value) => {
        if (!hasDatesProp.value) {
          return;
        }
        if (
          !isSameDay(value[0], props.dates[0]) ||
          !isSameDay(value[1], props.dates[1])
        ) {
          emit('update:dates', value);
        }
      },
    });

    /**
     * @type {import('vue-demi').Ref<moment.Moment|undefined>}
     */
    const monthYear = shallowRef(undefined);

    const dayNames = computed(() => {
      const dayInitials = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
      if (weekStartsOnSunday.value !== 'isoweek') {
        // Move last day 'Sunday' to first position
        const sunday = dayInitials.pop();
        dayInitials.unshift(sunday);
      }
      return dayInitials;
    });

    // Build the days to show in the calendar.
    const visibleDaysArray = computed(() => {
      const start = monthYear.value.clone().startOf('month').startOf('isoweek');
      const respectedStart = start.subtract({
        days: weekStartsOnSunday.value !== 'isoweek' ? 1 : 0,
      });

      // Array to store the dates
      const array = [];

      for (let i = 0; i < DAY_COUNT; i += 1) {
        const nextDate = respectedStart.clone().add(i, 'days');
        array.push(nextDate);
      }

      return array;
    });

    const visibleDays = computed(() =>
      visibleDaysArray.value.map((day) => day.startOf('day')),
    );

    /**
     * Navigate relative to the current month.
     * @param {Number} diff
     */
    function navigateMonth(diff) {
      monthYear.value = moment(monthYear.value)
        .add(diff, 'month')
        .startOf('month');
    }

    /**
     * Navigate to today.
     */
    function goToToday() {
      monthYear.value = moment().startOf('month');
    }

    /**
     *
     * @param {moment} _date
     */
    function isDateDisabled(_date) {
      return (
        (props.minDate && _date < moment(props.minDate).toDate()) ||
        (props.maxDate && _date > moment(props.maxDate).toDate())
      );
    }

    function isStartDate(_date) {
      if (!hasDatesProp.value || !dates.value) {
        return false;
      }
      const [startDate, endDate] = dates.value;
      return isSameDay(_date, startDate) && startDate && endDate;
    }

    function isEndDate(_date) {
      if (!hasDatesProp.value || !dates.value) {
        return false;
      }
      const [startDate, endDate] = dates.value;
      return startDate && endDate && isSameDay(_date, endDate);
    }

    function isDateSelected(_date) {
      if (hasDateProp.value) {
        return isSameDay(_date, date.value);
      }
      if (!dates.value) {
        return false;
      }
      const [startDate, endDate] = dates.value;
      return isSameDay(_date, startDate) || isSameDay(_date, endDate);
    }

    /**
     *
     * @param {moment.Moment} _date
     */
    function isBetweenSelected(_date) {
      if (!hasDatesProp.value || !dates.value) {
        return false;
      }
      const [startDate, endDate] = dates.value;
      return startDate && endDate && _date.isBetween(startDate, endDate, 'day');
    }

    /**
     * Select a day in the calendar.
     * @param {moment.Moment} newDate
     */
    function selectDate(newDate) {
      date.value = moment(newDate);
    }

    /**
     * Select a range in the calendar.
     * @param {moment.Moment} newDate
     */
    function selectDates(newDate) {
      const [startDate, endDate] = dates.value;

      // If both dates are set, clear the selection
      // & set the start date to the new date.
      if (startDate && endDate) {
        dates.value = [moment(newDate), undefined];
      }

      // Set the individual dates if they're not set.
      if (!startDate) {
        dates.value = [moment(newDate), undefined];
      } else if (!endDate) {
        if (newDate < startDate) {
          dates.value = [moment(newDate), startDate];
        } else {
          dates.value = [startDate, moment(newDate)];
        }
      }
    }
    /**
     * Select a day in the calendar.
     * @param {moment.Moment} newDate
     */
    function select(_newDate) {
      const newDate = moment(_newDate);
      if (hasDateProp.value) {
        selectDate(newDate);
      } else if (hasDatesProp.value) {
        selectDates(newDate);
      }
    }

    // Set the initial month and year to the first date in the range, or today if no date is set.
    let initialMonthYear;
    if (hasDateProp.value) {
      initialMonthYear = date.value?.clone() ?? getToday();
    } else if (hasDatesProp.value && dates.value) {
      initialMonthYear =
        dates.value?.[1]?.clone() ?? dates.value?.[0]?.clone() ?? getToday();
    } else {
      initialMonthYear = getToday();
    }
    monthYear.value = initialMonthYear?.startOf('month');

    watchEffect(() => {
      if (!hasDateProp.value && !hasDatesProp.value) {
        // eslint-disable-next-line no-console
        console.warn('LscDatePicker requires a date or dates prop');
      }
      if (hasDateProp.value && hasDatesProp.value) {
        // eslint-disable-next-line no-console
        console.warn('LscDatePicker must not have both a date and dates prop');
      }
    });

    /**
     *
     * @param {moment.Moment} newMonthYear
     */
    function setMonthYear(newMonthYear) {
      if (!newMonthYear || !newMonthYear?.isValid()) {
        return;
      }
      if (!visibleDaysArray.value.contains(newMonthYear)) {
        monthYear.value = newMonthYear.startOf('month');
      }
    }

    return {
      hasDateProp,
      hasDatesProp,
      now,
      datePickerEl,
      monthYear,
      dayNames,
      visibleDays,
      navigateMonth,
      goToToday,
      isDateDisabled,
      isStartDate,
      isEndDate,
      isDateSelected,
      isBetweenSelected,
      isSameMonth,
      isSameDay,
      select,
      setMonthYear,
    };
  },
};
</script>
