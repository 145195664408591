<template>
  <v-popover
    v-if="!isCollaborator"
    trigger="click"
    popover-class="file-upload-popover"
    :placement="placement"
    :open.sync="isOpen"
    offset="0"
    popover-arrow-class="hidden"
    boundaries-element="body"
    @apply-show="$emit('toggle', true)"
    @apply-hide="$emit('toggle', false)"
  >
    <slot />
    <template slot="popover">
      <ul class="upload-menu">
        <li v-if="canUploadFile">
          <button v-close-popover @click="addFile">
            {{ $t('Upload a new file') }}
          </button>
        </li>
        <li>
          <button v-close-popover @click="selectExistingFiles">
            {{ $t('Choose from existing files') }}
          </button>
        </li>
      </ul>
      <input
        multiple
        @change="onFileChange"
        ref="fileInput"
        type="file"
        class="hidden"
      />
    </template>
  </v-popover>
</template>

<script>
import { computed } from 'vue-demi';

export default {
  name: 'FileUploadMenu',
  display: 'FileUploadMenu',
  props: {
    canUploadFile: { type: Boolean, required: true },
    isCollaborator: { type: Boolean, required: true },
    open: { type: Boolean, default: false },
    placement: { type: String, default: 'top' },
  },
  setup(props, { emit }) {
    const isOpen = computed({
      get: () => props.open,
      set: (newVal) => emit('update:open', newVal),
    });

    function addFile() {
      this.$refs.fileInput.click();
    }

    function selectExistingFiles() {
      this.$emit('select-existing-files');
    }

    function onFileChange(event) {
      this.$emit('on-file-change', event);
    }

    return {
      isOpen,
      addFile,
      selectExistingFiles,
      onFileChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.v-popover {
  display: inline-block;
}

.upload-menu {
  list-style: none;
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  padding: 5px 0;
  li {
    button {
      appearance: none;
      border: none;
      background: transparent;
      display: flex;
      font-size: 14px;
      width: 100%;
      height: 32px;
      padding: 7px 13px;
      &:hover {
        background: #f5f7fa;
      }
    }
  }
}
</style>
