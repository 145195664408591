<template>
  <div class="date-range" :class="{ 'date-range--disabled': disabled }">
    <v-popover
      popover-class="date-range__popover"
      :placement="datePickerPlacement"
      :open.sync="isOpen"
      popover-arrow-class="hidden"
      :boundaries-element="boundariesElement"
      open-group="date-range"
      :auto-hide="!isIncludingPopoverOpen"
      :disabled="disabled"
      @show="onShow"
      @hide="onHide"
    >
      <div class="empty-trigger" v-if="!dueDate && !startDate">
        <slot name="trigger">
          <Button
            class="date-range__btn"
            v-tooltip="{ content: $t('Add dates') }"
          >
            <CommonIcon class="date-range__btn-icon" id="calendar-light" />
            {{ $t('Date') }}
          </Button>
        </slot>
      </div>

      <template v-else>
        <slot
          name="trigger-populated"
          :dueDate="dueDate"
          :startDate="startDate"
        >
          <Button
            class="date-range__btn"
            v-tooltip="{
              content:
                dueDate && startDate ? $t('Edit dates') : $t('Add dates'),
            }"
          >
            <CommonIcon class="date-range__btn-icon" id="calendar-light" />
            <template v-if="dueDate || startDate">
              <span v-if="startDate && !dueDate">
                {{ inPast(startDate) ? $t('Started') : $t('Starts') }}
                <span>
                  {{ returnDate(startDate) }}
                </span>
              </span>
              <span
                v-else-if="!startDate && dueDate"
                :class="colorClass(dueDate)"
              >
                {{ $t('Due') }}
                {{ returnDate(dueDate) }}
              </span>
              <span v-else-if="startDate && dueDate">
                <span>
                  {{ returnDate(startDate) }}
                </span>
                -
                <span :class="colorClass(dueDate)">
                  {{ returnDate(dueDate) }}
                </span>
              </span>
            </template>
          </Button>
        </slot>
      </template>
      <template slot="popover">
        <div class="date-range__popover-content">
          <div class="date-range__date-picker text-center">
            <p class="date-range__info">
              <strong>{{ $t('Start Date') }}:</strong>
              {{ formatDate(startDate) || $t('None') }}
              <span v-if="pastDate(startDate)">
                ({{ pastDate(startDate) }})
              </span>
            </p>
            <DatePicker
              @input="onStartDateChange"
              :value="startDate"
              :show-default-shortcuts="true"
            />
          </div>
          <div class="date-range__date-picker text-center">
            <p class="date-range__info">
              <strong>{{ $t('Due Date') }}:</strong>
              {{ formatDate(dueDate) || $t('None') }}
              <span v-if="pastDate(dueDate)">({{ pastDate(dueDate) }})</span>
            </p>
            <DatePicker
              @input="onDueDateChange"
              :value="dueDate"
              :show-default-shortcuts="true"
            />
          </div>
        </div>

        <div class="popover-including-tasks-wrapper">
          <span>{{ $t('Adjust this task') }}&nbsp;</span>
          <v-popover
            :open.sync="isIncludingPopoverOpen"
            placement="top"
            class="popover-including-tasks"
            popover-arrow-class="hidden"
            open-group="date-range"
          >
            <a class="popover-including-tasks__trigger">
              {{ radioSubtasks ? $t('including subtasks') : $t('only') }}
            </a>
            <template #popover>
              <div class="popover-including-tasks__content">
                <label class="popover-including-tasks__label">
                  <input
                    name="adjust-task"
                    type="radio"
                    :value="false"
                    v-model="radioSubtasks"
                    @change="onRadioSubtasksChange"
                    v-close-popover
                  />
                  {{ $t('only') }}
                </label>
                <label class="popover-including-tasks__label">
                  <input
                    name="adjust-task"
                    type="radio"
                    :value="true"
                    v-model="radioSubtasks"
                    @change="onRadioSubtasksChange"
                    v-close-popover
                  />
                  {{ $t('including subtasks') }}
                </label>
              </div>
            </template>
          </v-popover>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import { defineComponent, computed, ref, shallowRef } from 'vue-demi';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import DatePicker from '@widgets/DatePicker';
import Button from '@widgets/Button';
import moment from 'moment';
import Vue from 'vue';
import { isToday, isTomorrow, isYesterday, inPast } from '@/utils/helpers/date';
import useStore from '@/platform/composables/useStore';
import { useLocalization } from '@/platform/composables/useLocalization';

export default defineComponent({
  name: 'DateRangePicker',
  components: {
    DatePicker,
    Button,
    CommonIcon,
  },
  props: {
    dueDate: { type: Object, default: null },
    startDate: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
    datePickerPlacement: { type: String, required: false, default: 'top' },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { dateFormat } = useLocalization();
    const isOpen = ref(false);

    const boundariesElement = shallowRef(document.body);

    const taskPushSubTasks = computed(
      () => store.state.preferences.user.taskPushSubTasks,
    );

    const radioSubtasks = ref(taskPushSubTasks.value);
    const isIncludingPopoverOpen = ref(false);
    const maskDate = computed(() =>
      dateFormat.value.startsWith('DD') ? 'Do MMM' : 'MMM Do',
    );

    function pastDate(date) {
      const diff = date && Math.abs(date.diff(moment(), 'years'));
      if (date && date.isBefore(moment(), 'year') && diff > 0) {
        const yearString = diff === 1 ? Vue.t('year') : Vue.t('years');
        return `${diff} ${yearString} ${Vue.t('ago')}`;
      }
      return null;
    }

    function onStartDateChange(date) {
      const data = { date, autoAdjustDueDate: false };
      // auto adjust due date if start date is after due date
      if (date && props.dueDate && date.isAfter(props.dueDate)) {
        data.autoAdjustDueDate = true;
        store.dispatch(
          'notifications/flashes/info',
          Vue.t('Auto-adjusted Due Date'),
        );
      }
      emit('change-start-date', data);
    }
    function onDueDateChange(date) {
      const data = { date, autoAdjustStartDate: false };
      // auto adjust start date if due date is before start date
      if (date && props.startDate && date.isBefore(props.startDate)) {
        data.autoAdjustStartDate = true;
        store.dispatch(
          'notifications/flashes/info',
          Vue.t('Auto-adjusted Start Date'),
        );
      }
      emit('change-due-date', data);
    }
    function formatDate(date) {
      return date && date.format(maskDate.value);
    }
    function isDifferentYear(date) {
      const currentYear = moment().year();
      return date.year() !== currentYear;
    }

    function returnDate(date) {
      if (isToday(date)) {
        return Vue.t('Today');
      }
      if (isTomorrow(date)) {
        return Vue.t('Tomorrow');
      }
      if (isYesterday(date)) {
        return Vue.t('Yesterday');
      }
      return date.format(
        `${maskDate.value}${isDifferentYear(date) ? ', YYYY' : ''}`,
      );
    }

    function colorClass(date) {
      if (isToday(date)) {
        return 'present';
      }
      if (isTomorrow(date)) {
        return 'future';
      }
      if (inPast(date)) {
        return 'past';
      }
      return '';
    }

    function onRadioSubtasksChange() {
      store.dispatch('preferences/hybrid-persist', {
        prefs: { 'task-push-sub-tasks': radioSubtasks.value },
        scope: 'user',
      });
    }

    function escClose(event) {
      if (event.keyCode === 27) {
        isOpen.value = false;
      }
    }

    function onShow() {
      document.addEventListener('keyup', escClose);
    }

    function onHide() {
      document.removeEventListener('keyup', escClose);
    }

    return {
      boundariesElement,
      onStartDateChange,
      onDueDateChange,
      formatDate,
      isDifferentYear,
      maskDate,
      returnDate,
      colorClass,
      inPast,
      isIncludingPopoverOpen,
      radioSubtasks,
      onRadioSubtasksChange,
      pastDate,
      taskPushSubTasks,
      isOpen,
      escClose,
      onShow,
      onHide,
    };
  },
});
</script>

<style lang="scss">
.date-range__popover {
  .date-picker-wrapper {
    .rd-container {
      border: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.date-range {
  padding: 0 16px;

  &__btn {
    border-radius: 50px;
    background-color: #f5f7fa;
    font-size: 13px;
    border: 1px solid #e1e6ee;

    &:hover {
      border: 1px solid #4461d7;
    }
  }

  &__btn-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  &__popover-content {
    justify-content: center;
    display: flex;
    min-height: 390px;
  }

  &__date-picker {
    display: flex;
    flex-direction: column;
  }

  &__info {
    padding: 16px 0 4px;
    font-size: 14px;
  }

  &--disabled {
    cursor: default;
    pointer-events: none;
  }
}

.popover-including-tasks-wrapper {
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 16px;
  color: #646d7d;
}

.popover-including-tasks {
  font-size: 14px;

  &__trigger {
    cursor: pointer;
    color: #4461d7;

    &:hover {
      text-decoration: underline;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 0;

    input {
      margin-right: 4px;
    }
  }
}
</style>
