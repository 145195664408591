<template>
  <div class="tag-picker">
    <v-popover
      placement="bottom-end"
      boundaries-element="document.body"
      popover-arrow-class="hidden"
      :disabled="disabled"
      @hide="hide"
      @show="show"
      :open.sync="isOpen"
    >
      <!-- Trigger button -->
      <slot>
        <button
          :class="{
            'add-tag-button': true,
            'is-active': isOpen || selectedTags.length > 0,
            'dropdown-open': isOpen,
            'is-button': isFilter,
            'is-limit': selectedTags.length >= limit,
          }"
        >
          <template v-if="isFilter">
            <CommonIcon
              id="tag"
              color="#0B0E1F"
              class="font-size--small mr--medium"
            />
            {{
              selectedTags.length === 0
                ? $t('Tags')
                : selectedTags.length === 1
                ? $t('[0] Tag', 1)
                : $t('[0] Tags', selectedTags.length)
            }}
            <CommonIcon
              id="chevron-down"
              color="#0B0E1F"
              class="tag-picker__chevron"
            />
          </template>
          <span
            v-else
            class="tag-picker__icon-button"
            v-tooltip="{
              content:
                selectedTags.length >= limit
                  ? $t('Only [0] tags are allowed', limit)
                  : $t('Add a tag'),
              placement: 'top',
              boundariesElement: 'document.body',
            }"
          >
            <ProjectsIcon id="plus" color="#fff" class="tag-picker__plus" />
          </span>
        </button>
      </slot>

      <!-- Popover content -->
      <template slot="popover">
        <InlineSearch
          ref="inputElement"
          :value="highlightedTag ? highlightedTag.name : searchTerm"
          @input="searchTerm = $event"
          @on-keydown="highlightTag"
          class="tag-search border--none"
          :placeholder="mode === 'view' ? $t('Search') : $t('Create a tag')"
          :show-icon="false"
        />

        <!-- View Tags mode -->
        <div v-if="mode === 'view'" class="tag-lists">
          <template v-if="selectedTags.length">
            <span class="title ml--medium">
              {{ $t('Selected:') }}
            </span>
            <ItemList v-model="selectedTags" :items-are-selected="true" />
          </template>

          <template v-if="selectedTags.length >= limit">
            <div class="tag-picker__limit-msg">
              {{ $t('Only [0] tags are allowed', limit) }}
            </div>
          </template>

          <template v-else>
            <div
              v-for="{ projectId, projectName, tags } in availableTagsByProject"
              :key="projectId"
            >
              <span class="title ml--medium">{{ projectName }}:</span>
              <ItemList
                :value="tags"
                :items-are-selected="false"
                @select="selectTag"
                :read-only="disabled"
              />
            </div>

            <template
              v-if="inSync && debouncedSearchTerm && availableTags.length === 0"
            >
              <div class="title m--medium emptystate">
                <div class="title emptystate__msg">
                  {{
                    isSearchedTagAlreadySelected
                      ? $t('This tag is already selected')
                      : $t('No tags match your query')
                  }}
                </div>
              </div>
            </template>

            <template v-if="!inSync">
              <div class="title m--medium">{{ $t('Loading tags') }}</div>
            </template>
          </template>
        </div>

        <!-- Create Tag mode -->
        <template v-else>
          <!-- Tags tip message -->
          <template v-if="tagsTipEnabled">
            <div class="tag-picker__no-tags">
              <NoTags />
              <div class="tag-picker__no-tags__title">
                {{ $t('No Tags') }}
              </div>
              <div class="tag-picker__no-tags__description">
                {{
                  $t(
                    'Tags are a way to mark items so that you can use a filter',
                  )
                }}
              </div>
              <button class="btn btn-primary" @click="tagsTipEnabled = false">
                {{ $t('Create a Tag') }}
              </button>
            </div>
          </template>

          <!-- Tags creation form -->
          <div v-else class="create-tag-form">
            <span class="title ml--medium">
              {{ $t('Tag color') }}

              <span
                v-tooltip="{
                  content: $t('Choose a color for your tag'),
                  classes: 'tooltip w-tooltip w-toggle-switch__tooltip',
                }"
              >
                <CommonIcon
                  class="w-toggle-switch__info-icon"
                  id="info-circle"
                  color="#838F9C"
                />
              </span>
            </span>

            <div class="tag-picker__new-tag">
              <div class="tag-picker__tag-indicators">
                <div
                  v-for="(color, index) in tagColors.slice(
                    0,
                    Math.ceil(tagColors.length / 2),
                  )"
                  :key="index"
                  class="tag-picker__tag-indicators__selector"
                >
                  <button
                    @click="selectedColor = color"
                    :class="[
                      {
                        'tag-picker__indicator--btn-selected':
                          color === selectedColor,
                      },
                      'tag-picker__indicator',
                      'tag-picker__indicator--btn',
                    ]"
                    :style="{ color: color, background: color }"
                  />
                </div>
              </div>
              <div class="tag-picker__tag-indicators">
                <div
                  v-for="(color, index) in tagColors.slice(
                    -Math.ceil(tagColors.length / 2),
                  )"
                  :key="index"
                  class="tag-picker__tag-indicators__selector"
                >
                  <button
                    @click="selectedColor = color"
                    :class="[
                      {
                        'tag-picker__indicator--btn-selected':
                          color === selectedColor,
                      },
                      'tag-picker__indicator',
                      'tag-picker__indicator--btn',
                    ]"
                    :style="{ color: color, background: color }"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="projectIdForNewTags > 0"
              class="tag-picker__project-toggle"
            >
              <ToggleSwitch
                :tip="$t('Show only in this project')"
                :reversed-order="true"
                :show-title="false"
                v-model="projectSpecificTag"
                :label="$t('Project Specific')"
              />
            </div>

            <div class="tag-picker__new-tag-button">
              <Button
                class="btn btn-primary"
                @click="createNewTag"
                :loading="isCreating"
              >
                {{ isCreating ? $t('Creating Tag') : $t('Create Tag') }}
              </Button>
            </div>
          </div>
        </template>
      </template>
    </v-popover>
  </div>
</template>

<script>
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import ProjectsIcon from '@teamwork/common-icons/dist/v-projects-icon';
import { useTagsLoader } from '@teamwork/use';
import { useDebounce } from '@vueuse/core';
import {
  computed,
  shallowRef,
  Vue2 as Vue,
  watch,
  watchEffect,
} from 'vue-demi';
import ItemList from '@widgets/ItemList';
import InlineSearch from '@widgets/InlineSearch';
import Button from '@widgets/Button';
import ToggleSwitch from '@widgets/ToggleSwitch';
import { tagColors } from '@/utils/helpers/color';
import TagsAPI from '@/platform/data/tags';
import NoTags from './NoTags/NoTags';
import useStore from '@/platform/composables/useStore';

// @vue/component
export default {
  name: 'TagPicker',
  display: 'TagPicker',
  components: {
    CommonIcon,
    ProjectsIcon,
    InlineSearch,
    Button,
    ToggleSwitch,
    ItemList,
    NoTags,
  },
  data() {
    return {
      isCreating: false,
      highlightedTagIndex: -1,
      tagColors,
      selectedColor: tagColors[6], // Seventh color on the list
      projectSpecificTag: false,
      isOpen: false,
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    projectIds: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 20,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    show() {
      this.popoverOpen = true;
      this.searchTerm = '';
      this.highlightedTagIndex = -1;
      this.$emit('show');
    },
    hide() {
      this.popoverOpen = false;
      this.$emit('hide');
    },
    highlightTag(event) {
      event.stopPropagation();
      if (this.availableTags.length === 0) {
        return;
      }

      switch (event.key) {
        case 'ArrowUp':
          this.highlightedTagIndex = Math.max(0, this.highlightedTagIndex - 1);
          event.preventDefault();
          break;
        case 'ArrowDown':
          this.highlightedTagIndex = Math.min(
            this.availableTags.length - 1,
            this.highlightedTagIndex + 1,
          );
          event.preventDefault();
          break;
        case 'Enter':
        case 'Tab':
          if (this.highlightedTag) {
            this.selectTag(this.highlightedTag);
            this.highlightedTagIndex = -1;
          }
          break;
        default:
          break;
      }
    },
    createNewTag() {
      if (this.searchTerm.length > 50) {
        this.$store.dispatch(
          'notifications/flashes/error',
          Vue.t('Tag name must be less than 50 characters.'),
        );
        return;
      }
      this.isCreating = true;
      const tagObj = {
        name: this.searchTerm,
        color: this.selectedColor,
        projectId: this.projectSpecificTag ? this.projectIdForNewTags : 0,
      };
      TagsAPI.create(tagObj)
        .then((result) => {
          if (result.data.STATUS === 'OK') {
            tagObj.id = result.data.id;
            this.selectTag(tagObj);
            this.searchTerm = '';
          }
        })
        .finally(() => {
          this.isCreating = false;
        });
    },
  },
  computed: {
    highlightedTag() {
      return this.availableTags[this.highlightedTagIndex];
    },
  },

  watch: {
    searchTerm() {
      this.highlightedTagIndex = -1;
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const pageSize = computed(() =>
      Math.max(20, Math.min(100, props.limit + 20)),
    );
    const popoverOpen = shallowRef(false);
    const searchTerm = shallowRef('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const selectedTags = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit('input', value);
      },
    });
    function selectTag(tag) {
      selectedTags.value = selectedTags.value.concat(tag);
    }

    const {
      items: loadedTags,
      inSync,
      response,
      error,
    } = useTagsLoader({
      count: computed(() => (popoverOpen.value ? pageSize.value : -1)),
      pageSize,
      params: computed(() => ({
        getProjectData: true,
        sort: 'projectDateLastUsed',
        sortOrder: 'desc',
        projectIds: props.projectIds,
        q: debouncedSearchTerm.value,
      })),
    });

    // `stableTags` are kept in sync with `loadedTags`
    // at all times except for right after the loader is reset.
    const stableTags = shallowRef([]);
    function updateStableTags() {
      if (response.value || error.value) {
        stableTags.value = loadedTags.value;
      }
    }
    watch(loadedTags, updateStableTags);
    watch(response, updateStableTags);
    watch(error, updateStableTags);

    const availableTags = computed(() =>
      stableTags.value.filter((loadedTag) =>
        selectedTags.value.every(
          (selectedTag) => loadedTag.id !== selectedTag.id,
        ),
      ),
    );

    const availableTagsByProject = computed(() => {
      const groups = [];

      availableTags.value.forEach((tag, index, tags) => {
        if (index === 0 || tags[index - 1].projectId !== tag.projectId) {
          groups.push({
            projectId: tag.projectId,
            projectName:
              tag.projectId === 0 ? Vue.t('Global') : tag.projectName,
            tags: [],
          });
        }
        groups[groups.length - 1].tags.push(tag);
      });

      return groups;
    });

    const projectIdForNewTags = computed(() => {
      const projectIds = props.projectIds
        .split(',')
        .map(Number)
        .filter(Boolean);
      return projectIds.length === 1 ? projectIds[0] : 0;
    });

    const isSearchedTagAlreadySelected = computed(
      () =>
        Boolean(debouncedSearchTerm.value) &&
        selectedTags.value.some(
          (tag) => tag.name === debouncedSearchTerm.value,
        ),
    );

    // Supported modes are "view" and "create".
    const mode = shallowRef('view');
    watchEffect(() => {
      if (!inSync.value || !popoverOpen.value) {
        return;
      }

      const canCreateTags =
        !store.state.account.tagsLockedToAdmins ||
        store.getters['user/isAdmin'];
      mode.value =
        canCreateTags &&
        !props.isFilter &&
        !isSearchedTagAlreadySelected.value &&
        availableTags.value.length === 0 &&
        selectedTags.value.length < props.limit
          ? 'create'
          : 'view';
    });

    const tagsTipEnabled = shallowRef(true);
    watch(searchTerm, () => {
      if (searchTerm) {
        tagsTipEnabled.value = false;
      }
    });

    const inputElement = shallowRef();
    watch(inputElement, () => {
      setTimeout(() => {
        // accessing child element's method is bad practise. Possible improvement
        if (inputElement.value && inputElement.value.focus) {
          inputElement.value.focus();
        }
      }, 100);
    });

    return {
      popoverOpen,
      searchTerm,
      debouncedSearchTerm,
      inSync,
      selectedTags,
      selectTag,
      availableTags,
      availableTagsByProject,
      projectIdForNewTags,
      isSearchedTagAlreadySelected,
      mode,
      tagsTipEnabled,
      inputElement,
    };
  },
};
</script>

<style lang="scss">
@import '~@tko/src/styles/variables/variables';
@import '~@tko/src/styles/mixins/mixins';

$base--font-size: 14px;

.tag-picker {
  cursor: pointer;
  position: relative;

  .add-tag-button {
    background-color: transparent;
    white-space: nowrap;
    border: none;
    height: 32px;

    &.is-button {
      position: relative;
      border: 1px solid #e7ebf1;
      background-color: inherit;
      border-radius: 100px;
      padding: 5px 12px;
      display: flex;
      align-items: center;
      width: 112px;
      color: $color--text-tinted-dark;
      font-size: $base--font-size;
      line-height: $base--font-size;

      .tag-picker__chevron {
        transition: all 0.2s ease;
      }

      &.is-active {
        border-color: $indigo-80;
      }

      &.dropdown-open {
        .tag-picker__chevron {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
          top: 10px;
        }
      }
    }
  }

  &__limit-msg {
    padding: 16px;
  }

  &__icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $indigo-80;
  }

  &__chevron {
    font-size: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    font-size: 10px;
  }

  &__plus {
    font-size: 16px;
  }

  &__new-tag {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 38px;
  }

  &__new-tag-button {
    padding: 10px 8px 15px 8px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  &__tag-indicators {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 12px;
  }

  &__indicator {
    height: 12px;
    width: 12px;
    display: block;
    background: white; //This should always be overriden by a modifier
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;

    &--btn {
      width: 24px;
      height: 24px;
      will-change: transform;
      border: none !important;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
      &:focus {
        outline: none;
      }
    }

    &--btn-selected {
      transform: scale(1.2);
      &::after {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 22px;
        height: 22px;
        display: block;
        content: ' ';
        border: 2px solid #fff;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }
  }

  &__project-toggle {
    margin-top: 5px;
    padding: 0 8px;
    width: 100%;
    box-sizing: border-box;

    .w-toggle-switch {
      display: flex;
      justify-content: space-between;
    }

    .w-toggle-switch__label {
      padding-left: 0;
    }

    .w-toggle-switch__info-icon {
      fill: #0b0e1f;
    }
  }

  &__no-tags {
    max-width: 250px;
    text-align: center;
    padding: 60px 25px;

    &__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
      color: #646d7d;
      margin-bottom: 8px;
    }
  }

  .title {
    font-size: $base--font-size;
  }
}

.is-limit .tag-picker__icon-button {
  background-color: $border-color-focus--btn-tertiary;
}

.tag-search {
  $bg: #f5f7fa !important;
  $placeholderColor: #838f9c !important;

  margin: 16px;
  background-color: $bg;

  .w-inline-search__input {
    background-color: $bg;
    @include placeholder($placeholderColor);
  }

  .w-inline-search__clear-button {
    background-color: $bg;
  }
}

.tag-lists {
  min-height: 200px;
  max-height: 280px;
  overflow-y: auto;
  margin: 8px;
  display: flex;
  flex-direction: column;
  .emptystate {
    background-color: #f5f7fa;
    border-radius: $border-radius--default;
    align-self: center;
    padding: 4px 3px;
    &__msg {
      color: $table-cell--blank-slate-color;
      font-size: $font-size--btn;
      text-align: center;
      padding: 0px;
    }
  }
}

.create-tag-form {
  margin: 8px;
}
</style>
