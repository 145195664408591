/* eslint-disable no-underscore-dangle, import/prefer-default-export */
import { useListLoader } from '@teamwork/use';

function responseToItems(response) {
  return response.data.updates.map((update) => ({
    ...update,
  }));
}

function responseToMeta(response) {
  return {
    totalItems: Number(response.headers[`x-records`]),
    page: Number(response.headers[`x-page`]),
    totalPages: Number(response.headers[`x-pages`]),
  };
}

export function useProductUpdatesLoader({ params, count, pageSize = 20 }) {
  const { state } = useListLoader({
    url: '/updates/latest.json', // Not a versioned products api
    params,
    count,
    responseToItems,
    responseToMeta,
    pageSize,
  });

  return state;
}
