<template>
  <PopoverMenuItem :route="`/people/${user.id}/integrations`" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path
          d="M16.477 1.523C15.003.05 12.105.536 9 2.53 5.895.537 2.998.052 1.523 1.523.05 2.995.535 5.89 2.53 8.993c-1.995 3.104-2.48 6-1.008 7.471 2.27 2.27 6.803-.572 7.477-1.004.677.434 5.205 3.272 7.477 1.004 1.473-1.471.988-4.367-1.008-7.47 1.995-3.104 2.48-6 1.008-7.47Zm-1.264 1.263c.623.623.394 2.405-.882 4.625a23.002 23.002 0 0 0-3.749-3.745c1.567-.899 3.803-1.705 4.631-.88Zm-12.426 0c.831-.829 3.075-.013 4.63.88A23.038 23.038 0 0 0 3.67 7.41c-1.276-2.22-1.506-4.002-.882-4.625Zm0 12.415c-.624-.623-.394-2.405.882-4.625a22.676 22.676 0 0 0 3.75 3.74c-2.222 1.277-4.007 1.507-4.632.885ZM9 13.266a19.949 19.949 0 0 1-4.277-4.272C5.439 8.03 6.803 6.334 9 4.703a20.57 20.57 0 0 1 4.276 4.29A19.924 19.924 0 0 1 9 13.267Zm6.213 1.935c-.626.622-2.41.392-4.633-.884a22.678 22.678 0 0 0 3.75-3.741c1.277 2.22 1.506 4.002.883 4.625ZM9 7.803a1.191 1.191 0 1 0 0 2.383 1.191 1.191 0 0 0 0-2.383Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('My Apps') }}
    </template>
  </PopoverMenuItem>
</template>

<script>
import { useCurrentUser } from '@teamwork/use';
import PopoverMenuItem from './PopoverMenuItem.vue';

export default {
  components: {
    PopoverMenuItem,
  },
  setup() {
    const user = useCurrentUser();

    return {
      user,
    };
  },
};
</script>

<style></style>
