"use strict";

define(['moment'], function (moment) {
  var TimeEntryModel;
  return TimeEntryModel = function () {
    class TimeEntryModel {
      constructor(data) {
        this.updateProperties = this.updateProperties.bind(this);
        this.setupComputedsAndObservables = this.setupComputedsAndObservables.bind(this);
        this.Edit = this.Edit.bind(this);
        this.ToggleBillable = this.ToggleBillable.bind(this);
        this.UpdateData = this.UpdateData.bind(this);
        ko.mapping.fromJS(data, this.mapping, this);
        this.componentName = 'TimeEntry';
        this.updateProperties(data);
        this.setupComputedsAndObservables();

        if (data.tags != null && data.tags.length) {
          this.tags(app.tags.getAll(data.tags));
        } else {
          this.tags = ko.observableArray([]);
        }
      }

      updateProperties(timeEntry) {
        this.date = ko.observable(moment(timeEntry.date));
        this.createdAt = moment(timeEntry.createdAt);

        if (this.taskId == null) {
          // Map old API names to new format, change to bools
          this.taskId = this.todoItemId;
        }

        if (this.taskName == null) {
          this.taskName = this.todoItemName;
        }

        if (this.tasklistName == null) {
          this.tasklistName = this.todoListName;
        }

        if (this.userId == null) {
          this.userId = this.personId;
        }

        if (this.userFirstName == null) {
          this.userFirstName = this.personFirstName;
        }

        if (this.userLastName == null) {
          this.userLastName = this.personLastName;
        }

        this.project = app.projectInfo.get(this.projectId());

        if (timeEntry.avatarUrl == null) {
          this.avatarUrl = "";
        } // Give a default in case these aren't returned


        return timeEntry.tags != null ? timeEntry.tags : timeEntry.tags = [];
      }

      setupComputedsAndObservables() {
        if (this.taskListId == null) {
          if (!ko.isComputed(this.taskListId)) {
            this.taskListId = ko.pureComputed(() => {
              if (this.todoListId == null) {
                return 0;
              }

              if (this.todoListId() === '') {
                return 0;
              } else {
                return parseInt(this.todoListId(), 10);
              }
            });
          }
        }

        if (!ko.isComputed(this.userName)) {
          this.userName = ko.pureComputed(() => {
            return this.userFirstName() + ' ' + this.userLastName();
          });
        }

        if (!ko.isComputed(this.totalMinutes)) {
          this.totalMinutes = ko.pureComputed(() => {
            return this.hours() * 60 + this.minutes();
          });
        }

        if (!ko.isComputed(this.decimalHours)) {
          this.decimalHours = ko.pureComputed(() => {
            var _threePointDecimal;

            _threePointDecimal = (this.totalMinutes() / 60).toFixed(3);

            if (_threePointDecimal.slice(-1) === '0') {
              return _threePointDecimal.slice(0, -1);
            } else {
              return _threePointDecimal;
            }
          });
        }

        if (this.isBillable == null) {
          if (!ko.isComputed(this.isBillable)) {
            this.isBillable = ko.pureComputed(() => {
              if (this.isbillable == null) {
                return false;
              }

              if (this.isbillable() === '1') {
                return true;
              } else {
                return false;
              }
            });
          }
        }

        if (this.isBilled == null) {
          if (!ko.isComputed(this.isBilled)) {
            this.isBilled = ko.pureComputed(() => {
              if (this.isbilled == null) {
                return false;
              }

              if (this.isbilled() === '1') {
                return true;
              } else {
                return false;
              }
            });
          }
        }

        if (!ko.isComputed(this.canLogTime)) {
          return this.canLogTime = ko.pureComputed(() => {
            var ref;
            return (ref = this.project()) != null ? ref.permissions.canLogTime() : void 0;
          });
        }
      }

      Edit() {
        return app.modal.Show('addOrEditTimeEntry', {
          timeEntry: this
        });
      }

      ToggleBillable() {
        var data, newValue, oldValue;
        oldValue = this.isBillable();
        newValue = !oldValue;

        if (this.isbillable != null) {
          this.isbillable(newValue);
        }

        if (this.isBillable != null && !ko.isPureComputed(this.isBillable) && !ko.isComputed(this.isBillable) && ko.isObservable(this.isBillable)) {
          this.isBillable(newValue);
        }

        data = {
          timelog: {
            isBillable: newValue
          }
        };
        return app.api.put(`projects/api/v3/time/${this.id()}.json`, data).then(result => {
          ko.postbox.publish('allTWEvents', {
            itemId: this.id(),
            itemType: 'time',
            actionType: app.consts.ACTIONTYPE.UPDATED,
            eventTime: new Date(),
            projectId: this.projectId(),
            extraInfo: {
              objectType: 'task',
              objectId: this.taskId() || 0,
              validateTimeLogId: true
            }
          });
          return app.flash.Success(this.isBillable() === true || parseInt(this.isBillable(), 10) === 1 ? app.tl('Time log marked as billable') : app.tl('Time log marked as non-billable'));
        }).catch(e => {
          if (this.isbillable != null) {
            this.isbillable(newValue);
          }

          if (this.isBillable != null && !ko.isPureComputed(this.isBillable) && !ko.isComputed(this.isBillable) && ko.isObservable(this.isBillable)) {
            this.isBillable(oldValue);
          }
        });
      }

      UpdateData(timeEntry) {
        if (timeEntry == null) {
          return;
        }

        ko.mapping.fromJS(timeEntry, this.mapping, this);
        this.updateProperties(timeEntry);
        return this.setupComputedsAndObservables();
      }

    }

    ;
    TimeEntryModel.prototype.mapping = {
      key: function (data) {
        return ko.unwrap(parseInt(data.id, 10));
      },
      id: {
        update: function (options) {
          return parseInt(options.data, 10);
        }
      },
      projectId: {
        update: function (options) {
          return parseInt(options.data, 10);
        }
      },
      hours: {
        update: function (options) {
          return parseInt(options.data, 10);
        }
      },
      minutes: {
        update: function (options) {
          return parseInt(options.data, 10);
        }
      },
      userId: {
        update: function (options) {
          return parseInt(options.data, 10);
        }
      },
      personId: {
        update: function (options) {
          return parseInt(options.data, 10);
        }
      },
      taskEstimatedTime: {
        update: function (options) {
          return parseInt(options.data, 10);
        }
      },
      hasStartTime: {
        update: function (options) {
          if (options.data === '1' || options.data === true) {
            return true;
          } else {
            return false;
          }
        }
      },
      taskIsSubTask: {
        update: function (options) {
          if (options.data === '1' || options.data === true) {
            return true;
          } else {
            return false;
          }
        }
      },
      taskIsPrivate: {
        update: function (options) {
          if (options.data === '1' || options.data === true) {
            return true;
          } else {
            return false;
          }
        }
      },
      taskId: {
        update: function (options) {
          if (options.data === '') {
            return 0;
          } else {
            return parseInt(options.data, 10);
          }
        }
      },
      todoItemId: {
        update: function (options) {
          if (options.data === '') {
            return 0;
          } else {
            return parseInt(options.data, 10);
          }
        }
      }
    };
    return TimeEntryModel;
  }.call(this);
});