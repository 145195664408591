<template>
  <div class="flex flex-col justify-center items-center h-full">
    <div
      class="
        text-center
        h-full
        flex flex-col
        items-center
        justify-center
        min-h-[314px]
      "
      v-if="canAddProjectsPermission"
    >
      <AddAProjectImage class="mt-300 flex-shrink-0" />
      <p
        class="
          text-text text-[14px]
          leading-default
          px-200
          mb-300
          pt-4
          mx-auto
          w-full
          flex flex-col
          items-center
        "
      >
        <span class="font-semibold block mb-2 w-44">
          {{ $t('Using Projects') }}
        </span>

        <small class="w-56 text-[#646d7d] text-[13px] font-base">
          {{
            $t(
              'Add a project to plan, schedule and track your work in real time',
            )
          }}
        </small>
      </p>
      <ProjectsPanelAddProjectButton class="text-[12px] px-[46px]" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import { useCurrentUser } from '@teamwork/use';
import AddAProjectImage from '@/assets/images/blankslates/addAProject.svg';
import ProjectsPanelAddProjectButton from './ProjectsPanelAddProjectButton.vue';

export default {
  name: 'ProjectsPanelInformationalMessage',
  components: {
    AddAProjectImage,
    ProjectsPanelAddProjectButton,
  },
  setup() {
    const user = useCurrentUser();

    const canAddProjectsPermission = computed(() => {
      return user.value.permissions.canAddProjects || user.value.administrator;
    });

    return {
      canAddProjectsPermission,
    };
  },
};
</script>
