export const inputSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};
export const defaultInputSize = inputSizes.md;

export const inputTypes = {
  text: 'text',
  number: 'number',
  email: 'email',
  password: 'password',
  tel: 'tel',
  url: 'url',
};
export const defaultInputType = inputTypes.text;

export const inputVariants = {
  outline: 'outline',
  filled: 'filled',
};
export const defaultInputVariant = inputVariants.outline;
