<template>
  <Date
    class="custom-field-date"
    no-arrow
    :colored="false"
    :show-relative="false"
    :date="momentDate"
    :quick-select="quickSelect"
    :disabled="disabled"
    :placeholder="placeholder"
    @change="onChange"
    :from-task-details="fromTaskDetails"
  />
</template>

<script>
import Date from '@widgets/TableCellWidgets/Date';
import { ensureMoment } from '@/utils/helpers/date';

export default {
  name: 'CustomDate',
  display: 'CustomDate',
  components: {
    Date,
  },
  props: {
    value: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    quickSelect: { type: Array, default: () => [] },
    fromTaskDetails: { type: Boolean, default: false },
  },
  computed: {
    momentDate() {
      return this.value ? ensureMoment(this.value, 'YYYYMMDD') : null;
    },
  },
  methods: {
    onChange(date) {
      this.$emit('change', date);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-field-date {
  &.tasks-table {
    height: 48px;
  }
}
</style>
