var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-avatar",class:[
    {
      'w-avatar--loaded': _vm.loaded,
    } ],style:({
    width: (_vm.size + "px"),
    height: (_vm.size + "px"),
    'background-image': 'url(//cdn.teamwork.com/images/noPhoto2.png)',
  })},[_c('img',{directives:[{name:"img-load-fail",rawName:"v-img-load-fail",value:('userAvatar'),expression:"'userAvatar'"}],ref:"avatar",attrs:{"src":_vm.src,"alt":_vm.title,"loading":"lazy"}})])}
var staticRenderFns = []

export { render, staticRenderFns }