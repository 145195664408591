<template>
  <li
    class="
      w-full
      h-[60px]
      mb-2
      outline-none
      pointer-events-none
      flex
      items-center
      bg-transparent
    "
  >
    <div class="w-full h-full flex items-center p-3 pr-6">
      <!-- Icon -->
      <div
        class="mr-3 flex-none w-[36px] h-[36px] skeleton rounded-full"
        v-if="avatar"
      />
      <!-- Text -->
      <div class="flex flex-1 flex-column">
        <!-- Text - Line 1 -->
        <div
          class="flex items-center w-full h-[18px] mb-1"
          v-if="line1Left || line1Right"
        >
          <!-- Text - Line 1 - Left -->
          <div class="flex-1 h-full" v-if="line1Left">
            <div class="skeleton w-[160px] h-full"></div>
          </div>
          <!-- Text - Line 1 - Right -->
          <div class="flex-none h-full" v-if="line1Right">
            <div class="skeleton w-[80px] h-full"></div>
          </div>
        </div>
        <!-- Text - Line 2 -->
        <div class="flex items-center w-full h-[20px]" v-if="line2">
          <div class="skeleton w-3/4 h-full"></div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'SkeletonListItem',
  props: {
    avatar: {
      type: Boolean,
      required: false,
      default: true,
    },
    line1Left: {
      type: Boolean,
      required: false,
      default: true,
    },
    line1Right: {
      type: Boolean,
      required: false,
      default: true,
    },
    line2: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped></style>
