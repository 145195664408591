<template>
  <li role="none" class="h-[40px] flex select-none rounded-full">
    <button
      tabindex="-1"
      role="menuitem"
      @click="onClick"
      @mouseenter="onMouseHover('enter')"
      @mouseleave="onMouseHover('leave')"
      class="
        flex
        gap-2
        justify-between
        items-center
        py-0
        px-4
        m-0
        border-0
        bg-transparent
        w-full
        rounded-full
      "
      :class="[
        ...(active
          ? [
              'bg-palette-indigo-10',
              'hover:bg-palette-indigo-10',
              'focus:bg-palette-indigo-10',
              'text-palette-indigo-40',
              'hover:text-palette-indigo-40',
            ]
          : [
              'bg-transparent',
              'hover:bg-palette-neutral-10',
              'focus:bg-palette-neutral-10',
              'text-text',
              'hover:text-text',
            ]),
      ]"
    >
      <span class="flex grow items-center gap-4 min-w-0">
        <span
          v-if="$slots.icon"
          class="
            flex
            items-center
            justify-center
            w-[20px]
            h-[20px]
            flex-grow-0 flex-shrink-0
            text-text-disabled
            fill-current
          "
          :class="[...(active ? ['text-primary'] : ['text-text-disabled'])]"
        >
          <slot name="icon"></slot>
        </span>
        <span
          class="
            block
            text-current
            whitespace-nowrap
            overflow-hidden
            text-ellipsis text-left
          "
        >
          <slot name="title"></slot>
          <div v-if="$slots.subtitle" class="text-xs text-[#888] text-left">
            <slot name="subtitle"></slot>
          </div>
        </span>
      </span>
      <span class="flex shrink-0 items-center gap-2">
        <span
          v-if="this.$props.shortcut"
          class="
            flex flex-none
            border-2 border-[#E7EBF1] border-solid
            text-[10px]
            rounded-sm
            w-5
            h-5
            font-bold
            text-text-disabled
            leading-none
            items-center
            justify-center
          "
        >
          {{ shortcut }}
        </span>
        <span
          v-if="this.$props.badge"
          class="
            text-[10px]
            font-bold
            text-black
            bg-[#FF61C7]
            rounded-full
            h-[20px]
            inline-flex
            justify-center
            items-center
            px-1.5
          "
        >
          {{ badge }}
        </span>
      </span>
      <div
        v-if="$slots.additionalIcon"
        class="flex items-center ml-auto mr-0 flex-none"
      >
        <slot name="additional-icon"></slot>
      </div>
    </button>
  </li>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    shortcut: {
      type: String,
      required: false,
      default: null,
    },
    badge: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const onMouseHover = (event, state) => {
      emit('hover', event, state);
    };

    const onClick = (event) => {
      emit('click', event);
    };

    return {
      onClick,
      onMouseHover,
    };
  },
};
</script>
