module.exports = {
  '/browserCheck.coffee': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2018-02-06T11:14:15.134Z',
  },
  'widget-creditcard-notification': {
    notes: [
      {
        note: 'WIP',
        user: 'michaelgallaghertw',
        date: '2018-05-03T11:15:05.654Z',
      },
    ],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2018-05-03T11:15:02.817Z',
  },
  'widget-easy-markdown-editor': {
    notes: [
      {
        note: 'temp MarkdownEditor wrapper component',
        user: 'shanepm',
        date: '2020-07-1013:07:00.000Z'
      },
    ],
    migrated: false,
    user: 'shanepm',
    date: '2020-07-1013:07:00.000Z'
  },
  '/helpers/dataLoader.coffee': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2018-05-15T11:36:02.777Z',
  },
  'widget-toggleSwitch': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-23T14:48:46.472Z',
  },
  'widget-quick-search': {
    notes: [],
    migrated: true,
    user: 'michaelgallaghertw',
    date: '2020-03-23T11:12:23.305Z',
  },
  'header-icons': {
    notes: [],
    migrated: true,
    user: 'michaelgallaghertw',
    date: '2020-03-23T11:12:56.465Z',
  },
  'widget-tipped-switch-project': {
    notes: [],
    migrated: true,
    user: 'michaelgallaghertw',
    date: '2020-03-23T11:15:32.678Z',
  },
  'widget-projects-dropdown': {
    notes: [
      {
        note: 'Component deprecated in favour of widget-projects-dropdown-new',
        user: 'TJ Fogarty',
        date: '2020-03-23T11:16:10.019Z',
      },
    ],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-23T11:16:10.019Z',
  },
  'widget-tipped-tasks': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-23T11:16:44.798Z',
  },
  'list-state-loading': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-23T11:17:13.684Z',
  },
  'widget-privacy-icon': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-23T11:30:24.431Z',
  },
  'widget-text-input': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-23T16:29:18.938Z',
  },
  '/models/projectModel.coffee': {
    notes: [
      {
        note: 'only required data logic has been migrated',
        user: 'michaelgallaghertw',
        date: '2018-08-28T19:58:13.414Z',
      },
    ],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2018-08-28T19:57:58.348Z',
  },
  '/models/loggedInUserModel.coffee': {
    notes: [
      {
        note: 'only necessary data logic migrated',
        user: 'michaelgallaghertw',
        date: '2012-08-28T20:02:02.645Z',
      },
    ],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-01-04T20:01:43.643Z',
  },
  '/models/quickSearchModel.coffee': {
    notes: [],
    migrated: true,
    user: 'michaelgallaghertw',
    date: '2020-03-23T19:41:37.714Z',
  },
  'modal-confirm': {
    notes: [],
    migrated: true,
    user: 'samternent',
    date: '2019-07-12T21:21:08.422Z',
  },
  '/helpers/notifications.coffee': {
    notes: [
      {
        note: 'Migrated the getEventTitle method',
        user: 'TJ Fogarty',
        date: '2020-03-09T14:06:15.132Z',
      },
    ],
    migrated: false,
    user: 'TJ Fogarty',
    date: '2020-03-09T14:06:15.132Z',
  },
  'widget-tags-special': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-24T12:52:07.012Z',
  },
  'widget-tags-special-hubspot': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-24T12:52:16.058Z',
  },
  'popup-HubSpot': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-24T12:52:25.455Z',
  },
  'modal-hubspot-addLink': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-03-24T12:52:38.033Z',
  },
  'widget-projects-dropdown-new': {
    notes: [
      {
        note: 'still exists in tko in sub pages',
        user: 'TJ Fogarty',
        date: '2019-12-16T10:25:16.539Z',
      },
    ],
    migrated: false,
    user: 'TJ Fogarty',
    date: '2020-03-23T10:25:16.539Z',
  },
  'ui-menuActions': {
    notes: [],
    migrated: false,
    user: 'TJ Fogarty',
    date: '2020-03-23T10:27:01.283Z',
  },
  'widget-tipped-company': {
    notes: [],
    migrated: true,
    user: 'TJ Fogarty',
    date: '2020-03-23T10:35:53.263Z',
  },
  'widget-header-trial-actions': {
    notes: [],
    migrated: true,
    user: 'TJ Fogarty',
    date: '2020-03-23T10:36:41.172Z',
  },
  'widget-onboarding-flow': {
    notes: [],
    migrated: true,
    user: 'TJ Fogarty',
    date: '2020-03-23T09:41:45.220Z',
  },
  'modal-trialInviteUsers': {
    notes: [],
    migrated: true,
    user: 'Pierre-Louis Renaudin',
    date: '2020-02-21T14:13:18.210Z',
  },
  '/helpers/checkout.coffee': {
    notes: [],
    migrated: false,
    user: 'Pierre-Louis Renaudin',
    date: '2020-02-24T10:53:52.628Z',
  },
  'section-quick-view-gantt-settings': {
    notes: [],
    migrated: true,
    user: 'Petar Stoyanov',
    date: '2020-04-07T14:33:13Z',
  },
  '/bindings/select2.coffee': {
    notes: [
      {
        note: 'SelectMulti or Select component',
        user: 'michaelgallaghertw',
        date: '2020-05-12T15:35:28.008Z',
      },
    ],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-05-12T15:35:18.384Z',
  },
  '/bindings/loader.coffee': {
    notes: [
      {
        note: 'Button component',
        user: 'michaelgallaghertw',
        date: '2020-05-12T15:35:50.975Z',
      },
    ],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-05-12T15:35:45.059Z',
  },
  '/helpers/api.coffee': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-05-13T21:00:37.249Z',
  },
  'list-state-blank': {
    notes: [],
    migrated: false,
    user: 'Petar Stoyanov',
    date: '2020-05-18T15:31:14+01:00',
  },
  'list-state-error': {
    notes: [],
    migrated: false,
    user: 'Petar Stoyanov',
    date: '2020-05-21T16:56:54+01:00',
  },
  'list-state-loading-new': {
    notes: [],
    migrated: false,
    user: 'Petar Stoyanov',
    date: '2020-05-27T14:05:47+01:00',
  },
  'widget-loadMore': {
    notes: [],
    migrated: false,
    user: 'michaelgallaghertw',
    date: '2020-05-27T20:49:32.699Z',
  },
  'widget-color-picker': {
    notes: [],
    migrated: false,
    user: 'Petar Stoyanov',
    date: '2020-06-04T10:27:23+01:00',
  },
  '/bindings/slider.coffee': {
    notes: [
      {
        note: 'Slider component',
        user: 'Jordan Butler',
        date: '2020-06-05T08:21:40Z',
      },
    ],
    migrated: false,
    user: 'Jordan Butler',
    date: '2020-06-05T08:21:40Z',
  },
};
