export default function handleXHRError(error) {
  if (!error?.isAxiosError) {
    return false;
  }

  if (error?.response?.status === 401) {
    if (
      !window.app.isShowingLoginModal() &&
      !(window.app.currentRoute && window.app.currentRoute().page === 'login')
    ) {
      window.app.showLoginModal();
    }

    return true;
  }

  return false;
}
