var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-picker"},[_c('v-popover',{attrs:{"placement":"bottom-end","boundaries-element":"document.body","popover-arrow-class":"hidden","disabled":_vm.disabled,"open":_vm.isOpen},on:{"hide":_vm.hide,"show":_vm.show,"update:open":function($event){_vm.isOpen=$event}}},[_vm._t("default",function(){return [_c('button',{class:{
          'add-tag-button': true,
          'is-active': _vm.isOpen || _vm.selectedTags.length > 0,
          'dropdown-open': _vm.isOpen,
          'is-button': _vm.isFilter,
          'is-limit': _vm.selectedTags.length >= _vm.limit,
        }},[(_vm.isFilter)?[_c('CommonIcon',{staticClass:"font-size--small mr--medium",attrs:{"id":"tag","color":"#0B0E1F"}}),_vm._v("\n          "+_vm._s(_vm.selectedTags.length === 0
              ? _vm.$t('Tags')
              : _vm.selectedTags.length === 1
              ? _vm.$t('[0] Tag', 1)
              : _vm.$t('[0] Tags', _vm.selectedTags.length))+"\n          "),_c('CommonIcon',{staticClass:"tag-picker__chevron",attrs:{"id":"chevron-down","color":"#0B0E1F"}})]:_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content:
              _vm.selectedTags.length >= _vm.limit
                ? _vm.$t('Only [0] tags are allowed', _vm.limit)
                : _vm.$t('Add a tag'),
            placement: 'top',
            boundariesElement: 'document.body',
          }),expression:"{\n            content:\n              selectedTags.length >= limit\n                ? $t('Only [0] tags are allowed', limit)\n                : $t('Add a tag'),\n            placement: 'top',\n            boundariesElement: 'document.body',\n          }"}],staticClass:"tag-picker__icon-button"},[_c('ProjectsIcon',{staticClass:"tag-picker__plus",attrs:{"id":"plus","color":"#fff"}})],1)],2)]}),_vm._v(" "),_c('template',{slot:"popover"},[_c('InlineSearch',{ref:"inputElement",staticClass:"tag-search border--none",attrs:{"value":_vm.highlightedTag ? _vm.highlightedTag.name : _vm.searchTerm,"placeholder":_vm.mode === 'view' ? _vm.$t('Search') : _vm.$t('Create a tag'),"show-icon":false},on:{"input":function($event){_vm.searchTerm = $event},"on-keydown":_vm.highlightTag}}),_vm._v(" "),(_vm.mode === 'view')?_c('div',{staticClass:"tag-lists"},[(_vm.selectedTags.length)?[_c('span',{staticClass:"title ml--medium"},[_vm._v("\n            "+_vm._s(_vm.$t('Selected:'))+"\n          ")]),_vm._v(" "),_c('ItemList',{attrs:{"items-are-selected":true},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})]:_vm._e(),_vm._v(" "),(_vm.selectedTags.length >= _vm.limit)?[_c('div',{staticClass:"tag-picker__limit-msg"},[_vm._v("\n            "+_vm._s(_vm.$t('Only [0] tags are allowed', _vm.limit))+"\n          ")])]:[_vm._l((_vm.availableTagsByProject),function(ref){
          var projectId = ref.projectId;
          var projectName = ref.projectName;
          var tags = ref.tags;
return _c('div',{key:projectId},[_c('span',{staticClass:"title ml--medium"},[_vm._v(_vm._s(projectName)+":")]),_vm._v(" "),_c('ItemList',{attrs:{"value":tags,"items-are-selected":false,"read-only":_vm.disabled},on:{"select":_vm.selectTag}})],1)}),_vm._v(" "),(_vm.inSync && _vm.debouncedSearchTerm && _vm.availableTags.length === 0)?[_c('div',{staticClass:"title m--medium emptystate"},[_c('div',{staticClass:"title emptystate__msg"},[_vm._v("\n                "+_vm._s(_vm.isSearchedTagAlreadySelected
                    ? _vm.$t('This tag is already selected')
                    : _vm.$t('No tags match your query'))+"\n              ")])])]:_vm._e(),_vm._v(" "),(!_vm.inSync)?[_c('div',{staticClass:"title m--medium"},[_vm._v(_vm._s(_vm.$t('Loading tags')))])]:_vm._e()]],2):[(_vm.tagsTipEnabled)?[_c('div',{staticClass:"tag-picker__no-tags"},[_c('NoTags'),_vm._v(" "),_c('div',{staticClass:"tag-picker__no-tags__title"},[_vm._v("\n              "+_vm._s(_vm.$t('No Tags'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"tag-picker__no-tags__description"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'Tags are a way to mark items so that you can use a filter'
                ))+"\n            ")]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){_vm.tagsTipEnabled = false}}},[_vm._v("\n              "+_vm._s(_vm.$t('Create a Tag'))+"\n            ")])],1)]:_c('div',{staticClass:"create-tag-form"},[_c('span',{staticClass:"title ml--medium"},[_vm._v("\n            "+_vm._s(_vm.$t('Tag color'))+"\n\n            "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('Choose a color for your tag'),
                classes: 'tooltip w-tooltip w-toggle-switch__tooltip',
              }),expression:"{\n                content: $t('Choose a color for your tag'),\n                classes: 'tooltip w-tooltip w-toggle-switch__tooltip',\n              }"}]},[_c('CommonIcon',{staticClass:"w-toggle-switch__info-icon",attrs:{"id":"info-circle","color":"#838F9C"}})],1)]),_vm._v(" "),_c('div',{staticClass:"tag-picker__new-tag"},[_c('div',{staticClass:"tag-picker__tag-indicators"},_vm._l((_vm.tagColors.slice(
                  0,
                  Math.ceil(_vm.tagColors.length / 2)
                )),function(color,index){return _c('div',{key:index,staticClass:"tag-picker__tag-indicators__selector"},[_c('button',{class:[
                    {
                      'tag-picker__indicator--btn-selected':
                        color === _vm.selectedColor,
                    },
                    'tag-picker__indicator',
                    'tag-picker__indicator--btn' ],style:({ color: color, background: color }),on:{"click":function($event){_vm.selectedColor = color}}})])}),0),_vm._v(" "),_c('div',{staticClass:"tag-picker__tag-indicators"},_vm._l((_vm.tagColors.slice(
                  -Math.ceil(_vm.tagColors.length / 2)
                )),function(color,index){return _c('div',{key:index,staticClass:"tag-picker__tag-indicators__selector"},[_c('button',{class:[
                    {
                      'tag-picker__indicator--btn-selected':
                        color === _vm.selectedColor,
                    },
                    'tag-picker__indicator',
                    'tag-picker__indicator--btn' ],style:({ color: color, background: color }),on:{"click":function($event){_vm.selectedColor = color}}})])}),0)]),_vm._v(" "),(_vm.projectIdForNewTags > 0)?_c('div',{staticClass:"tag-picker__project-toggle"},[_c('ToggleSwitch',{attrs:{"tip":_vm.$t('Show only in this project'),"reversed-order":true,"show-title":false,"label":_vm.$t('Project Specific')},model:{value:(_vm.projectSpecificTag),callback:function ($$v) {_vm.projectSpecificTag=$$v},expression:"projectSpecificTag"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tag-picker__new-tag-button"},[_c('Button',{staticClass:"btn btn-primary",attrs:{"loading":_vm.isCreating},on:{"click":_vm.createNewTag}},[_vm._v("\n              "+_vm._s(_vm.isCreating ? _vm.$t('Creating Tag') : _vm.$t('Create Tag'))+"\n            ")])],1)])]],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }