/* eslint-disable no-param-reassign,consistent-return */
import { passive } from '@/utils/helpers/promise';
import { remove } from '@/utils/helpers/array';

const undos = new WeakMap();
const expls = ['Whoops!', 'Yikes!', 'Blimey!', 'Oh no!', 'Oh Dear!'];

const titleOrObject = (flash) =>
  typeof flash === 'string' ? { title: flash } : flash;

export default {
  namespaced: true,
  state: {
    flashes: [],
  },
  mutations: {
    show({ flashes }, flash) {
      flashes.push(flash);
    },
    close({ flashes }, flash) {
      remove(flashes, flash);
    },
  },
  actions: {
    info: ({ dispatch }, flash) =>
      dispatch('show', { ...titleOrObject(flash), type: 'info' }),
    warn: ({ dispatch }, flash) =>
      dispatch('show', { ...titleOrObject(flash), type: 'warn' }),
    success: ({ dispatch }, flash) =>
      dispatch('show', { ...titleOrObject(flash), type: 'success' }),
    error: ({ dispatch }, flash) =>
      dispatch('show', { ...titleOrObject(flash), type: 'error' }),
    errorExclaim: ({ dispatch }, flash = {}) => {
      const title = expls[Math.floor(expls.length * Math.random())];
      dispatch('show', { ...flash, type: 'error', title });
    },

    show({ commit }, { type, title, msg, opts = {} }) {
      // In future when we won't use toastr, the timeout will be
      // handled within the store.
      const flash = { type, title, msg, opts };
      commit('show', flash);
      // Using weakmap and passive promise for undo option
      if (opts.undo) {
        const undo = passive();
        undos.set(flash, undo);
        return undo;
      }
    },
    undo({ dispatch }, flash) {
      dispatch('close', flash);
      if (flash.opts.undo) {
        return undos.get(flash).resolve();
      }
    },
    close({ state: { flashes }, commit }, flash) {
      if (!flashes.includes(flash)) {
        throw new Error(`Flash closed when it is not open. "${flash.title}"`);
      }
      commit('close', flash);
    },
  },
};
