import sharedFilter from './shared-filter';

export default ({ type, payload }, state) => {
  const app = window.app;

  if (!app) {
    return;
  }

  if (['project/activate', 'project/archive'].includes(type)) {
    const id = payload;
    app.ko.postbox.publish('projects-update-stats', { vue: true });
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'project',
      actionType: app.consts.ACTIONTYPE.UPDATED,
      subType: type.includes('archive') ? 'archived' : 'active',
      itemId: id,
    });
  } else if (type === 'project/deleted') {
    const id = payload;
    app.ko.postbox.publish('projects-updated', { vue: true });
    app.ko.postbox.publish('allTWEvents', {
      vue: true,
      eventTime: new Date(),
      itemType: 'project',
      actionType: app.consts.ACTIONTYPE.DELETED,
      itemId: id,
    });
  } else if (['project/completed', 'project/uncompleted'].includes(type)) {
    const id = payload;
    if (id === state.project.currentProjectId) {
      app.ko.postbox.publish('allTWEvents', {
        vue: true,
        eventTime: new Date(),
        itemType: 'project',
        actionType: app.consts.ACTIONTYPE.UPDATED,
        itemId: id,
        subType: type.includes('completed') ? 'completed' : 'uncompleted',
      });
    } else {
      app.ko.postbox.publish('projects-updated', { vue: true });
    }
  } else if (type === 'preferences/change') {
    const { scope, prefs } = payload;
    if (scope === 'user') {
      Object.keys(prefs).forEach((pref) => {
        if (app.loggedInUser.preferences[pref]) {
          app.loggedInUser.preferences[pref](prefs[pref]);
        }
      });
    }
    if (scope === 'user' && prefs.teamsEnabled !== undefined) {
      if (prefs.teamsEnabled) {
        // eslint-disable-next-line no-console
        console.info('Reloading to enable teams');
      } else {
        // eslint-disable-next-line no-console
        console.info('Reloading to disable teams');
      }

      document.location.reload();
    }
  } else if (type === 'modals/close') {
    app.ko.postbox.publish('modal-close', { name: payload });
  } else if (type === 'filter/currentFilterKey') {
    sharedFilter(payload);
  }
  // Fire TKO postbox event when a Hybrid modal is opened.
  if (type === 'modals/open') {
    app.ko.postbox.publish('modal-show', { name: payload });
  }
};
