<template>
  <div class="subtask-new">
    <button />
    <input
      type="text"
      ref="input"
      v-model="value"
      :placeholder="$t('Add Subtask')"
      @keyup.enter="onSubmit"
      @keyup.escape="onCancel"
      @keydown.stop="onValueChange"
      @blur="onBlur"
    />
  </div>
</template>

<script>
export default {
  name: 'SubtasksNew',
  data() {
    return {
      value: '',
    };
  },
  methods: {
    onSave() {
      const value = this.value.trim().length ? this.value.trim() : null;

      if (this.$refs.input) {
        this.value = '';
        // in case the user actually added a new subtask and didn't cancelled
        // we're refocusing the input again
        if (value) {
          this.$refs.input.focus();
        }
      }
      this.$emit('change', value);
    },
    onValueChange(event) {
      // using this to prevent other hotkeys from triggering
      event.stopPropagation();
    },
    onSubmit() {
      if (this.$refs.input) {
        this.$refs.input.blur();
      }
    },
    onCancel(event) {
      event.stopPropagation();
      if (this.$refs.input) {
        this.value = '';
        this.$refs.input.blur();
      }
    },
    onBlur() {
      this.$emit('blur');
      this.onSave();
    },
  },
  mounted() {
    if (this.$refs.input) {
      this.$refs.input.focus();
    }
  },
};
</script>

<style lang="scss" scoped>
.subtask-new {
  border: 1px solid #f5f7fa;
  padding: 14px 12px;
  padding-left: 32px;
  font-size: 12px;
  display: flex;
  align-items: center;

  button {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #c5cada;
    position: relative;
    background-color: #fff;

    &:after,
    &:before {
      content: '';
      position: absolute;
      background-color: #0b0e1f;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &:before {
      width: 8px;
      height: 1px;
    }

    &:after {
      height: 8px;
      width: 1px;
    }
  }

  input {
    font-size: 14px;
    line-height: 24px;
    color: #0b0e1f;
    border: none;
    flex-grow: 2;
    outline: none;
    margin-left: 8px;

    &::placeholder {
      color: #6d6f80;
    }
  }
}
</style>
