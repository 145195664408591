<template>
  <button
    type="button"
    class="flex items-center gap-2 w-full text-left p-0 border-0 bg-transparent"
    :class="{
      'cursor-pointer': Boolean($listeners.click),
      'cursor-default': !Boolean($listeners.click),
    }"
    @click="$listeners.click && $emit('click')"
  >
    <FAvatar
      v-bind="$props"
      :has-tooltip="false"
      :clickable="Boolean($listeners.click)"
    />
    <div
      class="
        grow
        text-ellipsis
        overflow-hidden
        whitespace-nowrap
        text-default
        text-[color:var(--token-c-avatar-color-initials)]
      "
    >
      {{ name }}
    </div>
  </button>
</template>
<script>
import FAvatar from '../FAvatar/FAvatar.vue';
import { avatarProps } from '../FAvatar/avatarCommon';

export default {
  name: 'FAvatarMenuItem',
  components: {
    FAvatar,
  },
  props: { ...avatarProps },
};
</script>
