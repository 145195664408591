<template>
  <div class="panel flex flex-col text-text bg-[#f0f3f7]">
    <div class="flex items-center px-6 mt-300 mb-400 h-[30px] leading-none">
      <slot name="titlePrepend" v-if="$slots.titlePrepend" />
      <h2
        class="
          block
          flex-1
          text-current text-[16px]
          font-bold
          select-none
          m-0
          truncate
          leading-none
        "
      >
        {{ title }}
      </h2>
      <div class="flex-grow-0 flex-shrink-0 flex flex-items-center">
        <slot name="panel-header-actions" />
        <button
          class="
            flex
            items-center
            justify-center
            rounded-full
            bg-transparent
            border-none
            text-text-secondary
            hover:text-text
            transition-colors
            cursor-pointer
            w-[30px]
            h-[30px]
            p-2
            -mr-2
          "
          title="Close"
          @click="close"
          v-tooltip="{
            content: $t('Close'),
            placement: 'bottom',
            boundariesElement: 'document.body',
            offset: 0,
          }"
        >
          <!-- icon-close -->
          <svg
            class="fill-current"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99001 5.00031L8.98144 2.00888L9.59833 1.39199C9.68934 1.30098 9.68934 1.15311 9.59833 1.0621L8.93827 0.40204C8.84727 0.311037 8.69939 0.311037 8.60839 0.40204L5.00007 4.01036L1.39174 0.401748C1.30074 0.310745 1.15286 0.310745 1.06186 0.401748L0.401504 1.06181C0.310501 1.15281 0.310501 1.30069 0.401504 1.3917L4.01012 5.00031L0.401504 8.60863C0.310501 8.69963 0.310501 8.84751 0.401504 8.93852L1.06157 9.59858C1.15257 9.68958 1.30045 9.68958 1.39145 9.59858L5.00007 5.99026L7.99149 8.98168L8.60839 9.59858C8.69939 9.68958 8.84727 9.68958 8.93827 9.59858L9.59833 8.93852C9.68934 8.84751 9.68934 8.69963 9.59833 8.60863L5.99001 5.00031Z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="flex-1 min-h-0">
      <slot></slot>
    </div>
    <div
      v-if="$slots['footer']"
      class="flex items-center flex-none bg-[#f8f9fc]"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SidebarPanel',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const close = () => {
      emit('close');
    };

    return {
      close,
    };
  },
};
</script>
