<template>
  <div
    class="notifications-loader"
    :class="[hasOverflow && 'has-overflow', type]"
    ref="scrollContainerEl"
    @scroll="onScroll"
  >
    <template v-if="shouldShowError">
      <BlankSlate
        :title="$t('Error')"
        :msg="
          $t('We\'re experiencing some issues retrieving your notifications')
        "
      >
        <template #image>
          <ErrorImage class="blank-slate__image" />
        </template>
      </BlankSlate>
    </template>
    <template v-else>
      <slot
        :notifications="notifications"
        :allLoaded="allLoaded"
        :isLoading="isLoading"
      />
      <transition name="fade">
        <button
          v-show="shouldShowScrollToTopBtn"
          @click="resetScrollPosition"
          class="notifications-loader__scroll-to-top-btn"
        >
          <ProjectsIcon id="chevron-up" />
          <span
            class="notifications-loader__scroll-to-top-btn-text"
            v-if="isWide"
          >
            {{ $t('Jump to latest notifications') }}
          </span>
        </button>
      </transition>
    </template>
  </div>
</template>

<script>
import { computed, nextTick, ref, watch } from 'vue-demi';
import { usePreventLoaderFlash } from '@teamwork/use';
import { useDebounceFn, until, useResizeObserver } from '@vueuse/core';
import ProjectsIcon from '@teamwork/common-icons/dist/v-projects-icon';

import BlankSlate from '@widgets/BlankSlate/BlankSlate';
import ErrorImage from '@/assets/images/blankslates/bell_error.svg';

export default {
  name: 'NotificationLoader',
  components: {
    ProjectsIcon,
    BlankSlate,
    ErrorImage,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(val) {
        return val === 'read' || val === 'unread' || val === 'all';
      },
    },
    loader: {
      type: Object,
      required: true,
    },
    /**
     * Determines whether or not the loader is currently in use (used to delay XHR until necessary)
     */
    active: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const scrollContainerEl = ref(null);
    const scrollContainerElWidth = ref(0);
    const hasOverflow = ref(false);

    const isLoading = computed(() => {
      if (!props.loader) {
        return true;
      }

      return props.loader.state.loading.value;
    });

    const shouldShowError = computed(() => {
      return (
        !!props.loader.state.error.value &&
        props.loader.state.items.value.length <= 0
      );
    });

    const isWide = computed(() => {
      return scrollContainerElWidth.value > 500;
    });

    const { shouldShowLoader } = usePreventLoaderFlash(isLoading);

    const notifications = computed(() => {
      if (!props.loader) {
        return [];
      }

      const items = props.loader.state.items.value;
      const pageSize = props.loader.state.pageSize;

      // Fill the list with empty items/skeletons equal to the configured page size
      const dummy = shouldShowLoader.value
        ? Array(pageSize.value)
            .fill()
            .map((i, idx) => ({
              id: -Math.abs(idx + 1),
              isPlaceholder: true,
            }))
        : [];

      return [...items, ...dummy];
    });

    const allLoaded = computed(() => {
      if (!props.loader) {
        return false;
      }

      return props.loader.state.allLoaded.value;
    });

    const scrollTop = ref(0);
    const shouldShowScrollToTopBtn = computed(() => {
      return scrollTop.value > 200;
    });

    const setScrollTop = useDebounceFn((val) => {
      scrollTop.value = val;
    }, 200);

    function onScroll() {
      const target = scrollContainerEl.value;

      if (!props.loader) {
        return;
      }

      if (!(target instanceof HTMLElement)) {
        return;
      }

      if (!target.offsetParent) {
        return;
      }

      const scrollThreshold = 300;
      setScrollTop(target.scrollTop);

      if (
        target.offsetHeight &&
        target.scrollTop >=
          target.scrollHeight - target.offsetHeight - scrollThreshold
      ) {
        // Remember this position so we can go to it once notifications have loaded in
        // it means we won't be stuck to the bottom and in a constant state of loading
        const lastScrollTop = target.scrollTop;

        props.loader.loadMore();

        // eslint-disable-next-line no-param-reassign
        target.scrollTop = lastScrollTop;
      }
    }

    function resetScrollPosition() {
      if (scrollContainerEl.value) {
        scrollContainerEl.value.scrollTop = 0;
      }
    }

    function initializeLoader() {
      if (!props.loader) {
        return;
      }

      // Reset scroll when loader re-initializes
      watch(
        props.loader.state.initialized,
        async (_initialized) => {
          async function initializationComplete() {
            await until(props.loader.state.loading).toBe(true);
            await until(props.loader.state.loading).toBe(false);
          }

          if (_initialized) {
            return;
          }

          // we need to wait until loading is complete before
          // resetting scroll position
          await initializationComplete();
          resetScrollPosition();

          watch(props.loader.state.items, () => {
            if (!props.active) {
              return;
            }

            nextTick(() => {
              onScroll();
            });
          });
        },
        { immediate: true },
      );

      useResizeObserver(scrollContainerEl.value, (entries) => {
        const entry = entries[0];
        const { width } = entry.contentRect;
        scrollContainerElWidth.value = width;

        hasOverflow.value =
          scrollContainerEl.value.scrollHeight >
          scrollContainerEl.value.clientHeight;
      });

      props.loader.loadMore();
    }

    function activate() {
      initializeLoader();
      resetScrollPosition();
    }

    watch(
      () => [props.active, props.loader],
      ([active, loader]) => {
        if (active) {
          if (!loader) {
            return;
          }

          activate();
        }
      },
      {
        immediate: true,
      },
    );

    return {
      notifications,
      isLoading,
      shouldShowError,
      allLoaded,
      onScroll,
      scrollContainerEl,
      scrollContainerElWidth,
      isWide,
      hasOverflow,
      resetScrollPosition,
      shouldShowScrollToTopBtn,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/mixins/mixins';
@import '~@tko/src/styles/variables/variables';

.notifications-loader {
  @include scrollbar();
  width: 100%;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.notifications-loader__scroll-to-top-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #3f4650;
  display: flex;
  align-items: center;
  background: #fff;
  /* Neutral/10 */
  border: 1px solid $neutral-10;
  /* Elevation/Extra small */
  box-shadow: 0px 0px 12px rgba(11, 14, 31, 0.08);
  border-radius: 60px;
  transition: opacity 0.5s ease-in-out;
  height: 40px;
  text-align: center;
  padding: 13px 16px;
  line-height: 24px;
  font-size: 14px;

  &:hover {
    background: $neutral-10;
  }

  &:active {
    background: $neutral-20;
  }
}

.notifications-loader__scroll-to-top-btn-text {
  margin-left: 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.u-blank-slate {
  margin: 0;
  height: 100%;
  padding-left: 72px;
  padding-right: 72px;

  .blank-slate__image {
    max-height: 96px;
  }
}
</style>
