<template>
  <div>
    <label
      v-if="label || $slots.default"
      :for="id"
      class="text-xs font-semibold mb-2"
    >
      <slot>{{ label }}</slot>
    </label>
    <div class="h-1 relative">
      <input
        class="rounded-full h-1 bg-transparent z-20 w-full absolute"
        type="range"
        :name="name"
        :id="id"
        :step="step"
        :max="max"
        :min="min"
        :required="required"
        :disabled="disabled"
        v-model="inputProgress"
        @change="$emit('change', Number(inputProgress))"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      />
      <span
        v-if="hover"
        class="
          p-2
          rounded
          z-30
          bg-[color:var(--token-c-slider-color-tooltip)]
          text-white
          absolute
          -translate-x-2/4
          bottom-[16px]
        "
        :style="{
          left: `${position}`,
        }"
      >
        {{ inputProgress }}
      </span>
      <span
        class="
          rounded-full
          h-1
          absolute
          bg-[color:var(--token-c-slider-color-progress)]
          top-0
          left-0
          right-0
          z-10
        "
        :class="{
          'bg-[color:var(--token-c-slider-color-progress-disabled)]': disabled,
        }"
        :style="{
          width: `${percent}%`,
        }"
      />
      <span
        class="
          rounded-full
          h-1
          absolute
          bg-[color:var(--token-c-slider-color-track)]
          top-0
          left-0
          right-0
          z-0
        "
        :class="{
          'bg-[color:var(--token-c-slider-color-track-disabled)]': disabled,
        }"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  name: 'FSlider',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: () => `slider-${Math.floor(Math.random() * 1000).toString()}`,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 10,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const inputProgress = ref(props.value || 0);
    const hover = ref(false);

    const percent = computed(
      () => (Number(inputProgress.value) * 100) / props.max,
    );

    const position = computed(() => {
      const delta = 0.12; // https://css-tricks.com/value-bubbles-for-range-inputs/
      return `calc(${percent.value}% + (${6 - percent.value * delta}px))`;
    });

    watch(
      () => props.value,
      (newValue) => {
        // used to update the progress in case of realtime updates
        inputProgress.value = Number(newValue);
      },
    );

    return {
      inputProgress,
      hover,
      percent,
      position,
    };
  },
};
</script>
<style scoped>
/* Track */
input {
  -webkit-appearance: none;
}

input:disabled {
  cursor: not-allowed;
}

/* Knob */
input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  cursor: pointer;
  background: var(--token-c-slider-color-knob);
}

input:disabled::-webkit-slider-thumb {
  background: var(--token-c-slider-color-knob-disabled);
  cursor: not-allowed;
}

input:not(:disabled):hover::-webkit-slider-thumb {
  box-shadow: var(--token-c-slider-color-shadow);
}

input:not(:disabled):focus-within::-webkit-slider-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strong);
}

input:not(:disabled):active::-webkit-slider-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strongest);
}

input::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  cursor: pointer;
  background: var(--token-c-slider-color-knob);
}

input:disabled::-moz-range-thumb {
  background: var(--token-c-slider-color-knob-disabled);
  cursor: not-allowed;
}

input:not(:disabled):hover::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow);
}

input:not(:disabled):focus-within::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strong);
}

input:not(:disabled):active::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strongest);
}

input::-ms-thumb {
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  cursor: pointer;
  background: var(--token-c-slider-color-knob);
}

input:disabled::-ms-thumb {
  background: var(--token-c-slider-color-knob-disabled);
  cursor: not-allowed;
}

input:not(:disabled):hover::-ms-thumb {
  box-shadow: var(--token-c-slider-color-shadow);
}

input:not(:disabled):focus-within::-ms-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strong);
}

input:not(:disabled):active::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strongest);
}
</style>
