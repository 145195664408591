<template>
  <RoundButton
    class="task-item-options"
    icon="ellipsis-v"
    v-tooltip="{
      content: tooltip,
      classes: 'tooltip w-tooltip',
    }"
  />
</template>
<script>
import { RoundButton } from '@widgets/TaskDetailWidgets';

export default {
  name: 'TaskItemOptions',
  props: {
    tooltip: { type: String, default: '' },
  },
  components: {
    RoundButton,
  },
};
</script>
<style lang="scss">
.task-item-options {
  background: white !important;
}
</style>
