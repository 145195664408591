<template>
  <div
    class="
      py-[64px]
      text-center
      h-full
      flex flex-col
      items-center
      justify-center
      min-h-[214px]
    "
  >
    <div class="flex-shrink-0">
      <StarredProjectsImage class="mt-300 flex-shrink-0" />
    </div>
    <p
      class="
        text-text text-[14px]
        leading-default
        px-200
        mb-300
        pt-4
        mx-auto
        w-full
        flex flex-col
        items-center
      "
    >
      <span class="font-semibold block mb-2 w-44">
        {{ $t('Starred Projects') }}
      </span>

      <small class="w-56 text-[#646d7d] text-[13px] font-base">
        {{
          $t(
            'Star any project so that you can easily access it later from this space',
          )
        }}
      </small>
    </p>
  </div>
</template>

<script>
import StarredProjectsImage from '@/assets/images/blankslates/starredProjects.svg';

export default {
  components: {
    StarredProjectsImage,
  },

  setup(props, { emit }) {
    const handleClick = () => emit('click');

    return {
      handleClick,
    };
  },
};
</script>
