import moment from 'moment';

// NB - mapping should consider incoming data immutable!

// tasks/5?getPermissions=1
const idFromLink = (url) =>
  url &&
  url.slice(
    url.lastIndexOf('/') + 1,
    url.includes('?') ? url.indexOf('?') : undefined,
  );

const typeMapping = {
  statusupdate: (mapped) => ({ ...mapped, itemLink: 'people/statuses' }),
  task: (mapped) => ({
    ...mapped,
    parentItemId: parseInt(
      mapped.parentItemId || idFromLink(mapped.extraLink),
      10,
    ),
    extraInfo: {
      ...mapped.extraInfo,
      taskListId:
        mapped.extraInfo.taskListId &&
        parseInt(mapped.extraInfo.taskListId, 10),
      parentTaskId:
        mapped.extraInfo.parentTaskId &&
        parseInt(mapped.extraInfo.parentTaskId, 10),
    },
  }),
  task_comment: (mapped) => ({
    ...mapped,
    extraInfo: {
      ...mapped.extraInfo,
      objectType: 'task',
      objectId: parseInt(
        idFromLink(mapped.extraLink) || idFromLink(mapped.itemLink),
        10,
      ),
      taskListId: parseInt(mapped.extraInfo.taskListId, 10),
    },
  }),
  // taskcard becomes card
  taskcard: (mapped) => ({ ...mapped, itemType: 'card' }),
};

export default (eventInfo) => {
  let mapped = {
    ...eventInfo,
    itemType: eventInfo.itemType.toLowerCase(),
    twimEventId: eventInfo.twimEventId
      ? parseInt(eventInfo.twimEventId, 10)
      : null,
    // eslint-disable-next-line no-eval
    eventTime: eval(eventInfo.eventTime),
    eventTimeUTC: moment.utc().format(),
    extraInfo: eventInfo.extraInfo || {},
    lockdownId: eventInfo.lockdownId || '',
    // ugly mapping, but better not to break the existing interface
    isPrivate: !!eventInfo.IsPrivate,
  };

  // Additional mapping rules based on itemType
  if (typeMapping[mapped.itemType]) {
    mapped = typeMapping[mapped.itemType](mapped);
  }

  if (mapped.extraInfo.data) {
    try {
      mapped.extraInfo.data = JSON.parse(mapped.extraInfo.data);
    } catch (e) {
      // not valid json, ignore
    }
  }
  return mapped;
};
