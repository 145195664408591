<template>
  <PopoverMenuActionItem @click="openUpdateStatus" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="14"
        viewBox="0 0 18 14"
      >
        <path
          d="M16.87.426h-.463a.463.463 0 0 0-.463.463v.374L2.034 4.49a.457.457 0 0 0-.441-.36H1.13a.463.463 0 0 0-.463.464v4.63c0 .255.207.462.463.462h.463a.457.457 0 0 0 .442-.359l3.328.772a2.563 2.563 0 0 0-.066.513 2.778 2.778 0 0 0 2.777 2.778 2.763 2.763 0 0 0 2.665-2.045l5.205 1.208v.374c0 .256.208.463.463.463h.463a.463.463 0 0 0 .463-.463V.889a.463.463 0 0 0-.463-.463ZM8.074 12a1.39 1.39 0 0 1-1.389-1.389c0-.07.026-.131.036-.198l2.67.619c-.18.56-.699.968-1.317.968ZM2.056 7.906V5.909l13.888-3.22v8.437L2.056 7.906Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Update my status') }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';

export default {
  components: {
    PopoverMenuActionItem,
  },
  methods: {
    openUpdateStatus() {
      this.$store.dispatch('modals/tko/open', { id: 'addOrEditStatus' });
    },
  },
};
</script>
