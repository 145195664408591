<template>
  <div
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="table-cell-time"
  >
    <v-popover
      trigger="click"
      placement="bottom"
      offset="4"
      :open.sync="isOpen"
      :disabled="!task.userPermissions.canEdit"
      @apply-show="focusInput"
      popover-arrow-class="hidden"
      :popper-options="popperOptions"
    >
      <button
        v-if="totalMinutes > 0"
        v-tooltip="{ content: tooltip, html: true }"
        class="btn btn-reset content-button"
      >
        {{ formattedTime }}
        <div class="table-cell-time__edit-icon">
          <SupersetIcon
            v-if="task.userPermissions.canEdit"
            color="#555555"
            class="edit-icon"
            id="edit"
          />
        </div>
      </button>
      <div v-else class="empty-button">
        <button
          v-if="isHovering"
          class="table-cell-time__placeholder-text btn-reset"
        >
          {{ $t('Add estimate') }}
        </button>
        <CommonIcon
          v-else
          class="empty-state-icon"
          id="minus-solid"
          color="#c5cee0"
        />
      </div>
      <template slot="popover">
        <div @keyup.enter="$refs.btnSubmit.click()" class="estimate-popover">
          <h3 class="estimate-popover__header">
            {{ $t('Estimate Time') }}
            <CommonIcon
              class="ml--small"
              id="info-circle"
              color="#6d6f80"
              v-tooltip="{
                content: $t('Add estimated time to a task'),
                placement: 'top',
              }"
            />
          </h3>
          <div class="estimate-popover__body">
            <div class="mr--large">
              <input
                ref="inputEl"
                type="text"
                @keypress="onlyNumbers($event)"
                v-model="hours"
                class="estimate-popover__time-input"
                placeholder="0"
                maxlength="4"
              />
              h
            </div>
            <div>
              <input
                type="text"
                @keypress="onlyNumbers($event)"
                v-model="minutes"
                class="estimate-popover__time-input"
                placeholder="0"
                maxlength="2"
              />
              m
            </div>
          </div>
          <button
            v-close-popover
            @click="onChangeEstimateTime"
            ref="btnSubmit"
            class="estimate-popover__submit btn btn-primary width--100"
          >
            {{ $t('Add estimate') }}
          </button>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import SupersetIcon from '@teamwork/common-icons/dist/v-icon';
import { formatMinutes } from '@/utils/helpers/time';

// @vue/component
export default {
  name: 'EstimateTime',
  display: 'EstimateTime',
  components: {
    CommonIcon,
    SupersetIcon,
  },
  data() {
    return {
      hours: null,
      minutes: null,
      isHovering: false,
      isOpen: false,
    };
  },
  props: {
    task: { type: Object, required: true, default: null },
    tooltip: { type: String, default: undefined },
  },
  computed: {
    formattedTime() {
      return formatMinutes(this.totalMinutes);
    },
    totalMinutes() {
      return this.task.estimateMinutes;
    },
    popperOptions: () => ({
      modifiers: {
        hide: { enabled: true },
      },
    }),
  },
  methods: {
    convertTime(totalMin) {
      const hours = totalMin / 60;
      this.hours = Math.floor(hours) || null;

      const minutes = (hours - this.hours) * 60;
      this.minutes = Math.round(minutes) || null;
    },
    onChangeEstimateTime() {
      const hours = parseInt(this.hours, 10) || 0;
      const minutes = parseInt(this.minutes, 10) || 0;
      this.$emit('change', hours * 60 + minutes);
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    focusInput() {
      // This has to be used in order to wait the popover show to focus the input,
      // otherwise it won't focus.
      setTimeout(() => {
        this.$refs.inputEl.focus();
      }, 100);
    },
  },
  watch: {
    isOpen(newValue) {
      this.$emit('toggle', newValue);
    },
  },
  mounted() {
    this.convertTime(this.totalMinutes);
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

$font-size--base: 14px;
$width--time-input: 49px;
$height--time-input: 32px;

.table-cell-time {
  padding: $padding--medium;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .table-cell-time__edit-icon {
      display: flex;
    }
  }

  &__placeholder-text {
    font-size: $font-size--base;
    color: $indigo-30;
    margin-left: -12px;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: $border-radius--default;
  }

  &__edit-icon {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 45px;
    height: 100%;
    padding-right: 15px;
    background: linear-gradient(90deg, transparent 0%, #f5f7fa 45%);
    position: absolute;
    right: 0;
    top: 0;
  }

  .btn {
    font-size: 13px;
    width: 100%;
    position: relative;

    &:hover {
      color: inherit;
    }
  }
}

.estimate-popover {
  padding: 24px 16px 16px;
  font-weight: 600;

  &__header {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: $font-size--medium;
    margin-bottom: $margin--large;
    color: $color--text-tinted-dark;
  }

  &__body {
    display: flex;
    align-items: center;
    margin-bottom: $margin--large;
  }

  &__time-input {
    width: $width--time-input;
    height: $height--time-input;
    font-size: $font-size--base;
    background-color: $neutral-20;
    margin-right: $margin--tiny;
    padding: 0 $padding--small;
    text-align: right;
    font-weight: normal;
    border-radius: 4px;
    border: none;

    &:focus {
      outline: none;
      border: 1px solid $indigo-30;
    }
  }
}

::v-deep .v-popover,
::v-deep .trigger,
.content-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: $font-size--btn;
}

.empty-button {
  padding-left: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
