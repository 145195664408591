<template>
  <div
    class="flex"
    :class="{
      'flex-col gap-4': !inline,
      'flex-row items-start gap-x-8 gap-y-4 flex-wrap': inline,
    }"
    role="group"
    :aria-labelledby="`group_label_${name}`"
  >
    <slot />
  </div>
</template>
<script>
export default {
  name: 'FCheckboxGroup',
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>
