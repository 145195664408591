<template>
  <div
    class="TableViewColumnPickerFieldItems"
    :class="{ 'TableViewColumnPickerFieldItems--secondary': secondary }"
  >
    <VueDraggable
      :list="columns"
      draggable=".TableViewColumnPickerFieldItem--draggable"
      handle=".TableViewColumnPickerFieldItem__drag-handle"
      group="TableViewColumnPickerFieldItems"
      direction="vertical"
      :invert-swap="true"
      tag="ul"
      @start="dragStart"
      @end="dragEnd"
      @change="$emit('on-column-reorder', $event)"
    >
      <TableViewColumnPickerItem
        v-for="column in columns"
        :key="column.id"
        :column="column"
        :data-column-id="column.id"
        :drag-started="column.id === dragStartedColumnId"
        :data-identifier="
          dataIdentifierPrefix
            ? `${dataIdentifierPrefix}-${column.id}`
            : undefined
        "
        class="TableViewColumnPickerFieldItem--draggable"
        drag-handle-class="TableViewColumnPickerFieldItem__drag-handle"
        @mouseenter.native="showEditHoverId = column.id"
        @mouseleave.native="showEditHoverId = ''"
      >
        <template v-if="showEditIcon(column)" #right-action>
          <CommonIcon
            v-tooltip="{ content: $t('Edit field') }"
            class="TableViewColumnPickerFieldItems__edit-icon"
            v-if="showEditHoverId == column.id"
            @click.native="$emit('on-show-edit-custom-field', column)"
            id="edit"
            color="#838F9C"
          />
        </template>
        <template #toggle-switch>
          <ToggleSwitch
            reversed-order
            @input="updateColumn(column.id, 'enabled', $event)"
            :value="column.enabled"
          />
        </template>
      </TableViewColumnPickerItem>
    </VueDraggable>
    <ul>
      <slot></slot>
    </ul>
  </div>
</template>
<script>
import ToggleSwitch from '@widgets/ToggleSwitch';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import { shallowRef } from 'vue-demi';
import { mapState } from 'vuex';
import VueDraggable from 'vuedraggable';
import { useTableView } from './useTableView';
import TableViewColumnPickerItem from './TableViewColumnPickerItem';

export default {
  name: 'TableViewColumnPickerFieldItems',
  components: {
    VueDraggable,
    TableViewColumnPickerItem,
    ToggleSwitch,
    CommonIcon,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    dataIdentifierPrefix: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showEditHoverId: '',
    };
  },
  computed: {
    ...mapState({
      canManageAllCustomFields: (state) =>
        state.permissions.canManageCustomFields,
    }),
    canManageProjectCustomFields() {
      return this.$store.getters[
        'project/current/canManageProjectCustomFields'
      ];
    },
    showProjectSpecificCustomFields() {
      return this.canManageAllCustomFields || this.canManageProjectCustomFields; // Shown except global and project customfield permissions are disabled
    },
  },
  methods: {
    showEditIcon(column) {
      if (!column.customField) {
        return false;
      }
      const isGlobalField = column.customField.projectId === null;
      if (isGlobalField) {
        return this.canManageAllCustomFields;
      }
      return this.showProjectSpecificCustomFields;
    },
  },
  setup(_props, { emit }) {
    const { updateColumn } = useTableView();
    const dragStartedColumnId = shallowRef(undefined);

    return {
      updateColumn,
      dragStartedColumnId,
      dragStart(event) {
        dragStartedColumnId.value = event.item.dataset.columnId;
        emit('on-drag-start', event);
      },
      dragEnd(event) {
        dragStartedColumnId.value = undefined;
        emit('on-drag-end', event);
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.TableViewColumnPickerFieldItems {
  list-style-type: none;
  padding: 5px 0 5px 0;
  max-height: 395px;
  overflow-y: auto;

  ul {
    padding-left: 0;
    max-height: 280px;
    overflow-y: auto;
  }

  &__edit-icon {
    cursor: pointer;
  }

  &--secondary {
    ul {
      max-height: 333px;
    }
  }
}
</style>
