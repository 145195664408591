<template>
  <div tabindex="0" :class="['w-empty-state-cell', { left: leftAlign, reset }]">
    <CommonIcon id="minus-solid" color="#c5cee0" />
  </div>
</template>

<script>
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';

export default {
  components: { CommonIcon },
  props: {
    leftAlign: {
      type: Boolean,
      default: true,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

.w-empty-state-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $table-cell--blank-slate-color;

  &.left {
    padding-left: 13px;
    justify-content: flex-start;
  }
  &.reset {
    padding-left: 0px;
  }
}
</style>
