<!-- Added: trigger slot to add custom trigger element, if not added, uses default -->
<!-- eslint-disable max-len -->
<template>
  <div
    class="assignees"
    :class="[
      { 'is-disabled': disabled },
      canEdit ? 'cursor-pointer' : 'cursor-default',
    ]"
    :style="cssVars"
    @click="$emit('click')"
  >
    <div
      :style="{
        zIndex: key,
        '--assignee-bubble-order': key,
      }"
      class="assignee"
      :class="{ 'read-only': disabled }"
      v-for="(item, key) in assigneesTeamsAndCompanies.slice(
        0,
        visibleAssignees,
      )"
      :key="key"
      tabindex="0"
      v-tooltip="{
        content: buildTooltipContent(item),
        placement: 'top',
        boundariesElement: 'viewport',
        container: 'body',
      }"
    >
      <template v-if="item.assigneeType === 'companies'">
        <img
          :src="item.companyLogoURL || item.logoUrl"
          :title="item.companyName || item.name"
          v-if="isAvatarCompany(item)"
        />
        <span v-else :title="item.companyName || item.name" class="company">
          <CommonIcons id="company" color="#999" />
        </span>
      </template>
      <template v-else-if="item.assigneeType === 'teams'">
        <img
          :src="item.teamLogo"
          :title="item.teamName || item.name"
          v-if="item.teamLogo && item.teamLogo.length"
        />
        <span
          v-if="!item.teamLogo.length"
          :title="item.teamName || item.name"
          :style="{ backgroundColor: item.teamLogoColor }"
        >
          <CommonIcons
            :id="item.teamLogoIcon"
            color="white"
            v-if="item.teamLogoIcon"
          />
          <span v-else>{{ item.teamName.substr(0, 2) }}</span>
        </span>
      </template>
      <template v-else>
        <img
          v-if="hasAvatar(item)"
          :src="item.avatarUrl"
          :alt="`${item.firstName} ${item.lastName}`"
        />
        <span
          v-else
          :title="`${item.firstName} ${item.lastName}`"
          :style="{
            backgroundColor: strToColor(`${item.firstName}${item.lastName}`),
          }"
        >
          {{ initials(item) }}
        </span>
      </template>
    </div>
    <div
      v-if="assigneesTeamsAndCompanies.length > visibleAssignees"
      :style="{
        zIndex: assigneesTeamsAndCompanies.length,
        '--assignee-bubble-order': visibleAssignees,
      }"
      class="assignees__popover-extra"
      :class="canEdit ? 'cursor-pointer' : 'cursor-default'"
    >
      <span
        :style="{ backgroundColor: '#000' }"
        tabindex="0"
        v-tooltip="{
          content: extraAssigneesTooltipContent,
          html: true,
          placement: 'top',
        }"
      >
        +{{ assigneesTeamsAndCompanies.length - visibleAssignees }}
      </span>
    </div>
    <div
      v-if="
        canEdit &&
        (!disabled || showAddButton) &&
        (!assigneesTeamsAndCompanies.length || showTriggerAfterPopulated)
      "
      :style="{
        zIndex: visibleAssignees + 1,
      }"
    >
      <slot
        name="trigger"
        :has-assignees="Boolean(assigneesTeamsAndCompanies.length)"
      >
        <span>{{ $t('Anyone') }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
// @vue/component
import SupersetIcon from '@teamwork/common-icons/dist/v-icon';
import { computed } from 'vue-demi';
import safeHtml from '@/utils/helpers/safeHtml';
import { strToColor } from '@/utils/helpers/color';
import { initials as buildInitials } from '@/utils/helpers/strings';

export default {
  name: 'Assignees',
  components: {
    CommonIcons: SupersetIcon,
  },
  props: {
    assignees: { type: Array, default: () => [] },
    teams: { type: Array, default: () => [] },
    companies: { type: Array, default: () => [] },
    visibleAssignees: { type: Number, default: 2 },
    canEdit: { type: Boolean, default: true },
    size: { type: Number, default: 28 },
    // set showTriggerAfterPopulated to true if you want to keep the trigger
    // even after populated with assignees
    showTriggerAfterPopulated: {
      type: Boolean,
      default: false,
    },
    disabled: { type: Boolean, default: false },
    showAddButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const cssVars = computed(() => ({
      '--avatar-size': `${props.size}px`,
      '--avatar-offset': `${Math.round(props.size / -3)}px`,
    }));
    const assigneesTeamsAndCompanies = computed(() => {
      const mappedAssignees =
        props.assignees?.map((assignee) => ({
          ...assignee,
          assigneeType: 'assignees',
        })) ?? [];

      const mappedTeams =
        props.teams?.map((team) => ({
          ...team,
          assigneeType: 'teams',
        })) ?? [];

      const mappedCompanies =
        props.companies?.map((company) => ({
          ...company,
          assigneeType: 'companies',
        })) ?? [];

      return [...mappedAssignees, ...mappedTeams, ...mappedCompanies];
    });

    const buildTooltipContent = (item) => {
      if (item.firstName && item.lastName) {
        return `${item.firstName} ${item.lastName}`;
      }
      if (item.companyName) {
        return item.companyName;
      }
      if (item.teamName) {
        return item.teamName;
      }
      return item.name;
    };

    const extraAssigneesTooltipContent = computed(() => {
      const extra = assigneesTeamsAndCompanies.value.slice(
        props.visibleAssignees,
      );
      return extra
        .map((item) => `${safeHtml(buildTooltipContent(item))} <br>`)
        .join('');
    });

    const hasAvatar = (assignee) =>
      assignee.avatarUrl && !assignee.avatarUrl.includes('noPhoto');

    const initials = (assignee) =>
      buildInitials(assignee.firstName, assignee.lastName);

    function isAvatarCompany(item) {
      return item.companyLogoURL?.length || item.logoUrl?.length;
    }

    return {
      assigneesTeamsAndCompanies,
      buildTooltipContent,
      cssVars,
      extraAssigneesTooltipContent,
      hasAvatar,
      initials,
      isAvatarCompany,
      strToColor,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
@import 'src/styles/components/widgets/_w-tooltip.scss';

.assignees {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;

  &__popover-tooltip-content,
  &__popover-extra-tooltip-content {
    padding: $padding--w-tooltip__inner;
    background-color: $background--w-tooltip;
    color: $text--w-tooltip;
    border-radius: $border-radius--w-tooltip;
    font-size: $font-size--w-tooltip__inner;
  }

  .assignee,
  &__popover-extra {
    width: var(--avatar-size);
    height: var(--avatar-size);
    margin-left: var(--avatar-offset);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 0px 1px #fff;

    &:first-of-type {
      margin-left: 0;
    }

    img,
    .trigger {
      width: var(--avatar-size);
      height: var(--avatar-size);
    }

    span {
      font-size: 13px;
      color: #fff;
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }
  .company {
    background-color: #eee;
  }

  .assignee:not(:first-child),
  &__popover-extra {
    animation: slide 1s forwards;
    animation-timing-function: cubic-bezier(0.33, 0, 0.18, 0.99);
  }

  @keyframes slide {
    0% {
      transform: translateX(
        calc(var(--assignee-bubble-order) * calc(var(--avatar-size) / -2) + 4px)
      );
    }
  }

  &.is-disabled {
    pointer-events: none;
  }
}
</style>
