<template>
  <div class="px-6 py-4 rounded flex items-start gap-4" :class="bannerClasses">
    <div class="shrink-0 mt-0.5 flex items-center">
      <slot name="icon">
        <IconDefault v-if="variant === 'default'" :class="iconClasses" />
        <IconWarning v-if="variant === 'warning'" :class="iconClasses" />
        <IconError v-if="variant === 'error'" :class="iconClasses" />
      </slot>
    </div>
    <div
      class="
        flex flex-col
        grow
        gap-2
        text-default
        text-[color:var(--token-c-banner-color-text)]
      "
    >
      <div v-if="title || $slots.title" class="font-semibold">
        <slot name="title">{{ title }}</slot>
      </div>
      <div v-if="description || $slots.default">
        <slot>{{ description }}</slot>
      </div>
    </div>
    <button
      v-if="showDismissButton"
      type="button"
      class="border-0 cursor-pointer p-0 m-0 mt-0.5 bg-transparent outline-none"
      @click="$emit('dismiss')"
    >
      <IconClose
        class="w-4 h-4 fill-[color:var(--token-c-banner-color-text)]"
      />
    </button>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import IconDefault from '~icons/tw/banner-default';
import IconWarning from '~icons/tw/banner-warning';
import IconError from '~icons/tw/banner-error';
import IconClose from '~icons/tw/banner-close';
import { bannerVariants, defaultBannerVariant } from './consts';
import optionPropValidator from '../../../helpers/optionPropValidator';

export default {
  name: 'FBanner',
  components: {
    IconDefault,
    IconWarning,
    IconError,
    IconClose,
  },
  props: {
    iconClasses: {
      type: [String, Array, Object],
      default: '',
    },
    variant: {
      type: String,
      default: defaultBannerVariant,
      validator: optionPropValidator(bannerVariants, 'variant'),
    },
    title: {
      type: String,
      default: () => null,
    },
    description: {
      type: String,
      default: () => null,
    },
    showDismissButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const bannerVariantMap = {
      [bannerVariants.default]:
        'bg-[color:var(--token-c-banner-color-default)]',
      [bannerVariants.warning]:
        'bg-[color:var(--token-c-banner-color-warning)]',
      [bannerVariants.error]: 'bg-[color:var(--token-c-banner-color-error)]',
    };

    const bannerClasses = computed(() => bannerVariantMap[props.variant]);

    return {
      bannerClasses,
    };
  },
};
</script>
