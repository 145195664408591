<template>
  <ItemPicker
    :open="open"
    @show="show"
    @hide="close"
    :state="itemPickerState"
    :search-term="searchTerm"
    @search-change="onSearch"
    :search-placeholder="$t('Search tasks')"
    :no-result-text="$t('No tasks match your search')"
    :skeleton-items-count="5"
  >
    <template #trigger><slot></slot></template>
    <template #content>
      <DependenciesRecentItems
        v-if="showRecents"
        :exclude-task-ids="excludeTaskIds"
        @add-new-predecessor="addNewPredecessor"
      />
      <DependenciesSearchItems
        v-if="showQuickSearch"
        :tasks="formatedItems"
        :search-term="searchTerm"
        :exclude-task-ids="excludeTaskIds"
        @add-new-predecessor="addNewPredecessor"
      />
    </template>
    <template #footer>
      <ButtonText ref="browseDependencies" underline @click="openBrowseTasks">
        {{ $t('Browse tasks') }}
      </ButtonText>
    </template>
  </ItemPicker>
</template>
<script>
import { debounce } from 'lodash';
import ko from 'knockout';
import ItemPicker from '@widgets/ItemPicker/ItemPicker';
import ButtonText from '@widgets/TaskDetailWidgets/ButtonText';
import { computed, ref } from 'vue-demi';
import { useQuickSearchResultsLoader } from '@/platform/composables/useQuickSearchResultsLoader';
import DependenciesRecentItems from './DependenciesRecentItems';
import DependenciesSearchItems from './DependenciesSearchItems';
import { ITEM_PICKER_STATES } from '@/utils/helpers/itemPicker';
import toTaskModel from '@/utils/helpers/toTaskModel';

export default {
  name: 'DependencyPicker',
  props: {
    open: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    selectedTask: { type: Object, default: () => {} },
  },
  setup(props) {
    const MIN_CHARS_FOR_SEARCH = 2;
    const MAX_CHARS_FOR_SEARCH = 100;
    const RESULT_PAGE_SIZE = 5;

    const count = ref(-1);

    const searchTerm = ref('');

    const excludeTaskIds = computed(() => {
      const subtasksIds = props.selectedTask.subTaskIds;
      const predecessorIds = props.selectedTask.predecessorTasks
        ? props.selectedTask.predecessorTasks.map((task) => {
            return task.id;
          })
        : [];

      const concatIfArray = (...arrays) =>
        [].concat(...arrays.filter(Array.isArray));
      return concatIfArray(
        subtasksIds,
        [props.selectedTask.id],
        predecessorIds,
      );
    });

    const isSearchTermTooShort = computed(() => {
      return searchTerm.value.length < MIN_CHARS_FOR_SEARCH;
    });

    const isSearchTermTooLong = computed(() => {
      return searchTerm.value.length > MAX_CHARS_FOR_SEARCH;
    });

    const canPerformSearch = computed(() => {
      return !isSearchTermTooShort.value && !isSearchTermTooLong.value;
    });

    const showRecents = computed(() => {
      return props.open && searchTerm.value.length < 2;
    });
    const showQuickSearch = computed(() => {
      return props.open && searchTerm.value.length >= 2;
    });

    const onSearch = debounce((search) => {
      searchTerm.value = search;
      count.value = canPerformSearch.value ? RESULT_PAGE_SIZE : -1;
    }, 300);

    const params = computed(() => {
      return {
        searchTerm,
        searchAllProjects: true,
      };
    });

    const { state: quickSearchState } = useQuickSearchResultsLoader({
      params,
      count,
      pageSize: RESULT_PAGE_SIZE,
      entities: 'tasks',
    });
    const formatedItems = computed(() =>
      quickSearchState.items.value.map((item) => {
        return { id: item.entityId, name: item.title };
      }),
    );

    const { SUCCESS, NO_RESULT, LOADING } = ITEM_PICKER_STATES;
    const itemPickerState = computed(() => {
      if (showRecents.value) {
        return SUCCESS;
      }
      if (quickSearchState.loading.value) {
        return LOADING;
      }
      if (!quickSearchState.loading.value && formatedItems.value.length === 0) {
        return NO_RESULT;
      }
      return SUCCESS;
    });

    return {
      searchTerm,
      onSearch,
      quickSearchState,
      excludeTaskIds,
      itemPickerState,
      showRecents,
      showQuickSearch,
      formatedItems,
    };
  },
  data() {
    return {
      depenencyTaskSelected: false,
      tasks: [],
    };
  },
  components: {
    DependenciesRecentItems,
    DependenciesSearchItems,
    ItemPicker,
    ButtonText,
  },
  methods: {
    addNewPredecessor(predecessor) {
      this.addNewPredecessors([predecessor]);
    },
    addNewPredecessors(predecessors) {
      this.close();
      this.$emit('add-new-predecessors', predecessors);
    },
    show() {
      this.$emit('open');
    },

    close() {
      this.searchTerm = '';
      this.$emit('close');
    },
    openBrowseTasks() {
      this.close();
      const subtasksAsPredecessors =
        this.selectedTask?.subTaskIds &&
        this.selectedTask.subTaskIds.map((subTaskId) => {
          return {
            id: subTaskId,
          };
        });

      const concatIfArray = (...arrays) =>
        [].concat(...arrays.filter(Array.isArray));

      const modifiedTask = {
        ...this.selectedTask,
        predecessors: concatIfArray(
          subtasksAsPredecessors,
          this.selectedTask.predecessors,
        ),
      };
      this.$store.dispatch('modals/tko/open', {
        id: 'dependenciesPicker',
        args: {
          task: toTaskModel(modifiedTask),
          taskListId: this.selectedTask.tasklistId,
          projectId: this.selectedTask.project.id,
          projectName: this.selectedTask.project.name,
          stack: true,
          callback: (tasks) => {
            const selectedTasks = ko.unwrap(tasks).map((task) => {
              return {
                id: ko.unwrap(task.id),
                name: ko.unwrap(task.name),
                status: ko.unwrap(task.status),
                type: ko.unwrap(task.type),
              };
            });
            this.addNewPredecessors(selectedTasks);
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
.dependency-picker-popover-wrapper .w-popover__inner {
  width: 320px;
  padding: 16px 0 8px 0;
}
</style>
<style lang="scss" scoped>
.dependency-picker-search {
  margin: 0 12px 8px 12px;
}
.dependency-footer {
  display: flex;
  justify-content: end;
  .browse-tasks-button {
    color: #4461d7;
    padding-right: 12px;
    cursor: pointer;
  }
}
</style>
