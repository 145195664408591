/* eslint-disable no-param-reassign,consistent-return */
import { createListLoader } from '../index.js';
import { ACTIONS, MUTATIONS } from '../constants';

/**
 * Batch Loader - loads records in predefined batches
 *
 * This loader has no pagination, nor does it work with updates.
 * These concepts should be handled prior to batching.
 * The possible states that apply are:
 * - uninitialised
 * - loading
 * - loaded
 * - stale
 * - error
 *
 * Some of these states apply to a batch (loading, loaded, error),
 * while others apply to a single record (uninitialised, stale).
 *
 * Note that this loader will flag stale records, but it doesn't
 * proactively refresh any data, the assumption is that there will
 * be a lower granularity endpoint for refreshing individual records.
 *
 * Additional config options:
 * - exists (rootState, id) returns true if this record exists in the store
 * - param: name of the url param for the ids
 */

// eslint-disable-next-line import/prefer-default-export
export const createBatchLoader = (opts = {}) => {
  const config = opts.config || {};

  // Module shorthand
  if (config.module) {
    config.commit = config.commit || `${config.module}/records`;
    config.exists =
      config.exists ||
      ((rootState, id) => rootState[config.module].records[id]);
    config.mapToRecords =
      config.mapToRecords ||
      (({ rootState }, list) =>
        list.map((id) => rootState[config.module].records[id]));
  }

  const loaderActions = {};
  const batchActions = {};
  const acceptedLoaderActions = Object.values(ACTIONS);
  Object.keys(opts.actions || {}).forEach((action) => {
    (acceptedLoaderActions.includes(action) ? loaderActions : batchActions)[
      action
    ] = opts.actions[action];
  });

  return {
    namespaced: opts.namespaced !== false,
    modules: {
      batch: createListLoader({
        namespaced: true,
        config,
        // we're not expecting any filtering on a batch loader
        reactiveParams: false,
        actions: loaderActions,
      }),
    },
    state: {
      nextBatch: 1,
      stale: {},
      batchByRecId: {},
    },
    getters: {
      // Map through the isWorking getter by default
      isWorking: (state, getters) => getters['batch/isWorking'],
      ...(opts.getters || {}),
    },
    mutations: {
      markStale(state, id) {
        state.stale[id] = true;
        if (state.batchByRecId[id]) {
          delete state.batchByRecId[id];
        }
      },
      unmarkStale(state, ids) {
        ids.forEach((id) => {
          if (state.stale[id]) {
            delete state.stale[id];
          }
        });
      },
      incrementBatch(state) {
        state.nextBatch += 1;
      },
      assignBatch(state, { id, batch }) {
        batch.forEach((recId) => {
          state.batchByRecId[recId] = id;
        });
      },
    },
    actions: {
      ...batchActions,
      /**
       * Ensure all these records are available
       * @param {*} batch Array of IDs for access
       */
      [ACTIONS.ACCESS]({ dispatch, commit, state, rootState }, batch) {
        const newBatch = [];
        const working = {};

        batch.forEach((id) => {
          if (state.stale[id] || !config.exists(rootState, id)) {
            newBatch.push(id);
          } else {
            const currBatch = state.batchByRecId[id];
            if (currBatch) {
              working[currBatch] = true;
            }
          }
        });

        const promises = Object.keys(working).map((batchId) =>
          dispatch('batch/access', Number(batchId)),
        );

        if (newBatch.length) {
          const batchId = state.nextBatch;
          commit('incrementBatch');
          commit(`batch/${MUTATIONS.INIT}`, batchId);
          commit(`batch/${MUTATIONS.PARAMS}`, {
            [config.param]: newBatch.join(','),
          });
          commit('assignBatch', { id: batchId, batch: newBatch });
          promises.push(
            dispatch('batch/access', batchId).then(() =>
              commit('unmarkStale', newBatch),
            ),
          );
        }
        return Promise.all(promises);
      },

      /**
       * This loader will register stale records, it will not perform any updates,
       * the expectation is that another loader will catch these records first if they
       * were visible. If they aren't visible then they should be flagged stale.
       */
      [ACTIONS.DATA_CHANGE]({ commit }, idOrBatch) {
        const batch = Array.isArray(idOrBatch) ? idOrBatch : [idOrBatch];
        batch.forEach((id) => commit('markStale', id));
      },

      // Force refresh, for example when we don't know if records are stale
      [ACTIONS.RELOAD]({ dispatch, commit }, batch) {
        batch.forEach((id) => commit('markStale', id));
        dispatch(ACTIONS.ACCESS, batch);
      },
    },
  };
};
