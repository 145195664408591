import throttle from 'lodash/throttle';
import clamp from 'lodash/clamp';

// Component to allow a moveable absolutely positioned page element

// @vue/component
export default {
  name: 'Draggable',
  props: {
    active: { type: Boolean, default: true },
  },
  data: () => ({
    originPosition: [0, 0],
    currentPosition: [0, 0],
    draggableBounds: [[], []],
    isDragging: false,
  }),
  computed: {
    transform: ({ currentPosition: [x, y] }) =>
      `translate3d(${x}px, ${y}px, 0)`,
  },
  methods: {
    drag(event) {
      if (!this.isDragging) {
        return;
      }
      const { clientX, clientY } = event.touches ? event.touches[0] : event;

      // Calculate next dragged position - we work in transforms, rather than top-left pixels.
      const [originX, originY] = this.originPosition;
      const [boundsX, boundsY] = this.draggableBounds;

      const nextX = clamp(clientX - originX, ...boundsX);
      const nextY = clamp(clientY - originY, ...boundsY);

      this.currentPosition = [nextX, nextY];
    },
    dragStart(event) {
      // Prevent default actions when using mouse - so we can't select text etc when dragging
      if (!event.touches) {
        event.preventDefault();
      }
      const { clientX, clientY } = event.touches ? event.touches[0] : event;

      const [currentX, currentY] = this.currentPosition;
      this.originPosition = [clientX - currentX, clientY - currentY];
      this.isDragging = true;
    },
    dragEnd() {
      this.isDragging = false;
    },
    setBounds() {
      const { top, left, bottom, right } = this.$el.getBoundingClientRect();
      const [currentX, currentY] = this.currentPosition;
      this.draggableBounds = [
        [currentX - left, window.innerWidth - (right - currentX)],
        [currentY - top, window.innerHeight - (bottom - currentY)],
      ];
    },
    init() {
      // Use draggable handle or full area to drag
      const handle = this.$el.querySelector('.draggable-handle') || this.$el;

      // Add drag event listeners
      handle.addEventListener('touchstart', this.dragStart, false);
      window.addEventListener('touchend', this.dragEnd, false);
      window.addEventListener('touchmove', throttle(this.drag, 5), {
        passive: true,
      });

      handle.addEventListener('mousedown', this.dragStart, false);
      window.addEventListener('mouseup', this.dragEnd, false);
      window.addEventListener('mousemove', throttle(this.drag, 5), {
        passive: true,
      });

      // Add window resize event to watch and recalculate bounds if needed.
      window.addEventListener('resize', this.setBounds, false);

      this.setBounds();
    },
    dispose() {
      const handle = this.$el.querySelector('.draggable-handle') || this.$el;

      // remove all event listeners
      handle.removeEventListener('touchstart', this.dragStart, false);
      handle.removeEventListener('touchend', this.dragEnd, false);
      window.removeEventListener('touchmove', this.drag, { passive: true });

      handle.removeEventListener('mousedown', this.dragStart, false);
      handle.removeEventListener('mouseup', this.dragEnd, false);
      window.removeEventListener('mousemove', this.drag, { passive: true });

      window.addEventListener('resize', this.setBounds, false);
    },
  },
  watch: {
    active() {
      return this.active ? this.init() : this.dispose();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.active) {
        this.init();
      }
    });
  },
  destroy() {
    this.dispose();
  },
};
