<template>
  <div
    class="TableView"
    ref="tableElement"
    v-resize.content-box.immediate="onTableResize"
  >
    <div class="TableView__content">
      <div ref="topElement" />
      <div
        :class="{
          TableView__header: true,
          'TableView__header--dragging': resizedColumnId != null,
          'TableView__header--shadow': showHeaderShadow,
        }"
      >
        <VueDraggable
          :class="{
            TableView__cells: true,
            'TableView__cells--dragging': resizedColumnId != null,
          }"
          draggable=".TableView__cell--draggable"
          :value="enabledColumns"
          @input="onReorderColumns"
          @choose="draggingHeader = true"
          @unchoose="draggingHeader = false"
        >
          <div
            v-for="column in enabledColumns"
            :key="column.id"
            :data-column-id="column.id"
            :data-identifier="
              dataIdentifierPrefix
                ? `${dataIdentifierPrefix}-table-view-${column.id}-column`
                : undefined
            "
            @mouseenter="headerHover = true"
            @mouseleave="headerHover = false"
            :class="{
              TableView__cell: true,
              'TableView__cell--draggable': column.draggable,
              'TableView__cell--borders': headerHover,
              'TableView__cell--dragging': draggingHeader,
              'TableView__cell--center': column.headerCellCenter,
            }"
            :style="{
              left: column.left != null ? `${column.left}px` : undefined,
              zIndex: column.left != null ? '1' : undefined,
              width:
                resizedColumnId == null
                  ? column.width
                  : resizedColumnId === column.id
                  ? resizedColumnWidth
                  : `${column.computedWidth}px`,
              minWidth:
                resizedColumnId == null || resizedColumnId === column.id
                  ? `max(${minColumnWidth}, ${column.minWidth})`
                  : `${column.computedWidth}px`,
              maxWidth:
                resizedColumnId == null || resizedColumnId === column.id
                  ? `min(${maxColumnWidth}, ${column.maxWidth})`
                  : `${column.computedWidth}px`,
            }"
            v-resize.border-box.immediate="onCellResizeRAF"
            v-resizable="{
              right: column.resizable,
              onResizeStart: onManualResizeStart,
              onResize: onManualResize,
              onResizeEnd: onManualResizeEnd,
            }"
          >
            <div
              :class="{
                'TableView__cell-name': true,
                'TableView__cell-name--sortable': Boolean(column.sortKey),
                'TableView__cell-name--sorting': hasSortApplied(column),
              }"
              :title="column.name"
              @click="sortBy(column)"
            >
              <slot :name="`header-${column.id}`" :column="column">
                {{ column.name }}
              </slot>
            </div>
            <ColumnHelp v-if="column.tooltip" :text="column.tooltip" />
            <div class="TableView__cell-sort-arrow" @click="sortBy(column)">
              <SortArrow
                v-if="Boolean(column.sortKey)"
                :color="
                  (draggingHeader && '#E5E5E5') ||
                  (Boolean(column.sortKey) &&
                    column.sortKey === sort.key &&
                    '#3C55BD') ||
                  '#C5CEE0'
                "
                :class="{
                  'TableView__cell-sort-arrow-icon': true,
                  'TableView__cell-sort-arrow-icon--ascending': sort.ascending,
                  'TableView__cell-sort-arrow-icon--visible':
                    hasSortApplied(column),
                }"
              />
            </div>
            <v-popover
              v-if="hasSortApplied(column) || canEditCustomFields(column)"
              :class="{
                'TableView__cell-buttons': true,
                'TableView__cell-buttons-center': column.headerCellCenter,
              }"
              open-class="TableView__cell-buttons--open"
              :boundaries-element="boundariesElement"
              placement="bottom-end"
              popover-arrow-class="hidden"
            >
              <template #popover>
                <button
                  v-show="hasSortApplied(column)"
                  class="TableView__cell-buttons-content-button"
                  @click="resetSort"
                >
                  {{ $t('Clear sort') }}
                </button>
                <button
                  v-if="canEditCustomFields(column)"
                  class="TableView__cell-buttons-content-button"
                  @click="editCustomFieldModal(column)"
                  v-close-popover
                >
                  {{ $t('Edit field') }}
                </button>
              </template>
              <button class="TableView__cell-buttons-trigger-button">
                <CommonIcon
                  class="TableView__cell-buttons-trigger-icon"
                  id="chevron-down"
                />
              </button>
            </v-popover>
          </div>
        </VueDraggable>
        <div v-if="!hideTableViewPicker" class="TableView__column-picker">
          <TableViewColumnPicker
            class="TableView__column-picker-component"
            :data-identifier-prefix="
              dataIdentifierPrefix
                ? `${dataIdentifierPrefix}-table-view-column-picker`
                : null
            "
          />
        </div>
      </div>
      <div class="TableView__body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import { useIntersectionObserver } from '@vueuse/core';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  shallowRef,
} from 'vue-demi';
import VueDraggable from 'vuedraggable';
import ColumnHelp from '@/components/widgets/Help';
import SortArrow from '@/platform/icons/SortArrow';
import resizable from '@/utils/directives/resizable';
import resize from '@/utils/directives/resize';
import { useQuickViews } from '@/platform/composables/useQuickViews';
import { useTableView } from './useTableView';
import { provideTableViewInternal } from './useTableViewInternal';
import TableViewColumnPicker from './TableViewColumnPicker';
import useStore from '@/platform/composables/useStore';

function createSort(key = '', ascending = true) {
  return { key, ascending };
}

function validateSort(sort) {
  return (
    sort != null &&
    typeof sort === 'object' &&
    typeof sort.key === 'string' &&
    typeof sort.ascending === 'boolean'
  );
}

export default defineComponent({
  name: 'TableView',

  props: {
    sort: {
      validator: validateSort,
      default: createSort,
    },
    dataIdentifierPrefix: {
      type: String,
      default: null,
    },
    // Experiment E17 - Budget view
    hideTableViewPicker: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    'update:sort': validateSort,
  },

  components: {
    ColumnHelp,
    CommonIcon,
    SortArrow,
    TableViewColumnPicker,
    VueDraggable,
  },

  directives: {
    resizable,
    resize,
  },

  setup(props, { emit }) {
    const store = useStore();

    const draggingHeader = shallowRef(false);
    const headerHover = shallowRef(false);
    const tableElement = shallowRef();
    const topElement = shallowRef();
    const tableHeight = shallowRef(0);
    const tableWidth = shallowRef(0);
    const headerHeight = shallowRef(0);
    const sectionCount = shallowRef(0);
    const isIntersecting = shallowRef(true);
    const showHeaderShadow = computed(
      () => sectionCount.value === 0 && !isIntersecting.value,
    );
    const { enabledColumns, updateColumn, reorderColumns } = useTableView();
    const { closeQuickView } = useQuickViews();
    const resizedColumnId = shallowRef();
    const resizedColumnWidth = shallowRef();
    const minColumnWidth = computed(() =>
      enabledColumns.value.length > 1 ? '50px' : '100%',
    );
    const maxColumnWidth = computed(() =>
      enabledColumns.value.length > 1 ? `${tableWidth.value - 50}px` : '100%',
    );
    let resizedColumnElement;
    let resizing = false;

    function sortBy(column) {
      const key = column.sortKey;
      if (!key) {
        return;
      }
      const ascending = key === props.sort.key ? !props.sort.ascending : true;
      emit('update:sort', createSort(key, ascending));
    }

    function resetSort() {
      emit('update:sort', createSort());
    }

    provideTableViewInternal({
      tableElement,
      tableHeight,
      tableWidth,
      headerHeight,
      sectionCount,
    });

    function onTableResize({ element }) {
      tableHeight.value = element.clientHeight;
      tableWidth.value = element.clientWidth;
    }

    function onCellResizeRAF({ element }) {
      // When the side nav project panel is pinned, on expanding and collapsing
      // causes onCellResize to fire,  likewise expand and collapse of the sidenav itself.
      // On a page with many tasks, this tends to slow the expanding and collapsing down alot.
      // This is a workaround for the problem. A better approach to solve this
      // will most likely be required
      // see PR: https://github.com/Teamwork/teamwork-web-app/pull/627
      requestAnimationFrame(() => {
        headerHeight.value = element.offsetHeight;
        if (resizing) {
          return;
        }
        const { columnId } = element.dataset;
        const width = element.offsetWidth;
        updateColumn(columnId, 'computedWidth', width);
      });
    }

    function onManualResizeStart({ element }) {
      resizing = true;
      const { columnId } = element.dataset;
      const width = element.offsetWidth;
      resizedColumnId.value = columnId;
      resizedColumnWidth.value = `${width}px`;
      resizedColumnElement = element;
    }

    function onManualResize({ element, width }) {
      const { columnId } = element.dataset;
      resizedColumnId.value = columnId;
      resizedColumnWidth.value = `${width}px`;
      resizedColumnElement = element;
    }

    function onManualResizeEnd() {
      let tableHeaderElement;

      if (resizedColumnElement) {
        tableHeaderElement = resizedColumnElement.parentNode;
        const { columnId } = resizedColumnElement.dataset;
        const width = resizedColumnElement.offsetWidth;
        updateColumn(columnId, 'width', `${width}px`);
      }

      resizedColumnId.value = undefined;
      resizedColumnWidth.value = undefined;
      resizedColumnElement = undefined;

      requestAnimationFrame(() => {
        resizing = false;

        if (!tableHeaderElement) {
          return;
        }
        const cells = tableHeaderElement.children;

        for (let i = 0; i < cells.length; i += 1) {
          const cell = cells[i];
          const { columnId } = cell.dataset;

          if (columnId != null) {
            const width = cell.offsetWidth;
            updateColumn(columnId, 'computedWidth', width);
          }
        }
      });
    }

    function onReorderColumns(orderedColumns) {
      reorderColumns(orderedColumns.map(({ id }) => id));
    }

    const currentProject = computed(() => store.getters['project/current']);
    const canManageCustomFields = computed(
      () => store.state.permissions.canManageCustomFields,
    );
    const canManageProjectCustomFields = computed(
      () => store.getters['project/current/canManageProjectCustomFields'],
    );

    const showProjectSpecificCustomFields = computed(
      () => canManageCustomFields.value || canManageProjectCustomFields.value,
    );

    const onCloseCallBack = (result) => {
      if (result.wasCancelled) {
        return;
      }
      const customField =
        typeof result.customField.toJSON === 'function'
          ? result.customField.toJSON()
          : result.customField;
      const columnId = `custom-field-${customField.id}`;
      updateColumn(columnId, 'customField', customField);
      updateColumn(columnId, 'name', customField.name);
      updateColumn(columnId, 'description', customField.description);
    };

    function hasSortApplied(column) {
      return Boolean(column.sortKey) && column.sortKey === props.sort.key;
    }

    function canEditCustomFields(column) {
      if (!column.customField) {
        return false;
      }
      const isGlobalField = column.customField.projectId === null;
      if (isGlobalField) {
        return canManageCustomFields.value;
      }
      return showProjectSpecificCustomFields.value;
    }

    const canSetProjectSpecific = computed(() =>
      currentProject.value
        ? currentProject.value.type !== 'tasklists-template'
        : false,
    );

    function editCustomFieldModal(column) {
      const customField = column.customField;
      store.dispatch('modals/open', {
        name: 'add-edit-custom-field',
        props: {
          mode: 'edit',
          field: {
            id: customField.id,
            type: customField.type,
            name: customField.name,
            options: customField.options?.choices,
            description: customField.description,
          },
          entity: canSetProjectSpecific.value ? 'task' : 'project',
          projectId: canSetProjectSpecific.value
            ? currentProject.value.id
            : null,
          onUpdate: onCloseCallBack,
        },
      });
    }

    onMounted(() => {
      useIntersectionObserver(
        topElement.value,
        (entries) => {
          isIntersecting.value = entries[0].isIntersecting;
        },
        {
          root: tableElement.value,
          threshold: 0,
        },
      );
    });

    onUnmounted(() => closeQuickView());

    return {
      draggingHeader,
      headerHover,
      tableWidth,
      tableElement,
      topElement,
      showHeaderShadow,
      enabledColumns,
      resizedColumnId,
      resizedColumnWidth,
      minColumnWidth,
      maxColumnWidth,
      boundariesElement: document.body,
      sortBy,
      resetSort,
      onTableResize,
      onCellResizeRAF,
      onManualResizeStart,
      onManualResize,
      onManualResizeEnd,
      onReorderColumns,
      editCustomFieldModal,
      canEditCustomFields,
      hasSortApplied,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';
@import '@tko/src/styles/variables/colors/_branding.colors.scss';
@import '@tko/src/styles/variables/colors/_background.colors.scss';

.TableView {
  color: $token-ui-text-01;

  overflow: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &:hover {
    scrollbar-color: rgba(66, 82, 110, 0.36) rgba(255, 255, 255, 0);
    &::-webkit-scrollbar-thumb {
      background-color: rgba(66, 82, 110, 0.36);
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
  }

  &__content {
    width: max-content;
    min-width: 100%;
  }

  &__header {
    display: flex;
    position: sticky;
    top: 0;
    background: $token-ui-white;
    border-bottom: $token-size-px solid $neutral-50;
    color: #646d7d;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 10px;
    z-index: 100;
    &--shadow {
      box-shadow: 0 8px 20px rgba(11, 14, 31, 0.06);
      border-bottom: 1px solid #f5f7fa;
    }
    &--dragging {
      width: min-content;
    }
  }

  &__cells {
    display: flex;
    flex: 1 1 auto;
    &--dragging {
      width: min-content;
    }
  }

  &__cell {
    position: sticky;
    display: flex;
    align-items: center;
    background: $token-ui-white;
    flex: 1 1 auto;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 0 $token-size-4;
    line-height: 40px;

    &:first-child {
      padding-left: $token-size-0;
    }
    &:last-child {
      padding-right: $token-size-8;
    }
    &:hover > .TableView__cell-buttons {
      visibility: visible;
    }
    &:hover .TableView__cell-sort-arrow-icon {
      visibility: visible;
    }
    &--draggable {
      cursor: pointer;

      &.sortable-chosen,
      &.sortable-ghost {
        background-color: #e5e5e5;
      }
      &.sortable-drag {
        color: inherit;
        background-color: $token-ui-white;
      }
    }
    &--borders {
      border-right: $token-size-px solid $neutral-50;
    }
    &--dragging {
      &.sortable-ghost {
        cursor: grabbing;
        & > .TableView__cell-name {
          cursor: grabbing;
          color: #e5e5e5;
        }
      }
    }
    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
      & > .TableView__cell-name {
        margin-left: 15px;
        justify-self: center;
      }
    }
    ::v-deep .v-resizable:hover {
      background-color: $indigo-30;
    }
  }

  &__cell-name {
    overflow: hidden;
    text-overflow: ellipsis;
    &--sortable {
      cursor: pointer;
    }
    &--sorting {
      color: #3c55bd;
    }
  }

  &__cell-sort-arrow {
    cursor: pointer;
  }

  &__cell-sort-arrow-icon {
    transition: transform 0.3s ease;
    margin: 0 $token-size-2;
    visibility: hidden;

    &--ascending {
      transform: rotate(180deg);
    }
    &--visible {
      visibility: visible;
    }
  }

  &__cell-buttons {
    visibility: hidden;
    margin-left: auto;
    line-height: 0;
    &--open {
      visibility: visible;
    }
    &-center {
      margin-left: 0;
    }
  }

  &__cell-buttons-content-button {
    padding: 10px 12px;
    width: min-content;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    width: 100%;

    --radius-value: 8px;
    &:first-child {
      border-top-left-radius: var(--radius-value);
      border-top-right-radius: var(--radius-value);
    }
    &:last-child {
      border-bottom-left-radius: var(--radius-value);
      border-bottom-right-radius: var(--radius-value);
    }
    &:hover {
      background-color: #f5f7fa;
    }
  }

  &__cell-buttons-trigger-button {
    width: 16px;
    height: 16px;
    padding: 0;
    background: transparent;
    border: 1px solid #e7ebf1;
    border-radius: 4px;
  }

  &__cell-buttons-trigger-icon {
    width: 8px;
    height: 8px;
  }

  &__column-picker {
    position: sticky;
    right: 0;
    z-index: 1;
  }

  &__column-picker-component {
    position: absolute;
    top: 8px;
    right: 0;
  }
}
</style>
