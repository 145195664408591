<template>
  <div class="logged-time">
    <div class="logged-time__row" v-for="(log, index) in logs" :key="index">
      <RoundButton icon="timer" size="big" />
      <div class="logged-time__content">
        <div class="logged-time__user">{{ log.name }}</div>
        <div class="logged-time__tags">
          <Chip
            v-for="(tag, i) in log.tags"
            :key="i"
            :label="tag.name"
            :background="tag.color"
          />
        </div>
      </div>
      <div class="logged-time__timecol">
        <div class="logged-time__time">{{ log.time }}</div>

        <RoundButton icon="play" size="medium" :primary="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { Chip, RoundButton } from '..';

export default {
  name: 'LoggedTime',
  display: 'LoggedTime',
  components: { Chip, RoundButton },
  data() {
    return {
      logs: [
        {
          name: 'Amy Barret',
          time: '00h:42m',
          tags: [
            {
              name: 'Work',
              color: '#DCF5EA',
            },
            {
              name: 'Test Project',
              color: '#F5EEFC',
            },
          ],
        },
        {
          name: 'Claire Murphy',
          time: '1h:30m',
          tags: [
            {
              name: 'Work',
              color: '#DCF5EA',
            },
            {
              name: 'Test Project',
              color: '#F5EEFC',
            },
          ],
        },
        {
          name: 'James Brown',
          time: '4h:12m',
          tags: [
            {
              name: 'Work',
              color: '#DCF5EA',
            },
            {
              name: 'Test Project',
              color: '#F5EEFC',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.logged-time {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    border: 1px solid #f5f7fa;
    border-radius: 12px;
    padding: 10px 16px 8px 16px;
    margin-bottom: 8px;

    &:hover {
      background-color: #f5f7fa;
      border-color: #6a84ed;
    }
  }

  &__content {
    padding-left: 8px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
  }

  &__user {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0b0e1f;
    margin-bottom: 8px;
  }

  &__timecol {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__time {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #0b0e1f;
    margin-bottom: 4px;
  }
}
</style>
