<template>
  <button
    :class="['toggle-subtasks', { visible: hasChildren }]"
    :disabled="!hasChildren"
  >
    <CommonIcon :id="open ? 'chevron-down' : 'chevron-right'" color="#969BB1" />
  </button>
</template>
<script>
import CommonIcon from '@teamwork/common-icons/dist/v-icon';

export default {
  name: 'SubtasksItemToggle',
  props: {
    hasChildren: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
  },
  components: {
    CommonIcon,
  },
};
</script>
<style lang="scss" scoped>
.toggle-subtasks {
  margin-right: 8px;
  border: none;
  padding: 0;
  background: transparent;
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
</style>
