<template>
  <div
    class="task-item-name"
    :class="{ 'task-item-name--completed': completed }"
    v-on="$listeners"
    ref="current"
    v-tooltip="{
      content: isTruncated ? value : undefined,
      classes: 'tooltip w-tooltip task-item-name__tooltip',
    }"
  >
    <span class="task-item-name__text">
      {{ value }}
    </span>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue-demi';

export default defineComponent({
  name: 'TaskItemName',
  props: {
    value: { type: String, required: true },
    completed: { type: Boolean, default: false },
  },
  setup() {
    const current = ref(null);
    const isTruncated = ref(false);

    // Using watchEffect because offsetWidth starts with 0 until subtasks are loaded
    watchEffect(
      () => {
        if (current.value) {
          const { offsetWidth, scrollWidth } = current.value;
          isTruncated.value = offsetWidth < scrollWidth;
        }
      },
      {
        flush: 'post',
      },
    );

    return {
      isTruncated,
      current,
    };
  },
});
</script>

<style lang="scss">
.task-item-name__tooltip {
  max-width: 300px;
}
</style>

<style lang="scss" scoped>
.task-item-name {
  flex-grow: 2;
  font-size: 14px;
  line-height: 24px;
  color: #0b0e1f;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__text:hover {
    text-decoration: underline;
  }

  &--completed {
    text-decoration: line-through;
    cursor: default;
  }
}
</style>
