import {
  createRecordListLoader,
  API,
  ACTIONS,
  MUTATIONS,
} from '@/store/utils/loader';
import { mapActions } from '@/store/utils/record-mapper';

// Recursive Deps loader should only be called with `reload`, being recursive
// means the staleness of the data cannot be tracked.
export default createRecordListLoader({
  namespaced: true,
  config: {
    url: (id) => `${API.v2}/tasks/${id}/predecessors.json`,
    reactiveParams: false,
    params: () => ({
      onlyBasicFields: true,
      onlyActionable: true,
      getRecursively: true,
    }),
  },
  actions: mapActions({
    // Public - matches a `reload`, but can specify `onlyDependencies` API parameter
    fetch({ dispatch, commit }, onlyDependencies) {
      dispatch(ACTIONS.LOAD_CANCEL);
      commit(MUTATIONS.INIT);
      commit(MUTATIONS.PARAMS, { onlyDependencies });
      return dispatch(ACTIONS.LOAD_WAIT);
    },
    async [ACTIONS.LOADED_SUCCESS]({ commit, dispatch, rootState, id }, rs) {
      const deps = rs.data.tasks;
      const uniqueTasks = [
        ...new Set([
          id,
          ...deps.map((d) => d.id),
          ...deps.map(({ depTaskId }) => depTaskId),
        ]),
      ];
      await dispatch('task/batch/access', uniqueTasks, {
        root: true,
        recordMap: false,
      });
      const projLookup = {};
      const projs = new Set();
      uniqueTasks.forEach((t) => {
        projLookup[t] = (rootState.task.records[t] || {}).projectId;
        projs.add(projLookup[t]);
      });
      // We may be loading tasks across projects, so best ensure those projects are loaded
      await Promise.all(
        [...projs].map(
          (projectId) =>
            projectId &&
            dispatch(
              'project/access',
              { id: projectId, payload: { registerAccess: false } },
              { root: true, recordMap: false },
            ),
        ),
      );
      const enriched = deps.map((d) => ({
        ...d,
        projectId: projLookup[d.depTaskId],
      }));
      commit('dependencies/records', enriched, {
        root: true,
        recordMap: false,
      });
    },
  }),
});
