<template>
  <span
    class="mx-auto"
    :style="shouldMinimizeSidebar ? stylesMinimize : stylesFull"
  >
    <slot />
  </span>
</template>

<script>
import { usePreferences } from '@/platform/composables/usePreferences';

export default {
  data() {
    return {
      stylesMinimize: {
        width: '40px',
      },
      stylesFull: {
        width: '164px',
      },
    };
  },
  setup() {
    const { shouldMinimizeSidebar } = usePreferences();

    return {
      shouldMinimizeSidebar,
    };
  },
};
</script>
