import {
  computed,
  getCurrentScope,
  onScopeDispose,
  shallowRef,
  watch,
} from 'vue-demi';
import useRouter from './useRouter';
import useProjectLoader from './useProjectV2Loader';

/**
 * Loads a project for the current route.
 * The loader's state is guaranteed to be consistent with the Vue router.
 * In most cases the `item` will be available immediately on route changes,
 * however, it is still necessary to handle all the usual loader states.
 */
export default function useCurrentProjectLoader() {
  const scope = getCurrentScope();
  const router = useRouter();
  const params = {
    getPermissions: true,
    getNotificationSettings: true,
    getActivePages: true,
    getDateInfo: true,
    getEmailAddress: true,
    formatMarkdown: false,
    includeProjectOwner: true,
    includeCustomFields: true,
  };

  function createState({ type, id } = {}) {
    const itemType = shallowRef(type);
    const itemId = shallowRef(id);
    return {
      ...useProjectLoader({ itemType, itemId, params }),
      itemType,
      itemId,
    };
  }

  function fixType(type) {
    if (type === 'projects') {
      return 'project';
    }
    if (type === 'tasks') {
      return 'task';
    }
    if (type === 'tasklists') {
      return 'tasklist';
    }
    if (type === 'notebooks') {
      return 'notebook';
    }
    if (type === 'files') {
      return 'file';
    }
    if (type === 'links') {
      return 'link';
    }
    if (type === 'messages') {
      return 'message';
    }
    if (type === 'milestones') {
      return 'milestone';
    }
    return undefined;
  }

  function getTypeAndId(route) {
    const typeAndId =
      /^\/(projects|tasks|tasklists|notebooks|files|links|messages|milestones)\/(\d+)/.exec(
        route.path.toLowerCase(),
      );
    return {
      type: typeAndId ? fixType(typeAndId[1]) : undefined,
      id: typeAndId ? Number(typeAndId[2]) : undefined,
    };
  }

  const currentState = shallowRef(
    createState(getTypeAndId(router.currentRoute)),
  );
  const nextState = shallowRef(createState());

  // Just before navigation is confirmed,
  // prepare `nextState` and wait until it's ready.
  onScopeDispose(
    router.beforeResolve((to, from, next) => {
      const { type, id } = getTypeAndId(to);

      // `currentState` or `nextState` is the expected state, so proceed with the route change.
      if (
        (currentState.value.itemType.value === type &&
          currentState.value.itemId.value === id) ||
        (nextState.value.itemType.value === type &&
          nextState.value.itemId.value === id)
      ) {
        next();
        return;
      }

      const { inSync, item, error, itemType, itemId } = nextState.value;
      let unwatch;

      // Wait until `nextState` is initialized.
      scope.run(() => {
        unwatch = watch([inSync, item, error, itemType, itemId], () => {
          if (
            unwatch &&
            (inSync.value ||
              item.value !== undefined ||
              (error.value && error.value.config?.cache !== 'only-if-cached'))
          ) {
            unwatch();
            unwatch = null;
            next();
          }
        });
      });

      // Update `nextState`.
      itemType.value = type;
      itemId.value = id;
    }),
  );

  // Just after navigation has been confirmed,
  // make sure that `currentState` is correct.
  onScopeDispose(
    router.afterEach((to) => {
      const { type, id } = getTypeAndId(to);

      // `currentState` is the expected state.
      if (
        currentState.value.itemType.value === type &&
        currentState.value.itemId.value === id
      ) {
        return;
      }

      // `nextState` is the expected state, so swap it with `currentState`.
      if (
        nextState.value.itemType.value === type &&
        nextState.value.itemId.value === id
      ) {
        const previousCurrentState = currentState.value;
        currentState.value = nextState.value;
        nextState.value = previousCurrentState;
        return;
      }

      // No state ready to use, so update `currentState`.
      currentState.value.itemType.value = type;
      currentState.value.itemId.value = id;
    }),
  );

  return {
    item: computed(() => currentState.value.item.value),
    inSync: computed(() => currentState.value.inSync.value),
    loading: computed(() => currentState.value.loading.value),
    meta: computed(() => currentState.value.meta.value),
    response: computed(() => currentState.value.response.value),
    error: computed(() => currentState.value.error.value),
  };
}
