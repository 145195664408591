<template>
  <Popover
    v-if="task.parentTaskId > 0"
    ref="popover"
    placement="top-center"
    @apply-show="show"
    :no-flip="true"
  >
    <div class="label label-default font-size--tiny mr--small">
      {{ $t('Subtask') }}
    </div>
    <template slot="popover">
      <div class="subtask-popover">
        <template v-if="parentTask && !isLoading">
          <label class="mb--none">{{ $t('Parent Task') }}</label>
          <a
            class="subtask-popover__parent-task"
            :href="`#/tasks/${task.parentTaskId}`"
          >
            {{ parentTask.name || task.parentTask.content }}
          </a>
          <table>
            <tr>
              <td class="subtask-popover__key">{{ $t('Last Updated') }}:</td>
              <td class="subtask-popover__value">{{ dateUpdated }}</td>
            </tr>
            <tr>
              <td class="subtask-popover__key">{{ $t('Created By') }}:</td>
              <td class="subtask-popover__value">
                {{ fullName }}
                <br />
                {{ dateCreated }}
              </td>
            </tr>
          </table>
        </template>
        <template v-else>{{ $t('Loading') }}</template>
      </div>
    </template>
  </Popover>
</template>
<script>
import moment from 'moment';
import Popover from '@widgets/Popover';
import TasksAPI from '@/platform/data/tasks';

export default {
  name: 'SubtaskPopover',
  components: { Popover },
  data() {
    return {
      parentTask: null,
      isLoading: false,
    };
  },
  props: {
    task: {
      type: Object,
      default: null,
    },
  },
  computed: {
    dateUpdated() {
      return moment(this.parentTask.dateChanged).format('DD/MM/YYYY, HH:mm');
    },
    dateCreated() {
      /* eslint-disable no-underscore-dangle */
      return moment(this.parentTask.dateCreated._d).format('DD/MM/YYYY, HH:mm');
    },
    fullName() {
      return `${this.parentTask.createdBy.firstName} ${this.parentTask.createdBy.lastName}`;
    },
  },
  methods: {
    show() {
      this.fetchParentTask();
    },
    async fetchParentTask() {
      this.isLoading = true;
      try {
        const { task } = await TasksAPI.fetchSingleTask(this.task.parentTaskId);
        this.parentTask = task;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

.subtask-popover {
  padding: $padding--medium;
  color: $color--text-tinted-dark;

  &__parent-task {
    display: block;
    font-size: $font-size--smaller;
    color: $color--text-tinted-dark;
    padding-bottom: $margin--small;
    margin-bottom: $padding--small;
    border-bottom: 1px solid $neutral-60;
    text-decoration: underline;
  }

  &__key {
    font-size: $font-size--small;
    vertical-align: top;
    white-space: nowrap;
    color: $color--text-sub;
    padding-right: $padding--medium;
  }

  &__value {
    font-size: $font-size--small;
    vertical-align: top;
    white-space: nowrap;
    color: $color--text-default;
  }
}
</style>
