var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications"},[(_vm.title || _vm.$slots.title)?_c('div',{staticClass:"notifications__header"},[(_vm.title)?_c('span',{staticClass:"notifications__header-text"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]):_vm._e(),_vm._v(" "),_vm._t("title")],2):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [(!_vm.shouldStillShowIfMuted && _vm.areNotificationsMuted)?_c('BlankSlate',{attrs:{"title":_vm.$t('Notifications muted'),"msg":_vm.$t('You have muted notifications')},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('MutedEmptyStateImg',{staticClass:"blank-slate__image"})]},proxy:true},{key:"action",fn:function(){return [_c('button',{staticClass:"btn btn-primary",attrs:{"data-identifier":(_vm.dataIdentifierPrefix + "-empty-state-mute"),"type":"button"},on:{"click":function($event){_vm.areNotificationsMuted = false}}},[_vm._v("\n          "+_vm._s(_vm.$t('Unmute'))+"\n        ")])]},proxy:true}],null,false,4208080718)}):[_c('div',{staticClass:"flex justify-between items-end mx-4"},[_c('FTabs',{attrs:{"data-identifier-prefix":_vm.dataIdentifierPrefix,"tabs":Object.values(_vm.tabs),"disable-integrated-border":""},model:{value:(_vm.activeTabCode),callback:function ($$v) {_vm.activeTabCode=$$v},expression:"activeTabCode"}}),_vm._v(" "),_vm._t("filter")],2),_vm._v(" "),_c('FTabsBorder',{staticClass:"mx-4",attrs:{"border-color-class":_vm.darkenTabBorder
            ? "border-[color:var(--token-palette-color-neutral-30)]"
            : undefined}}),_vm._v(" "),(_vm.loaders['unread'])?_c('NotificationsLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabCode === 'unread'),expression:"activeTabCode === 'unread'"}],attrs:{"type":"unread","loader":_vm.loaders['unread'],"active":_vm.activeTabCode === 'unread' && _vm.active},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var notifications = ref.notifications;
            var allLoaded = ref.allLoaded;
            var isLoading = ref.isLoading;
return [_c('NotificationsList',{attrs:{"all-loaded":allLoaded,"blank-state-message":_vm.unreadBlankStateMessage,"blank-state-title":_vm.unreadBlankStateTitle,"data-identifier-prefix":_vm.dataIdentifierPrefix,"disable-sub-element-click":_vm.disableSubElementClick,"is-loading":isLoading,"new-notification-ids":_vm.newEventIds,"notifications":notifications,"popover-id":_vm.popoverId,"transition-duration":_vm.transitionDuration,"transition":_vm.transitionName},on:{"new-notification-observe":_vm.onNotificationObserved},scopedSlots:_vm._u([{key:"blank-state-image",fn:function(){return [(_vm.showBellEmptyStateImg)?_c('BellEmptyStateImg',{staticClass:"blank-slate__image"}):_c('InboxEmptyStateImg',{staticClass:"blank-slate__image"})]},proxy:true}],null,true)})]}}],null,false,4013369685)}):_vm._e(),_vm._v(" "),(_vm.loaders['read'])?_c('NotificationsLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabCode === 'read'),expression:"activeTabCode === 'read'"}],attrs:{"type":"read","loader":_vm.loaders['read'],"active":_vm.activeTabCode === 'read' && _vm.active},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var notifications = ref.notifications;
            var allLoaded = ref.allLoaded;
            var isLoading = ref.isLoading;
return [_c('NotificationsList',{attrs:{"all-loaded":allLoaded,"blank-state-message":_vm.readBlankStateMessage,"blank-state-title":_vm.readBlankStateTitle,"data-identifier-prefix":_vm.dataIdentifierPrefix,"disable-sub-element-click":_vm.disableSubElementClick,"is-loading":isLoading,"new-notification-ids":_vm.newEventIds,"notifications":notifications,"popover-id":_vm.popoverId,"transition-duration":_vm.transitionDuration,"transition":_vm.transitionName},on:{"new-notification-observe":_vm.onNotificationObserved},scopedSlots:_vm._u([{key:"blank-state-image",fn:function(){return [_c('ReadEmptyStateImg',{staticClass:"blank-slate__image"})]},proxy:true}],null,true)})]}}],null,false,2867762470)}):_vm._e(),_vm._v(" "),(_vm.loaders['all'])?_c('NotificationsLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTabCode === 'all'),expression:"activeTabCode === 'all'"}],attrs:{"type":"all","loader":_vm.loaders['all'],"active":_vm.activeTabCode === 'all' && _vm.active},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var notifications = ref.notifications;
            var allLoaded = ref.allLoaded;
            var isLoading = ref.isLoading;
return [_c('NotificationsList',{attrs:{"all-loaded":allLoaded,"blank-state-message":_vm.allBlankStateMessage,"blank-state-title":_vm.allBlankStateTitle,"data-identifier-prefix":_vm.dataIdentifierPrefix,"disable-sub-element-click":_vm.disableSubElementClick,"is-loading":isLoading,"new-notification-ids":_vm.newEventIds,"notifications":notifications,"popover-id":_vm.popoverId,"transition-duration":_vm.transitionDuration,"transition":_vm.transitionName},on:{"new-notification-observe":_vm.onNotificationObserved},scopedSlots:_vm._u([{key:"blank-state-image",fn:function(){return [(_vm.showBellEmptyStateImg)?_c('BellEmptyStateImg',{staticClass:"blank-slate__image"}):_c('InboxEmptyStateImg',{staticClass:"blank-slate__image"})]},proxy:true}],null,true)})]}}],null,false,3845334293)}):_vm._e()]]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }