import { ref, computed, provide, inject, watch } from 'vue-demi';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';
import useCurrentRoute from '@/platform/composables/useCurrentRoute';

const sidebarActiveRouteSymbol = Symbol('useSidebarActiveRoute');

// Must be exported for access from TKO as this behaviour is from older TKO features
const isSidebarHidden = ref(false);

/*
 * Some application routes like Email Gateway should never render the sidebar
 * Check if the current route is part of this list.
 */
function isHiddenForRoute(routeName) {
  return /(\/(emailgateway|getting-started|onboarding|embed))/.test(routeName);
}

/**
 * Sets a piece of state to indicate the sidebar should hide
 * @param {Boolean} val
 */
export const setIsSidebarHidden = (val) => {
  isSidebarHidden.value = val;
};

// TODO use the router - we actually already have it when provideSidebarActiveRoute is called
/*
 * We do not have Vue Router at the sidebar level currently
 * So lets watch the pop state and use that to determine the active route
 * within the side bar
 */
export function provideSidebarActiveRoute() {
  // const currentHash = ref(window.location.hash);
  const currentRoute = useCurrentRoute();
  const { hideActivePanel } = useSidebarPanels();

  isSidebarHidden.value = isHiddenForRoute(currentRoute.value?.path);

  const isActive = (routeName) =>
    computed(() => currentRoute.value?.path === routeName);

  const isInActiveList = (routeNames) => {
    return computed(() => {
      const path = currentRoute.value?.path;

      return routeNames.some((routeName) => {
        if (typeof routeName === 'string') {
          return path.startsWith(routeName);
        }

        return routeName.test(path);
      });
    });
  };

  const gotoRoute = (hash) => {
    // eslint-disable-next-line no-param-reassign
    window.location.hash = hash;
  };

  watch(currentRoute, () => {
    if (`#${currentRoute.value?.path}` !== window.location.hash) {
      hideActivePanel();
    }
    isSidebarHidden.value = isHiddenForRoute(currentRoute.value?.path);
  });

  provide(sidebarActiveRouteSymbol, {
    isActive,
    isInActiveList,
    gotoRoute,
    isSidebarHidden: computed(() => {
      // hidden for MS Teams Integration when fullscreen query is set
      return isSidebarHidden.value || currentRoute.value?.query.fullscreen;
    }),
  });
}

export function useSidebarActiveRoute() {
  return inject(sidebarActiveRouteSymbol);
}
