var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"h-[40px] flex select-none rounded-full",attrs:{"role":"none"}},[_c('button',{staticClass:"\n      flex\n      gap-2\n      justify-between\n      items-center\n      py-0\n      px-4\n      m-0\n      border-0\n      bg-transparent\n      w-full\n      rounded-full\n    ",class:[].concat( (_vm.active
        ? [
            'bg-palette-indigo-10',
            'hover:bg-palette-indigo-10',
            'focus:bg-palette-indigo-10',
            'text-palette-indigo-40',
            'hover:text-palette-indigo-40' ]
        : [
            'bg-transparent',
            'hover:bg-palette-neutral-10',
            'focus:bg-palette-neutral-10',
            'text-text',
            'hover:text-text' ]) ),attrs:{"tabindex":"-1","role":"menuitem"},on:{"click":_vm.onClick,"mouseenter":function($event){return _vm.onMouseHover('enter')},"mouseleave":function($event){return _vm.onMouseHover('leave')}}},[_c('span',{staticClass:"flex grow items-center gap-4 min-w-0"},[(_vm.$slots.icon)?_c('span',{staticClass:"\n          flex\n          items-center\n          justify-center\n          w-[20px]\n          h-[20px]\n          flex-grow-0 flex-shrink-0\n          text-text-disabled\n          fill-current\n        ",class:[].concat( (_vm.active ? ['text-primary'] : ['text-text-disabled']) )},[_vm._t("icon")],2):_vm._e(),_vm._v(" "),_c('span',{staticClass:"\n          block\n          text-current\n          whitespace-nowrap\n          overflow-hidden\n          text-ellipsis text-left\n        "},[_vm._t("title"),_vm._v(" "),(_vm.$slots.subtitle)?_c('div',{staticClass:"text-xs text-[#888] text-left"},[_vm._t("subtitle")],2):_vm._e()],2)]),_vm._v(" "),_c('span',{staticClass:"flex shrink-0 items-center gap-2"},[(this.$props.shortcut)?_c('span',{staticClass:"\n          flex flex-none\n          border-2 border-[#E7EBF1] border-solid\n          text-[10px]\n          rounded-sm\n          w-5\n          h-5\n          font-bold\n          text-text-disabled\n          leading-none\n          items-center\n          justify-center\n        "},[_vm._v("\n        "+_vm._s(_vm.shortcut)+"\n      ")]):_vm._e(),_vm._v(" "),(this.$props.badge)?_c('span',{staticClass:"\n          text-[10px]\n          font-bold\n          text-black\n          bg-[#FF61C7]\n          rounded-full\n          h-[20px]\n          inline-flex\n          justify-center\n          items-center\n          px-1.5\n        "},[_vm._v("\n        "+_vm._s(_vm.badge)+"\n      ")]):_vm._e()]),_vm._v(" "),(_vm.$slots.additionalIcon)?_c('div',{staticClass:"flex items-center ml-auto mr-0 flex-none"},[_vm._t("additional-icon")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }