<template>
  <div class="preview-container bg-white border-1 border-[#C5CEE0] p-4 rounded">
    <div class="file-contents relative">
      <div class="w-5/6">
        <p>
          {{ file.name }}
        </p>
      </div>
      <div class="file-men absolute top-0 right-0">
        <div class="w-1/6"></div>
        <MenuPopover
          :open.sync="isMenuOpen"
          :no-arrow="true"
          placement="top-end"
          :boundaries="'body'"
          :offset="0"
        >
          <button class="file-menu-button btn-reset">
            <CommonIcons id="ellipsis-h" />
          </button>
          <template #menu>
            <MenuElement
              class="file-menu-item"
              v-close-popover
              :text="$t('Delete file')"
              @click="onDeleteFile"
            />
          </template>
        </MenuPopover>
      </div>
    </div>
  </div>
</template>

<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import { useFileActions } from '@teamwork/use';
import MenuPopover from '@widgets/Menu/MenuPopover';
import MenuElement from '@widgets/Menu/MenuElement';
import { ref } from 'vue-demi';

export default {
  name: 'FileThumbnail',
  props: {
    file: { type: Object, required: true },
  },
  components: {
    CommonIcons,
    MenuPopover,
    MenuElement,
  },
  setup() {
    const showError = ref(false);
    const isLoading = ref(false);
    const isMenuOpen = ref(false);
    const { deleteFile, restoreFile } = useFileActions();

    function onDeleteFile() {
      this.$emit('delete-file', this.file);
    }

    return {
      showError,
      isLoading,
      isMenuOpen,
      deleteFile,
      restoreFile,
      onDeleteFile,
    };
  },
};
</script>
