import Qs from 'qs';
import { essentialDataIsLoaded } from './map-tko-globals';

let sharedFilterProvider;
const projectSubscriptions = [];
const subscriptions = [];

// Scaffolds the sharedFilterProvider data into Vue, right now this is one-way.
// Each time the filterKey is set,
// if this is a project route, we add a subscriber to the projectId,
// when it changes we call the filter provider again. This is because the filter
// provider isn't reactive to project changes :(
//
// If this isn't a project route, we can safely subscribe directly to
// the filter.

// Custom fields need to be parsed into a format like:
// projectCustomField[3][eq]=some value
// customField[7][any]=some,comma,seperated,list
// @field is the field name, eg customfield or projectCustomField
// @value is the custom field value object, eg:
// {
//   3: {
//     eq:'some value
//   }
// }
const stringifyCustomFieldParam = (field, value) => {
  const obj = {
    [field]: value,
  };
  const returnObj = {};

  const stringifiedParam = Qs.stringify(obj)
    .replace(/(?:\[|%5B)/g, '[')
    .replace(/(?:\]|%5D)/g, ']');
  stringifiedParam.split('&').forEach((param) => {
    const stringifiedParamParts = param.split('=');
    // We need to decode the end value back for the api and replace '%2C' with '%2C',
    const parsedParamValue = decodeURI(stringifiedParamParts[1]).replace(
      /%2C/g,
      ',',
    );
    if (stringifiedParamParts[0] && stringifiedParamParts[1]) {
      returnObj[`${stringifiedParamParts[0]}`] = parsedParamValue;
    }
  });

  return returnObj;
};

const newFilterSubscription = async (filterKey, parameter) => {
  // If the provider hasn't been loaded, do it dynamically and wait
  sharedFilterProvider =
    sharedFilterProvider ||
    (await import('@tko/src/app/sharedDataProviders/shared-filterProvider'))
      .default;
  const app = window.app;
  const ko = app.ko;

  // Cleanup subs for previous filter key
  subscriptions.forEach((s) => s.dispose());
  subscriptions.length = 0;

  if (!filterKey) {
    return;
  }

  const filter = sharedFilterProvider.getFilter(filterKey, parameter);
  // Once created, the filter appears to persist between routes, which
  // seems like a good thing, but it may cause the currentFilter not to
  // be updated.
  // https://digitalcrew.teamwork.com/#/tasks/15377083
  if (!filter().subscriptions.length) {
    // eslint-disable-next-line no-underscore-dangle
    filter()._buildSubscriptions();
  }
  if (!filter().sections().length) {
    // eslint-disable-next-line no-underscore-dangle
    filter()._getSections();
  }
  if (filter() !== app.currentFilter()) {
    app.currentFilter(filter());
  }

  // JSON-like parameters data
  const jsonParams = ko.pureComputed(() => {
    if (!filter()) {
      return {};
    }
    const params = ko.toJS(filter().parameters);
    // Convert Moments into formatted strings
    ['startDate', 'endDate', 'createdAfterDate', 'createdBeforeDate'].forEach(
      (k) => {
        if (params[k]) {
          params[k] = params[k].format('YYYYMMDD');
        }
      },
    );

    // custom fields
    ['customFields', 'projectCustomFields'].forEach((k) => {
      if (!params[k]) {
        return;
      }
      const customFields = params[k];
      if (!customFields) {
        return;
      }

      // This takes `customFields` and parses them into
      // `customField` or `projectCustomField` objects the way the api likes it
      app.customFieldsHelper.addFilterParams(customFields, params);
      delete params[k]; // We only need the `customField` param for the api
    });

    // Stringify each parsed custom field
    ['customField', 'projectCustomField'].forEach((k) => {
      if (!params[k]) {
        return;
      }
      // Make up the stringified param name like projectCustomField[3][eq]
      const stringifiedParam = stringifyCustomFieldParam(k, params[k]);
      Object.assign(params, stringifiedParam);
      delete params[`${k}s`];
    });

    return params;
  });

  subscriptions.push(jsonParams);

  // Rate limit is here because `active` is calculated from params, this avoids double
  // notification
  const filterChange = ko
    .pureComputed(() => [jsonParams(), filter() && filter().active()])
    .extend({ rateLimit: { timeout: 100, method: 'notifyWhenChangesStop' } });
  subscriptions.push(filterChange);

  // Action to push the filter data into the store
  const pushFilterToVue = ([parameters, active]) =>
    app.hybrid.store.commit('filter/record', {
      key: filterKey,
      active,
      parameters,
    });

  subscriptions.push(filterChange.subscribe(pushFilterToVue));
  pushFilterToVue(filterChange());
};

export default async (filterKey) => {
  await essentialDataIsLoaded;
  const app = window.app;
  const ko = app.ko;

  if (app.currentRoute().projectId) {
    // Cleanup subs for previous filter key
    projectSubscriptions.forEach((s) => s.dispose());
    projectSubscriptions.length = 0;

    const projectId = ko.pureComputed(() => app.currentRoute().projectId);
    projectSubscriptions.push(projectId);

    projectSubscriptions.push(
      projectId.subscribe((projId) => newFilterSubscription(filterKey, projId)),
    );
    newFilterSubscription(filterKey, projectId());
  } else {
    newFilterSubscription(filterKey);
  }
};
