<template>
  <div class="TableViewRow" v-on="$listeners">
    <slot name="before" />
    <div
      v-for="column in enabledColumns"
      :key="column.id"
      :class="{
        TableViewRow__cell: true,
        [`TableViewRow__cell--${column.id}`]: true,
        'TableViewRow__cell--no-bottom-border': !bottomBorder,
        'TableViewRow__cell--sticky': column.left != null,
        'TableViewRow__cell--center': column.headerCellCenter,
      }"
      :style="{
        left: column.left != null ? `${column.left}px` : undefined,
        zIndex: column.left != null ? '4' : undefined,
        flex: `0 0 ${column.computedWidth}px`,
        maxWidth: `${column.computedWidth}px`,
      }"
    >
      <slot :name="`cell-${column.id}`">
        <div v-if="placeholder" class="TableViewRow__cell-placeholder" />
      </slot>
    </div>
    <slot name="after" />
  </div>
</template>

<script>
import { defineComponent } from 'vue-demi';
import { useTableView } from './useTableView';

export default defineComponent({
  name: 'TableViewRow',

  props: {
    bottomBorder: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { enabledColumns } = useTableView();

    return {
      enabledColumns,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';
@import '@tko/src/styles/variables/colors/_branding.colors.scss';

.TableViewRow {
  display: flex;
  background: $token-ui-white;

  &__cell {
    position: relative;
    background: inherit;
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: $token-size-px solid $neutral-50;

    &--no-bottom-border {
      border-bottom: 0;
    }

    &--sticky {
      position: sticky;
      z-index: 10 !important;
    }

    &--center {
      justify-content: center;
    }
  }

  &:hover {
    // TODO remove this style - it should be added by the client code as needed
    background: $neutral-10;
  }

  &__cell-placeholder {
    margin: auto 10px;
    width: 100%;
    height: 1em;
    border-radius: 0.5em;
    animation: placeholder-glow 0.8s linear infinite alternate;
  }
}

@keyframes placeholder-glow {
  from {
    background: #e5e6ee;
  }

  to {
    background: #e5e6ee66;
  }
}
</style>
