import { nextTick } from 'vue-demi';

export const isProductionBuild = document.cookie.includes('BYPASSCFK8S=1');

export async function goToWindows() {
  // set a short lived cookie to bypass routing to K8s projects backend
  const cookieDate = new Date();
  const daysToAdd = 1;
  cookieDate.setDate(cookieDate.getDate() + daysToAdd);
  document.cookie = `BYPASSCFK8S=1;path=/;expires=${cookieDate.toGMTString()}`;
  document.cookie = 'PROJFT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  document.cookie = 'PROJLB=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';

  await nextTick();
  window.location.reload();
}

export async function goToK8s() {
  // removes the bypass cookie
  document.cookie =
    'BYPASSCFK8S=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  document.cookie = 'PROJFT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
  document.cookie = 'PROJLB=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';

  await nextTick();
  window.location.reload();
}
