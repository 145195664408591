<template>
  <transition
    appear
    enter-active-class="duration-300 ease-out"
    enter-class="translate-y-4 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-4 opacity-0"
  >
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      class="
        f-modal
        text-left
        inline-block
        align-middle
        bg-white
        rounded-md
        overflow-hidden
        shadow-lg
        transform
        transition-all
        w-full
      "
      :class="modalWidthClass"
    >
      <div class="bg-white h-full">
        <!-- @slot Modal header -->
        <slot
          name="header"
          v-bind="{
            closeModal,
            persistent,
            shouldShowCloseButton,
            title,
          }"
        >
          <FModalHeader
            v-if="title"
            :close-modal="closeModal"
            :persistent="persistent"
            :should-show-close-btn="shouldShowCloseButton"
            :title="title"
          />
        </slot>

        <!-- @slot Modal content - this has no padding -->
        <slot
          name="content"
          v-bind="{
            closeModal,
            persistent,
            shouldShowCloseButton,
          }"
        >
          <FModalContent
            :class="{
              'pt-6': !$slots.header && !title,
              'pb-6': !$slots.footer,
            }"
          >
            <!-- @slot Modal body - this includes default padding -->
            <slot
              v-bind="{
                closeModal,
                persistent,
              }"
            />
          </FModalContent>
        </slot>
        <FModalFooter v-if="$slots.footer">
          <slot
            name="footer"
            v-bind="{
              closeModal,
              persistent,
            }"
          />
        </FModalFooter>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, onMounted, onUnmounted } from 'vue-demi';
import { useEventListener } from '@vueuse/core';
import { modalSizes, defaultModalSize } from './constants';
import FModalContent from './FModalContent.vue';
import FModalFooter from './FModalFooter.vue';
import FModalHeader from './FModalHeader.vue';

const modalWidthClassMap = {
  [modalSizes.XS]: 'max-w-[var(--token-c-modal-size-xs)]',
  [modalSizes.SM]: 'max-w-[var(--token-c-modal-size-sm)]',
  [modalSizes.MD]: 'max-w-[var(--token-c-modal-size-md)]',
  [modalSizes.LG]: 'max-w-[var(--token-c-modal-size-lg)]',
  [modalSizes.XL]: 'max-w-[var(--token-c-modal-size-xl)]',
  [modalSizes.XXL]: 'max-w-[var(--token-c-modal-size-xxl)]',
};

export default {
  components: {
    FModalFooter,
    FModalHeader,
    FModalContent,
  },
  props: {
    /**
     * The modal title.
     */
    title: { type: String, default: '' },

    /**
     * Whether the user can close the modal or not.
     */
    persistent: { type: Boolean, default: false },

    /**
     * Whether the close button is shown or not
     */
    shouldShowCloseButton: { type: Boolean, default: true },

    /**
     * The size of the modal
     */
    size: {
      type: String,
      default: defaultModalSize,
      validator: (size) => Object.values(modalSizes).includes(size),
    },
  },
  setup(props, { emit }) {
    const modalWidthClass = computed(() => modalWidthClassMap[props.size]);

    onMounted(() => {
      document.body.classList.add('overflow-hidden');
    });

    const closeModal = (canClosePersistent = false) => {
      if (!props.persistent || canClosePersistent) {
        emit('close');
      }
    };

    const handleEscKey = (event) => {
      // TODO: Check if foremost modal
      if (event.code === 'Escape') {
        closeModal();
      }
    };

    useEventListener(document, 'keyup', handleEscKey, { passive: true });

    onUnmounted(() => {
      document.body.classList.remove('overflow-hidden');
    });

    return {
      closeModal,
      modalWidthClass,
    };
  },
};
</script>
