import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import Draggable from '@widgets/Draggable';
import EllipsisLabel from '@widgets/EllipsisLabel';

// Delay in ms between the dialog transition starting and the overlay transition starting
const ANIMATION_TIMEOUT = 150;

// @vue/component
export default {
  name: 'ModalDialog',
  components: {
    CommonIcon,
    Draggable,
    EllipsisLabel,
  },
  props: {
    name: { type: String, default: null },
    title: { type: String, required: false, default: '' },
    draggable: { type: Boolean, default: false },
    transition: { type: String, default: 'slide' },
    hasCloseIcon: { type: Boolean, default: true },
    hasHeader: { type: Boolean, default: true },
    hasFooter: { type: Boolean, default: true },
    headerIsBordered: { type: Boolean, default: true },
    headerIsShadowedOnScroll: { type: Boolean, default: false },
    callback: { type: Function, default: null },
    dataIdentifierPrefix: { type: String, default: 'undefined' },
  },
  data: () => ({
    active: false,
    visible: false,
    atTopOfModal: true,
  }),
  mounted() {
    this.active = true;
  },
  methods: {
    open() {
      this.visible = true;
      this.$emit('open');
    },
    close(opts) {
      if (this.callback) {
        this.callback(opts).then(() => {
          this.closeModal();
        });
      } else {
        this.closeModal();
      }
    },
    closeModal() {
      this.active = false;
      this.timeout = setTimeout(() => this.$emit('close'), ANIMATION_TIMEOUT);
    },
    handleScroll() {
      const dialogBodyElement = document.querySelector('.w-modal-dialog__body');
      if (dialogBodyElement.scrollTop > 0) {
        this.atTopOfModal = false;
      } else if (!this.atTopOfModal) {
        this.atTopOfModal = true;
      }
    },
  },
  destroyed() {
    clearTimeout(this.timeout);
  },
};
