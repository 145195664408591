import Vue from 'vue';
/**
 * Returns the number of minutes since a timestamp
 * @param {Number} time Timestamp against which we compare with now
 */
const getTimeDiff = (time) => (new Date() - time) / 1000 / 60;

export function minutesToHours(minutes) {
  return minutes / 60;
}

export function formatMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);

  if (Number.isNaN(hours) || Number.isNaN(mins)) {
    return '';
  }

  if (mins === 0) {
    return `${hours}${Vue.t('hours').charAt(0)}`;
  }

  if (hours === 0) {
    return `${mins}${Vue.t('minutes').charAt(0)}`;
  }

  return `${hours}${Vue.t('hours').charAt(0)} ${mins}${Vue.t('minutes').charAt(
    0,
  )}`;
}

/**
 * Returns the number of minutes since a timestamp for reports
 * Uses format of h/m
 * @param {Number} time Timestamp against which we compare with now
 */
export function formatMinutesReport(minutes = 0) {
  if (minutes === 0) {
    return '0h';
  }
  const positiveMinutes = Math.abs(minutes);
  const hours = Math.floor(positiveMinutes / 60);
  const mins = Math.round(positiveMinutes) % 60;
  const hoursText = `${hours}h`;
  const minutesText = mins !== 0 ? `${mins}m` : '';

  return [hoursText, minutesText].filter(Boolean).join(' ');
}

export function isTimeFormat(val) {
  // value should be XX:XX to pass with the range of hour and minutes 00:00 - 23:59
  return /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);
}

export function isValidTimeInput(val) {
  // check time is valid human format like 4am, 4 am, 4:00, 4:00am, 4.30 am, 4:00 pm, 16:00 etc
  return /^(([0-1]{0,1}[0-9]( )?[AaPp][Mm])|(([0]?[1-9]|1[0-2])(:|\.)[0-5][0-9]( )?[AaPp][Mm])|(([0]?[0-9]|1[0-9]|2[0-3])(:|\.)[0-5][0-9]))$/.test(
    val,
  );
}

// digits before ':', '.' or 'a', 'p'
const hrsRegex = /(\d+)(?=\s*(:|\.|[AaPp]))/i;
const getHrs = (time) => {
  const hrsMatches = hrsRegex.exec(time);
  return hrsMatches ? hrsMatches[0] : '00';
};

// digits after ':', '.'
const minsRegex = /((:|\.)\s*)(\d+)/i;
const getMins = (time) => {
  const minsMatches = minsRegex.exec(time);
  return minsMatches ? minsMatches[3] : '00';
};

// convert any human time like 4am, 4 am, 4:00, 4:00am, 4.30 am, 4:00 pm, 16:00 etc
// to 24 hrs format
const twelveHrFormatRegex = /[AaPp][Mm]/i;
export function humanTimeTo24Hrs(time = '') {
  const is12HrFormat = twelveHrFormatRegex.test(time);
  if (!is12HrFormat) {
    return time;
  }

  const isPM = /[Pp][Mm]/.test(time);
  let hrs = getHrs(time);
  hrs = parseInt(hrs, 10);
  // allow 12PM only
  if (hrs % 12 === 0) {
    hrs = !isPM ? '00' : '12';
  } else if (isPM) {
    hrs = parseInt(hrs, 10) + 12; // add 12 hrs if 'pm'
  }

  const mins = getMins(time);
  return `${hrs}:${mins}`;
}

/**
 * Given a user input in seconds, return the number of minutes and hours in the following format:
 * For example:
 *  - 363 -> 6:03
 *  - 2703 -> 45:03
 *
 * @param {number} seconds
 *
 */
export function formatSeconds(seconds) {
  const hrs = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0');
  const min = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const sec = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');

  return `${hrs > 0 ? `${hrs}:` : ''}${min}:${sec}`;
}

/**
 * Given a user input in hours/minutes, return the number of minutes
 * For example:
 *  - 1h 30m -> 90
 *  - 90m -> 90
 *  - 1.5 -> 90
 *
 * @param {string} input
 * @returns {number}
 */
export function convertUserInputToMinutes(input = '') {
  if (!input.trim()) {
    return 0;
  }
  const text = input.match(/\D/g);
  const grouped = Array.from(input.matchAll(/\d+/gi));

  if (!text) {
    return parseFloat(input) * 60;
  }

  if (text.length === 1 && text[0] === '.') {
    return Number(parseFloat(input * 60).toFixed(0));
  }

  // Just one group of digit (either min or sec)
  if (grouped.length === 1) {
    if (input.toLowerCase().includes('m')) {
      return Number(grouped[0][0]);
    }
    return Number(grouped[0][0] * 60);
  }

  if (grouped.length > 1) {
    const hours = parseInt(grouped[0][0], 10);
    const minutes =
      grouped[1] && grouped[1][0] ? parseInt(grouped[1][0], 10) : 0;
    return Number(hours * 60 + minutes);
  }
  return 0;
}

export default getTimeDiff;
