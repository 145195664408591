<template>
  <div
    class="TableViewBlock"
    :style="{
      width: `${tableWidth}px`,
    }"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue-demi';
import { useTableViewInternal } from './useTableViewInternal';

export default defineComponent({
  name: 'TableViewBlock',

  setup() {
    const { tableWidth } = useTableViewInternal();

    return {
      tableWidth,
    };
  },
});
</script>

<style lang="scss" scoped>
.TableViewBlock {
  position: sticky;
  left: 0;
}
</style>
