/* eslint-disable no-param-reassign */
import { useListLoader } from '@teamwork/use';
import { v3Url } from '@teamwork/fetcher';
import { ref, computed, watch } from 'vue-demi';
import { isEqual } from 'lodash';
import savedFiltersAPI from '@/platform/data/savedFilters';
import useStore from '@/platform/composables/useStore';
import useTranslations from '@/platform/composables/useTranslations';
import { copyToClipboard } from '@/utils/helpers/clipboard';

// This is used as an override of the serverside rendered filters
// because if the user change filters then go back to this section
// we would load the default filters from the initial data when
// we should load the last applied filters
const defaultFilters = {};
const cachedValues = {};

// @vue/component
export default function useSavedFilters(
  defaultParams = {},
  filterMeta = {},
  params = ref({}),
  paramNameMapping = {},
  defaultFiltersNameMapping = {},
  clearAdvanced,
) {
  const defaultMeta = {
    isDefault: true,
    title: '',
    description: '',
    color: '',
    fulldata: null,
    isTemporary: true,
    isProjectSpecific: false,
    includesSort: false,
    displayOrder: 0,
    useRandomColorIfEmpty: false,
  };
  const filtersMeta = { ...defaultMeta, ...filterMeta };
  const filterId = ref(0);
  const store = useStore();
  const $t = useTranslations();
  let skipAutoSave = false;
  const savingFilter = ref(false);

  /**
   * This method will populate the cachedValues object.
   * @elementsToAdd will either be an obejct whose fields are
   * arrays (i.e. the param object) or an object whose fields
   * are objects, with their key being an id (i.e. info coming from the API)
   */
  function addElementsToCache(elementsToAdd) {
    const processedElelements = { ...elementsToAdd };
    Object.keys(paramNameMapping).forEach((paramName) => {
      if (!Array.isArray(processedElelements[paramName])) {
        const paramObject = elementsToAdd[paramName];
        processedElelements[paramName] = [];

        if (paramObject) {
          Object.keys(paramObject).forEach((element) => {
            processedElelements[paramName].push(paramObject[element]);
          });
        }
      }

      processedElelements[paramName].forEach((item) => {
        if (
          item &&
          cachedValues[filtersMeta.section] &&
          !cachedValues[filtersMeta.section][paramName].find((cacheItem) =>
            isEqual(cacheItem, item),
          )
        ) {
          cachedValues[filtersMeta.section][paramName].push(item);
        }
      });
    });
  }

  function paramsToApiFriendly(parameters) {
    const result = {};

    Object.keys(parameters).forEach((param) => {
      const val = parameters[param];
      const paramNameMappingKeys = Object.keys(paramNameMapping);
      const key = paramNameMappingKeys.includes(param)
        ? paramNameMapping[param]
        : param;
      let value = val;

      // Due to legacy stuff in the backend, this mapping is necessary
      if (Array.isArray(val)) {
        value =
          // Special treatment of Custom Fields
          key === 'customFields'
            ? JSON.stringify(val)
            : val.map((prop) => prop.id).join(',');
      } else if (typeof val === 'boolean') {
        value = val ? 'YES' : 'NO';
      }

      result[key] = value;
    });

    return result;
  }

  function fromAPIFriendlyToParams(apiParams) {
    const result = {};
    const inverseParamNameMapping = {};
    Object.keys(paramNameMapping).forEach((key) => {
      inverseParamNameMapping[paramNameMapping[key]] = key;
    });

    Object.keys(apiParams).forEach((param) => {
      const val = apiParams[param];

      // If the field is in the mapping list
      if (Object.keys(inverseParamNameMapping).includes(param)) {
        const mappedName = inverseParamNameMapping[param];
        // If there is cachedValues for this section
        // and also for that parameter within them,
        // make sure to load from them
        if (
          cachedValues[filtersMeta.section] &&
          cachedValues[filtersMeta.section][mappedName]
        ) {
          // Empty default
          if (!result[mappedName]) {
            result[mappedName] = [];
          }

          // Make sure it has a value, and handle if it has more than one value
          const vals = val && (Array.isArray(val) ? val : val.split(','));
          if (vals) {
            vals.forEach((value) => {
              const valueFromCache = cachedValues[filtersMeta.section][
                mappedName
              ].find((el) => Number(value) === Number(el.id));
              if (valueFromCache) {
                result[mappedName].push(valueFromCache);
              }
            });
          }
        } else {
          // If there is no value for that element in the included data, leave empty
          result[mappedName] = [];
        }
      } else if (val === 'YES' || val === 'NO') {
        // Due to legacy stuff in the backend, this mapping is necessary for booleans
        result[param] = val === 'YES';
      } else {
        // If the field is not in the mapping list, add the plain value
        result[param] = val;
      }
    });

    return result;
  }

  const v1Tov3Filter = (input) => {
    const legacyFilter = window.ko.toJS(input);

    return {
      id: legacyFilter.id,
      title: legacyFilter.title,
      description: legacyFilter.description,
      color: legacyFilter.color,
      fulldata: legacyFilter.fulldata || cachedValues[filtersMeta.section],
      isTemporary: legacyFilter.isTemporary,
      includesSort: legacyFilter.includesSort,
      displayOrder: legacyFilter.displayOrder,
      parameters: fromAPIFriendlyToParams(legacyFilter.parameters),
    };
  };

  function responseToItems(response) {
    const result = [];
    addElementsToCache(response.data.included);

    response.data.savedfilters.forEach((savedFilter) => {
      result.push({
        ...savedFilter,
        isTemporary: savedFilter.temporary,
        parameters: fromAPIFriendlyToParams(savedFilter.parameters),
      });
    });

    return result.filter((filter) => filter.title);
  }

  const updateDefaultFilterLocally = (newFilter) => {
    defaultFilters[filtersMeta.section] = {
      ...v1Tov3Filter(newFilter),
      id: filterId.value,
    };
  };

  const {
    state: { items: savedFilters },
    loading,
    refresh,
  } = useListLoader({
    url: v3Url('me/savedfilters'),
    params: {
      include: filtersMeta.include,
      sections: filtersMeta.section,
      fulldata: true,
      showDeleted: false,
      includeTemporary: true,
      orderBy: 'displayOrder',
      orderMode: 'desc',
    },
    count: Infinity,
    responseToItems,
  });

  /**
   * This is used to show the Update button instead of save when a saved filter is applied
   */
  const canUpdateExisting = computed(() => {
    const found = savedFilters.value.find(
      (filter) => Number(filter.id) === filterId.value,
    );
    return found && !found.isTemporary;
  });

  const payload = () => {
    const shouldSaveOrder = filtersMeta.includesSort || !filtersMeta.title;
    const apiFriendlyParams = paramsToApiFriendly(params.value);

    return {
      savedFilter: {
        title: filtersMeta.title,
        description: filtersMeta.description,
        color: filtersMeta.color,
        isTemporary: !filtersMeta.title,
        includesSort: filtersMeta.includesSort,
        displayOrder: filtersMeta.displayOrder,
        section: filtersMeta.section,
        parameters: {
          ...apiFriendlyParams,
          sortBy: shouldSaveOrder
            ? params.value.orderBy
            : defaultParams.orderBy,
          sortOrder: shouldSaveOrder
            ? params.value.orderMode
            : defaultParams.orderMode,
        },
      },
    };
  };

  const saveFilter = ({ name = '', isNew = false, savedFilter = {} } = {}) => {
    // prevent empty filters
    if (!(name || savedFilter.title) && !savedFilter.isTemporary) {
      return;
    }

    if (name) {
      filtersMeta.title = name;
    }

    savingFilter.value = true;
    savedFiltersAPI
      .saveV3(payload(), isNew ? 0 : Number(filterId.value))
      .then(({ data }) => {
        if (data.savedfilter.id) {
          filterId.value = Number(data.savedfilter.id);
          updateDefaultFilterLocally(payload().savedFilter);
        }
        if (isNew) {
          store.dispatch(
            'notifications/flashes/success',
            $t('[0] filter has been created', filtersMeta.title),
          );
        }

        refresh();
      })
      .finally(() => {
        skipAutoSave = false;
        savingFilter.value = false;
      });
  };

  const clearSavedFilter = () => {
    skipAutoSave = true;
    filterId.value = 0;
    Object.assign(filtersMeta, { ...defaultMeta, ...filterMeta });

    clearAdvanced();
    saveFilter({ savedFilter: { isTemporary: true } });
  };

  const deleteFilter = (filter) =>
    savedFiltersAPI.deleteItemV3(filter.id).then(() => {
      if (filterId.value === Number(filter.id)) {
        clearSavedFilter();
      }
      store.dispatch(
        'notifications/flashes/success',
        $t('[0] filter has been deleted', filter.title),
      );
      refresh();
    });

  const repositionSavedFilters = (positionAfter, id) =>
    savedFiltersAPI.repositionV3(positionAfter, id).then(() => {
      refresh();
    });

  const copyFilter = (filter) => {
    copyToClipboard(filter.shareLink);
    store.dispatch('notifications/flashes/success', $t('Copied to clipboard!'));
  };

  const editFilter = async (data) => {
    // Clean the object, the API is expecting something quite specific
    const filterToEdit = {
      title: data.title,
      description: data.description,
      color: data.color,
      isTemporary: data.isTemporary,
      includesSort: data.includesSort,
      displayOrder: data.displayOrder,
      section: data.section,
      projectId: data.projectId,
      isDefault: data.default,
      isProjectSpecific: data.projectSpecific,
      parameters: {
        ...paramsToApiFriendly(data.parameters),
        sortBy: data.includesSort
          ? params.value.orderBy
          : defaultParams.orderBy,
        sortOrder: data.includesSort
          ? params.value.orderMode
          : defaultParams.orderMode,
      },
    };

    filtersMeta.title = filterToEdit.title;

    savedFiltersAPI
      .editV3({ savedFilter: filterToEdit }, Number(data.id))
      .then(refresh);
  };

  const openEditFilterModal = (filter) => {
    store.dispatch('modals/open', {
      name: 'save-filter-modal',
      props: {
        filter,
        showIncludeSort: filtersMeta.showIncludeSort,
        save: (filterToSave) => editFilter({ ...filterToSave.savedFilter }),
      },
    });
  };

  const applyFilter = (filter) => {
    skipAutoSave = true;
    filterId.value = Number(filter.id);

    filtersMeta.title = filter.title;
    filtersMeta.description = filter.description;
    filtersMeta.color = filter.color;
    filtersMeta.fulldata = filter.fulldata || cachedValues[filtersMeta.section];
    filtersMeta.isTemporary = filter.isTemporary;
    filtersMeta.includesSort = filter.includesSort;
    filtersMeta.displayOrder = filter.displayOrder;

    params.value = { ...params.value, ...filter.parameters };

    if (Number(filterId.value)) {
      savedFiltersAPI
        .setAsDefaultV3(
          filter.id,
          filtersMeta.isTemporary,
          filtersMeta.section,
          paramsToApiFriendly(filter.parameters),
        )
        .finally(() => {
          skipAutoSave = false;
        });
    }
    updateDefaultFilterLocally({
      ...payload().savedFilter,
      fulldata: filter.fulldata,
    });
  };

  /**
   * default filters are server side rendered so we need to access the window object.
   */
  const loadDefaultFilter = () => {
    if (defaultFilters && defaultFilters[filtersMeta.section]) {
      applyFilter(defaultFilters[filtersMeta.section]);
    } else {
      const legacyFilter =
        window.app.loggedInUser.defaultFilters[filtersMeta.section];
      if (legacyFilter) {
        const dataWithMappedKeys = {};
        const rawData = legacyFilter.fulldata;
        const mappingKeys = Object.keys(defaultFiltersNameMapping);

        Object.keys(rawData).forEach((param) => {
          dataWithMappedKeys[
            mappingKeys.includes(param)
              ? defaultFiltersNameMapping[param]
              : param
          ] = legacyFilter.fulldata[param];
        });

        addElementsToCache(dataWithMappedKeys);
        applyFilter(v1Tov3Filter(legacyFilter));
      }
    }
  };

  watch(
    params,
    async () => {
      if (!filtersMeta.title && !skipAutoSave) {
        filtersMeta.isTemporary = true;
        if (filterId.value) {
          savedFiltersAPI.editV3(payload(), Number(filterId.value));
        } else {
          const { data } = await savedFiltersAPI.saveV3(payload());
          if (data.savedfilter.id) {
            filterId.value = Number(data.savedfilter.id);
          }
        }
      }
      addElementsToCache(params.value);
      // save as default filters
      updateDefaultFilterLocally(payload().savedFilter);
    },
    { deep: true },
  );

  Object.keys(defaultParams).forEach((defParKey) => {
    if (Array.isArray(defaultParams[defParKey])) {
      if (!cachedValues[filtersMeta.section]) {
        cachedValues[filtersMeta.section] = {};
      }
      if (!cachedValues[filtersMeta.section][defParKey]) {
        cachedValues[filtersMeta.section][defParKey] = [];
      }
    }
  });
  loadDefaultFilter();

  return {
    params,
    filterId,
    filtersMeta,
    savedFilters,
    canUpdateExisting,
    prepareFilterPayload: payload,
    loading,
    saveFilter,
    deleteFilter,
    repositionSavedFilters,
    copyFilter,
    openEditFilterModal,
    editFilter,
    applyFilter,
    clearSavedFilter,
    defaultFilters,
    cachedValues,
    savingFilter,
  };
}
