import Vue from 'vue';

const typesConfig = {
  get activity() {
    return Vue.t('Activity');
  },
  get backlog() {
    return Vue.t('Backlog');
  },
  get board() {
    return Vue.t('Board');
  },
  get boards() {
    return Vue.t('Boards');
  },
  get clockIn() {
    return Vue.t('Clock-Ins');
  },
  get comment() {
    return Vue.t('Comments');
  },
  get company() {
    return Vue.t('Companies');
  },
  get dashboard() {
    return Vue.t('Dashboard');
  },
  get event() {
    return Vue.t('Events');
  },
  get file() {
    return Vue.t('Files');
  },
  get link() {
    return Vue.t('Links');
  },
  get loginHistory() {
    return Vue.t('Login History');
  },
  get message() {
    return Vue.t('Messages');
  },
  get milestone() {
    return Vue.t('Milestones');
  },
  get notebook() {
    return Vue.t('Notebooks');
  },
  get people() {
    return Vue.t('People');
  },
  get breakdown() {
    return Vue.t('Breakdown');
  },
  get plan() {
    return Vue.t('Plans');
  },
  get project() {
    return Vue.t('Projects');
  },
  get projectReport() {
    return Vue.t('Project Report');
  },
  get risk() {
    return Vue.t('Risks');
  },
  get shortcut() {
    return Vue.t('Shortcuts');
  },
  get status() {
    return Vue.t('Statuses');
  },
  get tag() {
    return Vue.t('Tags');
  },
  get task() {
    return Vue.t('Tasks');
  },
  get taskList() {
    return Vue.t('Task Lists');
  },
  get time() {
    return Vue.t('Time Entries');
  },
  get workload() {
    return Vue.t('Workload');
  },
  get payment() {
    return Vue.t('Payment');
  },
};

// @vue/component
export default typesConfig;
