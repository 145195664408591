<template>
  <div>
    <v-popover
      class="log-time-time-popover"
      trigger="click"
      :open.sync="isOpen"
      :auto-hide="!isDateInputOpen"
      popover-class="logtime-popover-wrapper"
      placement="bottom"
      offset="0"
      popover-arrow-class="hidden"
      boundaries-element="body"
      @apply-show="onApplyShow"
      @apply-hide="$emit('toggle', false)"
      :disabled="disabled"
    >
      <slot />
      <template slot="popover">
        <form @submit.prevent="onSubmit" class="logtime-popover">
          <h3 class="header-title mb-16">
            {{ $t('Log time on task') }}
          </h3>

          <div class="flex start">
            <label class="label" for="logtime-date">
              {{ $t('Date') }}
            </label>
          </div>
          <div class="flex start mb-16">
            <DateInput
              :date="date"
              :no-arrow="true"
              size="sm"
              variant="filled"
              @change="date = $event"
              @toggle="isDateInputOpen = $event"
            />
          </div>

          <div class="flex start">
            <div class="mr--large">
              <label class="label">
                {{ $t('Start Time') }}
              </label>
              <input
                type="text"
                v-model="startTime"
                :class="{ error: $v.startTime.$invalid }"
                class="input w-120"
                placeholder="00:00"
                maxlength="8"
                @input="onStartTimeUpdate"
                @blur="autoCorrectStartTime"
              />
            </div>
            <div>
              <label class="label">
                {{ $t('End Time') }}
              </label>
              <input
                type="text"
                v-model="endTime"
                :class="{ error: $v.endTime.$invalid }"
                class="input w-120"
                placeholder="00:00"
                maxlength="8"
                @input="onEndTimeUpdate"
                @blur="autoCorrectEndTime"
              />
              <div class="logtime-popover__end-date">
                <span v-show="!$v.endTime.$invalid && !isEndingOnSameDay">
                  {{ formattedEndDate }}
                </span>
              </div>
            </div>
          </div>

          <div class="flex middle mb-8">
            <label class="label mb-0">
              {{ $t('Time Spent') }}
            </label>
          </div>

          <TimerInputs
            :key="timerInputsKey"
            :hours.sync="hours"
            :minutes.sync="minutes"
            :is-form-valid.sync="isTimerInputsValid"
            :is-billable.sync="isBillable"
            :accelerator-options="acceleratorOptions"
            @update:hours="onHourMinuteUpdate"
            @update:minutes="onHourMinuteUpdate"
            autofocus
          />

          <div class="flex mb-24">
            <textarea
              class="textarea"
              v-model="description"
              :placeholder="$t('Add a Description')"
            />
          </div>

          <div class="flex space-between">
            <Button
              @click="isOpen = false"
              type="button"
              class="btn btn-default border--none"
            >
              {{ $t('Cancel') }}
            </Button>

            <Button
              type="submit"
              class="btn btn-primary"
              :class="{ disabled: !isValid }"
            >
              {{ $t('Log time') }}
            </Button>
          </div>
        </form>
      </template>
    </v-popover>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import DateInput from '@widgets/DateInput/DateInput';
import TimerInputs from '@widgets/TimerInputs/TimerInputs';
import { isValidTimeInput, humanTimeTo24Hrs } from '@/utils/helpers/time';
import { ensureMoment } from '@/utils/helpers/date';

import useExperimentA15 from '@/platform/composables/useExperimentA15';
import usePendo from '@/platform/composables/usePendo';

// @vue/component
export default {
  name: 'LogTimeLoggerPopover',
  components: {
    DateInput,
    TimerInputs,
  },
  props: {
    open: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    task: { type: Object, default: () => {} },
    source: { type: String, default: '' },
    acceleratorOptions: {
      type: Array,
      default: () => [],
      validator: (prop) =>
        prop.every(
          (p) => Number.isInteger(p.hours) && Number.isInteger(p.minutes),
        ),
    },
  },
  data() {
    return {
      date: null,
      startTime: null,
      endTime: null,
      hours: 1,
      minutes: 0,
      isBillable: this.defaultBillable(),
      description: '',
      isHovering: false,
      isDateInputOpen: false,
      isTimerInputsValid: false,
      timerInputsKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      dateFormat: 'user/dateFormat',
      timeFormat: 'user/timeFormat',
    }),
    timeDiff() {
      const minsInHours = this.hours * 60;
      return Number(minsInHours) + Number(this.minutes);
    },
    isOpen: {
      get() {
        return this.open;
      },
      set(newVal) {
        this.$emit('update:open', newVal);
      },
    },
    startTime24Hrs() {
      return this.startTime ? humanTimeTo24Hrs(this.startTime) : '00:00';
    },
    endTime24Hrs() {
      return this.endTime ? humanTimeTo24Hrs(this.endTime) : '00:00';
    },
    startEndTimeDiff() {
      if (!this.startTime || !this.endTime) {
        return 0;
      }

      const [startHrs, startMins] = this.startTime24Hrs.split(':');
      const [endHrs, endMins] = this.endTime24Hrs.split(':');
      const startHrsInt = startHrs / 1;
      const endHrsInt = endHrs / 1;

      const hrsDiff =
        startHrsInt > endHrsInt
          ? 24 - startHrsInt + endHrsInt
          : endHrsInt - startHrsInt;
      const minsDiff = (endMins || 0) - (startMins || 0);

      return hrsDiff * 60 + minsDiff;
    },
    endDate() {
      const [startHrs, startMins] = this.startTime24Hrs.split(':');
      const date = ensureMoment(this.date)
        .clone()
        .set({ hour: startHrs || 0, minutes: startMins || 0, seconds: 0 });

      return date.add(this.timeDiff, 'minutes');
    },
    formattedEndDate() {
      return this.endDate.clone().format(this.dateFormat); // format to user locale
    },
    isEndingOnSameDay() {
      return this.endDate.isSame(ensureMoment(this.date).startOf('day'), 'day');
    },
    isValid() {
      // hours and minutes can't be 0 at the same time
      return !this.$v.$invalid && !(this.minutes === 0 && this.hours === 0);
    },
  },
  methods: {
    // set hrs and mins diff between start and end time
    setHoursMinutes() {
      this.hours = Number(Math.floor(this.startEndTimeDiff / 60));
      this.minutes = Number(this.startEndTimeDiff % 60);
    },
    autoCorrectStartTime() {
      if (this.$v.startTime.$invalid) {
        return;
      }
      this.startTime = moment(this.startTime24Hrs, 'HH:mm').format(
        this.timeFormat,
      );
    },
    defaultBillable() {
      if (this.task?.taskList?.isBillable != null) {
        return this.task.taskList.isBillable;
      }

      return this.task?.project?.isBillable || false;
    },
    autoCorrectEndTime() {
      if (this.$v.endTime.$invalid) {
        return;
      }
      this.endTime = moment(this.endTime24Hrs, 'HH:mm').format(this.timeFormat);
    },
    onStartTimeUpdate() {
      if (this.$v.startTime.$invalid) {
        return;
      }

      this.setHoursMinutes();
    },
    onEndTimeUpdate() {
      if (this.$v.endTime.$invalid) {
        return;
      }
      this.setHoursMinutes();
    },
    onHourMinuteUpdate() {
      if (this.$v.startTime.$invalid) {
        return;
      }

      const [startHour, startMinute] = this.startTime24Hrs.split(':');
      this.endTime = moment()
        .hours(Number(startHour))
        .minutes(Number(startMinute))
        .add(Number(this.hours), 'hours')
        .add(Number(this.minutes), 'minutes')
        .format(this.timeFormat);
    },
    resetFields() {
      const now = new Date();
      this.date = moment(now);
      this.hours = 1;
      this.minutes = 0;
      this.endTime = moment(now).format(this.timeFormat);
      this.startTime = moment(now)
        .subtract(Number(this.minutes), 'minutes')
        .subtract(Number(this.hours), 'hours')
        .format(this.timeFormat);
      this.description = '';
      this.isBillable = this.defaultBillable();
    },
    onApplyShow() {
      this.$emit('toggle', true);
      this.timerInputsKey += 1;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.isValid) {
        const data = {
          hours: Number(this.hours),
          minutes: Number(this.minutes),
          date: moment(this.date).format('YYYYMMDD'),
          time: this.startTime24Hrs,
          description: this.description,
          isBillable: this.isBillable,
          'time-default-timeSpent': this.timeDiff,
        };
        this.$emit('update', data);
        this.trackSubmit();
        this.isOpen = false;
        setTimeout(() => {
          this.resetFields();
        }, 100);
      }
    },
  },
  setup(props) {
    const { EXP_A15_COMMON_METRICS, isExpA15Variation } = useExperimentA15();
    const { trackPendoEvent } = usePendo();

    const trackSubmit = () => {
      trackPendoEvent({
        eventName: 'TIME_LOG_EVENT',
        commonMetrics: EXP_A15_COMMON_METRICS,
        metadata: {
          event_action: 'time_logged',
          event_source: props.source,
        },
      });
    };

    return {
      isExpA15Variation,
      trackSubmit,
    };
  },
  mounted() {
    this.resetFields();
  },
  validations: {
    startTime: {
      required,
      isValidTimeInput,
    },
    endTime: {
      required,
      isValidTimeInput,
    },
  },
};
</script>

<style lang="scss">
.log-time-time-popover,
.log-time-time-popover div {
  height: 100%;
}
</style>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

$font-size--base: 14px;
$input-height: 32px;
$error-color: #e02020;

.flex {
  display: flex;
  &.center {
    justify-content: center;
  }

  &.middle {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.start {
    justify-content: flex-start;
  }
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-4 {
  margin-left: 4px;
}

.mt-8 {
  margin-top: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}

.mt-12 {
  margin-top: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}

.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}

.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}

.w-160 {
  width: 160px;
}
.w-49 {
  width: 49px;
}
.w-120 {
  width: 120px;
}

.logtime-popover {
  height: 100%;
  padding: 24px 24px 16px;
  min-width: 308px;
  width: auto;

  .header-title {
    font-weight: 600;
    font-size: $font-size--medium;
    color: $color--text-tinted-dark;
  }

  .line {
    border-top: 1px solid #f5f7fa;
  }

  .input {
    height: $input-height;
    font-size: $font-size--base;
    background-color: $neutral-20;
    margin-right: $margin--tiny;
    padding: 0 12px;
    font-weight: normal;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;

    &.ta-l {
      text-align: left;
    }

    &.ta-r {
      text-align: right;
    }

    &[type='number'] {
      appearance: none;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    &.error {
      border: 1px solid $error-color;
    }

    &:focus {
      outline: none;
      border: 1px solid $indigo-30;
      &.error {
        border: 1px solid $error-color;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 600;
    color: #0b0e1f;
    display: block;
    text-align: left;
    padding-left: 0;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
  }

  .textarea {
    width: 100%;
    height: 80px;
    background-color: #f5f7fa;
    resize: none;
    padding: 12px;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
    &:focus {
      outline: none;
      border: 1px solid $indigo-30;
    }
  }

  &__end-date {
    font-size: 12px;
    font-weight: normal;
    padding: 2px 12px;
    height: 16px;
    color: #aaa;
  }
}

::v-deep {
  .w-date-input__input {
    width: 125px;
  }
}
</style>
