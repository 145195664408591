import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import api from '@/services/api';
import { plugin as SocketNotificationService } from '@/services/socket-notifications';
import { registerStore as VuexDynamicWatches } from '@/services/vuex-dynamic-watches';
import { registerStore as VuexVisibleData } from '@/services/vuex-visible-data';
import VueAssign from '@/utils/plugins/assign';
import VuexDebug from '@/store/utils/debug';
import scaffoldingWatchers from '@/scaffolding/store-watchers';
import scaffoldingActionSubscriber from '@/scaffolding/action-subscriber';
import scaffoldingMutationSubscriber from '@/scaffolding/mutation-subscriber';

// Needs to be called prior to usage of moment.js within the Store
import '@/services/reactive-moment';

import modules, { watchers } from './modules';
import state from './state';
import mutations from './mutations';
import WhenUtil from './utils/when';

Vue.use(VueAssign);
Vue.use(Vuex);

Vue.config.devtools = window.devMode;

const plugins = [SocketNotificationService];
// Debug setup
if (window.devMode) {
  plugins.push(VuexDebug);
}

const store = new Store({
  strict: window.devMode,
  plugins,
  state,
  modules,
  mutations,
});

// Handy watcher shortcut
store.when = WhenUtil;

api.registerStore(store);
VuexDynamicWatches(store);
VuexVisibleData(store);

if (watchers && watchers.length) {
  watchers.forEach((watcher) => watcher(store));
}

// TODO Hybrid - scaffolding
setTimeout(() => {
  scaffoldingWatchers.forEach((watcher) => watcher(store));
  store.subscribeAction({
    after: scaffoldingActionSubscriber,
  });
  store.subscribe(scaffoldingMutationSubscriber);
}, 0);

if (module.hot) {
  module.hot.accept(['./modules'], () => {
    store.hotUpdate({
      modules: require('./modules').default, // eslint-disable-line
    });
  });
}

export default store;
