import typedDefaults from '@/utils/helpers/typed-defaults';
import {
  createListLoader,
  createRecordLoader,
  API,
} from '@/store/utils/loader';

const defaultTypes = {
  canEdit: true,
  companyId: '',
  companyName: '',
  content: '',
  description: '',
  distributedMinutes: '',
  dueDate: '',
  estimatedMinutes: '',
  id: '',
  isPrivate: '',
  lockdownId: '',
  parentTaskId: '',
  priority: '',
  progress: '',
  projectId: '',
  projectName: '',
  prorataCalculatedDays: '',
  prorataDueDate: '',
  prorataMinutes: '',
  prorataStartDate: '',
  prorataTotalDays: '',
  responsiblePartyId: '',
  responsiblePartyNames: '',
  responsiblePartySummary: '',
  startDate: '',
  status: '',
  taskListId: '',
  taskListName: '',
  taskLockedToUserIds: '',
  totalTimeLogged: '',
  totalTimeLoggedByAnyone: '',
};

export default {
  namespaced: true,
  modules: {
    loader: createRecordLoader({
      config: {
        url: () => `${API.v3}/workload/tasks.json`,
      },
    }),
    filtered: createListLoader({
      namespaced: true,
      state: {
        userId: 0,
        startDate: '',
        endDate: '',
        projectIds: null,
      },
      config: {
        module: 'workloadTasks',
        url: '/workload/tasks.json',
        mapResponse: (ctx, rs) => rs.data.tasks,
        params: ({ workloadTasks: { filtered } }) => ({
          responsiblePartyIds: filtered.userId,
          startDate: filtered.startDate.replace(/-/g, ''),
          endDate: filtered.endDate.replace(/-/g, ''),
          projectId: filtered.projectIds,
          taskStatus: 'active',
          sortBy: 'user',
          includeTasksWithoutDates: false,
          distributeEstimatedTimeToAssignees: true,
          prorataEstimatedTime: true,
          onlyTasksWithStartDates: true,
        }),
      },
      mutations: {
        search: (state, search) => {
          state.userId = search.userId;
          state.startDate = search.startDate;
          state.endDate = search.endDate;
          state.projectIds = search.projectIds;
        },
      },
    }),
  },
  state: {
    records: {},
  },
  mutations: {
    records(state, tasks) {
      const newTasks = {};
      tasks.forEach((task) => {
        const withDefaults = typedDefaults(defaultTypes, task);
        newTasks[task.id] = withDefaults;
      });

      state.records = newTasks;
    },
  },
};
