<template>
  <div @click="openQuickView" class="table-cell-task-quick-view">
    <a :href="taskUrl">
      <span>{{ $t('Details') }}</span>
    </a>
  </div>
</template>

<script>
// @vue/component
export default {
  name: 'QuickView',
  display: 'QuickView',
  props: {
    taskId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openQuickView(e) {
      this.$emit('click', e);
    },
  },
  computed: {
    taskUrl() {
      return `#/tasks/${this.taskId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell-task-quick-view {
  display: none;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-right: 12px;
  a {
    cursor: pointer;
    color: #4461d7;
    font-size: 13px;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
