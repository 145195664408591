<template>
  <div
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    :class="[
      { 'table-cell-date': !fromTaskDetails },
      color,
      { 'is-open': isOpen },
      { disabled },
    ]"
  >
    <template v-if="disabled && disabledTooltip">
      <div
        class="relative"
        v-tooltip="{
          content: disabledTooltip,
          classes: 'w-tooltip table-cell-date-disabled-tooltip',
          'handle-resize': false,
        }"
      >
        <template v-if="text">
          {{ text }}
        </template>
        <empty-state-cell v-else />
      </div>
    </template>

    <template v-else>
      <v-popover
        :hide-on-target-click="false"
        :auto-hide="isMonthYearPopoverOpen"
        :open.sync="isOpen"
        placement="bottom"
        popover-arrow-class="hidden"
        :popper-options="popperOptions"
      >
        <div
          :tabindex="!disabled ? 0 : -1"
          :class="[
            'date-label',
            fromTaskDetails ? 'task-details' : 'tasks-table',
            { 'is-open': isOpen },
          ]"
          :style="{ textAlign: alignText }"
          @keydown.tab="isOpen = false"
          @keydown.enter.space.prevent="togglePopover"
          @click="togglePopover"
        >
          <template v-if="!text">
            <AddButton v-if="fromTaskDetails">
              {{ $t('Add date') }}
            </AddButton>
            <span v-else-if="placeholder" class="placeholder">
              {{ placeholder }}
            </span>
            <span v-else-if="isHovering" class="fallback-label">
              {{ hoverLabelWithFallback }}
            </span>
            <empty-state-cell v-else />
          </template>
          <template v-else>
            <span class="value">{{ text }}</span>
            <div v-if="!fromTaskDetails" class="edit-icon__wrapper">
              <CommonIcons color="#555555" class="edit-icon" id="edit" />
            </div>
          </template>
        </div>

        <template v-if="isOpen" slot="popover">
          <ProjectTemplateOffsetDayOptions
            v-if="isProjectTemplate"
            :date="date"
            @change="onDateChange"
          />
          <DatePicker
            v-else
            @input="onDateChange"
            :value="date"
            :show-default-shortcuts="quickSelect.length === 0"
            :shortcuts="quickSelect"
            v-bind="{ ...pickerOptions }"
            @monthYearMenuVisibilityChange="isMonthYearPopoverOpen = $event"
          />
        </template>
      </v-popover>
    </template>
  </div>
</template>

<script>
import AddButton from '@widgets/TaskDetailWidgets/AddButton';
import { computed, watch, ref, shallowRef } from 'vue-demi';
import moment from 'moment';
import DatePicker from '@widgets/DatePicker';
import SupersetIcon from '@teamwork/common-icons/dist/v-icon';
import EmptyStateCell from '@widgets/EmptyStateCell';
import ProjectTemplateOffsetDayOptions from '@widgets/ProjectTemplateOffsetDayOptions';
import { useLocalization } from '@/platform/composables/useLocalization';
import useTranslations from '@/platform/composables/useTranslations';
import useStore from '@/platform/composables/useStore';
import {
  getDaysFromEpoch,
  isToday,
  isTomorrow,
  isYesterday,
  inPast,
  sameYear,
} from '@/utils/helpers/date';

// @vue/component
export default {
  name: 'Date',
  display: 'Date',
  components: {
    AddButton,
    DatePicker,
    CommonIcons: SupersetIcon,
    EmptyStateCell,
    ProjectTemplateOffsetDayOptions,
  },
  props: {
    date: { type: Object, default: null },
    pickerOptions: { type: Object, default: null },
    quickSelect: { type: Array, default: () => [] },
    colored: { type: Boolean, default: true },
    hoverLabel: { type: String, default: undefined },
    noArrow: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledTooltip: { type: String, default: null },
    alignText: { type: String, default: 'center' },
    placeholder: { type: String, default: '' },
    showRelative: { type: Boolean, default: true },
    fromTaskDetails: { type: Boolean, default: false },
  },

  setup(props, { emit }) {
    const store = useStore();
    const { dateFormat } = useLocalization();
    const $t = useTranslations();

    const isHovering = ref(false);
    const isOpen = ref(false);
    const isMonthYearPopoverOpen = shallowRef(false);

    const isProjectTemplate = computed(() => {
      return store.getters['project/current/isTemplate'];
    });

    const text = computed(() => {
      if (!props.date || (props.date && !props.date.isValid())) {
        return '';
      }

      if (isProjectTemplate.value) {
        const offsetDays = getDaysFromEpoch(props.date);
        return $t('Day [0]', offsetDays + 1);
      }

      let format = '';
      if (dateFormat.value.substr(0, 2) === 'DD') {
        format = 'Do MMM';
      } else {
        format = 'MMM Do';
      }

      format += `${sameYear(props.date) ? '' : ', YYYY'}`;

      if (isToday(props.date)) {
        return $t('Today');
      }
      if (isTomorrow(props.date)) {
        return $t('Tomorrow');
      }
      if (isYesterday(props.date)) {
        return $t('Yesterday');
      }
      return props.date.format(format);
    });

    const color = computed(() => {
      if (!props.colored || isProjectTemplate.value) {
        return '';
      }

      if (!props.date || (props.date && !props.date.isValid())) {
        return 'muted';
      }

      if (inPast(props.date)) {
        return 'past';
      }
      if (isToday(props.date)) {
        return 'today';
      }
      if (isTomorrow(props.date)) {
        return 'tomorrow';
      }

      return '';
    });

    const hasDate = computed(() => {
      return moment.isMoment(props.date) && props.date.isValid();
    });

    const hoverLabelWithFallback = computed(() => {
      return typeof props.hoverLabel === 'string'
        ? props.hoverLabel
        : $t('Add date');
    });

    const shouldShowEmptyState = computed(() => {
      return !text && !isHovering.value && !isOpen.value;
    });

    const popperOptions = computed(() => ({
      modifiers: {
        hide: { enabled: true },
      },
    }));

    function onDateChange(date) {
      isOpen.value = false;
      emit('change', date);
    }

    function togglePopover() {
      isOpen.value = !isOpen.value;
    }

    watch(isOpen, (newVal) => {
      if (newVal) {
        emit('pickerOpen', true);
      } else {
        emit('pickerOpen', false);
      }
    });

    return {
      isHovering,
      isOpen,
      isProjectTemplate,
      text,
      color,
      hasDate,
      hoverLabelWithFallback,
      shouldShowEmptyState,
      popperOptions,
      onDateChange,
      togglePopover,
      isMonthYearPopoverOpen,
    };
  },
};
</script>

<style src="./Date.scss" lang="scss"></style>
