<template>
  <label class="text-default leading-none font-semibold text-text">
    <slot>
      {{ label }}
    </slot>
  </label>
</template>

<script>
export default {
  name: 'FLabel',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
