<template>
  <Panel
    :class="['checklist-panel']"
    :is-open="true"
    :is-fullscreen="false"
    :show-footer="true"
    @close="close"
    :show-fullscreen-button="false"
    :show-fullscreen-toggle="false"
    :clip-body="false"
    :can-outside-click-close="true"
    :show-close-button="true"
    :prevent-overflow="false"
    :has-backdrop="false"
    ref="panel"
  >
    <template #header>
      <h1 class="title">
        <span v-if="!isChecklistComplete">{{ $t('Getting Started') }}</span>
        <span v-if="isChecklistComplete">{{ $t('Well done!') }}</span>
      </h1>
    </template>
    <template #body>
      <div class="onboarding-checklist">
        <h3 class="onboarding-checklist__subtitle" v-if="!isChecklistComplete">
          {{ $t("Let's make Teamwork work for you") }}
        </h3>
        <h3 class="onboarding-checklist__subtitle" v-if="isChecklistComplete">
          {{ $t("You've made a great start with Teamwork") }}
        </h3>
        <div class="video-container">
          <div class="preview">
            <img
              @click="openVideoModal()"
              src="https://embed-ssl.wistia.com/deliveries/190308bd7643c0146865605b723d8b76427387d1.jpg?video_still_time=5"
              class="preview__thumbnail"
            />
            <button @click="openVideoModal()" class="preview__button">
              <ProjectsIcon id="play-solid" class="preview__icon" />
            </button>
          </div>
          <!-- <WistiaVideo video-id="ftiwzkyu0o" /> -->
        </div>
        <div class="onboarding-checklist__progress-bar">
          <span
            class="onboarding-checklist__progress-bar-fill"
            :style="{ width: taskProgress }"
          ></span>
        </div>
        <ul class="tasklist">
          <Spinner v-if="isLoading" class="tasklist__load-state" />
          <li v-for="(item, index) in checklistItems" :key="item.id">
            <ChecklistItem
              :item="item"
              :index="index"
              @click="handleChecklistItemClick(item)"
            />
          </li>
        </ul>
      </div>
    </template>
    <template #footer>
      <button
        @click="dismissOnboardingChecklist()"
        class="onboarding-checklist__dismiss-text"
      >
        {{ $t('Dismiss this guide forever') }}
      </button>
    </template>
  </Panel>
</template>

<script>
import { onMounted } from 'vue-demi';
import { mapState } from 'vuex';
import ProjectsIcon from '@teamwork/common-icons/dist/v-projects-icon';
import Panel from '@widgets/Panel';
import Spinner from '@widgets/Spinner';
import useOnboardingChecklist from '@/views/onboarding/composables/onboardingChecklist';
import useProductTour from '@/views/onboarding/composables/productTour';
import ChecklistItem from './ChecklistItem.vue';
import { useHybrid } from '@/platform/composables/useHybrid';

export default {
  components: {
    ProjectsIcon,
    Panel,
    Spinner,
    ChecklistItem,
  },
  props: {},
  setup() {
    const {
      checklistItems,
      taskProgress,
      isLoading,
      completedItems,
      isChecklistComplete,
      dismissOnboardingChecklist,
    } = useOnboardingChecklist();

    const { setActiveProductTourId, updateTourPreferences } = useProductTour();

    const { openModal } = useHybrid();

    function trackPendo() {
      if (!window.pendo || !window.pendo.track) {
        return;
      }
      window.pendo.track('ONBOARDING_CHECKLIST_COMPLETE');
    }

    onMounted(() => {
      if (isChecklistComplete) {
        trackPendo();
      }
    });

    return {
      checklistItems,
      taskProgress,
      isLoading,
      completedItems,
      setActiveProductTourId,
      isChecklistComplete,
      updateTourPreferences,
      openModal,
      dismissOnboardingChecklist,
    };
  },
  computed: {
    ...mapState('preferences', {
      userPreferences: 'user',
    }),
    ...mapState('user', {
      currentUserId: 'id',
    }),
  },
  methods: {
    async close() {
      this.$emit('close');
    },
    handleChecklistItemClick(checklistItem) {
      const addUser = 'addUser';
      if (checklistItem.type === addUser) {
        const inviteTeamChecklistItemId = 7;
        this.openModal('trialInviteUsers', {});
        this.updateTourPreferences(inviteTeamChecklistItemId);
        return;
      }
      this.setActiveProductTourId(checklistItem.id);
    },
    openVideoModal() {
      this.$store.dispatch('modals/open', {
        name: 'upgrade-video-modal',
        props: {
          videoId: 'ftiwzkyu0o',
          autoPlay: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.checklist-panel {
  ::v-deep .panel-header {
    padding: 0px 0px 0px 25px;
    height: 60px;
  }

  ::v-deep .panel {
    width: 25vw;
    min-width: 350px;
  }

  ::v-deep .panel-body {
    width: 25vw;
    min-width: 350px;
  }

  ::v-deep .panel-header {
    width: 25vw;
    min-width: 350px;
  }
}

.title {
  bottom: 0px;
  padding: 0px;
  margin-bottom: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  color: #0b0e1f;
  font-size: 22px;
}

.video-container {
  border-radius: 8px;
  overflow: hidden;
  flex: 0 0 auto;

  .preview {
    position: relative;

    &__thumbnail {
      width: 100%;
      cursor: pointer;
      filter: brightness(50%);
    }

    &__button {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      box-shadow: 0px 8px 24px rgba(11, 14, 31, 0.16);
      font-size: 16px;
      border: none;
      cursor: pointer;
      border-radius: 100px;
      animation: button_pulse 1200ms ease-out infinite;
    }
    &__icon {
      fill: #4ecd97;
      font-size: 84px;
    }
  }
}
$pulseColour: #ffffff;
@keyframes button_pulse {
  from {
    box-shadow: 0 0 0 0 rgba($pulseColour, 1);
  }
  to {
    box-shadow: 0 0 0 12px rgba($pulseColour, 0);
  }
}

.onboarding-checklist {
  display: flex;
  position: absolute;
  flex-direction: column;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  background: #fff;
  padding: 0px 25px 25px 25px;
  transition: 600ms;
  width: 100%;
  max-height: calc(100vh - 66px);

  &__subtitle {
    color: #646d7d;
    margin-bottom: 20px;
  }

  &__close-btn {
    background: transparent;
    border: none;
  }
  &__progress-bar {
    height: 12px;
    margin: 27px 0px 17px 0px;
    display: flex;
    width: 100%;
    background-color: #e1e6ee;
    border-radius: 24px;
  }
  &__progress-bar-fill {
    display: block;
    height: 12px;
    background-color: #4461d7;
    border-radius: 16px;
    transition: width 500ms ease-in-out;
  }
  &__dismiss-text {
    background: transparent;
    border: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: rgb(161, 161, 161);
  }

  .tasklist {
    padding: 0;
    overflow: auto;
    margin-top: 10px;
    flex: 1 1 auto;

    &__load-state {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}
</style>
