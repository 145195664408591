/* eslint-disable lines-between-class-members */
import { inject, provide } from 'vue-demi';

/**
 * @typedef {Object} KnowledgeBaseArticle a knowledge base article
 * @property {number} id
 * @property {string} Title article Title
 * @property {string} Description article description
 * @property {string} Contents article contents as a html string
 * @property {string} Slug article slug
 * @property {string} CategorySlug slug for containing category
 * @property {string} canonicalURL URL link to original article on support site
 * @property {string} status publish status of article (not available for article payload)
 * @property {string} createdAt article creation date as ISO string
 * @property {string} updatedAt article last updated date as ISO string
 */

/**
 * @typedef {Object} KnowledgeBaseListArticle a related article
 * @property {number} id
 * @property {number} CategoryID category id (not currently available)
 * @property {number} popularity popularity index, bigger is "better"
 * @property {string} title article title
 * @property {string} description article description (not currently available)
 * @property {string} slug article slug
 * @property {string} status publish status of article
 * @property {string} categorySlug slug for containing category
 * @property {string} canonicalURL URL link to original article on support site
 * @property {string} createdAt article creation date as ISO string
 * @property {string} updatedAt article last updated date as ISO string
 */

/**
 * @typedef {Object} KnowledgeBaseCategory a category
 * @property {number} id
 * @property {number} parentId
 * @property {number} numArticles how many articles in this category
 * @property {number} displayOrder integer order for displaying in a list
 * @property {string} name category title
 * @property {string} slug category slug
 * @property {boolean} DisplayOnDocHomepage if category is shown on the front page
 * @property {KnowledgeBaseCategory[]} subCategories array of nested categories
 */

/**
 * @typedef {Object} KnowledgeBaseSearchResult search result item
 * @property {number} id
 * @property {string} url full url string for article
 * @property {string} value article name string
 * @property {string} categorySlug category slug (parsed from url)
 * @property {string} articleSlug article slug (parsed from url)
 */

const useKnowledgeBaseApiSymbol = Symbol('useKnowledgeBaseApiSymbol');

function KnowledgeBaseApi({
  baseURL = 'https://support.teamwork.com',
  timeout = 10000,
}) {
  /** @type { KnowledgeBaseCategory[] } */
  let categories = [];

  /** @type { Map<string, KnowledgeBaseListArticle[] } */
  const articlesInCategory = new Map();

  /** @type { Map<string, {article: KnowledgeBaseArticle, relatedArticles: KnowledgeBaseListArticle[]}> } */
  const articles = new Map();

  // eslint-disable-next-line class-methods-use-this
  function getOptions() {
    return {
      method: 'GET',
      signal: AbortSignal.timeout(timeout),
      redirect: 'follow',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        twProjectsVer: window.appVersionId ?? '',
      }),
    };
  }

  /**
   * Sanitize the html string and do operations on it. Currently only replaces relative urls with
   * absolute urls using the baseURL provided
   * @param {string} contentString string of valid html, will be converted to actual document fragment
   * @returns {string} innerHTML string after conversion
   */
  function sanitizeContent(contentString) {
    try {
      const template = document.createElement('template');
      template.innerHTML = contentString;

      const absoluteUrlRegex = /^https?/i;

      const nodesToFix = template.content.querySelectorAll(['[src]', '[href]']);
      nodesToFix.forEach((node) => {
        ['src', 'href'].forEach((attr) => {
          if (node.hasAttribute(attr)) {
            if (!absoluteUrlRegex.test(node.getAttribute(attr))) {
              node.setAttribute(attr, `${baseURL}${node.getAttribute(attr)}`);
            }
          }
        });
      });

      return template.innerHTML;
    } catch {
      return contentString;
    }
  }

  /**
   * Find subcategories (also nested) for given slug and knowledge base category data array.
   * @param {string} slug Category or subcategory slug
   * @param {KnowledgeBaseCategory[]} array array of knowledge base category data
   * @returns {(KnowledgeBaseCategory[])} array of subcategories from given category/subcategory slug
   */
  function findSubcategoriesForSlug(slug, array) {
    if (!Array.isArray(array)) {
      return [];
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const item of array) {
      if (item.slug === slug) {
        return item.subCategories;
      }

      const nested = findSubcategoriesForSlug(slug, item.subCategories);
      if (nested?.length > 0) {
        return nested;
      }
    }

    return [];
  }

  /**
   * Get list of all top level categories
   * @returns {KnowledgeBaseCategory[]} a list of categories
   */
  async function getCategories() {
    // cache categories
    if (categories.length === 0) {
      return fetch(`${baseURL}/projects/categories`, getOptions())
        .then((response) => response.json())
        .then((data) => {
          categories = data;
          return categories;
        });
    }

    return Promise.resolve(categories);
  }

  /**
   * Get a list of articles in a given category
   * @param {string} categorySlug category slug
   * @returns {KnowledgeBaseListArticle[]} array of available articles
   */
  async function getCategoryArticlesBySlug(categorySlug) {
    if (!articlesInCategory.has(categorySlug)) {
      return fetch(
        `${baseURL}/projects/${categorySlug}?order=popularity`,
        getOptions(),
      )
        .then((response) => response.json())
        .then(async (data) => {
          // cache article list per category
          articlesInCategory.set(categorySlug, data?.articles);
          return articlesInCategory.get(categorySlug);
        });
    }

    return Promise.resolve(articlesInCategory.get(categorySlug));
  }

  /**
   * Get a list of both the available sub-categories for given parent, and the articles in it
   * @param {string} categorySlug slug of parent category
   * @returns {Promise<{ subcategories: KnowledgeBaseCategory[], articles: KnowledgeBaseListArticle[]}>} Object with array of sub-categories and an array of articles
   */
  async function getSubcategoriesAndArticlesBySlug(categorySlug) {
    const localCategories = await this.getCategories();
    const localArticles = await this.getCategoryArticlesBySlug(categorySlug);

    return Promise.resolve({
      subcategories:
        findSubcategoriesForSlug(categorySlug, localCategories)?.filter(
          (sc) => sc.numArticles > 0,
        ) ?? [], // hide categories with no articles
      articles: localArticles,
    });
  }

  /**
   * Get a single article with the category and article slugs. Also contains related articles array
   * @param {string} categorySlug category slug string
   * @param {string} articleSlug article slug string
   * @param {boolean} [skipCache=false] skip cache and get fresh article from network
   * @returns {Promise<{article: KnowledgeBaseArticle, relatedArticles: KnowledgeBaseListArticle[]}>} An object with article entity and relatedArticles array
   */
  async function getArticleByCategoryAndSlug(
    categorySlug,
    articleSlug,
    skipCache = false,
  ) {
    if (skipCache || !articles.has(`${categorySlug}/${articleSlug}`)) {
      return fetch(
        `${baseURL}/projects/${categorySlug}/${articleSlug}`,
        getOptions(),
      )
        .then((response) => response.json())
        .then((articlePayload) => {
          if (articlePayload.article.Contents) {
            // eslint-disable-next-line no-param-reassign
            articlePayload.article.Contents = sanitizeContent(
              articlePayload?.article?.Contents,
            );
          }

          // cache individual articles
          articles.set(`${categorySlug}/${articleSlug}`, articlePayload);

          return articlePayload;
        });
    }

    return Promise.resolve(articles.get(`${categorySlug}/${articleSlug}`));
  }

  /**
   * Use the search endpoint to search for all articles in the knowledge base
   * @param {string} searchString query string for search
   * @returns {Promise<KnowledgeBaseSearchResult[]>} array of articles
   */
  async function getSearchResults(searchString) {
    if (!searchString) {
      return Promise.reject(new Error('Search term not provided.'));
    }

    return fetch(
      `${baseURL}/projects/search.json?query=${encodeURIComponent(
        searchString?.trim(),
      )}`,
    )
      .then((response) => response.json())
      .then((results) =>
        results.map((result) => {
          const [articleSlug, categorySlug] = result?.url
            .replace(/\/$/, '')
            .split('/')
            .reverse();

          return {
            ...result,
            articleSlug,
            categorySlug,
          };
        }),
      );
  }

  return {
    getCategories,
    getCategoryArticlesBySlug,
    getSubcategoriesAndArticlesBySlug,
    getArticleByCategoryAndSlug,
    getSearchResults,
  };
}

export function provideKnowledgeBaseApi() {
  provide(useKnowledgeBaseApiSymbol, KnowledgeBaseApi({}));
}

export function useKnowledgeBaseApi() {
  return inject(useKnowledgeBaseApiSymbol);
}
