<template>
  <button
    v-if="projectsSideNavPanelPinningEnabled"
    type="button"
    class="
      flex
      items-center
      justify-center
      rounded-full
      bg-transparent
      border-none
      text-text-secondary
      hover:text-text
      transition-colors
      cursor-pointer
      w-[32px]
      h-[32px]
      p-100
    "
    @click="pinProjectsPanel"
    v-tooltip="{
      content: isProjectsPanelPinned ? $t('Unpin') : $t('Pin'),
      placement: 'bottom',
      boundariesElement: 'document.body',
      offset: 0,
    }"
    :title="isProjectsPanelPinned ? $t('Unpin') : $t('Pin')"
  >
    <svg
      v-if="!isProjectsPanelPinned"
      class="fill-current"
      width="10"
      height="14"
      viewBox="0 0 10 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42334 9.47949V12.6263C4.42334 12.6446 4.42701 12.6602 4.43068 12.6758L4.71985 13.5094C4.7922 13.7178 5.20799 13.7204 5.28035 13.5094L5.56951 12.6758C5.57685 12.6602 5.57685 12.6419 5.57685 12.6263V9.47949"
      />
      <path
        d="M8.11136 1.42759C8.09642 1.934 7.748 2.37971 7.25356 2.51881C6.7274 2.66678 6.39025 3.17579 6.46182 3.7145L6.73185 5.7516C6.77668 6.09077 6.97881 6.3893 7.27768 6.55888C8.07571 7.01137 8.6294 7.67668 8.83599 8.41675H1.16401C1.36692 7.691 1.9041 7.03611 2.67591 6.58623C2.96875 6.41561 3.16589 6.1202 3.21046 5.78572L3.48836 3.69392C3.55809 3.16876 3.2393 2.66938 2.73149 2.50839C2.49764 2.43415 2.28712 2.28463 2.13874 2.08665C1.99376 1.89336 1.91406 1.6662 1.90724 1.42759L8.11162 1.42759H8.11136ZM8.38192 0.333496L1.63643 0.333496C1.17764 0.333496 0.805367 0.706008 0.805367 1.15928L0.805367 1.39372C0.805367 2.40185 1.47651 3.25889 2.3967 3.55065L2.11881 5.64245C0.983639 6.30411 0.184038 7.35131 0.00838781 8.57305C-0.0256936 8.8075 0.0450908 9.04716 0.202389 9.2269C0.359688 9.40925 0.590393 9.51084 0.831584 9.51084H9.16579C9.40698 9.51084 9.63768 9.40665 9.79498 9.2269C9.9549 9.04716 10.0257 8.8075 9.99161 8.57305C9.81333 7.33048 8.99014 6.27025 7.82351 5.60858L7.55348 3.57149C8.50775 3.30317 9.21298 2.4253 9.21298 1.39372V1.15928C9.21298 0.703403 8.83808 0.333496 8.38192 0.333496V0.333496Z"
      />
    </svg>

    <svg
      v-else
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42334 10.4795V13.6263C7.42334 13.6445 7.42701 13.6602 7.43068 13.6758L7.71985 14.5094C7.7922 14.7178 8.20799 14.7204 8.28035 14.5094L8.56951 13.6758C8.57685 13.6602 8.57685 13.6419 8.57685 13.6263V10.4795"
        fill="#4461D7"
      />
      <path
        d="M12.9916 9.57305C12.8133 8.33048 11.9901 7.27025 10.8235 6.60858L10.5535 4.57149C11.5078 4.30317 12.213 3.4253 12.213 2.39372V2.15928C12.213 1.7034 11.8381 1.3335 11.3819 1.3335L4.63643 1.3335C4.17764 1.3335 3.80537 1.70601 3.80537 2.15928V2.39372C3.80537 3.40185 4.47651 4.25889 5.3967 4.55065L5.11881 6.64245C3.98364 7.30411 3.18404 8.35131 3.00839 9.57305C2.97431 9.8075 3.04509 10.0472 3.20239 10.2269C3.35969 10.4093 3.59039 10.5108 3.83158 10.5108H12.1658C12.407 10.5108 12.6377 10.4066 12.795 10.2269C12.9549 10.0472 13.0257 9.8075 12.9916 9.57305Z"
        fill="#4461D7"
      />
    </svg>
  </button>
</template>

<script>
import { useFeatures } from '@/platform/composables/useFeatures';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

export default {
  setup() {
    const { isProjectsPanelPinned, pinProjectsPanel } = useSidebarPanels();
    const { projectsSideNavPanelPinningEnabled } = useFeatures();
    return {
      pinProjectsPanel,
      isProjectsPanelPinned,
      projectsSideNavPanelPinningEnabled,
    };
  },
};
</script>
