export const textareaSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};
export const defaultTextareaSize = textareaSizes.md;

export const textareaVariants = {
  outline: 'outline',
  filled: 'filled',
};

export const defaultTextareaVariant = textareaVariants.outline;
