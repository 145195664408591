<template>
  <hr
    role="presentation"
    class="h-0 m-0 border-0 border-solid border-b-2"
    :class="borderColorClass"
  />
</template>

<script>
export default {
  name: 'FTabsBorder',
  props: {
    borderColorClass: {
      type: String,
      default: 'border-default',
    },
  },
};
</script>
