<template>
  <PopoverMenuActionItem @click="editMyDetails" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path
          d="m16.67 3.063-1.754-1.752a2.196 2.196 0 0 0-3.107 0L1.478 11.575l-.8 4.85a.781.781 0 0 0 .897.898l4.85-.8L16.69 6.19c.857-.857.865-2.242-.02-3.128ZM7.19 10.808a.714.714 0 0 0 1.013 0l3.875-3.874.93.93-5.506 5.542V12.06H5.94v-1.563H4.594l5.542-5.506.93.93-3.875 3.875a.716.716 0 0 0 0 1.013Zm-3.85 4.772-.92-.92.394-2.396.6-.595h1.353v1.563h1.563v1.354l-.595.599-2.396.395ZM15.676 5.178l-.002.002-.001.002-1.564 1.573-2.865-2.865 1.573-1.564.002-.001.002-.002a.764.764 0 0 1 1.082 0l1.752 1.753c.322.322.324.8.021 1.102Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Edit my details') }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import { useCurrentUser } from '@teamwork/use';
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';
import { useHybrid } from '@/platform/composables/useHybrid';

export default {
  components: {
    PopoverMenuActionItem,
  },
  setup() {
    const user = useCurrentUser();
    const { openModal: openHybridModal } = useHybrid();

    function editMyDetails() {
      openHybridModal('addOrEditPerson', { personId: user.value.id });
    }

    return {
      editMyDetails,
    };
  },
};
</script>

<style></style>
