<template>
  <PopoverMenuContainer class="w-[230px]">
    <ul id="side-nav-profile-more-menu" role="menu" class="list-none px-0 py-2">
      <UpdateStatusPopoverItem v-if="inOwnerCompany && isAccount" />
      <MyShortcutsPopoverItem v-if="shortcutsAvailable" />
      <MyBoardsPopoverItem v-if="myBoardsEnabled" />
      <li>
        <hr class="mx-200 bg-palette-neutral-20" />
      </li>
      <SuccessPlannerPopoverItem v-if="isSiteAdmin" />
      <WhatsNewPopoverItem />
      <template v-if="accounts && accounts.length > 0">
        <li>
          <hr class="mx-200 bg-palette-neutral-20" />
        </li>
        <VPopover
          placement="right-end"
          popover-class="quickadd-more-popover"
          popover-arrow-class="hidden"
          popover-inner-class="quickadd-popover_inner"
          :offset="0"
          :open.sync="isOpenAccounts"
        >
          <SwitchAccountPopoverItem
            class="w-full"
            @click.native.stop="isOpenAccounts = !isOpenAccounts"
          />
          <template #popover>
            <AccountsItemsMenu />
          </template>
        </VPopover>
      </template>
    </ul>
  </PopoverMenuContainer>
</template>

<script>
import { computed, ref } from 'vue-demi';
import { VPopover } from 'v-tooltip';
import useStore from '@/platform/composables/useStore';
import { useFeatures } from '@/platform/composables/useFeatures';
import UpdateStatusPopoverItem from './UpdateStatusPopoverItem.vue';
import PopoverMenuContainer from './PopoverMenuContainer.vue';
import MyShortcutsPopoverItem from './MyShortcutsPopoverItem.vue';
import MyBoardsPopoverItem from './MyBoardsPopoverItem.vue';
import SuccessPlannerPopoverItem from './SuccessPlannerPopoverItem.vue';
import WhatsNewPopoverItem from './WhatsNewPopoverItem.vue';
import SwitchAccountPopoverItem from './SwitchAccountPopoverItem.vue';
import AccountsItemsMenu from './AccountsItemsMenu.vue';

export default {
  components: {
    VPopover,
    PopoverMenuContainer,
    UpdateStatusPopoverItem,
    MyShortcutsPopoverItem,
    MyBoardsPopoverItem,
    SuccessPlannerPopoverItem,
    WhatsNewPopoverItem,
    SwitchAccountPopoverItem,
    AccountsItemsMenu,
  },
  setup() {
    const isOpenFeatures = ref(false);
    const isOpenAccounts = ref(false);
    const { myBoardsEnabled } = useFeatures();
    const store = useStore();

    const isTWInternal = computed(() => store.getters['account/isTWInternal']);
    const inOwnerCompany = computed(() => store.getters['user/inOwnerCompany']);
    const isAccount = computed(() => store.getters['user/isAccount']);
    const isSiteAdmin = computed(() => store.getters['user/isSiteAdmin']);
    const shortcutsAvailable = computed(
      () => store.state.account.shortcutsAvailable,
    );

    const accounts = computed(() => store.state.user.accounts);

    return {
      isOpenFeatures,
      isOpenAccounts,
      myBoardsEnabled,
      isTWInternal,
      inOwnerCompany,
      isAccount,
      shortcutsAvailable,
      isSiteAdmin,
      accounts,
    };
  },
};
</script>
<style lang="scss" scoped>
/* Had no choice but to access the trigger class here */
::v-deep .trigger {
  width: 100%;
  height: 100%;
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
