"use strict";

/*
    File Model
*/
var indexOf = [].indexOf;
define(['moment', 'projectModel'], function (moment, ProjectModel) {
  var File;

  File = function () {
    class File {
      constructor(data, parentVM = null) {
        var dateLongVersion, i, id, idSeenValue, idsSeen, relatedUnique, targetProject, tmpObj, txtAgo, uploadedDate; //<-------------------------------------------------------------->
        //                          Actions
        //<-------------------------------------------------------------->

        this.checkHighlight = this.checkHighlight.bind(this);
        this.OnUpdateFollowing = this.OnUpdateFollowing.bind(this);
        this.IsActive = this.IsActive.bind(this);
        this.Lock = this.Lock.bind(this);
        this.Unlock = this.Unlock.bind(this); //DELETE FILES ACTIONS#

        this.OnClickDeleteFile = this.OnClickDeleteFile.bind(this);
        this.OnDeleteFile = this.OnDeleteFile.bind(this);
        this.DeleteFile = this.DeleteFile.bind(this);
        this.OnClickDeleteFileVersion = this.OnClickDeleteFileVersion.bind(this);
        this.DeleteFileVersion = this.DeleteFileVersion.bind(this);
        this.UndoDeleteFile = this.UndoDeleteFile.bind(this);
        this.UndoDeleteVersion = this.UndoDeleteVersion.bind(this); //DELETE FILES ACTIONS#

        this.OnClickUploadNewVersion = this.OnClickUploadNewVersion.bind(this);
        this.OnQuickView = this.OnQuickView.bind(this);
        this.componentName = "File";
        this.parentVM = parentVM; // Conversion to numbers

        data.projectId = parseInt(data['projectId'], 10);
        data.id = parseInt(data['id'], 10);
        data.categoryId = +parseInt(data['categoryId'], 10) || 0;
        data.size = parseInt(data['size'], 10);
        data.version = parseInt(data['version'], 10);
        data.versionId = parseInt(data['versionId'], 10);
        data.private = parseInt(data['private'], 10);
        data.commentEndpoint = 'files';
        data.fileRootVersionId = data.id;

        if (data.lockdownId == null || data.lockdownId === "") {
          data.lockdownId = 0;
        }

        if (data.hasUnreadComments == null) {
          data.hasUnreadComments = false;
        }

        if (data.numberOfUnreadComments != null) {
          data.hasUnreadComments = parseInt(data.numberOfUnreadComments, 10) > 0;
        }

        if (data.isSelected == null) {
          data.isSelected = false;
        } // for document editor locking,
        // it's better to set default values for locking
        // if they are not returned by the API


        if (data.fileLockedByUserId == null) {
          data.fileLockedByUserId = -1;
        } else {
          data.fileLockedByUserId = parseInt(data.fileLockedByUserId, 10);
        }

        if (data.fileLocked == null) {
          data.fileLocked = 0;
        } else {
          data.fileLocked = parseInt(data.fileLocked, 10);
        }

        if (data.fileLockedBySelf == null) {
          data.fileLockedBySelf = false;
        }

        if (data.displayName == null) {
          data.displayName = null;
        }

        if (data.extension != null) {
          // file extensions should always be lcased
          data.extension = data.extension.toLowerCase();
        } else {
          data.extension = '';
        }

        if (data.thumbURL == null) {
          data.thumbURL = '';
        }

        if (data.versions == null) {
          data.versions = [];
        }

        if (data.relatedItems == null) {
          data.relatedItems = [];
        }

        if (data.shareable == null) {
          data.shareable = false;
        }

        if (this.descriptionAsHTML == null) {
          this.descriptionAsHTML = ko.observable(ko.unwrap(data.description)).extend({
            linkify: true
          });
        } else {
          this.descriptionAsHTML(ko.unwrap(data.description));
        }

        if (data.commentCount == null) {
          data.commentCount = 0;
        }

        data.commentCount = parseInt(data.commentCount, 10);

        if (data.canAddFiles != null) {
          if (data.canAddFiles === "0") {
            data.canAddFiles = false;
          }

          if (data.canAddFiles === "1") {
            data.canAddFiles = true;
          }
        }

        if (data.doHighlight == null) {
          data.doHighlight = false;
        }

        data.keepIconsActive = false;
        this.isIntegrationEnabled = ko.observable;
        data.fileSourceDisplay = {};

        switch (data.fileSource) {
          case "onedrive":
            data.fileSourceDisplay.name = "OneDrive";
            data.fileSourceDisplay.css = "onedrive";
            data.fileSourceDisplay.icon = '//twa-prod.teamwork.com/tko/public/legacy/images/newui/file-icons/large/' + app.utility.GetAttachmentIcon(data.originalName);
            this.isIntegrationEnabled = ko.pureComputed(() => {
              var ref;
              return (ref = app.account.integrations.onedrive) != null ? typeof ref.enabled === "function" ? ref.enabled() : void 0 : void 0;
            });
            break;

          case "onedrivebusiness":
            data.fileSourceDisplay.name = "OneDrive Business";
            data.fileSourceDisplay.css = "onedrive";
            data.fileSourceDisplay.icon = '//twa-prod.teamwork.com/tko/public/legacy/images/newui/file-icons/large/' + app.utility.GetAttachmentIcon(data.originalName);
            this.isIntegrationEnabled = ko.pureComputed(() => {
              var ref;
              return (ref = app.account.integrations.onedrivebusiness) != null ? typeof ref.enabled === "function" ? ref.enabled() : void 0 : void 0;
            });
            break;

          case "sharepoint":
            data.fileSourceDisplay.name = "SharePoint";
            data.fileSourceDisplay.css = "sharepoint";
            data.fileSourceDisplay.icon = '//twa-prod.teamwork.com/tko/public/legacy/images/newui/file-icons/large/' + app.utility.GetAttachmentIcon(data.originalName);
            this.isIntegrationEnabled = ko.pureComputed(() => {
              var ref;
              return (ref = app.account.integrations.sharepoint) != null ? typeof ref.enabled === "function" ? ref.enabled() : void 0 : void 0;
            });
            break;

          case "googledrive":
            data.fileSourceDisplay.name = "Google Drive";
            data.fileSourceDisplay.css = "googledocs";
            data.fileSourceDisplay.icon = '//twa-prod.teamwork.com/tko/public/legacy/images/newui/drive-icons/large/' + data.extraData + '.png';
            this.isIntegrationEnabled = ko.pureComputed(() => {
              var ref;
              return (ref = app.account.integrations.googledrive) != null ? typeof ref.enabled === "function" ? ref.enabled() : void 0 : void 0;
            });
            break;

          case "dropbox":
            data.fileSourceDisplay.name = "Dropbox";
            data.fileSourceDisplay.css = "dropbox";
            data.fileSourceDisplay.icon = '//twa-prod.teamwork.com/tko/public/legacy/images/newui/file-icons/large/' + app.utility.GetAttachmentIcon(data.originalName);
            this.isIntegrationEnabled = ko.pureComputed(() => {
              var ref;
              return (ref = app.account.integrations.dropbox) != null ? typeof ref.enabled === "function" ? ref.enabled() : void 0 : void 0;
            });
            break;

          case "box.com":
            data.fileSourceDisplay.name = "Box.com";
            data.fileSourceDisplay.css = "box";
            data.fileSourceDisplay.icon = '//twa-prod.teamwork.com/tko/public/legacy/images/newui/file-icons/large/' + app.utility.GetAttachmentIcon(data.originalName);
            this.isIntegrationEnabled = ko.pureComputed(() => {
              var ref;
              return (ref = app.account.integrations.box) != null ? typeof ref.enabled === "function" ? ref.enabled() : void 0 : void 0;
            });
            break;

          default:
            data.fileSourceDisplay.name = data.fileSourceDisplay.css = data.fileSource;
            data.fileSourceDisplay.icon = '//twa-prod.teamwork.com/tko/public/legacy/images/newui/file-icons/large/' + app.utility.GetAttachmentIcon(data.originalName);
        }

        if (data.relatedItems != null && data.relatedItems.length > 0) {
          //remove duplicated relatedItems
          data.relatedItems.map(item => {
            if (item.type === 'comment' && item.commentId != null) {
              item.cId = 'comment' + item.commentId;
            }

            return item;
          });
          relatedUnique = [];
          idsSeen = {};
          idSeenValue = {};
          i = 0;

          while (i < data.relatedItems.length) {
            id = data.relatedItems[i].cId;

            if (idsSeen[id] !== idSeenValue || id == null) {
              relatedUnique.push(data.relatedItems[i]);
              idsSeen[id] = idSeenValue;
            }

            ++i;
          }

          data.relatedItems = relatedUnique;
        }

        data.numLikes = parseInt(data['numLikes'], 10);
        data.likedByMe = false;

        if (data.likedByUsers === void 0) {
          data.likedByUsers = [];
        }

        if (data.numLikes > 0) {
          data.likedByUsers = [];
          tmpObj = app.ko.utils.parseJson(data.likedByUsersJSON);

          for (i in tmpObj) {
            if (!(tmpObj[i] === void 0 || tmpObj[i] === null)) {
              data.likedByUsers.push({
                id: i,
                name: tmpObj[i]
              });

              if (i === app.loggedInUser.id().toString()) {
                data.likedByMe = true;
              }
            }
          }
        }

        if (data.reactions == null) {
          data.reactions = {};
        }

        if (data.reactions.counts == null) {
          data.reactions.counts = {
            joy: 0,
            dislike: 0,
            heart: 0,
            like: 0,
            frown: 0
          };
        }

        if (data.reactions.mine == null) {
          data.reactions.mine = [];
        }

        if (data.dropboxShareableLinks == null) {
          data.dropboxShareableLinks = false;
        } // Map the data across


        app.ko.mapping.fromJS(data, this.mapping, this);
        /*
        Extras and defaults
        */
        // The googlePlusURL field may not have been return - set it to empty string

        this.extension = ko.pureComputed(() => {
          if (data.originalName.indexOf('.') !== -1) {
            return data.originalName.split('.').pop().toLowerCase();
          } else {
            return '';
          }
        });
        this.isDisplayableImage = ko.pureComputed(() => {
          return ["png", "jpg", "jpeg", "gif", "webp", "svg"].indexOf(this.extension().toLowerCase()) > -1;
        });
        this.isPlayableVideo = ko.pureComputed(() => {
          var ref;
          return ref = this.extension().toLowerCase(), indexOf.call(app.consts.FILES.PLAYABLEVIDEO, ref) >= 0;
        });
        this.isBlockedVideo = ko.pureComputed(() => {
          var ref;
          return ref = this.extension().toLowerCase(), indexOf.call(app.consts.FILES.BLOCKEDVIDEOS, ref) >= 0;
        }); // we'll allow direct videoJS playing from S3 and sharepoint/onedrivebusiness hosted files

        this.isExternalVideo = ko.pureComputed(() => {
          var ref;
          return this.isPlayableVideo() && !this.isBlockedVideo() && (ref = this.fileSource()) !== "teamworkpm" && ref !== "sharepoint" && ref !== "onedrivebusiness";
        });
        this.canOpenInOfficeOnline = ko.pureComputed(() => {
          return ["xlsx", "docx", "pptx", "mdbx", "doc", "xls"].indexOf(this.extension().toLowerCase()) > -1;
        });
        this.canEditFileInDocEditor = ko.pureComputed(() => {
          return ["pdf", "mp3", "avi", "mpg", "flv", "zip"].indexOf(this.extension().toLowerCase()) === 0;
        });
        this.getProject = ko.pureComputed(() => {
          var targetProject;

          if (app.currentRoute().project != null) {
            return app.currentRoute().project;
          } else {
            targetProject = ko.unwrap(this.projectId);

            if (targetProject > 0) {
              return app.projectInfo.get(targetProject);
            } else {
              return null;
            }
          }
        });
        this.canUploadFilesDirectly = ko.pureComputed(() => {
          var project;

          if (!app.featureFlip.check('disablingdirectfileuploads', {
            unwrap: true
          })) {
            return true;
          }

          project = ko.unwrap(this.getProject());

          if (project != null) {
            if (!project.directFileUploadsEnabled()) {
              return false;
            }
          }

          return app.account.canUploadFilesDirectly();
        });
        this.uploadedByName = ko.pureComputed(() => {
          return this.uploadedByUserFirstName() + ' ' + this.uploadedByUserLastName();
        });
        this.finalThumbURL = ko.pureComputed(() => {
          var url; // (fileSource() == 'teamworkpm' ? '/' : '') +

          url = this.thumbURL();

          if (url.charAt(0) === "?") {
            return './' + url;
          }

          return url;
        });
        this.fileDateString = ko.pureComputed(() => {
          var uploadedDate;
          uploadedDate = this.uploadedDate();

          if (new moment().format('YY') === uploadedDate.format('YY')) {
            return app.utility.GetPastRelativeTime(uploadedDate);
          } else {
            return uploadedDate.format('DD MMM YY');
          }
        });
        this.dateDay = ko.pureComputed(() => {
          var diffInDays, now, prevYear;
          now = moment().startOf('day');
          diffInDays = Math.floor(this.uploadedDate().diff(now, 'days', true));

          if (diffInDays === 0) {
            return app.tl('Today');
          } else if (diffInDays === -1) {
            return app.tl('Yesterday');
          } //only show year if not current year


          if (moment().startOf('day').format("YYYY") !== this.uploadedDate().format('YYYY')) {
            prevYear = ', ' + ko.unwrap(this.uploadedDate().format('YYYY'));
          }

          return this.uploadedDate().format('MMMM DD') + (prevYear != null ? prevYear : prevYear = '');
        });
        this.canOpenInImageViewer = ko.pureComputed(() => {
          var fs;
          fs = this.fileSource();
          return fs === "teamworkpm" || fs === "dropbox" || fs === "onedrive" || fs === "onedrivebusiness" || fs === "box.com" || fs === "googledrive" || fs === "sharepoint";
        });
        this.fileDateDisplay = ko.pureComputed(() => {
          var df;
          df = app.loggedInUser.localization.timeFormat();
          return this.uploadedDate().format(`dddd, DD MMMM YYYY ${df}`);
        });
        this.fileUploadedDate = ko.pureComputed(() => {
          var df;
          df = app.loggedInUser.localization.dateFormat();
          return this.uploadedDate().format(`${df}`);
        });
        this.fileUploadedTime = ko.pureComputed(() => {
          var tf;
          tf = app.loggedInUser.localization.timeFormat();
          return this.uploadedDate().format(`${tf}`);
        });
        this.sizeText = ko.pureComputed(() => {
          return app.utility.GetFriendlyBytes(this.size());
        });
        this.fileSourceIsTeamworkPM = ko.pureComputed(() => {
          return this.fileSource() === 'teamworkpm';
        });
        this.showVersions = ko.observable(false);
        this.showInfoToggle = ko.observable(false); //can be used e.g. in select files modal show/hide file info

        this.fileChosen = ko.observable(false); //can be used e.g. in select files modal for check/uncheck

        this.hasActiveVersions = ko.pureComputed(() => {
          return this.versions().filter(i => {
            return i.status() === "active";
          }).length > 1;
        });
        this.versionsCount = ko.pureComputed(() => {
          return this.versions().filter(i => {
            return i.status() === "active";
          }).length;
        });
        this.listThumb = ko.observable('//twa-prod.teamwork.com/tko/public/legacy/images/filetypes/' + app.GetIcon(this.originalName()));

        if (this.isDisplayableImage()) {
          this.listThumb(this.thumbURL());
        }

        if (this.fileSource() === 'teamworkpm') {
          this.listThumb("/" + this.listThumb());
        }

        if (typeof this.canAddFiles === "undefined") {
          this.permissionsLoaded = ko.observable(false); //use cached project permissions if endpoint doesnt provide permissions

          this.projectPermissions = {};
          this.canAddFiles = ko.pureComputed(() => {
            return this.permissionsLoaded() && this.projectPermissions.canAddFiles();
          });

          if (app.currentRoute().projectId != null) {
            this.projectPermissions = app.currentRoute().project.permissions;
            this.permissionsLoaded(true);
          } else {
            targetProject = ko.unwrap(this.projectId);

            if (targetProject > 0) {
              app.GetProjectAndPermissions(targetProject).then(result => {
                var project;
                project = result.componentName != null && result.componentName === 'Project' ? result : new ProjectModel(result.response.project);
                this.projectPermissions = project.permissions;
                this.permissionsLoaded(true);

                if (app.cachedProjects[targetProject] == null) {
                  return app.cachedProjects[targetProject] = project;
                }
              });
            } else {
              this.permissionsLoaded(true);
            }
          }
        } else {
          this.permissionsLoaded = ko.observable(true);
        } //client side sort versions if needed


        if ((parentVM != null ? parentVM.versionsSort : void 0) != null) {
          this.versions().sort(function (a, b) {
            var sortDir;
            sortDir = ko.unwrap(parentVM.versionsSort) === 'asc' ? 1 : -1;

            if (parseInt(ko.unwrap(a.version), 10) > parseInt(ko.unwrap(b.version), 10)) {
              return sortDir;
            } else if (parseInt(ko.unwrap(a.version), 10) < parseInt(ko.unwrap(b.version), 10)) {
              return -sortDir;
            } else {
              return 0;
            }
          });
        } //format file version timestamps


        for (i in this.versions()) {
          uploadedDate = ko.unwrap(this.versions()[i].uploadedDate);
          txtAgo = dateLongVersion = '';

          if (uploadedDate != null) {
            if (new moment().format('YY') === uploadedDate.format('YY')) {
              txtAgo = app.utility.GetPastRelativeTime(uploadedDate);
            } else {
              txtAgo = uploadedDate.format('DD MMM YY');
            }

            dateLongVersion = uploadedDate.format('dddd, DD MMMM YYYY HH:mm');
          }

          this.versions()[i].fileDateString = ko.observable(txtAgo);
          this.versions()[i].fileDateDisplay = ko.observable(dateLongVersion);
          this.versions()[i].projectId = ko.observable(data.projectId);
          this.versions()[i].commentEndpoint = ko.observable('fileversions');
          this.versions()[i].fileRootVersionId = ko.observable(data.id);
          this.versions()[i].fileSource = ko.observable(data.fileSource); //file versions should inherit the privacy setting from the parent (latest/current) fileversion

          this.versions()[i].private = ko.observable(data.private); //for file version thumbnails, check for action prefix and prepend ./ if needed

          if (this.versions()[i].thumbURL != null && ko.unwrap(this.versions()[i].thumbURL).toString().substr(0, 16) === "?action=getThumb") {
            this.versions()[i].thumbURL('./' + this.versions()[i].thumbURL());
          }
        }

        return;
      }

      checkHighlight(eL = null, nodes, item) {
        if (this.doHighlight()) {
          if (eL != null) {
            app.utility.ScrollIntoViewIfNeeded(eL);
            app.utility.Highlight(eL, 50); //highlight the change
          }

          return this.doHighlight(false); //reset the highlight
        }
      }

      OnUpdateFollowing(type) {
        var data;
        Tipped.hideAll();
        data = {
          'follow-changes': type === 'changes' || type === 'all' ? true : false,
          'follow-comments': type === 'comments' || type === 'all' ? true : false
        };

        if (type === 'none') {
          app.api.put(`files/${this.id()}/unfollow.json`).then(r => {
            this.userFollowingChanges(false);
            return this.userFollowingComments(false);
          });
        } else {
          app.api.put(`files/${this.id()}/follow.json`, data).then(r => {
            this.userFollowingChanges(type === 'changes' || type === 'all' ? true : false);
            return this.userFollowingComments(type === 'comments' || type === 'all' ? true : false);
          });
        }
      }

      IsActive() {
        return this.status() === "active";
      }

      Lock() {
        Tipped.hideAll();
        return app.api.put(`files/${this.id()}/lock.json`).then(result => {
          this.fileLocked(1);
          this.fileLockedByUserId(app.loggedInUser.id());
          this.fileLockedBySelf(true);
          return ko.postbox.publish('allTWEvents', {
            eventTime: new Date(),
            itemType: 'file',
            actionType: 'edited',
            itemId: this.id(),
            subType: "file-locked",
            projectId: this.projectId()
          });
        });
      }

      Unlock() {
        Tipped.hideAll();
        return app.api.put(`files/${this.id()}/unlock.json`).then(result => {
          this.fileLocked(0);
          this.fileLockedByUserId(0);
          this.fileLockedBySelf(false);
          return ko.postbox.publish('allTWEvents', {
            eventTime: new Date(),
            itemType: 'file',
            actionType: 'edited',
            itemId: this.id(),
            subType: "file-unlocked",
            projectId: this.projectId()
          });
        });
      }

      OnClickDeleteFile(data, e) {
        var fv;

        if (this.fileSource() !== 'teamworkpm' || this.versions().length <= 1) {
          return this.OnDeleteFile(data, e);
        } else {
          // This should be foolproof as in some cases
          // file versions array isn't loaded
          fv = {
            id: ko.unwrap(this.versionId),
            version: ko.unwrap(this.version),
            fileRootVersionId: ko.unwrap(this.id),
            projectId: ko.unwrap(this.projectId)
          };
          return this.OnClickDeleteFileVersion(fv, e);
        }
      }

      OnDeleteFile() {
        var additionalLines, confirmMessage, question, title;
        Tipped.hideAll();

        if (this.fileSource() !== 'teamworkpm') {
          title = app.tl('Remove File Reference?');
          question = app.tl('Are you sure you want to delete this file?');
          additionalLines = [app.tl('It will be removed from [_s] but will remain on [_s]', 'Teamwork Projects', this.fileSourceDisplay.name())];
          confirmMessage = app.tl("Yes, Delete this File");
        } else {
          title = app.tl('Delete File?');
          question = app.tl('Are you sure you want to delete this file?');
          confirmMessage = app.tl("Yes, Delete this File");
          additionalLines = [];
        }

        return app.modal.Show('confirm', {
          title: title,
          text: question,
          dims: {
            width: 500,
            height: 300
          },
          confirmMessage: confirmMessage,
          additionalLines: additionalLines,
          callback: result => {
            if (result.wasCancelled) {
              return;
            }

            return this.DeleteFile();
          }
        });
      }

      DeleteFile() {
        return app.api.delete(`/files/${this.id()}.json`).then(result => {
          var eventAction, ref;
          app.flash.Success(app.tl('File has been deleted'), {
            undo: this.UndoDeleteFile
          });

          if (((ref = this.parentVM) != null ? ref.componentName : void 0) != null && this.parentVM.componentName === 'Task') {
            eventAction = {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.DELETED,
              subType: 'removed-files',
              itemId: this.parentVM.id(),
              taskListId: this.parentVM.taskListId(),
              projectId: ko.unwrap(this.projectId),
              numAdded: -1
            };
          } else {
            eventAction = {
              eventTime: new Date(),
              actionType: app.consts.ACTIONTYPE.DELETED,
              itemType: 'file',
              subType: 'project-file-deleted',
              itemId: ko.unwrap(this.id),
              projectId: ko.unwrap(this.projectId),
              numAdded: -1
            };
          }

          return ko.postbox.publish('allTWEvents', eventAction);
        }).catch(() => {
          return app.flash.Error(app.tl("Sorry. This file wasn't deleted. Please try again."));
        });
      }

      OnClickDeleteFileVersion(file, rowData) {
        var question, title;
        Tipped.hideAll();
        title = app.tl('Delete File?');
        question = app.tl('Would you like to delete only this version or all versions?');
        return app.modal.Show('confirmDeleteFileRevision', {
          title: title,
          text: question,
          dims: {
            width: 500,
            height: 300
          },
          callback: opts => {
            if (opts.removeAll) {
              return this.DeleteFile();
            } else {
              return this.DeleteFileVersion(file, opts);
            }
          }
        });
      }

      DeleteFileVersion(file, opts) {
        var fileRootVersionId, versionId;
        versionId = ko.unwrap(file.id); //file id of the specific version selected

        fileRootVersionId = ko.unwrap(file.fileRootVersionId); //the top level file id

        return app.api.delete(`fileversions/${versionId}.json`).then(result => {
          var eventAction, ref;
          app.flash.Success(app.tl('File version has been deleted'), {
            undo: () => {
              return this.UndoDeleteVersion(versionId);
            }
          });

          if (((ref = this.parentVM) != null ? ref.componentName : void 0) != null && this.parentVM.componentName === 'Task') {
            eventAction = {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.DELETED,
              subType: 'removed-files',
              itemId: this.parentVM.id(),
              taskListId: this.parentVM.taskListId(),
              projectId: ko.unwrap(file.projectId)
            };
          } else {
            eventAction = {
              eventTime: new Date(),
              actionType: app.consts.ACTIONTYPE.DELETED,
              itemType: 'file',
              subType: 'project-file-deleted',
              itemId: fileRootVersionId,
              projectId: ko.unwrap(file.projectId),
              extraInfo: {
                fileType: 'version'
              }
            };
          }

          return ko.postbox.publish('allTWEvents', eventAction);
        });
      }

      UndoDeleteFile() {
        var id;
        id = ko.unwrap(this.id);
        app.flash.Success(app.tl('Restoring') + app.localizationPreferences().ellipsis);
        return app.api.put(`trashcan/files/${id}/restore.json`).then(result => {
          app.flash.Success(app.tl('File has been restored'));
          return ko.postbox.publish('allTWEvents', {
            eventTime: new Date(),
            actionType: app.consts.ACTIONTYPE.UPDATED,
            itemType: 'file',
            itemId: id,
            subType: "project-file-edited",
            projectId: ko.unwrap(this.projectId),
            numAdded: 1
          });
        });
      }

      UndoDeleteVersion(versionId) {
        var id;
        id = ko.unwrap(this.id);
        app.flash.Success(app.tl('Restoring') + app.localizationPreferences().ellipsis);
        return app.api.put(`trashcan/fileversions/${versionId}/restore.json`).then(result => {
          app.flash.Success(app.tl('File version has been restored'));
          return ko.postbox.publish('allTWEvents', {
            eventTime: new Date(),
            actionType: app.consts.ACTIONTYPE.UPDATED,
            itemType: 'file',
            itemId: id,
            subType: "project-file-edited",
            projectId: ko.unwrap(this.projectId)
          });
        });
      }

      OnClickUploadNewVersion(listContainer = null) {
        Tipped.hideAll();
        return app.modal.Show('projectsEditFileDetails', {
          listContainer: this.listContainer,
          title: 'Upload New Version',
          item: ko.unwrap(this),
          projectId: ko.unwrap(this.projectId),
          type: 'file',
          showUploadNew: true
        });
      }

      PreviewContents(index, parentVM, file) {
        var ref, ref1, ref2;

        if (this.isDisplayableImage()) {
          return app.modal.Show('previewImages', {
            item: this,
            filteredItems: parentVM != null ? parentVM.filteredItems != null ? parentVM.filteredItems : parentVM.filteredItems = [] : void 0,
            startingIndex: ko.unwrap(index),
            attachedTo: {
              type: (ref = parentVM.listContainer) != null ? ref.componentName != null ? ref.componentName : ref.componentName = '' : void 0,
              id: (ref1 = parentVM.listContainer) != null ? ref1.id != null ? ref1.id : ref1.id = 0 : void 0,
              projectId: (ref2 = parentVM.listContainer) != null ? ref2.projectId != null ? ref2.projectId : ref2.projectId = 0 : void 0,
              attachedVM: parentVM.listContainer != null ? parentVM.listContainer : parentVM.listContainer = {}
            }
          });
        } else {
          return window.open('./?action=viewFile&fileId=' + this.id() + '&v=' + this.version() + '&display=true');
        }
      }

      OnQuickView(parentFile = null, listContainer = null, file = null) {
        file = file != null ? file : this;
        return app.quickView.show('file', {
          id: file.fileRootVersionId(),
          fileId: file.fileRootVersionId(),
          fileVersion: file.version(),
          projectId: file.projectId(),
          parentFile: parentFile,
          listContainer: listContainer
        });
      }

    }

    ;
    /*  ____       _
              / ___|  ___| |_ _   _ _ __
              \___ \ / _ \ __| | | |  _ \
               ___) |  __/ |_| |_| | |_) |
              |____/ \___|\__|\__,_| .__/
                                   |_|
     * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */
    //<-------------------------------------------------------------->
    //                          Mapping
    //<-------------------------------------------------------------->

    File.prototype.mapping = {
      'uploadedDate': {
        create: function (options) {
          return app.ko.observable(moment(options.data, 'YYYY-MM-DDTHH:mm:ssZ'));
        }
      }
    };
    return File;
  }.call(this);

  return File;
});