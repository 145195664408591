<template>
  <v-popover
    :open.sync="isOpen"
    trigger="click"
    offset="2"
    placement="bottom-end"
    popover-arrow-class="hidden"
    boundaries-element="body"
  >
    <slot />
    <template slot="popover">
      <ul class="task-context-menu__list">
        <li
          v-if="!isTemplate"
          v-close-popover
          class="task-context-menu__item"
          @click="$emit('view-history')"
        >
          {{ $t('View history') }}
        </li>
        <template v-if="canEdit">
          <li
            v-close-popover
            class="task-context-menu__item"
            @click="$emit('modify-properties')"
          >
            {{ $t('Modify properties') }}
          </li>
          <li
            v-close-popover
            class="task-context-menu__item"
            @click="$emit('move-or-copy')"
          >
            {{ $t('Move or copy this task') }}
          </li>
          <li
            :class="['task-context-menu__item', { disabled: !canEditPrivacy }]"
            v-tooltip="{
              content: canEditPrivacy ? '' : privacyTooltip,
              placement: 'top',
            }"
          >
            <div
              v-close-popover
              data-identifier="privacy-list-item"
              @click="$emit('make-private')"
            >
              {{
                isPrivate ? $t('Edit privacy') : $t('Make this task private')
              }}
            </div>
          </li>
          <li
            v-close-popover
            class="task-context-menu__item"
            @click="$emit('delete')"
          >
            {{ $t('Delete task') }}
          </li>
        </template>
      </ul>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'ContextMenu',
  props: {
    open: { type: Boolean, default: false },
    isTemplate: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: true },
    isSubtask: { type: Boolean, default: false },
    isArchived: { type: Boolean, default: false },
    isPrivate: { type: Number, default: 0 },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(newVal) {
        this.$emit('update:open', newVal);
      },
    },
    canEditPrivacy() {
      return !(this.isSubtask || this.isArchived);
    },
    privacyTooltip() {
      if (this.isArchived) {
        return Vue.t(
          "You can't change privacy for a task in an archived project",
        );
      }
      return Vue.t("You can't change privacy for a subtask");
    },
  },
};
</script>

<style lang="scss" scoped>
.task-context-menu {
  &__list {
    list-style-type: none;
    padding: 5px 0 5px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 35px;
    cursor: pointer;

    &.disabled {
      color: #c5cee0;
      div {
        pointer-events: none;
      }
    }

    &:hover {
      background-color: #f5f7fa;
    }
  }
}
</style>
