/* eslint-disable no-console */
import moment from 'moment';
/**
 * All dashes followed by letters, are replaced by the uppercase letter
 */
const kebabCaseRegex = /-([A-Za-z])/g;
const camelCase = (str) =>
  // eslint-disable-next-line no-unused-vars
  str.replace(kebabCaseRegex, ([a, b]) => `${b.toUpperCase()}`);

/**
 * Casts `val` based on the type of `typeVal`
 * @param val
 * @param typeVal
 * @returns {*}
 */
export const typecast = (val, typeVal) => {
  if (Number.isInteger(typeVal)) {
    return Number(val);
  }
  if (typeVal === !!typeVal) {
    // is it a boolean?
    return ['true', 'TRUE', true, 1, '1', 'yes', 'YES'].some(
      (check) => val === check,
    );
  }
  if (typeVal instanceof moment && !(val instanceof moment)) {
    const { format } = typeVal.creationData();
    return moment.tz(val, format, typeVal.tz());
  }
  if (typeof typeVal === 'object' && typeof val !== 'object') {
    try {
      return JSON.parse(val);
    } catch (e) {
      console.error('e', e);
      console.log('Failed to JSON parse the following value', val);
      return val;
    }
  }
  return val;
};

/**
 * When retrieving API responses, the data is kebab-case and possibly
 * not correctly typed. This function will take a set of defaults
 *
 *     {aValue: 0, someThingElse: false}
 *
 *  and take the response from the API
 *
 *     {'a-value': '123', 'some-thing-else': 'true'}
 *
 * and output an object with the values from the response (where a default exists)
 * changed to kebab-case and correctly typed
 *
 *     {aValue: 123, someThingElse: true)
 *
 * This supports nested objects also (not arrays right now).
 *
 * @param defaults
 * @param response
 * @param mappings {Object} optional - if specified it will be have all the field mappings
 * written to it (i.e. mutated!) - this will only be 1 level deep.
 */
const typedDefaults = (defaults, response, mappings = {}) => {
  const result = {};
  if (!defaults) {
    Object.assign(result, response);
    return result;
  }

  Object.keys(response).forEach((field) => {
    const customFieldsCamelCaseExceptions = ['not-like'];
    const def = camelCase(field);

    // projectCustomField[... and customField[...
    if (field.includes('ustomField[')) {
      if (customFieldsCamelCaseExceptions.some((v) => field.includes(v))) {
        result[field] = response[field];
      } else {
        result[def] = response[field];
      }
    }

    if (defaults[def] !== undefined) {
      // eslint-disable-next-line no-param-reassign
      mappings[def] = field;
      const val = response[field];
      if (val !== undefined) {
        if (typeof val === 'object' && val !== null && !Array.isArray(val)) {
          result[def] = val ? typedDefaults(defaults[def], val) : val;
        } else {
          result[def] = typecast(val, defaults[def]);
        }
      }
    }
  });
  return result;
};

export default typedDefaults;
