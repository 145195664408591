<template>
  <div :class="['task-row', { centered }, { 'no-max-height': noMaxHeight }]">
    <div class="task-row__label">
      <EllipsisLabel :label="title">
        {{ title }}
      </EllipsisLabel>
    </div>
    <div class="task-row__content" :style="contentStyle">
      <slot />
    </div>
  </div>
</template>

<script>
import EllipsisLabel from '@/components/widgets/EllipsisLabel';

export default {
  name: 'TaskRow',
  display: 'TaskRow',
  props: {
    title: { type: String, required: true },
    fill: { type: Boolean, default: false },
    centered: { type: Boolean, default: true },
    noMaxHeight: { type: Boolean, default: false },
  },
  components: {
    EllipsisLabel,
  },
  computed: {
    contentStyle() {
      if (this.fill) {
        return { flexGrow: 2 };
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components/widgets/_w-tooltip.scss';

.task-row {
  display: flex;
  margin-bottom: 16px;
  color: #0b0e1f;
  height: 28px;
  max-height: 28px;
  font-size: 14px;

  &.no-max-height {
    height: initial;
    max-height: initial;
  }

  &.centered {
    align-items: center;
  }

  &__label {
    width: 120px;
    margin-right: 16px;
    color: #6d6f80;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content {
    flex-grow: 2;
  }

  &::v-deep .assignees {
    display: inline-flex;
    width: auto;
    justify-content: flex-start;
    padding-left: 0;
  }
}
</style>
