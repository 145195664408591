<template>
  <div class="flex-inline">
    <FAvatar
      v-for="(user, i) in visibleUsers"
      :key="user.id"
      :name="user.name"
      :size="size"
      :bordered="true"
      :stacked="i > 0"
    />
    <v-popover popover-arrow-class="hidden">
      <FAvatar
        v-if="hiddenUsers.length"
        name=""
        :has-tooltip="false"
        :size="size"
        :stacked="true"
        :clickable="true"
        background="#0B0E1F"
      >
        <span class="text-white">+{{ hiddenUsers && hiddenUsers.length }}</span>
      </FAvatar>
      <FAvatarMenu
        slot="popover"
        :users="hiddenUsers"
        @show-more="handleToggleModal"
      />
    </v-popover>

    <portal to="body">
      <FModalContainer v-if="showModal" @click="handleToggleModal" class="z-50">
        <FModal size="xs">
          <FModalHeader
            :title="`${$t('All users')} (${modalUsers.length})`"
            :close-modal="handleToggleModal"
            class="px-0 py-0 pb-8"
          />
          <FModalContent class="flex flex-col gap-4 px-0">
            <FAvatarMenuItem
              v-for="user in modalUsers"
              :key="user.id"
              :name="user.name"
              :has-tooltip="false"
            />
          </FModalContent>
        </FModal>
      </FModalContainer>
    </portal>
  </div>
</template>
<script>
import { computed, ref } from 'vue-demi';
import FAvatar from '../FAvatar/FAvatar.vue';
import FAvatarMenu from '../FAvatarMenu/FAvatarMenu.vue';
import FAvatarMenuItem from '../FAvatarMenuItem/FAvatarMenuItem.vue';
import { avatarSizes, defaultAvatarSize } from '../FAvatar/consts';
import FModal from '../../overlays/FModal/FModal.vue';
import FModalContainer from '../../overlays/FModal/FModalContainer.vue';
import FModalHeader from '../../overlays/FModal/FModalHeader.vue';
import FModalContent from '../../overlays/FModal/FModalContent.vue';
import optionPropValidator from '../../../helpers/optionPropValidator';

export default {
  name: 'FAvatarStack',
  components: {
    FAvatar,
    FAvatarMenu,
    FAvatarMenuItem,
    FModal,
    FModalContainer,
    FModalHeader,
    FModalContent,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
      validator(value) {
        if (!value.length) {
          return true;
        }
        let valid = true;
        const ids = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < value.length; i++) {
          if (!value[i].name || !value[i].id) {
            console.error('User is missing required info (name, id)', value[i]);
            valid = false;
          }
          if (ids.includes(value[i].id)) {
            console.error('Duplicated user id', value[i]);
            valid = false;
          }
          ids.push(value[i].id);
        }

        if (!valid) {
          console.error('Invalid users array specified');
        }

        return valid;
      },
    },
    size: {
      type: String,
      default: defaultAvatarSize,
      validator: optionPropValidator(avatarSizes, 'size'),
    },
    max: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    const showModal = ref(false);

    const visibleUsers = computed(() => {
      return props.users.slice(0, props.max);
    });

    const hiddenUsers = computed(() => {
      return props.users.slice(props.max);
    });

    const modalUsers = computed(() => {
      // 4 is the default `max` used in `FAvatarMenu`
      // make sure to change this value in chase the default one is overwritten
      return hiddenUsers.value.slice(4);
    });

    const handleToggleModal = () => {
      // this will close the popover before opening the modal
      document.body.click();
      showModal.value = !showModal.value;
    };

    return {
      showModal,
      visibleUsers,
      hiddenUsers,
      modalUsers,
      handleToggleModal,
    };
  },
};
</script>
