<template>
  <PopoverMenuActionItem
    @click="openWhatsNew"
    :badge="updatesCount"
    v-close-popover
  >
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        viewBox="0 0 18 16"
      >
        <path
          d="M15.77 4.363h-.849c.202-.394.329-.83.329-1.302A2.869 2.869 0 0 0 12.385.196c-1.354 0-2.23.694-3.352 2.223C7.91.89 7.033.196 5.68.196a2.869 2.869 0 0 0-2.865 2.865c0 .472.124.908.329 1.302h-.915c-.862 0-1.562.7-1.562 1.562v4.167c0 .286.234.52.52.52h.521v3.497c0 .944.769 1.712 1.713 1.712h11.158c.944 0 1.713-.768 1.713-1.712v-3.496h.52c.287 0 .521-.235.521-.521V5.925c0-.862-.7-1.562-1.562-1.562ZM8.22 14.259H3.42a.15.15 0 0 1-.15-.15v-3.496h3.646V9.05H2.229V5.925h5.99v8.334ZM5.676 4.363a1.302 1.302 0 1 1 0-2.604c.717 0 1.221.247 2.738 2.506l.065.098H5.676Zm3.972-.098c1.517-2.259 2.021-2.506 2.737-2.506a1.302 1.302 0 1 1 0 2.604H9.583l.065-.098ZM15.77 9.05h-4.688v1.563h3.646v3.496a.15.15 0 0 1-.15.15H9.781V5.925h5.99V9.05Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t("What's new?") }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import { computed } from 'vue-demi';
import useStore from '@/platform/composables/useStore';
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';

export default {
  components: {
    PopoverMenuActionItem,
  },
  setup() {
    const store = useStore();

    const openWhatsNew = () => {
      store.dispatch('quickViews/tko/open', { id: 'productUpdates' });
    };

    const updatesCount = computed(() =>
      store.state.user.hasFeatureUpdates
        ? store.state.user.featureUpdatesCount
        : null,
    );

    return {
      updatesCount,
      openWhatsNew,
    };
  },
};
</script>
