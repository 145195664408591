import { ref, computed } from 'vue-demi';
import useCurrentRoute from '@/platform/composables/useCurrentRoute';
import PassiveHelpers from '../../data/passive-help/PassiveHelpers';

const showPassiveHelpPanel = ref(false);
const activePassiveHelpId = ref(null);

export default function usePassiveHelp() {
  const currentRoute = useCurrentRoute();

  const isCurrentRoutePassiveHelpEnabled = computed(() => {
    const isPassiveHelpable = Object.values(PassiveHelpers).find((ph) =>
      currentRoute.value?.path[ph.matchIncludes ? 'includes' : 'endsWith'](
        ph.route,
      ),
    );
    return !!isPassiveHelpable;
  });

  const currentPassiveHelp = computed(() => {
    const matchedPassiveHelper = Object.values(PassiveHelpers).find((ph) =>
      currentRoute.value?.path[ph.matchIncludes ? 'includes' : 'endsWith'](
        ph.route,
      ),
    );
    if (!matchedPassiveHelper) {
      return [];
    }
    return matchedPassiveHelper;
  });

  function changePassiveHelpPanelStatus(status) {
    showPassiveHelpPanel.value = status;
  }

  const isPassiveHelpPanelEnabled = computed(() => {
    return showPassiveHelpPanel.value;
  });

  return {
    activePassiveHelpId,
    isPassiveHelpPanelEnabled,
    changePassiveHelpPanelStatus,
    currentRoute,
    currentPassiveHelp,
    isCurrentRoutePassiveHelpEnabled,
  };
}
