import { provide, inject, shallowRef } from 'vue-demi';

const RadioGroupSymbol = Symbol('RadioGroup');

export function provideRadioGroup(radioGroup) {
  provide(RadioGroupSymbol, radioGroup);
  return radioGroup;
}

export function useRadioGroup() {
  return inject(RadioGroupSymbol, {
    groupName: shallowRef(null),
    groupValue: shallowRef(null),
    groupOnChange: () => {},
  });
}
