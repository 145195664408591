<template>
  <div>
    <div class="bg-white p-6 rounded shadow-sm">
      <h2 class="text-text text-base font-bold">{{ title }}</h2>
      <div class="flex justify-start items-center mb-4">
        <TagPill
          v-for="tag in tags"
          :key="tag.label"
          :label="tag.label"
          :type="tag.type"
        />
      </div>
      <div class="text-text text-sm leading-5 mb-4">
        <div v-if="date" class="mb-5">{{ formatISODate(date) }}</div>

        <div v-html="processedDescription" class="mb-2 description" />
        <button
          class="
            bg-transparent
            hover:text-[#2947bf]
            text-[#2947bf] text-default
            font-normal
            appearance-none
            border-none
            p-0
          "
          @click.prevent="isTruncated = !isTruncated"
          v-if="description.length > 239"
        >
          {{ isTruncated ? $t('Show more') : $t('Show less') }}
        </button>
      </div>
      <div class="flex items-center justify-between">
        <div v-if="isUpcoming" class="h-10 flex items-center justify-start">
          <span
            v-if="isWebinarButtonClicked"
            class="text-[#318160] text-default flex items-center"
          >
            <CheckIcon class="mr-3" />
            {{ $t('Registered - Check your email') }}
          </span>
          <FButton v-else @click="webinarRegisterFn">
            {{ actionText }}
          </FButton>
        </div>

        <a
          v-else
          :href="webinarLink"
          target="_blank"
          class="
            outline-none
            h-10
            rounded
            py-2
            px-3
            gap-2
            text-default
            leading-2xl
            transition
            duration-300
            ease-in-out
            border-0
            text-white
            bg-primary
            hover:bg-primary-strong hover:text-white
            active:bg-primary-strongest
          "
          @click="emitCTAClicked"
        >
          {{ actionText }}
        </a>
        <span
          v-if="webinarDuration"
          class="leading-6 text-text-secondary text-right"
        >
          {{ totalTime }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import FButton from '@fragments/actions/FButton';
import { useCurrentUser } from '@teamwork/use';
import { ref, computed } from 'vue-demi';
import TagPill from './TagPill.vue';
import CheckIcon from '@/assets/images/features/sidenav/check.svg';
import { useLocalization } from '@/platform/composables/useLocalization';
import { formatMinutes } from '@/utils/helpers/time';

const WEBINAR_REGISTER_URL =
  'https://api-website.teamwork.com/index.cfm?action=registerWebinar&json=1';

export default {
  name: 'WebinarCard',
  components: {
    FButton,
    TagPill,
    CheckIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      required: true,
    },
    actionText: {
      type: String,
      required: true,
    },
    webinarDuration: {
      type: Number,
      default: 0,
    },
    isUpcoming: {
      type: Boolean,
      default: false,
    },
    webinarLink: {
      type: String,
      default: '',
    },
    webinarKey: {
      type: String,
      default: '',
    },
  },
  setup(props, { parent }) {
    const currentUser = useCurrentUser();
    const totalTime = formatMinutes(props.webinarDuration);
    const { languageCode } = useLocalization();
    const formatter = new Intl.DateTimeFormat(languageCode.value, {
      weekday: 'long',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    const formatISODate = (dateString) => {
      return formatter.format(new Date(dateString));
    };

    const isWebinarButtonClicked = ref(false);

    const emitCTAClicked = () => {
      parent.$emit('CTAClicked', props.title, props.actionText);
    };

    const webinarRegisterFn = () => {
      // IMPORTANT: We don't need a success toast or notification in .then() for the user here because the api call takes a very long time
      // we will catch an error when the CORS issue is resolved
      isWebinarButtonClicked.value = true;
      fetch(WEBINAR_REGISTER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          twProjectsVer: window.appVersionId ?? '',
        },
        body: new URLSearchParams({
          name: `${currentUser.value.firstName} ${currentUser.value.lastName}`,
          email: currentUser.value.emailAddress,
          company: currentUser.value.companyName,
          webinar: props.webinarKey,
          webinarTag: 'webinar_projectsapp',
        }),
      });

      emitCTAClicked();
    };

    const truncateDescription = (description) =>
      description.length > 239
        ? `${description
            .slice(0, 239)
            .replace(/\s\S*$/, '')
            .replace(/\.\.\.$/, '')} &hellip;`
        : description;

    const parseParagraphs = (description) =>
      description
        .split('\n\n')
        .map((paragraph) => `<p>${paragraph}</p>`)
        .join('');

    const isTruncated = ref(true);

    const processedDescription = computed(() =>
      isTruncated.value
        ? parseParagraphs(truncateDescription(props.description))
        : parseParagraphs(props.description),
    );

    return {
      formatISODate,
      totalTime,
      emitCTAClicked,
      webinarRegisterFn,
      isWebinarButtonClicked,
      processedDescription,
      isTruncated,
    };
  },
};
</script>

<style lang="scss" scoped>
.description p {
  margin: inherit;
}
</style>
