import api, { API_PREFIXES } from '@/services/api';

export default () => {
  return api.get(`${API_PREFIXES.v3}/projects.json`).then(({ data }) => {
    if (!data || data.projects.length === 0) {
      return null;
    }

    const { id } = data.projects.reduce((prev, curr) =>
      prev.updatedAt > curr.updatedAt ? prev : curr,
    );

    return id;
  });
};
