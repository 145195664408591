export default (el, { value }) => {
  if (value) {
    // find scrolled parent (max 5 levels or to offsetParent)
    let jumps = 0;
    let scrollParent = el;
    while (jumps < 5) {
      scrollParent = scrollParent.parentElement;
      if (
        !scrollParent ||
        scrollParent === el.offsetParent ||
        scrollParent.scrollHeight > scrollParent.clientHeight
      ) {
        break;
      }
      jumps += 1;
    }
    // clipped parent?
    if (scrollParent && scrollParent.scrollHeight > scrollParent.clientHeight) {
      // eslint-disable-next-line no-unused-vars
      const parentY1 = scrollParent.scrollTop;
      const parentY2 = scrollParent.scrollTop + scrollParent.offsetHeight;
      const elY1 = el.offsetTop - el.offsetHeight;
      const elY2 = el.offsetTop;
      if (elY2 > parentY2) {
        el.scrollIntoView({ block: 'end' });
      } else if (elY1 < parentY1) {
        el.scrollIntoView();
      }
    }
  }
};
