var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{key:(_vm.type + "-" + _vm.id),staticClass:"w-full h-[60px] mb-2 rounded-full outline-none bg-transparent",attrs:{"data-identifier":_vm.identifierPrefix && (_vm.identifierPrefix + "__list-item")},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c(_vm.component,_vm._b({tag:"component",class:[
      'w-full',
      'h-full',
      'flex',
      'items-center',
      'p-3',
      'pr-6',
      'outline-none',
      'rounded-full',
      'bg-transparent',
      'focus:bg-palette-neutral-20',
      'hover:bg-palette-neutral-20',
      'transition-colors' ].concat( (_vm.isClickable ? ['cursor-pointer'] : []) ),attrs:{"tabindex":"0"}},'component',_vm.linkAttrs || {},false),[_c('div',{staticClass:"mr-3 w-[36px] h-[36px] flex-none rounded-full overflow-hidden"},[_vm._t("icon",function(){return [_c('EntityIcon',{staticClass:"fill-current text-text w-full h-full",attrs:{"item-type":_vm.type,"size":36}})]})],2),_vm._v(" "),_c('div',{staticClass:"flex flex-1 flex-column min-w-0"},[_c('div',{staticClass:"w-full flex items-center text-text-secondary text-xs mb-1"},[(_vm.$slots['line-1-left'])?_c('span',{staticClass:"flex-1 block truncate"},[_vm._t("line-1-left")],2):_vm._e(),_vm._v(" "),(_vm.$slots['line-1-right'])?_c('span',{staticClass:"flex-none ml-1 block truncate"},[_vm._t("line-1-right")],2):_vm._e()]),_vm._v(" "),(_vm.$slots['line-2'])?_c('p',{staticClass:"truncate flex-1 text-default text-text font-semibold",class:_vm.strikethrough ? ['line-through'] : []},[_vm._t("line-2")],2):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }