import { useCurrentAccount } from '@teamwork/use';
import ko from 'knockout';
import TkoComponent from '@sections/TkoComponent';

// @vue/component
export default {
  components: { TkoComponent },
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    disableResize: { type: Boolean, default: false },
    showToggleOptions: { type: Boolean, default: true },
    quiet: { type: Boolean, default: false },
    wizard: { type: Boolean, default: false },
    showing: { type: Boolean, default: false },
    autoFocusAtEnd: { type: Boolean, default: false },
    dataIdentifier: { type: String, default: '' },
  },
  watch: {
    value(val) {
      if (this.content() !== val) {
        this.content(val);
      }
    },
  },
  setup() {
    const account = useCurrentAccount();

    return {
      account,
    };
  },
  methods: {
    onBlur(value) {
      this.$emit('blur', value);
    },
  },
  created() {
    this.disposes = [];
    this.content = ko.observable(this.value);
    this.disposes.push(
      this.content.subscribe((val) => {
        if (this.value !== val) {
          this.$emit('input', val);
        }
      }),
    );
  },
  destroyed() {
    this.disposes.forEach((s) => s.dispose());
  },
};
