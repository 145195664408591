import { ref } from 'vue-demi';
import useStore from '@/platform/composables/useStore';

const activeProductTourId = ref(null);

export default function useProductTour() {
  const store = useStore();

  function setActiveProductTourId(newProductTourId) {
    activeProductTourId.value = newProductTourId;
  }

  async function updateTourPreferences(productTourId) {
    const productToursCompleted = [
      ...store.state.preferences.user.productToursCompleted,
      productTourId,
    ];

    store.commit('preferences/change', {
      prefs: {
        productToursCompleted,
      },
      scope: 'user',
    });

    await store.dispatch('preferences/persist', {
      prefs: {
        productToursCompleted,
      },
      scope: 'user',
    });
  }

  return {
    activeProductTourId,
    setActiveProductTourId,
    updateTourPreferences,
  };
}
