<template>
  <div
    class="notifications-list"
    :class="[
      {
        'notifications-list--empty': shouldShowBlankSlate,
      },
    ]"
    :style="style"
  >
    <template v-if="shouldShowBlankSlate">
      <BlankSlate
        :is-in-popover="true"
        :title="blankStateTitle"
        :msg="blankStateMessage"
        :has-active-filter="hasActiveFilter"
        :allow-clear-filter="hasActiveFilter"
        :clear-filter="clearFilter"
        item-text-plural="notifications"
      >
        <template #image>
          <slot name="blank-state-image" />
        </template>
      </BlankSlate>
    </template>
    <template v-else>
      <div class="notifications-list__separator"></div>
      <transition-group
        :name="disableTransition ? null : transition"
        tag="div"
        :style="{
          position: 'relative',
        }"
        type="animation"
      >
        <template v-for="notification in notificationsList">
          <NotificationsListItem
            :data-identifier-prefix="dataIdentifierPrefix"
            :notification="notification"
            :key="notification.id"
            :data-id="notification.id"
            :loading="notification.isPlaceholder"
            :disable-sub-element-click="disableSubElementClick"
            @status-click="onStatusClicked"
          />
        </template>
      </transition-group>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import {
  computed,
  onMounted,
  ref,
  shallowRef,
  Vue2 as Vue,
  watch,
} from 'vue-demi';

import BlankSlate from '@widgets/BlankSlate';
import NotificationsListItem from '@sections/NotificationsListItem';
import { useNotificationsManager } from '@sections/Notifications/useNotificationsManager';

export default {
  name: 'NotificationsList',
  props: {
    notifications: {
      type: Array,
      required: false,
      default: () => [],
    },
    newNotificationIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    dataIdentifierPrefix: {
      type: [String, Number],
      required: false,
      default: '',
    },
    allLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBellEmptyStateImg: {
      type: Boolean,
      required: false,
      default: false,
    },
    transition: {
      type: String,
      required: false,
      default: 'shrink',
      validator(v) {
        return v && ['slide-left', 'slide-right', 'shrink'].includes(v);
      },
    },
    transitionDuration: {
      type: Number,
      required: false,
      default: 360,
    },
    blankStateTitle: {
      type: String,
      required: true,
    },
    blankStateMessage: {
      type: String,
      required: true,
    },
    /*
     * Determines whether or not entire notification items should be clickable or
     * their sub-elements i.e. quick-view buttons and title links
     */
    disableSubElementClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BlankSlate,
    NotificationsListItem,
  },
  setup(props, { emit }) {
    const item = ref([]);
    const observed = new Map();
    const disableTransition = shallowRef(true);

    const NotificationsManager = useNotificationsManager();

    const shouldShowBlankSlate = computed(() => {
      return !props.notifications.length && !props.isLoading;
    });

    const notificationsList = computed(() => {
      if (!props.notifications) {
        return [];
      }

      return props.notifications;
    });

    /*
     * Temporarily enable transition styles so that notifications can slide out when
     * marked as read/unread only
     */
    let transitionLockTimeout = null;
    function executeTransition() {
      clearTimeout(transitionLockTimeout);

      disableTransition.value = false;

      transitionLockTimeout = setTimeout(() => {
        disableTransition.value = true;
      }, props.transitionDuration);
    }

    function onStatusClicked() {
      executeTransition();
    }

    onMounted(() => {
      /*
       * Register InteractionObserver to track which notifications have been viewed. Flag any new
       * notifications received whilst popover is open
       */
      const observer = new IntersectionObserver(
        (entries, _observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              _observer.unobserve(entry.target);

              observed.value.set(Number(entry.target.dataset.id), true);

              emit('new-notification-observe', Number(entry.target.dataset.id));
            }
          });
        },
        {
          root: null,
          threshold: 1.0,
        },
      );

      /*
       * Start observing any new notifications received post-mount
       */
      watch(
        () => props.newNotificationIds,
        () => {
          props.newNotificationIds.forEach((id) => {
            if (observed.get(Number(id))) {
              return;
            }

            const $el = this.$el.querySelector(
              `.notifications-list-item[data-id="${id}"]`,
            );

            if (!($el instanceof HTMLElement)) {
              return;
            }

            observed.set(Number(id), false);
            observer.observe($el);
          });
        },
        { immediate: true },
      );
    });

    const style = computed(() => {
      return {
        '--transition-duration': `${props.transitionDuration}ms`,
      };
    });

    function formatRelativeDate(date) {
      switch (
        date.clone().startOf('day').diff(moment().startOf('day'), 'days')
      ) {
        case 0:
          return Vue.t('Today');
        case 1:
          return Vue.t('Tomorrow');
        case -1:
          return Vue.t('Yesterday');
        default:
          return date.format('dddd, MMMM Do');
      }
    }

    return {
      style,
      disableTransition,
      item,
      notificationsList,
      observed,
      onStatusClicked,
      shouldShowBlankSlate,
      clearFilter: NotificationsManager.filter.clearAdvanced,
      hasActiveFilter: NotificationsManager.filter.hasActiveFilter,
      formatRelativeDate,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/_variables';
@import '~@tko/src/styles/variables/dimensions/_typography.dimensions';
@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

.notifications-list {
  padding-bottom: 0;
}

.notifications-list--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 0;
}

.notifications-list__separator {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px; /* 24px gap; compensating for spacing within text */
  padding-bottom: 8px; /* 24px gap; compensating for spacing within below item */
}

.u-blank-slate {
  margin: 0;
  height: 100%;
}

/*************************** Transitions ***************************/
$transition-duration: var(--transition-duration);

/***************** Slide ******************/
.slide-left-move,
.slide-right-move {
  transition: transform $transition-duration;
}

.slide-left-leave-active {
  animation-name: slide-left;
  animation-duration: $transition-duration;
}

.slide-right-leave-active {
  animation-name: slide-right;
  animation-duration: $transition-duration;
}

@keyframes slide-left {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
}

@keyframes slide-right {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(100%, 0);
  }
}

/***************** Shrink ******************/
.shrink-move {
  transition: transform $transition-duration;
}

.shrink-leave-active {
  animation-name: shrink;
  animation-duration: $transition-duration;
  position: absolute;
  width: calc(100% - 6px); // Compenstate for scroll bar width
}

@keyframes shrink {
  from {
    opacity: 1;
    transform-origin: top;
    transform: scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    transform-origin: top;
    transform: scale3d(0, 0, 0);
  }
}
</style>
