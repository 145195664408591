import { isObservable, toJS } from 'knockout';
import { passive } from '@/utils/helpers/promise';
/* eslint-disable no-unused-vars */

/**
 * This maps global TKO variables to the a Vuex store
 *
 * This should only be used for layout components where we don't want to
 * duplicate or hijack requests, but rather use the existing logic form inside TKO.
 *
 * @param store
 */
const subscriptions = [];

// Map TKO app variables to store mutations.
const tkoAppGlobalsMap = {
  isBoardsView: 'layout/isBoardsView',
  isPlannerView: 'layout/isPlannerView',
  sidebarClosed: {
    commit: 'layout/isSidebarCollapsed',
    getter: (state) => state.layout.isSidebarCollapsed,
  },
  filterSidebarVisible: 'layout/filterSidebarVisible',
  customCSS: 'layout/customCSS',
  pageComponentToShow: 'layout/pageComponentToShow',
  shouldHideFooter: 'layout/shouldHideFooter',
  shouldShowPrimaryNav: 'layout/shouldShowPrimaryNav',
  showBacklog: 'layout/showBacklog',
  isShowingFullScreenMode: 'layout/isShowingFullScreenMode',
  isTrialExpired: 'layout/isTrialExpired',
  newVersionAvailable: 'layout/isNewVersionAvailable',
  hasOutageErrorMessage: 'layout/hasOutageErrorMessage',
  bodyId: 'layout/bodyId',
  isEmbeddedChatEnabled: {
    commit: 'layout/isEmbeddedChatEnabled',
    getter: (state) => state.layout.isEmbeddedChatEnabled,
  },
  isEmbeddedChatConversationVisible: {
    commit: 'layout/isEmbeddedChatConversationVisible',
    getter: (state) => state.layout.isEmbeddedChatConversationVisible,
  },
  isEmbeddedChatConversationListVisible: {
    commit: 'layout/isEmbeddedChatConversationListVisible',
    getter: (state) => state.layout.isEmbeddedChatConversationListVisible,
  },
  currentRoute: 'routes/tkoCurrentRoute',
  theme: 'branding/theme',
  'quickView.views': 'layout/hasOpenQuickView',
  isPaymentOverDue: 'layout/isPaymentOverDue',
  priceplanType: 'account/priceplanType',
  isQuickAddOpen: 'layout/isQuickAddOpen',
  quickAddProject: 'layout/quickAddProject',
  modals: 'layout/areTkoModalsOpen',
  isShowingQuickJump: 'layout/isShowingQuickJump',
  enforceProject: 'layout/enforceProject',
  enforceProjectId: 'layout/enforceProjectId',
};

const getStoreKey = (storeMapValue) => {
  if (typeof storeMapValue === 'string') {
    return storeMapValue;
  }
  if (storeMapValue.commit != null) {
    return storeMapValue.commit;
  }
  return null;
};

const subscribeAppGlobals = (app, store) => {
  Object.keys(tkoAppGlobalsMap).forEach((key) => {
    const tkoGlobal = key
      .split('.')
      .reduce(
        (acc, curr) => (isObservable(acc) ? acc()[curr] : acc[curr]),
        app,
      );

    if (!(tkoGlobal && isObservable(tkoGlobal))) {
      return;
    }
    const storeMapValue = tkoAppGlobalsMap[key];
    const storeKey = getStoreKey(storeMapValue);
    // If the value in tkoAppGlobalsMap has a `getter` defined
    // then we watch it and sync its value to its TKO equivalent
    if (Object.prototype.hasOwnProperty.call(storeMapValue, 'getter')) {
      store.watch(storeMapValue.getter, (newVal) => {
        tkoGlobal(newVal);
      });
    }
    store.commit(storeKey, toJS(tkoGlobal));

    // Watch all observable changes
    subscriptions.push(
      tkoGlobal.subscribe((val) => store.commit(storeKey, toJS(val))),
    );
  });
};

// A convienence, a promise for TKO essential data,
// this way it is easy to just await it. Otherwise we'd
// have to setup a store watcher which is more hassle.
export const essentialDataIsLoaded = passive();

export const mapTkoGlobals = (store) => {
  const { app } = window;

  // we need to wait until essentialData is loaded before syncing anything
  // this is due to accessing computed observables before the app is ready.
  const essentialDataIsLoadedSubscription = app.essentialDataIsLoaded.subscribe(
    (val) => {
      if (val) {
        store.commit('layout/essentialDataIsLoaded', val);
        essentialDataIsLoaded.resolve();

        subscribeAppGlobals(app, store);
        // we can dispose of this right away as it's just for initial load.
        essentialDataIsLoadedSubscription.dispose();
      }
    },
  );
};

export const disposeTkoGlobals = () => {
  subscriptions.forEach((sub) => sub.dispose());
};

export default {
  mapTkoGlobals,
  disposeTkoGlobals,
};
