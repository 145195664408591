<template>
  <div class="custom-field-dropdown">
    <OptionPicker
      v-on="$listeners"
      :value="board"
      :options="options"
      no-arrow
      show-dot
      :block="false"
      :disabled="disabled"
    >
      <template #trigger>
        <div class="board-container">
          <div
            v-if="board"
            :class="`flex h-6 ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`"
          >
            <div
              class="
                grid
                content-center
                justify-center
                rounded-full
                w-6
                h-6
                border-gray
              "
            >
              <div
                class="rounded-full w-2 h-2"
                :style="`background-color: ${column.color}`"
              ></div>
            </div>
            <span class="ml-2 place-self-center">
              {{ column.name }}
            </span>
          </div>
          <AddButton :disabled="disabled" v-else>
            {{ $t('Add board column') }}
          </AddButton>
        </div>
      </template>
    </OptionPicker>
  </div>
</template>

<script>
import { useBoardColumnsLoader } from '@teamwork/use';
import { computed, defineComponent, toRef, Vue2 as Vue } from 'vue-demi';
import OptionPicker from '@widgets/OptionPicker';
import AddButton from '../AddButton/AddButton';

export default defineComponent({
  name: 'BoardColumn',

  props: {
    projectId: { type: Number, required: true },
    column: { type: Object, default: null },
    disabled: { type: Boolean, default: false },
  },

  components: {
    OptionPicker,
    AddButton,
  },

  setup(props) {
    const projectId = toRef(props, 'projectId');
    const { items: boardColumns } = useBoardColumnsLoader({
      projectId,
      count: 100,
    });

    const board = computed(() => (props.column ? props.column.id : ''));
    const options = computed(() => [
      {
        id: 0,
        label: Vue.t('None'),
        background: '#F5F7FA',
        dotColor: 'transparent',
      },
      ...boardColumns.value.map((column) => ({
        id: column.id,
        label: column.name,
        background: '#F5F7FA',
        dotColor: column.color,
      })),
    ]);

    return {
      options,
      board,
    };
  },
});
</script>

<style scoped lang="scss">
$hover-color: #4461d7;
$border-color: #e1e6ee;
// .cursor-not-allowed {
//   cursor: not-allowed !important;
// }
// .cursor-pointer {
//   cursor: pointer !important;
// }
.custom-field-dropdown {
  width: 100%;
  padding: 0;
}

.border-gray {
  border: 1px solid $border-color;
}

.board-container {
  &:hover {
    color: $hover-color;
    .border-gray {
      border: 1px solid $hover-color;
    }
  }
}
</style>
