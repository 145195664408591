/**
 * Allows an action in Vuex to register a watch condition on the store.
 *
 * Currently these watches are never destroyed, their current usage does
 * not require it - but this can be added when/if needed.
 */
const watches = new WeakMap();
let store;

const watching = ({ state, getter }) =>
  watches.has(state) && watches.get(state).get(getter);

/**
 * @param state {Object} This is the key object for the WeakMap
 * @param getter {Function}
 * @param action {Function} Should only be a dispatch for safety
 * @param id {Number}
 * @param json {Boolean} True to watch for material JSON data changes (not just rebuilt objects)
 */
const watch = ({ state, getter, action, id, json }) => {
  if (watching({ state, getter })) {
    return;
  }
  const resolvedGetter =
    id && typeof getter(id) === 'function' ? getter(id) : getter;
  const finalGetter = json
    ? (...args) => JSON.stringify(resolvedGetter(...args))
    : resolvedGetter;
  const unwatcher = store.watch(finalGetter, action);
  if (!watches.has(state)) {
    watches.set(state, new WeakMap());
  }
  watches.get(state).set(getter, unwatcher);
};

const unwatch = ({ state, getter }) => {
  const unwatcher = watching({ state, getter });
  if (unwatcher) {
    watches.get(state).delete(getter);
    unwatcher();
  }
};

const registerStore = (st) => {
  store = st;
};

export { watching, watch, unwatch, registerStore };
