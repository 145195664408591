/* eslint-disable no-underscore-dangle, no-use-before-define */
import Vue from 'vue';
import { unref } from 'vue-demi';
import { get, isPlainObject } from 'lodash-es';

export const NOTIFICATION_ITEM_TYPES = Object.freeze({
  comment: 'comment',
  dashboard: 'dashboard',
  event: 'event',
  file: 'file',
  fileversion: 'fileversion',
  form: 'form',
  integration: 'integration',
  invoice: 'invoice',
  link: 'link',
  message: 'message',
  milestone: 'milestone',
  notebook: 'notebook',
  project: 'project',
  reply: 'reply',
  status: 'status',
  statusrequest: 'statusrequest',
  statusupdate: 'statusupdate',
  task: 'task',
  tasklist: 'tasklist',
});

export const NOTIFICATION_GROUPS = Object.freeze({
  comment: 'comment',
  event: 'event',
  file: 'file',
  form: 'form',
  integration: 'integration',
  invoice: 'invoice',
  link: 'link',
  message: 'message',
  milestone: 'milestone',
  notebook: 'notebook',
  project: 'project',
  reaction: 'reaction',
  status: 'status',
  task: 'task',
  tasklist: 'tasklist',
});

export function getItemTypeForNotification(notification) {
  return notification.itemType.toLowerCase();
}

export function getActionTypeForNotification(notification) {
  return notification.actionType.toLowerCase();
}

export function getReactionTypeForNotification(notification) {
  if (getGroupForNotification(notification) !== NOTIFICATION_GROUPS.reaction) {
    return null;
  }

  const reactionType = notification.extraDesc.toLowerCase();
  if (['frown', 'joy', 'heart', 'like', 'dislike'].includes(reactionType)) {
    return reactionType;
  }

  return null;
}

export function getGroupForNotification(notification) {
  const itemTypeGroupMap = {
    [NOTIFICATION_ITEM_TYPES.comment]: NOTIFICATION_GROUPS.comment,
    [NOTIFICATION_ITEM_TYPES.event]: NOTIFICATION_GROUPS.event,
    [NOTIFICATION_ITEM_TYPES.file]: NOTIFICATION_GROUPS.file,
    [NOTIFICATION_ITEM_TYPES.fileVersion]: NOTIFICATION_GROUPS.file,
    [NOTIFICATION_ITEM_TYPES.form]: NOTIFICATION_GROUPS.form,
    [NOTIFICATION_ITEM_TYPES.integration]: NOTIFICATION_GROUPS.integration,
    [NOTIFICATION_ITEM_TYPES.invoice]: NOTIFICATION_GROUPS.invoice,
    [NOTIFICATION_ITEM_TYPES.link]: NOTIFICATION_GROUPS.link,
    [NOTIFICATION_ITEM_TYPES.message]: NOTIFICATION_GROUPS.message,
    [NOTIFICATION_ITEM_TYPES.milestone]: NOTIFICATION_GROUPS.milestone,
    [NOTIFICATION_ITEM_TYPES.notebook]: NOTIFICATION_GROUPS.notebook,
    [NOTIFICATION_ITEM_TYPES.project]: NOTIFICATION_GROUPS.project,
    [NOTIFICATION_ITEM_TYPES.reply]: NOTIFICATION_GROUPS.message,
    [NOTIFICATION_ITEM_TYPES.status]: NOTIFICATION_GROUPS.status,
    [NOTIFICATION_ITEM_TYPES.statusrequest]: NOTIFICATION_GROUPS.status,
    [NOTIFICATION_ITEM_TYPES.statusupdate]: NOTIFICATION_GROUPS.status,
    [NOTIFICATION_ITEM_TYPES.task]: NOTIFICATION_GROUPS.task,
    [NOTIFICATION_ITEM_TYPES.tasklist]: NOTIFICATION_GROUPS.tasklist,
  };

  const itemType = getItemTypeForNotification(notification);

  // Special case for reactions
  if (
    getActionTypeForNotification(notification) === 'reacted' &&
    notification.extraDesc.length
  ) {
    return NOTIFICATION_GROUPS.reaction;
  }

  if (itemType.includes('comment')) {
    return NOTIFICATION_GROUPS.comment;
  }

  if (itemType.includes('project')) {
    return NOTIFICATION_GROUPS.project;
  }

  return itemTypeGroupMap[itemType] || undefined;
}

// eslint-disable-next-line import/prefer-default-export
export function getEventTitle(event, loggedInUserId) {
  let extraInfo;
  let extraInfoData;
  let fullActionText;
  let taskAssignedToUserId;
  let itemType = event.itemType;

  if (event.actionType === 'statusrequest') {
    return Vue.t('Request to update your status');
  }

  if (event.actionType === 'reminder') {
    switch (event.itemType) {
      case 'task':
        return Vue.t('Task Reminder');
      case 'event':
        return Vue.t('Event Reminder');
      case 'milestone':
        return Vue.t('Milestone Reminder');
      default:
        break;
    }
  }

  if (event.itemType.toLowerCase().indexOf('comment') !== -1) {
    if (
      typeof event.itemLink === 'string' &&
      event.itemLink.toLowerCase().indexOf('message') !== -1
    ) {
      itemType = 'reply';
    } else {
      itemType = 'comment';
    }
  } else if (event.itemType.toLowerCase() === 'fileversion') {
    itemType = 'file';
  } else if (event.itemType.toLowerCase() === 'statusupdate') {
    itemType = 'status';
  } else if (event.itemType.toLowerCase() === 'tasklist') {
    itemType = 'task list';
  } else if (event.itemType.toLowerCase() === 'projectupdate') {
    itemType = 'project update';
  }

  const action = (() => {
    switch (event.actionType) {
      case 'like':
        return 'liked';
      case 'new':
        return 'added';
      case 'reacted':
        return 'reacted to';
      default:
        return event.actionType;
    }
  })();

  if (itemType === 'integration') {
    fullActionText = `${action} an ${itemType}`;
  } else {
    fullActionText = `${action} a ${itemType}`;
  }

  if (
    itemType === 'integration' &&
    event.itemLink &&
    event.itemLink.includes(':USER_ID_REPLACEMENT')
  ) {
    // If the property of the notification called 'event' was named 'integration-enabled' we
    // link to the users personal integration page -> "/people/:USER_ID/integrations"
    // eslint-disable-next-line no-param-reassign
    event.itemLink = event.itemLink.replace(
      ':USER_ID_REPLACEMENT',
      loggedInUserId,
    );
  }

  extraInfoData = null;

  if (
    event.extraInfo !== null &&
    typeof event.extraInfo === 'string' &&
    event.extraInfo !== ''
  ) {
    extraInfo = JSON.parse(event.extraInfo);
    if (
      get(extraInfo, 'data') &&
      typeof extraInfo.data === 'string' &&
      event.extraInfo.data !== ''
    ) {
      extraInfoData = JSON.parse(extraInfo.data);
    }
  } else if (get(event, 'extraInfo.data')) {
    extraInfoData = event.extraInfo.data;
  }

  taskAssignedToUserId = [];

  if (get(extraInfoData, 'taskAssignedToUserId')) {
    taskAssignedToUserId = extraInfoData.taskAssignedToUserId;
    if (typeof taskAssignedToUserId === 'string') {
      taskAssignedToUserId = taskAssignedToUserId.split(',').map(Number);
    } else if (typeof taskAssignedToUserId === 'number') {
      taskAssignedToUserId = [taskAssignedToUserId];
    }
  }

  if (taskAssignedToUserId !== null && taskAssignedToUserId.length > 0) {
    if (taskAssignedToUserId.includes(loggedInUserId)) {
      if (action === 'added') {
        fullActionText = 'added a task for you';
      } else {
        fullActionText = 'assigned a task to you';
      }
    }
  }

  return `${event.userFName} ${fullActionText}`;
}

// eslint-disable-next-line no-unused-vars
export function shouldAddNewNotificationFromRealTimeUpdate(
  currentUser,
  currentAccount,
  event,
  rawEvent,
) {
  if (!rawEvent) {
    return false;
  }

  if (!rawEvent?.eventInfo || !rawEvent?.eventInfo?.popup) {
    return false;
  }

  // Only handle notification not originating from this user
  if (
    parseInt(rawEvent?.eventInfo?.userId, 10) ===
    parseInt(currentUser.value.id, 10)
  ) {
    return false;
  }

  return true;
}

export function getLinkForNotificationItem(_currentUser, notification) {
  const currentUser = unref(_currentUser);
  if (!isPlainObject(currentUser)) {
    return undefined;
  }

  if (!isPlainObject(notification)) {
    return undefined;
  }

  // Ignore these item types
  if (
    ['status', 'statusupdate'].includes(notification?.item?.type.toLowerCase())
  ) {
    return undefined;
  }

  // Ignore deleted items
  if (notification?.action === 'deleted') {
    return undefined;
  }

  function transformLink(link) {
    let _link = link;

    _link = _link.toLowerCase().replace(':user_id_replacement', currentUser.id);

    if (!_link.startsWith('/')) {
      _link = `/${_link}`;
    }

    return _link;
  }

  if (notification?.link) {
    return transformLink(notification.link);
  }

  if (notification?.item?.link) {
    return transformLink(notification.item.link);
  }

  return undefined;
}
