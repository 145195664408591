<template>
  <ul class="comments">
    <li v-for="(comment, index) in comments" :key="index">
      <Chip
        :image="`https://i.pravatar.cc/150?img=${comment.avatar}`"
        size="medium"
      />
      <div class="comments__body">
        <div class="comments__body--header">
          {{ comment.name }} &nbsp; {{ comment.time }}
        </div>
        <div class="comments__body--comment">
          {{ comment.message }}
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { Chip } from '..';

export default {
  name: 'Comments',
  display: 'Comments',
  props: {
    comments: { type: Array, required: true, default: () => [] },
  },
  components: { Chip },
};
</script>
<style lang="scss" scoped>
.comments {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    margin-bottom: 16px;
  }

  &__body {
    margin-left: 16px;

    &--header {
      font-size: 12px;
      line-height: 18px;
      color: #6d6f80;
    }

    &--comment {
      font-size: 14px;
      line-height: 18px;
      color: #0b0e1f;
    }
  }
}
</style>
