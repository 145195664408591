<template>
  <span
    class="
      text-white text-[12px]
      leading-[21px]
      py-1
      px-3
      rounded-full
      mr-2
      inline-block
    "
    :class="type"
  >
    {{ label }}
  </span>
</template>
<script>
export default {
  name: 'TagPill',
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
<style scoped>
.primary {
  background-color: #ff61c7;
}
.secondary {
  background-color: #895ef7;
}
.dark {
  background-color: #0b0e1f;
}
</style>
