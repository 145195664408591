/* eslint-disable no-param-reassign,no-return-assign */
import Vue from 'vue';
import { createListLoader, createRecordLoader } from '@/store/utils/loader';
import typedDefaults from '@/utils/helpers/typed-defaults';

const defaultTypes = {
  parentId: 0,
  name: '',
  count: 0,
  elementsCount: 0,
  id: 0,
  color: '',
  type: 'ProjectCategory',
};

export default {
  namespaced: true,
  modules: {
    single: createRecordLoader({
      config: {
        url: (id) => `/projectcategories/${id}.json`,
      },
    }),
    loader: createListLoader({
      namespaced: true,
      // no id, always update if initialised
      config: {
        url: '/projectcategories.json',
        module: 'category',
        sort: false,
        mapResponse: (ctx, rs) => {
          ctx.commit('category/headers', rs.headers, {
            root: true,
            recordMap: false,
          });
          return rs.data.categories;
        },
      },
    }),
  },
  state: {
    records: {},
    totalProjectsCategorized: 0,
    totalProjectsUncategorized: 0,
  },
  mutations: {
    headers(state, headers) {
      state.totalProjectsCategorized = headers['x-total-projects-categorized'];
      state.totalProjectsUncategorized =
        headers['x-total-projects-uncategorized'];
    },
    records(state, categories) {
      const newCats = {};
      categories.forEach((cat) => {
        newCats[cat.id] = typedDefaults(defaultTypes, cat);
      });
      state.records = { ...state.records, ...newCats };
    },
    record(state, cat) {
      const newCat = typedDefaults(defaultTypes, cat);
      Vue.set(state.records, newCat.id, newCat);
    },
    delete(state, id) {
      Vue.delete(state.records, id);
    },
  },
};
