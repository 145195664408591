<template>
  <div
    class="table-cell-privacy-column"
    :class="{ 'privacy-padding': showLabel }"
  >
    <v-popover
      :placement="openDirection"
      trigger="manual"
      :boundaries-element="boundariesElement"
      :delay="{ hide: 300, show: 100 }"
      :open="isOpen"
      popover-arrow-class="hidden"
      @show="onShow"
      @hide="onHide"
    >
      <div
        @mouseenter="show"
        @mouseleave="hide"
        class="privacy-label"
        :class="{
          'privacy-label-bg privacy-label-padding': showLabel,
          'cursor-wait': isLoading,
        }"
      >
        <CommonIcon
          id="lock-solid"
          class="lock-icon"
          :color="
            showLabel ? '#fff' : args.privacyIsInherited ? '#8f9bb3' : '#ED818E'
          "
        />
        <span v-if="showLabel" class="font-size--small privacy-label-text">
          {{ $t('Private') }}
        </span>
      </div>
      <template slot="popover">
        <div class="privacy-popover" @mouseenter="show" @mouseleave="hide">
          <template>
            <div class="privacy-popover-header">
              <h3 class="privacy-popover-title">{{ $t('Private for') }}:</h3>
              <div
                class="privacy-popover-inherited"
                v-if="args.privacyIsInherited"
              >
                {{ $t('Inherited') }}
              </div>
              <button
                v-else
                class="btn btn-xs btn-default"
                @click="editPrivacy"
              >
                {{ $t('Edit') }}
              </button>
            </div>
            <div>
              <p v-if="isLoading">{{ $t('Loading') }}</p>
            </div>
            <ul class="privacy-popover-list" v-if="lockdownItems.length">
              <li v-for="item in lockdownItems" :key="item.id">
                <div class="popover-avatar">
                  <img
                    v-if="item.type === 'user'"
                    :src="
                      item.avatar ||
                      '//cdn.teamwork.com/images/icons/company.png'
                    "
                    v-img-load-fail="'person'"
                  />
                  <img
                    v-else-if="item.type === 'company'"
                    :src="'//twa-prod.teamwork.com/tko/public/assets/svg/company-regular.svg'"
                  />
                  <TeamLogo
                    v-else-if="item.type === 'team'"
                    :team="item"
                    size="list-smaller"
                  />
                </div>
                <span class="popover-name">
                  {{ item.name }}
                </span>
                <span v-if="item.type === 'user'" class="popover-company-name">
                  &nbsp; ({{ item.companyName }})
                </span>
              </li>
            </ul>
          </template>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import Vue from 'vue';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import TeamLogo from '@widgets/TeamLogo';
import TasksAPI from '@/platform/data/tasks';

export default {
  name: 'Privacy',
  display: 'Privacy',
  props: {
    args: { type: Object, required: true },
    openDirection: { type: String, default: 'top-middle' },
    showLabel: { type: Boolean, required: false, default: true },
  },
  components: { CommonIcon, TeamLogo },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isLoaded: false,
      lockdownItems: [],
      debounce: null,
    };
  },
  computed: {
    boundariesElement() {
      return document.body;
    },
  },
  methods: {
    editPrivacy() {
      this.isOpen = false;

      this.$store.dispatch('modals/tko/open', {
        id: 'editPrivacy',
        args: {
          ...this.args,
          onCloseCallBack: (result) => {
            if (result.wasCancelled) {
              return;
            }
            this.load();
          },
        },
      });
    },
    onShow() {
      if (this.isLoaded) {
        return;
      }
      this.load();
    },
    onHide() {
      this.isLoaded = false;
    },
    load() {
      this.isLoading = true;
      TasksAPI.getLockdown(this.args.lockdownId)
        .then((response) => {
          if (response.data.STATUS === 'OK') {
            this.lockdownItems = response.data.lockdown.items;
            this.isLoaded = true;
          }
        })
        .catch(() => {
          this.$store.dispatch(
            'notifications/flashes/error',
            Vue.t('Failed to load privacy information'),
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    show() {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.isOpen = true;
    },
    hide() {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }

      // required in order to not trigger the hide() method when
      // the popover is opening and it's animating under the mouse
      // thus triggering it
      this.debounce = setTimeout(() => {
        this.isOpen = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

.table-cell-privacy-column {
  &.privacy-padding {
    padding: 0 10px;
  }
}
.privacy-label {
  margin-top: 3px;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  border-radius: 10px;
  line-height: 1;
  user-select: none;
  cursor: pointer;
  &.cursor-wait {
    cursor: wait;
  }
  &.privacy-label-bg {
    background-color: #ed818e;
  }
  &.privacy-label-padding {
    padding: 3px 6px;
  }
  .lock-icon {
    width: $font-size--btn !important;
    height: $font-size--btn !important;
  }
  .privacy-label-text {
    margin-left: $margin--small;
  }
}

.privacy-popover {
  min-width: 200px;
  max-width: 280px;
  padding: $padding--large;
  .privacy-popover-header {
    font-size: $font-size--btn;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .privacy-popover-inherited {
      color: #8f9bb3;
    }

    .btn {
      padding: 0px;
      color: #4461d7;

      &:hover {
        color: #4461d7;
        text-decoration: underline;
      }
    }
  }
  .privacy-popover-title {
    color: $color--text-tinted-dark;
    font-size: $font-size--default;
    font-weight: $font-weight--semibold;
    margin-bottom: 0;
    margin-bottom: 0;
  }
  .privacy-popover-list {
    list-style-type: none;
    font-size: $font-size--default;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0;
    li {
      padding-top: 12px;
      display: flex;
      align-items: center;
      color: $color--text-tinted-dark;
      &:last-child {
        border: 0;
      }
    }
    .popover-name {
      max-width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .popover-company-name {
      color: #646d7d;
    }
    .popover-avatar {
      margin-right: $margin--medium;
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 50%;
        height: 100%;
        width: inherit;
      }
    }
  }
}
</style>
