import {
  computed,
  del,
  inject,
  isRef,
  provide,
  ref,
  set,
  shallowRef,
  unref,
  watch,
} from 'vue-demi';

const useTableViewSymbol = Symbol('useTableView');

const { hasOwnProperty } = Object.prototype;

const baseColumn = {
  // These properties CAN be defined in `columns` provided to `provideTableView`.
  id: undefined, // A unique column ID.
  name: undefined, // The column name for display.
  tooltip: undefined, // The tooltip for the column.
  sortKey: '', // The key used for sorting.
  width: '200px', // Default column width.
  minWidth: '0%', // Min column width.
  maxWidth: '100%', // Max column width.
  enabled: true, // Should this column be displayed.
  sticky: false, // Should this column stick to the left when scrolling horizontally.
  draggable: true, // Can a user reorder this column by dragging.
  resizable: true, // Can a user resize this column.
  toggleable: true, // Can a user enable/disable this column.
  order: 0, // Determines the column order.
  headerCellCenter: false, // Is this cell centered in the column.

  // These properties MUST NOT be defined in `columns` provided to `provideTableView`.
  computedWidth: 0, // The computed column width in pixels.
  left: undefined, // If `sticky === true`, the value for the CSS `left` property in pixels.
};

/**
 * Returns a valid subset of the the provided columns.
 * It is intended for validating column overrides provided by the client code.
 */
function getValidColumns(columnsOrRef) {
  const columns = unref(columnsOrRef);
  const validColumns = {};

  if (columns && typeof columns === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const id in columns) {
      if (hasOwnProperty.call(columns, id)) {
        const column = columns[id];
        const validColumn = {};
        validColumns[id] = validColumn;

        if (
          hasOwnProperty.call(column, 'enabled') &&
          typeof column.enabled === 'boolean'
        ) {
          validColumn.enabled = column.enabled;
        }

        if (
          hasOwnProperty.call(column, 'width') &&
          typeof column.width === 'string'
        ) {
          validColumn.width = column.width;
        }

        // The DataViews v1 API converts numbers to strings in `displaySettings`,
        // so we can't just drop `order`, if it is not a number.
        if (hasOwnProperty.call(column, 'order')) {
          validColumn.order = Number(column.order);
        }
      }
    }
  }

  return validColumns;
}

/**
 * Determines if the specified columns have any significant updates.
 */
function areColumnsUpdated(columnsOrRef) {
  const columns = unref(columnsOrRef);

  // eslint-disable-next-line no-restricted-syntax
  for (const id in columns) {
    if (hasOwnProperty.call(columns, id)) {
      const column = columns[id];
      if (
        hasOwnProperty.call(column, 'enabled') ||
        hasOwnProperty.call(column, 'order')
      ) {
        return true;
      }
    }
  }

  return false;
}

/**
 * Gets the specified column.
 */
function getColumn(columnsOrRef, columnId) {
  const columns = unref(columnsOrRef);
  return columns && hasOwnProperty.call(columns, columnId)
    ? columns[columnId]
    : undefined;
}

/**
 * Sets the specified column property
 * by directly modifying the existing columns object.
 */
function setColumnProperty(columnsRef, columnId, key, value) {
  if (!hasOwnProperty.call(columnsRef.value, columnId)) {
    set(columnsRef.value, columnId, {});
  }
  set(columnsRef.value[columnId], key, value);
}

/**
 * Returns a copy of the existing columns object
 * with the specified column property set to the given value.
 */
function withColumnProperty(columnsOrRef, columnId, key, value) {
  return {
    ...unref(columnsOrRef),
    [columnId]: {
      ...getColumn(columnsOrRef, columnId),
      [key]: value,
    },
  };
}

/**
 * Provides a config object to the TableView component.
 *
 * @param config Object Configuration for the TableView component.
 * @param config.columns Ref<Array<Partial<Column>>> | Array<Partial<Column>>
 *   A read-only ref or an array of available columns.
 * @param config.columnDefaults Ref<Object> | Object | undefined
 *   An optional read-write ref or an object containing column defaults.
 * @param config.columnCache Ref<Object> | undefined
 *   An optional read-write ref or an object containing column modifications.
 * @param config.canResetColumns Ref<boolean> | boolean | undefined
 *   A read-only ref or boolean which determines if column modifications can be reset.
 * @param config.canAutoResetColumns Ref<boolean> | boolean | undefined
 *   A read-write ref or boolean which determines if column modifications can be reset automatically.
 * @param config.canUpdateColumnDefaults Ref<boolean> | boolean | undefined
 *   A read-only ref or boolean whuch determines if `columnDefaults` can be updated
 *   when column modifications are reset.
 */
export function provideTableView({
  columns: inputColumns,
  columnDefaults,
  columnCache: _columnCache,
  canResetColumns: _canResetColumns = false,
  canAutoResetColumns: _canAutoResetColumns = false,
  canUpdateColumnDefaults: _canUpdateColumnDefaults = false,
}) {
  const validColumnDefaults = computed(() => getValidColumns(columnDefaults));
  const columnCache = shallowRef(_columnCache);
  const validColumnCache = computed(() => getValidColumns(columnCache));
  const columnOverrides = ref({});
  const columns = computed(() => {
    const normalizedColumns = unref(inputColumns)
      .map((column) => ({
        ...baseColumn,
        ...column,
        ...getColumn(validColumnDefaults, column.id),
        ...getColumn(validColumnCache, column.id),
        ...getColumn(columnOverrides, column.id),
      }))
      .sort((column1, column2) => column1.order - column2.order);

    let left = 0;
    normalizedColumns.forEach((column) => {
      if (column.enabled && column.sticky) {
        // eslint-disable-next-line no-param-reassign
        column.left = left;
        left += column.computedWidth;
      } else {
        // eslint-disable-next-line no-param-reassign
        column.left = undefined;
      }
    });

    return normalizedColumns;
  });

  const enabledColumns = computed(() =>
    columns.value.filter(({ enabled }) => enabled),
  );
  const columnsUpdated = computed(() => areColumnsUpdated(validColumnCache));
  const canResetColumns = computed(() => Boolean(unref(_canResetColumns)));
  const canAutoResetColumns = computed({
    get() {
      return Boolean(unref(_canAutoResetColumns));
    },
    set(value) {
      if (isRef(_canAutoResetColumns)) {
        // eslint-disable-next-line no-param-reassign
        _canAutoResetColumns.value = Boolean(value);
      }
    },
  });
  const canUpdateColumnDefaults = computed(() =>
    Boolean(unref(_canUpdateColumnDefaults)),
  );

  function updateColumn(columnId, key, value) {
    if (key === 'enabled' || key === 'order' || key === 'width') {
      // eslint-disable-next-line no-param-reassign
      columnCache.value = withColumnProperty(
        validColumnCache,
        columnId,
        key,
        value,
      );
    } else {
      setColumnProperty(columnOverrides, columnId, key, value);
    }
  }

  function reorderColumns(orderedColumnIds) {
    let defaultOrder = orderedColumnIds.length;
    unref(columns).forEach(({ id }) => {
      const order = orderedColumnIds.indexOf(id);
      // Calling `updateColumn` in a loop is inefficient because
      // it copies and validates `columnCache` more then necessary.
      // It should not cause problems in practice given then the number of columns
      // is limited. It could be optimized if needed though.
      if (order >= 0) {
        updateColumn(id, 'order', order);
      } else {
        updateColumn(id, 'order', defaultOrder);
        defaultOrder += 1;
      }
    });
  }

  function resetColumns() {
    if (isRef(columnDefaults) && canUpdateColumnDefaults.value) {
      const newColumnDefaults = {};
      columns.value.forEach(({ id, enabled, order }) => {
        newColumnDefaults[id] = { enabled, order };
      });
      // eslint-disable-next-line no-param-reassign
      columnDefaults.value = newColumnDefaults;
    }

    Object.values(columnCache.value).forEach((column) => {
      del(column, 'enabled');
      del(column, 'order');
    });
  }

  watch(canAutoResetColumns, () => {
    if (canAutoResetColumns.value) {
      resetColumns();
    }
  });

  watch(
    columnCache,
    () => {
      if (columnsUpdated.value && canAutoResetColumns.value) {
        if (canUpdateColumnDefaults.value) {
          resetColumns();
        } else {
          canAutoResetColumns.value = false;
        }
      }
    },
    { deep: true },
  );

  provide(useTableViewSymbol, {
    columns,
    enabledColumns,
    columnsUpdated,
    canResetColumns,
    canAutoResetColumns,
    canUpdateColumnDefaults,
    updateColumn,
    reorderColumns,
    resetColumns,
  });
}

/**
 * Returns a config object provided to the TableView component.
 *
 * @returns An object as follows:
 * {
 *   columns: ShallowRef<Array<Column>> // an immutable managed list of columns
 *   enabledColumns: ShallowRef<Array<Column>> // an immutable managed list of only enabled columns
 *   updateColumn(columnId, propertyName, propertyValue): void
 *   reorderColumns(orderedColumnIds: Array<string>): void
 * }
 */
export function useTableView() {
  return inject(useTableViewSymbol, null);
}
