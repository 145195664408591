<template>
  <button
    :type="type"
    v-on="disabled || loading ? undefined : $listeners"
    :class="buttonClasses"
    :disabled="disabled"
  >
    <span
      v-if="$scopedSlots.left"
      class="inline-flex justify-items-center btn-slot"
    >
      <slot v-if="!loading" name="left" />
      <IconSpinner class="animate-spin" v-else />
    </span>

    <span
      v-if="$scopedSlots.default"
      :class="[{ invisible: loading && !hasSlots }]"
    >
      <slot />
    </span>

    <span
      class="
        animate-spin
        absolute
        object-center
        flex
        justify-items-center
        content-center
        btn-slot
      "
      v-if="loading && !hasSlots"
    >
      <IconSpinner />
    </span>

    <span
      v-if="$scopedSlots.right"
      class="inline-flex justify-items-center btn-slot"
    >
      <slot v-if="!loading" name="right" />
      <IconSpinner class="animate-spin" v-else />
    </span>
  </button>
</template>

<script>
import { computed } from 'vue-demi';
import optionPropValidator from '../../../helpers/optionPropValidator';
import IconSpinner from '~icons/tw/spinner';
import { btnProps, useCommonBtn } from './buttonCommon';
import {
  buttonVariants,
  defaultButtonVariant,
  buttonAlignments,
  defaultButtonAlignment,
} from './consts';

export default {
  name: 'FButton',
  components: { IconSpinner },
  props: {
    ...btnProps,
    variant: {
      type: String,
      default: defaultButtonVariant,
      validator: optionPropValidator(buttonVariants, 'variant'),
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    alignText: {
      type: String,
      default: defaultButtonAlignment,
      validator: optionPropValidator(buttonAlignments, 'alignText'),
    },
  },
  setup(props, { slots }) {
    const { buttonClasses } = useCommonBtn(props);

    const hasSlots = computed(() => {
      return Boolean(slots.left) || Boolean(slots.right);
    });

    return {
      buttonClasses,
      hasSlots,
    };
  },
};
</script>
<style scoped>
.variant-primary:focus-visible,
.variant-secondary:focus-visible,
.variant-warning:focus-visible,
.variant-slate:focus-visible,
.variant-ghost:focus-visible,
.variant-outline:focus-visible {
  position: relative;
}
.variant-primary:focus-visible::after,
.variant-secondary:focus-visible::after,
.variant-warning:focus-visible::after,
.variant-slate:focus-visible::after,
.variant-ghost:focus-visible::after,
.variant-outline:focus-visible::after {
  content: '';
  position: absolute;
  border: 1px solid var(--token-color-white);
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 2px;
}
.variant-primary:focus-visible.rounded-full::after,
.variant-secondary:focus-visible.rounded-full::after,
.variant-warning:focus-visible.rounded-full::after,
.variant-slate:focus-visible.rounded-full::after,
.variant-ghost:focus-visible.rounded-full::after,
.variant-outline:focus-visible.rounded-full::after {
  border-radius: var(--token-border-radius-full);
}
.variant-secondary:focus-visible::after,
.variant-outline:focus-visible::after {
  border-color: var(--token-color-primary);
}

.btn-slot svg {
  height: 16px;
  fill: currentColor;
}
</style>
