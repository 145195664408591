<template>
  <!-- eslindisable vue/no-v-html -->
  <div class="p-6">
    <div>
      <div class="flex justify-between items-center gap-4">
        <h4
          v-if="title"
          id="modal-headline"
          class="text-h-04 font-semibold text-text mb-0"
        >
          {{ title }}
        </h4>
        <FFabButton
          v-if="!persistent && shouldShowCloseButton"
          variant="tertiary"
          size="sm"
          :aria-label="$t('Close modal')"
          @click="closeModal"
        >
          <IconClose class="fill-current" />
        </FFabButton>
      </div>
    </div>
  </div>
</template>

<script>
import FFabButton from '../../actions/FFabButton/FFabButton.vue';
import IconClose from '~icons/tw/close';

export default {
  components: {
    FFabButton,
    IconClose,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    /**
     * Whether the user can close the modal or not.
     */
    persistent: { type: Boolean, default: false },

    /**
     * Whether the close button is shown or not
     */
    shouldShowCloseButton: { type: Boolean, default: true },
    closeModal: { type: Function, required: true },
    // infoTooltipHtml: {
    //   type: String,
    //   default: null,
    // },
  },
};
</script>
