var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('svg',{style:({
    'z-index': ("" + _vm.zIndexOffset),
    position: 'absolute',
    top: ("-" + _vm.borderOffset + "px"),
    left: (_vm.targetOffset + "px"),
  }),attrs:{"width":((_vm.level * _vm.targetWidth + _vm.targetWidth) + "px"),"height":_vm.rowHeight + _vm.verticalOffset + (_vm.lastChild ? _vm.verticalOffset : 0)}},[_vm._l((_vm.level),function(guide){return _c('g',{key:(guide + "-path")},[(guide === _vm.level)?_c('path',{attrs:{"stroke":_vm.strokeColor,"stroke-width":"1","fill":"none","d":("\n        M" + ((guide - 1) * _vm.targetWidth + _vm.targetWidth / 2) + " 0\n        L" + ((guide - 1) * _vm.targetWidth + _vm.targetWidth / 2) + "\n        " + (_vm.rowHeight / 2 - _vm.curveOffset) + "\n        C" + ((guide - 1) * _vm.targetWidth + _vm.targetWidth / 2) + "\n        " + (_vm.rowHeight / 2 + _vm.verticalOffset) + "\n        " + (_vm.level * _vm.targetWidth + _vm.horizontalOffset) + "\n        " + ((_vm.rowHeight + _vm.verticalOffset) / 2) + ",\n        " + (_vm.level * _vm.targetWidth + _vm.horizontalOffset) + "\n        " + ((_vm.rowHeight + _vm.verticalOffset) / 2) + "\n        ")}}):_vm._e(),_vm._v(" "),(!_vm.leafLevels.includes(guide))?_c('path',{attrs:{"stroke":_vm.strokeColor,"stroke-width":"1","fill":"none","d":("\n        M" + ((guide - 1) * _vm.targetWidth + _vm.targetWidth / 2) + " 0\n        V" + (_vm.rowHeight + _vm.verticalOffset + (_vm.lastChild ? _vm.verticalOffset : 0)) + "\n        ")}}):_vm._e()])}),_vm._v(" "),(_vm.open)?_c('line',{style:({
      position: 'absolute',
      left: (_vm.targetOffset + "px"),
    }),attrs:{"x1":((_vm.level * _vm.targetWidth + _vm.targetWidth / 2) + "px"),"y1":((_vm.rowHeight + _vm.verticalOffset) + "px"),"x2":((_vm.level * _vm.targetWidth + _vm.targetWidth / 2) + "px"),"y2":((_vm.rowHeight / 2 + _vm.targetWidth / 2 - _vm.verticalOffset) + "px"),"stroke":_vm.strokeColor}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }