<template>
  <div class="py-16 text-center h-full flex flex-col items-center">
    <NoSearchResultsImage class="w-1/3 mt-300 flex-shrink-0" />
    <p
      class="
        text-text text-default
        leading-default
        px-200
        mb-300
        pt-4
        mx-auto
        w-full
        flex flex-col
        items-center
      "
    >
      <span class="font-semibold block mb-2 w-44">
        {{ $t('No articles found matching your search') }}
      </span>

      <small class="w-52 opacity-80 text-sm font-base">
        <span v-html="$t('Please try again with a different search term')" />
      </small>
    </p>
  </div>
</template>

<script>
import NoSearchResultsImage from '@/assets/images/blankslates/noSearchResults.svg';

export default {
  props: {
    searchTerm: {
      type: String,
      required: false,
      default: '',
    },
    identifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
    headerText: {
      type: String,
      required: false,
      default: "We couldn't find anything matching your search",
    },
  },
  components: {
    NoSearchResultsImage,
  },
};
</script>
