import { useCurrentUser } from '@teamwork/use';
import api, { API_PREFIXES } from '@/services/api';

export default function useCompanyRoleId() {
  const user = useCurrentUser();

  function getCompanyRoleId() {
    return api
      .get(`${API_PREFIXES.v3}/people/${user.value.id}.json`)
      .then(({ data }) => data.person.companyRoleId);
  }

  return {
    getCompanyRoleId,
  };
}
