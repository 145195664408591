<template>
  <h2>{{ title }}</h2>
</template>
<script>
export default {
  name: 'HelpCenterSectionTitle',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  color: #646d7d;
}
</style>
