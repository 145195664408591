<template>
  <div class="w-full mb-2">
    <router-link
      to="/projects/list"
      @click.native="tryhideProjectsPanel"
      class="
        flex
        items-center
        h-[40px]
        transition-colors
        duration-100
        bg-transparent
        hover:bg-[#e4e9ef]
        border border-solid border-primary
        rounded-full
        hover:text-primary
        text-[14px] text-primary
        px-4
        font-semibold
        group
      "
    >
      <span class="flex-auto">
        <span class="block w-full truncate">
          {{ $t('See All Projects') }}
        </span>
      </span>
      <span class="flex-none mr-0 ml-auto">
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          width="6"
          height="10"
          viewBox="0 0 6 10"
        >
          <path
            d="M5.634 5.309 1.34 9.537a.438.438 0 0 1-.62 0L.463 9.28a.438.438 0 0 1 0-.62l3.729-3.66L.465 1.34a.438.438 0 0 1 0-.62l.26-.258a.438.438 0 0 1 .619 0l4.294 4.228a.441.441 0 0 1-.004.62Z"
            fill="#4461D7"
          />
        </svg>
      </span>
    </router-link>
  </div>
</template>

<script>
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

export default {
  name: 'ProjectsPanelAllProjectsNavItem',
  setup() {
    const { tryhideProjectsPanel } = useSidebarPanels();

    return {
      tryhideProjectsPanel,
    };
  },
};
</script>
