<template>
  <li role="none" class="h-[40px] flex w-full rounded-full">
    <component
      :is="linkType"
      tabindex="-1"
      role="menuitem"
      v-bind="link"
      class="
        group
        flex
        items-center
        py-0
        px-4
        w-full
        border-0
        bg-transparent
        rounded-full
      "
      :class="[
        ...(isInActiveList
          ? [
              'bg-palette-indigo-10',
              'hover:bg-palette-indigo-10',
              'focus:bg-palette-indigo-10',
              'text-palette-indigo-40',
              'hover:text-palette-indigo-40',
            ]
          : [
              'bg-transparent',
              'hover:bg-palette-neutral-10',
              'focus:bg-palette-neutral-10',
              'text-text',
              'hover:text-text',
            ]),
      ]"
    >
      <span
        class="
          flex
          items-center
          justify-center
          w-[20px]
          h-[18px]
          mr-200
          flex-grow-0 flex-shrink-0
        "
        :class="[
          isInActiveList
            ? '-ml-[3px] text-palette-indigo-40 fill-palette-indigo-40'
            : 'text-palette-neutral-40 fill-current',
        ]"
      >
        <slot name="icon">icon</slot>
      </span>
      <span class="flex items-center text-text">
        <slot name="title">title</slot>
      </span>
      <span class="ml-auto flex items-center">
        <slot name="additional-icon"></slot>
      </span>
    </component>
  </li>
</template>

<script>
import { computed } from 'vue-demi';
import { useSidebarActiveRoute } from '@/platform/composables/useSidebarActiveRoute';

export default {
  props: {
    route: {
      type: String,
      required: false,
      default: '',
    },

    external: {
      type: Boolean,
      required: false,
      default: false,
    },

    activeForRoutes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props) {
    const { isInActiveList } = useSidebarActiveRoute();

    const linkType = props.external ? 'a' : 'router-link';
    const link = computed(() => {
      if (props.external) {
        return {
          href: props.route,
          target: '_blank',
          rel: 'noopener',
        };
      }

      return {
        to: props.route,
      };
    });

    return {
      link,
      linkType,
      isInActiveList: isInActiveList(props.activeForRoutes),
    };
  },
};
</script>
