"use strict";

define(['stringHelper', 'knockout'], function (strings, ko) {
  var resolveDeepField, splitFirst; // a.b.c -> ['a', 'b.c']
  // a -> ['a']

  splitFirst = function (str, delimiter) {
    var indx;
    indx = str.indexOf(delimiter);

    if (!(indx > -1)) {
      return [str];
    }

    return [str.slice(0, indx), str.slice(indx + 1)];
  }; // a, 'b.c' -> a.b.c


  resolveDeepField = function (obj, path) {
    var parts;
    parts = splitFirst(path, '.');

    if (parts[1] == null) {
      return obj[parts[0]];
    }

    if (obj[parts[0]] != null) {
      return resolveDeepField(obj[parts[0]], parts[1]);
    }

    return null;
  };

  return {
    // Wrapper around vuex dispatch which assumes an Axios promise as the response,
    // this apiDispatcher will normalise the Axios promise to follow the same format
    // as a jQuery Ajax request, this way TKO code which previously used a jQuery Ajax RQ
    // can leverage a store action with Axios request, but keep the same jQuery RS code
    apiDispatcher: function (vuex) {
      return function (action, args, opts = {}) {
        return vuex.dispatch(action, args).then(function (axios) {
          var data;

          if (!axios) {
            return;
          }

          data = (axios[0] || axios).data;
          return {
            response: opts.noCamelize ? data : strings.camelize(data)
          };
        }).catch(function (err) {
          var ref;
          err.status = (ref = err.response) != null ? ref.status : void 0;
          return Promise.reject(err);
        });
      };
    },
    // One way sync from Vuex store state to a new observable
    // vuex - ref to store
    // storeGet - (state) -> value, where value is the data from the store
    stateAsObservable: function (vuex, storeGet, def) {
      var disposeScaffolding, obs;
      obs = ko.observable(def);
      disposeScaffolding = vuex.watch(storeGet, obs); // This is a readonly observable, try to write something to it will
      // trigger the watcher cleanup instead - might need to something cleverer
      // if someone wants to unwrap this or whatever.

      return function (val) {
        if (!val) {
          return obs();
        }

        return disposeScaffolding();
      };
    },
    // ###################################
    // Store/Observable 2-way sync methods
    // ###################################
    // vuex - ref to store
    // storeReady - (store) -> boolean, false if an obs event should be ignored
    // storeGet - (state) -> value, where value is the data from the store
    // storeSet - (val) -> (state) -> state.x = val, the mutation code to update the store
    // obs - TKO Observable to subscribe to and update
    singleSync: function (vuex, storeReady, storeGet, storeSet, obs) {
      var disposeObs, disposeStore;
      disposeObs = obs.subscribe(function (newVal) {
        if (storeReady(vuex)) {
          // storeSync is an open-ended mutation which accepts a function
          // this allows adhoc mutation where that is necessary
          return vuex.commit('storeSync', storeSet(newVal));
        }
      });
      disposeStore = vuex.watch(storeGet, function (newVal) {
        if (obs != null) {
          return obs(newVal);
        }
      }); // Unified dispose function

      return function () {
        disposeObs();
        return disposeStore();
      };
    },
    // vuex - ref to store
    // storeRecord - (state) -> object, where object relates to a record
    // model - object containing observable properties
    // fields - array of strings, properties to map 1-2-1 between the record and model
    storeSync: function (vuex, storeRecord, model, fields) {
      var disposes;
      disposes = fields.map(function (field) {
        var obs;
        obs = resolveDeepField(model, field);

        if (obs && obs.subscribe) {
          return obs.subscribe(function (newVal) {
            if (storeRecord(vuex.state) != null) {
              return vuex.commit('storeSync', function (state) {
                var parts;

                if (field.indexOf('.') === -1) {
                  return storeRecord(state)[field] = newVal;
                } else {
                  parts = splitFirst(field, '.');
                  return resolveDeepField(storeRecord(state), parts[0])[parts[1]] = newVal;
                }
              });
            }
          });
        }

        return function () {};
      });
      disposes.push.apply(disposes, fields.map(function (field) {
        return vuex.watch(function (state) {
          return storeRecord(state) && resolveDeepField(storeRecord(state), field);
        }, function (newVal) {
          var obs;
          obs = resolveDeepField(model, field);

          if (obs != null) {
            return obs(newVal);
          }
        });
      })); // Create an internal dispose method on the model, grouping together all the disposal above

      return model._disposeStoreSync = function () {
        return disposes.forEach(function (dispose) {
          return dispose();
        });
      };
    },
    // Call the internal dispose method on a model
    disposeStoreSync: function (model) {
      return model != null ? typeof model._disposeStoreSync === "function" ? model._disposeStoreSync() : void 0 : void 0;
    }
  };
});