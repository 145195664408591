import { postMessageToLightspeed } from '@/scaffolding/tko-lightspeed';
/**
 * We want to be able to trigger TKO quick views from Vue components
 * we should use 'quickViews/tko/open' so we are consious about using a TKO component.
 */

export default {
  tko: {
    namespaced: true,
    actions: {
      open(_, { id, args, onCloseCallback }) {
        const realArgs = { ...args };
        if (
          id === 'task' &&
          window.app.features.projectsLightspeedTaskDetails()
        ) {
          postMessageToLightspeed('twa:task-details', realArgs);
        } else {
          window.app.quickView.show(id, realArgs, onCloseCallback);
        }
      },
      close(_, { id }) {
        window.app.quickView.close(id);
      },
    },
  },
};
