<template>
  <div
    class="drag-area"
    @click="showDragArea = false"
    :class="{
      visible: showDragArea,
      hover: hoverDragArea,
      commentVisible: isCommentEditorShowing,
    }"
    ref="dragArea"
  >
    <div class="drag-content">
      <FileIcon />
      <p>{{ $t('Drag and drop files here') }}</p>
    </div>
  </div>
</template>

<script>
import FileIcon from '../FileIcon/FileIcon.vue';

export default {
  name: 'FileDragArea',
  components: {
    FileIcon,
  },
  props: {
    isCommentEditorShowing: { type: Boolean, default: false },
    isEditingDescription: { type: Boolean, default: false },
  },
  data() {
    return {
      showDragArea: false,
      hoverDragArea: false,
    };
  },
  mounted() {
    ['dragenter', 'dragleave'].forEach((eventName) => {
      document.body.addEventListener(eventName, this.windowDragHandler, false);
    });
    ['dragover', 'drop', 'dragenter', 'dragleave'].forEach((eventName) => {
      if (this.$refs.dragArea) {
        this.$refs.dragArea.addEventListener(
          eventName,
          this.areaDragHandler,
          false,
        );
      }
    });
  },
  beforeDestroy() {
    ['dragenter', 'dragleave'].forEach((eventName) => {
      document.body.removeEventListener(
        eventName,
        this.windowDragHandler,
        false,
      );
    });
    ['dragover', 'drop', 'dragenter', 'dragleave'].forEach((eventName) => {
      if (this.$refs.dragArea) {
        this.$refs.dragArea.removeEventListener(
          eventName,
          this.areaDragHandler,
          false,
        );
      }
    });
  },
  methods: {
    isInsideWindow({ clientX, clientY }) {
      if (
        clientX > 0 &&
        clientX < window.innerWidth &&
        clientY > 0 &&
        clientY < window.innerHeight
      ) {
        return true;
      }
      return false;
    },
    isValidType(event) {
      const dataTransfer = event.dataTransfer;
      if (dataTransfer.types != null) {
        if (dataTransfer.types.indexOf) {
          // if dt is an array (Blink based browsers)
          return dataTransfer.types.indexOf('Files') !== -1;
        }
        // if dt is an object (IE11, Firefox)
        return (
          typeof dataTransfer.types === 'object' &&
          (dataTransfer.types.contains('application/x-moz-file') ||
            dataTransfer.types.contains('Files'))
        );
      }
      return false;
    },
    windowDragHandler(event) {
      if (
        event.type === 'dragenter' &&
        this.isValidType(event) &&
        !this.isEditingDescription
      ) {
        this.showDragArea = true;
      }
      if (event.target.closest('.w-add-comment')) {
        this.showDragArea = false;
      }
      if (!event.target.closest('.panel-container')) {
        this.showDragArea = false;
      }
      if (event.type === 'dragleave' && !this.isInsideWindow(event)) {
        this.showDragArea = false;
      }
    },
    areaDragHandler(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isValidType(event)) {
        return;
      }
      if (this.isEditingDescription) {
        return;
      }
      switch (event.type) {
        case 'dragenter':
          this.showDragArea = true;
          this.hoverDragArea = true;
          break;
        case 'dragleave':
          this.hoverDragArea = false;
          if (!this.isInsideWindow(event)) {
            this.showDragArea = false;
          }
          break;
        case 'drop':
          this.showDragArea = false;
          this.hoverDragArea = false;
          this.onDropFiles(event);
          break;
        case 'dragover':
          this.hoverDragArea = true;
          break;

        default:
          break;
      }
    },
    onDropFiles(event) {
      this.$emit('on-drop-files', event);
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-area {
  position: absolute;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 110px);
  padding: 0 25px 16px 25px;
  transition: 0.1s opacity ease-out;
  opacity: 0;
  visibility: hidden;
  &.commentVisible {
    height: calc(100% - 320px);
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    .drag-content {
      display: flex;
    }
  }
  &.hover {
    .drag-content {
      border: 2px solid #4461d7;
      background-color: rgba(white, 1);
    }
  }
  .drag-content {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.8);
    transition: 0.1s background-color ease-out;
    border: 2px dashed #4461d7;
    border-radius: 8px;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #0b0e1f;
    p {
      margin-top: 16px;
    }
  }
}
</style>
