<template>
  <div
    class="table-cell-task-attachments"
    v-tooltip="{
      content:
        numAttachments === 1
          ? $t(`[0] file attached`, numAttachments)
          : $t(`[0] files attached`, numAttachments),
      placement: 'top',
      boundariesElement: 'document.body',
    }"
  >
    <button @click="openQuickView" class="attachment-btn">
      <span class="cursor--pointer attachments-count">
        <CommonIcon
          class="u-common-icon"
          id="paperclip-light"
          color="#0B0E1F"
        />
        <span>
          {{ numAttachments }}
        </span>
      </span>
    </button>
  </div>
</template>

<script>
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import toTaskModel from '@/utils/helpers/toTaskModel';

export default {
  name: 'Attachments',
  display: 'Attachments',
  components: { CommonIcon },
  props: {
    task: { type: Object, required: true, default: () => null },
  },
  computed: {
    numAttachments() {
      return this.task.attachments ? this.task.attachments.length : 0;
    },
  },
  methods: {
    openQuickView() {
      toTaskModel(this.task).OnQuickView('files');
    },
  },
};
</script>

<style lang="scss">
@import '~@tko/src/styles/variables/variables';
.table-cell-task-attachments {
  height: 28px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  .attachments-count {
    display: flex;
    align-items: center;
    font-size: $font-size--h4;
    font-weight: $font-weight--normal;
    color: $color--text-tinted-dark;
    margin: 0px;

    .u-common-icon {
      margin-right: $margin--tiny;
      font-size: $font-size--btn;
    }
  }
}
.attachment-btn {
  position: relative;
  background: none;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
</style>
