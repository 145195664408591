<template>
  <span
    class="
      flex flex-col
      justify-center
      align-center
      px-2
      bg-palette-neutral-10
      key-border
      rounded
      drop-shadow
      h-6
    "
  >
    {{ glyph }}
  </span>
</template>

<script>
export default {
  name: 'KeyboardShortcutList',
  props: {
    glyph: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
.key-border {
  border: 1px solid $neutral-30;
}
</style>
