<template>
  <ul
    class="
      inline-flex
      items-center
      justify-start
      list-none
      p-0
      w-full
      max-w-full
    "
    ref="rootEl"
    :class="{ 'flex-wrap': !isTruncated }"
    v-if="breadcrumbs.length"
    role="navigation"
  >
    <li
      v-for="(item, index) in displayCrumbs"
      :key="index"
      class="inline-flex items-center h-6"
      :class="('max-w-1/' + max, { truncate: item.type !== 'expand' })"
    >
      <a
        @click.prevent="onClick($event, item)"
        :href="item.href"
        :class="item.type === 'expand' ? 'min-w-min' : 'truncate'"
        :ref="item.key ? 'link-' + item.key : null"
        class="
          appearance-none
          text-xs text-text
          hover:underline hover:text-text
          border-none
          bg-transparent
          p-0
        "
        v-tooltip="{
          content:
            item.overflows || item.type === 'expand' ? item.tooltip : null,
          position: 'top',
        }"
      >
        {{ item.text }}
      </a>
      <span
        v-if="index !== displayCrumbs.length - 1"
        class="px-1 flex items-center"
      >
        <slot name="seperator">
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.1476 3.1141C17.4989 3.33363 17.6056 3.79635 17.3861 4.1476L7.3861 20.1476C7.16657 20.4989 6.70385 20.6056 6.3526 20.3861C6.00135 20.1666 5.89457 19.7039 6.1141 19.3526L16.1141 3.3526C16.3336 3.00135 16.7963 2.89457 17.1476 3.1141Z"
              fill="#8F96B3"
            />
          </svg>
        </slot>
      </span>
    </li>
  </ul>
</template>

<script>
import Vue from 'vue';
import { v4 as uuid } from 'uuid';

/* EXAMPLE DATA
breadcrumbs = [{
  text: 'Breadcrumb 1',
  onClick: () => {},
}]
*/

export default {
  name: 'Breadcrumb',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 3,
    },
    truncated: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isTruncated: this.truncated,
      displayCrumbs: [],
    };
  },
  computed: {
    allCrumbs() {
      return this.breadcrumbs.map((item) => ({
        ...item,
        overflows: false,
        tooltip: item.text,
        type: 'link',
        key: uuid(),
      }));
    },
    truncatedCrumbs() {
      const crumbs = [];
      crumbs.push(this.allCrumbs[0]);
      crumbs.push({
        text: '...',
        tooltip: Vue.t('Expand breadcrumbs'),
        overflows: true,
        onClick: () => {
          this.isTruncated = false;
        },
        href: null,
        type: 'expand',
      });
      crumbs.push(this.allCrumbs[this.allCrumbs.length - 1]);
      return crumbs;
    },
  },
  mounted() {
    setTimeout(() => {
      this.assignCrumbs();
    }, 0);
  },
  watch: {
    allCrumbs() {
      this.assignCrumbs();
    },
    isTruncated() {
      this.assignCrumbs();
    },
  },
  methods: {
    assignCrumbs() {
      this.displayCrumbs =
        this.isTruncated && this.allCrumbs.length > this.max
          ? this.truncatedCrumbs
          : this.allCrumbs;
      setTimeout(() => {
        this.detectOverflows();
      }, 0);
    },
    onClick(event, item) {
      if ((event.metaKey || event.ctrlKey) && item.href) {
        window.open(item.href, '_blank');
        return;
      }
      if (item.onClick) {
        item.onClick();
      }
    },
    detectOverflows() {
      Object.entries(this.$refs)
        .filter(([key]) => key.includes('link'))
        .map(([_key, link]) => {
          const ref = link[0];
          const key = _key.replace('link-', '');
          return {
            key,
            ref,
          };
        })
        .forEach((link) => {
          const el = link.ref;
          if (el) {
            const overflows = el.offsetWidth < el.scrollWidth;
            const theCrumb = this.displayCrumbs.find(
              (crumb) => crumb.key === link.key,
            );
            if (theCrumb) {
              Vue.set(theCrumb, 'overflows', overflows);
            }
          }
        });
    },
  },
};
</script>
