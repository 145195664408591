import moment from 'moment';
import { mapGetters } from '@/store/utils/record-mapper';
import { convertAssignedTo } from '@/utils/helpers/responsible';

const today = () => moment.tz(moment.tz.guess()).startOf('day');

export default {
  subTaskRecords: (state) => (id) =>
    Object.values(state.subtasks[id] || [])
      .map((subTaskId) => state.records[subTaskId])
      .filter(({ status }) => status !== 'deleted')
      .sort((a, b) => a.order - b.order),
  hasSubTasks: (state) => (id) =>
    !!Object.values(state.subtasks[id] || [])
      .map((subTaskId) => state.records[subTaskId])
      .find(({ status }) => status !== 'deleted'),
  ...mapGetters({
    siblingIds: (
      { parentTaskId, taskListId },
      getters,
      { task },
      rootGetters,
    ) =>
      parentTaskId
        ? task.subtasks[parentTaskId]
        : rootGetters['tasklist/tasks'](taskListId).map(({ id }) => id),
    nextSiblingId: ({ id }, getters) => {
      const sibs = getters.siblingIds(id);
      return sibs[sibs.indexOf(id) + 1] || -1;
    },
    prevSiblingId: ({ id }, getters) => {
      const sibs = getters.siblingIds(id);
      return sibs[sibs.indexOf(id) - 1] || -1;
    },
    resolveRange: (
      { startDate, dueDate, projectId },
      getters,
      rootState,
      rootGetters,
    ) => {
      const { diff } = rootGetters['project/workingWeek'](projectId);
      const start =
        (startDate.isValid() && startDate) ||
        (dueDate.isValid() && dueDate) ||
        today();
      let due =
        (dueDate.isValid() && dueDate) ||
        (startDate.isValid() && startDate) ||
        today();
      if (due.isBefore(start)) {
        due = start;
      }
      const duration = Math.max(diff(due.toDate(), start.toDate()), 0) + 1;
      return { startDate: start, dueDate: due, duration };
    },
    // Notice this gives the current user ID on success
    assignedToCurrent: ({ assignedTo }, getters, { user }) =>
      assignedTo.find(({ id }) => id === user.id) ? user.id : false,

    isAssignedToMe: ({ assignedTo }, getters, { user }) => {
      const assignedToIds = convertAssignedTo(assignedTo);
      if (assignedToIds.includes(user.id)) {
        return true;
      }
      if (assignedToIds.includes(`c${user.companyId}`)) {
        return true;
      }
      const matchingTeams = assignedToIds.filter((id) =>
        user.teamIds.some((teamId) => id === `t${teamId}`),
      );
      return matchingTeams.length > 0;
    },

    assigneeAvatars: ({ assignedTo }, getters, rootState, rootGetters) =>
      assignedTo
        .map(
          ({ id, companyId, teamId, label, logo, icon, color }) =>
            (id && rootGetters['people/avatar'](id)) ||
            (logo && { label, avatarUrl: logo }) ||
            (icon && { label, icon, color }) ||
            (companyId && { label, icon: 'company' }) ||
            (teamId && { label, icon: 'users' }),
        )
        .filter(Boolean),

    // returns progress/remaining proportional to duration
    progressProportional: ({ id, progress }, getters) => {
      const duration = getters.resolveRange(id).duration / 100;
      return [progress * duration, (100 - progress) * duration];
    },
    getPredecessorType:
      (state, getters, rootState, rootGetters, id) => (otherId) =>
        (
          (rootState.dependencies.preds[id] || []).find(
            (d) => d.id === otherId,
          ) || {}
        ).dependentCant,
    isAncestor:
      ({ parentTaskId }, getters) =>
      (id) =>
        !!parentTaskId &&
        (parentTaskId === id || getters.isAncestor(parentTaskId)(id)),
    // This task or an ancestor is collapsed
    isHierarchyCollapsed: ({ id, expanded }, getters) =>
      !expanded || getters.isHidden(id),
    // If a parent is collapsed, it means this task is 'hidden'
    isHidden: ({ parentTaskId, taskListId }, getters, { tasklist }) =>
      parentTaskId
        ? getters.isHierarchyCollapsed(parentTaskId)
        : tasklist.records[taskListId].collapsed,
    boardColumn: ({ boardColumnId }, getters, { boardColumn }) =>
      boardColumnId && boardColumn.records[boardColumnId],
    tags: ({ tagIds }, getters, { tag }) => tagIds.map((id) => tag.records[id]),
  }),
};
