<template>
  <div class="log-time">
    <div class="log-time__wrapper">
      <LogTimeLogger
        data-identifier="task-details-header-log-time"
        :task="task"
        :disabled="disabled"
        :logged-time="loggedTime"
        :should-open-new-modal="shouldOpenNewModal"
        @update="(time) => $emit('update', time)"
        :show-time-list="showTimeList"
        :open.sync="isPopoverOpen"
      />
      <LogTimeTimer
        data-identifier="task-details-header-timer"
        :logged-time="loggedTime"
        :elapsed-time.sync="elapsedTime"
        :timer-state.sync="timerState"
        :task="task"
      />
    </div>
    <span v-if="showLoggedTime" class="log-time__logged">
      {{ loggedTime }}
    </span>
    <span v-else class="log-time__timer">
      {{ formattedElapsedTime }}
    </span>
  </div>
</template>
<script>
import LogTimeTimer from '@widgets/TaskDetailWidgets/LogTimeTimer';
import LogTimeLogger from '@widgets/TaskDetailWidgets/LogTimeLogger';
import { ref, computed } from 'vue-demi';
import {
  TIMER_STATUSES,
  getTimerByTaskId,
  formatElapsedTime,
} from '@/utils/helpers/timer';

export default {
  name: 'LogTime',
  props: {
    task: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loggedTime: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    showTimeList: {
      type: Boolean,
      default: false,
    },
    shouldOpenNewModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LogTimeTimer,
    LogTimeLogger,
  },
  setup(props, { emit }) {
    const { currentElapsedTime, currentTimeState } = getTimerByTaskId(
      props.task.id,
    );

    const elapsedTime = ref(currentElapsedTime);
    const timerState = ref(currentTimeState);

    const { PLAY } = TIMER_STATUSES;

    return {
      elapsedTime,
      timerState,
      formattedElapsedTime: computed(() => {
        return formatElapsedTime(elapsedTime.value);
      }),
      showLoggedTime: computed(() => {
        return timerState.value === PLAY;
      }),
      isPopoverOpen: computed({
        get() {
          return props.open;
        },
        set(val) {
          emit('update:open', val);
        },
      }),
    };
  },
};
</script>
<style lang="scss">
.log-time {
  display: flex;
  align-items: center;

  &__logged {
    font-size: 12px;
    color: #0b0e1f;
    margin-right: 16px;
    white-space: nowrap;
    font-weight: 600;
  }

  &__timer {
    font-size: 12px;
    font-weight: 600;
    color: #0b0e1f;
    margin-right: 10px;
    white-space: nowrap;
  }

  &__wrapper {
    display: flex;
    position: relative;
    width: 57px;
    height: 28px;
    z-index: 103;
    margin-right: 8px;
  }
}
</style>
