/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { debounce, throttle, omit, isEqual } from 'lodash-es';
import { inject, provide, ref, watch, computed } from 'vue-demi';
import {
  useAxios,
  useOptimisticUpdates,
  useRealTimeUpdates,
} from '@teamwork/use';
import { v3Url } from '@teamwork/fetcher';
import { setInboxUnreadCount as setTKOInboxUnreadCount } from '@/scaffolding/socket';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';

import { useInboxFilter } from '@/views/inbox/composables/useInboxFilter';
import handleXHRError from '@/views/inbox/utils/handleXHRError';

const InboxUnreadCountSymbol = Symbol('InboxUnreadCount');

export function provideInboxUnreadCount() {
  const api = useAxios();
  const filters = useInboxFilter();
  const { postMessageToLightspeed } = useLightspeedBridge();

  const abortController = ref(null);

  const count = ref(0);

  const params = computed(() => {
    const omittedParams = omit(filters.queryParams.value, ['searchTerm']);
    return {
      ...omittedParams,
      isRead: false,
      isArchived: false,
    };
  });

  const paramsBuffer = ref(params.value);

  watch(params, (newVal) => {
    paramsBuffer.value = newVal;
  });

  const queryParams = ref(paramsBuffer.value);

  watch(
    paramsBuffer,
    debounce(
      // eslint-disable-next-line func-names
      function (newVal, oldVal) {
        if (!isEqual(oldVal, newVal)) {
          queryParams.value = newVal;
        }
      },
      1000,
      { leading: true, trailing: true },
    ),
  );

  function set(newCount) {
    count.value = newCount < 0 ? 0 : newCount;
  }

  function increment() {
    set(count.value + 1);
  }

  function decrement() {
    set(count.value - 1);
  }

  function optimisticIncrement(promise) {
    increment();
    promise.catch(decrement);
  }

  function optimisticDecrement(promise) {
    decrement();
    promise.catch(increment);
  }

  const fetch = throttle(
    async function fetch(isWebsocket = false) {
      try {
        abortController.value = new AbortController();

        const response = await api.get(
          v3Url(`inbox/items/count`),
          {
            params: queryParams.value,
            signal: abortController.value.signal,
          },
          {
            headers: {
              'Triggered-By': isWebsocket ? 'event' : 'user',
              'Sent-By': 'composable',
              twProjectsVer: window.appVersionId ?? '',
            },
          },
        );

        set(response.data.item.count);
      } catch (_error) {
        if (axios.isCancel(_error)) {
          return;
        }

        if (!axios.isAxiosError(_error)) {
          throw _error;
        }

        handleXHRError(_error);
      }
    },
    1000,
    {
      leading: false,
      trailing: true,
    },
  );

  useRealTimeUpdates(async (event) => {
    // We need to ignore bulk action events becasue the fetch would trigger before the
    // asynchronous API operations have completed
    if (event?.type === 'inboxItem' && event?.action !== 'inboxItem-bulk') {
      fetch(true);
    }
  });

  // Bulk action RTU handling
  useRealTimeUpdates(async (event, rawEvent) => {
    if (
      rawEvent?.eventInfo?.event === 'inboxBulkUpdate' ||
      rawEvent?.eventInfo?.event === 'inboxBulkUpdateById'
    ) {
      if (rawEvent.eventInfo.actionType === 'completed') {
        fetch(true);
      }
    }
  });

  useOptimisticUpdates((event) => {
    if (event?.type === 'inboxItem') {
      const oldIsUnread = !event.oldInboxItem.isRead;
      const newIsUnread = !event.inboxItem.isRead;
      const oldIsArchived = event.oldInboxItem.isArchived;
      const newIsArchived = event.inboxItem.isArchived;

      // Decrement the unread count if an item goes from unread to read and is never archived
      const unreadToRead =
        oldIsUnread && !oldIsArchived && !newIsUnread && !newIsArchived;

      // Decrement the unread count if an item goes from unread and unarchived to archived
      const unreadToArchived = oldIsUnread && !oldIsArchived && newIsArchived;

      // Increment the unread count if an item goes from read to unread and is not archived
      const readToUnread = !oldIsUnread && newIsUnread && !newIsArchived;

      // Increment the unread count if an item goes from archived to unarchived and unread
      const archivedToUnread = oldIsArchived && newIsUnread && !newIsArchived;

      if (event?.action === 'update') {
        // mark as read event
        if (unreadToRead || unreadToArchived) {
          optimisticDecrement(event.promise);
          return;
        }

        // mark as unread event
        if (readToUnread || archivedToUnread) {
          optimisticIncrement(event.promise);
          return;
        }
      }

      // decrement if unread item deleted
      if (event?.action === 'delete') {
        if (oldIsUnread) {
          optimisticDecrement(event.promise);
        }
      }
    }
  });

  // Sync count changes with LS legacy bridge to ensure filter changes update count in Lightspeed
  watch(count, () => {
    setTKOInboxUnreadCount(count.value);

    postMessageToLightspeed('twa:inbox-count-update', {
      count: count.value,
    });
  });

  watch(
    queryParams,
    (newVal, oldVal) => {
      if (abortController.value) {
        abortController.value.abort();
      }

      if (isEqual(oldVal, newVal)) {
        return;
      }

      fetch();
    },
    { deep: true },
  );

  const inboxUnreadCountSymbol = {
    count,
    fetch,
  };

  provide(InboxUnreadCountSymbol, inboxUnreadCountSymbol);
  return inboxUnreadCountSymbol;
}

export function useInboxUnreadCount() {
  return inject(InboxUnreadCountSymbol);
}
