<template>
  <div
    :class="[
      'panel-overlay',
      {
        opening,
        open,
        closing,
        clickable: canOutsideClickClose,
        scrollable: !preventOverflow,
      },
    ]"
  >
    <transition name="panel-backdrop">
      <div
        :class="['panel-backdrop', panelBackdropClass]"
        v-if="hasBackdrop && isOpen"
      />
    </transition>
    <div
      :class="[
        'panel-container',
        `position-${isModal ? 'center' : position}`,
        `type-${isModal ? 'modal' : 'panel'}`,
        { fullscreen: isFullscreen, overflow: !preventOverflow },
        { 'panel-container--with-header-banner': hasHeaderBanner },
        { 'panel-container--with-app-shell': isAppShell },
      ]"
      @click.self="onOutsideClick"
    >
      <div
        v-show="showFullscreenToggle"
        class="fullscreen-toggle-container"
        @mouseover="fullscreenToggleHover = true"
        @mouseleave="fullscreenToggleHover = false"
      >
        <FullscreenToggle
          :is-fullscreen="isFullscreen"
          :hover="fullscreenToggleHover"
          @toggle-fullscreen="toggleFullscreen"
        />
      </div>

      <transition
        :name="`panel-${animation}-${isModal ? 'top' : position}`"
        @before-enter="onOpening"
        @after-enter="onOpened"
        @before-leave="onClosing"
        @after-leave="onClosed"
      >
        <div
          v-if="isOpen"
          :class="['panel', panelWidthClass, panelClass, { open }]"
          :data-identifier="
            dataIdentifierPrefix ? `${dataIdentifierPrefix}-panel` : undefined
          "
        >
          <slot name="panel">
            <div v-if="showHeader" :class="['panel-header', panelHeaderClass]">
              <h4 class="panel-header--title" v-if="title">{{ title }}</h4>
              <slot name="header" />
              <div class="panel-header--options">
                <slot name="header-options" />
                <button
                  class="panel-header--fullscreen-btn"
                  v-if="showFullscreenButton"
                  @click="toggleFullscreen"
                >
                  <FullscreenIcon v-show="!isFullscreen" />
                  <MinimizeIcon v-show="isFullscreen" />
                </button>
              </div>
              <button
                v-if="showCloseButton"
                class="panel-header--close-btn"
                @click="close"
              >
                <CommonIcon id="close" />
              </button>
            </div>
            <div class="panel-content">
              <div class="panel-body" ref="panelBody">
                <slot name="body" />
              </div>
              <slot name="sidebar" />
            </div>
            <div v-if="showFooter" :class="['panel-footer', panelFooterClass]">
              <slot name="footer" />
            </div>
          </slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/*
PROPS
  isOpen: Toggles the visibility of the overlay and its children
  title: Creates an H4 panel title
  hasBackdrop: Whether the panel has a darker backdrop under it
  showCloseButton: Whether to show the close button
  showFullscreenButton: Whether to show the fullscreen button
  showHeader: Whether to show the header
  showFooter: Whether to show the footer
  isModal: When true will open in the center of the page
  canEscapeKeyClose: Whether pressing the esc key should invoke @close
  canOutsideClickClose: Whether clicking outside the panel will invoke @close
  position: Where should the panel be opened 'right' or 'left'
  animation: What animation to use when opening 'slide'
  panelClass: Extra class to pass to the panel
  panelHeaderClass: Extra class to pass to the header
  panelFooterClass: Extra class to pass to the footer
  panelBackdropClass: Extra class to pass to the backdrop
  preventOverflow: Prevent the Panel to grow outside of the window
  clipBody: Clip body when panel is open (prevents double scrollbars)

EVENTS
  @close: Triggered when the close button is pressed
  @opening: Triggered when the panel is openening but not yet fully open
  @opened: Triggered when the panel is fully opened
  @closing: Triggered when the panel is closing but not yet fully closed
  @closed: Triggered when the panel is fully closed
  @fullscreen: Triggered when the fullscreen button is pressed (will send future state)

SLOTS
  #panel - this would overwrite the complete panel (will remove header, body and footer)
  #header - used to add content in the header section
  #header-options - used to add more buttons on the left side of the fullscreen btn
  #body - used to add content in the body section
  #footer - used to add content in the footer section
*/
import { computed } from 'vue-demi';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import { mapGetters } from 'vuex';
import { FullscreenToggle } from '@widgets/TaskDetailWidgets';
import FullscreenIcon from './icons/Fullscreen/Fullscreen';
import MinimizeIcon from './icons/Minimize/Minimize';
import { useAppShell } from '@/platform/composables/useAppShell';
import { usePreferences } from '@/platform/composables/usePreferences';

// @vue/component
export default {
  props: {
    isOpen: { type: Boolean, default: false },
    isFullscreen: { type: Boolean, default: false },
    title: { type: String, default: null },
    hasBackdrop: { type: Boolean, default: false },
    showCloseButton: { type: Boolean, default: true },
    showFullscreenButton: { type: Boolean, default: true },
    showFullscreenToggle: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: true },
    showFooter: { type: Boolean, default: true },
    isModal: { type: Boolean, default: false },
    canEscapeKeyClose: { type: Boolean, default: true },
    canOutsideClickClose: { type: Boolean, default: true },
    position: { type: String, default: 'right' },
    animation: { type: String, default: 'slide' },
    panelClass: { type: String, default: '' },
    panelHeaderClass: { type: String, default: '' },
    panelFooterClass: { type: String, default: '' },
    panelBackdropClass: { type: String, default: '' },
    preventOverflow: { type: Boolean, default: true },
    clipBody: { type: Boolean, default: true },
    dataIdentifierPrefix: { type: String, default: null },
  },
  components: { FullscreenIcon, MinimizeIcon, CommonIcon, FullscreenToggle },
  setup() {
    const { shouldUseAppShell } = useAppShell();
    const { shouldMinimizeSidebar } = usePreferences();

    const panelWidthClass = computed(() => {
      if (shouldUseAppShell.value) {
        return shouldMinimizeSidebar.value ? 'panel-md' : 'panel-sm';
      }
      return 'panel-lg';
    });

    return {
      open: false,
      opening: false,
      closing: false,
      hasEverOpened: false,
      backdrop: false,
      isAppShell: shouldUseAppShell,
      panelWidthClass,
    };
  },
  computed: {
    ...mapGetters({
      banners: 'notifications/banners/visible',
    }),
    hasHeaderBanner() {
      return Object.keys(this.banners).length;
    },
  },
  data() {
    return {
      fullscreenToggleHover: false,
    };
  },
  methods: {
    toggleFullscreen() {
      this.$emit('toggle-fullscreen');
    },
    escClose(event) {
      if (event.keyCode === 27 && this.canEscapeKeyClose) {
        event.preventDefault();
        event.stopPropagation();
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
    onOpening() {
      this.opening = true;
      this.$emit('opening');

      if (this.clipBody) {
        document.body.classList.add('panel-open');
      }
    },
    onOpened() {
      this.open = true;
      this.opening = false;
      this.$emit('opened');
      document.addEventListener('keyup', this.escClose);
    },
    onClosing() {
      this.closing = true;
      this.opening = false;
      this.$emit('closing');
    },
    onClosed() {
      this.open = false;
      this.closing = false;
      this.$emit('closed');
      this.fullscreen = false;
      document.removeEventListener('keyup', this.escClose);
      document.body.classList.remove('panel-open');
    },
    onOutsideClick() {
      if (this.canOutsideClickClose) {
        this.close();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.escClose);
    document.body.classList.remove('panel-open');
  },
};
</script>

<style lang="scss">
body {
  &.panel-open {
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
@import '~@tko/src/styles/mixins/mixins';

$panel-zIndex: 19;
$app-header-height: 56px;
$header-height: 68px;
$header-banner-height: 40px;
$light-gray: #f5f7fa;

$content-width-sm: 688px;
$content-width: 768px;

$nav-width-minimized: 84px;
$nav-width: 208px;
$nav-offset: 70px;

.panel-overlay {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 100px;
  top: 0;
  z-index: $panel-zIndex;
  pointer-events: none;

  &.scrollable {
    pointer-events: all;
    overflow: auto;
  }

  &.open,
  &.opening {
    position: fixed;
  }

  &.clickable {
    pointer-events: all;
    z-index: 1 + $panel-zIndex; // should be more than header z-index in case of modal
  }
}

.panel-backdrop {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 1;
  background-color: rgba(16, 22, 26, 0.7);
  overflow: auto;
  user-select: none;
  z-index: $panel-zIndex;
  pointer-events: all;
}

.panel {
  z-index: $panel-zIndex + 1;
  width: $content-width-sm;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.2);
  background: $light-gray;
  transition-duration: 300ms;
  transition-property: width;
  transition-timing-function: ease-out;

  @media (min-width: 1400px) {
    width: $content-width;
  }
}

.panel-container {
  position: fixed;
  z-index: $panel-zIndex + 1;
  width: 100%;
  display: flex;
  user-select: none;
  bottom: 0;

  height: calc(100% - #{$app-header-height});

  &--with-header-banner {
    height: calc(100% - #{$app-header-height} - #{$header-banner-height});
  }

  &--with-app-shell {
    height: 100%;
  }

  &.type-modal {
    align-items: center;

    .panel {
      height: 90vh;
      width: 1200px;
      max-width: 90vw;
    }
  }

  /* STATES */
  &.overflow {
    .panel {
      height: auto;
    }
  }

  &.fullscreen {
    .panel-sm {
      width: calc(100vw - #{$nav-width} - #{$nav-offset});
    }
    .panel-md {
      width: calc(100vw - #{$nav-width-minimized} - #{$nav-offset});
    }
    .panel-lg {
      width: calc(100vw - #{$nav-offset});
    }
  }

  /* POSITIONS */
  &.position-right {
    justify-content: flex-end;
  }

  &.position-left {
    justify-content: flex-start;
  }

  &.position-center {
    justify-content: center;
    // left: 50%;
    // top: 50%;
    // transform: translateX(-50%) translateY(-50%);
    .panel {
      transform-origin: center center;
    }
  }
}

.panel-header,
.panel-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background: white;
}

.panel-header {
  height: $header-height;
  padding: 0 32px 0 32px;
  justify-content: space-between;
  position: relative;
  width: $content-width-sm;
  margin: 0 auto;

  @media (min-width: 1400px) {
    width: $content-width;
  }

  &--title {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
  }

  &--options {
    display: flex;
    align-items: center;
  }

  &--fullscreen-btn {
    svg {
      fill: inherit;
      width: 16px;
      height: 16px;
    }
  }

  &--close-btn {
    position: absolute;
    top: 4px;
    right: 4px;
    color: #000;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
.panel-footer {
  padding: 24px;
  // border-top: 1px solid rgba(230, 230, 230, 0.5);
}

.panel-content {
  flex-grow: 2;
  display: flex;
  align-items: stretch;
  min-height: 0;
  position: relative;
  margin: 0 auto;
  background: white;
}

.panel-body {
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  width: $content-width-sm;
  @media (min-width: 1400px) {
    width: $content-width;
  }
  @include scrollbar();
}
.fullscreen-toggle-container {
  width: 16px;
  display: flex;
  pointer-events: all;
  justify-content: end;
  align-items: center;
}

.fullscreen {
  .panel-header {
    width: $content-width;
    margin: 0 auto;
  }
  .panel-body {
    width: $content-width;
  }
}

/* ANIMATION BACKDROP */
.panel-backdrop-enter-active,
.panel-backdrop-leave-active {
  transition: opacity 0.2s;
}
.panel-backdrop-enter,
.panel-backdrop-leave-to {
  opacity: 0;
}

@mixin animationType {
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* ANIMATION PANEL */
.panel-slide-right-enter-active,
.panel-slide-right-leave-active,
.panel-slide-left-enter-active,
.panel-slide-left-leave-active {
  @include animationType();
}

// RIGHT
.panel-slide-right-enter,
.panel-slide-right-leave-to {
  transform: translateX(100%);
}

// LEFT
.panel-slide-left-enter,
.panel-slide-left-leave-to {
  transform: translateX(-100%);
}

// CENTER
.panel-slide-top-enter-active {
  transform-origin: center;
  animation: grow 0.2s;
}

.panel-slide-top-leave-active {
  transform-origin: center;
  animation: grow 0.2s reverse;
}

@keyframes grow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
