<template>
  <div class="subtasks-count">
    <CommonIcons class="subtasks-count__icon" id="subtasks" />
    <span class="subtasks-count__number">{{ count }}</span>
  </div>
</template>

<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';

export default {
  name: 'SubtasksCount',
  components: { CommonIcons },
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.subtasks-count {
  color: #0b0e1f;
  border: 1px solid #e1e6ee;
  border-radius: 20px;
  min-height: 28px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__number {
    margin-left: 4px;
  }
}
</style>
