/* eslint-disable no-console */
import api, { API_PREFIXES } from '@/services/api';

export default {
  namespaced: true,
  state: {
    productOutcomes: [],
    userProductOutcomes: [],
  },

  actions: {
    fetchProductOutcomes({ commit }) {
      api.get(`${API_PREFIXES.v3}/productoutcomes.json`).then((result) => {
        commit('saveProductOutcomes', result.data.productOutcomes);
      });
    },
    fetchUserProductOutcomes({ commit }, { userId }) {
      api
        .get(`${API_PREFIXES.v3}/users/${userId}/productoutcomes.json`)
        .then((result) => {
          commit('saveUserProductOutcomes', result.data.productOutcomes);
        })
        .catch(() => {
          commit('saveUserProductOutcomes', []);
        });
    },
  },

  mutations: {
    saveProductOutcomes(state, response) {
      state.productOutcomes = response;
    },
    saveUserProductOutcomes(state, response) {
      state.userProductOutcomes = response;
    },
  },

  getters: {
    getProductOutcomes: (state) => {
      return state.productOutcomes;
    },
    getUserProductOutcomes: (state) => {
      return state.userProductOutcomes;
    },
  },
};
