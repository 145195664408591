<script>
import { toRef, shallowRef, watch, ref } from 'vue-demi';
import { useDelayLoader, useProjectsLoader } from '@teamwork/use';
import LoaderState from '@/platform/components/LoaderState';
import ProjectsPanelProjectList from './ProjectsPanelProjectList.vue';
import ProjectsPanelInformationalMessage from './ProjectsPanelInformationalMessage.vue';
import ProjectsPanelLoadingState from './ProjectsPanelLoadingState.vue';
import SearchBlankState from '@/platform/components/common/SearchBlankState.vue';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

export default {
  components: {
    LoaderState,
    ProjectsPanelProjectList,
    ProjectsPanelInformationalMessage,
    ProjectsPanelLoadingState,
    SearchBlankState,
  },

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      required: false,
      default: '',
    },

    totalCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const { tryhideProjectsPanel } = useSidebarPanels();

    const count = shallowRef(20);
    // When moving from search results back to the all tab
    // the project informational panel can flcker
    // we will add a delay to rendering this to compensate
    // see -> watch searchTerm
    const searchTransitioning = shallowRef(false);
    const params = ref({
      searchTerm: toRef(props, 'searchTerm'),
      include: 'companies',
      includeProjectUserInfo: true,
      'fields[project]': 'name, isStarred, companyId',
      orderBy: 'name',
      orderMode: 'asc',
      onlyStarredProjects: false,
    });

    // use a little delay loader magic to throttle rendering
    // on typeahead search
    const allProjectsState = useDelayLoader(
      useProjectsLoader({
        count,
        pageSize: shallowRef(20),
        params,
      }),
    );

    watch(
      () => props.searchTerm,
      (searchTerm) => {
        count.value = 20;
        if (searchTerm) {
          searchTransitioning.value = true;
        } else {
          setTimeout(() => {
            searchTransitioning.value = false;
          }, 300);
        }
      },
    );

    watch(
      allProjectsState.totalCount,
      (totalCount) => {
        emit('update:total-count', totalCount);
      },
      {
        immediate: true,
      },
    );

    return {
      count,
      allProjectsState,
      searchTransitioning,
      tryhideProjectsPanel,
    };
  },
};
</script>

<template>
  <LoaderState
    v-if="isActive"
    :state="allProjectsState"
    :count.sync="count"
    class="h-full recent-project-list--scroll-container"
  >
    <template #data="{ items }">
      <div class="w-full pb-2">
        <ProjectsPanelProjectList class="px-6" :projects="items" />

        <ProjectsPanelInformationalMessage
          class="pt-8"
          v-if="!searchTerm && items.length < 3 && !searchTransitioning"
        />
      </div>
    </template>
    <template #blank>
      <SearchBlankState
        v-if="searchTerm"
        :search-term="searchTerm"
        header-text="No projects are matching your search"
        identifier-prefix="app-nav__projects-panel__results"
        class="h-full p-6 py-40"
        @click="$emit('reset-search')"
        @advanced-search="tryhideProjectsPanel"
      />

      <ProjectsPanelInformationalMessage
        class="min-h-[314px]"
        v-else-if="!searchTerm && !searchTransitioning"
      />
    </template>
    <template #loading>
      <ProjectsPanelLoadingState />
    </template>
  </LoaderState>
</template>
