<template>
  <div class="flex flex-row flex-nowrap items-center">
    <LogTimeLoggerPopover
      :task="task"
      :key="loggedTime"
      :open.sync="isPopoverOpen"
      :disabled="disabled"
      :source="'task_details_header'"
      :accelerator-options="
        isExpA15Variation
          ? [
              { label: '15m', hours: 0, minutes: 15 },
              { label: '30m', hours: 0, minutes: 30 },
              { label: '1h', hours: 1, minutes: 0 },
            ]
          : []
      "
      @update="(time) => $emit('update', time)"
    />
    <FButtonGroup class="task-time-logger">
      <FButton
        data-identifier="twa-task-time-logger"
        size="md"
        align-text="center"
        :disabled="disabled"
        class="flex items-center h-7 !px-2 leading-none whitespace-nowrap"
        @click.native="onLogTimeClicked"
      >
        <template #left>
          <CommonIcons
            id="clock"
            class="icon text-xs leading-none"
            color="white"
          />
        </template>
        {{ loggedTime === '0h' ? $t('Log time') : $t('Log more time') }}
      </FButton>
      <FButton
        size="md"
        :test="true"
        class="h-7 !px-2 leading-none whitespace-nowrap"
        :class="buttonProps.class"
        :disabled="disabled"
        v-tooltip="{
          content: buttonProps.tooltip,
          placement: 'bottom',
        }"
        @click.native="timerToggle"
      >
        <template #left>
          <CommonIcons
            :id="buttonProps.icon"
            class="icon text-xs leading-none"
          />
        </template>
        <template v-if="timerState !== PLAY || elapsedTime > 0">
          <span class="timer-counter">
            {{ formattedElapsedTime }}
          </span>
        </template>
      </FButton>
    </FButtonGroup>
    <span class="whitespace-nowrap mx-2">
      {{ loggedTime }}
    </span>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, Vue2 as Vue } from 'vue-demi';
import FButton from '@fragments/actions/FButton';
import FButtonGroup from '@fragments/actions/FButtonGroup';
import LogTimeLoggerPopover from '@widgets/LogTimeLoggerPopover/LogTimeLoggerPopover';
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import { useRealTimeUpdates } from '@teamwork/use';
import {
  TIMER_STATUSES,
  getTimerByTaskId,
  formatElapsedTime,
} from '@/utils/helpers/timer';
import toTaskModel from '@/utils/helpers/toTaskModel';
import useExperimentA15 from '@/platform/composables/useExperimentA15';
import usePendo from '@/platform/composables/usePendo';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';

export default {
  name: 'TaskTimeLogger',
  props: {
    task: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loggedTime: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    shouldOpenNewModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FButton,
    FButtonGroup,
    CommonIcons,
    LogTimeLoggerPopover,
  },
  setup(props, { emit }) {
    const { STARTED, PAUSE, RESUME, PLAY, EDITED, COMPLETE, DELETED } =
      TIMER_STATUSES;

    const { timerId, currentElapsedTime, currentTimeState } = getTimerByTaskId(
      props.task.id,
    );

    const { EXP_A15_COMMON_METRICS, isExpA15Variation } = useExperimentA15();
    const { trackPendoEvent } = usePendo();
    const { postMessageToLightspeed } = useLightspeedBridge();

    const timer = ref(undefined);
    const elapsedTime = ref(currentElapsedTime);
    const timerState = ref(currentTimeState);

    const buttonProps = computed(() => {
      switch (timerState.value) {
        case PAUSE:
          return {
            class: 'is-pause',
            tooltip: Vue.t('Pause timer'),
            icon: 'timer-pause-solid',
          };

        case RESUME:
          return {
            class: 'is-resume',
            tooltip: Vue.t('Resume timer'),
            icon: 'timer-start',
          };

        default:
          return {
            class: 'is-play',
            tooltip: Vue.t('Start timer'),
            icon: 'timer-start',
          };
      }
    });

    function start() {
      const initialState = timerState.value;
      if (timerState.value === PAUSE) {
        return;
      }
      timerState.value = PAUSE;
      timer.value = setInterval(() => {
        elapsedTime.value += 1;
      }, 1000);

      trackPendoEvent({
        eventName: 'TASK_DETAILS_EVENT',
        commonMetrics: EXP_A15_COMMON_METRICS,
        metadata: {
          event_action:
            initialState === RESUME
              ? 'resume_timer_clicked'
              : 'start_timer_clicked',
          event_source: 'task_details_header',
        },
      });
    }

    function stop() {
      if (timerState.value === RESUME) {
        return;
      }
      timerState.value = RESUME;
      clearInterval(timer.value);

      trackPendoEvent({
        eventName: 'TASK_DETAILS_EVENT',
        commonMetrics: EXP_A15_COMMON_METRICS,
        metadata: {
          event_action: 'pause_timer_clicked',
          event_source: 'task_details_header',
        },
      });
    }

    function onLogTimeClicked() {
      if (this.shouldOpenNewModal) {
        postMessageToLightspeed('twa:open-dialog', {
          dialogName: 'TimeTimelogAddOrEditDialog',
          dialogProps: { task: this.task, openFromTWA: true },
        });
      } else {
        this.isPopoverOpen = !this.isPopoverOpen;
      }

      trackPendoEvent({
        eventName: 'TASK_DETAILS_EVENT',
        commonMetrics: EXP_A15_COMMON_METRICS,
        metadata: {
          event_action: 'log_time_clicked',
          event_source: 'task_details_header',
        },
      });
    }

    function timerToggle() {
      if (timerState.value === PAUSE) {
        stop();
      } else {
        start();
      }
      toTaskModel(props.task).ToggleTimer();
    }

    function resetTimer() {
      timerState.value = PLAY;
      elapsedTime.value = 0;
      clearInterval(timer.value);
    }

    function updateTimerStats(state, oldState) {
      switch (state) {
        case PAUSE:
          stop();
          break;

        case RESUME:
        case STARTED:
          start();
          break;

        case COMPLETE:
        case DELETED:
          resetTimer();
          break;

        case EDITED:
          if (oldState === 'resume') {
            start();
          }
          break;

        default:
        // no default
      }
    }

    onMounted(() => {
      updateTimerStats(timerState.value);
    });

    onUnmounted(() => {
      clearInterval(timer.value);
    });

    useRealTimeUpdates((event, details) => {
      const allowedActions = [
        PAUSE,
        RESUME,
        STARTED,
        COMPLETE,
        DELETED,
        EDITED,
      ];
      if (allowedActions.includes(event.action) && event.type === 'timer') {
        const {
          currentElapsedTime: latestElapsedTime,
          currentTimeState: latestTimeState,
        } = getTimerByTaskId(props.task.id);

        const timerTaskId = parseInt(
          details.eventInfo.extraInfo?.data?.taskId,
          10,
        );

        elapsedTime.value = latestElapsedTime;

        // reset timer if task or project change
        if (event.action === EDITED && timerId === 0) {
          resetTimer();
        }

        if (timerTaskId === props.task.id) {
          updateTimerStats(event.action, latestTimeState);
        }
      }
    });

    return {
      elapsedTime,
      timerState,
      buttonProps,
      timerToggle,
      onLogTimeClicked,
      PLAY,
      formattedElapsedTime: computed(() => {
        return formatElapsedTime(elapsedTime.value);
      }),
      isPopoverOpen: computed({
        get() {
          return props.open;
        },
        set(val) {
          emit('update:open', val);
        },
      }),
      isExpA15Variation,
    };
  },
};
</script>

<style lang="scss" scoped>
.task-time-logger {
  .timer-counter {
    width: 60px;
    display: inline-block;
  }

  button {
    &.is-play {
      .icon {
        fill: #fff;
      }
    }
    &.is-pause {
      background-color: #fce9eb;
      color: #b32334;
      .icon {
        fill: #b32334;
      }
    }
    &.is-resume {
      background-color: #d6deff;
      color: #425fd7;
      .icon {
        fill: #425fd7;
      }
    }

    &::before {
      height: 100%;
    }

    &:first-child:focus-visible::after {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:last-child:focus-visible::after {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
</style>
