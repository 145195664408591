import m from 'moment';
import Vue from 'vue';
import { computed } from 'vue-demi';

// Some shorthands
const dayStart = () => m().startOf('day');
const ml = () => m().locale('en-us');
export const teamworkEpoch = () => m({ year: 2007, month: 10 - 1, day: 1 });

const within = (interval) => [
  interval > 0 ? dayStart() : dayStart().subtract(Math.abs(interval), 'day'),
  interval < 0 ? dayStart() : dayStart().add(interval, 'day'),
];

// eslint-disable-next-line import/prefer-default-export
export const getDatesFromRange = (
  dateCode,
  weekString,
  format,
  interval = 0,
) => {
  const ranges = {
    yesterday: () => [dayStart().subtract(1, 'day')],
    today: () => [dayStart()],
    tomorrow: () => [dayStart().add(1, 'day')],

    thisweek: () => [ml().startOf(weekString), ml().endOf(weekString)],
    lastweek: () => [
      ml().startOf(weekString).subtract(1, 'week'),
      ml().endOf(weekString).subtract(1, 'week'),
    ],
    thismonth: () => [m().startOf('month'), m().endOf('month')],
    lastmonth: () => [
      m().subtract(1, 'month').startOf('month'),
      m().subtract(1, 'month').endOf('month'),
    ],
    last3months: () => [
      m().startOf('month').subtract(2, 'month'),
      m().endOf('month'),
    ],
    last6months: () => [
      m().startOf('month').subtract(5, 'month'),
      m().endOf('month'),
    ],
    within: () => within(interval),
    withinprev: () => within(interval * -1),
    anytime: () => [
      m().startOf('month').subtract(6, 'month'),
      m().startOf('month').add(6, 'month'),
    ],
  };
  if (ranges[dateCode] === undefined) {
    return null;
  }
  let range = ranges[dateCode]();
  if (range.length === 1) {
    // 1 element means same start/end date
    range.push(range[0].clone());
  }
  if (format) {
    range = range.map((d) => d.format(format));
  }
  return { startDate: range[0], endDate: range[1] };
};

export const getFormattedDate = (date) => {
  let dateText = '';
  const diff = m().diff(date, 'days');

  if (diff === 0) {
    dateText = Vue.t('today');
  } else if (diff === 1) {
    dateText = Vue.t('yesterday');
  } else if (diff > 1 && diff < 7) {
    dateText = Vue.t('[0] days ago', diff);
  } else if (diff >= 7) {
    dateText = Vue.t('on [0]', date.format('dddd MMM Do'));
  }

  return dateText;
};

/**
 * Adds timezone offset to a date.
 * E.G: This is used in Scheduler to format timezone agnostic dates
 * meaning that "Thu Oct 22 2020 23:59:59 GMT-0200" will not be formatted
 * as 2020-10-23 but 2020-10-22
 *
 * @param {String | Date | Moment} date The date to offset
 * @param {String} format (optional) if set, returns a String representation
 *  of the date following this format
 * @returns {String | Date}
 */
export const offsetTimezone = (date, format) => {
  const momentDate = m.utc(date).add(m().utcOffset(), 'minute');
  return format ? momentDate.format(format) : momentDate.toDate();
};

/**
 * Get all dates within a date range
 *
 * @param {String | Date | Moment} date start date
 * @param {String | Date | Moment} date end date
 * @param {String} format (optional) if set, returns a String representation
 *  of the date following this format
 * @returns {Array}
 */
export const getDatesInRange = (startDate, endDate, format = 'YYYY-MM-DD') => {
  const dates = [];
  let start = m(startDate).clone();

  while (start.isSameOrBefore(endDate, 'day')) {
    dates.push(start.format(format));
    start = start.add(1, 'day');
  }

  return dates;
};

export const getAvailableStartAndEnd = (
  oldStart,
  oldEnd,
  newStart,
  newEnd,
  unavailableDates,
) => {
  const oldDatesInRange = getDatesInRange(oldStart, oldEnd);
  const oldWorkingDays = oldDatesInRange.filter(
    (d) => !unavailableDates.includes(d),
  ).length;
  const finalStart = m(newStart);
  const finalEnd = m(newEnd);
  let startsOnUnavailableDay = unavailableDates.includes(newStart);
  let offset = 0;
  let newDatesInRange = getDatesInRange(newStart, newEnd);
  let newWorkingDays = newDatesInRange.filter(
    (d) => !unavailableDates.includes(d),
  ).length;

  while (newWorkingDays !== oldWorkingDays) {
    finalEnd.add(oldWorkingDays - newWorkingDays, 'd');
    newDatesInRange = getDatesInRange(newStart, finalEnd);
    newWorkingDays = newDatesInRange.filter(
      (d) => !unavailableDates.includes(d),
    ).length;
  }

  if (startsOnUnavailableDay) {
    while (unavailableDates.includes(finalStart.format('YYYY-MM-DD'))) {
      finalStart.add(1, 'd');
      offset += 1;
      startsOnUnavailableDay = unavailableDates.includes(
        finalStart.format('YYYY-MM-DD'),
      );
    }

    finalEnd.add(offset, 'd');
  }

  let endsOnUnavailableDay = unavailableDates.includes(
    finalEnd.format('YYYY-MM-DD'),
  );

  if (endsOnUnavailableDay) {
    while (unavailableDates.includes(finalEnd.format('YYYY-MM-DD'))) {
      finalEnd.add(1, 'd');
      endsOnUnavailableDay = unavailableDates.includes(
        finalEnd.format('YYYY-MM-DD'),
      );
    }
  }

  return {
    start: finalStart.format('YYYY-MM-DD'),
    end: finalEnd.format('YYYY-MM-DD'),
  };
};

export const getProjectUpdateDisplayDate = (date) => {
  const todayAsMoment = m().startOf('day');
  const yesterdayAsMoment = m().startOf('day').subtract('1', 'day');
  const dateAsMoment = m(date);

  if (dateAsMoment.format('YYYYMMDD') === todayAsMoment.format('YYYYMMDD')) {
    return Vue.t('Today at [0]', dateAsMoment.userFormat('time'));
  }
  if (
    dateAsMoment.format('YYYYMMDD') === yesterdayAsMoment.format('YYYYMMDD')
  ) {
    return Vue.t('Yesterday at [0]', dateAsMoment.userFormat('time'));
  }
  const dateFormat =
    dateAsMoment.format('YYYY') === todayAsMoment.format('YYYY')
      ? 'DD MMM [at] HH:mm'
      : // eslint-disable-next-line no-undef
        app.loggedInUser.localization.dateFormat();
  return dateAsMoment.format(dateFormat);
};

/**
 * Returns the date range without month/year repetition if they are the same
 * Returns year if not in current year
 * @param {Moment} startMoment start date (moment)
 * @param {Moment} endMoment end Date (moment)
 * @returns {String} Duration formatted string
 */
export const dateRangeDisplay = (
  startMoment,
  endMoment,
  showYear = true,
  dateFormat = 'DD/MM/YYYY',
  isForChart = false,
) => {
  if (
    startMoment.format('YYYY') !== m().format('YYYY') &&
    startMoment.format('YYYY') === endMoment.format('YYYY')
  ) {
    if (dateFormat.substr(0, 2) === 'DD') {
      return `${startMoment.format('D MMM')} - ${endMoment.format(
        'D MMM YYYY',
      )}`;
    }
    return `${startMoment.format('MMM D')} - ${endMoment.format('MMM D YYYY')}`;
  }
  if (startMoment.format('YYYY') === endMoment.format('YYYY')) {
    if (isForChart) {
      return `${startMoment.format('D')} - ${endMoment.format(
        `D MMM${showYear ? ' YYYY' : ''}`,
      )}`;
    }
    if (startMoment.format('MM') === endMoment.format('MM')) {
      if (dateFormat.substr(0, 2) === 'DD') {
        return `${startMoment.format('D')} - ${endMoment.format(
          `D MMM${showYear ? ' YYYY' : ''}`,
        )}`;
      }
      return `${startMoment.format('D')} - ${endMoment.format(
        `MMM D${showYear ? ' YYYY' : ''}`,
      )}`;
    }
    if (dateFormat.substr(0, 2) === 'DD') {
      return `${startMoment.format('D MMM')} - ${endMoment.format(
        `D MMM${showYear ? ' YYYY' : ''}`,
      )}`;
    }
    return `${startMoment.format('MMM D')} - ${endMoment.format(
      `MMM D${showYear ? ' YYYY' : ''}`,
    )}`;
  }
  if (dateFormat.substr(0, 2) === 'DD') {
    return `${startMoment.format('D MMM YYYY')} - ${endMoment.format(
      'D MMM YYYY',
    )}`;
  }
  return `${startMoment.format('MMM D YYYY')} - ${endMoment.format(
    'MMM D YYYY',
  )}`;
};

/**
 * Returns the date string with shortened formats for hours, months and years.
 * Also replaces 'a' for '1', i.e. 'a day' becomes '1 day'
 *
 * @param {Number} daysLeft Number of days left
 * @returns {String} Shortened and homogenised string
 */
export const homogeniseFormat = (daysLeft) => {
  if (daysLeft === 0 || daysLeft == null) {
    return `0 days`;
  }
  return m.duration(daysLeft, 'days').humanize().replace('a ', '1 ');
};

/**
 * Returns the date string adding 'left' or 'over'
 * to the end, depending on the number of days left.
 *
 * @param {String} dateStr Date string
 * @param {Number} daysLeft Number of days left
 * @returns {String} Resulting string with extra word at the end
 */
export const calculateLeftOrOver = (dateStr, daysLeft) =>
  dateStr.concat(daysLeft >= 0 ? ' left' : ' over');

// Makes sure that a date is an instance of moment
export const ensureMoment = (date, format) => {
  let dateMoment = date;
  if (!m.isMoment(dateMoment)) {
    dateMoment = m(dateMoment, format);
  }
  return dateMoment;
};

export const ensureMomentUTC = (date) => {
  if (m.isMoment(date)) {
    return date;
  }
  return m.utc(date);
};

/**
 * This adds a certain number of days (`offset`), to a base date.
 * The base date defaults to the TW epoch
 * @param {Number} offset Number of days to add to the baseDate
 * @param {String | Date | Moment} baseDate (optional) the date to start from
 * @returns {Moment} Moment offset by `offset` days
 */
export const getOffsetDate = (offset = 0, baseDate = null) => {
  const daysToAdd = Number(offset);
  let baseMoment;
  if (!baseDate) {
    baseMoment = teamworkEpoch();
  } else {
    baseMoment = ensureMoment(baseDate);
  }
  if (daysToAdd < 0) {
    return baseMoment.subtract(daysToAdd * -1, 'days');
  }
  return baseMoment.add(daysToAdd, 'days');
};

/**
 * Returns the difference in days between the TW epoch date and the date passed in
 *
 * @param {String | Date | Moment} date The date to compare
 * @returns {Number} Number of days since the TW epoch date
 */
export const getDaysFromEpoch = (date) =>
  ensureMoment(date).diff(teamworkEpoch(), 'days');

/**
 * Gets the duration in days between two dates, optionally landing only on weekdays
 * If we need the dates to land on weekdays then we need to adjust the duration
 *
 * @param {String | Date | Moment} startDate The date to start from
 * @param {String | Date | Moment} endDate The date to end on
 * @param {Boolean} landOnWeekday Only land on weekdays
 */
export const getDatesDuration = (startDate, endDate, landOnWeekday = true) => {
  const startDateMoment = ensureMoment(startDate);
  const endDateMoment = ensureMoment(endDate);
  let duration = endDateMoment.diff(startDateMoment, 'days') + 1;

  if (landOnWeekday) {
    if (startDateMoment.isoWeekday() > 5) {
      duration += 7 - startDateMoment.isoWeekday();
    }
    if (endDateMoment.isoWeekday() > 5) {
      duration += 7 - endDateMoment.isoWeekday();
    }
  }

  return duration;
};

/**
 * Get the duration of a project
 *
 * @param {String} type Is it a project or template
 * @param {String | Date | Moment} minMaxAvailableDates The dates available
 */
export const getProjectDuration = (type = 'project', minMaxAvailableDates) => {
  let startDate = '';
  let days = '';
  let label = '';

  if (type === 'projects-template') {
    startDate = teamworkEpoch().format('YYYYMMDD');
  } else if (minMaxAvailableDates && minMaxAvailableDates.suggestedStartDate) {
    startDate = minMaxAvailableDates.suggestedStartDate;
  }

  if (minMaxAvailableDates && minMaxAvailableDates.suggestedEndDate) {
    days = getDatesDuration(
      startDate,
      minMaxAvailableDates.suggestedEndDate,
      false,
    );
  } else {
    days = 1;
  }

  if (days === 1) {
    label = Vue.t('[0] Day', days);
  } else {
    label = Vue.t('[0] Days', days);
  }

  return {
    label,
    numDays: days,
  };
};

/**
 * Compares date to get better context
 * Returns bool
 *
 * @param {String} date dateTime to compare
 */
export const sameYear = (date) => m(date).isSame(m(), 'year');
export const isToday = (date) => m(date).isSame(m().startOf('day'), 'day');
export const inPast = (date) =>
  m(date).startOf('day').isBefore(m().startOf('day'));
export const isTomorrow = (date) =>
  m(date).isSame(m().add(1, 'days').startOf('day'), 'day');
export const isYesterday = (date) =>
  m(date).isSame(m().subtract(1, 'days').startOf('day'), 'day');

export const formatDueDate = (deadline) => {
  const dueDate = m(deadline);
  const sameYr = dueDate.isSame(m(), 'year');
  const format = `Do MMM${sameYr ? '' : ', YYYY'}`;
  return dueDate.format(format);
};

/**
 * Format date to given format
 *
 * @param {String | Date | Moment} date the date to format
 * @param {String} format output format
 */
export const formatDate = (date, format = 'YYYYMMDD') => {
  if (!date) {
    return null;
  }

  const mo = ensureMoment(date);
  return mo.clone().format(format);
};

export const formatDateUTC = (date, format = 'YYYYMMDD') => {
  if (!date) {
    return null;
  }
  const mo = ensureMomentUTC(date);
  return mo.format(format);
};

/**
 * Creates an array of offset days for selects
 *
 * @param {Number} start from day
 * @param {Number} end to day
 * @param {Array} days array of days
 */
export const createOffsetDayOptions = (start = 0, end = 1094) => {
  const dayOptions = [];

  let i = start;
  while (i <= end) {
    const obj = {
      id: i,
      text: Vue.t('Day [0]', i + 1),
    };

    if (i === 0) {
      obj.text += ` (${Vue.t('Today')})`;
    }

    if (i === 1) {
      obj.text += ` (${Vue.t('Tomorrow')})`;
    }

    dayOptions.push(obj);
    // eslint-disable-next-line no-plusplus
    i++;
  }

  return dayOptions;
};

// Get date (or time) relative to current time, i.e. 5 mins ago, a year ago, etc.
export const getPastRelativeTime = (date) => {
  // eslint-disable-next-line no-param-reassign
  date = ensureMoment(date);
  const now = m();
  if (date > now) {
    return Vue.t('a few seconds ago');
  }

  return date.from(now);
};

/**
 * Format date to user date format
 *
 * @param {String | Date | Moment} date the date to format
 * @param {String} format output format
 */
export const formatToUserDate = (date) => {
  if (!date) {
    return null;
  }

  const userDateFormat = window.app.loggedInUser.localization.dateFormat();
  const dateFormat = userDateFormat.split('T'); // get only date format without time & timezone

  const mo = ensureMoment(date);
  return mo.clone().format(dateFormat[0]);
};

/**
 * Check if string or date is ISO date
 *
 * @param {String | Date } date the string to check
 * @param {Boolean} isISODate is a date and in ISO format
 */

export const isISODate = (str) => {
  return m(str, m.ISO_8601, true).isValid();
};

/**
 * Provides a 2-way binding between a moment instance ref and an ISO string ref.
 */
export function useMomentISO(isoString) {
  return computed({
    get() {
      return m(isoString.value);
    },
    set(date) {
      // eslint-disable-next-line no-param-reassign
      isoString.value = date.toISOString();
    },
  });
}
