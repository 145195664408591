import bindUpHierarchy from './upHierarchy';

// Read the tasklist ID from the event (understands many different formats)
const taskList = ({ taskListId, extraInfo, extraLink }) =>
  taskListId ||
  (extraInfo && extraInfo.taskListId > 0 && extraInfo.taskListId) ||
  (extraLink &&
    extraLink.startsWith('tasklists/') &&
    Number(extraLink.slice('tasklists/'.length))) ||
  null;

// With a reorder, 2 updates sent
const taskReorder = ({ dispatch }, event) =>
  Promise.all([
    event.oldParentTaskId
      ? dispatch('task/subtasks/changeNotification', event.oldParentTaskId)
      : dispatch('tasklist/tasks/changeNotification', event.oldTaskListId),
    event.newParentTaskId
      ? dispatch('task/subtasks/changeNotification', event.newParentTaskId)
      : dispatch('tasklist/tasks/changeNotification', event.newTaskListId),
  ]);

const taskSort = ({ dispatch }, event) =>
  Promise.all([
    dispatch('tasklist/tasks/changeNotification', event.taskListId),
  ]);

const taskCreatedOrUpdated = async ({ dispatch, state }, event) => {
  const { actionType, extraInfo, itemId } = event;
  const parentTaskId =
    event.parentTaskId || (extraInfo && extraInfo.parentTaskId);
  const dispatches = [];

  if (parentTaskId) {
    dispatches.push(
      await dispatch('task/subtasks/changeNotification', parentTaskId),
    );
  } else {
    // If no tasklist, then false will cause the upstream project to update.
    dispatches.push(
      taskList(event) &&
        (await dispatch('tasklist/tasks/changeNotification', taskList(event))),
    );
  }
  // Predecessors - might be a wasted access, but if this task is being viewed in Gantt
  // we'll need this
  if (['edited'].includes(actionType)) {
    dispatches.push(dispatch('task/predecessors/changeNotification', itemId));
  } else if (['new', 'added'].includes(actionType)) {
    // if we just retrieved the new task, then we'll want predecessor data 2
    if (itemId && dispatches[0]) {
      dispatches.push(
        dispatch('task/predecessors/access', {
          id: itemId,
          payload: { registerAccess: false },
        }),
      );
    }
    if (parentTaskId && state.task.records[parentTaskId]) {
      dispatches.push(
        dispatch('task/predecessors/changeNotification', parentTaskId),
      );
    }
  }

  return Promise.all(dispatches);
};

export default async (store, event) => {
  const { actionType, subType, itemId, extraInfo } = event;
  const { dispatch, commit } = store;
  // If any lower-level change notifications don't result in API calls,
  // or if we are running a filter currently (as it will need to be reassessed)
  // then we need to go up the hierarchy and mark stale/refresh the higher-level DLs
  // https://digitalcrew.teamwork.com/#/tasks/13673822
  const upHierarchy = bindUpHierarchy(store, itemId, event.taskListId);
  if (subType && subType === 'task-reorder') {
    await taskReorder(store, event).then(upHierarchy);
  } else if (subType && subType === 'sort') {
    await taskSort(store, event).then(upHierarchy);
  } else if (['completed', 'deleted'].includes(actionType)) {
    commit(`task/${actionType.slice(0, -1)}`, itemId);
  } else if (['new', 'added', 'edited', 'reopened'].includes(actionType)) {
    await taskCreatedOrUpdated(store, event).then(upHierarchy);

    // Some tasks modifications (e.g. dates or privacy) will have follow-on changes
    // to either subtask or dependencies, and these events may not be notified separately.
    if (extraInfo && extraInfo.data && extraInfo.data.affectedTaskIds) {
      const { affectedTaskIds } = extraInfo.data;
      const taskIds = affectedTaskIds
        .split(',')
        .map(Number)
        .filter((taskId) => taskId !== itemId);

      await dispatch('task/batch/changeNotification', taskIds);
    }
  }
};
