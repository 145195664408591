<template>
  <div
    :class="[
      {
        disabled: readonly,
      },
      'task-tags',
      view === 'tasks-table' ? 'table-cell-tags' : '',
    ]"
  >
    <TagPicker
      v-if="!readonly || (view === 'task-details' && value.length > 0)"
      :project-ids="`0,${projectId}`"
      :limit="limit"
      :class="{
        'mr--small': view === 'tasks-table',
        'table-cell-tags__picker': view === 'tasks-table',
        'table-cell-tags__picker--inactive':
          view === 'tasks-table' && !pickerOpen,
      }"
      @show="pickerOpen = true"
      @hide="pickerOpen = false"
      v-model="tags"
      :disabled="readonly"
    >
      <AddButton v-if="view === 'task-details'" :disabled="readonly">
        <template v-if="value.length === 0">{{ $t('Add tags') }}</template>
      </AddButton>
    </TagPicker>

    <TagList
      v-model="tags"
      :readonly="readonly"
      list-mode="horizontal"
      :wrap-items="view === 'task-details'"
      :items-are-selected="true"
    />
    <div v-if="view === 'tasks-table'" class="chevron" />
  </div>
</template>

<script>
import TagPicker from '@widgets/TagPicker';
import TagList from '@widgets/TagList';
import AddButton from '@widgets/TaskDetailWidgets/AddButton';

export default {
  name: 'Tags',
  components: { AddButton, TagPicker, TagList },
  data() {
    return {
      pickerOpen: false,
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: 0,
    },
    view: {
      type: String,
      default: 'tasks-table',
    },
  },
  computed: {
    tags: {
      get() {
        return this.value || [];
      },
      set(newVal) {
        this.$emit('input', newVal);
        this.$emit('change', newVal);
      },
    },
  },
};
</script>

<style lang="scss">
.task-tags {
  display: flex;
  align-items: center;
}

.table-cell-tags {
  padding-left: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  overflow: overlay;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
  }

  &:not(:hover) {
    .table-cell-tags__picker--inactive {
      display: none;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(66, 82, 110, 0.36);
    }
  }

  .chevron::before {
    top: calc(50% - 10px);
    border: 0;
    transition: top ease 0.1s;
    color: inherit;
  }
}
</style>
