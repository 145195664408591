<script>
import { shallowRef } from 'vue-demi';
import { useDelayLoader, useProjectsLoader } from '@teamwork/use';
import LoaderState from '@/platform/components/LoaderState';
import ProjectsPanelProjectList from './ProjectsPanelProjectList.vue';
import ProjectsPanelInformationalMessage from './ProjectsPanelInformationalMessage.vue';
import ProjectsPanelLoadingState from './ProjectsPanelLoadingState.vue';
import ProjectsPanelBlankSlate from './ProjectsPanelBlankSlate.vue';

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoaderState,
    ProjectsPanelProjectList,
    ProjectsPanelInformationalMessage,
    ProjectsPanelLoadingState,
    ProjectsPanelBlankSlate,
  },

  setup() {
    const recentProjectsState = useDelayLoader(
      useProjectsLoader({
        count: shallowRef(10),
        pageSize: shallowRef(10),
        params: shallowRef({
          searchTerm: null,
          include: 'companies',
          includeProjectUserInfo: true,
          'fields[project]': 'name, isStarred, companyId',
          orderBy: 'lastWorkedOn',
          orderMode: 'desc',
          onlyStarredProjects: false,
        }),
      }),
    );

    return {
      recentProjectsState,
    };
  },
};
</script>

<template>
  <LoaderState
    v-if="isActive"
    :count="7"
    :state="recentProjectsState"
    class="h-full recent-project-list--scroll-container"
  >
    <template #data="{ items }">
      <div class="w-full pb-2">
        <ProjectsPanelProjectList class="px-6" :projects="items.slice(0, 10)" />

        <ProjectsPanelInformationalMessage
          class="pt-8"
          v-if="items.length < 3"
        />
      </div>
    </template>
    <template #blank>
      <ProjectsPanelBlankSlate />
    </template>
    <template #loading>
      <ProjectsPanelLoadingState />
    </template>
  </LoaderState>
</template>
