<template>
  <modal-dialog
    @close="closePopover"
    class="passive-help-popover"
    :transition="'disable'"
    name="passive-help-modal"
    :has-close-icon="false"
    :header-is-bordered="false"
    v-click-outside="closePopover"
    v-bind="{ draggable: false }"
  >
    <div class="passive-help-popover__content">
      <div class="passive-help-popover__image">
        <img
          :src="`${currentPassiveHelp.image.normal}`"
          :width="currentPassiveHelp.image.width"
          :height="currentPassiveHelp.image.height"
          :alt="currentPassiveHelp.name()"
        />
      </div>
      <div>
        <div class="passive-help-popover__text">
          <h2 class="title">{{ currentPassiveHelp.name() }}</h2>
          <p class="description" v-html="currentPassiveHelp.description()"></p>
        </div>
        <div class="passive-help-popover__footer">
          <Button
            variant="primary"
            @click="changePassiveHelpPanelStatus(false)"
          >
            {{ $t('Got it!') }}
          </Button>
          <Button
            variant="primary"
            class="passive-help-popover__btn-read-more"
            @click="openSupportLink"
          >
            {{ $t('Read more') }}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.75 8H13.25C13.0938 8 13 8.125 13 8.25V14.5C13 14.7812 12.75 15 12.5 15H1.5C1.21875 15 1 14.7812 1 14.5V3.5C1 3.25 1.21875 3 1.5 3H7.75C7.875 3 8 2.90625 8 2.75V2.25C8 2.125 7.875 2 7.75 2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.25C14 8.125 13.875 8 13.75 8ZM15.625 0L11.375 0.03125C11.1562 0.03125 11 0.1875 11 0.40625V0.71875C11 0.90625 11.1562 1.09375 11.375 1.09375L14.1875 1.0625V1.09375L4.09375 11.1875C4.03125 11.2812 3.96875 11.375 3.96875 11.4688C3.96875 11.5625 4.03125 11.6562 4.09375 11.7188L4.28125 11.9062C4.34375 11.9688 4.4375 12.0312 4.53125 12.0312C4.625 12.0312 4.71875 11.9688 4.8125 11.9062L14.9062 1.8125L14.9375 1.84375L14.9062 4.65625C14.9062 4.84375 15.0938 5.03125 15.2812 5.03125H15.5938C15.8125 5.03125 15.9688 4.84375 15.9688 4.65625L16 0.375C16 0.1875 15.8125 0 15.625 0Z"
                fill="#4461D7"
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  </modal-dialog>
</template>
<script>
import { watch } from 'vue-demi';
import ModalDialog from '@widgets/ModalDialog';
import Button from '@widgets/Button';
import usePassiveHelp from '@/views/PassiveHelp/usePassiveHelp';

export default {
  components: {
    Button,
    ModalDialog,
  },
  props: {},
  data() {
    return {};
  },
  methods: {
    closePopover() {
      this.$emit('close');
    },
    openSupportLink() {
      this.closePopover();
      window.open(this.currentPassiveHelp.supportLink);
    },
  },

  destroyed() {
    this.changePassiveHelpPanelStatus(false);
  },

  setup(props, { emit }) {
    const {
      changePassiveHelpPanelStatus,
      currentPassiveHelp,
      isCurrentRoutePassiveHelpEnabled,
    } = usePassiveHelp();

    watch(
      () => isCurrentRoutePassiveHelpEnabled.value,
      (newValue) => {
        if (!newValue) {
          emit('close');
        }
      },
      { immediate: true },
    );

    return {
      changePassiveHelpPanelStatus,
      currentPassiveHelp,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

.passive-help-popover {
  position: fixed;
  left: 60px;
  top: 110px;
  z-index: 199;
  max-width: 100%;
  max-width: 80vw;
  max-height: 80vh;

  ::v-deep .w-modal-dialog {
    overflow: auto;
    width: 862px;
    max-width: 80vw;
    max-height: 80vh;
    min-width: 280px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(11, 14, 31, 0.16);
    flex-shrink: 0;

    &__body {
      padding: 0px 20px;
    }
    &__header {
      padding: 12px;
    }
    &__footer {
      padding: 0px 0px 24px;
    }
  }

  &__footer {
    padding: 16px 0px 0px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
  }

  &__btn-read-more {
    color: #4461d7;
    background: none;
    border: none;
    padding: 0px 0px;
    margin-left: 32px;
  }
}

.description {
  font-size: 14px;
  line-height: 150%;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
</style>
