export default function optionPropValidator(
  _options = [],
  propName = 'options',
) {
  const options = Array.isArray(_options) ? _options : Object.values(_options);
  return function validator(value) {
    const valid = options.includes(value);
    if (!valid) {
      console.error(
        `${propName} does not include "${value}". Valid values are [${options.join()}]`,
      );
    }
    return valid;
  };
}
