<template>
  <div class="table-cell-repeats px--medium block max-w-full">
    <EllipsisLabel :label="getRepeatSequence" />
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import EllipsisLabel from '@widgets/EllipsisLabel';

// @vue/component
export default {
  name: 'Repeats',
  display: 'Repeats',
  components: {
    EllipsisLabel,
  },
  data() {
    return {
      daysKey: {
        sunday: Vue.t('Sunday'),
        monday: Vue.t('Monday'),
        tuesday: Vue.t('Tuesday'),
        wednesday: Vue.t('Wednesday'),
        thursday: Vue.t('Thursday'),
        friday: Vue.t('Friday'),
        saturday: Vue.t('Saturday'),
      },
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getRepeatSequence() {
      const sequence = this.value;
      let hasEndDate = false;
      let formattedEndDate = null;
      let output = null;

      if (!sequence) {
        return Vue.t('No');
      }

      if (sequence.endOn != null && sequence.endOn !== '') {
        hasEndDate = true;
        formattedEndDate = moment(sequence.endOn).format('DD/MM/YYYY');
      }

      switch (sequence.frequency) {
        case 'daily':
          output = Vue.t('Daily');
          break;
        case 'weekdays':
          output = Vue.t('Weekdays');
          break;
        case 'selecteddays':
          output = sequence.selectedWeekDays
            .map((d) => this.daysKey[d])
            .join(', ');
          break;
        case 'weekly':
          output = Vue.t('Weekly');
          break;
        case 'everyxdays':
          if (sequence.duration / 1 === 1) {
            output = Vue.t('Every [0] day', 1);
          } else {
            output = Vue.t('Every [0] days', sequence.duration);
          }
          break;
        case 'everyxdaysweekday':
          if (sequence.duration / 1 === 1) {
            output = Vue.t('Weekday');
          } else {
            output = Vue.t(
              "Every [0] days ('only weekdays')",
              sequence.duration,
            );
          }
          break;
        case 'every2weeks':
          output = Vue.t('Every [0] weeks', 2);
          break;
        case 'every3weeks':
          output = Vue.t('Every [0] weeks', 3);
          break;
        case 'every4weeks':
          output = Vue.t('Every [0] weeks', 4);
          break;
        case 'every5weeks':
          output = Vue.t('Every [0] weeks', 5);
          break;
        case 'every6weeks':
          output = Vue.t('Every [0] weeks', 6);
          break;
        case 'monthly':
          output = Vue.t('Monthly');
          break;
        case 'every2months':
          output = Vue.t('Every [0] months', 2);
          break;
        case 'every3months':
          output = Vue.t('Every [0] months', 3);
          break;
        case 'every4months':
          output = Vue.t('Every [0] months', 4);
          break;
        case 'every6months':
          output = Vue.t('Every [0] months', 6);
          break;
        case 'yearly':
          output = Vue.t('Yearly');
          break;
        default:
          output = '';
      }

      if (hasEndDate) {
        return output + Vue.t(' until [0]', formattedEndDate);
      }
      return output;
    },
  },
};
</script>
