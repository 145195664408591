<template>
  <Popover
    ref="optionsPopover"
    :popper-options="optionsPopperOptions"
    :no-close-other="true"
    :no-arrow="true"
    boundaries-element="body"
    placement="bottom-end"
    :container="false"
    popover-class="notifications__header-options-popover-content"
    class="notifications__header-options-popover"
  >
    <IconButton
      v-tooltip="{
        content: $t('More options'),
        placement: 'top',
      }"
      icon="ellipsis-h"
      :classic-mode="true"
      :data-identifier="`${dataIdentifierPrefix}-notifications-options`"
    />

    <template slot="popover" slot-scope="{ hide }">
      <ul>
        <li
          v-if="shouldRenderMarkAllAsReadOption"
          :disabled="!canMarkAllAsRead"
          v-tooltip="{
            content: markAllAsReadTooltipContent,
            placement: 'top',
          }"
          :data-identifier="`${dataIdentifierPrefix}-notifications-options-mark-all-as-read`"
          role="button"
          class="flex items-center gap-3"
          @click="onMarkAllAsReadClicked(hide)"
        >
          <ProjectsIcon id="check-circle" class="mute-icon" />
          <span>{{ $t('Mark all as read') }}</span>
        </li>

        <li
          v-if="shouldRenderMuteOption"
          :data-identifier="`${dataIdentifierPrefix}-notifications-options-mute`"
          role="button"
          class="flex items-center gap-3"
          @click="onMuteNotificationsClicked(hide)"
        >
          <CommonIcon id="notifications-disabled" />
          <FSwitchField
            label-before-input
            size="sm"
            :checked="areNotificationsMuted"
            @change="setAreNotificationsMuted($event)"
          >
            {{ $t('Mute notifications') }}
          </FSwitchField>
        </li>
      </ul>
    </template>
  </Popover>
</template>

<script>
import { computed, ref, Vue2 as Vue, watch } from 'vue-demi';

import ProjectsIcon from '@teamwork/common-icons/dist/v-projects-icon';
import IconButton from '@widgets/IconButton';
import Popover from '@widgets/Popover';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import { FSwitchField } from '@fragments';

import useStore from '@/platform/composables/useStore';
import { useFeatures } from '@/platform/composables/useFeatures';

import { useNotificationsManager } from '@/components/sections/Notifications/useNotificationsManager';
import { useNotificationsUnreadCount } from '@/platform/composables/useNotificationsUnreadCount';

export default {
  name: 'NotificationsOptionsPopover',
  components: {
    ProjectsIcon,
    IconButton,
    Popover,
    FSwitchField,
    CommonIcon,
  },
  props: {
    dataIdentifierPrefix: {
      type: [String, Number],
      required: false,
      default: '',
    },
    shouldShowMuteOption: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const { shouldReduceElasticsearchLoad } = useFeatures();

    const { filter, markAllRead, areNotificationsMuted } =
      useNotificationsManager();

    const { count: unreadCount } = useNotificationsUnreadCount();

    const optionsPopover = ref(null);

    const optionsPopperOptions = computed(() => ({
      modifiers: { flip: { enabled: false } },
    }));

    const shouldRenderMarkAllAsReadOption = computed(() => {
      if (shouldReduceElasticsearchLoad.value) {
        return false;
      }

      if (areNotificationsMuted.value && props.shouldShowMuteOption) {
        return false;
      }
      return true;
    });

    const shouldRenderMuteOption = computed(() => {
      return props.shouldShowMuteOption;
    });

    const canMarkAllAsRead = computed(() => {
      if (unreadCount.value < 1) {
        return false;
      }

      if (filter.hasActiveFilter.value) {
        return false;
      }

      return true;
    });

    const markAllAsReadTooltipContent = computed(() => {
      if (filter.hasActiveFilter.value) {
        return Vue.t('Remove filter to \n mark all as read');
      }
      if (unreadCount.value < 1) {
        return Vue.t('You have no unread \n notifications');
      }
      return '';
    });

    const areTkoModalsOpen = computed(
      () => store.state.layout.areTkoModalsOpen,
    );

    watch(areTkoModalsOpen, () => optionsPopover.value?.hide());

    function onMarkAllAsReadClicked(hideFn) {
      if (!canMarkAllAsRead.value) {
        return;
      }

      markAllRead();
      hideFn();
    }

    function onMuteNotificationsClicked(hideFn) {
      areNotificationsMuted.value = !areNotificationsMuted.value;
      hideFn();
    }

    return {
      optionsPopover,
      optionsPopperOptions,
      shouldRenderMarkAllAsReadOption,
      shouldRenderMuteOption,
      canMarkAllAsRead,
      markAllAsReadTooltipContent,
      areTkoModalsOpen,
      areNotificationsMuted,
      onMarkAllAsReadClicked,
      onMuteNotificationsClicked,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

// This is a temporary override to avoid harming global popovers
// This should be removed when the component is updated
.notifications__header-options-popover {
  flex: 0 0 auto;
}

.notifications__header-options-popover-content {
  .w-popover__inner {
    overflow: hidden;
  }

  ul {
    user-select: none;
    list-style-type: none;
    padding: 0;

    li {
      font-size: 14px;
      line-height: 24px;
      color: #0b0e1f; // Use 'Neutral 60' design token when available
      padding: 10px 14px;
      border-radius: 8px;
      transition-property: background-color, border-color, color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      position: relative;
      z-index: 1;

      &:hover {
        background-color: #f5f7fa; // Use 'Neutral 10' design token when available
      }

      &[disabled] {
        cursor: default;
        color: #c5cee0; // Use 'Neutral 30' design token when available

        &:hover {
          background-color: transparent;
        }

        svg {
          fill: #c5cee0; // Use 'Neutral 30' design token when available
        }
      }

      svg {
        fill: #646d7d; // Use 'Neutral 50' design token when available
        width: 16px;
      }
    }
  }
}
</style>
