import { kebabCase } from 'lodash';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import ProjectsIcon from '@teamwork/common-icons/dist/v-projects-icon';

import Button from '@widgets/Button';

// @vue/component
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'add',
    },
    color: {
      type: String,
      default: '',
    },
    // Classic mode can be enabled to mimic the style of the older TKO icon button
    classicMode: {
      type: Boolean,
      default: false,
    },
    component: {
      type: String,
      default: 'common-icon',
      validator(value) {
        return ['common-icon', 'projects-icon'].includes(kebabCase(value));
      },
    },
  },
  components: {
    Button,
    CommonIcon,
    ProjectsIcon,
  },
};
