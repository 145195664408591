import { validateHex, isColorLight, darken } from '@/utils/helpers/color';

export default function buildThemeCSS(color) {
  const rawColor = color.replace('#', '');
  const darkTint = darken(rawColor, 0.75);
  const isLight = validateHex(rawColor) && isColorLight(rawColor);

  return `
      .app-header, .w-side-nav__block {
        background-color: #${rawColor};
      }
      .w-data-grid th, .w-time-grid th, table.timeGrid th, table.dataGrid th{
        background-color: ${darkTint} !important;
      }
      #navPTW, .app-header__logo, .w-header-titles__account-name,
      .w-header-titles__project-name, .w-header-titles__company-owner,
      .w-side-nav__item a, .w-header-nav__item {
        ${isLight ? 'color: #333 !important;' : ''}
      }
    `;
}
