<template>
  <component
    :is="tag"
    class="
      px-2
      border border-solid border-[#E557B3]
      rounded
      bg-transparent
      text-xs text-[#E557B3]
      h-6
      inline-flex
      justify-center
      items-center
    "
  >
    <slot>{{ $t('Beta') }}</slot>
  </component>
</template>

<script>
export default {
  name: 'FBetaIndicator',
  props: {
    tag: {
      type: String,
      default: 'span',
    },
  },
};
</script>
