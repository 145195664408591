var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tko-component',{ref:"editor",class:[
    {
      'w-markdown-editor-vue--quiet': _vm.quiet && _vm.account.markdownEnabled,
      'w-markdown-editor-vue--wizard': _vm.wizard,
    },
    'w-markdown-editor-vue' ],attrs:{"name":"widget-easy-markdown-editor","params":{
    content: _vm.content,
    placeholder: _vm.placeholder,
    disableResize: _vm.disableResize,
    showToggleOptions: _vm.showToggleOptions,
    showing: _vm.showing,
    autoFocusAtEnd: _vm.autoFocusAtEnd,
    dataIdentifier: _vm.dataIdentifier,
    onBlur: _vm.onBlur,
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }