<template>
  <label
    :class="['w-checkbox', label ? 'w-checkbox__label' : 'no-label']"
    tabindex="0"
    @keydown.enter.prevent="toggle"
  >
    <input
      ref="input"
      tabindex="-1"
      :checked="value"
      type="checkbox"
      :style="{ color: iconColor }"
      @click="handleClick"
      :disabled="disabled"
    />
    <span :class="label ? 'w-checkbox__text' : ''">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    iconColor: {
      type: String,
      required: false,
      default: '#767676',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('input', event.target.checked);
    },
    toggle() {
      this.$refs.input.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.w-checkbox {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  font-size: 16px;

  &__text {
    margin-left: 8px;
    user-select: none;
  }
}

.w-checkbox__label {
  font-size: 14px;
  color: black;
}

.no-label {
  margin: 0px; // override default label margin
}
</style>
