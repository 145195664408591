<template>
  <div
    :class="[
      'dependnecy-status-picker-item',
      { 'dependnecy-status-picker-item__selected': selected },
    ]"
  >
    <DependencyStatus
      :type="type"
      class="dependnecy-status-picker-item__status"
    />
    <span class="dependnecy-status-picker-item__title">{{ name }}</span>
    <p class="dependnecy-status-picker-item__description">
      <slot name="description" />
    </p>
  </div>
</template>
<script>
import DependencyStatus from '@/components/widgets/TaskDetailWidgets/DependencyStatus';

export default {
  name: 'DependencyStatusPickerItem',
  props: {
    name: { type: String, default: '' },
    type: { type: String, default: '' },
    selected: { type: Boolean, default: false },
  },
  components: {
    DependencyStatus,
  },
};
</script>
<style lang="scss" scoped>
.dependnecy-status-picker-item {
  padding: 8px 16px;
  cursor: pointer;
  &__selected {
    background: #f5f7fa;
    pointer-events: none;
  }
  &:hover {
    background: #f5f7fa;
  }
  &__status {
    margin-right: 8px;
  }
  &__title {
    font-weight: 600;
  }
  &__description {
    margin-top: 4px;
    margin-left: 32px;
  }
}
</style>
