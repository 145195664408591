<template>
  <main class="main">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="" />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://fonts.googleapis.com/css?family=Nunito:400,500"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdn-brand.teamwork.com/uptime/503.css"
    />
    <div class="content">
      <div class="content-left">
        <div class="logo">
          <img
            src="https://cdn-brand.teamwork.com/images/logos/teamwork-slate.svg"
          />
        </div>
        <h1 class="heading">We're experiencing some issues</h1>
        <hr class="divider" />
        <p class="context">
          We're very sorry for the inconvenience. We're aware of the problem and
          are currently working hard to get back up and running. In the
          meantime, try going back or check out our
          <a class="link" href="https://status.teamwork.com" target="_blank">
            Status page
          </a>
          for an update.
        </p>
        <p>
          <a
            class="btn btn-outline-indigo"
            href="https://status.teamwork.com"
            target="_blank"
          >
            Check our Status page
          </a>
          <a
            class="btn btn-outline-indigo"
            href="mailto:support@teamwork.com"
            target="_blank"
          >
            Report a problem
          </a>
        </p>
      </div>
      <div class="content-right">
        <div class="graphic">
          <img src="https://cdn-brand.teamwork.com/uptime/503.svg" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  mounted() {
    document.getElementById('preloader').classList.toggle('hidden', true);
  },
};
</script>

<style scoped>
.app-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.app-error-svg {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
