import { provide, inject, shallowRef } from 'vue-demi';

const StepsSymbol = Symbol('Steps');

export function provideSteps(steps) {
  provide(StepsSymbol, steps);
  return steps;
}

export function useSteps() {
  return inject(StepsSymbol, {
    current: shallowRef(null),
  });
}
