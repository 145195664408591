<template>
  <div>
    <TaskItemContainer
      :has-children="Boolean(hasChildren)"
      :level="level"
      :last="last"
    >
      <SubtasksItemToggle
        :has-children="Boolean(hasChildren)"
        :open="open"
        @click.native="open = !open"
      />
      <TasksIndentation
        :target-width="20"
        :target-offset="32"
        :vertical-offset="0"
        :horizontal-offset="0"
        :z-index-offset="2"
        :row-height="48"
        :level="level"
        :last-child="last"
        :open="open"
        :leaf-levels="leafParents"
      />
      <template #content>
        <TaskToggleStatus size="small" :task="subtask" />
        <TaskItemName
          :value="subtask.name"
          :completed="isCompleted"
          @click="selectSubtask"
        />
      </template>
      <template #actions>
        <DateRangePicker
          date-picker-placement="top-end"
          :disabled="isCompleted || !canEdit"
          :due-date="subtask.dueDate"
          :start-date="subtask.startDate"
          @change-start-date="updateStartDate"
          @change-due-date="updateDueDate"
        />
        <TaskAssigneePicker
          v-if="subtask.userPermissions"
          :project-id="subtask.projectId"
          :task-id="subtask.id"
          :assignees="subtask.assignees"
          :disabled="!subtask.userPermissions.canEdit"
          :company-privacy-ids="companyPrivacyIds"
          :user-privacy-ids="userPrivacyIds"
          @choose-more="onChooseMore"
          @assignees-updated="onAssigneesUpdated"
        />
      </template>
    </TaskItemContainer>
    <div v-if="hasChildren && open">
      <SubtasksItems
        :task="subtask"
        :subtask-ids="subtask.subTaskIds"
        :level="level + 1"
        :leaf-levels="leafParents"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, shallowRef } from 'vue-demi';
import { useTaskActions } from '@teamwork/use';
import DateRangePicker from '@widgets/DateRangePicker';
import TasksIndentation from '@widgets/TasksIndentation';
import TaskToggleStatus from '@/components/widgets/TaskToggleStatus';
import { useQuickViews } from '@/platform/composables/useQuickViews';
import updateTaskAssignees from '@/utils/helpers/updateTaskAssignees';
import TaskItemName from '../TaskItemName';
import SubtasksItemToggle from '../SubtasksItemToggle';
import TaskItemContainer from '../TaskItemContainer';
import useStore from '@/platform/composables/useStore';

export default defineComponent({
  name: 'SubtasksItem',
  components: {
    DateRangePicker,
    TaskItemName,
    TasksIndentation,
    TaskToggleStatus,
    SubtasksItemToggle,
    TaskItemContainer,
    TaskAssigneePicker: () =>
      import('../TaskAssigneePicker/TaskAssigneePicker.vue'),
    SubtasksItems: () => import('../SubtasksItems/SubtasksItems.vue'),
  },
  props: {
    parent: { type: Object, required: true },
    subtask: { type: Object, required: true },
    level: { type: Number, required: false, default: 0 },
    last: { type: Boolean, default: false },
    leafLevels: { type: Array, default: () => [] },
  },
  setup(props) {
    const { completeTask, uncompleteTask, updateTask } = useTaskActions();
    const { replaceQuickView } = useQuickViews();
    const store = useStore();
    const open = shallowRef(false);

    const canEdit = computed(
      () =>
        props.subtask.canEdit ||
        (props.subtask.userPermissions &&
          props.subtask.userPermissions.canEdit),
    );

    const hasChildren = computed(() => {
      if (!props.subtask.subtaskStats) {
        return false;
      }

      const { subtaskStats } = props.subtask;
      return subtaskStats.active + subtaskStats.complete > 0;
    });

    const isCompleted = computed(() => props.subtask.status === 'completed');

    // add current task if it's a leaf node
    const leafParents = computed(() => {
      const leafLevels = props.leafLevels.slice();
      if (props.last) {
        leafLevels.push(props.level);
      }
      return leafLevels;
    });

    const companyPrivacyIds = computed(() =>
      props.subtask.lockdown && props.subtask.lockdown.grantAccessTo
        ? props.subtask.lockdown.grantAccessTo.companyIds
        : [],
    );

    const userPrivacyIds = computed(() =>
      props.subtask.lockdown && props.subtask.lockdown.grantAccessTo
        ? props.subtask.lockdown.grantAccessTo.userIds
        : [],
    );

    function onChooseMore() {
      // Open old People Picker
      this.updateAssignees();
    }

    function onAssigneesUpdated(assignees) {
      updateTaskAssignees({
        task: this.subtask,
        assignees,
        updateTask,
      });
    }

    function updateAssignees() {
      if (!canEdit.value) {
        return;
      }
      store.dispatch('modals/open', {
        name: 'people-picker-modal',
        props: {
          itemType: 'task',
          taskId: props.subtask.id,
          projectId: props.subtask.projectId,
          lockdownId: props.subtask.lockdownId,
          assignedTo: props.subtask.assignees,
          callback: (assignees) => {
            updateTask(
              {
                id: props.subtask.id,
                assignedTo: assignees
                  .filter((x) => x.linkedItem.pickerItemType === 'user')
                  .map((x) => ({
                    id: x.id,
                    avatarUrl:
                      x.linkedItem.avatarUrl || x.linkedItem.defaultImageUrl,
                    firstName: x.linkedItem.firstName,
                    lastName: x.linkedItem.lastName,
                  })),
                assignedToCompanies: assignees
                  .filter((x) => x.linkedItem.pickerItemType === 'company')
                  .map((x) => ({
                    companyId: x.companyId / 1,
                    companyLogoURL: x.linkedItem.defaultImageUrl,
                    companyName: x.label,
                  })),
                assignedToTeams: assignees
                  .filter((x) => x.linkedItem.pickerItemType === 'team')
                  .map((x) => ({
                    teamId: x.teamId,
                    teamLogo: x.logo,
                    teamLogoColor: x.color,
                    teamLogoIcon: x.icon,
                    teamName: x.label,
                  })),
              },
              props.subtask,
            );
          },
        },
      });
    }

    function updateStartDate({ date, autoAdjustDueDate = false }) {
      const updatedSubtask = {
        id: props.subtask.id,
        startDate: date,
        pushSubtasks: store.state.preferences.user.taskPushSubTasks,
        pushDependents: store.state.preferences.user.taskPushDependentTasks,
      };
      if (autoAdjustDueDate) {
        updatedSubtask.dueDate = date;
      }
      updateTask(updatedSubtask, props.subtask);
    }

    function updateDueDate({ date, autoAdjustStartDate = false }) {
      const updatedSubtask = {
        id: props.subtask.id,
        dueDate: date,
        pushSubtasks: store.state.preferences.user.taskPushSubTasks,
        pushDependents: store.state.preferences.user.taskPushDependentTasks,
      };
      if (autoAdjustStartDate) {
        updatedSubtask.startDate = date;
      }
      updateTask(updatedSubtask, props.subtask);
    }

    function selectSubtask() {
      replaceQuickView({
        type: 'task',
        id: props.subtask.id,
        initialItem: { ...props.subtask, parentTask: props.parent },
      });
    }

    function toggleSubtask() {
      if (props.subtask.status === 'completed') {
        uncompleteTask(props.subtask);
      } else {
        completeTask(props.subtask);
      }
    }

    return {
      isCompleted,
      open,
      leafParents,
      hasChildren,
      companyPrivacyIds,
      userPrivacyIds,
      onChooseMore,
      onAssigneesUpdated,
      updateAssignees,
      updateStartDate,
      updateDueDate,
      toggleSubtask,
      selectSubtask,
      canEdit,
    };
  },
});
</script>
