<template>
  <div class="mt-400 mb-200 px-350 w-full" v-if="canAddProjectsPermission">
    <button
      type="button"
      class="
        rounded-full
        w-full
        h-9
        outline-none
        bg-primary
        border-none
        hover:bg-primary-strong
        text-[14px] text-white
        leading-tight
        inline-flex
        justify-center
        items-center
      "
      @click="addProject"
    >
      <span class="mr-2 w-4 h-4">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1111 5.33329H6.66669V0.888848C6.66669 0.76607 6.56724 0.666626 6.44447 0.666626H5.55558C5.4328 0.666626 5.33335 0.76607 5.33335 0.888848V5.33329H0.888909C0.766131 5.33329 0.666687 5.43274 0.666687 5.55551V6.4444C0.666687 6.56718 0.766131 6.66663 0.888909 6.66663H5.33335V11.1111C5.33335 11.2338 5.4328 11.3333 5.55558 11.3333H6.44447C6.56724 11.3333 6.66669 11.2338 6.66669 11.1111V6.66663H11.1111C11.2339 6.66663 11.3334 6.56718 11.3334 6.4444V5.55551C11.3334 5.43274 11.2339 5.33329 11.1111 5.33329Z"
            fill="#ffffff"
          />
        </svg>
      </span>
      <span class="leading-none">
        {{ $t('Add Project') }}
      </span>
    </button>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import { useCurrentUser } from '@teamwork/use';
import { useHybrid } from '@/platform/composables/useHybrid';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

export default {
  name: 'ProjectsPanelAddProjectButton',
  setup() {
    const user = useCurrentUser();

    const { openModal: openHybridModal } = useHybrid();
    const { tryhideProjectsPanel } = useSidebarPanels();
    const canAddProjectsPermission = computed(() => {
      return user.value.permissions.canAddProjects || user.value.administrator;
    });

    function addProject() {
      openHybridModal('addOrEditProjectWizard', {});
      tryhideProjectsPanel();
    }

    return {
      addProject,
      canAddProjectsPermission,
    };
  },
};
</script>
