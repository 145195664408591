<template>
  <div
    class="skeleton"
    :style="{
      width: `${width}px`,
      height: `${height}px`,
      borderRadius: radius,
      display: inline ? 'inline-block' : 'block',
    }"
  ></div>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    width: {
      type: [String, Number],
      default: 100,
    },
    height: {
      type: [String, Number],
      default: 50,
    },
    radius: {
      type: String,
      default: '20px',
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
