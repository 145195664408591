/* eslint-disable no-param-reassign,consistent-return,no-console */
import Vue from 'vue';
import api, { API_PREFIXES } from '@/services/api';

async function isNewInvoiceActive(dispatch, getters) {
  // eslint-disable-next-line no-undef
  if (!app.features.projectsStripeV2SubscriptionUpdateEnabled()) {
    return false;
  }

  let checkoutData = getters['checkout/checkoutData'];

  if (!checkoutData.installation) {
    await dispatch('checkout/computeUrl');
    await dispatch('checkout/fetchCheckoutData');
    checkoutData = getters['checkout/checkoutData'];
  }

  const projects = checkoutData.installation.apps.projects;

  return (
    projects.paymentMethod === 'stripe' &&
    projects.paymentCollectionMethod === 'send_invoice' &&
    projects.inGracePeriod
  );
}

export const watchers = [
  {
    getter: (state, rootState, getters) =>
      getters['account/shouldWarnTrialExpiry'],
    callback: async ({ getters, dispatch }, warn) => {
      if (await isNewInvoiceActive(dispatch, getters)) {
        return;
      }

      const id = 'trialexpiry';
      const data = {
        overdue: getters['account/paymentOverdue'],
        admin: getters['user/isSiteAdmin'],
      };
      const action = warn ? 'add' : 'ignore';
      dispatch(`notifications/banners/${action}`, warn ? { id, data } : id);
      if (warn) {
        dispatch('modals/tko/open', { id: 'trialOrPaymentExpiry' });
      }
    },
  },
  {
    getter: (state, rootState, getters) => getters['account/paymentOverdue'],
    callback: async ({ getters, dispatch }, warn) => {
      if (await isNewInvoiceActive(dispatch, getters)) {
        return;
      }

      const id = 'trialexpiry';
      const data = {
        overdue: getters['account/paymentOverdue'],
        admin: getters['user/isSiteAdmin'],
      };
      const action = warn ? 'add' : 'ignore';
      dispatch(`notifications/banners/${action}`, warn ? { id, data } : id);
      if (warn) {
        dispatch('modals/tko/open', {
          id: 'trialOrPaymentExpiry',
          args: { paymentOverdue: true },
        });
      }
    },
  },
  {
    getter: (state, rootState, getters) =>
      getters['account/shouldWarnTrialEndingSoon'],
    callback: ({ dispatch, getters }, warn) => {
      if (warn) {
        const trialDaysRemaining = getters['account/trialDaysRemaining'];
        if (trialDaysRemaining > 5 && trialDaysRemaining < 11) {
          dispatch('notifications/banners/add', { id: 'trialendingsoon' });
        } else if (trialDaysRemaining >= 0 && trialDaysRemaining <= 5) {
          dispatch('modals/tko/open', {
            id: 'trialOrPaymentExpiry',
            args: { trialDaysRemaining },
          });
        }
      }
    },
  },
  {
    getter: (state) => !!state, // should always return true
    callback: ({ dispatch }) =>
      dispatch('notifications/banners/getImporterStatus'),
    immediate: true,
  },
];

export default {
  namespaced: true,
  state: {
    banners: {},
    ignored: {},
  },
  getters: {
    valid: ({ banners, ignored }) =>
      Object.keys(banners).reduce(
        (visible, id) =>
          ignored[id] === banners[id]
            ? visible
            : { ...visible, [id]: banners[id] },
        {},
      ),
    // Never show the credit card expiry while there is an outage - a little tact
    visible: (state, { valid }) => {
      const { creditcard, ...rest } = valid;
      return creditcard && rest.outageErrorMessage ? rest : valid;
    },
  },
  mutations: {
    add({ banners }, { id, data = {} }) {
      Vue.set(banners, id, data);
    },
    ignore({ banners, ignored }, id) {
      Vue.set(ignored, id, banners[id]);
    },
  },
  actions: {
    add: ({ commit }, banner) => commit('add', banner),
    ignore: ({ commit }, id) => commit('ignore', id),
    version: ({ state, commit }, { version, reload }) => {
      if (
        reload &&
        (!state.ignored.version || state.ignored.version !== version)
      ) {
        commit('add', { id: 'version', version });
      }
    },
    getImporterStatus: ({ commit }) =>
      api
        .get(`${API_PREFIXES.v3}/importer/stats.json`)
        .then((rs) =>
          rs.data.isImporting
            ? commit('add', { id: 'importer' })
            : commit('ignore', 'importer'),
        ),
  },
};
