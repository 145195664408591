/* eslint-disable no-console */
import Vue from 'vue';
import { CHECKOUT } from '@tko/src/app/helpers/constants';
import api from '@/services/api';
import getTimeDiff from '@/utils/helpers/time';

export default {
  namespaced: true,
  state: {
    subscriptionRef: '',
    subscriptionRefTime: null,
    checkoutData: {},
    subscription: {},
    cardData: {},
    costData: {},
    tax: 0,
    total: 0,
    subTotal: 0,
    extraTax: 0,
    extraTotal: 0,
    extraSubTotal: 0,
    prorationTimestamp: 0,
    freeSeats: 0,
    seatsBeforePromo: 0,
  },
  getters: {
    checkoutData: (state) => state.checkoutData,
    checkoutUrl:
      ({ subscriptionRef }, getters, { account }) =>
      ({ part }) =>
        `${account?.environment.serviceUrls.checkout}${part}${encodeURI(
          subscriptionRef,
        )}`,
    currencySymbol: (state) =>
      state?.checkoutData?.currency?.symbol || state.currencySymbol,

    cost(state) {
      return Object.values(state.costData);
    },
    projectsSubscription: (state) => {
      return state.checkoutData?.installation?.apps?.projects;
    },
    paidForUsers(state, getters) {
      return getters.projectsSubscription?.paidUsers;
    },
    numPaidSeats(state) {
      return state.checkoutData?.usage?.numUsers;
    },
    currentPlan(state, getters) {
      const planId = getters.projectsSubscription?.pricePlanId;
      return state.checkoutData?.plans?.find((plan) => plan.id === planId);
    },
    minPaidSeats(state, getters) {
      return getters.currentPlan?.minUsers;
    },
  },
  actions: {
    async computeUrl({ commit, state }) {
      if (
        !state.subscriptionRef ||
        !state.subscriptionRefTime ||
        getTimeDiff(state.subscriptionRefTime) > CHECKOUT.NEW_REF_IN_MINUTES
      ) {
        const call = await api.get(CHECKOUT.SUBSCRIPTION_URL);
        commit('subscription', call.data);
      }
    },
    async fetchCheckoutData({ commit, getters }) {
      await api
        .get(getters.checkoutUrl({ part: 'checkout.json?ref=' }))
        .then((result) => {
          commit('saveCheckoutData', result.data);
        });
    },
    async fetchCreditCards({ commit, getters }) {
      await api
        .get(getters.checkoutUrl({ part: 'creditcards.json?ref=' }))
        .then((result) => {
          commit('saveCardData', result.data);
        });
    },
    async createSubscription({ commit, getters }, data) {
      const result = await api.post(
        getters.checkoutUrl({ part: 'subscription.json?ref=' }),
        data,
      );
      commit('addSubscription', result.data);
      return result;
    },
    async generateCost({ commit, getters }, data) {
      const result = await api.post(
        getters.checkoutUrl({ part: 'subscription/cost.json?ref=' }),
        data,
      );
      const { cost, proration, freeSeats, seatsBeforePromo } = result.data;
      commit('saveCost', result.data.cost);
      // divide figures below by 12*100 to get monthly costs in $
      commit('tax', cost?.vat);
      commit('total', cost?.total);
      commit('subTotal', cost?.subTotal);
      commit('extraTax', proration?.vat);
      commit('extraTotal', proration?.total);
      commit('extraSubTotal', proration?.subTotal);
      commit('prorationTimestamp', proration?.prorationTimestamp);
      commit('freeSeats', freeSeats);
      commit('seatsBeforePromo', seatsBeforePromo);
      return result;
    },
  },
  mutations: {
    subscription(state, response) {
      state.subscriptionRef = response.ref;
      state.subscriptionRefTime = new Date();
    },
    currencySymbol(state, currencySymbol) {
      state.currencySymbol = currencySymbol;
    },
    saveCheckoutData(state, response) {
      state.checkoutData = response;
    },
    saveCardData(state, response) {
      state.cardData = response[0];
    },
    addSubscription(state, response) {
      state.subscription = response;
    },
    saveCost(state, response) {
      Vue.set(state.costData, 'data', response);
    },
    tax(state, x) {
      state.tax = x;
    },
    total(state, x) {
      state.total = x;
    },
    subTotal(state, x) {
      state.subTotal = x;
    },
    extraTax(state, x) {
      state.extraTax = x;
    },
    extraTotal(state, x) {
      state.extraTotal = x;
    },
    extraSubTotal(state, x) {
      state.extraSubTotal = x;
    },
    prorationTimestamp(state, x) {
      state.prorationTimestamp = x;
    },
    freeSeats(state, x) {
      state.freeSeats = x;
    },
    seatsBeforePromo(state, x) {
      state.seatsBeforePromo = x;
    },
  },
};
