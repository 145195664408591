<template>
  <PopoverMenuActionItem>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path
          d="M9 .684A8.332 8.332 0 0 0 .667 9.017 8.332 8.332 0 0 0 9 17.351a8.332 8.332 0 0 0 8.333-8.334A8.332 8.332 0 0 0 9 .684Zm6.025 5.376h-2.258c-.225-1.226-.588-2.312-1.049-3.182a6.737 6.737 0 0 1 3.307 3.182ZM9 2.297c.625 0 1.633 1.384 2.124 3.763H6.876C7.366 3.681 8.375 2.297 9 2.297Zm-6.72 6.72c0-.46.047-.91.134-1.344h2.61c-.033.44-.056.884-.056 1.344 0 .46.023.904.057 1.344H2.414a6.803 6.803 0 0 1-.134-1.344Zm.695 2.957h2.258c.225 1.227.588 2.312 1.049 3.182a6.736 6.736 0 0 1-3.307-3.182ZM5.233 6.06H2.975a6.736 6.736 0 0 1 3.307-3.182c-.46.87-.824 1.956-1.049 3.182ZM9 15.738c-.625 0-1.633-1.385-2.124-3.764h4.251c-.494 2.38-1.502 3.764-2.127 3.764Zm2.355-5.377h-4.71c-.037-.43-.064-.873-.064-1.344 0-.47.027-.914.063-1.344h4.715c.037.43.064.874.064 1.344 0 .47-.03.914-.068 1.344Zm.363 4.795c.46-.87.82-1.955 1.049-3.182h2.258a6.737 6.737 0 0 1-3.307 3.182Zm1.257-4.795c.034-.44.057-.883.057-1.344 0-.46-.023-.904-.057-1.344h2.611a6.801 6.801 0 0 1 0 2.688h-2.61Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Switch account') }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';

export default {
  components: {
    PopoverMenuActionItem,
  },
  setup() {
    return {};
  },
};
</script>
