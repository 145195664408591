<template>
  <VPopover
    ref="popover"
    placement="right-end"
    popover-class="quickadd-more-popover"
    popover-arrow-class="hidden"
    popover-inner-class="quickadd-popover_inner"
    :offset="0"
    :open.sync="isOpen"
    @show="onShow"
    @hide="onHide"
    @apply-show="handleShow"
    @apply-hide="handleHide"
  >
    <template #default>
      <PopoverMenuActionItem
        class="w-full"
        ref="navItem"
        :aria-label="$t('Profile more menu')"
        aria-controls="side-nav-profile-more-menu"
        aria-haspopup="menu"
        :aria-expanded="opened"
        role="menu-item"
        tabindex="0"
        :badge="updatesCount"
        @keydown.native.right.prevent.stop="showPopoverMenu"
        @keydown.native.enter.prevent="showPopoverMenu"
        @click.stop="togglePopover"
      >
        <template #icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="4"
            viewBox="0 0 16 4"
          >
            <path
              d="M9.563 2.009a1.563 1.563 0 1 1-3.126-.001 1.563 1.563 0 0 1 3.125 0ZM13.469.446a1.563 1.563 0 1 0 0 3.126 1.563 1.563 0 0 0 0-3.126ZM2.53.446a1.563 1.563 0 1 0 .001 3.126 1.563 1.563 0 0 0 0-3.126Z"
            />
          </svg>
        </template>
        <template #title>
          {{ $t('More') }}
        </template>
      </PopoverMenuActionItem>
    </template>
    <template #popover>
      <ProfileMenuMoreItemsMenu
        v-if="opened"
        ref="navItemsMenu"
        @keydown.native.left.prevent.stop="hidePopoverMenu"
        @keydown.native.up.prevent.stop="() => moveActive(-1)"
        @keydown.native.shift.tab.prevent.stop="() => moveActive(-1)"
        @keydown.native.down.prevent.stop="() => moveActive(1)"
        @keydown.native.tab.exact.prevent.stop="() => moveActive(1)"
        @click.native="hidePopoverMenu"
      />
    </template>
  </VPopover>
</template>

<script>
import { ref, computed } from 'vue-demi';
import { VPopover } from 'v-tooltip';
import useStore from '@/platform/composables/useStore';
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';
import ProfileMenuMoreItemsMenu from './ProfileMenuMoreItemsMenu.vue';
import useSideNavPopoverKeyboardAccessibility from '@/platform/composables/useSideNavPopoverKeyboardAccessibility';

export default {
  components: {
    PopoverMenuActionItem,
    ProfileMenuMoreItemsMenu,
    VPopover,
  },
  setup(props, { emit }) {
    const store = useStore();
    const isOpen = ref(false);
    const manuallyClosed = ref(false);

    const updatesCount = computed(() =>
      store.state.user.hasFeatureUpdates
        ? store.state.user.featureUpdatesCount
        : null,
    );

    const {
      hidePopoverMenu,
      navItem,
      navItemsMenu,
      opened,
      popover,
      onShow,
      onHide,
      showPopoverMenu,
      moveActive,
    } = useSideNavPopoverKeyboardAccessibility();

    const togglePopover = () => {
      if (isOpen.value === true) {
        manuallyClosed.value = true;
      }
      isOpen.value = !isOpen.value;
    };

    const handleShow = () => {
      manuallyClosed.value = false;
      emit('toggle', true);
    };

    const handleHide = () => {
      if (manuallyClosed.value === false) {
        emit('toggle', false);
      }
    };

    return {
      isOpen,
      updatesCount,

      hidePopoverMenu,
      navItem,
      navItemsMenu,
      opened,
      popover,
      onShow,
      onHide,
      showPopoverMenu,
      moveActive,

      togglePopover,
      handleShow,
      handleHide,
    };
  },
};
</script>
