/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
export const RoundButton = require('./RoundButton/RoundButton').default;
export const SectionBlock = require('./SectionBlock/SectionBlock').default;
export const TaskRow = require('./TaskRow/TaskRow').default;
export const Chips = require('./Chips/Chips').default;
export const Chip = require('./Chip/Chip').default;
export const Followers = require('./Followers/Followers').default;
export const FollowersSection =
  require('./FollowersSection/FollowersSection').default;
export const TaskProgressBar =
  require('./TaskProgressBar/TaskProgressBar').default;
export const ButtonText = require('./ButtonText/ButtonText').default;
export const Subtasks = require('./Subtasks/Subtasks').default;
export const Dependencies = require('./Dependencies/Dependencies').default;
export const Comments = require('./Comments/Comments').default;
export const CommentBox = require('./CommentBox/CommentBox').default;
export const HeaderSegment = require('./HeaderSegment/HeaderSegment').default;
export const LoggedTime = require('./LoggedTime/LoggedTime').default;
export const Priority = require('../Priority/Priority').default;
export const DateLabel = require('./DateLabel/DateLabel').default;
export const Tags = require('../Tags/Tags').default;
export const TaskName = require('./TaskName/TaskName').default;
export const TaskDescription =
  require('./TaskDescription/TaskDescription').default;
export const AddButton = require('./AddButton/AddButton').default;
export const TaskAssigneePicker =
  require('./TaskAssigneePicker/TaskAssigneePicker').default;
export const FileUpload = require('./FileUpload/FileUpload').default;
export const ContextMenu = require('./ContextMenu/ContextMenu').default;
export const TimeLogs = require('./TimeLogs/TimeLogs').default;
export const FullscreenToggle =
  require('./FullscreenToggle/FullscreenToggle').default;
export const CreatedBy = require('./CreatedBy/CreatedBy').default;
export const BoardColumn = require('./BoardColumn/BoardColumn').default;
export const LogTime = require('./LogTime/LogTime').default;
export const LogTimeButton = require('./LogTimeButton/LogTimeButton').default;
export const LogTimeTimer = require('./LogTimeTimer/LogTimeTimer').default;
export const LogTimeLogger = require('./LogTimeLogger/LogTimeLogger').default;
export const TaskTimeLogger =
  require('./TaskTimeLogger/TaskTimeLogger').default;
export const BreadcrumbSection =
  require('./BreadcrumbSection/BreadcrumbSection').default;
