/* eslint-disable no-use-before-define, no-underscore-dangle */
import { computed, inject, provide, ref, Vue2 as Vue } from 'vue-demi';
import {
  useCurrentAccount,
  useCurrentUser,
  useOptimisticUpdates,
  useRealTimeUpdates,
} from '@teamwork/use';
import { provideNotificationFilter } from '@sections/Notifications/useNotificationFilter';
import store from '@/store';

import useNotificationsFetcher from './useNotificationsFetcher';
import { useNotificationActions } from './useNotificationActions';
import normalizeNotification from '@/components/sections/Notifications/normalizeNotification';
import { usePreferences } from '@/platform/composables/usePreferences';
import { shouldAddNewNotificationFromRealTimeUpdate } from '@/utils/helpers/notifications';

const NotificationsManagerSymbol = Symbol('NotificationsManager');
const waitForElasticSearchToSync = () =>
  new Promise((resolve) => setTimeout(resolve, 2000));

export function NotificationsManager() {
  const { areNotificationsUnmuted } = usePreferences();
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();
  const pageSize = ref(20);
  const filter = provideNotificationFilter();

  // api params shared by "unread", "read" and "all" notification loaders
  const sharedApiParams = computed(() => {
    const result = {
      projectIds: filter.params?.value?.projects?.map((i) => i.id).join(','),
      entities: filter.params?.value?.notificationTypes
        ?.map((i) => i.id)
        .join(','),
    };
    if (!result.projectIds) {
      delete result.projectIds;
    }
    if (!result.entities) {
      delete result.entities;
    }

    return result;
  });

  const readApiParams = computed(() => {
    return {
      ...sharedApiParams.value,
      readOnly: true,
    };
  });
  const unreadApiParams = computed(() => {
    return {
      ...sharedApiParams.value,
      unreadOnly: true,
    };
  });
  const allApiParams = computed(() => {
    return {
      ...sharedApiParams.value,
      unreadOnly: undefined,
    };
  });

  const read = useNotificationsFetcher({
    params: readApiParams,
    pageSize,
  });

  const unread = useNotificationsFetcher({
    params: unreadApiParams,
    pageSize,
  });

  const all = useNotificationsFetcher({
    params: allApiParams,
    pageSize,
  });

  const areNotificationsMuted = computed({
    get() {
      return !areNotificationsUnmuted.value;
    },
    set(newValue) {
      areNotificationsUnmuted.value = !newValue;
    },
  });

  const {
    updateNotification,
    markNotificationRead,
    markNotificationUnread,
    toggleNotificationRead,
    markAllRead,
    quickView,
    navigate,
    access,
    triggerDesktopNotification,
    triggerNotificationSound,
  } = useNotificationActions();

  // search all loaders for a notification
  function findNotification(twimEventId) {
    return (
      unread.getItem(twimEventId) ||
      read.getItem(twimEventId) ||
      all.getItem(twimEventId)
    );
  }

  function markAllAsReadUi() {
    const readItems = unread.state.items.value.map((i) => ({
      ...i,
      read: true,
    }));

    // Clear the 'unread' list
    unread.clear();

    // Add the previously unread items
    read.addMultiple(readItems);

    all.updateMultiple(readItems);
  }

  function moveFromUnreadToRead(notification) {
    unread.remove(notification);
    read.add({ ...notification, read: true });
    all.update({ ...notification, read: true });
  }

  function moveFromReadToUnread(notification) {
    read.remove(notification);
    unread.add({ ...notification, read: false });
    all.update({ ...notification, read: false });
  }

  async function addNewNotificationFromRealTimeUpdate(event, rawEvent) {
    // Until we implement client side filtering we cannot hydrate the notification state
    // when a filter is applied so we need to re-fetch to get the new notifications
    if (filter.hasActiveFilter.value) {
      await waitForElasticSearchToSync();

      unread.refresh();
      all.refresh();

      return;
    }

    const notification = normalizeNotification(
      rawEvent.eventInfo,
      currentUser.value.id,
      true,
    );

    if (!notification.id) {
      return;
    }

    // Any new notification should be added to the `unread` list
    unread.add(notification);

    // Any new notification should be added to the `all` list
    all.add(notification);
  }

  useRealTimeUpdates(async (event, rawEvent) => {
    if (!currentAccount.value?.realTimeNotifications) {
      return;
    }
    if (!rawEvent) {
      return;
    }

    if (event.detail === 'notification-mark-all-read') {
      if (unread.state.items.value.length === 0) {
        return;
      }

      markAllAsReadUi();
    }

    // Note: the 'notification-markread' event is received when a comment or
    // message entity is marked as read. In this case we must also
    // mark the corresponding notification item as read
    if (
      event.detail === 'notification-updated-read' ||
      event.detail === 'notification-markread'
    ) {
      const alreadyMarkedAsRead = !!read.getItem(
        rawEvent.eventInfo.twimEventId,
      );
      if (alreadyMarkedAsRead) {
        return;
      }

      const notification = findNotification(rawEvent.eventInfo.twimEventId);

      if (notification) {
        moveFromUnreadToRead(notification);
      } else {
        await waitForElasticSearchToSync();
        read.refresh();
      }
    }

    if (event.detail === 'notification-updated-unread') {
      const alreadyMarkedAsUnead = !!unread.getItem(
        rawEvent.eventInfo.twimEventId,
      );
      if (alreadyMarkedAsUnead) {
        return;
      }

      const notification = findNotification(rawEvent.eventInfo.twimEventId);

      if (notification) {
        moveFromReadToUnread(notification);
      } else {
        await waitForElasticSearchToSync();
        unread.refresh();
      }
    }

    if (
      shouldAddNewNotificationFromRealTimeUpdate(
        currentUser,
        currentAccount,
        event,
        rawEvent,
      )
    ) {
      addNewNotificationFromRealTimeUpdate(event, rawEvent);

      triggerDesktopNotification(event, rawEvent);

      triggerNotificationSound(event, rawEvent);
    }
  });

  function optimisticMoveFromUnreadToRead({ notification, promise }) {
    moveFromUnreadToRead(notification);

    function rollback() {
      moveFromReadToUnread(notification);
      store.dispatch(
        'notifications/flashes/error',
        Vue.t('Failed to mark notification as read'),
      );
    }

    // rollback if promise rejected
    promise.catch(rollback);
  }
  function optimisticMoveFromReadToUnread({ notification, promise }) {
    moveFromReadToUnread(notification);

    function rollback() {
      moveFromUnreadToRead(notification);
      store.dispatch(
        'notifications/flashes/error',
        Vue.t('Failed to mark notification as unread'),
      );
    }

    // rollback if promise rejected
    promise.catch(rollback);
  }

  function optimisticMarkAllAsRead({ promise }) {
    markAllAsReadUi();
    function rollback() {
      // reset all loaders if mark all as read fails
      // this is simplest and most reliable way to ensure consistent state
      unread.reset();
      read.reset();
      all.reset();

      store.dispatch(
        'notifications/flashes/error',
        Vue.t('Failed to mark all notifications as read'),
      );
    }

    promise.catch(rollback);
  }

  useOptimisticUpdates((event) => {
    if (event.type !== 'notification') {
      return;
    }

    const actions = ['update', 'markAllRead'];
    if (!actions.includes(event.action)) {
      return;
    }

    if (event.action === 'update') {
      if (event.notification.read) {
        optimisticMoveFromUnreadToRead({
          notification: event.notification,
          promise: event.promise,
        });
      } else {
        optimisticMoveFromReadToUnread({
          notification: event.notification,
          promise: event.promise,
        });
      }
    } else if (event.action === 'markAllRead') {
      // Grab the list of unread items to modify and add to both the 'read' and 'all' lists
      optimisticMarkAllAsRead({
        promise: event.promise,
      });
    }
  });

  return {
    unread,
    read,
    all,
    access,
    updateNotification,
    markNotificationRead,
    markNotificationUnread,
    toggleNotificationRead,
    markAllRead,
    areNotificationsMuted,
    quickView,
    navigate,
    filter,
  };
}

export function provideNotificationsManager() {
  const notificationsManager = NotificationsManager();
  provide(NotificationsManagerSymbol, notificationsManager);
  return notificationsManager;
}

export function useNotificationsManager() {
  return inject(NotificationsManagerSymbol);
}
