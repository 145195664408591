import {
  provide,
  inject,
  shallowRef,
  watch,
  onMounted,
  onUnmounted,
} from 'vue-demi';
import { essentialDataIsLoaded } from '@/scaffolding/map-tko-globals';
import {
  isInLightspeed as tkoIsInLightspeed,
  lightspeedUiState as tkoLightspeedUiState,
} from '@/scaffolding/tko-lightspeed';

const useLightspeedBridgeSymbol = Symbol('useLightspeedBridge');

export const frameOrigin =
  window.location.origin !== 'http://localhost:8123'
    ? window.location.origin
    : 'http://localhost:8923';

export function provideLightspeedBridge() {
  const isInLightspeed = shallowRef(false);
  const lightspeedVisibilityState = shallowRef('normal');
  const lightspeedUiState = shallowRef({});

  let modalObserver;

  function postMessageToLightspeed(name, opts) {
    if (isInLightspeed.value) {
      window.parent.postMessage({ name, ...opts }, frameOrigin);
    }
  }

  function handleBodyClick(e) {
    // Only send trusted events up to Lightspeed
    // Events that aren't trusted will have originated from Lightspeed so don't need to go back.
    if (e.isTrusted) {
      window.parent.postMessage(
        {
          name: 'twa:click',
          isTaskDetailsActivator:
            Boolean(e.target.closest('.w-task-row')) ||
            Boolean(e.target.closest('.table-cell-task')) ||
            Boolean(e.target.closest('.task-card')) ||
            /tasks\/\d+/.test(e.target.href),
        },
        frameOrigin,
      );
    }
  }

  // Explicitly define modifier shortcuts which are allowed to go to Lightspeed
  // This is to ensure we can preserve default browser behaviour
  const lightspeedModifierShortcuts = [
    { shiftKey: true, key: 'S' },
    { ctrlOrMetaKey: true, key: 'B' },
  ];

  function shouldSendModifierShortcut(event) {
    return lightspeedModifierShortcuts.some((shortcut) =>
      Object.entries(shortcut).every(([attr, val]) => {
        if (attr === 'ctrlOrMetaKey') {
          return (event.ctrlKey || event.metaKey) && !event.shiftKey;
        }
        if (attr === 'shiftKey') {
          return event.shiftKey && (!event.ctrlKey || !event.metaKey);
        }
        if (attr === 'ctrlOrMetaAndShiftKey') {
          return event.shiftKey && (event.ctrlKey || event.metaKey);
        }
        if (attr === 'key') {
          return event.key.toUpperCase() === val;
        }
        return false;
      }),
    );
  }

  function handleKeydown(event) {
    const {
      ctrlKey,
      shiftKey,
      metaKey,
      key,
      target,
      repeat,
      keyCode,
      which,
      altKey,
    } = event;

    // If an editable area is in focus, allow the event to behave as normal
    if (
      ['INPUT', 'TEXTAREA'].includes(target.tagName) ||
      target.getAttribute('contenteditable') === 'true' ||
      key === 'Tab'
    ) {
      return;
    }

    // We only want to pass A-Z, 1-9 and some special character events
    if (!/^[-=?A-Z0-9]$/.test(key.toUpperCase())) {
      return;
    }

    // If a modifier key is pressed which isn't destined for Lightspeed allow the event to behave as normal
    if (
      (metaKey || shiftKey || ctrlKey || altKey) &&
      !shouldSendModifierShortcut(event)
    ) {
      return;
    }

    if (event.defaultPrevented) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    window.parent.postMessage(
      {
        name: 'twa:keydown',
        key,
        repeat,
        ctrlKey,
        shiftKey,
        metaKey,
        altKey,
        keyCode,
        which,
      },
      frameOrigin,
    );
  }

  const unwatchLightspeed = watch(isInLightspeed, async (_isInLightspeed) => {
    if (_isInLightspeed) {
      document.addEventListener('click', handleBodyClick, true);
      window.addEventListener('keydown', handleKeydown, false);

      await essentialDataIsLoaded;
      modalObserver = new MutationObserver(() => {
        const modals = document.querySelectorAll(
          '.u-modal-container, div.lbox, .lbox-wrapper .overlay, .f-modal',
        );
        const nextModal = document.querySelectorAll('.w-next-modal-frame')[0];

        const hasActiveModals = Boolean(
          modals.length || nextModal?.style.display === 'block',
        );

        // Post modal state message
        window.parent.postMessage(
          { name: hasActiveModals ? 'twa:modal-open' : 'twa:modal-close' },
          frameOrigin,
        );
      });

      // Observe modal container for active modals
      const modalContainerEl = document.getElementById('ModalContainer');
      if (modalContainerEl) {
        modalObserver?.observe(modalContainerEl, {
          attributes: true,
          childList: true,
          subtree: true,
        });
      }

      // We only want to apply these handlers once
      unwatchLightspeed();
    }
  });

  function handleIncomingLightspeedMessage(event) {
    if (event.origin !== frameOrigin) {
      return;
    }

    // Activate lightspeed mode
    if (event.data.name === 'lightspeed:initialized') {
      isInLightspeed.value = true;
      tkoIsInLightspeed(true);
    }

    if (event.data.name === 'lightspeed:ui-state') {
      lightspeedUiState.value = event.data.state;
      tkoLightspeedUiState(event.data.state);
    }

    if (event.data.name === 'lightspeed:click') {
      document.body.dispatchEvent(new MouseEvent('click'));
    }

    if (event.data.name === 'lightspeed:keydown') {
      document.documentElement.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: event.data.key,
          keyCode: event.data.keyCode,
          repeat: event.data.repeat,
          ctrlKey: event.data.ctrlKey,
          shiftKey: event.data.shiftKey,
          metaKey: event.data.metaKey,
          bubbles: true,
        }),
      );
    }

    // Lightspeed is requesting to open a modal
    if (event.data.name === 'lightspeed:modal-open') {
      // Open TKO Modal
      window.app?.modal?.Show(event.data.modal.name, event.data.modal.params);
    }

    // Lightspeed is requesting to open a quick view
    if (event.data.name === 'lightspeed:quick-view-open') {
      // Open TKO quickview
      window.app?.quickView?.show(
        event.data.quickView.name,
        event.data.quickView.params,
        // onCloseCallback - allow tracking closure in LS
        () => {
          postMessageToLightspeed('twa:quick-view-close', {});
        },
      );
      // after opening QV, let LS know
      postMessageToLightspeed('twa:quick-view-open', {});
    }

    // Lightspeed is requesting to close a quick view
    if (event.data.name === 'lightspeed:quick-view-close') {
      // Close topmost TKO quick view
      window.app?.quickView?.pop();
      postMessageToLightspeed('twa:quick-view-close', {});
    }

    // Lightspeed is requesting to close all quick views
    if (event.data.name === 'lightspeed:quick-view-empty') {
      // Close all TKO quick views
      window.app?.quickView?.empty();
      postMessageToLightspeed('twa:quick-view-empty', {});
    }

    // Set body styles for lightspeed
    if (event.data.name === 'lightspeed:visibility-state') {
      lightspeedVisibilityState.value = event.data.mode;
      if (lightspeedVisibilityState.value === 'modal') {
        document.body.style.backgroundColor = 'transparent';
        document.body.style.overflow = 'hidden';
      }
      if (lightspeedVisibilityState.value === 'normal') {
        document.body.style.backgroundColor = '#fff';
        document.body.style.overflow = 'auto';
      }
    }

    if (event.data.name === 'lightspeed:update-editor-content') {
      // Subscribed for event in wysiwyg and easyMDE to replace or insert content
      window.app.ko.postbox.publish('update-editor-content', event.data.text);
    }
  }

  onMounted(() => {
    window.addEventListener('message', handleIncomingLightspeedMessage, false);

    // Let parent know we're ready.
    if (!window.devMode || window.parent.location !== window.location) {
      window.parent.postMessage({ name: 'twa:initialized' }, frameOrigin);
    }
  });
  onUnmounted(() => {
    window.removeEventListener(
      'message',
      handleIncomingLightspeedMessage,
      false,
    );
    modalObserver?.disconnect();

    document.removeEventListener('click', handleBodyClick, true);
    window.removeEventListener('keydown', handleKeydown, false);
  });

  provide(useLightspeedBridgeSymbol, {
    isInLightspeed,
    lightspeedVisibilityState,
    lightspeedUiState,
    postMessageToLightspeed,
  });
}
export function useLightspeedBridge() {
  return inject(useLightspeedBridgeSymbol);
}
