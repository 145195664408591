<template>
  <div class="task-section">
    <div :class="['task-section__header', { small }]" v-if="title">
      <div class="task-section__title-wrapper">
        <div class="task-section__title">
          {{ title }}
        </div>
        <div v-if="$slots.icon" class="task-section__icon">
          <slot name="icon" />
        </div>
      </div>
      <div class="task-section__accessory">
        <slot name="accessory" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SectionBlock',
  display: 'SectionBlock',
  props: {
    title: { type: String, required: false, default: null },
    small: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss" scoped>
.task-section {
  margin-bottom: 24px;

  &--small {
    max-width: 200px;
  }

  &__header {
    margin-bottom: 14px;
    display: flex;
    justify-content: space-between;

    &.small {
      margin-bottom: 5px;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    color: #0b0e1f;

    &--helper {
      color: #6d6f80;
      font-size: 12px;
    }
  }

  &__icon {
    margin-left: 8px;
  }

  &__accessory {
    button {
      border: none;
      padding: 0;
      background: transparent;
    }
  }
}
</style>
