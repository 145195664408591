<template>
  <div class="notifications">
    <div class="notifications__header" v-if="title || $slots.title">
      <span v-if="title" class="notifications__header-text">
        {{ title }}
      </span>
      <slot name="title" />
    </div>
    <slot>
      <BlankSlate
        v-if="!shouldStillShowIfMuted && areNotificationsMuted"
        :title="$t('Notifications muted')"
        :msg="$t('You have muted notifications')"
      >
        <template #image>
          <MutedEmptyStateImg class="blank-slate__image" />
        </template>
        <template #action>
          <button
            :data-identifier="`${dataIdentifierPrefix}-empty-state-mute`"
            type="button"
            class="btn btn-primary"
            @click="areNotificationsMuted = false"
          >
            {{ $t('Unmute') }}
          </button>
        </template>
      </BlankSlate>

      <template v-else>
        <div class="flex justify-between items-end mx-4">
          <FTabs
            :data-identifier-prefix="dataIdentifierPrefix"
            :tabs="Object.values(tabs)"
            v-model="activeTabCode"
            disable-integrated-border
          />
          <slot name="filter"></slot>
        </div>
        <FTabsBorder
          :border-color-class="
            darkenTabBorder
              ? `border-[color:var(--token-palette-color-neutral-30)]`
              : undefined
          "
          class="mx-4"
        />

        <NotificationsLoader
          v-if="loaders['unread']"
          type="unread"
          :loader="loaders['unread']"
          v-slot="{ notifications, allLoaded, isLoading }"
          :active="activeTabCode === 'unread' && active"
          v-show="activeTabCode === 'unread'"
        >
          <NotificationsList
            :all-loaded="allLoaded"
            :blank-state-message="unreadBlankStateMessage"
            :blank-state-title="unreadBlankStateTitle"
            :data-identifier-prefix="dataIdentifierPrefix"
            :disable-sub-element-click="disableSubElementClick"
            :is-loading="isLoading"
            :new-notification-ids="newEventIds"
            :notifications="notifications"
            :popover-id="popoverId"
            :transition-duration="transitionDuration"
            :transition="transitionName"
            @new-notification-observe="onNotificationObserved"
          >
            <template #blank-state-image>
              <BellEmptyStateImg
                v-if="showBellEmptyStateImg"
                class="blank-slate__image"
              />
              <InboxEmptyStateImg v-else class="blank-slate__image" />
            </template>
          </NotificationsList>
        </NotificationsLoader>
        <NotificationsLoader
          v-if="loaders['read']"
          type="read"
          :loader="loaders['read']"
          v-slot="{ notifications, allLoaded, isLoading }"
          :active="activeTabCode === 'read' && active"
          v-show="activeTabCode === 'read'"
        >
          <NotificationsList
            :all-loaded="allLoaded"
            :blank-state-message="readBlankStateMessage"
            :blank-state-title="readBlankStateTitle"
            :data-identifier-prefix="dataIdentifierPrefix"
            :disable-sub-element-click="disableSubElementClick"
            :is-loading="isLoading"
            :new-notification-ids="newEventIds"
            :notifications="notifications"
            :popover-id="popoverId"
            :transition-duration="transitionDuration"
            :transition="transitionName"
            @new-notification-observe="onNotificationObserved"
          >
            <template #blank-state-image>
              <ReadEmptyStateImg class="blank-slate__image" />
            </template>
          </NotificationsList>
        </NotificationsLoader>
        <NotificationsLoader
          v-if="loaders['all']"
          type="all"
          :loader="loaders['all']"
          v-slot="{ notifications, allLoaded, isLoading }"
          :active="activeTabCode === 'all' && active"
          v-show="activeTabCode === 'all'"
        >
          <NotificationsList
            :all-loaded="allLoaded"
            :blank-state-message="allBlankStateMessage"
            :blank-state-title="allBlankStateTitle"
            :data-identifier-prefix="dataIdentifierPrefix"
            :disable-sub-element-click="disableSubElementClick"
            :is-loading="isLoading"
            :new-notification-ids="newEventIds"
            :notifications="notifications"
            :popover-id="popoverId"
            :transition-duration="transitionDuration"
            :transition="transitionName"
            @new-notification-observe="onNotificationObserved"
          >
            <template #blank-state-image>
              <BellEmptyStateImg
                v-if="showBellEmptyStateImg"
                class="blank-slate__image"
              />
              <InboxEmptyStateImg v-else class="blank-slate__image" />
            </template>
          </NotificationsList>
        </NotificationsLoader>
      </template>
    </slot>
  </div>
</template>

<script>
import { computed, provide, shallowRef, Vue2 } from 'vue-demi';
import { omit } from 'lodash-es';

import NotificationsList from '@sections/NotificationsList';
import NotificationsLoader from '@sections/Notifications/NotificationsLoader';
import BlankSlate from '@widgets/BlankSlate';
import { FTabs, FTabsBorder } from '@fragments';
import { useNotificationsManager } from '@/components/sections/Notifications/useNotificationsManager';
import { useFeatures } from '@/platform/composables/useFeatures';

import InboxEmptyStateImg from '@/assets/images/blankslates/inbox.svg';
import BellEmptyStateImg from '@/assets/images/blankslates/bell.svg';
import MutedEmptyStateImg from '@/assets/images/blankslates/bell_muted.svg';
import ReadEmptyStateImg from '@/assets/images/blankslates/bell_read.svg';

const TABS = Object.freeze({
  unread: { value: 'unread', text: 'Unread' },
  read: { value: 'read', text: 'Read' },
  all: { value: 'all', text: 'All' },
});

// @vue/component
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    defaultTab: {
      type: String,
      default: 'unread',
      validator(value) {
        return ['unread', 'read', 'all'].includes(value);
      },
    },
    hideTabUnread: {
      type: Boolean,
      default: false,
    },
    hideTabRead: {
      type: Boolean,
      default: false,
    },
    hideTabAll: {
      type: Boolean,
      default: false,
    },
    hideMentionsToggle: {
      type: Boolean,
      default: false,
    },
    /*
     * Determines whether or not entire notification items should be clickable or
     * their sub-elements i.e. quick-view buttons and title links
     */
    disableSubElementClick: {
      type: Boolean,
      default: false,
    },
    notifiedOnly: {
      type: Boolean,
      default: false,
    },
    shouldStillShowIfMuted: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBellEmptyStateImg: {
      type: Boolean,
      required: false,
      default: false,
    },
    newEventIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    dataIdentifierPrefix: {
      type: [String, Number],
      required: false,
      default: '',
    },
    popoverId: {
      type: Number,
      required: false,
      default: undefined,
    },
    transition: {
      type: String,
      required: false,
      default: 'slide',
      validator(v) {
        return v && ['slide', 'shrink'].includes(v);
      },
    },
    transitionDuration: {
      type: Number,
      required: false,
      default: 360,
    },
    darkenTabBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * set to false to prevent fetching of notifications
     * for example can defer initial fetch of notfications until
     * bell popover is visible
     */
    active: {
      type: Boolean,
      default: true,
    },
    loaders: {
      type: Object,
      required: true,
    },
    unreadBlankStateTitle: {
      type: String,
      required: false,
      default: () => Vue2.t('Woohoo!'),
    },
    unreadBlankStateMessage: {
      type: String,
      required: false,
      default: () => Vue2.t("You're all up to date!"),
    },
    readBlankStateTitle: {
      type: String,
      required: false,
      default: () => Vue2.t('All quiet for now'),
    },
    readBlankStateMessage: {
      type: String,
      required: false,
      default: () => Vue2.t("You don't have any read notifications."),
    },
    allBlankStateTitle: {
      type: String,
      required: false,
      default: () => Vue2.t('All quiet for now'),
    },
    allBlankStateMessage: {
      type: String,
      required: false,
      default: () => Vue2.t('You have no notifications.'),
    },
  },
  setup(props, { emit }) {
    provide(
      'popoverId',
      computed(() => props.popoverId),
    );

    const NotificationsManager = useNotificationsManager();

    const activeTabCode = shallowRef(props.defaultTab);

    const { shouldReduceElasticsearchLoad } = useFeatures();

    const tabs = computed(() => {
      return omit(TABS, [
        ...(props.hideTabUnread ? ['unread'] : []),
        ...(props.hideTabRead || shouldReduceElasticsearchLoad.value
          ? ['read']
          : []),
        ...(props.hideTabAll || shouldReduceElasticsearchLoad.value
          ? ['all']
          : []),
      ]);
    });

    const activeTab = computed(() => {
      return tabs.value[activeTabCode.value];
    });

    const transitionName = computed(() => {
      if (props.transition === 'shrink') {
        return props.transition;
      }

      if (props.transition === 'slide') {
        if (activeTabCode.value === 'unread') {
          return 'slide-right';
        }

        return 'slide-left';
      }

      return null;
    });

    function onNotificationObserved(id) {
      emit('notification-observed', id);
    }

    return {
      activeTab,
      activeTabCode,
      transitionName,
      tabs,
      onNotificationObserved,
      areNotificationsMuted: NotificationsManager.areNotificationsMuted,
    };
  },
  components: {
    NotificationsList,
    NotificationsLoader,
    FTabs,
    FTabsBorder,
    BlankSlate,
    BellEmptyStateImg,
    InboxEmptyStateImg,
    MutedEmptyStateImg,
    ReadEmptyStateImg,
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/dimensions/_typography.dimensions';
@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

.notifications {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  font-size: 11px;
  user-select: none;
}

.notifications__header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: $margin--medium;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}

.notifications__header-text {
  font-weight: $font-weight--semibold;
  font-size: $font-size--h1;
  line-height: $line-height--small;
  color: #0b0e1f; // Use 'Neutral 60' design token when available
  user-select: none;
}

.notifications__header-indicator {
  margin: 0 $margin--medium;
  flex: 0 0 auto;
}

.u-blank-slate {
  margin: 0;
  height: 100%;

  .blank-slate__image {
    max-height: 96px;
  }

  .mute-icon {
    width: 1.3rem;
    height: 1.3rem;
    max-width: 1.3rem;
  }
}
</style>
