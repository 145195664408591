<template>
  <ul class="list-none p-0 m-0">
    <li
      v-for="project in projects"
      :key="project.id"
      class="mb-150 recent-project-list-item relative"
    >
      <button
        @click="toggleProjectStarred(project)"
        class="
          border-none
          bg-transparent
          group
          absolute
          flex
          items-center
          justify-center
          w-[45px]
          h-[45px]
        "
      >
        <svg
          v-if="project.starred || project.isStarred"
          class="fill-[#FFC63C] group-hover:fill-[#edab10]"
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4796 6.01103L11.9282 5.34748L9.89392 1.22288C9.52943 0.487679 8.47336 0.478333 8.10576 1.22288L6.07149 5.34748L1.5201 6.01103C0.703902 6.12941 0.3768 7.13564 0.968699 7.71197L4.26153 10.9207L3.48271 15.4534C3.34253 16.2727 4.20545 16.8864 4.92819 16.5032L8.99984 14.363L13.0715 16.5032C13.7942 16.8833 14.6572 16.2727 14.517 15.4534L13.7381 10.9207L17.031 7.71197C17.6229 7.13564 17.2958 6.12941 16.4796 6.01103Z"
          />
        </svg>

        <svg
          v-else
          width="18"
          height="17"
          class="fill-[#8F9BB3] group-hover:fill-[#0B0E1F]"
          viewBox="0 0 18 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4797 6.01103L11.9283 5.34748L9.89404 1.22288C9.52956 0.487679 8.47348 0.478333 8.10588 1.22288L6.07162 5.34748L1.52022 6.01103C0.704024 6.12941 0.376922 7.13564 0.968821 7.71197L4.26165 10.9207L3.48284 15.4534C3.34265 16.2727 4.20558 16.8864 4.92832 16.5032L8.99996 14.363L13.0716 16.5032C13.7943 16.8833 14.6573 16.2727 14.5171 15.4534L13.7383 10.9207L17.0311 7.71197C17.623 7.13564 17.2959 6.12941 16.4797 6.01103ZM12.6697 10.5718L13.5358 15.6185L8.99996 13.2384L4.46726 15.6216L5.3333 10.5749L1.66352 6.99546L6.73205 6.26026L8.99996 1.66525L11.2679 6.26026L16.3364 6.99546L12.6697 10.5718Z"
          />
        </svg>
      </button>
      <router-link
        :to="`/projects/${project.id}`"
        @click.native="tryhideProjectsPanel"
        class="
          flex
          items-center
          h-[45px]
          pr-300
          pl-[45px]
          transition-colors
          duration-100
          bg-transparent
          hover:bg-palette-neutral-20/80
          rounded-full
          text-text text-[14px]
          hover:text-text
          leading-tight
        "
        :class="{
          'bg-[#fdfefe]': projectId === project.id,
        }"
      >
        <span class="flex-auto">
          <span class="block w-full truncate" :title="project.name">
            {{ project.name }}
          </span>
          <small
            class="text-[#8F9BB3] block w-full text-[13px] truncate"
            :title="project.company.name"
          >
            {{ project.company.name }}
          </small>
        </span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { computed } from 'vue-demi';
import { useProjectActions } from '@teamwork/use';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';
import { useCurrentProject } from '@/platform/composables/useCurrentProject';

export default {
  name: 'ProjectsPanelProjectList',
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { starProject, unstarProject } = useProjectActions();
    const { tryhideProjectsPanel } = useSidebarPanels();
    const currentProject = useCurrentProject();
    const projectId = computed(() => currentProject.value?.id || 0);

    const toggleProjectStarred = (project) => {
      if (project.starred || project.isStarred) {
        unstarProject(project);

        return;
      }

      starProject(project);
    };

    return {
      projectId,
      tryhideProjectsPanel,
      toggleProjectStarred,
    };
  },
};
</script>
<style lang="scss" scoped>
.recent-project-list-item {
  content-visibility: auto;
}
</style>
