<template>
  <TaskItemContainer class="dependencies-item">
    <template #content>
      <div class="ml-6">
        <TaskToggleStatus v-if="task" :task="task" size="small" />
        <Skeleton v-else class="mr-2" width="20" height="20" radius="50px" />
      </div>
      <div class="min-w-[110px]">
        <DependencyStatusPicker
          :disabled="false"
          :open.sync="isDependencyStatusOpen"
          @change-status="onChangeStatus"
          :type="predecessor.type === 'complete' ? 'waiting' : 'blocked'"
        >
          <FButton variant="tertiary" size="sm">
            <div class="flex items-center gap-1">
              <CommonIcon
                id="status-blocked"
                :color="predecessor.type === 'complete' ? '#ffb300' : '#E12D42'"
              />
              <span class="whitespace-nowrap">
                {{
                  predecessor.type === 'complete'
                    ? $t('Waiting on')
                    : $t('Blocked by')
                }}
              </span>

              <CommonIcon
                :id="isDependencyStatusOpen ? 'chevron-up' : 'chevron-down'"
                class="text-[8px]"
              />
            </div>
          </FButton>
        </DependencyStatusPicker>
      </div>

      <TaskItemName
        class="ml-1"
        @click="selectDependency"
        :value="predecessorName"
      />
    </template>
    <template #actions>
      <div v-if="!task || !task.id" style="display: flex">
        <Skeleton
          style="margin: 0 16px"
          width="100"
          height="28"
          radius="50px"
        />
        <Skeleton
          style="margin-right: 16px"
          width="28"
          height="28"
          radius="50%"
        />
      </div>
      <DependenciesItemTask v-else :task="task" />
      <DependencyOptionsMenu
        :open.sync="isOptionsMenuOpen"
        @remove-dependency="removePredecessor"
      >
        <TaskItemOptions
          :tooltip="isOptionsMenuOpen ? $t('Dependnecy Option') : ''"
        />
      </DependencyOptionsMenu>
    </template>
  </TaskItemContainer>
</template>
<script>
import DependencyStatusPicker from '@widgets/DependencyStatusPicker/DependencyStatusPicker';
import { computed } from 'vue-demi';
import { FButton } from '@fragments';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import TaskToggleStatus from '@/components/widgets/TaskToggleStatus';
import TaskItemContainer from '../TaskItemContainer';
import TaskItemOptions from '../TaskItemOptions';
import TaskItemName from '../TaskItemName';
import DependencyOptionsMenu from './DependencyOptionsMenu';
import DependenciesItemTask from './DependenciesItemTask';
import Skeleton from '@/components/widgets/Skeleton';
import { useQuickViews } from '@/platform/composables/useQuickViews';

export default {
  name: 'DependenciesItem',
  data() {
    return {
      openDependencyStatusId: 0,
      isDependencyStatusOpen: false,
      isOptionsMenuOpen: false,
    };
  },
  props: {
    predecessor: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    selectedTask: {
      type: Object,
      required: true,
    },
    initialItem: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DependencyStatusPicker,
    TaskToggleStatus,
    CommonIcon,
    FButton,
    TaskItemContainer,
    TaskItemOptions,
    TaskItemName,
    Skeleton,
    DependenciesItemTask,
    DependencyOptionsMenu,
  },
  setup(props, { emit }) {
    const { replaceQuickView } = useQuickViews();
    function removePredecessor() {
      emit('remove-predecessor', props.predecessor);
    }

    function onChangeStatus(status) {
      emit('edit-predecessor', { ...props.predecessor, type: status });
    }

    function selectDependency() {
      const dependenciesCrumbs = [
        ...(this.initialItem?.dependenciesCrumbs?.length
          ? this.initialItem.dependenciesCrumbs
          : []),
        {
          id: this.selectedTask.id,
          text: this.selectedTask.name,
        },
      ];

      if (props.task?.id) {
        replaceQuickView({
          type: 'task',
          id: props.task.id,
          initialItem: {
            ...props.task,
            dependenciesCrumbs,
          },
        });
      }
    }

    const predecessorName = computed(() => props.predecessor.name || '');

    return {
      removePredecessor,
      onChangeStatus,
      selectDependency,
      predecessorName,
    };
  },
};
</script>
<style lang="scss" scoped>
.dependencies-item {
  &__status {
    margin-right: 8px;
    margin-left: 24px;
  }
}
</style>
