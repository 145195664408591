import Vue from 'vue';
import moment from 'moment';
import {
  formatFieldsForSubmit,
  validateFields,
} from '@/utils/helpers/customFields';

const initialState = () => ({
  step: {},
  selectedProjectOption: 0,
  id: 0,
  name: '',
  isTemplate: false,
  company: null,
  categoryId: 0,
  tags: [],
  description: '',
  projectChatChannelEnabled: false,
  people: [],
  projectOwnerId: 0,
  privacyIds: [],
  forceValidation: false,
  items: [],
  selectedItems: [],
  makeMeCreator: true,
  uncompleteItems: false,
  saveAsTemplate: true,
  shouldRenderPeoplePicker: false,
  customFields: [],
  hasInvalidFields: false,
  isTemplateApplied: false,
  appliedTemplateId: null,
  startDate: null,
  endDate: null,
  templateDateTarget: 'start', // This is whether dates for a project created by a template are targeting the start or end date of the project
  keepOffWeekends: 0,
  templateTargetDate: moment(),
  activePages: {
    links: false,
    tasks: false,
    time: false,
    finance: false,
    notebooks: false,
    files: false,
    forms: false,
    comments: false,
    messages: false,
    milestones: false,
    riskRegister: false,
    list: false,
    board: false,
    table: false,
    gantt: false,
  },
  isBillable: true,
  isTeamworkTemplate: false,
  budgetType: null,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    activePages(state, activePages) {
      state.activePages = activePages;
    },
    step(state, step) {
      state.step = step;
    },
    id(state, id) {
      state.id = id;
    },
    name(state, name) {
      state.name = name;
    },
    isTemplate(state, isTemplate) {
      state.isTemplate = isTemplate;
    },
    categoryId(state, categoryId) {
      state.categoryId = categoryId;
    },
    tags(state, tags) {
      state.tags = tags;
    },
    company(state, company) {
      state.company = company;
    },
    description(state, description) {
      state.description = description;
    },
    makeMeCreator(state, makeMeCreator) {
      state.makeMeCreator = makeMeCreator;
    },
    uncompleteItems(state, uncompleteItems) {
      state.uncompleteItems = uncompleteItems;
    },
    items(state, items) {
      state.items = items;
    },
    selectedItems(state, selectedItems) {
      state.selectedItems = selectedItems;
    },
    saveAsTemplate(state, saveAsTemplate) {
      state.saveAsTemplate = saveAsTemplate;
    },
    projectChatChannelEnabled(state, projectChatChannelEnabled) {
      state.projectChatChannelEnabled = projectChatChannelEnabled;
    },
    people(state, people) {
      state.people = people;
    },
    projectOwnerId(state, projectOwnerId) {
      state.projectOwnerId = projectOwnerId;
    },
    privacyIds(state, privacyIds) {
      state.privacyIds = privacyIds;
    },
    forceValidation(state, forceValidation) {
      state.forceValidation = forceValidation;
    },
    shouldRenderPeoplePicker(state, shouldRenderPeoplePicker) {
      state.shouldRenderPeoplePicker = shouldRenderPeoplePicker;
    },
    customFields(state, fields) {
      state.customFields = fields;
    },
    isTemplateApplied(state, fields) {
      state.isTemplateApplied = fields;
    },
    appliedTemplateId(state, fields) {
      state.appliedTemplateId = fields;
    },
    templateDateTarget(state, templateDateTarget) {
      state.templateDateTarget = templateDateTarget;
    },
    keepOffWeekends(state, keepOffWeekends) {
      state.keepOffWeekends = keepOffWeekends;
    },
    templateTargetDate(state, templateTargetDate) {
      state.templateTargetDate = templateTargetDate;
    },
    startDate(state, startDate) {
      state.startDate = startDate;
    },
    endDate(state, endDate) {
      state.endDate = endDate;
    },
    addCustomField(state, field) {
      state.customFields.push(field);
    },
    updateField(state, field) {
      const index = state.customFields.findIndex(
        (f) => f.customfieldId === field.id,
      );
      if (index === -1) {
        return;
      }
      const newField = { ...state.customFields[index], ...field.data };
      Vue.set(state.customFields, index, newField);
    },
    updateValidCustomFields(state, hasInvalidFields) {
      state.hasInvalidFields = hasInvalidFields;
    },
    selectedProjectOption(state, option) {
      state.selectedProjectOption = option;
    },
    init(state) {
      const s = initialState();
      Object.assign(state, s);
    },
    isBillable(state, isBillable) {
      state.isBillable = isBillable;
    },
    isTeamworkTemplate(state, isTeamworkTemplate) {
      state.isTeamworkTemplate = isTeamworkTemplate;
    },
    budgetType(state, budgetType) {
      state.budgetType = budgetType;
    },
  },
  actions: {
    setCustomFields(context, fields) {
      context.commit('customFields', fields);
    },
    addCustomField(context, data) {
      context.commit('addCustomField', data);
    },
    updateCustomField(context, data) {
      context.commit('updateField', data);
    },
    updateValidCustomFields(context, data) {
      context.commit('updateValidCustomFields', data);
    },
    init({ commit }) {
      commit('init');
    },
    id({ commit }, id) {
      commit('id', id);
    },
    description({ commit }, description) {
      commit('description', description);
    },
    customFields({ commit }, customFields) {
      commit('customFields', customFields);
    },
    isTemplate({ commit }, isTemplate) {
      commit('isTemplate', isTemplate);
    },
    company({ commit }, company) {
      commit('company', company);
    },
    categoryId({ commit }, categoryId) {
      commit('categoryId', categoryId);
    },
    shouldRenderPeoplePicker({ commit }, shouldRenderPeoplePicker) {
      commit('shouldRenderPeoplePicker', shouldRenderPeoplePicker);
    },
    tags({ commit }, tags) {
      commit('tags', tags);
    },
    projectOwnerId({ commit }, projectOwnerId) {
      commit('projectOwnerId', projectOwnerId);
    },
    step({ commit }, step) {
      commit('step', step);
    },
    people({ commit }, people) {
      commit('people', people);
    },
    isTemplateApplied({ commit }, isTemplateApplied) {
      commit('isTemplateApplied', isTemplateApplied);
    },
    appliedTemplateId({ commit }, appliedTemplateId) {
      commit('appliedTemplateId', appliedTemplateId);
    },
    templateDateTarget({ commit }, templateDateTarget) {
      commit('templateDateTarget', templateDateTarget);
    },
    forceValidation({ commit }, forceValidation) {
      commit('forceValidation', forceValidation);
    },
    privacyIds({ commit }, privacyIds) {
      commit('privacyIds', privacyIds);
    },
    name({ commit }, name) {
      commit('name', name);
    },
    startDate({ commit }, startDate) {
      commit('startDate', startDate);
    },
    endDate({ commit }, endDate) {
      commit('endDate', endDate);
    },
    templateTargetDate({ commit }, templateTargetDate) {
      commit('templateTargetDate', templateTargetDate);
    },
    keepOffWeekends({ commit }, keepOffWeekends) {
      commit('keepOffWeekends', keepOffWeekends);
    },
    selectedItems({ commit }, selectedItems) {
      commit('selectedItems', selectedItems);
    },
    makeMeCreator({ commit }, makeMeCreator) {
      commit('makeMeCreator', makeMeCreator);
    },
    activePages({ commit }, activePages) {
      commit('activePages', activePages);
    },
    selectedProjectOption({ commit }, option) {
      commit('selectedProjectOption', option);
    },
    setIsBillable({ commit }, value) {
      commit('isBillable', value);
    },
    setIsTeamworkTemplate({ commit }, value) {
      commit('isTeamworkTemplate', value);
    },
    setBudgetType({ commit }, value) {
      commit('budgetType', value);
    },
  },
  getters: {
    selectedPeopleIds(state) {
      return (state.people || []).map((x) => x.id);
    },
    payload: (state, getters, _rootState, rootGetters) => {
      const features = rootGetters['account/features'];
      const isTemplate = state.isTemplate;
      let grantAccessTo = state.privacyIds;
      if (grantAccessTo.length === 0) {
        grantAccessTo = [0];
      } else if (
        !features.includes('templateprivacy') &&
        isTemplate &&
        grantAccessTo[0] !== 0
      ) {
        grantAccessTo = [-1];
      }

      let company = state.company;
      if (Array.isArray(company)) {
        company = state.company[0];
      }

      const data = {
        description: state.description,
        companyId: company ? company.id : 0,
        name: state.name,
        // chatChannelEnabled: state.projectChatChannelEnabled ? 1 : 0,
        tagIds:
          (state.tags || []).length > 0
            ? state.tags.map((x) => x.id).join(',')
            : '',
        'grant-access-to': grantAccessTo.join(','),
        private: false,
        'category-id': state.categoryId,
        people: getters.selectedPeopleIds.join(','),
        projectOwnerId: state.projectOwnerId,
        customFields: formatFieldsForSubmit(state.customFields),
        isBillable: state.isBillable,
      };
      if (grantAccessTo[0] > 0) {
        data.private = true;
      }
      if (
        state.company &&
        state.company.id === 0 &&
        state.company.name.length > 0
      ) {
        data.newCompany = state.company.name;
      }
      return data;
    },
    templatePayload: (state, getters, { account }) => {
      const select = state.selectedItems;
      let company = state.company;
      if (Array.isArray(company)) {
        company = state.company[0];
      }

      return {
        newFromTemplate: !state.isTemplate,
        targetDate: state.templateTargetDate.format('YYYYMMDD'),
        templateDateTarget: state.templateDateTarget,
        id: state.id,
        installationId: account.id,
        sourceInstallationId: account.id,
        'cloneproject-action': 'copy',
        cloneProjectName: state.name,
        description: state.description,
        copyTasks: select.includes('tasks') ? 'YES' : 'NO',
        copyTasklists: select.includes('tasks') ? 'YES' : 'NO',
        copyMilestones: select.includes('milestones') ? 'YES' : 'NO',
        copyMessages: select.includes('messages') ? 'YES' : 'NO',
        copyFiles: select.includes('files') ? 'YES' : 'NO',
        copyLinks: select.includes('links') ? 'YES' : 'NO',
        copyNotebooks: select.includes('notebooks') ? 'YES' : 'NO',
        copyTimelogs: select.includes('timelogs') ? 'YES' : 'NO',
        copyInvoices: select.includes('invoices') ? 'YES' : 'NO',
        copyExpenses: select.includes('expenses') ? 'YES' : 'NO',
        copyRisks: select.includes('risks') ? 'YES' : 'NO',
        copyPeople: select.includes('people') ? 'YES' : 'NO',
        copyForms: select.includes('forms') ? 'YES' : 'NO',
        copyAutomations: select.includes('automations') ? 'YES' : 'NO',
        copyComments: select.includes('comments') ? 'YES' : 'NO',
        copyFollowers: select.includes('followers') ? 'YES' : 'NO',
        copyProjectRoles: 'YES',
        copyProjectUpdates: 'NO',
        copyProjectPrivacy: 'YES',
        copyLogo: 'YES',
        copyBudgets: 'NO',
        daysOffset: 0,
        keepOffWeekends: state.keepOffWeekends,
        createActivityLog: 'YES',
        createItemsUsingCurrentUser: state.makeMeCreator ? 'YES' : 'NO',
        uncomplete: 'YES',
        companyId: company ? company.id : 0,
        newCompanyName: company ? company.name : '',
        tagIds:
          (state.tags || []).length > 0
            ? state.tags.map((x) => x.id).join(',')
            : '',
        customFields: JSON.stringify(formatFieldsForSubmit(state.customFields)),
        'use-tasks': state.activePages.tasks,
        'use-milestones': state.activePages.milestones,
        'use-messages': state.activePages.messages,
        'use-files': state.activePages.files,
        'use-time': state.activePages.time,
        'use-notebooks': state.activePages.notebooks,
        'use-riskregister': state.activePages.riskRegister,
        'use-links': state.activePages.links,
        'use-finance': state.activePages.finance,
        'use-comments': state.activePages.comments,
        'use-forms': state.activePages.forms,
        'category-id': state.categoryId,
        peopleIds: getters.selectedPeopleIds.join(','),
        projectOwnerId: state.projectOwnerId,
        toTemplate: !!(state.isTemplate && state.isTemplateApplied),
        isBillable: state.isBillable,
      };
    },
    teamworkTemplatePayload: (state, getters, { account }) => {
      let company = state.company;
      if (Array.isArray(company)) {
        company = state.company[0];
      }

      return {
        newFromTemplate: !state.isTemplate,
        targetDate: state.templateTargetDate.format('YYYYMMDD'),
        templateDateTarget: state.templateDateTarget,
        templateId: state.appliedTemplateId,
        installationId: account.id,
        sourceInstallationId: account.id,
        'cloneproject-action': 'copy',
        cloneProjectName: state.name,
        description: state.description,
        daysOffset: 0,
        keepOffWeekends: state.keepOffWeekends,
        createActivityLog: 'YES',
        createItemsUsingCurrentUser: state.makeMeCreator ? 'YES' : 'NO',
        uncomplete: 'NO',
        companyId: company ? company.id : 0,
        newCompanyName: company ? company.name : '',
        tagIds:
          (state.tags || []).length > 0
            ? state.tags.map((x) => x.id).join(',')
            : '',
        customFields: formatFieldsForSubmit(state.customFields),
        peopleIds: getters.selectedPeopleIds.join(','),
        projectOwnerId: state.projectOwnerId,
        toTemplate: !!(state.isTemplate && state.isTemplateApplied),
        isBillable: state.isBillable,
      };
    },
    hasInvalidCustomFields: (state) =>
      state.customFields.some((field) => !field.isValid),
    customFieldsValidationErrors: (state) => validateFields(state.customFields),
  },
};
