import '@/styles/index.scss';
import '@/utils/helpers/fixPathname';
import '@/utils/helpers/public-path';
import '@/platform/styles/styles.css';
import { createApp, install, Vue2 as Vue } from 'vue-demi';
import PortalVue from 'portal-vue';
import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';
import VScrollLock from 'v-scroll-lock';
import VClickOutside from 'v-click-outside';
import VueTheMask from 'vue-the-mask';
import VueI18Next from '@/utils/plugins/i18n';
import vTooltipConfig from '@/utils/plugins/config/v-tooltip';
import Can from '@/services/can';
import CanRules from '@/utils/rules';
import directives from '@/utils/directives';
import filters from '@/utils/filters';
import router from '@/router';
import store from '@/store';
import Root from '@/components/Root';

Vue.config.performance = window.devMode;
Vue.config.productionTip = false;

install(Vue);

Vue.use(PortalVue);
Vue.use(Vuelidate);
Vue.use(VTooltip, vTooltipConfig);
Vue.use(VScrollLock);
Vue.use(VClickOutside);
Vue.use(VueTheMask);
Vue.use(VueI18Next);
Vue.use(Can.plugin, CanRules);
Can.registerStore(store);
router.registerStore(store);

Object.entries(directives).forEach(([name, directive]) => {
  Vue.directive(name, directive);
});

Object.entries(filters).forEach(([name, filter]) => {
  Vue.filter(name, filter);
});

createApp(Root).mount('#teamwork-web-app');
