<template>
  <component
    v-bind="$attrs"
    :is="$attrs.href ? 'a' : 'button'"
    :title="title"
    class="
      flex
      items-center
      transition-colors
      duration-100
      bg-transparent
      hover:bg-palette-neutral-20/80
      rounded-full
      hover:text-text
      text-text text-[14px]
      font-normal
      appearance-none
      border-none
      p-2
      gap-4
      group
      justify-around
      text-left
      w-full
    "
    v-on="{ ...$listeners }"
  >
    <span
      :class="`flex-none flex items-center justify-center rounded-full ${iconCss} ${
        iconSize === 'sm' ? 'w-12 h-12' : 'w-14 h-14'
      }`"
    >
      <slot name="icon" />
    </span>
    <span class="flex-auto">
      <span class="title block w-full truncate">
        {{ $t(title) }}
      </span>
      <span class="description">
        {{ $t(description) }}
      </span>
    </span>
    <span
      v-if="!hideRightCaret"
      class="
        flex
        items-center
        w-6
        shrink-0
        opacity-0
        group-hover:opacity-100
        group-focus:opacity-100
        focus-within:opacity-100
      "
    >
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
          fill="#646D7D"
        />
      </svg>
    </span>
  </component>
</template>
<script>
export default {
  name: 'IconListItem',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'md',
      validator: (value) => ['sm', 'md'].includes(value.toLowerCase()),
    },
    iconCss: {
      type: String,
      required: true,
    },
    hideRightCaret: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0b0e1f;
}
.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #646d7d;
}
</style>
