import { render, staticRenderFns } from "./FileDragArea.vue?vue&type=template&id=49d4c386&scoped=true&"
import script from "./FileDragArea.vue?vue&type=script&lang=js&"
export * from "./FileDragArea.vue?vue&type=script&lang=js&"
import style0 from "./FileDragArea.vue?vue&type=style&index=0&id=49d4c386&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d4c386",
  null
  
)

export default component.exports