<template>
  <div
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="table-cell-progress"
    :class="{ 'TasksTableTask__cell--disabled': !canEditProgress }"
  >
    <v-popover
      trigger="click"
      placement="bottom"
      offset="4"
      :open.sync="isOpen"
      class="progress-popover"
      popover-arrow-class="hidden"
      :popper-options="popperOptions"
      :disabled="!canEditProgress"
    >
      <ProgressTableCell
        :value="Number(progress)"
        @click="openPopover"
        :clickable="true"
        :enabled="canEditProgress"
      >
        {{ progress }}%
      </ProgressTableCell>
      <template slot="popover">
        <div
          @mouseover="isOpen = true"
          @mouseleave="isOpen = false"
          class="progress-popover"
        >
          <p
            :class="[
              {
                'has-value': inputProgress > 0,
              },
              'progress-popover__text',
            ]"
          >
            {{ $t('Set progress:') }}
            <span>{{ inputProgress }}%</span>
          </p>
          <div class="slider">
            <span
              :style="{
                width: `${
                  maxRange === 100 ? inputProgress : Number(inputProgress) + 10
                }%`,
              }"
              class="slider__progress"
            />
            <input
              type="range"
              class="slider__input"
              step="10"
              :max="maxRange"
              :disabled="!canEditProgress"
              @change="$emit('change', Number(inputProgress))"
              v-model="inputProgress"
            />
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProgressTableCell from '@widgets/Reports/ProgressTableCell';

export default {
  name: 'Progress',
  components: {
    ProgressTableCell,
  },
  data() {
    return {
      isHovering: false,
      isOpen: false,
      inputProgress: 0,
    };
  },
  props: {
    task: {
      type: Object,
      default: null,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      userId: ({ user }) => user.id,
      userType: (state) => state.user.userType,
    }),
    isCollaborator() {
      return this.userType === 'collaborator';
    },
    isAssignedToMe() {
      if (this.task.assignees) {
        return this.task.assignees.some(({ id }) => id === this.userId);
      }
      return false;
    },
    maxRange() {
      return this.numPredecessors > 0 || this.numActiveSubTasks > 0 ? 90 : 100;
    },
    canEditProgress() {
      if (this.status === 'completed') {
        return false;
      }
      // this is different than task.userPermissions.canEdit
      // https://support.teamwork.com/projects/using-teamwork/working-with-collaborators
      if (this.isCollaborator && !this.isAssignedToMe) {
        return false;
      }
      return true;
    },
    popperOptions: () => ({
      modifiers: {
        hide: { enabled: true },
      },
    }),
    numPredecessors() {
      return this.task.predecessorIds ? this.task.predecessorIds.length : 0;
    },
    numActiveSubTasks() {
      return this.task.subTaskIds ? this.task.subTaskIds.length : 0;
    },
    status() {
      return this.task.status;
    },
  },
  methods: {
    openPopover() {
      this.isOpen = true;
      this.inputProgress = this.progress;
    },
  },
  watch: {
    status(newValue) {
      if (newValue === 'completed') {
        this.inputProgress = 100;
      } else {
        this.inputProgress = 0;
      }
    },
  },
  mounted() {
    this.inputProgress = this.progress;
  },
};
</script>

<style lang="scss">
@import '~@tko/src/styles/variables/variables';

$font-size--base: 14px;

.table-cell-progress {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  &__empty-state {
    font-size: $font-size--base;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}

.progress-popover {
  width: 100%;
  padding: 16px;

  .trigger {
    height: 35px;
    width: 100%;
  }

  .progressTableCell {
    margin: 0;
    height: 100%;
  }

  &__text {
    font-size: $font-size--base;
    margin-bottom: 18px;

    &.has-value {
      span {
        color: $indigo-30;
        font-weight: 600;
      }
    }
  }

  .slider {
    position: relative;
    width: 180px;
    height: 8px;
    background: rgba(68, 97, 215, 0.12);
    outline: none;
    border-radius: 50px;
    z-index: 2;

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 50px;
      background: $indigo-30;
      z-index: -1;
    }

    &__input {
      -webkit-appearance: none;
      height: 8px;
      background: transparent;
    }

    &__input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $indigo-30;
      cursor: pointer;
    }

    &__input:disabled::-webkit-slider-thumb {
      cursor: not-allowed;
    }

    &__input::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $indigo-30;
      cursor: pointer;
    }

    &__input:disabled::-moz-range-thumb {
      cursor: not-allowed;
    }

    &__input::-moz-range-progress {
      background: $indigo-30;
    }
  }
}
</style>
