<template>
  <PopoverMenuContainer class="w-[300px]">
    <ul class="list-none px-0 py-2">
      <template v-if="accounts && accounts.length > 0">
        <PopoverMenuActionItem
          v-for="account in accounts"
          :key="account.name"
          @click="linkedSite($event, account)"
        >
          <template #icon>
            <img :src="account.favIcon" width="20px" height="20px" />
          </template>
          <template #title>
            <span :title="`${account.name} (${account.email})`">
              {{ account.name }} ({{ account.email }})
            </span>
          </template>
          <template #subtitle>
            <span :title="account.link">{{ account.link }}</span>
          </template>
        </PopoverMenuActionItem>
        <li>
          <hr class="mx-200 bg-palette-neutral-20" />
        </li>
      </template>
      <PopoverMenuActionItem @click="showLinkedAccounts" v-close-popover>
        <template #title>
          {{ $t('Link [0] Accounts', 'Teamwork') }}
        </template>
      </PopoverMenuActionItem>
    </ul>
  </PopoverMenuContainer>
</template>

<script>
import { computed } from 'vue-demi';
import { useCurrentUser } from '@teamwork/use';
import router from '@/router/index';
import useStore from '@/platform/composables/useStore';
import PopoverMenuContainer from './PopoverMenuContainer.vue';
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';

export default {
  components: {
    PopoverMenuContainer,
    PopoverMenuActionItem,
  },
  setup() {
    const store = useStore();
    const user = useCurrentUser();

    const accounts = computed(() => store.state.user.accounts);

    const showLinkedAccounts = () => {
      store.dispatch('modals/tko/open', {
        id: 'linkedAccounts',
        args: { userId: user.id },
      });
    };

    const linkedSite = ({ metaKey, ctrlKey }, { id, userId }) => {
      // event, account
      store
        .dispatch('account/accessLinkedAccount', { id, userId })
        .then((url) => {
          if (url) {
            // Intentionally not using Vue's router here
            // External URLs otherwise wouldn't work with it
            if (metaKey || ctrlKey) {
              router.newTab(url);
            } else {
              window.location.href = url;
            }
          }
        });
    };

    return {
      accounts,
      showLinkedAccounts,
      linkedSite,
    };
  },
};
</script>
