<template>
  <div class="template-offset-day-picker">
    <div class="template-offset-day-picker__header">
      <input
        ref="searchInput"
        v-model="searchTerm"
        type="text"
        class="template-offset-day-picker__input"
      />
    </div>
    <ul class="template-offset-day-picker__list">
      <template v-if="offsetDayOptions.length > 0">
        <li
          v-for="(day, index) in offsetDayOptions"
          :key="day.id"
          :tabindex="index"
          :value="day.id"
          class="template-offset-day-picker__option"
          :class="[{ 'is-selected': day.id === selectedOffset }]"
          @click="selectDate(day.id)"
        >
          {{ day.text }}
        </li>
      </template>
      <li v-else class="template-offset-day-picker__option is-disabled">
        {{ $t('No results found') }}
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, onMounted, ref, shallowRef, Vue2 as Vue } from 'vue-demi';
// import moment from 'moment';
import {
  getDaysFromEpoch,
  getOffsetDate,
  createOffsetDayOptions,
} from '@/utils/helpers/date';

export default {
  name: 'ProjectTemplateOffsetDayOptions',
  display: 'ProjectTemplateOffsetDayOptions',

  props: {
    date: { type: Object, required: false, default: null },
  },

  setup(props, { emit }) {
    const searchInput = ref(null);
    const searchTerm = shallowRef('');

    const selectedOffset = computed(() =>
      props.date && props.date.isValid()
        ? Number(getDaysFromEpoch(props.date))
        : -1,
    );

    const dayOptions = [{ id: -1, text: Vue.t('No Date') }].concat(
      createOffsetDayOptions(),
    );

    const offsetDayOptions = computed(() =>
      searchTerm.value.length > 0
        ? dayOptions.filter(
            (option) =>
              option.text
                .toLowerCase()
                .indexOf(searchTerm.value.toLowerCase()) > -1,
          )
        : dayOptions,
    );

    onMounted(() => {
      setTimeout(() => {
        searchInput.value.focus();
      }, 100);
    });

    function selectDate(offset) {
      const date = offset === -1 ? null : getOffsetDate(offset);
      emit('change', date);
    }

    return {
      searchInput,
      searchTerm,
      selectedOffset,
      offsetDayOptions,
      selectDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.template-offset-day-picker {
  &__header {
    padding: 4px;
  }

  &__input {
    border: 1px solid #e1e1e1;
    padding: 4px;
  }

  &__list {
    max-height: 200px;
    overflow: scroll;
    padding: 0;
  }

  &__option {
    list-style: none;
    padding: 6px 10px;
    color: #555;

    &:hover:not(.is-disabled) {
      cursor: pointer;
      color: white;
      background-color: #5897fb;
    }

    &.is-selected {
      background-color: #e1e1e1;
    }
  }
}
</style>
