<template>
  <div>
    <div v-for="predecessor in task.predecessorTasks" :key="predecessor.id">
      <DependenciesItem
        :predecessor="predecessor"
        :task="getPredecessorTask(predecessor.id)"
        :selected-task="task"
        :initial-item="initialItem"
        @remove-predecessor="removePredecessor"
        @edit-predecessor="editPredecessor"
      />
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from 'vue-demi';
import { useTasksLoader } from '@teamwork/use';
import DependenciesItem from './DependenciesItem';

export default {
  name: 'DependenciesItems',
  props: {
    task: {
      type: Object,
      default: () => null,
    },
    initialItem: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DependenciesItem,
  },
  setup(props) {
    const count = computed(() =>
      !props.task.predecessorIds?.length ? -1 : Infinity,
    );

    const predecessorIds = computed(() => {
      return props.task.predecessorIds?.length
        ? props.task.predecessorIds.join(',')
        : '';
    });

    const params = ref({
      ids: '',
      include: 'users,teams,lockdowns,predecessors',
      includeCompletedTasks: false,
    });
    watch(
      predecessorIds,
      (newIds, oldIds) => {
        if (newIds !== oldIds) {
          params.value.ids = newIds;
        }
      },
      { immediate: true },
    );

    const { items: tasks } = useTasksLoader({
      params,
      count,
    });

    function getPredecessorTask(predecessorId) {
      if (!tasks.value || !tasks.value.length) {
        return {};
      }
      return tasks.value.find((task) => task.id === predecessorId);
    }

    return { tasks, getPredecessorTask };
  },
  methods: {
    removePredecessor(predecessor) {
      this.$emit('remove-predecessor', predecessor);
    },
    editPredecessor(predecessor) {
      this.$emit('edit-predecessor', predecessor);
    },
  },
};
</script>
