var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popover',{ref:"optionsPopover",staticClass:"notifications__header-options-popover",attrs:{"popper-options":_vm.optionsPopperOptions,"no-close-other":true,"no-arrow":true,"boundaries-element":"body","placement":"bottom-end","container":false,"popover-class":"notifications__header-options-popover-content"},scopedSlots:_vm._u([{key:"popover",fn:function(ref){
var hide = ref.hide;
return [_c('ul',[(_vm.shouldRenderMarkAllAsReadOption)?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.markAllAsReadTooltipContent,
          placement: 'top',
        }),expression:"{\n          content: markAllAsReadTooltipContent,\n          placement: 'top',\n        }"}],staticClass:"flex items-center gap-3",attrs:{"disabled":!_vm.canMarkAllAsRead,"data-identifier":(_vm.dataIdentifierPrefix + "-notifications-options-mark-all-as-read"),"role":"button"},on:{"click":function($event){return _vm.onMarkAllAsReadClicked(hide)}}},[_c('ProjectsIcon',{staticClass:"mute-icon",attrs:{"id":"check-circle"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Mark all as read')))])],1):_vm._e(),_vm._v(" "),(_vm.shouldRenderMuteOption)?_c('li',{staticClass:"flex items-center gap-3",attrs:{"data-identifier":(_vm.dataIdentifierPrefix + "-notifications-options-mute"),"role":"button"},on:{"click":function($event){return _vm.onMuteNotificationsClicked(hide)}}},[_c('CommonIcon',{attrs:{"id":"notifications-disabled"}}),_vm._v(" "),_c('FSwitchField',{attrs:{"label-before-input":"","size":"sm","checked":_vm.areNotificationsMuted},on:{"change":function($event){return _vm.setAreNotificationsMuted($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Mute notifications'))+"\n        ")])],1):_vm._e()])]}}])},[_c('IconButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.$t('More options'),
      placement: 'top',
    }),expression:"{\n      content: $t('More options'),\n      placement: 'top',\n    }"}],attrs:{"icon":"ellipsis-h","classic-mode":true,"data-identifier":(_vm.dataIdentifierPrefix + "-notifications-options")}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }