export default async ({ dispatch, commit, state }, event) => {
  const { actionType, itemId, projectId } = event;
  if (['new', 'added', 'edited', 'reopened'].includes(actionType)) {
    // added is an actionType used for client-side updates, i.e. same user
    // but on the TKO side of the Hybrid. This update doesn't come with a
    // projectId unfortunately, so the only action we can take is to update
    // the current project. In future we will probably need to extend the
    // message on the TKO side, but until then we'll avoid the extra scaffolding.
    const pid =
      projectId ||
      (state.tasklist.records[itemId] &&
        state.tasklist.records[itemId].projectId) ||
      state.project.currentProjectId;
    await dispatch('project/tasklists/changeNotification', pid);
    dispatch('tasklist/checkTasksForMilestone', itemId);
  } else if (['deleted'].includes(actionType)) {
    commit('tasklist/delete', itemId);
  }
};
