<template>
  <div class="item-picker-no-result">
    <!-- eslint-disable -->
    <svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.4563 45.5201C43.5938 48.715 50.7706 58.799 50.7852 58.8146C55.9744 64.2947 65.2814 55.091 59.7347 50.0109C59.7193 49.9966 49.5984 42.8075 46.3513 41.6892" fill="#F03063"/>
      <path d="M36.2374 46.231L39.6026 49.5801C40.6996 50.6719 42.4527 50.7377 43.6373 49.7405C46.1708 47.6079 48.3566 45.0817 50.5599 42.5734C51.5562 41.4393 51.5356 39.7434 50.5179 38.6289C49.5277 37.545 48.7227 36.6009 47.3182 35.2027C44.3135 39.4827 40.1001 42.6976 36.2374 46.231Z" fill="#727491"/>
      <path d="M1.9246 33.2781C14.596 67.0579 58.8136 48.6054 51.868 19.0864C49.9357 10.8736 43.2536 3.96341 35.0807 1.52265C16.1638 -4.12618 -5.07331 14.623 1.9246 33.2781Z" fill="#B3B9CE"/>
      <path d="M8.74795 31.227C17.9126 55.5398 49.8938 42.259 44.8705 21.0124C43.4728 15.1012 38.6398 10.1277 32.7286 8.37113C19.0463 4.30558 3.68623 17.8003 8.74795 31.227Z" fill="#BAF8DD"/>
      <path d="M16.7936 25.1486C16.324 25.1486 15.9435 24.7408 15.9435 24.2375C15.9435 22.3601 17.4047 21.3016 18.7798 21.2582C20.2952 21.2103 21.4184 22.3025 21.5065 23.914C21.534 24.4164 21.1763 24.8472 20.7076 24.8767C20.2393 24.9058 19.8368 24.5228 19.8093 24.0205C19.7633 23.1762 19.1754 23.0679 18.8297 23.0795C18.3528 23.0942 17.6437 23.415 17.6437 24.2375C17.6437 24.7408 17.2632 25.1486 16.7936 25.1486Z" fill="#494E6A"/>
      <path d="M33.4881 24.8243C33.0179 24.8243 32.6368 24.4187 32.6368 23.9181C32.6368 21.9788 34.1266 20.9324 35.5285 20.9324C36.2277 20.9324 36.8782 21.1886 37.3592 21.6534C37.7432 22.0247 38.2013 22.7035 38.2013 23.831C38.2013 24.3316 37.8203 24.7372 37.3501 24.7372C36.88 24.7372 36.4989 24.3316 36.4989 23.831C36.4989 23.4584 36.4033 23.1774 36.2152 22.9955C36.0477 22.8337 35.804 22.7447 35.528 22.7447C35.1164 22.7447 34.3384 22.99 34.3384 23.9181C34.3393 24.4187 33.9582 24.8243 33.4881 24.8243Z" fill="#494E6A"/>
      <path d="M22.3551 32.2838C22.2259 32.2838 22.0946 32.2555 21.9715 32.1957C21.554 31.9933 21.3875 31.5058 21.5994 31.1068C22.5053 29.4042 24.6255 28.339 27.0014 28.3939C29.1417 28.4431 30.8534 29.3947 31.5799 30.94C31.7717 31.3481 31.5807 31.8274 31.1533 32.0105C30.726 32.1936 30.2239 32.0113 30.0321 31.6033C29.5798 30.6414 28.4316 30.0469 26.9606 30.0129C25.5472 29.9806 23.8072 30.5328 23.1116 31.8401C22.9623 32.1219 22.664 32.2838 22.3551 32.2838Z" fill="#494E6A"/>
    </svg>
    <!-- eslint-enable -->
    <div class="item-picker-no-result__message">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemPickerNoResult',
  props: {
    text: { type: String, required: true },
  },
};
</script>

<style scoped lang="scss">
.item-picker-no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__message {
    margin-top: 8px;
    color: #646d7d;
    font-size: 14px;
  }
}
</style>
