<template>
  <label
    class="text-sm relative m-0 shrink-0 flex max-w-full"
    :class="{ 'cursor-pointer': !disabled }"
    :for="`checkbox-${inputName}`"
    role="checkbox"
    :aria-checked="checked"
  >
    <input
      :name="inputName"
      :id="`checkbox-${inputName}`"
      type="checkbox"
      :checked="checked"
      :data-identifier="dataIdentifier"
      :disabled="disabled"
      :required="required"
      class="absolute w-4 h-4 opacity-0"
      @change="onChange"
      :tabindex="checked ? 0 : -1"
    />
    <span :class="checkboxClasses">
      <IconTick
        v-if="checked"
        class="
          fill-current
          w-2.5
          h-2.5
          absolute
          top-1/2
          left-1/2
          transform
          -translate-x-1/2 -translate-y-1/2
        "
      />
    </span>
    <slot />
  </label>
</template>
<script>
import { computed } from 'vue-demi';
import IconTick from '~icons/tw/checkbox-tick';

export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  name: 'FCheckbox',
  components: { IconTick },
  props: {
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: null,
    },
    dataIdentifier: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const inputName = props.name || Math.floor(Math.random() * 1000);
    const onChange = () => {
      emit('change', !props.checked, props.name);
    };
    const checkboxClasses = computed(() => {
      const classes = [
        'inline-block mr-2 w-4 h-4 rounded-sm relative border border-solid shrink-0 mt-0.5 text-white',
        // hover shadow
        'hover:after:content-[""] after:w-7 after:h-7 after:rounded-full after:absolute after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:z-[-1]',
        {
          // CHECKBOX
          // un-checked
          'border-[color:var(--token-c-checkbox-color-shadow-background)]':
            !props.checked,
          'bg-white': !props.checked,
          // checked
          'border-primary': props.checked && !props.disabled,
          'bg-primary': props.checked && !props.disabled,
          // disabled un-checked
          'bg-[color:var(--token-c-checkbox-color-background)] border-[color:var(--token-c-checkbox-color-border)]':
            props.disabled && !props.checked,

          // disabled checked
          'border-[color:var(--token-c-checkbox-color-border)]': props.disabled,
          'bg-[color:var(--token-c-checkbox-color-border)]':
            props.disabled && props.checked, // border + bg Neutral/30

          // disable unchecked
          'bg-[color:var(--token-c-checkbox-color-background)]':
            props.disabled && !props.checked,

          // SHADOW
          // un-checked
          'after:bg-[color:var(--token-c-checkbox-color-background)]':
            !props.checked && !props.disabled,
          // un-checked active
          'active:after:bg-[color:var(--token-c-checkbox-color-shadow-background)]':
            !props.checked && !props.disabled,
          // checked
          'after:bg-[color:var(--token-c-checkbox-color-active-strong)]':
            props.checked && !props.disabled,
          // checked active
          'active:after:bg-[color:var(--token-c-checkbox-color-active-strongest)]':
            props.checked && !props.disabled,
        },
      ];
      return classes;
    });
    return {
      inputName,
      onChange,
      checkboxClasses,
    };
  },
};
</script>
<style scoped>
input:focus-within + span::after {
  content: '';
  background-color: var(--token-c-checkbox-color-border);
}
input:focus-within:checked + span::after {
  background-color: var(--token-c-checkbox-color-active-strongest);
}
</style>
