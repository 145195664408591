var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"App",class:{
    'in-owner-company': _vm.inOwnerCompany,
    'is-admin': _vm.administrator,
    'is-account': _vm.isAccount,
    'is-collaborator': _vm.isCollaborator,
    'is-contact': _vm.isContact,
    'alert-banner--is-active': _vm.isAlertBannerActive,
    'filter-sidebar-visible': !_vm.isFiltersCollapsed,
    'content-with-sidenav': _vm.shouldUseAppShell,
    'has-sidebar': _vm.hasSidebar,
    'has-open-quickview': _vm.hasOpenQuickView,
    'sidebar-closed': _vm.isSidebarCollapsed,
    'is-chat-conversation-visible': _vm.isEmbeddedChatConversationVisible,
    'is-chat-conversations-visible': _vm.isEmbeddedChatConversationListVisible,
  },attrs:{"data-user-id":_vm.userId,"data-company-id":_vm.companyId,"data-user-admin":_vm.administrator}},[_c('style',{tag:"component",domProps:{"innerHTML":_vm._s(_vm.themeCSS)}}),_vm._v(" "),(_vm.isHybridReady)?_c('RouterView'):_vm._e(),_vm._v(" "),_c('HybridPopoverContainer'),_vm._v(" "),(_vm.isHybridReady)?[_c('TkoComponent',{attrs:{"name":"hybrid-page-extras"}}),_vm._v(" "),_c('TkoComponent',{attrs:{"name":"section-dashboard-widgets"}}),_vm._v(" "),_c('TkoComponent',{attrs:{"name":"section-flash-alerts"}}),_vm._v(" "),_c('TkoComponent',{attrs:{"name":"widget-timers-list"}}),_vm._v(" "),_c('OnboardingAndProductTours')]:_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"ModalContainer"}},[_c('HybridModals')],1),_vm._v(" "),_c('HybridQuickViews'),_vm._v(" "),_c('ProfilePopoverSingleton'),_vm._v(" "),_c('WindowEvents'),_vm._v(" "),_c('MountingPortal',{attrs:{"mount-to":"body","append":""}},[_c('portal-target',{attrs:{"name":"body","multiple":""}})],1),_vm._v(" "),(_vm.showPubbleChat)?_c('PubbleChat',{ref:"pubbleChat",attrs:{"remove-transition":false,"current-user-name":_vm.fullName,"current-user-email":_vm.email}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }