import { tryOnUnmounted } from '@vueuse/core';

/**
 * Supports scheduling fn to run in the next iteration of the event loop.
 * Ensures that at most one call to fn is scheduled at any time.
 *
 * @param fn The function which can be scheduled.
 */
export default function useNextCycle(fn) {
  let animationHandle;
  let timeoutHandle;

  function stop() {
    cancelAnimationFrame(animationHandle);
    clearTimeout(timeoutHandle);
  }

  function schedule() {
    stop();
    // requestAnimationFrame callback runs towards the end of the event loop's cycle,
    // so the setTimeout callback scheduled at that time will execute in the next cycle.
    // See https://html.spec.whatwg.org/multipage/webappapis.html#event-loop-processing-model
    animationHandle = requestAnimationFrame(() => {
      timeoutHandle = setTimeout(fn);
    });
  }

  tryOnUnmounted(stop);

  return {
    /**
     * Schedules fn to run in the next iteration of the event loop.
     * The previously scheduled invocation is canceled, if any.
     */
    schedule,
    /**
     * Cancels the scheduled invocation of fn, if any.
     */
    stop,
  };
}
