<template>
  <div
    class="drag-area"
    @click="showDragArea = false"
    :class="{
      visible: showDragArea,
      hover: hoverDragArea,
    }"
    ref="dragArea"
  >
    <div class="drag-content">
      <svg
        width="174"
        height="145"
        viewBox="0 0 174 145"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M137.668 101.93H7"
          stroke="#F5F7FA"
          stroke-width="2.68"
          stroke-linecap="round"
        />
        <path
          d="M149.728 101.93H167.8"
          stroke="#F5F7FA"
          stroke-width="2.68"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.77 140.078C97.3359 141.929 33.0615 143.432 32.7814 136.61C32.5985 132.153 51.4018 131.191 56.8339 131.054C108.384 129.763 112.556 138.14 104.77 140.078Z"
          fill="#EEF1F6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M112.315 125.266C113.416 125.275 114.356 124.49 114.558 123.409C116.289 114.115 115.578 78.8568 115.463 78.7268C115.518 76.6559 114.356 76.6007 113.092 76.6007C91.6687 76.6956 43.3927 75.9135 42.1996 76.427H42.1046C41.7234 76.4209 41.353 76.5528 41.0616 76.7986C40.7969 77.0213 40.6391 77.3458 40.6268 77.6911C40.0264 84.4399 41.4726 117.933 41.4726 124.697L112.315 125.266Z"
          fill="#4DA2F8"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="84"
          y="19"
          width="58"
          height="71"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M84.2885 19.3859H141.104V89.4092H84.2885V19.3859Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M140.993 42.3916C141.035 42.2871 141.067 42.179 141.089 42.069C141.476 40.4689 133.867 29.0422 132.87 28.7127C122.679 25.3611 107.069 20.0149 103.855 19.4245L103.741 19.3906C103.461 19.3686 103.179 19.4241 102.928 19.5503C102.551 19.7556 102.263 20.0925 102.118 20.4965C95.8381 35.8068 90.8334 53.6589 86.0194 69.5631C85.9028 69.9521 84.4092 74.2499 84.3069 74.7978C83.7131 77.8863 97.473 80.2289 122.967 89.1964L123.109 89.2388C123.364 89.3125 123.633 89.3256 123.894 89.2774L124.055 89.3256C124.906 89.6089 125.826 89.1493 126.109 88.2981C126.111 88.2915 126.113 88.285 126.115 88.2784C133.103 70.7408 135.064 60.3599 140.993 42.3916Z"
            fill="#79E3AA"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M132.842 28.7041C130.962 28.079 127.925 40.1531 128.337 41.0206C128.75 41.8884 131.795 43.007 139.497 43.6595C140.181 43.6888 140.81 43.2864 141.07 42.6524C142.208 40.233 134.007 29.0942 132.842 28.7041Z"
          fill="#BAF8DD"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M118.931 69.6746L96.8397 63.0597L106.122 56.4498C106.609 56.1029 107.289 56.2615 107.572 56.7875L110.024 61.3356C110.203 61.666 110.625 61.7748 110.94 61.5718L117.852 57.1301C118.379 56.7909 119.085 57.0197 119.313 57.6036L124.717 71.4071L118.931 69.6746Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M107.627 47.314C107.157 48.8841 108.048 50.5383 109.618 51.0087C111.189 51.4786 112.843 50.5869 113.313 49.0168C113.784 47.4467 112.892 45.7925 111.321 45.3222C109.751 44.8518 108.097 45.7439 107.627 47.314Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M100.581 28.8964C100.576 28.7838 100.561 28.6722 100.535 28.5627C100.225 26.9459 88.573 19.6881 87.5289 19.8004C76.8639 20.963 60.44 22.5501 57.2697 23.3419L57.152 23.3577C56.8877 23.4542 56.6546 23.6205 56.4779 23.8393C56.2197 24.1823 56.0963 24.6079 56.131 25.0358C56.7441 41.5725 59.5694 59.8969 61.7627 76.368C61.8175 76.77 62.235 81.3009 62.3685 81.8422C63.1047 84.9002 76.6019 81.3429 103.522 78.966L103.669 78.9459C103.932 78.9073 104.182 78.8086 104.399 78.6565L104.566 78.6338C105.459 78.54 106.105 77.7409 106.012 76.8487C106.011 76.8422 106.01 76.8349 106.01 76.8283C105.12 57.9706 102.613 47.7082 100.581 28.8964Z"
          fill="#FFD56A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M87.4995 19.8043C85.5293 20.0127 87.7569 32.262 88.4908 32.8817C89.2251 33.5014 92.4602 33.2606 99.7427 30.6695C100.378 30.4137 100.785 29.7867 100.759 29.1021C100.795 26.4285 88.7216 19.6777 87.4995 19.8043Z"
          fill="#FFEAB6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M78.3639 40.8568L65.275 42.7896C64.5704 42.8228 63.9719 42.2787 63.9387 41.5737C63.9094 40.9552 64.3277 40.4049 64.9315 40.2679L78.0189 38.3251C78.7181 38.231 79.3614 38.7214 79.4559 39.4202C79.5501 40.1186 79.0616 40.7611 78.3639 40.8568Z"
          fill="#CE8D4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M68.7878 57.7219L67.5418 57.8913C66.8368 57.9248 66.2388 57.3804 66.2052 56.6758C66.1759 56.0576 66.5945 55.507 67.1984 55.3696C71.3881 54.7993 76.3758 54.07 81.2076 53.372C86.0398 52.674 91.0079 51.9474 95.2466 51.3701C95.9342 51.2706 96.5717 51.7479 96.6708 52.4351C96.672 52.4417 96.6728 52.4482 96.6735 52.4552C96.7735 53.1478 96.2927 53.7907 95.5997 53.8902C95.5966 53.8906 95.5935 53.891 95.59 53.8918C91.4003 54.4625 86.406 55.1423 81.5804 55.8898C77.2097 56.5245 72.7016 57.1789 68.7878 57.7219Z"
          fill="#CE8D4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M68.7639 64.1512C68.0933 64.2488 67.4643 63.802 67.3362 63.1364C67.2081 62.4472 67.6553 61.782 68.3418 61.6403C72.3424 60.8555 91.2163 57.9553 95.2721 57.5031C95.9647 57.3684 96.635 57.8207 96.7697 58.5133C96.9043 59.2059 96.4521 59.8762 95.7595 60.0112C95.6892 60.0247 95.6182 60.0321 95.5469 60.034C91.53 60.4808 72.8039 63.3613 68.8326 64.1419L68.7639 64.1512Z"
          fill="#CE8D4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.3328 70.9698C68.6622 71.0674 68.0332 70.6206 67.9051 69.955C67.799 69.2577 68.2786 68.606 68.9762 68.4999L85.9165 65.9327C86.6045 65.7764 87.289 66.2074 87.4453 66.8954C87.6016 67.5834 87.1706 68.268 86.4826 68.4242C86.4093 68.4408 86.3348 68.4513 86.2599 68.4543L69.3297 71.0203L69.3328 70.9698Z"
          fill="#CE8D4F"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M111.217 96.8574C111.217 82.0406 111.312 74.1932 111.122 72.8813C111.257 71.4192 110.83 70.4237 109.447 70.5109C97.5934 69.4992 78.8963 70.2971 73.5702 69.6018C72.8509 68.6298 72.1081 65.7771 71.3414 64.8603C70.5751 63.9439 68.7731 61.6997 67.9435 60.4823C66.6713 58.4202 65.5727 58.3253 61.1476 58.475C37.5426 59.1707 34.6189 57.9066 33.1808 58.9021C30.7626 64.5284 32.7857 121.868 32.92 122.05C32.8567 124.769 34.0262 124.848 35.2907 124.84C56.714 124.737 107.866 125.796 109.06 125.124H109.155C109.555 125.113 109.933 124.935 110.198 124.634C110.472 124.306 110.625 123.893 110.632 123.465C111.257 114.63 111.225 105.732 111.217 96.8574Z"
          fill="#63C0F9"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M46.7098 70.4554C43.4222 70.4554 40.6802 70.4554 39.9293 70.3605C39.0565 70.2644 38.4268 69.4792 38.5229 68.6064C38.6189 67.7332 39.4042 67.1034 40.2774 67.1995C41.7074 67.3496 53.6241 67.2474 61.282 67.1362C62.1544 67.1231 62.8729 67.8204 62.886 68.6932C62.8992 69.566 62.2019 70.2845 61.3291 70.2972C60.0727 70.3289 52.5259 70.4396 46.7098 70.4554Z"
          fill="white"
        />
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="98"
          y="116"
          width="15"
          height="10"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M98.4514 116.62H112.502V125.45H98.4514V116.62Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M110.983 116.62L110.95 122.867C110.95 122.867 110.958 124.872 112.502 125.258C107.486 125.644 98.4514 125.328 98.4514 125.328L104.777 117.465L110.95 116.694"
            fill="#63C0F9"
          />
        </g>
      </svg>
      <p>{{ $t('Drag and drop files here') }}</p>
    </div>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue-demi';

export default {
  name: 'FileDragArea',
  setup(props, context) {
    const showDragArea = ref(false);
    const hoverDragArea = ref(false);
    const dragArea = ref(null);

    function isInsideWindow({ clientX, clientY }) {
      if (
        clientX > 0 &&
        clientX < window.innerWidth &&
        clientY > 0 &&
        clientY < window.innerHeight
      ) {
        return true;
      }
      return false;
    }

    function isValidType(event) {
      const dataTransfer = event.dataTransfer;
      if (dataTransfer.types != null) {
        if (dataTransfer.types.indexOf) {
          // if dt is an array (Blink based browsers)
          return dataTransfer.types.indexOf('Files') !== -1;
        }
        // if dt is an object (IE11, Firefox)
        return (
          typeof dataTransfer.types === 'object' &&
          (dataTransfer.types.contains('application/x-moz-file') ||
            dataTransfer.types.contains('Files'))
        );
      }
      return false;
    }

    function windowDragHandler(event) {
      if (event.type === 'dragenter' && isValidType(event)) {
        showDragArea.value = false;
      }
      if (event.type === 'dragleave') {
        showDragArea.value = false;
      }
      if (event.target.closest('.contact-upload')) {
        showDragArea.value = true;
      }
    }

    function onDropFiles(event) {
      context.emit('on-drop-files', event);
      showDragArea.value = false;
    }

    function areaDragHandler(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!isValidType(event)) {
        return;
      }
      switch (event.type) {
        case 'dragenter':
          this.showDragArea = true;
          this.hoverDragArea = true;
          break;
        case 'dragleave':
          this.hoverDragArea = false;
          if (!isInsideWindow(event)) {
            this.showDragArea = false;
          }
          break;
        case 'drop':
          this.showDragArea = false;
          this.hoverDragArea = false;
          onDropFiles(event);
          break;
        case 'dragover':
          this.hoverDragArea = true;
          break;

        default:
          break;
      }
    }

    onMounted(() => {
      ['dragenter', 'dragleave'].forEach((eventName) => {
        document.body.addEventListener(eventName, windowDragHandler, false);
      });

      ['dragover', 'drop', 'dragenter', 'dragleave'].forEach((eventName) => {
        if (dragArea.value) {
          dragArea.value.addEventListener(eventName, areaDragHandler, false);
        }
      });
    });

    onBeforeUnmount(() => {
      ['dragenter', 'dragleave'].forEach((eventName) => {
        document.body.removeEventListener(eventName, windowDragHandler, false);
      });
      ['dragover', 'drop', 'dragenter', 'dragleave'].forEach((eventName) => {
        if (dragArea.value) {
          dragArea.value.removeEventListener(eventName, areaDragHandler, false);
        }
      });
    });

    return {
      showDragArea,
      hoverDragArea,
      isInsideWindow,
      isValidType,
      windowDragHandler,
      areaDragHandler,
      onDropFiles,
      dragArea,
    };
  },
};
</script>

<style lang="scss" scoped>
.drag-area {
  position: absolute;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.1s opacity ease-out;
  opacity: 0;
  visibility: hidden;
  &.commentVisible {
    height: calc(100% - 320px);
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    .drag-content {
      display: flex;
    }
  }
  &.hover {
    .drag-content {
      background-color: rgba(white, 1);
    }
  }
  .drag-content {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.8);
    transition: 0.1s background-color ease-out;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #0b0e1f;
    p {
      margin-top: 16px;
    }
  }
}
</style>
