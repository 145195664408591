var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'table-cell-task',
    {
      editing: _vm.task.id == null || _vm.editing,
      'is-new': _vm.task.id == null,
    } ],on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.quickView.apply(null, arguments)}}},[(_vm.task.id > 0)?_c('TaskToggleStatus',{attrs:{"placement":"top-start","task":_vm.task,"size":"small","data-identifier":_vm.dataIdentifierPrefix
        ? (_vm.dataIdentifierPrefix + "-task-completion-toggle")
        : undefined,"margin-right":0},on:{"open":_vm.onDependencyOpen}}):_vm._e(),_vm._v(" "),(_vm.editing || _vm.task.id == null)?[_c('div',{staticClass:"table-cell-task__form-container",style:({
        minWidth: _vm.task.id == null && ((_vm.tableWidth - 45) + "px"),
        paddingLeft: _vm.task.id == null && _vm.taskInputPadding,
      })},[_c('input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.cancel),expression:"cancel"}],ref:"taskName",attrs:{"type":"text","placeholder":_vm.task.parentTaskId ? _vm.$t('Add a subtask') : _vm.$t('Add a task'),"tabindex":"0"},domProps:{"value":_vm.task.name},on:{"keydown":function($event){$event.stopPropagation();return _vm.handleKeyDown.apply(null, arguments)},"blur":_vm.onBlur}})])]:[_c('div',{staticClass:"table-cell-task-name",on:{"click":_vm.quickView}},[_c('a',{staticClass:"table-cell-task-name-link",attrs:{"title":_vm.task.name,"href":("#/tasks/" + (_vm.task.id))}},[_c('span',{class:{
            'is-completed': _vm.task.status === 'completed',
          }},[_vm._v("\n          "+_vm._s(_vm.task.name)+"\n        ")])])]),_vm._v(" "),(
        _vm.task.status !== 'completed' && _vm.canEdit && _vm.projectStatus !== 'inactive'
      )?_c('EditTaskName',{on:{"click":_vm.edit}}):_vm._e(),_vm._v(" "),(_vm.task.lockdown && _vm.task.lockdown.id && _vm.task.id != null)?_c('div',{staticClass:"table-cell-task-lockdown"},[_c('Privacy',{attrs:{"args":_vm.privacyArgs,"show-label":false}})],1):_vm._e(),_vm._v(" "),(_vm.numComments > 0)?_c('Comments',{attrs:{"item":_vm.task,"type":"task"}}):_vm._e(),_vm._v(" "),(_vm.numAttachments)?_c('Attachments',{attrs:{"task":_vm.task}}):_vm._e(),_vm._v(" "),(_vm.subtasksVisible && _vm.numActiveSubTasks)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        placement: 'top',
        content: _vm.subtaskOpen ? _vm.$t("Hide Subtasks") : _vm.$t("View Subtasks"),
        boundariesElement: 'document.body',
      }),expression:"{\n        placement: 'top',\n        content: subtaskOpen ? $t(`Hide Subtasks`) : $t(`View Subtasks`),\n        boundariesElement: 'document.body',\n      }"}]},[_c('Subtasks',{attrs:{"subtasks":_vm.numActiveSubTasks},on:{"subtaskOpenChange":_vm.subtaskOpenChange}})],1):_vm._e(),_vm._v(" "),(_vm.showParent)?_c('SubtaskPopover',{attrs:{"task":_vm.task}}):_vm._e(),_vm._v(" "),_c('QuickView',{attrs:{"task-id":_vm.task.id},on:{"click":_vm.quickView}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }