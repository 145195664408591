<template>
  <button
    type="button"
    class="
      flex flex-grow
      items-center
      cursor-pointer
      bg-transparent
      border-none
      hover:bg-[#161A30]
      rounded-full
      px-0
      h-[40px]
      max-w-full
      select-none
    "
    @click.stop="$emit('click')"
  >
    <span class="block w-[40px] h-[40px] relative">
      <img
        class="rounded-full w-[40px] h-[40px]"
        :src="avatarUrl"
        :alt="fullName"
        loading="lazy"
        v-img-load-fail="'userAvatar'"
      />
      <span
        v-if="isUserClockedIn && clockInOutEnabled"
        class="block absolute right-[-2px] top-[-2px] w-4 h-4"
      >
        <svg width="16" height="16" viewBox="0 0 512 512" class="animate-spin">
          <path
            fill="#ffffff"
            d="M256 496c-133.091 0-240-106.909-240-240s106.909-240 240-240 240 106.909 240 240-106.909 240-240 240zM256 59.636c-109.091 0-196.363 87.273-196.363 196.363s87.273 196.363 196.363 196.363 196.363-87.273 196.363-196.363-87.273-196.363-196.363-196.363z"
          ></path>
          <path
            fill="#ffffff"
            d="M343.273 321.454c-4.363 0-6.546 0-8.728-2.182l-87.273-43.637c-8.727-4.363-13.091-10.909-13.091-19.637v-130.909c0-13.091 8.727-21.819 21.819-21.819s21.818 8.727 21.818 21.819v117.819l74.182 37.091c10.909 4.363 15.273 17.454 8.728 28.363-2.182 8.728-8.728 13.091-17.454 13.091z"
          ></path>
        </svg>
      </span>
    </span>
    <p class="truncate ml-150 pr-150" v-if="!shouldMinimizeSidebar">
      {{ fullName }}
    </p>
  </button>
</template>

<script>
import { useCurrentUser } from '@teamwork/use';
import { computed } from 'vue-demi';
import { usePreferences } from '@/platform/composables/usePreferences';
import { useFeatures } from '@/platform/composables/useFeatures';
import useStore from '@/platform/composables/useStore';
import imgLoadFail from '@/utils/directives/img-load-fail';

export default {
  directives: {
    imgLoadFail,
  },
  setup() {
    const store = useStore();
    const user = useCurrentUser();
    const { shouldMinimizeSidebar } = usePreferences();
    const { clockInOutEnabled } = useFeatures();

    const fullName = computed(
      () => `${user.value.firstName} ${user.value.lastName}`,
    );

    const isUserClockedIn = computed(() => store.state.user.isClockedIn);

    const avatarUrl = computed(() => user.value.avatarUrl);
    return {
      fullName,
      avatarUrl,
      shouldMinimizeSidebar,
      isUserClockedIn,
      clockInOutEnabled,
    };
  },
};
</script>

<style scoped>
.animate-spin {
  animation-duration: 5s;
}
</style>
