<template>
  <v-popover
    popover-arrow-class="hidden"
    trigger="manual"
    :options="{ stopPropagation: true }"
    :boundaries-element="'body'"
    :delay="{ hide: 300 }"
    :open="isOpen"
    @show="onShow"
    :placement="placement"
    v-tooltip="{
      content: tooltipContent,
      placement: 'top',
    }"
  >
    <button
      class="task-toggle-button"
      :data-identifier="dataIdentifier"
      :class="[
        `size-${size}`,
        {
          'is-completed': task.status === 'completed',
          'is-blocked': numPredecessors > 0,
          'has-subtask': numActiveSubTasks > 0 || hasActiveSubtasks,
          'cannot-complete': !canComplete,
          'in-progress': isCreatingTask,
        },
      ]"
      @click="beforeToggleStatus"
      :style="{ marginTop: `${marginTop}px`, marginRight: `${marginRight}px` }"
      :disabled="isTaskToggleStatusDisabled"
      @mouseenter="show"
      @mouseleave="hide"
    >
      <span
        v-if="
          (numPredecessors == 0 && numActiveSubTasks > 0) || hasActiveSubtasks
        "
      >
        {{ hasActiveSubtasks ? numExcludeActiveSubtasks : numActiveSubTasks }}
      </span>
      <CommonIcon v-else :id="numPredecessors > 0 ? 'minus' : 'check'" />
    </button>

    <template slot="popover" v-if="numPredecessors > 0">
      <div
        class="task-toggle-predecessors"
        @mouseenter="show"
        @mouseleave="hide"
      >
        <div :class="{ loading }">
          <p :class="{ skeleton: loading }">
            {{ $t('Task can’t be completed') }}
          </p>
          <p :class="{ skeleton: loading }">
            {{ $t('Waiting on these tasks to be completed') }}
          </p>
          <ul>
            <template v-if="loading">
              <li class="loading" v-for="item in numPredecessors" :key="item">
                <span class="task-toggle-button size-small readonly skeleton" />
                <div class="skeleton task-skeleton"></div>
              </li>
            </template>
            <template v-else>
              <li
                v-for="predecessorTask in predecessors"
                :key="predecessorTask.id"
                @click="$emit('open', predecessorTask.id)"
              >
                <span class="task-toggle-button size-small readonly">
                  <span class="check-icon" />
                </span>
                <a :href="`#/tasks/${predecessorTask.id}`" @click.prevent>
                  {{ predecessorTask.name }}
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue';
import { computed, ref } from 'vue-demi';

import { usePredecessorsLoader, useTaskActions } from '@teamwork/use';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';

export default {
  name: 'TaskToggleStatus',
  props: {
    task: { type: Object, required: true },
    size: { type: String, default: 'default' },
    disabled: { type: Boolean, default: false },
    placement: { type: String, default: 'bottom' },
    marginTop: { type: Number, default: 0 },
    marginRight: { type: Number, default: 8 },
    dataIdentifier: { type: String, default: '' },
  },
  components: {
    CommonIcon,
  },
  setup(props) {
    const { completeTask, uncompleteTask } = useTaskActions();
    const taskId = ref();
    const isOpen = ref(false);
    const { items: predecessors, loading } = usePredecessorsLoader({
      taskId,
      count: Infinity,
    });

    const isCreatingTask = computed(() => props.task.id < 0);
    const canComplete = computed(
      () => props?.task?.userPermissions?.canComplete || false,
    );
    const numPredecessors = computed(() =>
      props.task.predecessorIds ? props.task.predecessorIds.length : 0,
    );
    const numActiveSubTasks = computed(() =>
      props.task.subTaskIds ? props.task.subTaskIds.length : 0,
    );
    // If API is set to exclude subtasks on table view we want to show the number of subtasks but prevent the ability to show them.
    const hasActiveSubtasks = computed(() => {
      return !props.task?.subTaskIds && props.task.subtaskStats?.active > 0;
    });
    // Number of active subtasks for when the API is set to exclude subtasks on table view we want to show the number of subtasks but prevent the ability to show them.
    const numExcludeActiveSubtasks = computed(() => {
      return props.task.subtaskStats?.active > 0
        ? props.task.subtaskStats?.active
        : 0;
    });
    const isTaskToggleStatusDisabled = computed(() => {
      if (numPredecessors.value > 0) {
        return false;
      }
      return (
        numActiveSubTasks.value > 0 ||
        isCreatingTask.value ||
        hasActiveSubtasks.value
      );
    });
    const tooltipContent = computed(() => {
      const activeSubtaskCount = numActiveSubTasks.value;

      if (!canComplete.value && numPredecessors.value === 0) {
        return Vue.t("You don't have permission to complete this task");
      }

      if (numPredecessors.value > 0) {
        return null;
      }

      if (
        canComplete.value &&
        activeSubtaskCount === 0 &&
        numExcludeActiveSubtasks === 0
      ) {
        if (props.task.status !== 'completed') {
          return Vue.t('Mark task complete');
        }
        if (props.task.status === 'completed') {
          return Vue.t('Mark task incomplete');
        }
      }

      if (activeSubtaskCount > 0) {
        return `${activeSubtaskCount} ${
          activeSubtaskCount === 1
            ? Vue.t('subtask incomplete')
            : Vue.t('subtasks incomplete')
        }`;
      }

      // Checks for number of subtasks when they're excluded from API call
      if (hasActiveSubtasks.value && numExcludeActiveSubtasks.value > 0) {
        return numExcludeActiveSubtasks.value === 1
          ? Vue.t('[0] subtask incomplete', numExcludeActiveSubtasks.value)
          : Vue.t('[0] subtasks incomplete', numExcludeActiveSubtasks.value);
      }

      return null;
    });

    function onShow() {
      taskId.value = props.task.id;
    }

    function toggleStatus() {
      if (props.task.status === 'completed') {
        uncompleteTask(props.task);
      } else {
        completeTask(props.task);
      }
    }

    function beforeToggleStatus() {
      if (
        numPredecessors.value > 0 ||
        numActiveSubTasks.value > 0 ||
        isCreatingTask.value ||
        !canComplete.value
      ) {
        return;
      }
      this.toggleStatus();
    }

    function show() {
      if (numPredecessors.value > 0) {
        isOpen.value = true;
      }
    }

    function hide() {
      isOpen.value = false;
    }

    return {
      predecessors,
      loading,
      numPredecessors,
      numActiveSubTasks,
      canComplete,
      isCreatingTask,
      hasActiveSubtasks,
      numExcludeActiveSubtasks,
      isTaskToggleStatusDisabled,
      isOpen,
      onShow,
      toggleStatus,
      beforeToggleStatus,
      show,
      hide,
      tooltipContent,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

$text-color: #0b0e1f;
$border-color: #c5cee0;
$completed-color: #4ecd97;
$blocked-color: #e64d4d;
$counter-color: #d8e0ff;
$counter-text-color: #364daa;
$secondary-text-color: #646d7d;
$hover-color: #f5f7fa;

$sizeList: (
  tiny: 18px,
  small: 20px,
  default: 24px,
  medium: 32px,
  big: 40px,
);
$sizeMargins: (
  tiny: 6px,
  small: 8px,
  default: 8px,
  medium: 15px,
  big: 20px,
);
$sizeFonts: (
  tiny: 8px,
  small: 10px,
  default: 12px,
  medium: 14px,
  big: 18px,
);

$sizes: tiny, small, default, medium, big;

.task-toggle-button {
  display: inline-flex;
  border: 1px solid $border-color;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: $text-color;

  svg {
    width: 12px;
    height: 12px;
    fill: #8f9bb3;
  }

  &:hover:not(.readonly) {
    svg {
      fill: $text-color;
    }
  }

  &.is-completed {
    background-color: $completed-color;
    border: 1px solid $completed-color;
    .w-svg-sprite {
      fill: $white;
    }
  }
  &.is-completed:hover {
    .w-svg-sprite {
      fill: $white;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
  }

  &.is-blocked {
    background-color: white !important;
    border-color: $blocked-color !important;
    .w-svg-sprite {
      fill: $blocked-color !important;
      display: inherit !important;
    }
  }

  &.cannot-complete {
    background-color: $neutral-80;
    border-color: $neutral-80;
    cursor: not-allowed;
    &:active {
      background-color: $neutral-80;
      border: none;
    }
    .w-svg-sprite {
      display: none;
    }
  }

  &.has-subtask {
    background-color: $counter-color;
    border-color: $counter-color;
    color: $counter-text-color;
    font-weight: 600;
  }

  &.in-progress {
    background-color: white;

    .w-svg-sprite {
      display: inline-block;
    }
  }

  &.readonly {
    cursor: default;
  }

  /* SIZES */
  @each $size in $sizes {
    &.size-#{$size} {
      width: map-get($sizeList, $size);
      height: map-get($sizeList, $size);
      border-radius: map-get($sizeList, $size) / 2;
      margin-right: map-get($sizeMargins, $size);
      font-size: map-get($sizeFonts, $size);
    }
  }
}

.check-icon {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  &:after,
  &:before {
    content: '';
    position: absolute;
    background-color: #8f9bb3;
    height: 1px;
  }
  &:before {
    width: 5px;
    transform: rotate(45deg);
    top: 7px;
    left: 0px;
  }
  &:after {
    width: 8px;
    transform: rotate(-45deg);
    top: 5px;
    left: 3px;
  }
}

.task-toggle-predecessors {
  padding: 16px 0 8px 0;
  max-width: 300px;
  font-size: 14px;

  p {
    margin: 0 16px;
    &:first-of-type {
      font-weight: 600;
      margin-bottom: 8px;
    }
    &:last-of-type {
      color: $secondary-text-color;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin-top: 8px;
    overflow-y: auto;
    max-height: 130px;
  }

  li {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    &:not(.loading) {
      cursor: pointer;
    }
    .task-toggle-button {
      flex-shrink: 0;
    }
    a {
      flex-grow: 2;
      text-decoration: none;
      color: $text-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover:not(.loading) {
      background-color: $hover-color;
    }
  }

  .task-skeleton {
    width: 180px;
    height: 20px;
  }
}
</style>
