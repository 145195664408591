<template>
  <a
    :href="`/#/people/${id}`"
    v-close-popover
    tabindex="-1"
    role="menuitem"
    class="flex group items-center py-150 px-200 mt-[-6px]"
  >
    <img
      class="w-[32px] h-[32px] rounded-full"
      :src="avatarUrl"
      :alt="fullName"
      loading="lazy"
      v-img-load-fail="'userAvatar'"
    />
    <div class="flex flex-col truncate bg-red-300 pl-150">
      <span class="text-text text-md truncate text-[16px] font-semibold">
        {{ fullName }}
      </span>
      <span class="text-sm text-text-tertiary group-hover:text-palette-blue-40">
        {{ $t('View Profile') }}
      </span>
    </div>
  </a>
</template>
<script>
import { useCurrentUser } from '@teamwork/use';
import { computed } from 'vue-demi';
import imgLoadFail from '@/utils/directives/img-load-fail';

export default {
  directives: {
    imgLoadFail,
  },
  setup() {
    const user = useCurrentUser();

    const fullName = computed(
      () => `${user.value.firstName} ${user.value.lastName}`,
    );
    const avatarUrl = computed(() => user.value.avatarUrl);
    const id = computed(() => user.value.id);

    return {
      fullName,
      avatarUrl,
      id,
    };
  },
};
</script>
