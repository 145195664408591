<template>
  <div class="preview-container">
    <div class="preview-file">
      <div class="quick-actions-cont" v-if="!isLoading">
        <button
          v-if="!file.uploading && isPreviewableFile"
          class="quick-action-button"
          v-tooltip="{
            content: $t('Preview'),
          }"
          @click="onPreviewFile"
        >
          <CommonIcons id="quickview" color="white" />
        </button>
        <button
          v-if="!file.uploading"
          class="quick-action-button"
          v-tooltip="{
            content: $t('Download'),
          }"
          @click="onDownload"
        >
          <CommonIcons id="arrow-to-bottom" color="white" />
        </button>
      </div>
      <span class="file-loading-icon" v-if="isLoading || file.uploading">
        <FileLoadingIcon />
      </span>
      <div v-if="showError" class="file-error">
        <FileErrorIcon />
        <p>{{ $t('Failed to load') }}</p>
      </div>
      <div v-else-if="file.thumbURL && isImage" class="image-cont">
        <img v-show="!isLoading" ref="imgEl" class="image-el" />
      </div>
      <img
        v-else-if="!isImage"
        :src="`//twa-prod.teamwork.com/tko/public/images/icons/icon-file-${formatName}.svg`"
        :alt="`${formatName} icon`"
      />
    </div>
    <div class="file-contents">
      <div class="file-menu">
        <MenuPopover
          :open.sync="isMenuOpen"
          :no-arrow="true"
          placement="top-end"
          :boundaries="'body'"
          :offset="0"
        >
          <button class="file-menu-button btn-reset">
            <CommonIcons id="ellipsis-h" />
          </button>
          <template #menu>
            <MenuElement
              :text="$t('Edit file details')"
              class="file-menu-item"
              v-close-popover
              @click="onEditFileDetails"
            />
            <MenuElement
              :text="$t('Upload a new version')"
              class="file-menu-item"
              v-close-popover
              @click="onUploadNewVersion"
            />
            <MenuElement
              v-if="hasVersions"
              class="file-menu-item"
              :text="$t('Versions')"
            >
              <MenuElement
                v-for="prevVersion in prevVersions"
                :key="`${file.id}-version-${prevVersion.versionNo}`"
                :text="$t('Version [0]', prevVersion.versionNo)"
                :subtitle="getRelativeTime(prevVersion.uploadedAt)"
                class="file-menu-item"
                v-close-popover
                @click="onPreviewFile($event, prevVersion.versionNo)"
              />
            </MenuElement>
            <MenuElement
              class="file-menu-item"
              v-close-popover
              :text="$t('Remove file from task')"
              @click="onDetachFile"
            />
            <MenuElement
              class="file-menu-item"
              v-close-popover
              :text="$t('Delete file')"
              @click="onDeleteFile"
            />
          </template>
        </MenuPopover>
      </div>
      <div class="flex start middle">
        <span
          v-if="file.latestFileVersionNo && file.latestFileVersionNo > 1"
          class="file-version mr-5"
        >
          {{ latestVersionInfo }}
        </span>
        <p
          class="file-name"
          v-tooltip="{
            content: !isMenuOpen ? tooltipContent : null,
            html: true,
            classes: 'w-tooltip file-name__tooltip',
          }"
        >
          {{ file.displayName }}
        </p>
      </div>
      <div class="flex space-between middle mt-5">
        <p class="file-ext">
          {{ fileExtDisplay }}
        </p>
        <div class="flex">
          <div v-if="file.commentsCount > 0">
            <Comments :item="file" type="files" />
          </div>
          <div class="ml-8" v-if="file.isPrivate > 0">
            <Privacy :args="privacyArgs" :show-label="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import { fileTypeGroups, useFileActions } from '@teamwork/use';
import Privacy from '@widgets/TableCellWidgets/Privacy/Privacy';
import Comments from '@widgets/TableCellWidgets/TaskName/Comments/Comments';
import MenuPopover from '@widgets/Menu/MenuPopover';
import MenuElement from '@widgets/Menu/MenuElement';
import FileModel from 'fileModel';
import moment from 'moment';
import { Vue2 as Vue } from 'vue-demi';
import { mapGetters } from 'vuex';
import {
  friendlyFileSize,
  getExtension,
  isImage,
  isDisplayableImage,
  isPreviewableFile,
  isPlayableVideo,
  fileTooBig,
} from '@/utils/helpers/files';
import { getPastRelativeTime } from '@/utils/helpers/date';
import FileErrorIcon from '../FileErrorIcon/FileErrorIcon.vue';
import FileLoadingIcon from '../FileLoadingIcon/FileLoadingIcon.vue';
import safeHtml from '@/utils/helpers/safeHtml';
import api from '@/services/api';

export default {
  name: 'FileThumbnail',
  props: {
    file: { type: Object, required: true },
    task: { type: Object, required: true },
  },
  components: {
    CommonIcons,
    Privacy,
    FileErrorIcon,
    FileLoadingIcon,
    MenuPopover,
    MenuElement,
    Comments,
  },
  setup() {
    const { deleteFile, restoreFile } = useFileActions();

    return {
      deleteFile,
      restoreFile,
    };
  },
  data: () => ({
    showError: false,
    isLoading: false,
    isMenuOpen: false,
  }),
  computed: {
    ...mapGetters({
      timeFormat: 'user/timeFormat',
      dateFormat: 'user/dateFormat',
    }),
    latestVersionInfo() {
      return Vue.t('Version').charAt(0) + this.file.latestFileVersionNo;
    },
    fileExt() {
      return `.${getExtension(this.file.originalName)}`;
    },
    fileExtDisplay() {
      return `${getExtension(this.file.originalName).toUpperCase()}`;
    },
    isImage() {
      return isImage(this.file.originalName);
    },
    isPreviewableFile() {
      return isPreviewableFile(this.file);
    },
    formatName() {
      if (this.fileExt === '.pdf') {
        return 'pdf';
      }
      if (fileTypeGroups.video.includes(this.fileExt)) {
        return 'video';
      }
      if (fileTypeGroups.document.includes(this.fileExt)) {
        return 'document';
      }
      if (fileTypeGroups.audio.includes(this.fileExt)) {
        return 'audio';
      }
      if (fileTypeGroups.compressed.includes(this.fileExt)) {
        return 'zip';
      }
      if (fileTypeGroups.data.includes(this.fileExt)) {
        return 'data';
      }
      if (fileTypeGroups.spreadsheet.includes(this.fileExt)) {
        return 'xlsx';
      }
      if (fileTypeGroups.code.includes(this.fileExt)) {
        return 'code';
      }

      return 'unknown';
    },
    privacyArgs() {
      return {
        lockdownId: this.file.lockdownId,
        privacyIsInherited: this.file.isPrivate === 2,
        title: this.file.originalName,
        private: this.file.isPrivate,
        itemId: this.file.versionId,
        projectId: this.file.projectId,
        type: 'file',
        extraInfo: {
          fileVersionId: this.file.versionId,
        },
      };
    },
    tooltipContent() {
      return `<div>
          <div class="text-center"><strong>${safeHtml(
            this.file.displayName,
          )}</strong></div>
          <div>Uploaded on ${safeHtml(
            moment(this.file.uploadedAt).format(this.dateFormat),
          )} at ${safeHtml(
        moment(this.file.uploadedAt).format(this.timeFormat),
      )} - ${safeHtml(friendlyFileSize(this.file.size))}</div>
        </div>
      `;
    },
    hasVersions() {
      return this.file.versions.length > 1; // do not consider itself
    },
    prevVersions() {
      return this.file.versions
        .filter(
          (prevVersion) => prevVersion.fileVersionId !== this.file.versionId,
        )
        .sort((file1, file2) => file2.versionNo - file1.versionNo);
    },
  },
  mounted() {
    if (this.file.thumbURL && this.isImage) {
      this.loadImage(this.file.thumbURL);
    }
  },
  methods: {
    onDeleteFile() {
      this.$store.dispatch('modals/open', {
        name: 'confirm-modal',
        props: {
          title: Vue.t('Delete File?'),
          text: Vue.t('Are you sure you want to delete this file?'),
          cancelMessage: Vue.t('Cancel'),
          confirmMessage: Vue.t('Yes, Delete this File'),
          callback: async (result) => {
            if (result.OK) {
              await this.deleteFile(this.file);
              this.$store.dispatch('notifications/flashes/success', {
                title: Vue.t('File has been deleted.'),
                opts: { undo: () => this.restoreFile(this.file) },
              });
            }
          },
        },
      });
    },
    onDetachFile() {
      const attachments = this.task.attachments
        .filter((attachment) => attachment.id !== this.file.id)
        .map((attachment) => attachment.id)
        .toString();
      const data = {
        updateFiles: true,
        attachments,
        removeOtherFiles: true,
      };
      api.put(`tasks/${this.task.id}/files.json`, data);
    },
    onEditFileDetails() {
      const fileModel = new FileModel(this.file);
      this.$store.dispatch('modals/tko/open', {
        id: 'projectsEditFileDetails',
        args: {
          listContainer: null,
          item: fileModel,
          projectId: this.file.projectId,
          type: 'file',
        },
      });
    },
    loadImage(url) {
      this.isLoading = true;
      const image = document.createElement('img');
      image.onload = () => {
        if (this.$refs.imgEl) {
          this.$refs.imgEl.src = this.file.thumbURL;
        }
        this.isLoading = false;
      };
      image.onerror = () => {
        this.showError = true;
        this.isLoading = false;
      };
      image.src = url;
    },
    onDownload(version = this.file.latestFileVersionNo, display = false) {
      window.open(
        `./?action=viewFile&fileId=${this.file.id}&v=${version}&display=${display}`,
      );
    },
    onPreviewFile(e, version = this.file.latestFileVersionNo) {
      if (
        !this.isPreviewableFile ||
        e.ctrlKey ||
        e.metaKey ||
        (this.file.fileSource === 'teamworkpm' &&
          fileTooBig(this.file) &&
          !isPlayableVideo(this.file))
      ) {
        this.onDownload(version, this.isPreviewableFile);
        return;
      }

      const file = new FileModel(this.file);
      if (isDisplayableImage(this.file)) {
        this.$emit('preview-image', { file, version });
      } else {
        // previewable file
        this.$emit('preview-file', { file, version });
      }
    },
    getRelativeTime(date) {
      return getPastRelativeTime(date);
    },
    onUploadNewVersion() {
      const file = new FileModel(this.file);
      this.$store.dispatch('modals/tko/open', {
        id: 'projectsEditFileDetails',
        args: {
          listContainer: null,
          item: file,
          projectId: this.file.projectId,
          type: 'file',
          showUploadNew: true,
          title: Vue.t('Upload New Version'),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$self: 'preview-container';

.preview-container {
  display: grid;
  grid-template-columns: 76px 1fr;
  background: #f7f7f7;
  height: 62px;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    .quick-actions-cont {
      opacity: 1;
      visibility: visible;
    }

    .file-name {
      margin-right: 20px;
    }

    .file-menu-button {
      opacity: 1;
    }
  }
  .quick-actions-cont {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  .quick-action-button {
    appearance: none;
    background: #0b0e1f;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.preview-file {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #e7ebf1;
  overflow: hidden;
}

.image-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  .image-el {
    width: 76px;
    height: 62px;
    object-fit: cover;
    object-position: center;
    box-shadow: none !important;
  }
}

.file-loading-icon {
  position: absolute;
  color: red;
}

.file-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: #6d6f80;
    font-size: 10px;
    margin-top: 5px;
  }
}

.file-contents {
  position: relative;
  padding: 8px;
  overflow: hidden;
  .file-menu {
    position: absolute;
    top: 0;
    right: 0;
  }
  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #0b0e1f;
    width: auto;
  }
  .file-version {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #646d7d;
    border-radius: 9px;
    height: 18px;
    min-width: 18px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 0 3px;
    // margin-right: 4px;
  }
  .file-ext {
    color: #646d7d;
    font-size: 12px;
  }
}

.file-menu-button {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 8px;
  opacity: 0;
}

.file-menu-item {
  ::v-deep a {
    padding: 10px 16px;
  }
}

.flex {
  display: flex;
  &.center {
    justify-content: center;
  }

  &.middle {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.start {
    justify-content: flex-start;
  }
}

.mr-5 {
  margin-right: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.mt-5 {
  margin-top: 5px;
}
</style>

<style lang="scss">
.file-name__tooltip {
  max-width: 200px;
  text-align: center;
}
</style>
