import { v1 as fetcher, post } from '@teamwork/fetcher';

const defaultParams = {
  filter: 'all',
  skipSpecial: true,
  getProjectData: true,
  sort: 'projectDateLastUsed',
  sortOrder: 'desc',
  showAll: true,
};

const normalize = ({ data }) => data;

const search = async ({ searchTerm, params, pagination }) =>
  fetcher(
    'tags',
    { ...defaultParams, ...params, ...pagination, searchTerm, q: searchTerm },
    normalize,
  );

const create = (tag) => post('tags.json', { tag });

// eslint-disable-next-line import/prefer-default-export
export default {
  search,
  create,
};
