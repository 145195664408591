export const searchInputSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

export const defaultSearchInputSize = searchInputSizes.md;

export const searchInputVariants = {
  outline: 'outline',
  filled: 'filled',
};

export const defaultSearchInputVariant = searchInputVariants.outline;
