import { useCurrentAccount, useCurrentUser } from '@teamwork/use';
import { useIntervalFn, useNow } from '@vueuse/core';
import moment from 'moment';
import {
  computed,
  inject,
  onScopeDispose,
  provide,
  shallowRef,
  unref,
  watch,
} from 'vue-demi';
import { useLaunchDarkly } from './useLaunchDarkly';
import { useTeamworkFeaturesState } from './useTeamworkFeatures';

const useFeaturesSymbol = Symbol('useFeatures');

/**
 * Related to Craic Dealer experiment
 * @param {string | null | undefined} value
 */
function normalizeFeatureLimit(value) {
  if (value === null || value === '' || value === '-1') {
    return Infinity;
  }
  if (value === undefined) {
    return 0;
  }
  return Number(value);
}

/**
 * Provides LaunchDarkly and Teamwork features.
 */
function FeaturesService() {
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const nowDate = useNow({ interval: 60 * 60 * 1000 });
  const now = computed(() => moment(nowDate.value));

  const { items: twFeaturesList, inSync: twFeaturesInSync } =
    useTeamworkFeaturesState();
  const twFeaturesInitialized = shallowRef(false);
  const twFeatures = computed(() => {
    const features = new Map();
    twFeaturesList.value.forEach((feature) => {
      features.set(feature.key, feature);
    });
    return features;
  });
  const unwatch = watch(
    twFeaturesInSync,
    () => {
      if (twFeaturesInSync.value) {
        twFeaturesInitialized.value = true;
        queueMicrotask(() => unwatch());
      }
    },
    { immediate: true },
  );

  const {
    client: ldClient,
    ready: ldReady,
    initialized: ldInitialized,
  } = useLaunchDarkly();
  const launchDarklyUpdateCount = shallowRef(0);
  let removeChangeListener;

  function triggerLaunchDarklyUpdate() {
    launchDarklyUpdateCount.value += 1;
  }

  watch([ldReady, ldInitialized], triggerLaunchDarklyUpdate);

  function addChangeListener() {
    const client = ldClient.value;
    if (client) {
      client.on('change', triggerLaunchDarklyUpdate);
      return () => client.off('change', triggerLaunchDarklyUpdate);
    }
    return undefined;
  }

  watch(
    ldClient,
    () => {
      removeChangeListener?.();
      removeChangeListener = addChangeListener();
    },
    { immediate: true },
  );

  // Make sure that the calls to the LaunchDarkly `track` function are recorded,
  // even if the app keeps running for more than 24 hours.
  // See https://docs.launchdarkly.com/home/experimentation/interpreting#understanding-conversions
  useIntervalFn(triggerLaunchDarklyUpdate, 24 * 60 * 60 * 1000);

  onScopeDispose(() => {
    removeChangeListener?.();
    removeChangeListener = undefined;
  });

  // Returns a `computed` which provides a feature flag value from LaunchDarkly.
  function ldFlag(name, defaultValue, overrideValue) {
    return computed(() => {
      if (unref(overrideValue) !== undefined) {
        return unref(overrideValue);
      }
      // Accessing `launchDarklyUpdateCount.value` retriggers the computed when the flag
      // value must be retrieved from LaunchDarkly again.
      if (ldClient.value && launchDarklyUpdateCount.value >= 0) {
        return ldClient.value.variation(name, unref(defaultValue));
      }
      return unref(defaultValue);
    });
  }

  // Returns a `computed` which indicates if a Teamwork feature is enabled.
  /** @returns {{value: boolean}} */
  function twEnabled(name, overrideValue) {
    return computed(() => {
      if (unref(overrideValue) !== undefined) {
        return unref(overrideValue);
      }
      const feature = twFeatures.value.get(name);
      return Boolean(
        feature &&
          (feature.startAt == null || now.value.isAfter(feature.startAt)) &&
          (feature.endAt == null || now.value.isBefore(feature.endAt)) &&
          (feature.scope !== 'ownercompany' || user.value?.inOwnerCompany) &&
          // feature.scope === 'nobody' means "disabled in beta, but potentially togglable"
          feature.scope !== 'nobody',
      );
    });
  }

  /**
   * Returns a `computed` which evaluates to the value of a Teamwork feature.
   * @template T
   * @param {string} name
   * @param {(value: string | null | undefined) => T} normalize
   * @param {T | import('vue').Ref<T>} [overrideValue]
   * @returns {import('vue').ComputedRef<T>}
   */
  function twValue(name, normalize, overrideValue) {
    return computed(() => {
      if (overrideValue !== undefined) {
        return unref(overrideValue);
      }
      const feature = twFeatures.value.get(name);
      return normalize(feature?.value);
    });
  }

  return {
    initialized: computed(
      () => ldInitialized.value && twFeaturesInitialized.value,
    ),

    // LaunchDarkly features
    productTourEnabled: ldFlag('projects-show-in-app-product-tour', false),
    inboxEnabled: ldFlag('projects-show-inbox-new-notification-bell', false),
    projectsSideNavPanelPinningEnabled: ldFlag(
      'projects-side-nav-panel-pinning',
      false,
    ),
    projectsSideNavItemPinningEnabled: ldFlag(
      'projects-side-nav-item-pinning',
      false,
    ),
    hotspotTourEnabled: ldFlag('projects-hotspot-tour-flag', false),
    hideUpgradePathsForMaxpSmallAccountsEnabled: ldFlag(
      'projects-hide-upgrade-paths-maxp-small-accounts',
      false,
    ),
    vueListViewEnabled: ldFlag('projects-vue-list-view', false),
    addUsersCheckoutModalEnabled: ldFlag(
      'projects-show-add-users-checkout-modal',
      false,
    ),
    clickupImporterEnabled: ldFlag(
      'projects-integrations-clickup-importer',
      false,
    ),
    onboardingProgressBarExperimentEnabled: ldFlag(
      'projects-onboarding-progress-bar-experiment',
      false,
    ),
    isProjectsShowTableViewFilter: ldFlag(
      'projects-show-table-view-filter',
      false,
    ),
    isProjectsNewBlankStateEnabled: ldFlag('projects-new-blankstate', false),
    projectsProfilePhotoPromptEnabled: ldFlag(
      'projects-profile-photo-prompt-experiment',
      false,
    ),
    addTaskButtonTextEnabled: ldFlag('projects-add-task-button-to-text', false),
    inviteUsersAsAdminsEnabled: ldFlag(
      'projects-invite-users-as-admins',
      false,
    ),
    isProjectsInlineEditingUpdateFlagEnabled: ldFlag(
      'projects-inline-editing-update',
      false,
    ),
    clientViewEnabled: ldFlag('projects-client-view-access', false),
    newPrecannedReportsExportsEnabled: ldFlag(
      'projects-reports-new-exports-gen1',
      false,
    ),
    userTaskCompletionReportLDEnabled: ldFlag(
      'projects-reporting-pre-tasks-completion-report',
      false,
    ),
    customReportingEnabledLD: ldFlag('projects-custom-reporting', false),
    customReportingActionsRestricted: ldFlag(
      'projects-custom-reporting-restricted',
      false,
    ),
    newPrecannedReportsEnabled: ldFlag(
      'projects-reporting-new-precanned',
      false,
    ),

    pubbleChatEnabled: ldFlag('projects-pubble-chat-enabled', false),

    deskBannersEnabled: ldFlag('projects-desk-banners', false),

    projectsOnboardingDummyExperimentVariation: ldFlag(
      'projects-onboarding-dummy-experiment',
      'control',
    ),

    projectsFreeSeatsBannerEnabled: ldFlag(
      'projects-exp-41-free-seats-banner',
      false,
    ),

    scheduleReportingEnabledLD: ldFlag('projects-scheduled-reporting', false),

    projectsScaleUpgradeSlatesEnabled: ldFlag(
      'projects-scale-upgrade-slates-enabled',
      false,
    ),
    projectsExp44UpgradeFlowEnabled: ldFlag(
      'projects-exp-44-upgrade-flow',
      false,
    ),
    leanerUIForWorkerBeesEnabled: ldFlag(
      'projects-exp-e-23-16-leaner-ui-for-worker-bees',
      false,
    ),
    personalizedWorkerBeeOnboardingEnabled: ldFlag(
      'projects-exp-a-23-29-personalised-worker-bee-onboarding',
      false,
    ),
    projectsStripeV2SubscriptionUpdateEnabled: ldFlag(
      'projects-stripe-v2-subscription-update',
      false,
    ),

    isNewInlineImageEnabled: ldFlag('projects-new-inline-image', false),

    shouldReduceElasticsearchLoad: ldFlag(
      'projects-elasticsearch-should-reduce-load',
      false,
    ),
    noCustomReportingRefreshLimit: ldFlag(
      'projects-custom-reporting-reduced-cutoff',
      false,
    ),
    timeTotalsV3Enabled: ldFlag('projects-time-totals-v3', false),
    hideOldWorkload: ldFlag('projects-only-new-workload', false),

    projectsPlantrialUpgradeSlatesEnabled: ldFlag(
      'projects-plantrial-upgrade-slates-enabled',
      false,
    ),
    projectsWorkerBeeLogTimeCtaEnabled: ldFlag(
      'projects-exp-a-23-15-worker-bee-log-time-ctas',
      false,
    ),
    exp46BadgeRatingSystemEnabled: ldFlag(
      'projects-exp-46-badge-rating-system',
      false,
    ),
    timeRemindersFlagEnabled: ldFlag('projects-time-reminders', false),
    newUserProfileEnabled: ldFlag(
      'projects-lightspeed-new-user-profile',
      false,
    ),
    googleCalendarSyncEnabled: ldFlag(
      'projects-integrations-google-calendar',
      false,
    ),
    lightspeedListViewEnabled: ldFlag('projects-lightspeed-list-view', false),
    lightspeedListViewToggleEnabled: ldFlag(
      'projects-lightspeed-view-toggle--list-view',
      false,
    ),
    newLogTimeModalEnabled: ldFlag('projects-new-log-time-modal', false),
    projectExpE23SubscriptionChanges: ldFlag(
      'projects-exp-e-23-23a-subscription-changes',
      false,
    ),
    newTimerModalEnabled: ldFlag('projects-new-timer-modal', false),
    budgetRatesCtasEnabled: ldFlag(
      'projects-exp-e-23-17-budget-rates-ctas',
      false,
    ),
    improveCancellationFlowEnabled: ldFlag(
      'projects-exp-23-12-improved-cancellation',
      false,
    ),
    hideBoardTriggersEnabled: ldFlag(
      'projects-hide-board-triggers-from-users',
      false,
    ),

    projectsFinanceOnboardingV2Enabled: ldFlag(
      'projects-exp-a-23-01-finance-onboarding-v2',
      false,
    ),

    projectsDeskExpansionExperimentEnabled: ldFlag(
      'projects-exp-23-25-deliver-desk-expansion',
      false,
    ),

    projectsOnboardingTemplatesExperimentEnabled: ldFlag(
      'projects-exp-a-23-26-onboarding-templates',
      false,
    ),

    copyInviteLinksEnabled: ldFlag(
      'projects-exp-a-23-25-copy-invite-links',
      false,
    ),
    projectsLightspeedTaskDetails: ldFlag(
      'projects-lightspeed-task-details',
      false,
    ),
    projectFinanceRatesEnabled: ldFlag('projects-user-rates-v2', false),
    projectsOpenAIEnabled: ldFlag('projects-open-ai-enabled', false),

    onboardingGoalsSelectionRedesignEnabled: ldFlag(
      'projects-exp-23-37-onboarding-goals-selection-redesign',
      false,
    ),
    projectsDummyDataEnabled: ldFlag('projects-exp-a-23-27-dummy-data', false),
    projectsFeatureTrialScale: ldFlag(
      'projects-exp-e-23-21a-feature-trials-scale',
      false,
    ),
    projectsFeatureTrialGrow: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow',
      false,
    ),
    projectsFeatureTrialGrowTasklistBudgets: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow-tasklistbudgets',
      false,
    ),
    projectsFeatureTrialGrowRetainerBudgets: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow-retainerbudgets',
      false,
    ),
    projectsFeatureTrialGrowTimeReport: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow-timereport',
      false,
    ),
    projectsFeatureTrialGrowProjectBudgetExpenses: ldFlag(
      'projects-exp-e-23-21b-feature-trials-grow-projectbudgetexpenses',
      false,
    ),
    slackIntegrationDuringOnboarding: ldFlag(
      'projects-exp-a-23-41-slack-integration-during-onboarding',
      false,
    ),
    icpOnboardingImprovementsEnabled: ldFlag(
      'projects-a-23-30-personalised-icp-onboarding-improvements',
      false,
    ),
    onboardingSignupSourceSurveyStepEnabled: ldFlag(
      'projects-g-23-07-onboarding-signup-source-survey',
      false,
    ),
    projectsTemplateGalleryEnabled: ldFlag('projects-template-gallery', false),
    onboardingForPaidInvitedUsersEnabled: ldFlag(
      'projects-exp-a-23-45-onboarding-for-paid-invited-users',
      false,
    ),
    optimiseFreeForeverToPaidUpgradeUXEnabled: ldFlag(
      'projects-exp-a-23-18-optimise-free-forever-to-paid-upgrade-ux',
      false,
    ),
    projectsWorkflowsEnabled: ldFlag('projects-workflows', false),
    // Teamwork features
    activeProjectLimit: twValue('activeprojectlimit', normalizeFeatureLimit),
    activeProjectLimitEnabled: twEnabled('activeprojectlimit'),
    auditTrailEnabled: twEnabled('audittrail'),
    burndownchartLimit: twValue('burndownchart', normalizeFeatureLimit),
    clockInOutEnabled: twEnabled('clockinout'),
    customfieldsLimit: twValue('customfields', normalizeFeatureLimit),
    customfieldsProjectsEnabled: twEnabled('customfieldsprojects'),
    customfieldsTasksEnabled: twEnabled('customfieldstasks'),
    financialBudgetLimit: twValue(
      'projectfinancialbudgets',
      normalizeFeatureLimit,
    ),
    myBoardsEnabled: twEnabled('myboards'),
    newGanttEnabled: twEnabled('newgantt'),
    portfolioLimit: twValue('portfolio', normalizeFeatureLimit),
    plannedVsActualMilestoneReportEnabled: twEnabled(
      'plannedactualmilestonereport',
    ),
    plannedVsActualTasksReportEnabled: twEnabled('plannedactualtasksreport'),
    profitabilityEnabled: twEnabled('profitability'),
    projectBudgetExpensesEnabled: twEnabled('projectbudgetexpenses'),
    projectFinancialBudgetsEnabled: twEnabled('projectfinancialbudgets'),
    projectTemplatesEnabled: twEnabled('projecttemplates'),
    projectTemplatesLimit: twValue('projecttemplates', normalizeFeatureLimit),
    projectTimeBudgetsEnabled: twEnabled('projecttimebudgets'),
    projectTimeReportEnabled: twEnabled('projecttimereport'),
    projectRetainerBudgetsEnabled: twEnabled('retainerbudgets'),
    reportsEnabled: twEnabled('reports'),
    projectsHealthReportEnabled: twEnabled('projectshealthreport'),
    tasklistBudgetsEnabled: twEnabled('tasklistbudgets'),
    tasklistBudgetsLimit: twValue('tasklistbudgets', normalizeFeatureLimit),
    timeBudgetLimit: twValue('projecttimebudgets', normalizeFeatureLimit),
    taskTimeReportEnabled: twEnabled('tasktimereport'),
    retainerBudgetLimit: twValue('retainerbudgets', normalizeFeatureLimit),
    userLoggedTimeReportEnabled: twEnabled('userloggedtimereport'),
    userTaskCompletionReportEnabled: twEnabled('usertaskcompletionreport'),
    utilizationReportEnabled: twEnabled('utilizationreport'),
    timeReportEnabled: twEnabled('timereport'),
    workloadBacklogEnabled: twEnabled('workloadbacklog'),
    previewProfitabilityEnabled: twEnabled('previewprofitability'),
    customReportingEnabled: twEnabled('customreporting'),
    customReportingLimit: twValue('customreporting', normalizeFeatureLimit),
    workloadPlannerEnabled: twEnabled('workloadplanner'),
    intakeFormsLimit: twValue('intakeforms', normalizeFeatureLimit),
    riskRegisterEnabled: twEnabled('riskregister'),
    timeRemindersEnabled: twEnabled('timereminders'),

    scheduleReportingEnabled: twEnabled('schedulereporting'),
    resourceSchedulePeopleEnabled: twEnabled('resourceschedulingpeople'),
    googleSheetsExportEnabled: twEnabled('accessgooglesheetsexports'),

    // Other/temporary - migrate these features to LaunchDarkly
    teamworkNextNotebooksEnabled: computed(() => {
      const DIGITALCREW_SUNBEAM_ACCOUNT = 1;
      return (
        Boolean(account.value) &&
        (account.value.id === DIGITALCREW_SUNBEAM_ACCOUNT ||
          account.value.awsRegion === 'EU')
      );
    }),
    homeMyTasksEnabled: computed(() =>
      account.value?.createdAt.isSameOrAfter(moment('2021-10-08T00:00:00Z')),
    ),
  };
}

/**
 * Provides LaunchDarkly and Teamwork features.
 * @returns {void}
 */
export function provideFeatures() {
  provide(useFeaturesSymbol, FeaturesService());
}

/**
 * Provides LaunchDarkly and Teamwork features.
 * @returns {ReturnType<typeof FeaturesService>}
 */
export function useFeatures() {
  return inject(useFeaturesSymbol);
}
