<template>
  <div class="w-[312px] p-300 bg-[#f0f3f7]">
    <div class="h-px bg-[#646D7D] opacity-20 mb-300" />
    <ProjectsPanelAllProjectsNavItem class="mb-2" />
    <ProjectsPanelTemplateNavItem />
  </div>
</template>

<script>
import ProjectsPanelAllProjectsNavItem from './ProjectsPanelAllProjectsNavItem.vue';
import ProjectsPanelTemplateNavItem from './ProjectsPanelTemplateNavItem.vue';

export default {
  components: {
    ProjectsPanelAllProjectsNavItem,
    ProjectsPanelTemplateNavItem,
  },
};
</script>
