import Vue from 'vue';

export default {
  ProjectHealth: {
    id: 1,
    name: () => Vue.t('Track project progress at a glance'),
    description: () => `<p>${Vue.t(
      "Get a bird's-eye view of all of your projects and see where each one stands, when they're due, and which clients they're for.",
    )}</p>
      <p style="padding-top: 25px">${Vue.t(
        'To get started with the Project Health Report, create some projects, and assign yourself as project owner to populate the report.',
      )}
      ${Vue.t(
        'To activate the reporting data, add start/due dates, and some estimated time to your project tasks.',
      )}</p>
        <p style="padding-top: 25px">${Vue.t(
          'Set a budget for each project, to show the usage percentage, amount of budget used, and the overall budget spend.',
        )}
        ${Vue.t(
          'Manually update the project health status with red, green, or amber to flag the overall project health.',
        )}</p>
        `,
    image: {
      normal:
        'https://twa-prod.teamwork.com/tko/public/images/product-tours/progress-at-glance.svg',
      width: '280px',
      height: '200px',
    },
    route: '/reports/health',
    matchIncludes: true,
    supportLink:
      'https://support.teamwork.com/projects/reports/using-project-health-reports',
  },
  Workload: {
    id: 2,
    name: () => Vue.t("Track and manage your team's capacity"),

    description: () => `<p>${Vue.t(
      "In the Workload Planner, each cell fills with an indigo color to show a team member's capacity for the day.",
    )}
      ${Vue.t(
        'The cell will turn red as a quick identifier that someone has exceeded their workload capacity.',
      )}
    </p>
              <p style="padding-top: 25px">${Vue.t(
                'Clicking on a cell will show you all of the tasks for that user on that day, as long as the task has due dates, estimated time, and an assignee.',
              )}</p>
            <p style="padding-top: 25px">${Vue.t(
              "Clicking on a user's name will give you a full breakdown of their capacity for their tasks across all of their projects. ",
            )}
              ${Vue.t(
                'Click and drag to alter date range, or reassign tasks to another team member.',
              )}
            </p>
            `,
    image: {
      normal:
        'https://twa-prod.teamwork.com/tko/public/images/product-tours/allocate-your-resources.svg',
      width: '280px',
      height: '200px',
    },
    route: '/planning/workload',
    matchIncludes: true,
    supportLink:
      'https://support.teamwork.com/projects/workload/using-the-workload-planner',
  },
};
