<template>
  <div class="w-80 flex flex-col rounded-md">
    <div class="flex flex-col items-stretch gap-2 overflow-hidden py-2">
      <LscDatePicker
        :date="value"
        @update:date="$emit('input', $event)"
        class="my-1"
        @monthYearMenuVisibilityChange="
          $emit('monthYearMenuVisibilityChange', $event)
        "
      />
      <div
        class="
          border-t-[#EDEFF5]
          flex flex-col
          items-stretch
          gap-2
          border-0 border-t border-solid
          px-3
          pb-2
          pt-4
        "
        v-if="computedShortcuts.length"
      >
        <div
          class="
            divide-[#EDEFF5]
            flex
            justify-center
            divide-x divide-y-0 divide-solid
          "
        >
          <button
            type="button"
            class="
              border-0
              text-xs text-primary
              px-3
              font-semibold
              bg-transparent
              outline-none
            "
            :key="index"
            v-for="(shortcut, index) in computedShortcuts"
            @click="
              setCustomDate(
                shortcut.span,
                shortcut.um,
                shortcut.relativeToToday,
              )
            "
          >
            {{ shortcut.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { defineComponent, computed } from 'vue-demi';
import { LscDatePicker } from '@fragments/index';

export default defineComponent({
  components: { LscDatePicker },
  props: {
    value: {
      default: null,
      validator: (prop) => prop === null || moment.isMoment(prop),
    },
    /*
      Example of shortcuts
      label: string;
      span: number; // number of days to jump to from the current
                      value, in case of undefined it will treat it as no date
      um: string; // defaults to `days` more: https://momentjs.com/docs/#/manipulating/add/
      relativeToToday: boolean; // Adds span to todays date (ie: Next monday)
      [{label: 'Today', span: 0 }, {label: 'None'}]
    */
    shortcuts: { type: Array, required: false, default: () => [] },
    showDefaultShortcuts: { type: Boolean, default: false },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const defaultShortcuts = computed(() => {
      const shortcuts = [
        { label: Vue.t('Today'), span: 0 },
        { label: Vue.t('+[0] Day', 1), span: 1 },
        { label: Vue.t('+[0] Days', 7), span: 7 },
      ];

      shortcuts.push({ label: Vue.t('None') });

      return shortcuts;
    });
    function setCustomDate(span, um = 'days', relativeToToday = false) {
      if (typeof span === 'undefined') {
        emit('input', null);
        return;
      }
      let date = moment(props.value);
      if (span) {
        // If this is relativeToToday, then set it to today+span (relative from today)
        date = relativeToToday ? moment().add(span, um) : date.add(span, um);
      } else {
        date = moment();
      }
      emit('input', date);
    }

    const computedShortcuts = computed(() => {
      if (props.shortcuts.length) {
        return props.shortcuts;
      }
      if (!props.showDefaultShortcuts) {
        return [];
      }

      return defaultShortcuts.value;
    });

    return {
      computedShortcuts,
      setCustomDate,
      defaultShortcuts,
    };
  },
});
</script>
