var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.modalQuickViews.length)?[_c('transition',{attrs:{"name":"fade"}},[(_vm.quickViews.length)?_c('div',{staticClass:"u-quick-view-container",class:{ 'u-quick-view-container__overlay': !_vm.isInLightspeed }}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"u-quick-view-container",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeTopQuickView.apply(null, arguments)}}},_vm._l((_vm.modalQuickViews),function(ref$1,i){
var name = ref$1.name;
var props = ref$1.props;
var ref = ref$1.ref;
return _c(name,_vm._b({key:("" + name + i),tag:"component",on:{"close":_vm.close}},'component',Object.assign({}, props, {name: props.name, ref: ref}),false))}),1)]:_vm._e(),_vm._v(" "),(_vm.panelQuickViews.length)?[_c('div',{attrs:{"id":"vueQuickViewPanels"}},_vm._l((_vm.panelQuickViews),function(ref$1,i){
var name = ref$1.name;
var props = ref$1.props;
var ref = ref$1.ref;
return _c(name,_vm._b({key:("" + name + i),tag:"component",on:{"close":_vm.close}},'component',Object.assign({}, props, {name: props.name, ref: ref}),false))}),1)]:_vm._e(),_vm._v(" "),(_vm.quickViews.length)?_c('global-events',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeTopQuickView.apply(null, arguments)}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }