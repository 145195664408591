export default {
  'task-details-quick-view': () =>
    import(
      /* webpackChunkName: "projectTasks" */ '@sections/QuickViews/TaskDetailsQuickView'
    ),
  'gantt-settings-quick-view': () =>
    import(
      /* webpackChunkName: "frequentModals" */ '@sections/QuickViews/GanttSettingsQuickView'
    ),
  'allocation-quick-view': () =>
    import(
      /* webpackChunkName: "allocationQuickView" */ '@sections/QuickViews/AllocationQuickView'
    ),
  'estimated-tasks-quick-view': () =>
    import(
      /* webpackChunkName: "estimatedTasksQuickView" */ '@sections/QuickViews/EstimatedTasksQuickView'
    ),
  'generic-tko-quick-view': () =>
    import(
      /* webpackChunkName: "genericTkoQuickView" */ '@sections/QuickViews/GenericTkoQuickView'
    ),
};
