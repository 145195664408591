/* eslint-disable lines-between-class-members */
import { inject, provide } from 'vue-demi';

/**
 * @typedef {Object} WebinarResult webinar result item
 * @property {number} duration
 * @property {string} webinarKey
 * @property {string} title
 * @property {string} description
 * @property {string} webinarStartTime
 * @property {string} webinarEndTime
 * @property {any[]} coorganizers
 * @property {any[]} products
 */

const useWebinarsApiSymbol = Symbol('useWebinarsApiSymbol');

function WebinarsApi({
  baseURL = 'https://websitebackend.teamwork.com/v1/webinars',
  timeout = 10000,
}) {
  const upcoming = new Map();

  function getOptions() {
    return {
      method: 'GET',
      signal: AbortSignal.timeout(timeout),
      redirect: 'follow',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        twProjectsVer: window.appVersionId ?? '',
      }),
    };
  }

  /**
   * Get upcoming webinars with a filter, with searches being cached by filter string
   * @param {string} [filterString=fundamentals] filter for webinars
   * @returns {Promise<WebinarResult>}
   */
  async function getUpcoming(filterString = 'fundamentals') {
    // cache searches by filter
    if (!upcoming.has(filterString)) {
      const url = new URL(baseURL);
      url.search = new URLSearchParams({ filters: filterString }).toString();

      return fetch(url, getOptions())
        .then((response) => response.json())
        .then((results) => {
          upcoming.set(filterString, results?.data);
          return upcoming.get(filterString);
        });
    }

    return Promise.resolve(upcoming.get(filterString));
  }

  return {
    getUpcoming,
  };
}

export function provideWebinarsApi() {
  provide(useWebinarsApiSymbol, WebinarsApi({}));
}

export function useWebinarsApi() {
  return inject(useWebinarsApiSymbol);
}
