import { computed } from 'vue-demi';
import { useCurrentUser } from '@teamwork/use';
import { useFeatures } from '@/platform/composables/useFeatures';

export default function useExperimentA15() {
  const EXP_A15_FLAG_KEY = 'projects-exp-a-23-15-worker-bee-log-time-ctas';
  const EXP_A15_COMMON_METRICS = [
    'account_id',
    'on_trial',
    'plan_name',
    'user_role',
    'page',
  ];
  const WORKER_BEE_ROLES = [5, 6];

  const { projectsWorkerBeeLogTimeCtaEnabled } = useFeatures();
  const user = useCurrentUser();

  const expA15AppLevelTargeting = computed(() =>
    WORKER_BEE_ROLES.includes(user.value?.companyRoleId),
  );
  const isExpA15Variation = computed(
    () =>
      projectsWorkerBeeLogTimeCtaEnabled.value && expA15AppLevelTargeting.value,
  );

  return {
    EXP_A15_FLAG_KEY,
    EXP_A15_COMMON_METRICS,
    projectsWorkerBeeLogTimeCtaEnabled,
    isExpA15Variation,
  };
}
