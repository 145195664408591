// The v-resizable directive facilitates resizing elements using pointer events.
// Usage:
// <div v-resizable> - Use defaultConfig.
// <div v-resizable="config"> - Override defaultConfig on a per-property basis.

const defaultConfig = {
  // Enable the top resize handle.
  top: false,
  // Enable the bottom resize handle.
  bottom: false,
  // Enable the left resize handle.
  left: false,
  // Enable the right resize handle.
  right: false,
  // Called when a user starts resizing the element.
  // Called with an object containing the following properties:
  // - element: Element - The DOM element which was resized.
  // - resizedFrom: 'top' | 'bottom' | 'left' | 'right' - The drag handle which triggered the call.
  onResizeStart() {},
  // Called when width and/or height need to change.
  // Called with an object containing the following properties:
  // - element: Element - The DOM element which was resized.
  // - resizedFrom: 'top' | 'bottom' | 'left' | 'right' - The drag handle which triggered the call.
  // - width: number - The new width that the element should have.
  // - height: number - The new height that the element should have.
  onResize() {},
  // Called when a user end resizing the element.
  // Called with an object containing the following properties:
  // - element: Element - The DOM element which was resized.
  // - resizedFrom: 'top' | 'bottom' | 'left' | 'right' - The drag handle which triggered the call.
  onResizeEnd() {},
};

const names = ['top', 'bottom', 'left', 'right'];
const stateKey = Symbol('v-resizable state key');

function resizeStart(handle) {
  const element = handle.parentNode;
  if (!element) {
    return;
  }
  const state = element[stateKey];
  if (!state) {
    return;
  }

  const resizedFrom = handle.dataset.name;
  state.onResizeStart({ element, resizedFrom });
}

function resize(handle) {
  const element = handle.parentNode;
  if (!element) {
    return;
  }
  const state = element[stateKey];
  if (!state || !state.pointer) {
    return;
  }

  const resizedFrom = handle.dataset.name;
  const { top, bottom, left, right } = element.getBoundingClientRect();
  const { clientX, clientY } = state.pointer;
  const l = resizedFrom === 'left' ? clientX : left;
  const r = resizedFrom === 'right' ? clientX : right;
  const t = resizedFrom === 'top' ? clientY : top;
  const b = resizedFrom === 'bottom' ? clientY : bottom;
  const width = Math.max(r - l, 0);
  const height = Math.max(b - t, 0);
  state.onResize({ element, resizedFrom, width, height });
}

function resizeEnd(handle) {
  const element = handle.parentNode;
  if (!element) {
    return;
  }
  const state = element[stateKey];
  if (!state) {
    return;
  }

  const resizedFrom = handle.dataset.name;
  state.onResizeEnd({ element, resizedFrom });
}

function onPointerDown(event) {
  const handle = event.target;
  const element = handle.parentNode;
  if (!element) {
    return;
  }
  const state = element[stateKey];
  if (!state || state.pointer) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  handle.setPointerCapture(event.pointerId);
  state.pointer = {
    id: event.pointerId,
    clientX: event.clientX,
    clientY: event.clientY,
    handlerScheduled: false,
  };
  requestAnimationFrame(() => {
    resizeStart(handle);
  });
}

function onPointerMove(event) {
  const handle = event.target;
  const element = handle.parentNode;
  if (!element) {
    return;
  }
  const state = element[stateKey];
  if (!state || !state.pointer || state.pointer.id !== event.pointerId) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  const { pointer } = state;
  pointer.clientX = event.clientX;
  pointer.clientY = event.clientY;
  if (!pointer.handlerScheduled) {
    pointer.handlerScheduled = true;
    requestAnimationFrame(() => {
      pointer.handlerScheduled = false;
      resize(handle);
    });
  }
}

function onPointerUp(event) {
  const handle = event.target;
  const element = handle.parentNode;
  if (!element) {
    return;
  }
  const state = element[stateKey];
  if (!state || !state.pointer || state.pointer.id !== event.pointerId) {
    return;
  }
  event.preventDefault();
  event.stopPropagation();
  state.pointer = null;
  requestAnimationFrame(() => {
    resizeEnd(handle);
  });
}

// Ensures that the specified resize handle exists in the element.
function ensureResizeHandle(element, name) {
  const state = element[stateKey];
  if (state[name]) {
    return;
  }

  const handle = document.createElement('div');
  handle.classList.add('v-resizable', `v-resizable--${name}`);
  handle.dataset.name = name;
  handle.style.position = 'absolute';
  handle.style.userSelect = 'none';
  handle.addEventListener('pointerdown', onPointerDown);
  handle.addEventListener('pointermove', onPointerMove);
  handle.addEventListener('pointerup', onPointerUp);
  handle.addEventListener('pointercancel', onPointerUp);
  element.appendChild(handle);
  state[name] = handle;

  switch (name) {
    case 'top':
      handle.style.top = '0';
      handle.style.left = '0';
      handle.style.width = '100%';
      handle.style.height = '4px';
      handle.style.cursor = 'ns-resize';
      break;
    case 'bottom':
      handle.style.bottom = '0';
      handle.style.left = '0';
      handle.style.width = '100%';
      handle.style.height = '4px';
      handle.style.cursor = 'ns-resize';
      break;
    case 'left':
      handle.style.top = '0';
      handle.style.left = '0';
      handle.style.width = '4px';
      handle.style.height = '100%';
      handle.style.cursor = 'ew-resize';
      break;
    case 'right':
      handle.style.top = '0';
      handle.style.right = '0';
      handle.style.width = '4px';
      handle.style.height = '100%';
      handle.style.cursor = 'ew-resize';
      break;
    default:
      break;
  }
}

// Ensures that the given resize handle does not exist in the element.
function removeResizeHandle(element, name) {
  const state = element[stateKey];
  const handle = state[name];
  if (!handle) {
    return;
  }
  state[name] = null;
  if (!handle.parentNode) {
    return;
  }
  handle.parentNode.removeChild(handle);
}

export default {
  bind(el, binding) {
    const element = el;
    const config = { ...defaultConfig, ...binding.value };
    const state = {
      top: null,
      bottom: null,
      right: null,
      left: null,
      pointer: null,
      onResizeStart: config.onResizeStart,
      onResize: config.onResize,
      onResizeEnd: config.onResizeEnd,
    };
    element[stateKey] = state;

    // Make sure element is "positioned",
    // so that our absolutely positioned resize handles will appear in the correct places.
    const style = window.getComputedStyle(element);
    if (style.position === 'static') {
      element.style.position = 'relative';
    }

    // Add resize handles.
    names.forEach((name) => {
      if (config[name]) {
        ensureResizeHandle(element, name);
      }
    });
  },

  update(el, binding) {
    const element = el;
    const config = { ...defaultConfig, ...binding.value };
    const state = element[stateKey];
    state.onResizeStart = config.onResizeStart;
    state.onResize = config.onResize;
    state.onResizeEnd = config.onResizeEnd;

    // Add/remove resize handles.
    names.forEach((name) => {
      if (config[name]) {
        ensureResizeHandle(element, name);
      } else {
        removeResizeHandle(element, name);
      }
    });
  },

  unbind(el) {
    const element = el;

    // Remove resize handles.
    names.forEach((name) => {
      removeResizeHandle(element, name);
    });

    element[stateKey] = null;
  },
};
