import cssManifest from '@tko/config/css-manifest.json';
import { provide, inject, ref } from 'vue-demi';
import cloneDeep from 'lodash/cloneDeep';
import legacyRoutes from '@/scaffolding/legacy-routes';
import globalJQuery from '@/scaffolding/global-jquery';
import tkoBodyId from '@/scaffolding/tko-body-id';
import {
  essentialDataIsLoaded,
  mapTkoGlobals,
} from '@/scaffolding/map-tko-globals';
import tkoLoader from '@/scaffolding/tko-loader';
import useRouter from '@/platform/composables/useRouter';
import useStore from '@/platform/composables/useStore';
import { useFeatures } from './useFeatures';
import { useLaunchDarkly } from './useLaunchDarkly';
import { usePreferences } from './usePreferences';

const hybridSymbol = Symbol('useHybrid');

function setInitialData(account, user, branding) {
  const USER_KEY =
    '/me.json?getDefaultViews=true&getDefaultFilters=true&fullprofile=1&getPreferences=1&cleanPreferences=1&getAccounts=1&includeAuth=1&includeClockIn=1&includeTeamIds=true';
  window.initialData = {
    'account.json': cloneDeep(account),
    [USER_KEY]: cloneDeep(user),
    'v/1/settings/branding.json': cloneDeep(branding),
  };

  try {
    window.defaultViews = window.initialData[USER_KEY].person.defaultViews;
    window.defaultFilters = window.initialData[USER_KEY].person.defaultFilters;
    window.validFilters = true;
  } catch (err) {
    window.validFilters = false;
    console.error(err);
  }
}

export function provideHybrid() {
  const router = useRouter();
  const store = useStore();
  const features = useFeatures();
  const launchDarkly = useLaunchDarkly();
  const preferences = usePreferences();

  const isHybridReady = ref(false);

  // Lazy load legacy TKO css
  ['legacy', 'main-compiledScss'].forEach((key) => {
    const link = document.createElement('link');

    link.href = window.devMode
      ? // eslint-disable-next-line no-undef, camelcase
        `${__webpack_public_path__}static/css/${key}.css`
      : // eslint-disable-next-line no-undef, camelcase
        `${__webpack_public_path__}static/css/${key}.${cssManifest[key]}.css`;

    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'all';

    document.getElementsByTagName('head')[0].appendChild(link);
  });

  async function loadHybrid(account, user, branding) {
    setInitialData(account, user, branding);

    store.dispatch('branding/ssr');
    store.dispatch('account/ssr');

    const { default: TKO } = await import(
      '@tko/src/app/models/teamworkProjects'
    );

    const app = new TKO({ features, launchDarkly, preferences });

    legacyRoutes(store, app.router, router);
    mapTkoGlobals(store);
    globalJQuery();
    tkoBodyId(store);
    tkoLoader();

    await essentialDataIsLoaded;
    isHybridReady.value = true;

    if (module.hot) {
      module.hot.accept(['@tko/src/app/models/teamworkProjects'], () => {
        window.location.reload();
      });
    }
  }

  provide(hybridSymbol, {
    isHybridReady,
    loadHybrid,

    async openQuickView(id, args = {}) {
      await essentialDataIsLoaded;
      store.dispatch('quickViews/tko/open', {
        id,
        ...args,
      });
    },

    async openModal(id, args) {
      await essentialDataIsLoaded;
      window.app.modal.Show(id, args);
    },
  });
}

export function useHybrid() {
  return inject(hybridSymbol);
}
