<template>
  <div ref="fileUploadRoot">
    <FileThumbnailGrid
      :task="task"
      :can-upload-file="canUploadFile"
      :can-add-files="canAddFiles"
      :is-uploading="isUploading"
      :is-collaborator="isCollaborator"
      @set-is-uploading="$emit('update:is-uploading')"
      @on-file-change="onFileChange"
      @select-existing-files="selectExistingFiles"
    />
    <FileDragArea
      v-if="!isDragAreaHidden"
      :is-comment-editor-showing="isCommentEditorShowing"
      :is-editing-description="isEditingDescription"
      @on-drop-files="onDropFiles"
    />
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import FileThumbnailGrid from './FileThumbnailGrid/FileThumbnailGrid.vue';
import FileDragArea from './FileDragArea/FileDragArea.vue';

export default {
  name: 'FileUpload',
  components: {
    FileThumbnailGrid,
    FileDragArea,
  },
  props: {
    task: { type: Object, required: true },
    isCommentEditorShowing: { type: Boolean, default: false },
    isEditingDescription: { type: Boolean, default: false },
    isUploading: { type: Boolean, default: false },
    isCollaborator: { type: Boolean, required: true },
    canAddFiles: { type: Boolean, required: true },
    canUploadFile: { type: Boolean, required: true },
  },
  setup(props) {
    const isDragAreaHidden = computed(() => {
      return props.isCollaborator || !props.canUploadFile;
    });

    function onFileChange(event) {
      if (!event.target.files) {
        return;
      }
      const files = [...event.target.files];
      this.$emit('upload-files', files);
    }
    function onDropFiles(event) {
      if (!event.dataTransfer.files.length) {
        return;
      }
      const files = [...event.dataTransfer.files];
      this.$emit('upload-files', files);
    }
    function selectExistingFiles() {
      this.$emit('select-existing-files');
    }

    return {
      isDragAreaHidden,
      onFileChange,
      onDropFiles,
      selectExistingFiles,
    };
  },
};
</script>

<style lang="scss" scoped></style>
