import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import TaskModel from 'taskModel';

const taskModels = new WeakMap();

/**
 * Returns a TKO TaskModel based on the specified task.
 * It does not modify the original task, as the returned TaskModel instance
 * is created based on a deep copy of the input task.
 * It's useful in situations where new Vue code must interact with old TKO code,
 * for example to open a TKO modal which requires a TaskModel instance as a parameter.
 * @param task The task for which to create a TaskModel instance.
 * @param cache Determines if TaskModel instances should be cached
 *  in a WeakMap using `task` as the key. Defaults to true.
 * @returns A new or cached TaskModel instance.
 */
export default function toTaskModel(task, cache = true) {
  let taskModel = cache ? taskModels.get(task) : undefined;

  if (!taskModel) {
    // Create a shallow copy of the task to avoid:
    // - modifying the original task when replacing moment instances
    // - creating deep copies of moment instances.
    let taskCopy = { ...task };

    // Replace moment instances with strings.
    if (moment.isMoment(taskCopy.createdAt)) {
      taskCopy.createdAt = taskCopy.createdAt.toISOString();
    }
    if (moment.isMoment(taskCopy.updatedAt)) {
      taskCopy.updatedAt = taskCopy.updatedAt.toISOString();
    }
    if (moment.isMoment(taskCopy.dateLastModified)) {
      taskCopy.dateLastModified = taskCopy.dateLastModified.toISOString();
    }
    if (moment.isMoment(taskCopy.startDate)) {
      taskCopy.startDate = taskCopy.startDate.format('YYYYMMDD');
    }
    if (moment.isMoment(taskCopy.dueDate)) {
      taskCopy.dueDate = taskCopy.dueDate.format('YYYYMMDD');
    }
    if (moment.isMoment(taskCopy.originalDueDate)) {
      taskCopy.originalDueDate = taskCopy.originalDueDate.format('YYYYMMDD');
    }
    if (moment.isMoment(taskCopy.completedOn)) {
      taskCopy.completedOn = taskCopy.completedOn.format('YYYYMMDD');
    }

    // Rename some properties.
    taskCopy = {
      ...taskCopy,
      canEdit: taskCopy.userPermissions.canEdit,
      canComplete: taskCopy.userPermissions.canComplete,
      canLogTime: taskCopy.userPermissions.canLogTime,
      canAddSubtasks: taskCopy.userPermissions.canAddSubtasks,
      canViewEstTime: taskCopy.userPermissions.canViewEstTime,
      numPredecessors: taskCopy.predecessorIds
        ? taskCopy.predecessorIds.length
        : 0,
      numActiveSubTasks: taskCopy.subTaskIds ? taskCopy.subTaskIds.length : 0,
      numAttachments: taskCopy.attachments ? taskCopy.attachments.length : 0,
      assignedTo: taskCopy.assignees.filter(
        ({ assigneeType }) => assigneeType === 'users',
      ),
      assignedToCompanies: taskCopy.assignees.filter(
        ({ assigneeType }) => assigneeType === 'companies',
      ),
      assignedToTeams: taskCopy.assignees.filter(
        ({ assigneeType }) => assigneeType === 'teams',
      ),
      numEstMins: taskCopy.estimateMinutes,
      numTotalMinutesLogged: taskCopy.timeTotals
        ? taskCopy.timeTotals.loggedMinutes
        : 0,
      lockdownId: taskCopy.lockdown ? taskCopy.lockdown.id : 0,
      isPrivate: taskCopy.isPrivate === 1,
      privacyIsInherited: taskCopy.isPrivate === 2,
      order: taskCopy.displayOrder,
      position: taskCopy.displayOrder,
      project: taskCopy.project,
      projectId: taskCopy.taskList.projectId,
      priority: taskCopy.priority || '',
      dateCreated: taskCopy.createdAt,
      dateChanged: taskCopy.updatedAt,
      dateLastModified: taskCopy.updatedAt,
      recurring: taskCopy.sequence
        ? { ...taskCopy.sequence, sequenceId: taskCopy.sequence.id }
        : null,
      changeFollowerIds: taskCopy.changeFollowers
        ? taskCopy.changeFollowers.join(',')
        : '',
      commentFollowerIds: taskCopy.commentFollowers
        ? taskCopy.commentFollowers.join(',')
        : '',
      customFields: taskCopy?.customfieldTasks.map(
        ({ value, customfieldId: customFieldId }) => ({
          value,
          customFieldId,
        }),
      ),
    };

    // Create a deep copy to ensure that TaskModel does not modify the original task in any way.
    taskModel = new TaskModel(cloneDeep(taskCopy));

    if (cache) {
      taskModels.set(task, taskModel);
    }
  }

  return taskModel;
}
