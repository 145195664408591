<template>
  <div class="w-full" v-if="canAccessTemplatesPermission">
    <a
      href="#/projects/templates/projects"
      class="
        flex
        items-center
        h-[38px]
        transition-colors
        duration-100
        bg-transparent
        hover:bg-palette-neutral-20/80
        rounded-full
        hover:text-text
        text-text text-[14px]
        font-semibold
      "
      @click="tryhideProjectsPanel"
    >
      <span class="flex-none flex items-center justify-center w-[45px]">
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7707 0.707031H2.229C1.36637 0.707031 0.666504 1.4069 0.666504 2.26953V13.7279C0.666504 14.5905 1.36637 15.2904 2.229 15.2904H15.7707C16.6333 15.2904 17.3332 14.5905 17.3332 13.7279V2.26953C17.3332 1.4069 16.6333 0.707031 15.7707 0.707031ZM1.70817 2.26953C1.70817 1.98307 1.94255 1.7487 2.229 1.7487H3.7915V3.83203H1.70817V2.26953ZM16.2915 13.7279C16.2915 14.0143 16.0571 14.2487 15.7707 14.2487H2.229C1.94255 14.2487 1.70817 14.0143 1.70817 13.7279V4.8737H16.2915V13.7279ZM16.2915 3.83203H4.83317V1.7487H15.7707C16.0571 1.7487 16.2915 1.98307 16.2915 2.26953V3.83203Z"
            fill="#8F9BB3"
          />
        </svg>
      </span>
      <span class="flex-auto">
        <span class="block w-full truncate">
          {{ $t('Project Templates') }}
        </span>
      </span>
    </a>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import useStore from '@/platform/composables/useStore';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

export default {
  name: 'ProjectsPanelTemplateNavItem',
  setup() {
    const store = useStore();
    const { tryhideProjectsPanel } = useSidebarPanels();

    const canAccessTemplatesPermission = computed(() => {
      return store.getters['permissions/canAccessProjectTemplatesTab'];
    });

    return {
      canAccessTemplatesPermission,
      tryhideProjectsPanel,
    };
  },
};
</script>
