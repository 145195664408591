export default {
  BudgetExpenseModal: () =>
    import('./BudgetExpenseModal/BudgetExpenseModal.vue'),
  BudgetTimelogsModal: () =>
    import('./BudgetTimelogsModal/BudgetTimelogsModal.vue'),
  HistoricalBudgetsModal: () =>
    import('./HistoricalBudgetsModal/HistoricalBudgetsModal.vue'),
  ProjectBudgetConflictModal: () =>
    import('./ProjectBudgetConflictModal/ProjectBudgetConflictModal.vue'),
  ProjectBudgetModal: () =>
    import('./ProjectBudgetModal/ProjectBudgetModal.vue'),
  ProjectBudgetRepeatingModal: () =>
    import('./ProjectBudgetRepeatingModal/ProjectBudgetRepeatingModal.vue'),
  ProjectBudgetSetEndDateModal: () =>
    import('./ProjectBudgetSetEndDateModal/ProjectBudgetSetEndDateModal.vue'),
  UpcomingBudgetsModal: () =>
    import('./UpcomingBudgetsModal/UpcomingBudgetsModal.vue'),
  TasklistBudgetsModal: () =>
    import('./TasklistBudgetsModal/TasklistBudgetsModal.vue'),
  ProjectBudgetDeleteBudgetModal: () =>
    import(
      './ProjectBudgetDeleteBudgetModal/ProjectBudgetDeleteBudgetModal.vue'
    ),
};
