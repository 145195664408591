var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"h-[40px] flex w-full rounded-full",attrs:{"role":"none"}},[_c(_vm.linkType,_vm._b({tag:"component",staticClass:"\n      group\n      flex\n      items-center\n      py-0\n      px-4\n      w-full\n      border-0\n      bg-transparent\n      rounded-full\n    ",class:[].concat( (_vm.isInActiveList
        ? [
            'bg-palette-indigo-10',
            'hover:bg-palette-indigo-10',
            'focus:bg-palette-indigo-10',
            'text-palette-indigo-40',
            'hover:text-palette-indigo-40' ]
        : [
            'bg-transparent',
            'hover:bg-palette-neutral-10',
            'focus:bg-palette-neutral-10',
            'text-text',
            'hover:text-text' ]) ),attrs:{"tabindex":"-1","role":"menuitem"}},'component',_vm.link,false),[_c('span',{staticClass:"\n        flex\n        items-center\n        justify-center\n        w-[20px]\n        h-[18px]\n        mr-200\n        flex-grow-0 flex-shrink-0\n      ",class:[
        _vm.isInActiveList
          ? '-ml-[3px] text-palette-indigo-40 fill-palette-indigo-40'
          : 'text-palette-neutral-40 fill-current' ]},[_vm._t("icon",function(){return [_vm._v("icon")]})],2),_vm._v(" "),_c('span',{staticClass:"flex items-center text-text"},[_vm._t("title",function(){return [_vm._v("title")]})],2),_vm._v(" "),_c('span',{staticClass:"ml-auto flex items-center"},[_vm._t("additional-icon")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }