<template>
  <div
    class="w-avatar"
    :class="[
      {
        'w-avatar--loaded': loaded,
      },
    ]"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      'background-image': 'url(//cdn.teamwork.com/images/noPhoto2.png)',
    }"
  >
    <img
      ref="avatar"
      :src="src"
      :alt="title"
      loading="lazy"
      v-img-load-fail="'userAvatar'"
    />
  </div>
</template>

<script>
export const defaultSize = 40;

export default {
  name: 'Avatar',
  props: {
    size: {
      type: Number,
      default: defaultSize,
    },
    src: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loaded: false,
  }),
  mounted() {
    const $avatar = this.$refs.avatar;
    if ($avatar instanceof HTMLElement) {
      $avatar.addEventListener('load', () => {
        this.loaded = true;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/_variables';

.w-avatar {
  border-radius: 100%;
  background-size: 100%;
  overflow: hidden;
  background-color: #8c99a2;

  img {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $transition-duration--default;
  }

  &--loaded {
    img {
      opacity: 1;
    }
  }
}
</style>
