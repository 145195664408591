<template>
  <LogTimeButton
    :button-classes="[
      buttonProps.class,
      'direction-right',
      { 'is-active': loggedTime !== '0h' },
    ]"
    @click.native="timerToggle"
    :icon="buttonProps.icon"
    :tooltip="buttonProps.tooltip"
  />
</template>
<script>
import { ref, computed, onMounted, onUnmounted } from 'vue-demi';
import { useRealTimeUpdates } from '@teamwork/use';
import LogTimeButton from '@widgets/TaskDetailWidgets/LogTimeButton';
import toTaskModel from '@/utils/helpers/toTaskModel';
import {
  TIMER_STATUSES,
  getTimerStateProps,
  getTimerByTaskId,
} from '@/utils/helpers/timer';

import useExperimentA15 from '@/platform/composables/useExperimentA15';
import usePendo from '@/platform/composables/usePendo';

export default {
  name: 'LogTimeTimer',
  props: {
    task: { type: Object, required: true },
    elapsedTime: { type: Number, required: true },
    timerState: { type: String, required: true },
    loggedTime: { type: String, required: true },
  },
  components: {
    LogTimeButton,
  },
  setup(props, { emit }) {
    const { STARTED, PAUSE, RESUME, PLAY, EDITED, COMPLETE, DELETED } =
      TIMER_STATUSES;

    const { EXP_A15_COMMON_METRICS } = useExperimentA15();
    const { trackPendoEvent } = usePendo();

    const timer = ref(undefined);

    const elapsedTime = computed({
      get() {
        return props.elapsedTime;
      },
      set(val) {
        emit('update:elapsedTime', val);
      },
    });

    const timerState = computed({
      get() {
        return props.timerState;
      },
      set(val) {
        emit('update:timerState', val);
      },
    });

    const buttonProps = computed(() => {
      return getTimerStateProps(timerState.value);
    });

    function start() {
      if (timerState.value === PAUSE) {
        return;
      }
      timerState.value = PAUSE;
      timer.value = setInterval(() => {
        elapsedTime.value += 1;
      }, 1000);
    }

    function stop() {
      if (timerState.value === RESUME) {
        return;
      }
      timerState.value = RESUME;
      clearInterval(timer.value);
    }

    function timerToggle() {
      if (timerState.value === PAUSE) {
        stop();

        trackPendoEvent({
          eventName: 'TABLE_VIEW_EVENT',
          commonMetrics: EXP_A15_COMMON_METRICS,
          metadata: {
            event_action: 'pause_timer_clicked',
            event_source: 'task_row',
          },
        });
      } else {
        if (timerState.value === PLAY) {
          trackPendoEvent({
            eventName: 'TABLE_VIEW_EVENT',
            commonMetrics: EXP_A15_COMMON_METRICS,
            metadata: {
              event_action: 'start_timer_clicked',
              event_source: 'task_row',
            },
          });
        } else {
          trackPendoEvent({
            eventName: 'TABLE_VIEW_EVENT',
            commonMetrics: EXP_A15_COMMON_METRICS,
            metadata: {
              event_action: 'resume_timer_clicked',
              event_source: 'task_row',
            },
          });
        }

        start();
      }

      toTaskModel(props.task).ToggleTimer();
    }

    function resetTimer() {
      timerState.value = PLAY;
      elapsedTime.value = 0;
      clearInterval(timer.value);
    }

    function updateTimerStats(state, oldState) {
      if (state === PAUSE) {
        stop();
      }
      if (state === RESUME) {
        start();
      }
      if (state === STARTED) {
        start();
      }
      if (state === COMPLETE) {
        resetTimer();
      }
      if (state === DELETED) {
        resetTimer();
      }
      if (state === EDITED) {
        if (oldState === 'resume') {
          start();
        }
      }
    }

    onMounted(() => {
      updateTimerStats(timerState.value);
    });

    onUnmounted(() => {
      clearInterval(timer.value);
    });

    useRealTimeUpdates((event, details) => {
      const allowedActions = [
        PAUSE,
        RESUME,
        STARTED,
        COMPLETE,
        DELETED,
        EDITED,
      ];
      if (allowedActions.includes(event.action) && event.type === undefined) {
        const { timerId, currentElapsedTime, currentTimeState } =
          getTimerByTaskId(props.task.id);
        const timerTaskId = parseInt(
          details.eventInfo.extraInfo?.data?.taskId,
          10,
        );

        elapsedTime.value = currentElapsedTime;

        // reset timer if task or project change
        if (event.action === EDITED && timerId === 0) {
          resetTimer();
        }

        if (timerTaskId === props.task.id) {
          updateTimerStats(event.action, currentTimeState);
        }
      }
    });

    return {
      buttonProps,
      timerToggle,
    };
  },
};
</script>
