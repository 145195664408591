import { v2Url } from '@teamwork/fetcher';
import { useItemLoader, useRealTimeUpdates } from '@teamwork/use';
import moment from 'moment';
import { computed, unref } from 'vue-demi';

function responseToItem({ data: { project } }) {
  /* eslint-disable no-param-reassign */
  project.createdOn = moment(project.createdOn);
  project.lastChangeOn = moment(project.lastChangeOn);

  const { minMaxAvailableDates } = project;
  if (minMaxAvailableDates) {
    minMaxAvailableDates.maxEndDate = moment(
      minMaxAvailableDates.maxEndDate,
      'YYYYMMDD',
    );
    minMaxAvailableDates.minStartDate = moment(
      minMaxAvailableDates.minStartDate,
      'YYYYMMDD',
    );
    minMaxAvailableDates.suggestedEndDate = moment(
      minMaxAvailableDates.suggestedEndDate,
      'YYYYMMDD',
    );
    minMaxAvailableDates.suggestedStartDate = moment(
      minMaxAvailableDates.suggestedStartDate,
      'YYYYMMDD',
    );
  }
  /* eslint-enable no-param-reassign */
  return project;
}

/**
 * Loads a single project by an ID of a project or any item within a project.
 * The item type and ID can be provided using either:
 * - `itemType` and `itemId` OR
 * - one of the supported type-specific properties, eg projectId, taskId, etc.
 *
 * Note: For now we load the current project using a v2 API for compatibility with Vuex and TKO,
 * and because the v3 API endpoint does not yet support loading a project by taskId, etc.
 */
export default function useProjectLoader({
  projectId: _projectId,
  taskId: _taskId,
  tasklistId: _tasklistId,
  notebookId: _notebookId,
  fileId: _fileId,
  linkId: _linkId,
  messageId: _messageId,
  milestoneId: _milestoneId,
  itemType: _itemType,
  itemId: _itemId,
  params,
}) {
  const itemType = computed(() => {
    if (unref(_itemType)) {
      return unref(_itemType);
    }
    if (unref(_projectId)) {
      return 'project';
    }
    if (unref(_taskId)) {
      return 'task';
    }
    if (unref(_tasklistId)) {
      return 'tasklist';
    }
    if (unref(_notebookId)) {
      return 'notebook';
    }
    if (unref(_fileId)) {
      return 'file';
    }
    if (unref(_linkId)) {
      return 'link';
    }
    if (unref(_messageId)) {
      return 'message';
    }
    if (unref(_milestoneId)) {
      return 'milestone';
    }
    return undefined;
  });

  const itemId = computed(() => {
    if (unref(_itemType)) {
      return unref(_itemId);
    }
    if (unref(_projectId)) {
      return unref(_projectId);
    }
    if (unref(_taskId)) {
      return unref(_taskId);
    }
    if (unref(_tasklistId)) {
      return unref(_tasklistId);
    }
    if (unref(_notebookId)) {
      return unref(_notebookId);
    }
    if (unref(_fileId)) {
      return unref(_fileId);
    }
    if (unref(_linkId)) {
      return unref(_linkId);
    }
    if (unref(_messageId)) {
      return unref(_messageId);
    }
    if (unref(_milestoneId)) {
      return unref(_milestoneId);
    }
    return undefined;
  });

  const url = computed(() => {
    const type = itemType.value;
    const id = itemId.value;
    if (type && Number.isSafeInteger(id) && id > 0) {
      if (type === 'project') {
        return v2Url(`projects/${id}`);
      }
      if (type === 'task') {
        return v2Url(`tasks/${id}/project`);
      }
      if (type === 'tasklist') {
        return v2Url(`tasklists/${id}/project`);
      }
      if (type === 'notebook') {
        return v2Url(`notebooks/${id}/project`);
      }
      if (type === 'file') {
        return v2Url(`files/${id}/project`);
      }
      if (type === 'link') {
        return v2Url(`links/${id}/project`);
      }
      if (type === 'message') {
        return v2Url(`messages/${id}/project`);
      }
      if (type === 'milestone') {
        return v2Url(`milestones/${id}/project`);
      }
    }
    return undefined;
  });

  const { state, refresh } = useItemLoader({
    url,
    params,
    responseToItem,
  });

  useRealTimeUpdates((event) => {
    if (event.type === 'project') {
      if (
        // If the project which we have loaded has changed, we have to refresh.
        state.item.value?.id === event.projectId ||
        // If any project changed and we could not find the requested project,
        // we have to refresh because now there is a chance that we will find it.
        state.item.value === null ||
        // If we have not finished loading a project but we are still trying,
        // we must refresh to avoid loading stale data and
        // because loading might successed now, if we previously got an error.
        (state.item.value === undefined && !state.inSync.value)
      ) {
        refresh();
      }
    }
  });

  return state;
}
