import Vue from 'vue';
import typesConfig from './TypesConfig';

// @vue/component
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    loadingText: {
      type: String,
      default: '',
    },
    showText: {
      type: Boolean,
      default: true,
    },
    useDelay: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    text: ({ loadingText, type }) =>
      loadingText ||
      (type && `Loading ${typesConfig[type]}`) ||
      Vue.t('Loading'),
    classSettings() {
      return {
        'w-preloading--delayed': this.useDelay,
        'w-preloading--inline': this.format === 'inline',
      };
    },
  },
};
