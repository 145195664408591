import { useCurrentUser } from '@teamwork/use';
import { provide, inject, computed } from 'vue-demi';

const useLocalizationSymbol = Symbol('useLocalization');

function LocalizationService() {
  const user = useCurrentUser();
  return {
    ellipsis: computed(() => user.value?.localization.ellipsis ?? '...'),
    languageCode: computed(
      () => user.value?.localization.languageCode ?? window.navigator.language,
    ),
    timezoneName: computed(
      () => user.value?.localization.timezoneJavaRefCode ?? '',
    ),
    timeFormat: computed(() =>
      user.value?.localization.timeFormat
        .replace('tt', 'A')
        .replace(/hh:mm/i, 'HH:mm'),
    ),
    dateFormat: computed(() =>
      user.value?.localization.dateFormat
        .replace('dd', 'DD')
        .replace('mm', 'MM'),
    ),
    weekStartsOnSunday: computed(() =>
      user.value?.localization['start-on-sunday'] ? 'week' : 'isoweek',
    ),
  };
}

export function provideLocalization() {
  const localization = LocalizationService();
  provide(useLocalizationSymbol, localization);
  return localization;
}

/**
 *
 * @returns {LocalizationService}
 */
export function useLocalization() {
  return inject(useLocalizationSymbol);
}
