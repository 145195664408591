<template>
  <div class="timer-inputs" :class="{ 'timer-inputs--inline': isInline }">
    <div
      class="timer-inputs__inputs"
      data-identifier="task-log-time-time-spent-input"
    >
      <div class="timer-inputs__input-with-label mr--large">
        <input
          ref="hrsInput"
          v-mask="['##']"
          class="timer-inputs__input"
          :class="{ error: $v.hours.$invalid }"
          placeholder="0"
          :value="hours"
          @input="$emit('update:hours', Number($event.target.value))"
        />
        <label class="timer-inputs__label">
          {{ $t('hours').charAt(0) }}
        </label>
      </div>
      <div class="timer-inputs__input-with-label mr--large">
        <input
          v-mask="['##']"
          class="timer-inputs__input"
          :class="{ error: $v.minutes.$invalid }"
          placeholder="0"
          :value="minutes"
          @input="$emit('update:minutes', Number($event.target.value))"
        />
        <label class="timer-inputs__label">
          {{ $t('minutes').charAt(0) }}
        </label>
      </div>
    </div>

    <div v-if="acceleratorOptions.length > 0" class="flex gap-4 mb-4">
      <button
        v-for="({ label }, index) in acceleratorOptions"
        :key="index"
        class="
          flex
          items-center
          justify-center
          border border-solid
          rounded-md
          h-10
          w-16
          transition-colors
        "
        :class="{
          'border-[#4461D7] bg-[#D8E0FF]': selectedAcceleratorIndex === index,
          'border-[#C5CAE0] hover:border-[#64697D] bg-white':
            selectedAcceleratorIndex !== index,
        }"
        type="button"
        @click="handleAcceleratorButtonClicked(index)"
      >
        <span class="text-xs font-semibold">{{ label }}</span>
      </button>
    </div>

    <div
      class="timer-inputs__checkbox"
      :class="{ 'timer-inputs__checkbox--inline': isInline }"
      data-identifier="task-log-time-billable-input"
    >
      <Checkbox
        :label="$t('Billable')"
        @input="(checked) => $emit('update:isBillable', checked)"
        :value="isBillable"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue-demi';
import { required, between, numeric } from 'vuelidate/lib/validators';
import Checkbox from '@widgets/Checkbox';

export default {
  name: 'TimerInputs',
  components: {
    Checkbox,
  },
  props: {
    minutes: {
      type: Number,
      default: 0,
    },
    hours: {
      type: Number,
      default: 0,
    },
    isBillable: {
      type: Boolean,
      default: true,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    isFormValid: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    acceleratorOptions: {
      type: Array,
      default: () => [],
      validator: (prop) =>
        prop.every(
          (p) => Number.isInteger(p.hours) && Number.isInteger(p.minutes),
        ),
    },
  },
  setup(props) {
    const selectedAcceleratorIndex = ref(-1);

    function handleAcceleratorButtonClicked(index) {
      selectedAcceleratorIndex.value = index;

      this.$emit('update:minutes', props.acceleratorOptions[index].minutes);
      this.$emit('update:hours', props.acceleratorOptions[index].hours);
    }

    if (props.acceleratorOptions.length) {
      watch(
        () => props.hours,
        (hours) => {
          const foundAcceleratorIndex = props.acceleratorOptions.findIndex(
            (option) =>
              option.hours === hours && option.minutes === props.minutes,
          );

          selectedAcceleratorIndex.value = foundAcceleratorIndex;
        },
        { immediate: true },
      );

      watch(
        () => props.minutes,
        (minutes) => {
          const foundAcceleratorIndex = props.acceleratorOptions.findIndex(
            (option) =>
              option.hours === props.hours && option.minutes === minutes,
          );

          selectedAcceleratorIndex.value = foundAcceleratorIndex;
        },
        { immediate: true },
      );
    }

    return {
      selectedAcceleratorIndex,
      handleAcceleratorButtonClicked,
    };
  },
  computed: {
    isValid() {
      return !this.$v.$invalid && !(this.minutes === 0 && this.hours === 0);
    },
  },
  mounted() {
    if (this.autofocus && this.$refs.hrsInput) {
      setTimeout(() => {
        this.$refs.hrsInput.focus();
      }, 350);
    }
  },
  watch: {
    isValid(newVal) {
      this.$emit('update:isFormValid', newVal);
    },
  },
  validations: {
    hours: {
      required,
      numeric,
      between: between(0, 99),
    },
    minutes: {
      required,
      numeric,
      between: between(0, 59),
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

$font-size--base: 14px;
$input-height: 32px;
$error-color: #e02020;
$inputs-margin-bottom: 16px;

.timer-inputs {
  display: flex;

  flex-direction: column;
  &--inline {
    flex-direction: row;
  }

  &__inputs {
    display: flex;
    margin-bottom: $inputs-margin-bottom;
  }

  &__input-with-label {
    display: flex;
    align-items: center;
  }

  &__input {
    height: $input-height;
    font-size: $font-size--base;
    background-color: $neutral-20;
    margin-right: $margin--small;
    padding: 0 12px;
    font-weight: normal;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    width: 49px;
    text-align: right;

    &[type='number'] {
      appearance: none;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    &.error {
      border: 1px solid $error-color;
    }

    &:focus {
      outline: none;
      border: 1px solid $indigo-30;
      &.error {
        border: 1px solid $error-color;
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: $inputs-margin-bottom;

    &--inline {
      margin-left: 8px;
    }
  }

  &__label {
    margin-bottom: 0;
    font-weight: 600;
    color: #0b0e1f;

    &--checkbox {
      font-size: 14px;
      font-weight: 400;
      color: black;
      user-select: none;
      margin-left: 8px;
    }
  }
}
</style>
