// loadingMode spec
// - available values
//  > expand-right, expand-left
//  > expand-top, expand-bottom
//  > zoom-in, zoom-out
// - modifiers for specifying gap/padding between label and spinner
//  > expand-*--small = 20px
//  > expand-*--medium = 32px
//  > expand-*--large = 54px
//  > expand-*--xlarge = 82px

// @vue/component
export default {
  props: {
    disabled: { type: Boolean, default: false },
    type: { type: String, default: 'button' },
    id: { type: String, default: '' },
    variant: { type: String, default: 'default' },
    size: { type: String, default: 'small' },
    loading: { type: Boolean, default: false },
    loadingMode: { type: String, default: 'expand-right' },
    loadingPercent: { type: Number, default: 0 },
  },
  computed: {
    loadingClasses() {
      if (!this.loading) {
        return [];
      }
      const mode = this.loadingMode;
      const type = mode.split('-')[0];
      const direction = mode.split('--')[0];
      return [
        'w-loader',
        'w-loader--open',
        `w-loader--${type}`,
        `w-loader--${direction}`,
        {
          [`w-loader--${mode}`]: direction !== mode,
        },
      ];
    },
  },
};
