import vTooltipConfig from '@/utils/plugins/config/v-tooltip';

// @vue/component
export default {
  props: {
    noFlip: { type: Boolean, default: false },
    noCloseOther: { type: Boolean, default: false },
    noArrow: { type: Boolean, default: false },
    slim: { type: Boolean, default: false },
    placement: { type: String, default: '' },
    boundaries: {
      type: [String, Object, HTMLBodyElement, HTMLElement],
      default: null,
    },
    popoverClass: { type: String, default: '' },
    handleResize: { type: Boolean, default: true },
    offset: { type: [String, Number], default: 0 },
    hasPendoGuideTriggerInside: { type: Boolean, default: false },
  },
  data() {
    return {
      id: null,
      pendoGuideObserver: null,
    };
  },

  computed: {
    popoverArrowClass: (vm) =>
      vm.noArrow ? 'hidden' : vTooltipConfig.popover.defaultArrowClass,
    isOpen: {
      get(vm) {
        return vm.$store.state.popover.instances[vm.id];
      },
      set(newVal) {
        this.$emit('update:open', newVal);
      },
    },
    popperOptions: (vm) => ({
      modifiers: {
        flip: { enabled: !vm.noFlip },
      },
    }),
    popClass() {
      return `${this.popoverClass} ${this.slim ? 'w-popover--slim' : ''}`;
    },
    isLocked() {
      return this.$store.state.popover.locked.includes(Number(this.id));
    },
    autoHide() {
      return !this.isLocked;
    },
  },

  async mounted() {
    this.id = await this.$store.dispatch('popover/addInstance');

    if (this.$refs.popoverContainer) {
      // https://github.com/Akryum/v-tooltip/issues/325#issuecomment-602044408
      this.$watch(
        () => this.$refs.popoverContainer.isOpen,
        async (isOpen) => {
          if (isOpen) {
            await this.$nextTick();
            this.$emit('show-transitionend');
            this.$emit('opened');
          }
        },
      );
    }

    if (this.hasPendoGuideTriggerInside) {
      this.pendoGuideObserver = new MutationObserver((mutations) => {
        mutations.forEach(({ addedNodes, removedNodes }) => {
          const targetId = 'pendo-base';

          if (addedNodes && addedNodes.length) {
            addedNodes.forEach(({ id }) => {
              if (id === targetId) {
                this.$store.dispatch('popover/lock', this.id);
              }
            });
          }

          if (removedNodes && removedNodes.length) {
            removedNodes.forEach(({ id }) => {
              if (id === targetId) {
                this.$store.dispatch('popover/unlock', this.id);
              }
            });
          }
        });
      });

      this.pendoGuideObserver.observe(document.body, { childList: true });
    }
  },

  beforeDestroy() {
    if (this.pendoGuideObserver instanceof MutationObserver) {
      this.pendoGuideObserver.disconnect();
    }
    this.$store.commit('popover/removeInstance', this.id);
  },

  methods: {
    show() {
      if (this.isLocked) {
        return;
      }
      this.$store.commit('popover/show', {
        id: this.id,
        noCloseOther: this.noCloseOther,
      });
      this.$emit('show');
    },

    setupHoverCatch() {
      // If this popover triggers by hover, it should not close
      // if the user moves the mouse over the content.
      if (this.$attrs.trigger && this.$attrs.trigger.includes('hover')) {
        const contentEl = this.$refs.popoverContainer.$refs.popover;
        if (contentEl && !contentEl.onmouseenter) {
          contentEl.addEventListener('mouseenter', this.show);
          contentEl.addEventListener('mouseleave', this.hide);
        }
      }
    },

    hide() {
      if (this.isLocked) {
        return;
      }

      this.$store.commit('popover/hide', this.id);
      this.$emit('hide');
    },

    toggle() {
      if (this.isLocked) {
        return;
      }
      this.$store.commit('popover/toggle', this.id);
    },

    applyShow() {
      this.setupHoverCatch();
    },
  },
};
