<template>
  <Popover
    :slim="true"
    :trigger="'hover'"
    :no-arrow="true"
    placement="top"
    :container="container"
    :no-close-other="true"
    class="w-header-helper__trigger"
  >
    <common-icon id="info-circle" color="#8A8D99" class="ml--small" />
    <template #popover>
      <div class="w-header-helper__popover">
        {{ text }}
      </div>
    </template>
  </Popover>
</template>

<script>
import Popover from '@widgets/Popover';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';

export default {
  components: {
    Popover,
    CommonIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    container: {
      type: String,
      required: false,
      default: 'body',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';

.w-header-helper {
  &__trigger {
    display: inline;

    .w-svg-sprite {
      vertical-align: middle;
    }
  }

  &__popover {
    padding: $padding--small $padding--medium;
    color: white;
    background: black;
    border-radius: $border-radius--default;
    max-width: 225px;
    box-shadow: 0px 0px 12px rgba(11, 14, 31, 0.08);
    font-weight: normal;
    font-size: $font-size--small;
    line-height: 20px;
  }
}
</style>
