<template>
  <PopoverMenuItem route="/settings/subscription" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="14"
        viewBox="0 0 18 14"
      >
        <path
          d="M15.941.517H2.059A1.39 1.39 0 0 0 .667 1.906V12.09a1.39 1.39 0 0 0 1.391 1.389h13.884a1.39 1.39 0 0 0 1.391-1.39V1.907a1.39 1.39 0 0 0-1.391-1.39ZM2.232 1.906h13.536c.095 0 .173.078.173.173v1.215H2.059V2.08c0-.095.079-.173.174-.173ZM15.768 12.09H2.232a.174.174 0 0 1-.174-.174V6.998h13.884v4.92a.174.174 0 0 1-.174.173ZM6.222 9.197v1.158a.348.348 0 0 1-.347.347H3.792a.348.348 0 0 1-.348-.347V9.197c0-.19.157-.347.348-.347h2.083c.191 0 .347.156.347.347Zm5.556 0v1.158a.348.348 0 0 1-.348.347H7.495a.348.348 0 0 1-.347-.347V9.197c0-.19.156-.347.347-.347h3.936c.19 0 .347.156.347.347Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Subscription') }}
    </template>
  </PopoverMenuItem>
</template>

<script>
import PopoverMenuItem from './PopoverMenuItem.vue';

export default {
  components: {
    PopoverMenuItem,
  },
};
</script>

<style></style>
