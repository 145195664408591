<template>
  <div
    :class="[
      'collapse',
      {
        collapsed: !isOpen,
        partial: isPartial,
        clickable: hasOpenListener,
      },
    ]"
  >
    <div
      class="collapse__body"
      :style="{ maxHeight }"
      @transitionstart.self="onTransitionStart"
      @transitionend.self="onTransitionEnd"
    >
      <div class="collapse__slot" v-resize.immediate.border-box="onResize">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
/*
PROPS
  isOpen: Toggles the visibility of the its children components
  collapsedHeight: The min height of the body when closed
*/
import resize from '@/utils/directives/resize';

// @vue/component
export default {
  name: 'CollapsePanel',
  directives: {
    resize,
  },
  props: {
    isOpen: { type: Boolean, default: false },
    collapsedHeight: { type: String, default: '0' },
  },
  data() {
    return {
      contentHeight: 0,
    };
  },
  computed: {
    maxHeight() {
      return this.isOpen ? `${this.contentHeight}px` : this.collapsedHeight;
    },
    isPartial() {
      return this.collapsedHeight !== '0';
    },
    hasOpenListener() {
      return this.$listeners && this.$listeners.click;
    },
  },
  methods: {
    onResize({ element }) {
      this.contentHeight = element.offsetHeight;
    },
    onTransitionStart(event) {
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.style.overflow = 'hidden';
    },
    onTransitionEnd(event) {
      if (this.isOpen) {
        // eslint-disable-next-line no-param-reassign
        event.currentTarget.style.overflow = 'visible';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse {
  &__body {
    will-change: max-height;
    overflow: hidden;
    transition: max-height 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  }

  &.partial {
    position: relative;

    &.collapsed:after {
      content: '';
      background: #fff;
      background: -webkit-linear-gradient(
        to top,
        #fff 0%,
        rgba(255, 255, 255, 0.3) 120%
      );
      background: linear-gradient(
        to top,
        #fff 0%,
        rgba(255, 255, 255, 0.3) 120%
      );
      bottom: 0;
      left: 0;
      right: 0;
      height: 30px;
      position: absolute;
    }

    &.collapsed {
      .collapse__body {
        pointer-events: none;
      }

      &.clickable {
        cursor: pointer;
      }
    }
  }
}
</style>
