<template>
  <div class="flex-auto flex flex-col overflow-scroll">
    <section
      class="
        flex flex-col
        items-start
        justify-between
        select-none
        w-full
        px-6
        gap-2
        mb-2
        flex-auto
      "
    >
      <div class="w-full">
        <HelpCenterGettingStartedSiteNavItem />
        <ResourcesListNavItem />
      </div>

      <div class="w-full pb-8">
        <HelpCenterMenuItem
          v-for="item in footerMenuItems"
          :key="item.label"
          :is-button="item.isButton"
          :label="item.label"
          :icon="item.icon"
          :href="item.url"
          :target="item.target"
          @click="$emit('menuItemClicked', item.id)"
        />
      </div>
    </section>
  </div>
</template>
<script>
import HelpCenterMenuItem from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenuItem.vue';
import ResourcesListNavItem from '@/platform/components/side-nav/panels/help-center/nav-items/ResourcesListNavItem.vue';
import HelpCenterGettingStartedSiteNavItem from '@/platform/components/side-nav/panels/help-center/nav-items/HelpCenterGettingStartedNavItem.vue';

export default {
  name: 'HelpCenterDefaultState',
  components: {
    HelpCenterMenuItem,
    ResourcesListNavItem,
    HelpCenterGettingStartedSiteNavItem,
  },
  data() {
    return {
      footerMenuItems: [
        {
          id: 'apps',
          label: 'Apps',
          icon: 'apps',
          url: 'https://integrations.teamwork.com/',
          isButton: false,
          target: '_blank',
        },
        {
          id: 'keyboardShortcuts',
          label: 'Keyboard Shortcuts',
          icon: 'keyboard',
        },
        {
          id: 'browseOurSupportWebsite',
          label: 'Browse Our Support Website',
          icon: 'website',
          url: 'https://www.teamwork.com/support/',
          isButton: false,
          target: '_blank',
        },
      ],
    };
  },
};
</script>
