import Multiselect from 'vue-multiselect';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import groupBy from 'lodash/groupBy';
import { ObserveVisibility } from 'vue-observe-visibility';

// @vue/component
export default {
  components: { Multiselect, CommonIcon },
  directives: { ObserveVisibility },
  props: {
    options: { type: Array, default: () => [] },
    value: { type: [Array, Object, Number, String], default: undefined },
    label: { type: String, default: 'name' },
    trackBy: { type: String, default: 'id' },
    placeholder: { type: String, default: undefined },
    groupId: { type: String, default: undefined },
    groupLabel: { type: String, default: undefined },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    canLoadMore: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    internalSearch: { type: Boolean, default: false },
    id: { type: String, default: undefined },
    multiple: { type: Boolean, default: false },
    allowEmpty: { type: Boolean, default: false },
    closeOnSelect: { type: Boolean, default: true },
    openDirection: { type: String, default: '' },
    forceOpen: { type: Boolean, default: false },
    nameOnHover: { type: Boolean, default: false },
    dataIdentifier: { type: String, default: '' },
  },
  data: () => ({
    isFirstOpen: true,
  }),
  computed: {
    groupedOptions: (vm) => {
      if (!vm.groupId || !vm.groupLabel) {
        return vm.options;
      }
      const grouped = groupBy(vm.options, (opt) => opt[vm.groupId] || '0');
      const options = [];
      Object.keys(grouped).forEach((gid) => {
        const opts = grouped[gid];
        if (gid !== '0') {
          options.push({ $isLabel: true, $groupLabel: opts[0][vm.groupLabel] });
        }
        options.push(...opts);
      });
      return options;
    },
    templatesToCopy: (vm) =>
      ['singleLabel'].filter((name) => vm.$scopedSlots[name]),
    listenersForSelect: ({ $listeners: { open, close, ...rest } }) => rest,
  },
  methods: {
    open() {
      const ms = this.$refs.multiselect;
      if (ms && this.isFirstOpen) {
        // https://github.com/shentao/vue-multiselect/issues/1022
        this.$nextTick().then(() => ms.pointerReset());
        this.isFirstOpen = false;
      }
      this.positionDropdown();
      this.$emit('open');
    },
    close() {
      this.$emit('close');
    },
    loadMoreVisible(isVisible) {
      if (isVisible && !this.loading) {
        this.$emit('load-more');
      }
    },
    positionDropdown() {
      const ms = this.$refs.multiselect;
      if (!ms) {
        return;
      }
      const { list, tags } = ms.$refs;
      const { offsetWidth } = ms.$el;
      list.style.width = `${offsetWidth}px`;
      list.style.position = 'fixed';
      // Draggable and popover has transform3d which makes position:fixed relative to it
      // So we need to use its top/bottom when calculating positions
      const drag =
        ms.$el.closest('.w-draggable') || ms.$el.closest('.w-popover');
      if (ms.isAbove) {
        const offset = drag
          ? drag.getBoundingClientRect().bottom
          : window.innerHeight;
        list.style.bottom = `${offset - tags.getBoundingClientRect().top}px`;
        list.style.top = 'auto';
      } else {
        const offset = drag ? drag.getBoundingClientRect().top : 0;
        list.style.top = `${tags.getBoundingClientRect().bottom - offset}px`;
        list.style.bottom = 'auto';
      }
    },
    // The dropdown list uses fixed positioning, which doesn't work with scrolling
    // Simplest solution is to just prevent scrolling when the dropdown is open
    preventOutsideScroll(event) {
      const ms = this.$refs.multiselect;
      if (ms && ms.isOpen) {
        if (!ms.$el.contains(event.target)) {
          event.preventDefault();
        }
      }
    },
    clearAll() {
      this.$emit('input', null);
    },
  },
  mounted() {
    window.addEventListener('wheel', this.preventOutsideScroll, {
      passive: false,
    });
  },
  watch: {
    forceOpen(val) {
      if (val) {
        this.$refs.multiselect.$el.focus();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('wheel', this.preventOutsideScroll);
  },
};
