import { render, staticRenderFns } from "./DependenciesRecentItems.vue?vue&type=template&id=93ca5300&scoped=true&"
import script from "./DependenciesRecentItems.vue?vue&type=script&lang=js&"
export * from "./DependenciesRecentItems.vue?vue&type=script&lang=js&"
import style0 from "./DependenciesRecentItems.vue?vue&type=style&index=0&id=93ca5300&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93ca5300",
  null
  
)

export default component.exports