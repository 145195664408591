<template>
  <button
    :class="['round-btn', `size-${size}`, { primary, active, disabled }]"
    v-on="disabled ? undefined : $listeners"
    :data-identifier="identifier"
  >
    <Icons v-if="iconPackage === 'superset'" :id="icon" />
    <CommonIcons v-else-if="iconPackage === 'common'" :id="icon" />
    <slot />
    <span v-if="showBadge && badge" class="round-btn__badge">
      {{ Number(badge) }}
    </span>
  </button>
</template>

<script>
import Icons from '@teamwork/common-icons/dist/v-icon';
import CommonIcons from '@teamwork/common-icons/dist/v-common-icon';

export default {
  name: 'RoundButton',
  display: 'RoundButton',
  props: {
    icon: { type: String, required: false, default: 'circle' },
    badge: { type: [String, Number], required: false, default: '' },
    showBadge: { type: Boolean, required: false, default: true },
    active: { type: Boolean, required: false, default: false },
    primary: { type: Boolean, required: false, default: false },
    size: { type: String, required: false, default: 'default' },
    disabled: { type: Boolean, required: false, default: false },
    iconPackage: { type: String, required: false, default: 'superset' },
    identifier: { type: String, required: false, default: '' },
  },
  components: { CommonIcons, Icons },
};
</script>

<style lang="scss" scoped>
$sizeList: (
  default: 28px,
  small: 20px,
  medium: 32px,
  big: 44px,
);

$iconSizeList: (
  default: 12px,
  small: 11px,
  medium: 14px,
  big: 22px,
);

$sizes: default, small, medium, big;
$btn-color: #e1e6ee;
$icon-fill: #6d6f80;
$icon-active-fill: #4461d7;
$icon-disabled-fill: #d8e0ff;

.round-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $btn-color;
  color: $btn-color;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  position: relative;

  &__badge {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    bottom: -7px;
    right: -4px;
  }

  svg {
    fill: $icon-fill;
  }

  &.disabled {
    border-color: $icon-disabled-fill;
    cursor: not-allowed;

    svg {
      fill: $icon-disabled-fill;
    }
  }

  &.active {
    border: 1px solid $icon-active-fill;
    cursor: pointer;

    svg {
      fill: $icon-active-fill;
    }

    .round-btn__badge {
      background-color: $icon-active-fill;
    }
  }

  &.primary {
    background-color: $icon-active-fill;
    border-color: $icon-active-fill;

    svg {
      fill: #fff;
    }
  }

  &:hover:not(.disabled) {
    border-color: $icon-active-fill;

    svg {
      fill: $icon-active-fill;
    }
  }

  /* SIZES */
  @each $size in $sizes {
    &.size-#{$size} {
      width: map-get($sizeList, $size);
      height: map-get($sizeList, $size);
      border-radius: map-get($sizeList, $size) / 2;

      svg {
        width: map-get($iconSizeList, $size);
        height: map-get($iconSizeList, $size);
      }
    }
  }
}
</style>
