import Vue from 'vue';

// Note the itemType for importer is 'finished'

export default async ({ dispatch, state, getters }, { extraInfo }) => {
  dispatch('notifications/banners/getImporterStatus');
  if (extraInfo && !extraInfo.data) {
    if (getters['user/isSiteAdmin'] || state.user.siteOwner) {
      dispatch('notifications/flashes/success', Vue.t('Import finished'));
    }
  }
  if (
    extraInfo &&
    extraInfo.data &&
    extraInfo.data.importerKey &&
    extraInfo.data.importerKey === 'clickup'
  ) {
    if (getters['user/isSiteAdmin'] || state.user.siteOwner) {
      dispatch('modals/tko/open', {
        id: 'importClickupSuccess',
        args: { useBootstrap: true },
      });
    }
  }
};
