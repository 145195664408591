/* eslint-disable no-param-reassign */
import {
  mapActions,
  mapMutations,
  mapGetters,
} from '@/store/utils/record-mapper';
import { API_PREFIXES } from '@/services/api';

import state from './state';
import getters from './getters';
import { base as mutations, optional as optionalMutations } from './mutations';
import { base as actions, optional as optionalActions } from './actions';
import defaultConfig from './config';
import { detectUpdater, createUpdater, createRecordUpdater } from './updater';
import { createPagination, createRecordPagination } from './pagination';
import { STATUS, GETTERS, MUTATIONS, ACTIONS } from './constants';

// More info:
// https://digitalcrew.teamwork.com/spaces/teamwork-projects-development/page/544-vuex-proposal#data-loader

// Higher-order Vuex Module Pattern - however the module will still be
// solely responsible for loading, isolated from the main module
// as a nested module.

const baseLoader = (listLoader) => (cfg) => ({
  namespaced: listLoader,
  state: state(),
  getters,
  mutations,
  actions: actions({ ...cfg, listLoader }),
});

const recordLoader = (listLoader) => (cfg) => ({
  namespaced: listLoader,
  state: { records: {} },
  getters: mapGetters(getters),
  mutations: mapMutations(mutations),
  actions: mapActions(actions({ ...cfg, listLoader })),
});

const decorateWithConfig =
  (createLoader, isRecordLoader) =>
  (opts = {}) => {
    const cfg = { ...defaultConfig, ...(opts.config || {}) };
    // If updater/pagination was specified in the config, it should trigger
    // automatic creation of the module
    if (cfg.updater) {
      opts.modules = opts.modules || {};
      opts.modules.updater = (
        isRecordLoader ? createRecordUpdater : createUpdater
      )();
    }
    if (cfg.pagination) {
      opts.modules = opts.modules || {};
      opts.modules.pagination = (
        isRecordLoader ? createRecordPagination : createPagination
      )({
        config: cfg.pagination === true ? {} : cfg.pagination,
      });
    }
    // If an updater was specified as a module, ensure to update the
    // config so we know an updater exists
    if (!cfg.updater) {
      cfg.updater =
        opts.modules && Object.values(opts.modules).some(detectUpdater);
    }

    // Module shorthand - might be nested, e.g. `abc/def`
    if (cfg.module) {
      cfg.commit = opts.config.commit || `${cfg.module}/records`;
      if (!cfg.mapToRecords) {
        const modules = cfg.module.split('/');
        if (modules.length === 1) {
          cfg.mapToRecords = ({ rootState }, list) =>
            list.map((id) => rootState[cfg.module].records[id]);
        } else if (modules.length === 2) {
          cfg.mapToRecords = ({ rootState }, list) =>
            list.map((id) => rootState[modules[0]][modules[1]].records[id]);
        } else {
          cfg.mapToRecords = ({ rootState }, list) =>
            list.map(
              (id) =>
                modules.reduce((parent, mod) => parent[mod], rootState).records[
                  id
                ],
            );
        }
      }
    }

    // If we have an onLoad action provided, use that instead of LOADED_RECORD / LOADED_LIST
    if (opts.actions && opts.actions.onLoad) {
      cfg.onLoadAction = true;
    }

    const loader = createLoader(cfg);
    return {
      namespaced:
        opts.namespaced !== undefined ? opts.namespaced : loader.namespaced,
      modules: opts.modules,
      state: {
        ...loader.state,
        ...(opts.state || {}),
      },
      getters: {
        ...loader.getters,
        ...(opts.getters || {}),
      },
      mutations: {
        ...loader.mutations,
        ...(opts.mutations || {}),
      },
      actions: {
        ...loader.actions,
        ...(opts.actions || {}),
      },
    };
  };

export {
  optionalMutations as mutations,
  optionalActions as actions,
  STATUS,
  GETTERS,
  MUTATIONS,
  ACTIONS,
  API_PREFIXES as API,
};
export const createSingletonLoader = decorateWithConfig(
  baseLoader(false),
  false,
);
export const createListLoader = decorateWithConfig(baseLoader(true), false);
export const createRecordLoader = decorateWithConfig(recordLoader(false), true);
export const createRecordListLoader = decorateWithConfig(
  recordLoader(true),
  true,
);
