var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-popover',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.tooltipContent,
    placement: 'top',
  }),expression:"{\n    content: tooltipContent,\n    placement: 'top',\n  }"}],attrs:{"popover-arrow-class":"hidden","trigger":"manual","options":{ stopPropagation: true },"boundaries-element":'body',"delay":{ hide: 300 },"open":_vm.isOpen,"placement":_vm.placement},on:{"show":_vm.onShow}},[_c('button',{staticClass:"task-toggle-button",class:[
      ("size-" + _vm.size),
      {
        'is-completed': _vm.task.status === 'completed',
        'is-blocked': _vm.numPredecessors > 0,
        'has-subtask': _vm.numActiveSubTasks > 0 || _vm.hasActiveSubtasks,
        'cannot-complete': !_vm.canComplete,
        'in-progress': _vm.isCreatingTask,
      } ],style:({ marginTop: (_vm.marginTop + "px"), marginRight: (_vm.marginRight + "px") }),attrs:{"data-identifier":_vm.dataIdentifier,"disabled":_vm.isTaskToggleStatusDisabled},on:{"click":_vm.beforeToggleStatus,"mouseenter":_vm.show,"mouseleave":_vm.hide}},[(
        (_vm.numPredecessors == 0 && _vm.numActiveSubTasks > 0) || _vm.hasActiveSubtasks
      )?_c('span',[_vm._v("\n      "+_vm._s(_vm.hasActiveSubtasks ? _vm.numExcludeActiveSubtasks : _vm.numActiveSubTasks)+"\n    ")]):_c('CommonIcon',{attrs:{"id":_vm.numPredecessors > 0 ? 'minus' : 'check'}})],1),_vm._v(" "),(_vm.numPredecessors > 0)?_c('template',{slot:"popover"},[_c('div',{staticClass:"task-toggle-predecessors",on:{"mouseenter":_vm.show,"mouseleave":_vm.hide}},[_c('div',{class:{ loading: _vm.loading }},[_c('p',{class:{ skeleton: _vm.loading }},[_vm._v("\n          "+_vm._s(_vm.$t('Task can’t be completed'))+"\n        ")]),_vm._v(" "),_c('p',{class:{ skeleton: _vm.loading }},[_vm._v("\n          "+_vm._s(_vm.$t('Waiting on these tasks to be completed'))+"\n        ")]),_vm._v(" "),_c('ul',[(_vm.loading)?_vm._l((_vm.numPredecessors),function(item){return _c('li',{key:item,staticClass:"loading"},[_c('span',{staticClass:"task-toggle-button size-small readonly skeleton"}),_vm._v(" "),_c('div',{staticClass:"skeleton task-skeleton"})])}):_vm._l((_vm.predecessors),function(predecessorTask){return _c('li',{key:predecessorTask.id,on:{"click":function($event){return _vm.$emit('open', predecessorTask.id)}}},[_c('span',{staticClass:"task-toggle-button size-small readonly"},[_c('span',{staticClass:"check-icon"})]),_vm._v(" "),_c('a',{attrs:{"href":("#/tasks/" + (predecessorTask.id))},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("\n                "+_vm._s(predecessorTask.name)+"\n              ")])])})],2)])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }