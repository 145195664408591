var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border border-solid px-3 flex items-center gap-2 rounded",class:_vm.wrapperClasses,on:{"click":_vm.onClick}},[(_vm.$slots.prepend)?_c('div',{staticClass:"inline-flex items-center gap-2",class:{
      'color-[color:var(--token-c-input-color-placeholder)]': !_vm.disabled,
      'color-[color:var(--token-c-input-color-placeholder-disabled)]':
        _vm.disabled,
    }},[_vm._t("prepend")],2):_vm._e(),_vm._v(" "),_c('input',_vm._g(_vm._b({ref:"inputRef",staticClass:"\n      w-full\n      m-0\n      p-0\n      border-0\n      outline-none\n      text-default\n      leading-6\n      bg-transparent\n      appearance-none\n      disabled:placeholder-palette-neutral-40\n    ",class:_vm.inputClasses,attrs:{"disabled":_vm.disabled,"type":_vm.type,"required":_vm.required},domProps:{"value":_vm.modelValue}},'input',_vm.$attrs,false),Object.assign({}, _vm.$listeners, {input: _vm.onInput, blur: _vm.onBlur, focus: _vm.onFocus}))),_vm._v(" "),(_vm.$slots.append || _vm.showClearButton)?_c('div',{staticClass:"inline-flex items-center gap-2",class:{
      'fill-[color:var(--token-c-input-color-placeholder)]': !_vm.disabled,
      'fill-[color:var(--token-c-input-color-placeholder-disabled)]':
        _vm.disabled,
    }},[_vm._t("append"),_vm._v(" "),(_vm.showClearButton)?_c('button',{staticClass:"\n        border-0\n        p-0\n        m-0\n        appearance-none\n        outline-none\n        inline-flex\n        items-center\n        justify-center\n        bg-transparent\n      ",class:{
        'color-[color:var(--token-c-input-color-text-invalid)]': _vm.invalid,
      },attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickClear.apply(null, arguments)}}},[_c('IconClear',{staticClass:"fill-current w-4 h-4"})],1):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }