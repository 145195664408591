import tkoQuickView from '@/scaffolding/tko-quick-view';

export default {
  namespaced: true,
  modules: {
    ...tkoQuickView,
  },
  state: {
    quickViews: [],
  },
  getters: {
    active: (state) => state.quickViews.slice(-1)[0],
    isQuickViewOpen: (state) => () => {
      const vueQVs = state.quickViews || [];
      // It is accessed through the general window object instead
      // of a getter in the tko module because it isn't reactive
      const tkoQVs = window.app.quickView.views() || [];

      return vueQVs.length || tkoQVs.length;
    },
  },
  mutations: {
    open(state, { id, name, props = {}, type } = {}) {
      state.quickViews = [
        ...state.quickViews,
        { id, name, props, type, ref: `${name}-${state.quickViews.length}` },
      ];
    },
    replace(state, { id, name, props = {}, type } = {}) {
      if (state.quickViews.length) {
        state.quickViews.splice(0, 1, {
          id,
          name,
          props,
          type,
          ref: `${name}-${state.quickViews.length}`,
        });
      } else {
        state.quickViews = [
          ...state.quickViews,
          { id, name, props, type, ref: `${name}-${state.quickViews.length}` },
        ];
      }
    },
    close(state) {
      // Close the topmost modal
      state.quickViews.splice(-1, 1);
    },
    reset(state) {
      // Reset modal array to empty
      state.quickViews = [];
    },
  },
  actions: {
    open({ commit, getters: { active } }, { id, name, props, type } = {}) {
      if (active && active.name === name) {
        return;
      }
      commit('open', { id, name, props, type });
    },
    replace({ commit }, { id, name, props, type } = {}) {
      commit('replace', { id, name, props, type });
    },
    close({ commit }) {
      commit('close');
    },
    reset: ({ commit }) => commit('reset'),
  },
};
