<template>
  <div class="h-full flex flex-col">
    <div
      class="pb-6 border-b border-x-0 border-t-0 border-[#C5CEE0] border-solid"
    >
      <h1 class="text-[20px] text-ellipsis text-[#0B0E1F] mb-2 font-[600]">
        {{ $t(title) }}
      </h1>
      <div class="skeleton h-5 w-3/4" />
    </div>
    <div class="skeleton mt-6 flex-1" />
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
  },
};
</script>
