export const ITEM_PICKER_STATES = Object.freeze({
  INITIAL: 'INITIAL',
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  LOADING_WITH_DATA: 'LOADING_WITH_DATA',
  NO_DATA: 'NO_DATA',
  NO_RESULT: 'NO_RESULT',
  ERROR: 'ERROR',
});

export function getItemPickerState(search, data) {
  const { SUCCESS, NO_DATA, NO_RESULT } = ITEM_PICKER_STATES;
  if (data.length === 0 && search === '') {
    return NO_DATA;
  }
  if (data.length === 0 && search !== '') {
    return NO_RESULT;
  }

  return SUCCESS;
}
