<template>
  <div :class="['chips', { overlapped: overlapping, multiline }]">
    <slot />
  </div>
</template>

<script>
/*
PROPS
  overlapping: Whether the chips are overlapping each other
  multiline: Whether the chips will be on multiline (will have margins bottom)

SLOTS
  default - where the chips go
*/

export default {
  name: 'Chips',
  display: 'Chips',
  props: {
    overlapping: { type: Boolean, required: false, default: false },
    multiline: { type: Boolean, required: false, default: false },
  },
};
</script>
<style lang="scss" scoped>
.chips {
  display: flex;
  flex-wrap: wrap;

  &.overlapped {
    .chip:not(:first-child) {
      margin-left: -15px;
      box-shadow: 0 0 0 1.5px #fff;
    }
  }

  &.multiline {
    .chip {
      margin-bottom: 5px;
    }
  }
}
</style>
