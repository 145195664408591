<template>
  <div
    class="
      popover-menu-container
      bg-white
      text-[14px]
      rounded-[8px]
      overflow-hidden
      font-sans
      py-1
      px-[2px]
    "
  >
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.popover-menu-container {
  box-shadow: 0px 16px 40px 0px #0b0e1f29;
}
</style>
