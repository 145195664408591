/* eslint-disable consistent-return */
import Vue from 'vue';
import placeholder from '@/store/utils/placeholder';
import api from '@/services/api';
import recordDefault from './recordDefault';

const dateFormat = 'YYYYMMDD';

// Placeholder functionality is mixed in
export default placeholder({
  multipleMutation: true,
  persistApi: ({ state, name }) =>
    api.post(`/tasklists/${state.taskListId}/tasks.json`, {
      'todo-item': {
        content: name,
        parentTaskId: state.parentTaskId,
        'due-date': '',
        'start-date': '',
        progress: 0,
        'responsible-party-id': 0,
      },
    }),
  beforeSaveId: ({ state, dispatch, commit, newId }) => {
    if (state.parentTaskId) {
      // After persisting, need to swap out the ID in the parent subtasks
      commit(
        'addSubTask',
        {
          id: state.parentTaskId,
          payload: {
            id: newId,
            positionAfterTask: state.id,
          },
        },
        { recordMap: false },
      );
      commit(
        'removeSubTask',
        {
          id: state.parentTaskId,
          payload: state.id,
        },
        { recordMap: false },
      );
      dispatch(
        'removeLocalPredecessor',
        {
          id: state.parentTaskId,
          payload: state.id,
        },
        { recordMap: false },
      );
      commit(
        'dependencies/addPredecessor',
        {
          depTaskId: state.parentTaskId,
          id: newId,
          projectId: state.projectId,
          type: 'complete',
        },
        { recordMap: false, root: true },
      );
    } else {
      commit('removeFromTasklist', state.taskListId);
      commit(
        'addToTasklist',
        { id: newId, payload: state.taskListId },
        { recordMap: false },
      );
    }
  },
  beforeClear: ({ state, dispatch, commit, id }) => {
    if (state.parentTaskId) {
      commit(
        'removeSubTask',
        { id: state.parentTaskId, payload: id },
        { recordMap: false },
      );
      dispatch(
        'removeLocalPredecessor',
        {
          id: state.parentTaskId,
          payload: state.id,
        },
        { recordMap: false },
      );
    } else {
      commit('removeFromTasklist', state.taskListId);
    }
  },
  createPlaceholder: ({ initial, rootState }) => {
    if (!initial.taskListId) {
      Vue.util.warn(
        'Cannot create a placeholder task without a parent tasklist',
      );
      return;
    }
    const { projectId, milestoneId } =
      rootState.tasklist.records[initial.taskListId];
    const dueDate = milestoneId
      ? rootState.milestone.records[milestoneId].deadline.format(dateFormat)
      : '';
    return {
      ...recordDefault,
      ...initial,
      name: 'New task',
      startDate: '',
      dueDate,
      dueDateFromMilestone: !!milestoneId,
      canEdit: true,
      canComplete: true,
      projectId,
    };
  },
});
