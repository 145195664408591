/* eslint-disable no-use-before-define, import/prefer-default-export */
import { v3Url } from '@teamwork/fetcher';
import {
  useAxios,
  useCurrentAccount,
  useCurrentUser,
  useOptimisticUpdates,
  useRealTimeUpdates,
} from '@teamwork/use';
import { compact, isNumber, isPlainObject, isString } from 'lodash-es';

import { usePreferences } from '@/platform/composables/usePreferences';
import useStore from '@/platform/composables/useStore';
import useRouter from '@/platform/composables/useRouter';
import desktopNotification from '@/services/desktop-notifications';
import { reminder } from '@/services/audio';
import {
  getEventTitle,
  getLinkForNotificationItem,
} from '@/utils/helpers/notifications';

export function useNotificationActions() {
  const api = useAxios();
  const store = useStore();
  const router = useRouter();
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();
  const { emit: _emitOptimisticUpdate } = useOptimisticUpdates();
  const { socketId } = useRealTimeUpdates();
  const { useDesktopNotifications, areNotificationsUnmuted } = usePreferences();

  // delay promise resolution by X ms
  // used to improve ux of rolled back optimistic updates
  async function delayedPromise(promise, ms) {
    const delay = () => new Promise((resolve) => setTimeout(resolve, ms));
    await delay();
    return promise;
  }

  function emitOptimisticUpdate(promise, action, notification) {
    _emitOptimisticUpdate({
      promise,
      type: 'notification',
      action,
      notification,
    });
  }

  function config() {
    return {
      headers: {
        'Socket-ID': socketId.value,
        'Triggered-By': 'user',
        'Sent-By': 'composable',
      },
    };
  }

  function updateNotification(notification) {
    const promise = api.patch(
      v3Url(`notifications/${notification.id}`),
      {
        notification: {
          read: notification.read,
        },
      },
      config(),
    );

    emitOptimisticUpdate(delayedPromise(promise, 1000), 'update', notification);

    return promise;
  }

  function markNotificationRead(notification) {
    return updateNotification({ ...notification, read: true });
  }

  function markNotificationUnread(notification) {
    return updateNotification({ ...notification, read: false });
  }

  function toggleNotificationRead(notification) {
    return updateNotification({ ...notification, read: !notification.read });
  }

  function markAllRead() {
    const promise = api.post(v3Url(`notifications/read`), {}, config());

    emitOptimisticUpdate(delayedPromise(promise, 1000), 'markAllRead');

    return promise;
  }

  function quickView(notification, popoverId, markAsRead = true) {
    if (!isPlainObject(notification)) {
      return;
    }

    if (notification?.item?.type === 'event') {
      return;
    }

    if (!notification?.item?.link) {
      return;
    }

    const projectId =
      notification?.project?.id || store.state?.project?.currentProjectId;

    if (!projectId) {
      return;
    }

    const linkParts = /^#?(\w+)s\/(\d+).*$/.exec(notification.item.link);
    if (!linkParts) {
      return;
    }

    const [itemType, itemId] = linkParts.slice(1);
    const itemTypeFixed = itemType === 'tasklist' ? 'taskList' : itemType;

    if (
      [
        'task',
        'milestone',
        'link',
        'notebook',
        'message',
        'file',
        'taskList',
      ].includes(itemTypeFixed)
    ) {
      if (popoverId) {
        store.dispatch('popover/lock', popoverId);
      }

      if (markAsRead && !notification.read) {
        markNotificationRead(notification);
      }

      store.dispatch('quickViews/tko/open', {
        id: itemTypeFixed,
        args: { projectId, [`${itemTypeFixed}Id`]: itemId, id: itemId },
        onCloseCallback: () => {
          if (popoverId) {
            // RAF to ensure the popover isn't unlocked until after the quick view close click
            requestAnimationFrame(() => {
              store.dispatch('popover/unlock', popoverId);
            });
          }
        },
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn('Unsupported notification type', notification);
    }
  }

  function navigate(notification, newTab = false) {
    if (!isPlainObject(notification)) {
      return;
    }

    const href = getLinkForNotificationItem(currentUser, notification);

    if (!href) {
      return;
    }

    if (!notification.read) {
      markNotificationRead(notification);
    }

    if (newTab) {
      const route = router.resolve(href);
      window.open(route.href, '_blank');
      return;
    }

    router.push(href);
  }

  function access(notification) {
    if (!isPlainObject(notification)) {
      return false;
    }

    if (!notification.read) {
      markNotificationRead(notification);
    }

    if (
      notification.item.type === 'statusrequest' &&
      store.state.user.inOwnerCompany
    ) {
      // Timeout prevents an underlying issue of the dialog closing after route change on 2nd+ visits
      setTimeout(
        () => store.dispatch('modals/tko/open', { id: 'addOrEditStatus' }),
        200,
      );

      return false;
    }

    return true;
  }

  function triggerNotificationSound(event, rawEvent) {
    if (!shouldTriggerNotificationSound(event, rawEvent)) {
      return;
    }

    reminder();
  }

  function triggerDesktopNotification(event, rawEvent) {
    if (!shouldTriggerDesktopNotification(currentUser, event, rawEvent)) {
      return;
    }

    desktopNotification({
      ...event,
      title: getEventTitle(event, currentUser.value.id),
      icon: currentAccount.value.logo,
      tag: `tw-${currentAccount.value.id}-${currentUser.value.id}`,
      body: event.description,
    })
      .then(() => {
        // if the item has been deleted, do nothing
        // if it is active, then go to the affected item
        if (
          ['statusupdate', 'status'].includes(event.itemType.toLowerCase()) &&
          currentUser.value.inOwnerCompany
        ) {
          router.push('/statuses/overview');
          return;
        }

        if (
          event.itemType === 'statusrequest' &&
          currentUser.value.inOwnerCompany
        ) {
          store.dispatch(
            'modals/show',
            { id: 'addOrEditStatus' },
            { root: true },
          );
          return;
        }

        if (event.actionType !== 'deleted') {
          router.push(event.itemLink.replace(/^\/?/, '/'));
        }
      })
      .catch(() => {
        // eslint-disable-next-line
        console.warn('Desktop notifications not allowed');
      });
  }

  // eslint-disable-next-line no-unused-vars
  function shouldTriggerNotificationSound(event, rawEvent) {
    return (
      ['reminder', 'statusrequest'].includes(event.actionType) &&
      currentUser.value.notifications.soundAlertsEnabled &&
      areNotificationsUnmuted.value
    );
  }

  // eslint-disable-next-line no-unused-vars
  function shouldTriggerDesktopNotification(event, rawEvent) {
    // Don't show desktop notifications if the user actively viewing the app or has them disabled
    if (
      document.visibilityState === 'visible' ||
      !useDesktopNotifications.value
    ) {
      return false;
    }

    // Only show desktop notification if the user has been explictly chosen to be
    // notified by this event
    if (!event.notifiedUserIdList) {
      return false;
    }

    const recipientIds = (() => {
      if (isString(event.notifiedUserIdList)) {
        return compact(event.notifiedUserIdList.split(',').map(parseInt));
      }

      if (isNumber(event.notifiedUserIdList)) {
        return [event.notifiedUserIdList];
      }

      return [];
    })();

    if (!recipientIds.includes(currentUser.value.id)) {
      return false;
    }

    return true;
  }

  return {
    updateNotification,
    markNotificationRead,
    markNotificationUnread,
    toggleNotificationRead,
    markAllRead,
    quickView,
    navigate,
    access,
    triggerNotificationSound,
    triggerDesktopNotification,
  };
}
