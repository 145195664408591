<template>
  <v-popover
    :open="open"
    boundaries-element="document.body"
    placement="top"
    popover-class="item-picker-popover"
    popover-arrow-class="hidden"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <slot name="trigger" />
    <template slot="popover">
      <div
        class="item-picker-popover__wrapper"
        :style="`height: ${wrapperHeight}px`"
      >
        <slot></slot>
      </div>
    </template>
  </v-popover>
</template>
<script>
import { ITEM_PICKER_STATES } from '@/utils/helpers/itemPicker';

export default {
  name: 'ItemPickerPopover',
  props: {
    open: { type: Boolean, default: false },
    height: { type: Number, default: 345 },
    state: { type: String, required: true },
  },
  computed: {
    wrapperHeight() {
      return this.state === ITEM_PICKER_STATES.NO_DATA ? 265 : this.height;
    },
  },
};
</script>
<style lang="scss">
.item-picker-popover {
  width: 200px;
  z-index: 15001;
  &__wrapper {
    border-radius: 8px;
    background-color: white;
    width: 280px;
    padding: 0 10px;
  }
}
</style>
