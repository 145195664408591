"use strict";

var hasProp = {}.hasOwnProperty,
    indexOf = [].indexOf;
define(['knockout', 'moment', 'bluebird'], function (ko, moment, Promise) {
  var JSON_PARAM_NAMES, UserSavedFilter, mapAsInt, mapParameters, mappingConfig;
  mapAsInt = {
    update: options => {
      if (!(options.parent instanceof UserSavedFilter)) {
        // necessary to avoid mapping properties named projectId
        // in parameters object as integer by default
        return options.data;
      }

      return parseInt(options.data, 10) || 0;
    }
  };
  mapParameters = {
    update: options => {
      var key, params, ref, val;
      params = {};
      ref = options.data;

      for (key in ref) {
        val = ref[key];

        if (Array.isArray(val)) {
          params[key] = ko.observableArray(val);
        } else {
          params[key] = ko.observable(val);
        }
      }

      return params;
    }
  };
  mappingConfig = {
    id: mapAsInt,
    projectId: mapAsInt,
    userId: mapAsInt,
    displayOrder: mapAsInt,
    parameters: mapParameters,
    ignore: ['fulldata']
  }; // These params contain JSON values

  JSON_PARAM_NAMES = ['customFields'];
  return UserSavedFilter = class UserSavedFilter {
    constructor(data, opts = {}) {
      var ref; // Sets this filter's properties to their default values.

      this.Clear = this.Clear.bind(this);
      this.ResetValue = this.ResetValue.bind(this);
      this.ClearSharedFilterURL = this.ClearSharedFilterURL.bind(this);
      this.Edit = this.Edit.bind(this);
      this.SaveDefault = this.SaveDefault.bind(this);
      this.getSerializedParameters = this.getSerializedParameters.bind(this);
      this.ResetTemporaryFilter = this.ResetTemporaryFilter.bind(this);
      this.ClearDefault = this.ClearDefault.bind(this);
      this.Copy = this.Copy.bind(this);
      this.Save = this.Save.bind(this);
      this.Share = this.Share.bind(this);
      this.Delete = this.Delete.bind(this);
      this.UpdatePrivateFilterProperties = this.UpdatePrivateFilterProperties.bind(this); // Looks like we don't need this logic anymore. Commenting out for now
      // else if not @edited()
      //     @_resetToOriginalUniqueValues()

      this.UpdateSelectedFilterList = this.UpdateSelectedFilterList.bind(this);
      this._clearProjectSpecificValues = this._clearProjectSpecificValues.bind(this);
      this._resetToOriginalUniqueValues = this._resetToOriginalUniqueValues.bind(this);
      this._buildSubscriptions = this._buildSubscriptions.bind(this);
      this._getSections = this._getSections.bind(this);
      this._getClone = this._getClone.bind(this); // Models generally don't handle disposal logic correctly
      // Instead we force components that use models to call disposal logic properly

      this.dispose = this.dispose.bind(this);
      this.subscriptions = [];
      this._instanceCount = 0;
      this.id = ko.observable(0);
      this.title = ko.observable('');
      this.description = ko.observable('');
      this.color = ko.observable('');
      this.section = ko.observable('');
      this.projectId = ko.observable(0);
      this.userId = ko.observable(0);
      this.deleted = ko.observable(false);
      this.isTemporary = ko.observable(true);
      this.shared = ko.observable(false);
      this.isProjectSpecific = ko.observable(false);
      this.includesSort = ko.observable(false);
      this.displayOrder = ko.observable(0);
      this.shareLink = ko.observable('');
      this.sharedState = ko.observable(false);
      this.cloneId = ko.observable(0);
      this.cloneShareLink = ko.observable('');
      this.selectedFilterList = ko.observableArray([]);
      this.sections = ko.observable([]).extend({
        rateLimit: 0
      });
      this.parameters = {};
      this.privateParameters = {};
      this.ignoredParameters = {};
      this._previousProperties = {};
      this.fulldata = (ref = data.fulldata) != null ? ref : {};

      if (data.title === '' && !data.isTemporary) {
        data.title = app.tl('Saved filter');
      }

      ko.mapping.fromJS(data, mappingConfig, this);

      if (!window.validFilters) {
        // something went wrong decoding user saved filters, show an alert
        app.flash.Info(app.tl("There was an issue loading your saved filters. If this persists, please contact [_s].", "support@teamwork.com"));
      }

      app.filterConfig.buildParameters(this.section(), {
        parameters: this.parameters,
        privateParameters: this.privateParameters,
        ignoredParameters: this.ignoredParameters
      });
      this.uniqueId = `savedFilter-${Date.now()}`;
      this.collapsedCacheKey = `${this.section()}CollapsedFilters`;
      this.orderPreferenceName = `${this.section()}FiltersOrder`; // Set this property so that we can easily refer to and compare initial values
      // and meta info about filter parameters

      this._defaultProperties = {};

      if (this.section().length && app.filterConfig.config[this.section()] != null) {
        this._defaultProperties = app.filterConfig.config[this.section()].parameters;
      } // NOTE: Add business rules here but only if essential and can not be handled in individual filter components
      // This function will allow us to add any necessary logic we want to individual filter parameters.
      // Filter parameters should be as neutral as possible but sometimes specific use cases and edge cases will result in us having to handle specific values.
      // If the filter parameter should be ignored, i.e. ignoreChanges is set to TRUE, then we should return FALSE here
      // If the filter parameter is a moment type and is 'null' or is a null value then we return FALSE if ignoreNull is set to TRUE


      this._shouldValidateValue = (name, value) => {
        var dateCodeValue, dateRangeProperties, filtered, ignoreNull, isDateCodeParameter, linkedDateCodeParameter, parameter, ref1, ref2, ref3, ref4; // If a property defined in filterConfig has ignoreChanges set to true, then this function will return early and the subscription will no longer trip
        // Instead if we know that the parameter being checked is one of the filter's sorting properties, we can just return the value of includesSort
        // which will always result in accurate reporting and also allow the filter to prompt users to save it when it can be updated

        if (name === 'sortBy' || name === 'sortOrder') {
          return this.includesSort();
        }

        parameter = this._defaultProperties[name];

        if (!parameter || parameter.ignoreChanges) {
          return false;
        } // When validating dates, there are multiple factors to consider
        // - The user may have selected a generic date filter type like "anytime", "today", "tomorrow" where
        //   the filtered dates are calculated based on the relative type selected
        // - The user may have selected a relative period like "within" or "withinprev" where
        //   date ranges are calculated by using a specified interval
        // - Or the user may have selected a set date range which is not relative and is comprised of 2 static values.
        // In each case, we only validate the values that are relevant for the current selection


        isDateCodeParameter = (ref1 = parameter.isDateCode) != null ? ref1 : false;
        linkedDateCodeParameter = (ref2 = parameter.dateCode) != null ? ref2 : '';

        if (linkedDateCodeParameter.length || isDateCodeParameter) {
          dateCodeValue = isDateCodeParameter ? value : this.parameters[linkedDateCodeParameter]; // When using 'within' and 'withinprev' we are only interested in
          // whether or not the specific interval has changed, so dateCode needs to be ignored

          if ((ref3 = dateCodeValue()) === 'withinprev' || ref3 === 'within') {
            if (!name.toLowerCase().includes('interval')) {
              return false;
            } // When dateCode is 'custom'
            // we only care about validating the start and end dates

          } else if (dateCodeValue() === 'custom') {
            dateRangeProperties = ['startdate', 'enddate', 'afterdate', 'beforedate'];
            filtered = dateRangeProperties.filter(function (p) {
              return name.toLowerCase().includes(p);
            });

            if (!filtered.length) {
              return false;
            }
          } else {
            if (!isDateCodeParameter) {
              // When dateCode is any value other than 'within'/'withinprev'/'custom'
              // then we need to ignore all date properties other than dateCode
              // as dates are calculated and dynamic
              return false;
            }
          }
        }

        if (parameter.type === 'moment') {
          ignoreNull = (ref4 = parameter.ignoreNull) != null ? ref4 : false;

          if ((value() == null || value()._i === 'null') && ignoreNull) {
            return false;
          } else if (value() == null || !value().isValid()) {
            return false;
          }
        }

        return true;
      };

      this._valueHasChanged = (name, newValue, oldValue) => {
        var ref1, valueType;
        valueType = this._defaultProperties[name].type;

        if ((ref1 = valueType.toLowerCase()) === 'number' || ref1 === 'string') {
          return app.utility.AreEqual(newValue.toString(), oldValue.toString());
        } else {
          return app.utility.AreEqual(newValue, oldValue);
        }
      };

      this._isFilterSectionActive = (name, value) => {
        return this._shouldValidateValue(name, value) && !this._valueHasChanged(name, value(), value.defaultValue);
      };

      return;
    } // Updates the filter with the data from the server.
    // @UpdateSelectedFilterList()


    Update(data) {
      console.assert(!(data instanceof UserSavedFilter));
      ko.mapping.fromJS(data, mappingConfig, this);
      app.filterConfig.buildParameters(this.section(), {
        parameters: this.parameters,
        privateParameters: this.privateParameters,
        ignoredParameters: this.ignoredParameters
      });
    } // Sets this filter's properties to be in sync with the specified `filter`.


    SetFilter(filter, saveDefault = true) {
      var name, ref, ref1, value, valueObservable;
      console.assert(filter instanceof UserSavedFilter);

      if (this.shared()) {
        this.ClearSharedFilterURL();
      }

      this.id(filter.id());
      this.title(filter.title());
      this.description(filter.description());
      this.color(filter.color());
      this.projectId(filter.projectId());
      this.fulldata = (ref = filter.fulldata) != null ? ref : {};
      this.shareLink(filter.shareLink());
      this.isProjectSpecific(filter.isProjectSpecific());
      this.includesSort(filter.includesSort());
      this.displayOrder(filter.displayOrder());
      this.isTemporary(false);
      this.shared(false);
      this.sharedState(filter.sharedState());
      ref1 = this.parameters;

      for (name in ref1) {
        if (!hasProp.call(ref1, name)) continue;
        valueObservable = ref1[name];
        value = filter.parameters[name]();
        valueObservable(value);
        valueObservable.originalValue(value);
        valueObservable.notifySubscribers(value, 'reset');
      }

      this.UpdatePrivateFilterProperties();

      if (saveDefault) {
        this.SaveDefault();
      }
    }

    Clear(resetSavedDefault = true) {
      var currentFilterIsTemporary, name, ref, valueObservable;

      if (this.shared()) {
        // Free accounts can use only the default filter which is auto-saved,
        // so it's important that the id is preserved in that case.
        this.ClearSharedFilterURL();
      }

      currentFilterIsTemporary = this.isTemporary() && !this.shared();
      this.title('');
      this.description('');
      this.color('');
      this.shared(false);
      this.selectedFilterList([]);
      ref = this.parameters;

      for (name in ref) {
        if (!hasProp.call(ref, name)) continue;
        valueObservable = ref[name];
        this.ResetValue(name);
      } // If working with a temp filter, after it has had its values reset,
      // then update the filter on the DB


      if (currentFilterIsTemporary) {
        this.ResetTemporaryFilter();
      } else {
        if (resetSavedDefault && !this.shared()) {
          this.ClearDefault();
        }

        this.id(0);
        this.isTemporary(true);
      }

      this.UpdateSelectedFilterList(); // Alert other components that the filter has been cleared

      app.ko.postbox.publish('savedFilter-cleared', {
        uniqueId: this.uniqueId,
        section: this.section()
      });
    }

    ResetValue(name, value) {
      var defaultValue, valueObservable;

      if (!this.parameters[name]) {
        return;
      }

      valueObservable = this.parameters[name];
      defaultValue = valueObservable.defaultValue;
      value = valueObservable.defaultValue;
      valueObservable(value);

      if (!app.account.isFreeAccount()) {
        // Free accounts can use only the default filter which is auto-saved,
        // so we must leave originalValue intact to ensure that the filter will be auto-saved.
        valueObservable.originalValue(value);
      }

      return valueObservable.notifySubscribers(value, 'reset');
    }

    ClearSharedFilterURL() {
      var currentUrl, newUrl, queryString, ref, ref1;

      if (((ref = app.currentRoute().queryParams) != null ? ref.sf : void 0) == null) {
        return;
      }

      currentUrl = app.hasher.getHash();
      queryString = (ref1 = app.currentRoute()['?queryString_']) != null ? ref1 : '';
      newUrl = currentUrl.replace(queryString, '');

      if (newUrl[newUrl.length - 1] === '?') {
        newUrl = newUrl.substr(0, newUrl.length - 1);
      }

      return app.hasher.replaceHash(newUrl);
    }

    Edit() {
      app.modal.Show('addOrEditSavedFilter', {
        filter: this
      });
    }

    SaveDefault() {
      var projectId, savedFilter;

      if (!(parseInt(this.id(), 10) > 0)) {
        return;
      } // Note: This looks like a potential typo but it isn't..
      // We had a long term bug where clearing/setting a default filter was
      // resetting the value of projectId to 0 in the DB regardless.
      // This line ensures that if we are looking at a project level filter that
      // it always has a valid projectId value


      projectId = this.projectId() === 0 ? app.projectId() : this.projectId();
      savedFilter = {
        isDefault: true,
        section: this.section(),
        projectId: projectId,
        isProjectSpecific: this.isProjectSpecific(),
        displayOrder: this.displayOrder(),
        includesSort: this.includesSort(),
        parameters: {}
      };
      return app.api.put(`me/savedFilters/${this.id()}.json`, {
        savedFilter: savedFilter,
        updateDefault: true
      });
    }

    getSerializedParameters(includesSort) {
      var name, parameters, serializedParameters, value;
      parameters = ko.toJS(this.parameters);
      serializedParameters = {};

      for (name in parameters) {
        if (!hasProp.call(parameters, name)) continue;
        value = parameters[name];

        if (includesSort || name !== 'sortBy' && name !== 'sortOrder') {
          serializedParameters[name] = Array.isArray(value) ? indexOf.call(JSON_PARAM_NAMES, name) >= 0 ? JSON.stringify(value) : value.join(',') : moment.isMoment(value) ? value.format('YYYYMMDD') : value;
        }
      }

      return serializedParameters;
    }

    ResetTemporaryFilter() {
      var projectId, savedFilter;

      if (!(parseInt(this.id(), 10) > 0)) {
        return;
      } // See note above


      projectId = this.projectId() === 0 ? app.projectId() : this.projectId();
      savedFilter = {
        isDefault: true,
        isTemporary: true,
        isProjectSpecific: this.isProjectSpecific(),
        displayOrder: this.displayOrder(),
        includesSort: false,
        // temporary filters don't include sort settings
        section: this.section(),
        projectId: projectId,
        parameters: this.getSerializedParameters(false)
      };
      return app.api.put(`me/savedFilters/${this.id()}.json`, {
        savedFilter: savedFilter,
        updateDefault: true
      });
    }

    ClearDefault() {
      var projectId, savedFilter;

      if (!(parseInt(this.id(), 10) > 0)) {
        return;
      } // See note above


      projectId = this.projectId() === 0 ? app.projectId() : this.projectId();
      savedFilter = {
        isDefault: false,
        isTemporary: this.isTemporary(),
        isProjectSpecific: this.isProjectSpecific(),
        displayOrder: this.displayOrder(),
        includesSort: this.includesSort(),
        section: this.section(),
        projectId: projectId,
        parameters: {}
      };
      return app.api.put(`me/savedFilters/${this.id()}.json`, {
        savedFilter: savedFilter,
        updateDefault: true
      });
    }

    Copy() {
      var data, serializedParameters;
      serializedParameters = this.getSerializedParameters(this.includesSort());
      data = {
        savedFilterId: this.id(),
        section: this.section(),
        projectId: app.projectId(),
        // when updating existing filters, it's important to track the most recent project id
        parameters: serializedParameters
      };
      return app.api.post(`me/savedFilters/${this.id()}/copy.json`, data).then(result => {
        // When we are copying a filter in order to share a snapshot of it,
        // we need to store a temporary reference to to the cloned filter id and copy link
        this.cloneId(parseInt(result.response.id, 10));
        return this.cloneShareLink(result.response.shareLink);
      });
    }

    Save(params = {}) {
      var id, includesSort, isEditing, isTemporary, method, parameters, ref, ref1, ref2, ref3, ref4, ref5, ref6, savedFilter, serializedParameters, url, wasTemporary;
      id = params.saveAsNew ? 0 : this.id();
      isEditing = id > 0;
      isTemporary = (ref = params.isTemporary) != null ? ref : false;
      wasTemporary = false;

      if (this.isTemporary() && !isTemporary) {
        wasTemporary = true;
      }

      includesSort = (ref1 = params.includesSort) != null ? ref1 : this.includesSort();

      if (isTemporary) {
        // if we're saving/using a temporary filter, we should NOT includeSort as that would override userPrefs
        includesSort = false;
      }

      parameters = ko.toJS(this.parameters);
      serializedParameters = this.getSerializedParameters(includesSort);
      savedFilter = {
        isDefault: true,
        title: app.utility.StripHTML((ref2 = params.title) != null ? ref2 : this.title()),
        description: app.utility.StripHTML((ref3 = params.description) != null ? ref3 : this.description()),
        color: ((ref4 = params.color) != null ? ref4 : this.color()).replace('#', ''),
        isTemporary: isTemporary,
        isProjectSpecific: (ref5 = params.isProjectSpecific) != null ? ref5 : this.isProjectSpecific(),
        includesSort: includesSort,
        displayOrder: (ref6 = params.displayOrder) != null ? ref6 : this.displayOrder(),
        section: this.section(),
        projectId: app.projectId(),
        // when updating existing filters, it's important to track the most recent project id
        parameters: serializedParameters
      }; // This prevents a race condition where the same filter can be updated twice
      // We don't want to allow the same filter to be persisted if it hasn't changed

      if (ko.toJSON(savedFilter) === ko.toJSON(this._previousProperties)) {
        return Promise.resolve();
      } // After validating, update object for next time the filter is saved


      this._previousProperties = jQuery.extend({}, savedFilter);

      if (isEditing) {
        url = `me/savedFilters/${id}.json`;
        method = "put";
      } else {
        url = "me/savedFilters.json";
        method = "post"; // If we are saving as new, or saving a new filter and on a current project
        // then we need to make sure it uses the correct project id from the current route

        if (app.projectId() > 0) {
          savedFilter.projectId = app.projectId();
        }
      }

      return app.api[method](url, {
        savedFilter: savedFilter,
        useRandomColorIfEmpty: this.section() === 'homeDashboard'
      }).then(result => {
        var name, ref7, ref8, value;

        if (this.shared()) {
          this.ClearSharedFilterURL();
        }

        if (!isEditing) {
          this.id(parseInt(result.response.id, 10));
        }

        if (!isEditing) {
          this.shareLink(result.response.shareLink);
        }

        this.title(savedFilter.title);
        this.color(savedFilter.color);
        this.description(savedFilter.description);
        this.projectId(savedFilter.projectId);
        this.isTemporary(savedFilter.isTemporary);
        this.includesSort(savedFilter.includesSort);
        this.isProjectSpecific(savedFilter.isProjectSpecific);

        if (((ref7 = result.response.savedFilter) != null ? ref7.fulldata : void 0) != null) {
          this.fulldata = result.response.savedFilter.fulldata;
          this.UpdateSelectedFilterList();
        } // if the sent displayOrder was 0, the response will have the new value


        this.displayOrder(savedFilter.displayOrder || parseInt(result.response.displayOrder, 10));
        this.shared(false);
        ref8 = this.parameters;

        for (name in ref8) {
          if (!hasProp.call(ref8, name)) continue;
          value = ref8[name];
          value.originalValue(parameters[name]);
        }

        return app.ko.postbox.publish('allTWEvents', {
          eventTime: new Date(),
          itemType: 'savedFilter',
          extraInfo: {
            data: {
              isTemporary: this.isTemporary()
            }
          },
          actionType: wasTemporary ? app.consts.ACTIONTYPE.ADDED : app.consts.ACTIONTYPE.UPDATED,
          itemId: this.id(),
          title: this.title(),
          projectId: this.projectId()
        });
      });
    }

    Share() {
      /*
          Viewing saved filters:
          a) If there are changes, ask user if they want to save changes now
              - If they save changes, use current share link
              - If they don't save changes, get new share link
                  - this means a new filter is created in the background
          b) If there are no changes,
           Viewing temporary filters:
          a) When the modal opens, get a new share link
           Viewing project filters:
          a) If on project X and viewing a filter shared on project Y
              - Get new link when share modal opens
      */
      var openShareFilterModal, useClonedFilter;
      useClonedFilter = false;

      openShareFilterModal = () => {
        var ref;
        return app.modal.Show('shareFilterAsLink', {
          filter: this,
          filterType: (ref = this.section()) === 'homeDashboard' ? 'dashboard' : 'filter',
          sharedFilterId: useClonedFilter ? this.cloneId() : this.id(),
          sharedFilterLink: useClonedFilter ? this.cloneShareLink() : this.shareLink(),
          onCloseCallBack: result => {
            if (useClonedFilter && !result.filterShared) {
              return app.api.delete(`me/savedFilters/${this.cloneId()}.json`, {
                hardDelete: true
              }).then(result => {
                this.cloneId(0);
                return this.cloneShareLink('');
              });
            }
          }
        });
      };

      if (this.isTemporary()) {
        useClonedFilter = true;

        if (this.id() > 0) {
          this.Copy().finally(openShareFilterModal);
        } // If a user wants to share a filter then they may be doing so from the sidebar or from the summary view
        // picker in which case the @edited computed may not be set yet on a given userSavedFilter model

      } else if (this.edited != null && this.edited() > 0) {
        // it's been edited, save it and then share it
        app.modal.Show('confirm', {
          title: app.tl("You Have Unsaved Changes"),
          text: app.tl("Do you want to share ([_s]) with changes you've made?", this.isTemporary() ? app.tl('Untitled') : this.title()),
          additionalLines: [app.tl('Note - You can share the original saved filter without the changes.')],
          otherMessage: app.tl("Share Original"),
          confirmMessage: app.tl("Share Unsaved Filter"),
          multiChoice: true,
          dims: {
            width: 600
          },
          callback: result => {
            if (result.wasCancelled) {
              return;
            }

            if (result.Other != null && result.Other) {
              useClonedFilter = false;
              openShareFilterModal();
            }

            if (result.OK != null && result.OK) {
              useClonedFilter = true;

              if (this.id() > 0) {
                return this.Copy().finally(openShareFilterModal);
              }
            }
          }
        });
      } else if (this.projectId() !== app.projectId()) {
        useClonedFilter = true;

        if (this.id() > 0) {
          this.Copy().finally(openShareFilterModal);
        }
      } else {
        openShareFilterModal();
      }
    }

    Delete(selectNextFilter = false) {
      var question, ref, title;
      title = app.tl('Are you sure?');
      question = (ref = this.section()) === 'homeDashboard' ? app.tl('Are you sure you want to delete this dashboard?') : app.tl('Are you sure you want to delete this saved filter?');
      return app.modal.Show('confirm', {
        title: title,
        text: question,
        callback: result => {
          var data;

          if (result.wasCancelled) {
            return;
          }

          data = {};

          if (selectNextFilter === true) {
            data = {
              setNextFilterAsDefault: true
            };
          }

          return app.api.delete(`me/savedFilters/${this.id()}.json`, data).then(result => {
            var eventData, ref1;
            eventData = {
              eventTime: new Date(),
              itemType: 'savedFilter',
              actionType: app.consts.ACTIONTYPE.DELETED,
              itemId: this.id(),
              projectId: this.projectId()
            }; // After sending postbox event, ensure key properties are reset

            this.id(0);
            this.deleted(true);
            this.isTemporary(true);

            if (selectNextFilter === true) {
              if (((ref1 = result.response) != null ? ref1.defaultFilter : void 0) != null) {
                this.Update(result.response.defaultFilter);
                eventData.extraInfo = {
                  data: {
                    newFilter: this
                  }
                };
              } else {
                this.Clear(false);
              }
            }

            return ko.postbox.publish('allTWEvents', eventData);
          });
        }
      });
    } // The saved filter has been dragged to a new position in the list.


    Reposition(predecessorId) {
      return app.api.put(`me/savedFilters/${this.id()}/reposition.json`, {
        positionAfter: predecessorId
      }).then(result => {
        var eventData;

        if (!result.response.RESET) {
          this.displayOrder(result.response.displayOrder);
        }

        eventData = {
          eventTime: new Date(),
          itemType: result.response.RESET ? 'savedFilters' : 'savedFilter',
          actionType: app.consts.ACTIONTYPE.UPDATED,
          itemId: this.id(),
          projectId: this.projectId()
        };
        return ko.postbox.publish('allTWEvents', eventData);
      });
    } // Project Specific filters on top, then respect the display order,
    // lastly they should be with most recent on top


    SortCompare(other) {
      return this.isProjectSpecific() - other.isProjectSpecific() || this.displayOrder() === -1 || this.displayOrder() - other.displayOrder() || this.id() - other.id();
    }

    UpdatePrivateFilterProperties() {
      // If the user is opening a filter which is not related to the currently viewed project
      // we then need to detect if that filter has private parameters (i.e. private=true)
      // If we detect that the projectIds are different, then we clear any existing project specific parameters
      // Otherwise we attempt to set the current project's specific parameter values to be correct
      if (this.projectId() !== app.projectId()) {
        return this._clearProjectSpecificValues();
      }
    }

    UpdateSelectedFilterList(useRemoteData = false) {
      var updateFilterListData;

      updateFilterListData = filterData => {
        var activeFilters, categories, category, includesMe, index, keys, name, ref, ref1, tag, tags, tasklists, users, value, valueArray, valueText;
        activeFilters = [];

        if (((ref = this.parameters) != null ? ref.filterText : void 0) != null && this.parameters.filterText().length) {
          activeFilters.push({
            type: 'filterText',
            value: this.parameters.filterText(),
            label: app.tl('Search Term')
          });
        }

        if (((ref1 = this.parameters) != null ? ref1.priority : void 0) != null && this.parameters.priority() !== 'any') {
          activeFilters.push({
            type: 'priority',
            value: app.serverTranslate(app.utility.Capitalize(this.parameters.priority())),
            label: app.tl('Priority')
          });
        }

        for (name in filterData) {
          value = filterData[name];

          if (name === 'users') {
            //If the list of users includes the logged in user then display them first as 'You'
            users = filterData.users;
            keys = Object.keys(users);
            includesMe = keys.indexOf(app.loggedInUser.id().toString()) > -1; //Negative numbers for keys aren't allowed so check if the ID isnt -1, which means that it's for Everyone

            valueText = "";

            if (keys.length > 0) {
              valueText = includesMe && users[keys[0]].id !== "-1" ? 'You' : `${users[keys[0]].fullName}`;

              if (keys.length > 1) {
                valueText += ` + ${keys.length - 1} ${keys.length > 2 ? app.tl('others') : app.tl('other')}`;
              }
            }

            activeFilters.push({
              type: 'assignees',
              value: valueText,
              label: app.tl('Assignees')
            });
          }

          if (name === 'tasklists') {
            tasklists = filterData.tasklists;
            keys = Object.keys(tasklists);
            valueText = "";

            if (keys.length > 0) {
              valueText = `${tasklists[keys[0]].name}`;

              if (keys.length > 1) {
                valueText += ` + ${keys.length - 1} ${keys.length > 2 ? app.tl('others') : app.tl('other')}`;
              }
            }

            activeFilters.push({
              type: 'tasklists',
              value: valueText,
              label: app.tl('Task Lists')
            });
          }

          if (name === 'tags') {
            tags = filterData.tags;
            valueArray = [];

            for (index in tags) {
              tag = tags[index];
              valueArray.push(tag.name);
            }

            activeFilters.push({
              type: 'tags',
              value: valueArray.join(', '),
              label: app.tl('Tags')
            });
          }

          if (name === 'categories') {
            categories = filterData.categories;
            valueArray = [];

            for (index in categories) {
              category = categories[index];
              valueArray.push(category.name);
            }

            activeFilters.push({
              type: 'categories',
              value: valueArray.join(', '),
              label: app.tl('Categories')
            });
          }
        }

        return this.selectedFilterList(activeFilters);
      };

      if (useRemoteData) {
        // Updates the selectedFilterList array that holds data on the parameters currently being filtered by
        // Used for display purposes, e.g. the backlog tipped filter button
        return app.api.get(`me/savedFilters.json?section=${this.section()}&includeTemporary=true&fulldata=true`).then(result => {
          var f;

          if (result.response.savedFilters[0] != null) {
            f = result.response.savedFilters[0];
            return updateFilterListData(f.fulldata);
          }
        });
      } else {
        updateFilterListData(ko.mapping.toJS(this.fulldata));
        return Promise.resolve();
      }
    }

    _clearProjectSpecificValues() {
      var name, ref, results, value;
      ref = this.privateParameters;
      results = [];

      for (name in ref) {
        if (!hasProp.call(ref, name)) continue;
        value = ref[name];
        results.push(this.ResetValue(name));
      }

      return results;
    }

    _resetToOriginalUniqueValues() {
      var name, ref, results, value;
      ref = this.privateParameters;
      results = [];

      for (name in ref) {
        if (!hasProp.call(ref, name)) continue;
        value = ref[name];
        results.push(this.parameters[name](value));
      }

      return results;
    }

    _buildSubscriptions() {
      this.subscriptions.push(this.currentChanges = ko.pureComputed(() => {
        var arr, name, ref, value;
        arr = [];
        ref = this.parameters;

        for (name in ref) {
          if (!hasProp.call(ref, name)) continue;
          value = ref[name];

          if (this._shouldValidateValue(name, value) && !this._valueHasChanged(name, value(), value.originalValue())) {
            arr.push(name);
          }
        }

        return arr;
      })); // Determines, if any of the filter parameters has a value different
      // from the corresponding `originalValue`. There's no memory leak here
      // because it's `pureComputed`. Additionally, it depends only on local observables.

      this.subscriptions.push(this.edited = ko.pureComputed(() => {
        return this.currentChanges().length;
      }));
      this.subscriptions.push(this.activeParameters = ko.computed(() => {
        var arr, name, ref, value;
        arr = [];
        ref = this.parameters;

        for (name in ref) {
          if (!hasProp.call(ref, name)) continue;
          value = ref[name];

          if (this._isFilterSectionActive(name, value)) {
            arr.push({
              name: name,
              value: value
            });
          }
        }

        return arr;
      })); // Determines, if any of the filter parameters has a value different
      // from the corresponding `defaultValue`. There's no memory leak here
      // because it's `pureComputed`. Additionally, it depends only on local observables.

      return this.subscriptions.push(this.active = ko.pureComputed(() => {
        return this.activeParameters().length > 0;
      }));
    }

    _getSections() {
      var createdSections, i, isActive, len, ref, reset, s, toggleCollapsed;

      if (this.sections().length) {
        return;
      }

      reset = section => {
        var i, len, p, ref;
        ref = section.parameters;

        for (i = 0, len = ref.length; i < len; i++) {
          p = ref[i];
          this.ResetValue(p);
        } // Alert other components that the specific filter section has been reset


        return app.ko.postbox.publish('savedFilter-reset', {
          uniqueId: this.uniqueId,
          section: section.type
        });
      };

      toggleCollapsed = section => {
        var s;
        section.collapsed(!section.collapsed());
        app.cache.set(this.collapsedCacheKey, function () {
          var i, len, ref, results;
          ref = this.sections();
          results = [];

          for (i = 0, len = ref.length; i < len; i++) {
            s = ref[i];

            if (s.collapsed()) {
              results.push(s.type);
            }
          }

          return results;
        }.call(this));
      };

      isActive = function (sectionParameterNames) {
        var i, len, name, value;

        for (i = 0, len = sectionParameterNames.length; i < len; i++) {
          name = sectionParameterNames[i];
          value = this.parameters[name];

          if (this._isFilterSectionActive(name, value)) {
            return true;
          }
        }

        return false;
      };

      createdSections = (ref = app.filterConfig.createSections(this.section())) != null ? ref : [];

      for (i = 0, len = createdSections.length; i < len; i++) {
        s = createdSections[i];
        s.toggleCollapsed = toggleCollapsed;
        s.active = ko.pureComputed(isActive.bind(this, s.parameters));
        s.reset = reset;
      }

      return this.sections(createdSections);
    }

    _getClone() {
      var fulldata, mapped, toJS;
      toJS = ko.toJS(this);
      fulldata = this.fulldata;
      mapped = ko.mapping.fromJS(toJS, mappingConfig, {});
      mapped.fulldata = fulldata;
      return mapped;
    }

    dispose() {
      var i, len, ref, s; // When components are being disposed, the instance of filters also need to be disposed as part of cleanup
      // However this causes issues with the filter sidebar often removing subscribers
      // related to the paired listing components use of filter properties.
      // Therefore we should only kill subscribers if it's the last instance of a given filter.

      this._instanceCount--;

      if (this._instanceCount <= 0) {
        ref = this.subscriptions;

        for (i = 0, len = ref.length; i < len; i++) {
          s = ref[i];

          if (s.dispose != null) {
            s.dispose();
          }
        }

        return this.subscriptions = [];
      }
    }

  };
});