import { modalSizes } from '../FModal/constants';

export { modalSizes as dialogSizes } from '../FModal/constants';

export const defaultDialogSize = modalSizes.SM;

export const dialogVariants = {
  informative: 'informative',
  warning: 'warning',
};

export const defaultDialogVariant = dialogVariants.informative;
