<template>
  <div
    class="w-entity-icon"
    :class="[
      { 'w-entity-icon-background': !!backgroundClass },
      { 'w-entity-icon--invalid': isIconInvalid },
      backgroundClass,
    ]"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
  >
    <img
      v-if="resolvedItemType === 'reaction' && reactionType"
      loading="lazy"
      :src="`/tko/public/images/reactions/${reactionType}.png`"
    />
    <component v-else :id="icon" :is="component" :style="svgStyles" />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import ProjectsIcon from '@teamwork/common-icons/dist/v-projects-icon';
import TeamworkIcon from '@teamwork/common-icons/dist/v-teamwork-icon';

// TODO: abstract this somewhere
const itemTypes = Object.freeze({
  comment: 'comment',
  company: 'company',
  event: 'event',
  file: 'file',
  form: 'form',
  integration: 'integration',
  invoice: 'invoice',
  link: 'link',
  message: 'message',
  message_comment: 'message_comment',
  milestone: 'milestone',
  notebook: 'notebook',
  project: 'project',
  proof: 'proof',
  reaction: 'reaction',
  status: 'status',
  task: 'task',
  tasklist: 'tasklist',
  team: 'team',
  user: 'user',
});

// TODO: abstract this somewhere
const reactionTypes = Object.freeze({
  frown: 'frown',
  joy: 'joy',
  heart: 'heart',
  like: 'like',
  dislike: 'dislike',
});

// TODO: abstract this somewhere
const itemTypeIconMap = Object.freeze({
  [itemTypes.comment]: 'comment-alt-lines',
  [itemTypes.company]: 'company',
  [itemTypes.event]: 'calendar',
  [itemTypes.file]: 'file',
  [itemTypes.form]: 'form',
  [itemTypes.integration]: 'atom-alt',
  [itemTypes.invoice]: 'invoice',
  [itemTypes.link]: 'link',
  [itemTypes.message]: 'envelope',
  [itemTypes.message_comment]: 'reply',
  [itemTypes.milestone]: 'milestone-tw',
  [itemTypes.notebook]: 'book',
  [itemTypes.proof]: 'proofs',
  [itemTypes.project]: 'project',
  [itemTypes.budget]: 'circle-dollar-light',
  [itemTypes.status]: 'bullhorn',
  [itemTypes.task]: 'check-square',
  [itemTypes.tasklist]: 'checklist',
  [itemTypes.team]: 'users',
  [itemTypes.user]: 'user',
});

// TODO: abstract this somewhere
function getItemTypeGroup(itemType) {
  if (typeof itemType !== 'string') {
    return '';
  }

  const type = itemType.toLowerCase();

  if (type === 'message_comment') {
    return 'message_comment';
  }

  if (type.includes('comment')) {
    return 'comment';
  }

  if (type === 'fileversion') {
    return 'file';
  }

  if (type === 'billinginvoice') {
    return 'invoice';
  }

  if (type === 'projectupdate') {
    return 'project';
  }

  if (type === 'statusrequest' || type === 'statusupdate') {
    return 'status';
  }

  if (type === 'subtask') {
    return 'task';
  }

  if (type === 'person') {
    return 'user';
  }

  if (
    ['prooffeedback', 'proofdecision', 'proofversion', 'proofrole'].includes(
      type,
    )
  ) {
    return 'proof';
  }

  return type;
}

// TODO: abstract this somewhere
export function getColorForEntity(entityType) {
  if (!entityType) {
    return undefined;
  }

  // TODO: use design tokens
  const colors = Object.freeze({
    comment: '#95e1c1',
    company: '#e1e6ee',
    event: '#bba2ff',
    file: '#a1e9f0',
    form: '#dfa275',
    integration: '#ffffff',
    invoice: '#646d7d',
    link: '#ffe29b',
    message: '#ffa0dd',
    message_comment: '#ffa0dd',
    milestone: '#8f9fe7',
    notebook: '#ed818e',
    project: '#94c7fb',
    status: '#e1e6ee',
    task: '#ffad8d',
    tasklist: '#FFC63C',
    team: '#007AC0',
    user: '#C7D9F3',
    projectUpdate: '#94c7fb',
  });

  return colors[entityType] || undefined;
}

export default {
  name: 'EntityIcon',
  props: {
    itemType: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        const resolvedValue = getItemTypeGroup(value);
        const valid =
          resolvedValue && Object.keys(itemTypes).includes(resolvedValue);

        if (!valid) {
          console.warn(value);
        }

        return valid;
      },
    },
    reactionType: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return (
          value && Object.keys(reactionTypes).includes(value.toLowerCase())
        );
      },
    },
    size: {
      type: Number,
      required: false,
      default: 22,
    },
    disableBackground: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommonIcon,
    ProjectsIcon,
    TeamworkIcon,
  },
  computed: {
    resolvedItemType() {
      return getItemTypeGroup(this.itemType);
    },

    icon() {
      return itemTypeIconMap[this.resolvedItemType] || '';
    },

    svgScalePercentage() {
      return this.size < 28 ? '60%' : '50%';
    },

    svgStyles() {
      if (!this.icon) {
        return {};
      }

      return {
        width: this.svgScalePercentage,
        height: this.svgScalePercentage,
        'max-height': this.svgScalePercentage,
        'max-width': this.svgScalePercentage,
      };
    },

    backgroundClass() {
      if (this.disableBackground) {
        return undefined;
      }

      return `w-entity-icon-background--${
        this.resolvedItemType || (this.reactionType && 'reaction')
      }`;
    },

    component() {
      if ([itemTypes.milestone].includes(this.resolvedItemType)) {
        return 'TeamworkIcon';
      }

      if (
        [
          itemTypes.form,
          itemTypes.invoice,
          itemTypes.project,
          itemTypes.tasklist,
        ].includes(this.resolvedItemType)
      ) {
        return 'ProjectsIcon';
      }

      return 'CommonIcon';
    },

    isIconInvalid() {
      return !this.icon && this.resolvedItemType !== 'reaction';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/_variables';

$typeColors: (
  comment: #95e1c1,
  company: #e1e6ee,
  event: #bba2ff,
  file: #a1e9f0,
  form: #dfa275,
  integration: #ffffff,
  invoice: #646d7d,
  link: #ffe29b,
  message: #ffa0dd,
  message_comment: #ffa0dd,
  milestone: #8f9fe7,
  notebook: #ed818e,
  proof: #94c7fb,
  project: #94c7fb,
  status: #e1e6ee,
  task: #ffad8d,
  tasklist: #ffad8d,
  team: #e1e6ee,
  user: #e1e6ee,
  reaction: #ffffff,
);

.w-entity-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 22px;
  height: 22px;

  &--invalid {
    display: none;
  }

  svg {
    fill: #646d7d; // Use 'Neutral 50' design token when available
  }

  &-background {
    svg {
      fill: #0b0e1f; // Use 'Neutral 60' design token when available
    }

    @each $type, $color in $typeColors {
      &--#{$type} {
        background-color: $color;
      }
    }
  }

  img {
    max-width: 80%;
  }
}
</style>
