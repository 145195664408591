<template>
  <div
    :class="[
      'dependency-picker-item',
      { 'dependency-picker-item__selected': selected },
    ]"
    @click="addNewDependency(task)"
  >
    <button class="dependency-picker-item__icon">
      <CommonIcon id="check" />
    </button>

    <EllipsisLabel
      tooltip-class="dependency-picker-item__tooltip"
      :label="task.name"
      class="dependency-picker-item__task-name"
    />
  </div>
</template>
<script>
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import EllipsisLabel from '@widgets/EllipsisLabel';

export default {
  name: 'DependencyPickerItem',
  components: {
    CommonIcon,
    EllipsisLabel,
  },
  props: {
    task: { type: Object, default: () => {} },
    selected: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    function addNewDependency(task) {
      const { id, name } = task;
      emit('add-new-predecessor', { id, name });
    }

    return {
      addNewDependency,
    };
  },
};
</script>
<style lang="scss" scoped>
$text-color: #0b0e1f;
$border-color: #c5cee0;
.dependency-picker-item {
  display: flex;
  padding: 8px 12px;
  cursor: pointer;

  &__selected {
    pointer-events: none;

    .dependency-picker-item__task-name {
      color: #c5cee0;
      user-select: none;
    }
    .dependency-picker-item__icon > svg {
      fill: #c5cee0;
    }
  }

  &:hover {
    background-color: #f5f7fa;
  }

  &__icon {
    display: inline-flex;
    border: 1px solid $border-color;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: $text-color;

    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 8px;
    font-size: 20px;

    svg {
      width: 12px;
      height: 12px;
      fill: #8f9bb3;
    }
  }
  &__task-name {
    color: #0b0e1f;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
<style lang="scss">
.dependency-picker-item__tooltip {
  z-index: 15002;
}
</style>
