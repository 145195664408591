<template>
  <div class="overflow-y-scroll">
    <div class="mx-6 mb-10">
      <HelpCenterMenu :title="breadcrumb">
        <HelpCenterSearchSkeleton class="!mt-5" v-if="isLoading" />
        <HelpCenterMenuItem
          v-else
          v-for="(item, index) in getItems()"
          :key="index"
          :label="item.label"
          :icon="item.icon"
          @click="
            item.icon === 'subcategory'
              ? $emit(
                  'subcategoryClicked',
                  item.slug,
                  `${breadcrumb} / ${item.name}`,
                )
              : $emit(
                  'articleClicked',
                  item.categorySlug,
                  item.slug,
                  item.title,
                )
          "
        />
      </HelpCenterMenu>
    </div>
  </div>
</template>
<script>
import HelpCenterMenu from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenu.vue';
import HelpCenterMenuItem from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenuItem.vue';
import HelpCenterSearchSkeleton from '@/platform/components/side-nav/panels/help-center/common/HelpCenterSearchSkeleton.vue';

export default {
  components: {
    HelpCenterMenu,
    HelpCenterMenuItem,
    HelpCenterSearchSkeleton,
  },
  setup(props) {
    const getItems = () => {
      const articles = props.articles.map((article) => ({
        ...article,
        label: article.title,
      }));

      if (props.subcategories.length < 1) {
        return articles;
      }

      return [].concat(
        props.subcategories.map((subcategory) => ({
          ...subcategory,
          icon: 'subcategory',
          label: subcategory.name,
        })),
        articles,
      );
    };

    return {
      getItems,
    };
  },
  props: {
    isLoading: { type: Boolean, default: true, required: true },
    breadcrumb: { type: String, default: '' },
    subcategories: { type: Array, default: () => [] },
    articles: { type: Array, default: () => [] },
  },
};
</script>
