// custom knockout loader for lazy loading TKO component chunks through webpack.
import ko from 'knockout';
import getComponentChunk from '@tko/config/component-chunk-map';
import componentChunkMap from '@tko/config/component-chunks';
import migrationMap from '@tko/migrationMap';
import { shouldMeasurePerformance } from '@/utils/helpers/performance';

async function importComponentFromChunk(name) {
  const componentChunk = await getComponentChunk(name);
  if (!componentChunk) {
    return null;
  }
  const component = await componentChunk[name];
  return component;
}

export default () => {
  async function handleTkoHMR(event) {
    if (event.origin !== window.location.origin) {
      return;
    }
    if (event.data.tkoHMR) {
      ko.components.unregister(event.data.tkoHMR);
      ko.components.clearCachedDefinition(event.data.tkoHMR);
      // Load updated hot module
      const component = await importComponentFromChunk(event.data.tkoHMR);
      // Register and bind new ko component
      ko.components.register(event.data.tkoHMR, component);
      // LetkoComponent know to reload
      window.postMessage({ tkoHMRRefresh: true });
    }
  }

  if (module.hot) {
    // Stop TKO components from bubbling up HMR
    module.hot.accept(['@tko/config/component-chunk-map'], () => false);
    window.addEventListener('message', handleTkoHMR, false);
  }

  ko.components.loaders.unshift({
    getConfig: (name, callback) => {
      if (shouldMeasurePerformance) {
        const chunkName = componentChunkMap[name];
        performance.mark(`tko-component-call:${name}&chunk:${chunkName}`);
      }

      // if component has been migrated - load in placeholder to avoid dodgy renders
      if (migrationMap[name] && migrationMap[name].migrated) {
        import('@tko/src/app/components/hybrid-placeholder/def')
          .then((m) => m.default)
          .then(callback);
      }

      // this loads the chunk name from component-chunk-map.js
      // then the chunk file from ./chunks/{name}.js
      // and finally returns the component via callback
      importComponentFromChunk(name).then((component) => {
        // check if the component doesn't belong to the chunk
        if (!component || !ko.components.isRegistered(name)) {
          return;
        }

        // @TODO: Drop support for fragments
        const requireObject = {
          viewModel: component.default
            ? component.default.viewModel
            : component.viewModel,
          template: component.default
            ? component.default
            : component.template || component,
        };

        callback(requireObject);
      });
    },
    loadComponent(name, config, callback) {
      ko.components.defaultLoader.loadComponent(name, config, (definition) => {
        // performance measure for TKO component loading
        if (shouldMeasurePerformance) {
          const chunkName = componentChunkMap[name];

          performance.mark(`tko-component-loaded:${name}&chunk:${chunkName}`);
          performance.measure(
            `tko-component:${name}&chunk:${chunkName}`,
            `tko-component-call:${name}&chunk:${chunkName}`,
            `tko-component-loaded:${name}&chunk:${chunkName}`,
          );
        }

        callback(definition);
      });
    },
  });
};
