<template>
  <div>
    <LogTimeButton
      v-if="shouldOpenNewModal"
      :button-classes="['direction-left', { 'is-active': loggedTime !== '0h' }]"
      :tooltip="$t('Log time')"
      icon="clock"
      @click.native="openNewModal"
    />
    <LogTimeLoggerPopover
      v-else
      :task="task"
      :key="loggedTime"
      :open.sync="isPopoverOpen"
      :disabled="disabled"
      :source="'task_details_header'"
      @update="(time) => $emit('update', time)"
    >
      <LogTimeButton
        :button-classes="[
          'direction-left',
          { 'is-active': isPopoverOpen || loggedTime !== '0h' },
        ]"
        :tooltip="$t('Log time')"
        icon="clock"
        @click.native="trackLogTime"
      />
    </LogTimeLoggerPopover>
  </div>
</template>
<script>
import LogTimeButton from '@widgets/TaskDetailWidgets/LogTimeButton';
import LogTimeLoggerPopover from '@widgets/LogTimeLoggerPopover/LogTimeLoggerPopover';

import useExperimentA15 from '@/platform/composables/useExperimentA15';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';
import usePendo from '@/platform/composables/usePendo';

export default {
  name: 'LogTimeLogger',
  components: {
    LogTimeButton,
    LogTimeLoggerPopover,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loggedTime: {
      type: String,
      required: true,
    },
    showTimeList: {
      type: Boolean,
      default: false,
    },
    shouldOpenNewModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { EXP_A15_COMMON_METRICS } = useExperimentA15();
    const { trackPendoEvent } = usePendo();
    const { postMessageToLightspeed } = useLightspeedBridge();

    const trackLogTime = () => {
      trackPendoEvent({
        eventName: 'TASK_DETAILS_EVENT',
        commonMetrics: EXP_A15_COMMON_METRICS,
        metadata: {
          event_action: 'log_time_clicked',
          event_source: 'task_details_header',
        },
      });
    };

    function openNewModal() {
      postMessageToLightspeed('twa:open-dialog', {
        dialogName: 'TimeTimelogAddOrEditDialog',
        dialogProps: { task: this.task, openFromTWA: true },
      });
      trackLogTime();
    }

    return {
      trackLogTime,
      openNewModal,
    };
  },
  computed: {
    isPopoverOpen: {
      get() {
        return this.open;
      },
      set(newVal) {
        this.$emit('update:open', newVal);
      },
    },
  },
};
</script>
<style>
.log-time__wrapper div {
  height: 100%;
}
</style>
