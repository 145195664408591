<template>
  <svg
    :style="{
      'z-index': `${zIndexOffset}`,
      position: 'absolute',
      top: `-${borderOffset}px`,
      left: `${targetOffset}px`,
    }"
    :width="`${level * targetWidth + targetWidth}px`"
    :height="rowHeight + verticalOffset + (lastChild ? verticalOffset : 0)"
  >
    <g v-for="guide in level" :key="`${guide}-path`">
      <path
        v-if="guide === level"
        :stroke="strokeColor"
        stroke-width="1"
        fill="none"
        :d="`
          M${(guide - 1) * targetWidth + targetWidth / 2} 0
          L${(guide - 1) * targetWidth + targetWidth / 2}
          ${rowHeight / 2 - curveOffset}
          C${(guide - 1) * targetWidth + targetWidth / 2}
          ${rowHeight / 2 + verticalOffset}
          ${level * targetWidth + horizontalOffset}
          ${(rowHeight + verticalOffset) / 2},
          ${level * targetWidth + horizontalOffset}
          ${(rowHeight + verticalOffset) / 2}
          `"
      />
      <path
        v-if="!leafLevels.includes(guide)"
        :stroke="strokeColor"
        stroke-width="1"
        fill="none"
        :d="`
          M${(guide - 1) * targetWidth + targetWidth / 2} 0
          V${rowHeight + verticalOffset + (lastChild ? verticalOffset : 0)}
          `"
      />
    </g>
    <line
      v-if="open"
      :style="{
        position: 'absolute',
        left: `${targetOffset}px`,
      }"
      :x1="`${level * targetWidth + targetWidth / 2}px`"
      :y1="`${rowHeight + verticalOffset}px`"
      :x2="`${level * targetWidth + targetWidth / 2}px`"
      :y2="`${rowHeight / 2 + targetWidth / 2 - verticalOffset}px`"
      :stroke="strokeColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'TasksIndentation',
  props: {
    targetWidth: {
      type: Number,
      default: 30,
    },
    targetOffset: {
      type: Number,
      default: 30,
    },
    rowHeight: {
      type: Number,
      default: 64,
    },
    horizontalOffset: {
      type: Number,
      default: 3,
    },
    borderOffset: {
      type: Number,
      default: 1,
    },
    zIndexOffset: {
      type: Number,
      default: -1,
    },
    verticalOffset: {
      type: Number,
      default: 3,
    },
    curveOffset: {
      type: Number,
      default: 5,
    },
    level: {
      type: Number,
      default: 0,
    },
    lastChild: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    strokeColor: {
      type: String,
      default: '#C5CEE0',
    },
    leafLevels: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
