<template>
  <PopoverMenuContainer class="w-[254px]">
    <ProfilePopoverItem />

    <hr class="mt-0 mx-200 bg-palette-neutral-20" />

    <ul id="side-nav-profile-menu" role="menu" class="list-none p-0 m-0">
      <EditDetailsPopoverItem />
      <SettingsPopoverItem v-if="canViewSettings" />
      <SubscriptionPopoverItem v-if="isSiteAdmin" />
      <ClockInOutItem v-if="isAccount && clockInOutEnabled" />
      <li aria-hidden="true">
        <hr class="mx-200 bg-palette-neutral-20" />
      </li>
      <MyTasksPopoverItem />
      <MyAppsPopoverItem v-if="isAccount" />
      <AppsIntegrationsPopoverItem v-if="isSiteAdmin" />
      <ProfileMenuMorePopoverItem @toggle="(isOpen) => $emit('more', isOpen)" />
      <li>
        <hr class="mx-200 bg-palette-neutral-20" />
      </li>
      <ProductionSwitcherPopoverItem />

      <LogoutPopoverItem />
    </ul>
  </PopoverMenuContainer>
</template>
<script>
import { useCurrentUser } from '@teamwork/use';
import { computed } from 'vue-demi';
import { useFeatures } from '@/platform/composables/useFeatures';
import PopoverMenuContainer from './PopoverMenuContainer.vue';
import ProfilePopoverItem from './ProfilePopoverItem.vue';
import EditDetailsPopoverItem from './EditDetailsPopoverItem.vue';
import SettingsPopoverItem from './SettingsPopoverItem.vue';
import SubscriptionPopoverItem from './SubscriptionPopoverItem.vue';
import MyTasksPopoverItem from './MyTasksPopoverItem.vue';
import MyAppsPopoverItem from './MyAppsPopoverItem.vue';
import AppsIntegrationsPopoverItem from './AppsIntegrationsPopoverItem.vue';
import LogoutPopoverItem from './LogoutPopoverItem.vue';

import ClockInOutItem from './ClockInOutItem.vue';
import ProductionSwitcherPopoverItem from './ProductionSwitcherPopoverItem.vue';
import ProfileMenuMorePopoverItem from './ProfileMenuMorePopoverItem.vue';

import { usePermissions } from '@/platform/composables/usePermissions';

export default {
  components: {
    PopoverMenuContainer,
    ProfilePopoverItem,
    EditDetailsPopoverItem,
    SettingsPopoverItem,
    SubscriptionPopoverItem,
    MyTasksPopoverItem,
    MyAppsPopoverItem,
    AppsIntegrationsPopoverItem,
    LogoutPopoverItem,
    ClockInOutItem,
    ProductionSwitcherPopoverItem,
    ProfileMenuMorePopoverItem,
  },
  setup() {
    const user = useCurrentUser();

    const { clockInOutEnabled } = useFeatures();

    const {
      isOwnerAdmin,
      canViewGlobalSettingsTags,
      canViewGlobalSettingsCustomfields,
    } = usePermissions();

    const canViewSettings = computed(() => {
      return (
        isOwnerAdmin.value ||
        canViewGlobalSettingsTags.value ||
        canViewGlobalSettingsCustomfields.value
      );
    });

    const isAccount = computed(() => user.value.userType === 'account');
    const isSiteAdmin = computed(
      () => user.value.inOwnerCompany && user.value.administrator,
    );

    return {
      isAccount,
      isSiteAdmin,
      clockInOutEnabled,
      canViewSettings,
    };
  },
};
</script>
