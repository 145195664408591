<template>
  <label
    class="text-sm relative m-0 shrink-0 flex max-w-full"
    :class="{ 'cursor-pointer': !disabled }"
    :for="id"
    role="radio"
    :aria-checked="isChecked"
  >
    <input
      :name="inputName"
      :id="id"
      type="radio"
      :value="value"
      :checked="isChecked"
      :disabled="disabled"
      :required="required"
      class="absolute w-4 h-4 opacity-0"
      @change="onChange"
      :tabindex="isChecked ? -1 : 0"
      v-bind="$attrs"
    />
    <span :class="radioClasses" />
    <slot />
  </label>
</template>
<script>
import { computed } from 'vue-demi';
import { useRadioGroup } from '../FRadioGroup/useRadioGroup';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  inheritAttrs: false,
  name: 'FRadio',
  props: {
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: () => Math.floor(Math.random() * 1000).toString(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    // NOTE: if we wrap inside RadioGroup component
    const { groupName, groupValue, groupOnChange } = useRadioGroup();
    const isGroup = computed(() => Boolean(groupValue.value));
    const inputName = groupName.value || props.name;

    const isChecked = computed(() => {
      return isGroup.value
        ? groupValue.value === props.value
        : props.modelValue === props.value;
    });

    const onChange = () => {
      if (isGroup.value) {
        groupOnChange(props.value);
      } else {
        emit('change', props.value);
      }
    };

    // NOTE: Some helper warnings for developers/consumers
    if (!groupValue.value && !props?.modelValue) {
      console.log(
        `[FRAGMENT]: You must provide a 'modelValue' for <Radio v-model="modelValue"/> or <RadioGroup v-model="modelValue"/>`,
      );
    }
    if (groupValue.value && props.modelValue) {
      console.log(
        `[FRAGMENT]: You must provide a 'modelValue' _only_ for <RadioGroup v-model="stringVal"/>`,
      );
    }

    const radioClasses = computed(() => {
      const classes = [
        'inline-block mr-2 w-4 h-4 rounded-full relative border border-solid shrink-0 mt-0.5',
        // hover shadow
        'hover:after:content-[""] after:w-7 after:h-7 after:rounded-full after:absolute after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:z-[-1]',
        {
          // RADIO
          // un-checked
          'border-[color:var(--token-c-radio-color-shadow-background)]':
            !isChecked.value,
          'bg-white': !isChecked.value,
          // checked
          'border-primary': isChecked.value && !props.disabled,
          // disabled un-checked
          'bg-[color:var(--token-c-radio-color-background)] border-[color:var(--token-c-radio-color-border)]':
            props.disabled && !isChecked.value,
          'border-[color:var(--token-c-radio-color-border)]': props.disabled,
          // disabled checked
          'bg-white border-[color:var(--token-c-radio-color-border)]':
            props.disabled && isChecked.value,

          // TICK
          // un-checked
          // checked
          'before:content-[""]': isChecked.value,
          'before:w-2': isChecked.value,
          'before:h-2': isChecked.value,
          'before:bg-primary': isChecked.value && !props.disabled,
          'before:rounded-full': isChecked.value,
          'before:absolute': isChecked.value,
          'before:top-1/2': isChecked.value,
          'before:left-1/2': isChecked.value,
          'before:transform': isChecked.value,
          'before:-translate-x-1/2 before:-translate-y-1/2': isChecked.value,
          'before:z-20': isChecked.value,
          // disable
          'before:bg-[color:var(--token-c-radio-color-border)]': props.disabled,

          // SHADOW
          // un-checked
          'after:bg-[color:var(--token-c-radio-color-background)]':
            !isChecked.value && !props.disabled,
          // un-checked active
          'active:after:bg-[color:var(--token-c-radio-color-shadow-background)]':
            !isChecked.value && !props.disabled,
          // checked
          'after:bg-[color:var(--token-c-radio-color-active-strong)]':
            isChecked.value && !props.disabled,
          // checked active
          'active:after:bg-[color:var(--token-c-radio-color-active-strongest)]':
            isChecked.value && !props.disabled,
        },
      ];

      return classes;
    });

    return {
      onChange,
      inputName,
      isChecked,
      radioClasses,
      isGroup,
    };
  },
};
</script>
<style scoped>
input:focus-within:checked + span::after {
  content: '';
  background-color: var(--token-c-radio-color-active-strongest);
}
</style>
