<template>
  <div class="item-picker-no-data">
    <div class="item-picker-no-data__wrapper">
      <slot name="image">
        <img
          class="item-picker-no-data__icon"
          src="//twa-prod.teamwork.com/tko/public/images/blank-metric/task.svg"
        />
      </slot>

      <h3 v-if="title" class="item-picker-no-data__title">{{ title }}</h3>
      <p v-if="description" class="item-picker-no-data__description">
        {{ description }}
      </p>
      <!-- <button
        v-if="buttonText"
        @click="$emit('on-add-new')"
        class="btn item-picker-no-data__btn"
      >
        <CommonIcon id="add" color="white" />
        <span>{{ buttonText }}</span>
      </button> -->
    </div>
  </div>
</template>
<script>
// import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';

export default {
  name: 'ItemPickerNoData',
  props: {
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    buttonText: { type: String, default: '' },
  },
  // components: { CommonIcon },
};
</script>
<style lang="scss" scoped>
.item-picker-no-data {
  width: 100%;
  display: flex;
  justify-content: center;

  &__wrapper {
    text-align: center;
  }

  &__icon {
    width: 52px;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  &__title {
    font-size: 20px;
    line-height: 36px;
    font-weight: 600;
    color: #0b0e1f;
    margin-bottom: 0px;
  }

  &__description {
    font-size: 14px;
    line-height: 24px;
    color: #0b0e1f;
    max-width: 235px;
  }

  &__btn {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #4461d7;
    border-color: #4461d7;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    .w-svg-sprite {
      margin-right: 2px;
    }
    &:hover {
      color: white;
      background-color: darken(#4461d7, 7%);
    }
  }
}
</style>
