<template>
  <Popover @update:open="$emit('update:open', $event)" v-bind="$attrs">
    <slot />
    <template slot="popover" v-if="hasMenu">
      <Menu>
        <slot name="menu" />
      </Menu>
    </template>
  </Popover>
</template>

<script>
import Popover from '@widgets/Popover';
import Menu from '@widgets/Menu/Menu';

export default {
  name: 'MenuPopover',
  display: 'MenuPopover',
  components: {
    Menu,
    Popover,
  },
  computed: {
    hasMenu() {
      return Boolean(this.$slots.menu);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-popover {
  display: inline;
}
</style>
