import { useCurrentAccount, useCurrentUser } from '@teamwork/use';
import { computed, inject, provide, shallowRef, watch } from 'vue-demi';
import usePreferenceActions from './usePreferenceActions';

const usePreferencesSymbol = Symbol('usePreferences');

function useBooleanType(defaultValue = false) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'boolean': {
          return value;
        }
        case 'string': {
          switch (value.toLowerCase()) {
            case 'true':
            case 'yes': {
              return true;
            }
            case 'false':
            case 'no': {
              return false;
            }
            default: {
              return defaultValue;
            }
          }
        }
        default: {
          return defaultValue;
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

function useStringType(defaultValue = '') {
  return {
    parse(value) {
      switch (typeof value) {
        case 'string': {
          return value;
        }
        default: {
          return defaultValue;
        }
      }
    },
    serialize(value) {
      return value.toString();
    },
  };
}

function useObjectType(defaultValue = {}) {
  return {
    parse(value) {
      switch (typeof value) {
        case 'object': {
          if (value !== null && !Array.isArray(value)) {
            return value;
          }
          return defaultValue;
        }
        case 'string': {
          try {
            const parsedValue = JSON.parse(value);
            if (
              typeof parsedValue === 'object' &&
              parsedValue !== null &&
              !Array.isArray(parsedValue)
            ) {
              return { ...defaultValue, ...parsedValue };
            }
            return defaultValue;
          } catch (error) {
            return defaultValue;
          }
        }
        default: {
          return defaultValue;
        }
      }
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

function useArrayType(defaultValue = {}) {
  return {
    parse(value) {
      if (Array.isArray(value)) {
        return value;
      }

      if (typeof value !== 'string' || !value) {
        return defaultValue;
      }
      try {
        const parsedValue = JSON.parse(value);

        if (Array.isArray(parsedValue)) {
          return parsedValue;
        }
      } catch (error) {
        //
      }

      return defaultValue;
    },
    serialize(value) {
      return JSON.stringify(value);
    },
  };
}

function useScopeObject(scope) {
  switch (scope) {
    case 'account': {
      return useCurrentAccount();
    }
    case 'me': {
      return useCurrentUser();
    }
    default: {
      console.warn(`usePreferences: Unsupported scope: "${scope}"`);
      return undefined;
    }
  }
}

let lastPromise = Promise.resolve();

function useServerStore({ scope, name }) {
  const scopeObject = useScopeObject(scope);
  const { savePreferences } = usePreferenceActions();

  return {
    value: computed(() => scopeObject.value?.preferences?.[name]),
    save(value) {
      // Parallel API calls to update pref is causing an issue and overwritting prefs on server sometimes
      // To avoid this, we are queuing the calls as of now. Ideally, there should be a single debounced call
      // @TODO: Refactor logic to update prefs on server with min API calls
      lastPromise = lastPromise
        .catch(() => undefined)
        .then(() => savePreferences(scope, { [name]: value }));
      return lastPromise;
    },
  };
}

function usePreference({ type, store }) {
  const loadedValue = store.value;
  const savedValue = shallowRef();
  const pendingValue = shallowRef();
  let promise;

  return computed({
    get() {
      if (pendingValue.value !== undefined) {
        return pendingValue.value;
      }
      if (savedValue.value !== undefined) {
        return savedValue.value;
      }
      return type.parse(loadedValue.value);
    },
    set(value) {
      const localPromise = store.save(type.serialize(value)).then(
        () => {
          if (promise === localPromise) {
            promise = undefined;
            pendingValue.value = undefined;
            savedValue.value = value;
          }
        },
        () => {
          if (promise === localPromise) {
            promise = undefined;
            pendingValue.value = undefined;
          }
        },
      );
      promise = localPromise;
      pendingValue.value = value;
    },
  });
}

export function providePreferences({ accountInSync, userInSync }) {
  const spacesAddonBannerDismissedDate = usePreference({
    type: useStringType(),
    store: useServerStore({
      scope: 'me',
      name: 'spacesAddonBannerDismissedDate',
    }),
  });
  const sampleProjectsVisible = usePreference({
    type: useArrayType([]),
    store: useServerStore({
      scope: 'me',
      name: 'sampleProjectsVisible',
    }),
  });
  const sampleProjectIds = usePreference({
    type: useArrayType([]),
    store: useServerStore({
      scope: 'me',
      name: 'sampleProjectIds',
    }),
  });
  // Watcher added to update user preference to valid date format if they currently have an invalid value stored
  watch(spacesAddonBannerDismissedDate, (value) => {
    const isValid = !Number.isNaN(Date.parse(value));

    if (value && !isValid) {
      spacesAddonBannerDismissedDate.value = new Date();
    }
  });

  const initialized = shallowRef(false);
  const unwatch = watch(
    [accountInSync, userInSync],
    () => {
      if (accountInSync.value && userInSync.value) {
        initialized.value = true;
        // prevent potential undefined value on first call
        queueMicrotask(() => {
          unwatch();
        });
      }
    },
    { immediate: true },
  );

  provide(usePreferencesSymbol, {
    initialized,
    projectsPanelActiveTab: usePreference({
      type: useStringType('recent'),
      store: useServerStore({
        scope: 'me',
        name: 'recentProjectsPanelActiveTab',
      }),
    }),
    shouldMinimizeSidebar: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'shouldMinimizeSidebar',
      }),
    }),
    shouldShowRedesignedSidebar: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'shouldShowRedesignedSidebar',
      }),
    }),
    postponeSwitchEveryoneModalUntil: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'me',
        name: 'postponeSwitchEveryoneModalUntil',
      }),
    }),
    sidebarItemState: usePreference({
      type: useObjectType({
        search: {
          docked: true,
          order: 1,
        },
        mywork: {
          docked: true,
          order: 2,
        },
        projects: {
          docked: true,
          order: 3,
        },
        time: {
          docked: true,
          order: 4,
        },
        everything: {
          docked: false,
          order: 5,
        },
        planning: {
          docked: false,
          order: 6,
        },
        calendar: {
          docked: false,
          order: 7,
        },
        reports: {
          docked: false,
          order: 8,
        },
        people: {
          docked: false,
          order: 9,
        },
        welcome: {
          docked: true,
          order: 10,
        },
      }),
      store: useServerStore({
        scope: 'me',
        name: 'sidebarPinnedItems',
      }),
    }),
    sidebarPinnedItems: usePreference({
      type: useArrayType(['time']),
      store: useServerStore({
        scope: 'me',
        name: 'sidebarPinnedItems',
      }),
    }),

    /* Used to store the name of the panel that is currently open in the sidebar. */
    sidebarPinnedPanel: usePreference({
      type: useObjectType({ name: null, isOpen: false }),
      store: useServerStore({
        scope: 'me',
        name: 'sidebarPinnedPanel',
      }),
    }),
    hotspots: usePreference({
      type: useObjectType(),
      store: useServerStore({
        scope: 'me',
        name: 'hotspots',
      }),
    }),
    dismissedBanners: usePreference({
      type: useObjectType(),
      store: useServerStore({
        scope: 'me',
        name: 'dismissedBanners',
      }),
    }),
    spacesAddonBannerDismissedDate,
    sampleProjectIds,
    sampleProjectsVisible,
    areNotificationsUnmuted: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'webBNotifsOn',
      }),
    }),
    useDesktopNotifications: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'useDesktopNotifications',
      }),
    }),
    vueListViewActive: usePreference({
      type: useBooleanType(),
      store: useServerStore({
        scope: 'me',
        name: 'vueListViewActive',
      }),
    }),
    firstTimeExperienceVisibility: usePreference({
      type: useObjectType({
        allTime: true,
        myWorkInbox: true,
        myWorkTasks: true,
        myWorkProjects: true,
        projectsActive: true,
        projectsTemplates: true,
        everythingTasks: true,
        planningWorkload: true,
        reportsProfitability: true,
        reportsProjectHealth: true,
        reportsPlannedVsActual: true,
        reportsTaskTime: true,
        reportsUtilization: true,
        reportsUserTime: true,
        reportsUserTaskCompletion: true,
        reportsProjectsTime: true,
        reportsTime: true,
      }),
      store: useServerStore({
        scope: 'me',
        name: 'firstTimeExperienceVisibility',
      }),
    }),
    freeSeatsIncentiveVisibility: usePreference({
      type: useObjectType({
        globalFreeSeatsBanner: true,
        addUserModalFreeSeatsBanner: true,
        freeSeatsSideNavPrompt: true,
      }),
      store: useServerStore({
        scope: 'me',
        name: 'freeSeatsIncentiveVisibility',
      }),
    }),
    experiment42CallbackRequestedDate: usePreference({
      type: useStringType(),
      store: useServerStore({
        scope: 'me',
        name: 'experiment42CallbackRequestedDate',
      }),
    }),
    shouldExpandWorkloadUserRow: usePreference({
      type: useBooleanType(true),
      store: useServerStore({
        scope: 'me',
        name: 'shouldShowPersonalizedExperience',
      }),
    }),
    featureTrialsBannersVisibility: usePreference({
      type: useObjectType({
        tasklistbudgets: true,
        retainerbudgets: true,
        timereport: true,
        projectbudgetexpenses: true,
      }),
      store: useServerStore({
        scope: 'me',
        name: 'featureTrialsBannersVisibility',
      }),
    }),
    switchAllUsersToWorkflows: usePreference({
      type: useBooleanType(false),
      store: useServerStore({
        scope: 'account',
        name: 'switchAllUsersToWorkflows',
      }),
    }),
    onboardingSelectedIntegrations: usePreference({
      type: useArrayType([]),
      store: useServerStore({
        scope: 'me',
        name: 'onboardingSelectedIntegrations',
      }),
    }),
  });
}

export function usePreferences() {
  return inject(usePreferencesSymbol);
}
