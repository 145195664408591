<template>
  <div
    class="w-inline-search"
    :class="{ 'w-inline-search--active': active, 'no-border': !showBorder }"
  >
    <common-icon
      v-if="showIcon"
      id="search"
      :color="iconColor"
      class="w-inline-search__icon"
    />
    <input
      type="text"
      autocomplete="off"
      ref="input"
      v-model="inputValue"
      @focus="focused = true"
      @blur="focused = false"
      @keydown="$emit('on-keydown', $event)"
      :placeholder="placeholder"
      :aria-label="$t('Search by')"
      class="w-inline-search__input"
      :class="{ 'has-faded-placeholder': fadePlaceholderOnFocus }"
    />
    <button
      v-if="inputValue.length > 0"
      @click="clear"
      class="w-inline-search__clear-button"
      type="button"
    >
      <common-icon id="close" :color="iconColor" />
    </button>
  </div>
</template>

<script>
import Vue from 'vue';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import colors from '@widgets/InlineSearch/InlineSearch.scss';

export default {
  components: {
    CommonIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: () => Vue.t('Search'),
    },
    fadePlaceholderOnFocus: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      iconColor: colors.iconColor,
      focused: false,
    };
  },
  computed: {
    active() {
      return this.focused || this.inputValue.length > 0;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    clear() {
      this.inputValue = '';
    },
    focus() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
  },
  mounted() {
    if (this.focusOnMount) {
      setTimeout(() => {
        this.focus();
      }, 100);
    }
  },
};
</script>

<style src="./InlineSearch.scss" lang="scss" scoped></style>
