<template>
  <InlineSearch
    class="item-picker-search"
    :value="searchTerm"
    @input="onSearchChange"
    :placeholder="placeholder"
    focus-on-mount
    show-icon
  />
</template>
<script>
import InlineSearch from '@widgets/InlineSearch';

export default {
  name: 'ItemPickerSearch',
  props: {
    searchTerm: { type: String, required: true },
    placeholder: { type: String, required: true },
  },
  components: {
    InlineSearch,
  },
  methods: {
    onSearchChange(e) {
      this.$emit('search-change', e);
    },
  },
};
</script>
<style lang="scss" scoped>
.item-picker-search {
  margin-top: 16px;
}
</style>
