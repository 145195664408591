<template>
  <div class="group">
    <div class="flex items-center">
      <div class="w-[40px] h-[40px] mr-4 z-50">
        <slot name="icon" />
      </div>

      <div class="flex-1">
        <div class="flex justify-between">
          <div class="text-default text-[#646D7D] font-semibold capitalize">
            {{ type }}
          </div>
          <div class="text-default text-[#646D7D] font-normal">
            {{ formatISODate(date) }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="w-[40px] mr-4 relative">
        <div
          class="
            absolute
            left-1/2
            w-0.5
            h-full
            bg-[#E1E6EE]
            group-last:opacity-0
          "
        ></div>
      </div>

      <div class="flex-1 -mt-1.5 pb-8 text-text">
        <div class="text-base font-semibold mb-1">{{ title }}</div>
        <p class="text-default font-normal mb-1.5">
          {{ text }}
        </p>
        <a
          class="text-default text-[#4461D7]"
          target="_blank"
          v-bind="$attrs"
          v-on="$listeners"
        >
          {{ linkText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { useLocalization } from '@/platform/composables/useLocalization';

export default {
  name: 'ProductUpdateItem',
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { languageCode } = useLocalization();
    const formatter = new Intl.DateTimeFormat(languageCode.value, {
      weekday: 'long',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    const formatISODate = (dateString) => {
      return formatter.format(new Date(dateString));
    };
    return {
      formatISODate,
    };
  },
};
</script>
