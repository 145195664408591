import { fileTypeGroups } from '@teamwork/use';
import { FILES, FILEPREVIEWSIZELIMITS } from '@tko/src/app/helpers/constants';

// eslint-disable-next-line import/prefer-default-export
export function getExtension(fileName) {
  return fileName.split('.').pop();
}

export function isImage(fileName) {
  // Displayable image formats for web, exclude 'svg'
  return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(getExtension(fileName));
}

export function friendlyFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

export function isDisplayableImage(file) {
  const fileExt = getExtension(file.originalName);
  return (
    isImage(file.originalName) &&
    fileTypeGroups.displayableImage.includes(`.${fileExt}`)
  );
}

export function isViewableFile(file) {
  const fileExt = getExtension(file.originalName);
  return FILES.VIEWABLEFILETYPES.includes(fileExt);
}

export function isPlayableVideo(file) {
  const fileExt = getExtension(file.originalName);
  return FILES.PLAYABLEVIDEO.includes(fileExt);
}

export function isViewableDropboxFile(file) {
  const fileExt = getExtension(file.originalName);
  return FILES.VIEWABLEDROPBOXFILETYPES.includes(fileExt);
}

export function fileTooBig(file) {
  return parseInt(file.size, 10) > FILEPREVIEWSIZELIMITS.GOOGLE;
}

export function isPreviewableFile(file) {
  const fileExt = getExtension(file.originalName);
  // If the account allows file previews OR if the file is a teamwork file and an image/video
  if (!window.app.account || !window.app.account.canPreviewFiles()) {
    return (
      file.fileSource === 'teamworkpm' &&
      (isDisplayableImage(file) || isPlayableVideo(file))
    );
  }

  // Some services can only display images and not content
  if (file.fileSource === 'onedrive') {
    return isDisplayableImage(file);
  }

  if (
    file.fileSource === 'onedrivebusiness' ||
    file.fileSource === 'sharepoint'
  ) {
    return (
      (isViewableFile(file) || isDisplayableImage(file)) &&
      !FILES.ODB_BLOCKED_PREVIEW.includes(fileExt)
    );
  }

  if (file.fileSource === 'teamworkpm') {
    return (
      (isViewableFile(file) || isDisplayableImage(file)) && !fileTooBig(file)
    );
  }

  if (file.fileSource === 'dropbox') {
    return (
      (isViewableDropboxFile(file) || isDisplayableImage(file)) &&
      !fileTooBig(file)
    );
  }

  return true;
}
