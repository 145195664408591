<template>
  <div class="h-[calc(100%-96px)] px-6 flex flex-col">
    <h1 class="text-md font-semibold leading-normal mb-6">
      {{ $t('Webinars') }}
    </h1>
    <FTabs
      v-model="activeTab"
      data-identifier-prefix="webinars-panel"
      :tabs="tabs"
      class="pb-5"
    />
    <div class="grow overflow-y-auto min-h-full -mx-6 -mt-3">
      <div class="px-6 pt-3">
        <template v-if="activeTab === 'getting-started'">
          <HelpCenterSectionTitle :title="$t('Upcoming webinars')" />

          <HelpCenterWebinarSkeleton v-if="upcomingLoading" />
          <p
            v-else-if="!upcomingLoading && upcoming.length === 0"
            class="italic"
          >
            {{ $t('There are no upcoming webinars.') }}
          </p>
          <template v-else>
            <WebinarCard
              class="mb-4"
              v-for="webinar in upcoming"
              :key="webinar.webinarKey"
              :title="webinar.title"
              :tags="webinar.tags"
              :date="webinar.webinarStartTime"
              :description="webinar.description"
              :action-text="webinar.actionText"
              :webinar-duration="webinar.duration"
              :is-upcoming="true"
              :webinar-key="webinar.webinarKey"
            />
          </template>

          <HelpCenterSectionTitle
            class="mt-6"
            :title="$t('On Demand webinars')"
          />

          <p v-if="onDemandGettingStartedWebinars.length < 1" class="italic">
            {{ $t('There are no on demand webinars.') }}
          </p>
          <WebinarCard
            v-else
            class="mb-4"
            v-for="webinar in onDemandGettingStartedWebinars"
            :key="webinar.title"
            :title="webinar.title"
            :tags="webinar.tags"
            :description="webinar.description"
            :action-text="webinar.actionText"
            :webinar-duration="webinar.webinarDuration"
            :webinar-link="webinar.webinarLink"
          />
        </template>

        <template v-if="activeTab === 'success'">
          <HelpCenterSectionTitle
            class="mt-0.5"
            :title="$t('On Demand webinars')"
          />
          <p v-if="onDemandSuccessWebinars.length < 1" class="italic">
            {{ $t('There are no on demand webinars.') }}
          </p>
          <WebinarCard
            v-else
            class="mb-4"
            v-for="webinar in onDemandSuccessWebinars"
            :key="webinar.title"
            :title="webinar.title"
            :tags="webinar.tags"
            :description="webinar.description"
            :action-text="webinar.actionText"
            :webinar-duration="webinar.webinarDuration"
            :webinar-link="webinar.webinarLink"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { shallowRef, ref, onMounted, Vue2 as Vue } from 'vue-demi';
import { FTabs } from '@fragments';
import WebinarCard from '@/platform/components/side-nav/panels/help-center/common/WebinarCard.vue';
import HelpCenterSectionTitle from '@/platform/components/side-nav/panels/help-center/common/HelpCenterSectionTitle.vue';
import HelpCenterWebinarSkeleton from '@/platform/components/side-nav/panels/help-center/common/HelpCenterWebinarSkeleton.vue';
import { useWebinarsApi } from '@/platform/composables/useWebinarsApi';
import useStore from '@/platform/composables/useStore';

export default {
  name: 'HelpCenterWebinarState',
  components: {
    WebinarCard,
    FTabs,
    HelpCenterSectionTitle,
    HelpCenterWebinarSkeleton,
  },
  setup() {
    const serviceApi = useWebinarsApi();
    const store = useStore();

    const activeTab = ref('getting-started');
    const upcoming = shallowRef([]);
    const upcomingLoading = ref(true);

    const tabs = Object.freeze([
      {
        text: Vue.t('Getting Started'),
        value: 'getting-started',
      },
      {
        text: Vue.t('Success'),
        value: 'success',
      },
    ]);

    const onDemandGettingStartedWebinars = [
      {
        title: 'Get Started: Resource Management',
        description:
          'Dive into workload and portfolio management so you can not only plan work but understand how it’s actually going.',
        tags: [
          { label: 'On Demand', type: 'dark' },
          { label: 'Getting Started', type: 'secondary' },
        ],
        actionText: 'Watch now',
        webinarDuration: 37,
        webinarLink: 'https://teamwork-videos.wistia.com/medias/lm2zf3wavb',
      },
      {
        title: 'Get Started: Streamline Work',
        description:
          'Build upon what you know and begin organizing your account, streamlining repeatable processes, and reporting.',
        tags: [
          { label: 'On Demand', type: 'dark' },
          { label: 'Getting Started', type: 'secondary' },
        ],
        actionText: 'Watch now',
        webinarDuration: 68,
        webinarLink:
          'https://www.teamwork.com/resource/onboarding-webinar-2-recorded/?utm_medium=email&utm_source=ad-hoc&utm_campaign=nov-onboarding-webinar-2&mkt_tok=MjQ0LUlYQy0wNTkAAAGBDB9KrL3HVhfObhQ73421dvifdlVAm9XI6ZAzPCCOUjoljtw4VCuk49IMMhIs65guDiXoF2ar2JZhdgMTaw&wvideo=s7e9v31a08',
      },
      {
        title: 'Get Started: The Fundamentals',
        description:
          'Learn Teamwork’s building blocks (projects, task lists, tasks, and milestones) and how to use them to start working in Teamwork.',
        tags: [
          { label: 'On Demand', type: 'dark' },
          { label: 'Getting Started', type: 'secondary' },
        ],
        actionText: 'Watch now',
        webinarDuration: 54,
        webinarLink:
          'https://www.teamwork.com/resource/sept-onboarding-webinar-recorded/?wvideo=ec1f24zwfu',
      },
      {
        title: 'Make the most of your project features',
        description:
          'Learn how to use more of the features in Teamwork. This is a fundamentals level webinar providing you with taste of how to use features such as Time, Billing and Risks etc.',
        tags: [
          { label: 'On Demand', type: 'dark' },
          { label: 'Getting Started', type: 'secondary' },
        ],
        actionText: 'Watch now',
        webinarDuration: 25,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/26dd121c311e4e409c13667532c6673e/watch',
      },
      {
        title: 'Set up your site for success',
        description:
          "Join this webinar to learn about teamwork fundamentals. You're going to walk away knowing how to set up your company's profile and other useful user-based tips.",
        tags: [
          { label: 'On Demand', type: 'dark' },
          { label: 'Getting Started', type: 'secondary' },
        ],
        actionText: 'Watch now',
        webinarDuration: 26,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/25bc7c5e27984daaa655f4d4c4a370c5/watch',
      },
      {
        title: 'Teamwork Hierarchy',
        description:
          'Join our webinar to learn about how to structure the projects on your site for maximum effect. We will be focusing on building out a project and showing how each level of this come...',
        tags: [
          { label: 'On Demand', type: 'dark' },
          { label: 'Getting Started', type: 'secondary' },
        ],
        actionText: 'Watch now',
        webinarDuration: 35,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/3ef9aefa19c04f2d9c0ee992cc03e3be/watch',
      },
    ];

    const onDemandSuccessWebinars = [
      {
        title: '8 Ways to work from home like a pro with Teamwork',
        description:
          "Best practices using Teamwork when you're working remotely. We're going to share 8 ways to make you happy and productive when working from home.",
        tags: [{ label: 'On Demand', type: 'dark' }],
        actionText: 'Watch now',
        webinarDuration: 23,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/b2eaf6efa7694258b23b62accbad300f/watch',
      },
      {
        title: 'Workflow management with Teamwork board view',
        description:
          'Learn how to use Teamwork Boards to visualize and automate your workflows. Board view provides you with a visual representation of your workflow and allows you to automate some pro ...',
        tags: [{ label: 'On Demand', type: 'dark' }],
        actionText: 'Watch now',
        webinarDuration: 42,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/9de5389b73ee4baea9011de4f363d8e1/watch',
      },
      {
        title: 'Portfolio management with Teamwork',
        description: 'Learn how to master your Portfolio in Teamwork.',
        tags: [{ label: 'On Demand', type: 'dark' }],
        actionText: 'Watch now',
        webinarDuration: 27,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/8db0e6b5a61c4d01b080ec29525b650e/watch',
      },
      {
        title: 'A guide to working with Teams',
        description:
          'Teamwork Customer Champion, Jane Aston will go through how to group your users into Teams using our latest feature and give you advice on how to get started.',
        tags: [{ label: 'On Demand', type: 'dark' }],
        actionText: 'Watch now',
        webinarDuration: 30,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/7aeea5501c2c4686bc872f503f584c3e/watch',
      },
      {
        title: 'Working with the teamwork mobile app',
        description:
          'Join our webinar to learn about how to use our new mobile app. In this session we will cover all the main features and show you how to use teamwork on the go.',
        tags: [{ label: 'On Demand', type: 'dark' }],
        actionText: 'Watch now',
        webinarDuration: 25,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/69711eda08c84f1ba6ee5781cc8d92da/watch',
      },
      {
        title: 'Speed up you success with templates',
        description:
          'From task lists to complete Projects, you can replicate your work quickly. Templates are perfect for quickly duplicating your great work with just a couple of clicks.',
        tags: [{ label: 'On Demand', type: 'dark' }],
        actionText: 'Watch now',
        webinarDuration: 31,
        webinarLink:
          'https://www.gotostage.com/channel/b42df1e149c147eabc706c0f43a16d5c/recording/28e2b5daef914c65b1a497dc1fbad118/watch',
      },
      {
        title: 'Customer Panel: Client Services Productivity Insights',
        description:
          'Collaboration and productivity have forever been a topic of discussion in the business world. Now, more than ever, it’s a delicate balance to maintain employee productivity and avo ...',
        tags: [{ label: 'On Demand', type: 'dark' }],
        actionText: 'Watch now',
        webinarDuration: 62,
        webinarLink:
          'https://www.teamwork.com/resource/customer-panel-webinar-recorded/',
      },
    ];

    onMounted(async () => {
      try {
        const results = (await serviceApi.getUpcoming()).slice(0, 1);
        upcoming.value = results.map((webinar) => ({
          ...webinar,
          tags: [
            { label: Vue.t('Upcoming'), type: 'primary' },
            { label: Vue.t('Getting Started'), type: 'secondary' },
          ],
          actionText: Vue.t('Register Now'),
        }));
      } catch (error) {
        upcoming.value = [];
        store.dispatch(
          'notifications/flashes/error',
          Vue.t('There was an error loading upcoming webinars.'),
        );
      } finally {
        upcomingLoading.value = false;
      }
    });
    return {
      upcoming,
      upcomingLoading,
      activeTab,
      tabs,
      Vue,
      onDemandGettingStartedWebinars,
      onDemandSuccessWebinars,
    };
  },
};
</script>
