<template>
  <div class="comment-box">
    <Chip image="https://i.pravatar.cc/150?img=5" size="big" />
    <div class="comment-box__input">
      <input placeholder="Add a comment" @keyup.enter="onSubmit" />
      <span>
        <button>
          <CommonIcons id="at" />
        </button>
        <button>
          <CommonIcons id="paperclip" />
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import { Chip } from '..';

export default {
  name: 'CommentBox',
  display: 'CommentBox',
  props: {
    overlapping: { type: Boolean, required: false, default: false },
  },
  components: { Chip, CommonIcons },
  methods: {
    onSubmit(event) {
      if (event.target) {
        this.$emit('input', event.target.value);
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.comment-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;

  &__input {
    height: 60px;
    flex-grow: 2;
    background: #f5f7fa;
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6d6f80;

    input {
      flex-grow: 2;
      width: 100%;
      border: none;
      background: transparent;
    }

    span {
      flex-shrink: 0;
    }

    button {
      border: none;
      background: transparent;
      padding: 5px 2px;
      opacity: 0.8;

      &:active {
        opacity: 1;
      }

      svg {
        fill: #6d6f80;
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
