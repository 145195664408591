var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-y-scroll inline-flex min-h-full"},[_c('div',{staticClass:"mx-6 mb-10 flex-auto",class:{ 'h-[fit-content]': !_vm.isLoading }},[(_vm.isLoading)?_c('HelpCenterArticleSkeleton',{attrs:{"title":_vm.title}}):_c('div',[_c('div',{staticClass:"\n          pb-6\n          border-b border-x-0 border-t-0 border-[#C5CEE0] border-solid\n        "},[_c('h1',{class:("text-[20px] text-ellipsis text-[#0B0E1F] font-[600] " + (_vm.formattedLastModifiedDate ? 'mb-2' : '-mb-2'))},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.formattedLastModifiedDate)?_c('span',{staticClass:"text-[14px] text-ellipsis text-[#646D7D]"},[_vm._v("\n          "+_vm._s(_vm.$t('Last modified on'))+"\n          "+_vm._s(_vm.formattedLastModifiedDate)+"\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{class:{
          'pb-6 mb-6 border-b border-x-0 border-t-0 border-[#C5CEE0] border-solid':
            _vm.relatedArticles.length > 0,
        }},[_c('div',{staticClass:"my-6 text-[16px] leading-[20.8px]",domProps:{"innerHTML":_vm._s(_vm.content)}}),_vm._v(" "),_c('HelpCenterMenuItem',{attrs:{"is-button":false,"icon":"website","label":"Open in our support website","href":_vm.canonicalURL,"target":"_blank"}})],1),_vm._v(" "),(_vm.relatedArticles.length > 0)?_c('HelpCenterMenu',{attrs:{"title":_vm.$t('Related articles'),"items":_vm.relatedArticles},on:{"menuItemClicked":function($event){return _vm.$emit(
            'relatedArticleClicked',
            $event.categorySlug,
            $event.slug,
            $event.title
          )}}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }