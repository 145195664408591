var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"TableViewRow"},_vm.$listeners),[_vm._t("before"),_vm._v(" "),_vm._l((_vm.enabledColumns),function(column){
var _obj;
return _c('div',{key:column.id,class:( _obj = {
      TableViewRow__cell: true
    }, _obj[("TableViewRow__cell--" + (column.id))] = true, _obj['TableViewRow__cell--no-bottom-border'] =  !_vm.bottomBorder, _obj['TableViewRow__cell--sticky'] =  column.left != null, _obj['TableViewRow__cell--center'] =  column.headerCellCenter, _obj ),style:({
      left: column.left != null ? ((column.left) + "px") : undefined,
      zIndex: column.left != null ? '4' : undefined,
      flex: ("0 0 " + (column.computedWidth) + "px"),
      maxWidth: ((column.computedWidth) + "px"),
    })},[_vm._t(("cell-" + (column.id)),function(){return [(_vm.placeholder)?_c('div',{staticClass:"TableViewRow__cell-placeholder"}):_vm._e()]})],2)}),_vm._v(" "),_vm._t("after")],2)}
var staticRenderFns = []

export { render, staticRenderFns }