<template>
  <div class="flex flex-col gap-2">
    <label
      v-if="label"
      class="text-xs leading-5 flex items-center gap-1 m-0"
      :for="labelFor"
    >
      <span class="font-semibold text-[color:var(--token-c-label-color-label)]">
        {{ label }}
      </span>
      <span
        v-if="labelInfo"
        class="text-[color:var(--token-c-label-color-helper)]"
      >
        {{ labelInfo }}
      </span>
      <span
        v-if="required"
        class="text-[color:var(--token-c-label-color-required)]"
      >
        *
      </span>
    </label>
    <div class="flex flex-col gap-2">
      <slot />
      <div
        v-if="showHelper"
        class="text-xs leading-5"
        :class="{
          'text-[color:var(--token-c-label-color-helper)]': !invalid,
          'text-[color:var(--token-c-label-color-helper-invalid)]': invalid,
        }"
      >
        {{ invalid && errorText ? errorText : helperText }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue-demi';

export default {
  name: 'FInputLabel',
  props: {
    label: {
      type: String,
      default: () => null,
    },
    labelFor: {
      type: String,
      default: '',
    },
    labelInfo: {
      type: String,
      default: () => null,
    },
    helperText: {
      type: String,
      default: () => null,
    },
    errorText: {
      type: String,
      default: () => null,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showHelper = computed(
      () => props.helperText || (props.errorText && props.invalid),
    );
    return { showHelper };
  },
};
</script>
