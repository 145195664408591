<template>
  <div
    class="
      w-next-modal-frame
      fixed
      top-0
      left-0
      right-0
      bottom-0
      cursor-pointer
      z-[1300]
    "
    :style="{
      // We must manually set display property so we can detect if the modal is open
      // Firefox defaults are different to chrome so we must be explicit.
      display: activeModal ? 'block' : 'none',
    }"
    @click.self="closeIfModalNotInitialised"
  >
    <iframe
      v-show="isReady"
      ref="iframe"
      :src="frameSrc"
      allowtransparency="true"
      class="flex-1 overflow-auto border-0 w-full h-full"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NextModalFrame',
  data() {
    return {
      isReady: false,
      activeModalCallbacks: {},
    };
  },
  computed: {
    ...mapState('nextModals', ['activeModal', 'loading']),
    // We want strip out the callback functions, but maintain references to them
    // so the next app can in turn call the callback stored in `activeModalCallbacks`
    activeModalProps() {
      if (!this.activeModal) {
        return {};
      }
      const props = {};
      const callbackKeys = [];

      if (this.activeModal) {
        document.body.classList.add('overflow--hidden');
      }

      Object.entries(this.activeModal.props).forEach(([key, value]) => {
        if (typeof value === 'function') {
          callbackKeys.push(key);
        } else {
          props[key] = value;
        }
      });

      // Pass an array of callback keys to setup on the next side.
      // Look at the `ModalRouteContainer` in teamwork-next for the corresponding setup logic
      if (callbackKeys.length) {
        props.callbackKeys = callbackKeys;
      }

      return props;
    },
    frameSrc() {
      if (!this.activeModal) {
        return null;
      }
      return `${window.location.origin}/next/modals/${
        this.activeModal.name
      }?t=${Date.now()}`;
    },
  },
  mounted() {
    window.addEventListener('message', this.nextModalEventListener, true);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.nextModalEventListener, true);
  },
  watch: {
    async route() {
      this.cleanupActiveModalCallbacks();
      await this.$nextTick();
      this.closeAllModals();
      this.isReady = false;
    },
    activeModal: 'generateActiveModalCallbacks',
  },
  methods: {
    ...mapActions('nextModals', {
      closeModal: 'close',
      isLoading: 'isLoading',
    }),

    closeIfModalNotInitialised() {
      if (!this.isReady) {
        this.closeModal();
      }
    },

    cleanupActiveModalCallbacks() {
      this.activeModalCallbacks = {};
    },

    // store any callbacks here, window.postMessage will not support passing functions though
    // See more about the structured clone algorithm here
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm#things_that_dont_work_with_structured_clone
    generateActiveModalCallbacks() {
      if (!this.activeModal || !this.activeModal.props) {
        document.body.classList.remove('overflow--hidden');
        return;
      }
      Object.entries(this.activeModal.props).forEach(([callbackKey, value]) => {
        if (typeof value === 'function') {
          this.$set(this.activeModalCallbacks, callbackKey, value);
        }
      });
    },

    instantiateModal() {
      this.isReady = true;
      this.isLoading(false);

      this.sendMessage('instantiate-modal', {
        props: this.activeModalProps,
      });

      // This is needed to bring focus to the iframe (mainly to be able to press esc to close)
      this.$refs.iframe.focus();
    },

    nextModalEventListener(event) {
      const hasMatchingOrigin = event.origin === window.location.origin;
      if (!hasMatchingOrigin || !event.data) {
        return;
      }

      if (event.data.type === 'next-app-ready') {
        this.instantiateModal();
      } else if (event.data.type === 'modal-callback-triggered') {
        this.triggerCallback(event);
      }
    },

    sendMessage(type, message) {
      if (!this.isReady || !this.activeModal || !this.$refs.iframe) {
        return;
      }
      this.$refs.iframe.contentWindow.postMessage(
        { type, message },
        window.location.origin,
      );
    },

    async triggerCallback(event) {
      const { callbackKey, args } = event.data.message;
      const callbackFunction = this.activeModalCallbacks[callbackKey];
      if (!callbackFunction) {
        return;
      }

      callbackFunction(...args);
      await this.$nextTick();
      this.$delete(this.activeModalCallbacks, callbackKey);
    },
  },
};
</script>
