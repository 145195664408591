/* eslint-disable no-param-reassign,no-return-assign,consistent-return,no-underscore-dangle */
import moment from 'moment';
import Vue from 'vue';
import typedDefaults from '@/utils/helpers/typed-defaults';
import {
  mapActions,
  mapMutations,
  mapGetters,
} from '@/store/utils/record-mapper';
import placeholder from '@/store/utils/placeholder';
import api from '@/services/api';
import {
  convertAssignedTo,
  unifyAssignedTo,
} from '@/utils/helpers/responsible';

const dateFormat = 'YYYY-MM-DD';
const recordDefault = {
  id: 0,
  title: '',
  name: '',
  status: '',
  projectId: 0,
  canComplete: false,
  canEdit: false,
  completed: false,
  deadline: moment.tz('', dateFormat, moment.tz.guess()),
  assignedTo: [],
  busy: false,
};

// Standardise Map Task Response formats
const uniformat = (mstone) => ({
  // Strip off the time from the date, it only gets in the way
  deadline:
    typeof mstone.deadline === 'string' ? mstone.deadline.slice(0, 10) : mstone,
  ...mstone,
  // Map all assignees together
  assignedTo: unifyAssignedTo(mstone),
});

// Placeholder functionality is mixed in
const ph = placeholder({
  multipleMutation: true,
  priorToPersist: ({ rootGetters, id }) => ({
    tlid: rootGetters['tasklist/findByMilestone'](id),
  }),
  persistApi: ({ state, name }) =>
    api.post(`/projects/${state.projectId}/milestones.json`, {
      milestone: {
        title: name,
        deadline: state.deadline.format('YYYYMMDD'),
        'responsible-party-ids': convertAssignedTo(state.assignedTo).join(','),
      },
      'move-upcoming-milestones': false,
      'move-upcoming-milestones-off-weekends': false,
    }),
  afterSaveId: async ({
    state,
    commit,
    dispatch,
    oldId,
    priorState: { tlid },
  }) => {
    if (tlid) {
      commit(
        'tasklist/unlinkMilestone',
        { id: tlid, payload: oldId },
        { recordMap: false, root: true },
      );
      await dispatch(
        'tasklist/linkMilestone',
        { id: tlid, payload: state.id },
        { recordMap: false, root: true },
      );
    }
  },
  beforeClear: ({ commit, id, rootGetters }) => {
    const tlid = rootGetters['tasklist/findByMilestone'](id);
    if (tlid) {
      commit(
        'tasklist/unlinkMilestone',
        { id: tlid, payload: id },
        { recordMap: false, root: true },
      );
    }
  },
  createPlaceholder: ({
    initial,
    rootState: {
      user: { id: userId, firstName, lastName },
    },
  }) => {
    if (!initial.projectId) {
      Vue.util.warn(
        'Cannot create a placeholder milestone without a parent project',
      );
      return;
    }
    return {
      ...recordDefault,
      ...initial,
      title: 'New Milestone',
      canComplete: true,
      canEdit: true,
      deadline: moment.tz(moment.tz.guess()).startOf('day').format(dateFormat),
      assignedTo: [{ id: Number(userId), label: `${firstName} ${lastName}` }],
    };
  },
});

export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: {
    ...ph.getters,
    ...mapGetters({
      affectedTasks: (state, getters, { task }, rootGetters, id) => {
        const tasklistIds = rootGetters['tasklist/findAllByMilestone'](id);
        if (tasklistIds.length) {
          return Object.values(task.records)
            .filter((t) => tasklistIds.includes(t.taskListId))
            .filter(({ status }) => !['deleted', 'completed'].includes(status))
            .filter(({ dueDateFromMilestone }) => dueDateFromMilestone)
            .map((t) => t.id);
        }
        return [];
      },
    }),
  },
  mutations: {
    ...ph.mutations,
    records(state, milestones) {
      const hash = {};
      milestones.forEach((milestone) => {
        const withDefaults = typedDefaults(recordDefault, uniformat(milestone));
        withDefaults.busy = withDefaults.busy || false;
        withDefaults.name = withDefaults.title || milestone.name; // data format normalisation
        const existing = state.records[milestone.id];
        hash[milestone.id] = existing
          ? { ...existing, ...withDefaults }
          : withDefaults;
      });
      state.records = { ...state.records, ...hash };
    },
    ...mapMutations({
      changeName: (state, newName) => {
        state.title = newName;
        state.name = newName;
      },
      assignedTo: (state, assignedTo) => {
        state.assignedTo = assignedTo;
      },
      deadline: (state, deadline) => {
        state.deadline = deadline;
      },
      complete: (state) => {
        state.completed = true;
      },
      uncomplete: (state) => {
        state.completed = false;
      },
      delete: (state, taskListIds) => {
        state.status = 'deleted';
        // not reactive nor public - for undo/redo
        state._taskListIds = taskListIds;
      },
      undelete: (state) => {
        state.status = 'reopened';
        // not reactive nor public - for undo/redo
        delete state._taskListIds;
      },
    }),
  },
  actions: {
    ...ph.actions,
    ...mapActions({
      changeName({ commit, dispatch, state, id }, newName) {
        if (!newName) {
          dispatch(
            'notifications/flashes/error',
            Vue.t('The milestone title cannot be blank'),
            { root: true, recordMap: false },
          );
          return;
        }
        const oldName = state.title;
        commit('changeName', newName);
        return api
          .put(`/milestones/${id}.json`, { milestone: { title: newName } })
          .catch((e) => {
            commit('changeName', oldName);
            throw e;
          });
      },
      assign({ commit, state, id }, assignedTo) {
        const original = state.assignedTo;
        const responsiblePartyIds = convertAssignedTo(assignedTo).join(',');
        commit('assignedTo', assignedTo);
        return api
          .put(`/milestones/${id}.json`, {
            milestone: { 'responsible-party-ids': responsiblePartyIds },
          })
          .catch((e) => {
            commit('assignedTo', original);
            throw e;
          });
      },
      move({ commit, state, id }, deadline) {
        const oldDeadline = state.deadline;
        commit('deadline', deadline);
        return api
          .put(`/milestones/${id}.json`, {
            milestone: { deadline: deadline.format('YYYYMMDD') },
          })
          .catch((e) => {
            commit('deadline', oldDeadline);
            throw e;
          });
      },
      delete({ state, commit, dispatch, rootGetters, id }) {
        if (state.status === 'deleted') {
          return;
        }
        const taskListIds = rootGetters['tasklist/findAllByMilestone'](id);
        commit('delete', taskListIds);
        return api
          .delete(`/milestones/${id}.json`)
          .then(() =>
            Promise.all(
              taskListIds.map((tid) =>
                dispatch(
                  'tasklist/unlinkMilestone',
                  { id: tid, payload: id },
                  { root: true, recordMap: false },
                ),
              ),
            ),
          )
          .catch((e) => {
            commit('undelete');
            throw e;
          });
      },
      undelete({ state, dispatch, commit, id, rootState }) {
        if (state.status !== 'deleted') {
          return;
        }
        commit('busy');
        const taskListIds = state._taskListIds;
        commit('undelete');
        return api
          .put(`/trashcan/milestones/${id}/restore.json`)
          .then(() => {
            const { records } = rootState.tasklist;
            return Promise.all(
              taskListIds
                .map((tid) => {
                  if (tid && records[tid] && !records[tid].milestoneId) {
                    return dispatch(
                      'tasklist/linkMilestone',
                      { id: tid, payload: id },
                      { recordMap: false, root: true },
                    );
                  }
                  return false;
                })
                .filter(Boolean),
            );
          })
          .catch((e) => {
            commit('delete', taskListIds);
            throw e;
          })
          .finally(() => commit('notBusy'));
      },
    }),
  },
};
