<template>
  <PopoverMenuItem route="/settings" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        viewBox="0 0 16 18"
      >
        <path
          d="m14.397 8.382 1.036-.599a.782.782 0 0 0 .364-.88 8.06 8.06 0 0 0-2.085-3.607.781.781 0 0 0-.944-.124l-1.034.597a6.387 6.387 0 0 0-1.071-.619V1.954a.781.781 0 0 0-.58-.756 8.125 8.125 0 0 0-4.165 0 .782.782 0 0 0-.581.756V3.15a6.39 6.39 0 0 0-1.071.62l-1.035-.598a.781.781 0 0 0-.943.124A8.059 8.059 0 0 0 .203 6.902a.782.782 0 0 0 .363.881l1.037.599c-.04.41-.04.825 0 1.236l-1.037.599a.782.782 0 0 0-.363.88 8.06 8.06 0 0 0 2.085 3.607c.25.25.637.301.943.124l1.035-.597c.336.24.695.447 1.07.619v1.196a.782.782 0 0 0 .582.756 8.125 8.125 0 0 0 4.164 0 .782.782 0 0 0 .58-.756V14.85a6.397 6.397 0 0 0 1.072-.62l1.034.598a.781.781 0 0 0 .944-.124 8.06 8.06 0 0 0 2.085-3.607.782.782 0 0 0-.364-.88l-1.036-.599c.04-.411.04-.825 0-1.236Zm-1.715 2.05 1.426.824a6.515 6.515 0 0 1-1.101 1.905l-1.427-.823c-1.041.891-1.193.979-2.48 1.433v1.647a6.538 6.538 0 0 1-2.2 0V13.77c-1.287-.454-1.44-.542-2.48-1.434l-1.427.824a6.515 6.515 0 0 1-1.102-1.905l1.427-.823c-.249-1.344-.249-1.52 0-2.866L1.89 6.744a6.516 6.516 0 0 1 1.102-1.905l1.427.823C5.46 4.771 5.613 4.683 6.9 4.23V2.582a6.54 6.54 0 0 1 2.2 0V4.23c1.286.454 1.44.542 2.48 1.434l1.427-.824a6.515 6.515 0 0 1 1.101 1.905l-1.426.823c.248 1.345.249 1.52 0 2.866ZM8 5.876A3.129 3.129 0 0 0 4.875 9 3.129 3.129 0 0 0 8 12.125 3.129 3.129 0 0 0 11.125 9 3.129 3.129 0 0 0 8 5.875Zm0 4.688c-.862 0-1.563-.701-1.563-1.563S7.138 7.437 8 7.437c.861 0 1.562.701 1.562 1.563s-.7 1.563-1.562 1.563Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('Settings') }}
    </template>
  </PopoverMenuItem>
</template>

<script>
import PopoverMenuItem from './PopoverMenuItem.vue';

export default {
  components: {
    PopoverMenuItem,
  },
};
</script>

<style></style>
