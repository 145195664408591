<template>
  <section>
    <ProductTour v-if="productTourVisible && productOutcomeFlowEnabled" />
    <div
      class="button-container"
      data-identifier="product-onboarding-checklist-drawer-button"
    >
      <OnboardingChecklistButton
        v-if="showChecklistButton"
        @click="changeShowChecklistStatus(true)"
        :tasks-remaining="incompleteItems.length"
      />
    </div>
    <OnboardingChecklist
      v-if="showChecklistFlow && isCheckListEnabled && !isSmallDisplay"
      @close="changeShowChecklistStatus(false)"
    />
    <PassiveHelpInfoPopover
      v-if="isPassiveHelpPanelEnabled"
      @close="changePassiveHelpPanelStatus(false)"
    />
  </section>
</template>
<script>
import { computed, watchEffect, ref } from 'vue-demi';
import { useCurrentAccount, useCurrentUser } from '@teamwork/use';
import ProductTour from '@sections/ProductTour';
import OnboardingChecklistButton from '@widgets/OnboardingChecklistButton';
import OnboardingChecklist from '@sections/Modals/OnboardingChecklist';
import PassiveHelpInfoPopover from '@sections/PassiveHelp/PassiveHelpInfoPopover';
import useOnboardingChecklist from '@/views/onboarding/composables/onboardingChecklist';
import useProductTour from '@/views/onboarding/composables/productTour';
import { useFeatures } from '@/platform/composables/useFeatures';
import usePassiveHelp from '@/views/PassiveHelp/usePassiveHelp';
import useCurrentRoute from '@/platform/composables/useCurrentRoute';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';

export default {
  name: 'OnboardingAndProductTours',
  components: {
    OnboardingChecklistButton,
    OnboardingChecklist,
    ProductTour,
    PassiveHelpInfoPopover,
  },
  setup() {
    const account = useCurrentAccount();
    const user = useCurrentUser();
    const route = useCurrentRoute();
    const isOnboardingRoute = ref(false);

    const onboardingRoutePaths = ['getting-started', 'onboarding/step'];
    const {
      projectTimeBudgetsEnabled,
      projectFinancialBudgetsEnabled,
      utilizationReportEnabled,
      workloadPlannerEnabled,
    } = useFeatures();

    const { changePassiveHelpPanelStatus, isPassiveHelpPanelEnabled } =
      usePassiveHelp();

    const {
      incompleteItems,
      isChecklistDismissed,
      isSmallDisplay,
      isCheckListEnabled,
      changeShowChecklistStatus,
    } = useOnboardingChecklist();

    const { isInLightspeed } = useLightspeedBridge();

    const { activeProductTourId: productTourVisible } = useProductTour();

    const isAdminTrialUser = computed(() => {
      return (
        user.value.administrator &&
        user.value.inOwnerCompany &&
        // account is in free trial state
        account.value.trialDaysRemaining > 0 &&
        account.value.paymentStatus !== 'paid'
      );
    });
    const productOutcomeFlowEnabled = computed(() => {
      return (
        !isInLightspeed.value &&
        projectFinancialBudgetsEnabled.value &&
        projectTimeBudgetsEnabled.value &&
        workloadPlannerEnabled.value &&
        utilizationReportEnabled.value
      );
    });
    const showChecklistFlow = computed(() => {
      return (
        !productTourVisible.value &&
        !isChecklistDismissed.value &&
        productOutcomeFlowEnabled.value &&
        isAdminTrialUser.value
      );
    });

    const showChecklistButton = computed(() => {
      return (
        showChecklistFlow.value &&
        !isOnboardingRoute.value &&
        !isCheckListEnabled.value &&
        !isSmallDisplay.value
      );
    });

    watchEffect(() => {
      isOnboardingRoute.value = onboardingRoutePaths.some((path) =>
        route.value.path.includes(path),
      );
    });

    return {
      user,
      account,
      showChecklistFlow,
      showChecklistButton,
      productOutcomeFlowEnabled,
      incompleteItems,
      isChecklistDismissed,
      isSmallDisplay,
      productTourVisible,
      isCheckListEnabled,
      changeShowChecklistStatus,
      changePassiveHelpPanelStatus,
      isPassiveHelpPanelEnabled,
    };
  },
};
</script>
<style lang="css" scoped>
.reminder-container {
  position: fixed;
  bottom: 24px;
  right: 72px;
  z-index: 9999;
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.button-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1;
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
