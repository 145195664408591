const performance = window.performance;
// Performance metrics can be enabled through a session storage entry
const enabled = sessionStorage.getItem('shouldMeasurePerformance');

// Conditions under which we want to measure performance.
export const shouldMeasurePerformance = enabled && !!performance;

export default {
  shouldMeasurePerformance,
};
