<template>
  <div class="relative">
    <div
      class="
        rounded-full
        w-8
        h-8
        flex
        justify-center
        items-center
        grow
        text-default
        border-2 border-solid
        border-[color:var(--token-c-stepper-color-border)]
      "
      :class="{
        'bg-[color:var(--token-c-stepper-color-bubble-current)]': isActive,
        'bg-[color:var(--token-c-stepper-color-bubble)]': !isActive,
        'text-[color:var(--token-c-stepper-color-text-current)]': isActive,
        'text-[color:var(--token-c-stepper-color-text)]': !isActive,
        'cursor-pointer': $listeners.click,
      }"
      @click="$emit('click', index)"
    >
      <slot v-if="isCurrent" />
      <slot v-else name="complete">
        <IconTick
          class="
            fill-[color:var(--token-c-stepper-color-bubble-current)]
            w-4
            h-4
          "
        />
      </slot>
    </div>
    <div
      class="
        absolute
        text-xs
        leading-5
        text-center
        min-w-[var(--token-c-stepper-size-min-width)]
        max-w-[var(--token-c-stepper-size-max-width)]
        left-1/2
        top-10
        -translate-x-2/4
      "
      :class="{
        'text-[color:var(--token-c-stepper-color-label)]': !isActive,
        'text-[color:var(--token-c-stepper-color-label-current)]': isActive,
      }"
    >
      <slot name="title">{{ title }}</slot>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import IconTick from '~icons/tw/stepper-tick';
import { useSteps } from '../FSteps/useSteps';

export default {
  name: 'FStep',
  components: { IconTick },
  props: {
    title: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: -1,
    },
  },
  setup(props) {
    const { current } = useSteps();
    const isActive = computed(() => {
      return current.value === props.index;
    });
    const isCurrent = computed(() => {
      return current.value != null && props.index >= Number(current.value);
    });

    return { current, isActive, isCurrent };
  },
};
</script>
