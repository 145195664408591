<template>
  <div class="flex-auto flex flex-col overflow-scroll">
    <div v-if="!isFormSubmitted || !this.success" class="px-6 overflow-auto">
      <h1 class="text-md font-semibold leading-normal mb-6">
        {{ $t('Contact Support') }}
      </h1>
      <div class="w-full">
        <div>
          <div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                {{ $t('What do you want to contact us about?') }}
              </label>
              <SelectMulti
                :placeholder="$t('Choose')"
                :options="types"
                id="type"
                name="type"
                v-model="type"
                label="label"
                :class="{
                  'input--has-error': isFormSubmitted && $v.type.$invalid,
                }"
              />
              <div class="pt-2 text-sm">
                <span v-if="type.id === 1">
                  {{
                    $t(
                      'Great! We love getting suggestions but please check our',
                    )
                  }}
                  <a href="https://www.teamwork.com/roadmap" target="_blank">
                    {{ $t('roadmap') }}
                  </a>
                  {{
                    $t('first as we may be working on this feature already!')
                  }}
                </span>
                <span v-if="type.id === 2">
                  {{
                    $t(
                      "D'oh! Sorry about that, we hate bugs as much as you do! Please give us as much information as possible in the feedback below",
                    )
                  }}
                </span>
                <span v-if="type.id === 3">
                  {{
                    $t(
                      'Woohoo, a testimonial!! Thank you so much - these really brighten up our day. It will appear here',
                    )
                  }}
                  <a
                    href="https://www.teamwork.com/customer-stories"
                    target="_blank"
                  >
                    {{ $t('Testimonials') }}
                  </a>
                  {{ $t('P.S. please also let us know your Job Title') }}
                </span>
              </div>
              <span
                class="error-text"
                v-if="isFormSubmitted && $v.type.$invalid"
              >
                {{ $t('This field is required') }}
              </span>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                {{ $t('Your message') }}
              </label>
              <input
                id="summary"
                :placeholder="$t('Please provide a one line summary here')"
                type="text"
                name="summary"
                class="
                  hover:border-[#4461D7]
                  h-10
                  w-full
                  m-0
                  p-0
                  border-0
                  outline-none
                  text-default
                  leading-6
                  appearance-none
                  cursor-text
                  text-[color:var(--token-c-input-color-text-default)]
                  placeholder:color-[color:var(--token-c-input-color-text-placeholder-default)]
                  px-3
                  flex
                  items-center
                  gap-2
                  rounded
                  bg-[color:var(--token-c-input-color-background-outline-default)]
                  border-[color:var(--token-c-input-color-border-outline-default)]
                  hover:border-[color:var(--token-c-input-color-border-outline-hover)]
                "
                :class="{
                  'input--has-error': isFormSubmitted && $v.summary.$invalid,
                }"
                v-model="summary"
              />
              <span
                class="error-text"
                v-if="isFormSubmitted && $v.summary.$invalid"
              >
                {{ $t('This field is required') }}
              </span>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                {{ $t('Description (Optional)') }}
              </label>
              <div
                class="
                  border border-solid
                  px-3
                  flex
                  items-center
                  gap-2
                  py-2
                  rounded
                  bg-white
                  border-[color:var(--token-c-input-color-border-outline-default)]
                  hover:border-[color:var(--token-c-input-color-border-outline-hover)]
                "
              >
                <textarea
                  :placeholder="
                    $t('Provide us with a more detailed description...')
                  "
                  v-model="message"
                  id="message"
                  name="message"
                  class="
                    h-24
                    w-full
                    m-0
                    p-0
                    border-0
                    outline-none
                    text-default
                    leading-6
                    bg-transparent
                    appearance-none
                    text-[color:var(--token-c-input-color-text-default)]
                    placeholder:color-[color:var(--token-c-input-color-text-placeholder-default)]
                  "
                />
              </div>
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                {{ $t('Attach file (Optional)') }}
              </label>
              <FileUpload
                :is-uploading="isUploading"
                @upload-files="uploadFiles"
                @delete-file="deleteFile"
                :files="fileAttachments"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2">
                {{ $t('Priority') }}
              </label>
              <SelectMulti
                :placeholder="$t('Choose')"
                :options="priorities"
                v-model="priority"
                id="priority"
                label="label"
                name="priority"
                :class="{
                  'input--has-error': isFormSubmitted && $v.priority.$invalid,
                }"
              />
              <span
                class="error-text"
                v-if="isFormSubmitted && $v.priority.$invalid"
              >
                {{ $t('This field is required') }}
              </span>
            </div>
            <div class="mb-4">
              <FCheckbox v-model="sendCopyToMe">
                {{ $t('Send a copy of this message to my email') }}
              </FCheckbox>
            </div>
            <div class="mb-4">
              <FButton
                variant="primary"
                type="button"
                :loading="submitting"
                :disabled="submitting"
                class="min-w-[120px] mt-6 px-3 text-[13px]"
                @click="onSubmit"
              >
                {{ $t('Send Feedback') }}
              </FButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="content-center text-center px-6 pt-12">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mb-6"
      >
        <rect width="40" height="40" rx="20" fill="#4ECD97" />
        <g clip-path="url(#clip0_3649_10490)">
          <path
            d="M16.9999 24.17L12.8299 20L11.4099 21.41L16.9999 27L28.9999 15L27.5899 13.59L16.9999 24.17Z"
            fill="#0B0E1F"
          />
        </g>
        <defs>
          <clipPath id="clip0_3649_10490">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(8 8)"
            />
          </clipPath>
        </defs>
      </svg>
      <h3 class="text-xl font-semibold">{{ $t('Feedback Sent') }}</h3>
      <p>
        {{
          $t(
            'Thank you [0], your feedback will help us make this a better product. If you think of anything else, just send it on.',
            firstName,
          )
        }}
      </p>
      <button
        type="button"
        class="
          mt-6
          min-w-[120px]
          h-10
          px-3
          bg-primary
          hover:bg-primary--hover
          active:bg-primary--hover/90
          text-white
          border-none
          rounded-sm
        "
        @click="$emit('backToHelpCenterClicked')"
      >
        {{ $t('Back to Help Center') }}
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep .w-full.contact-upload.relative span {
  color: #3b4ca5;
  font-weight: bold;
}

::v-deep .multiselect {
  @apply h-10;
  &__tags {
    @apply h-10 flex items-center justify-center;
  }

  &__select {
    @apply top-[4px] right-1;
  }
}

input {
  /* White/10 */

  background: #ffffff;
  /* Neutral/20 */

  border: 1px solid #e1e6ee;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  &.error {
    border: 1px solid red;
  }

  &:focus {
    &.error {
      border: 1px solid red;
    }
  }
}
.input--has-error {
  border: 1px solid #e12d42;
}

.error-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #e12d42;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  display: block;
}
</style>
<script>
import { FCheckbox, FButton } from '@fragments';
import SelectMulti from '@widgets/SelectMulti';
import { required } from 'vuelidate/lib/validators';
import { useCurrentUser, useCurrentAccount } from '@teamwork/use';
import { computed, ref, Vue2 as Vue, watch } from 'vue-demi';
import debounce from 'lodash/debounce';
import FileUpload from '@/platform/components/side-nav/panels/help-center/common/FileUpload/FileUpload.vue';
import api from '@/services/api';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

const EMITSTATECONTACT = 'helpCenterContactFilledOut';

export default {
  name: 'HelpCenterContactSupportState',
  components: {
    SelectMulti,
    FCheckbox,
    FileUpload,
    FButton,
  },
  setup(props, { emit }) {
    const user = useCurrentUser();
    const account = useCurrentAccount();
    const priorities = ref([
      {
        id: 1,
        value: 'Low',
        label: Vue.t('Low'),
      },
      {
        id: 2,
        value: 'Medium',
        label: Vue.t('Medium'),
      },
      {
        id: 3,
        value: 'Important',
        label: Vue.t('High'),
      },
    ]);

    const priority = ref(priorities.value[0]);
    const message = ref(null);
    const summary = ref(null);
    const type = ref('');
    const fileAttachments = ref([]);
    const sideBarPanel = useSidebarPanels();
    const success = ref(false);

    watch(success, (val) => {
      if (val) {
        sideBarPanel.setHelpCenterContactFilledOut(false);
        emit(EMITSTATECONTACT, false);
      }
    });

    watch(fileAttachments, () => {
      if (fileAttachments.value.length > 0) {
        sideBarPanel.setHelpCenterContactFilledOut(true);
        emit(EMITSTATECONTACT, true);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (type.value !== '' || message.value || summary.value) {
          sideBarPanel.setHelpCenterContactFilledOut(true);
          emit(EMITSTATECONTACT, true);
        } else {
          sideBarPanel.setHelpCenterContactFilledOut(false);
          emit(EMITSTATECONTACT, false);
        }
      }
    });

    watch([type, message, summary], () => {
      if (fileAttachments.value.length < 1) {
        if (type.value !== '' || message.value || summary.value) {
          sideBarPanel.setHelpCenterContactFilledOut(true);
          emit(EMITSTATECONTACT, true);
        } else {
          sideBarPanel.setHelpCenterContactFilledOut(false);
          emit(EMITSTATECONTACT, false);
        }
      } else if (type.value !== '' || message.value || summary.value) {
        sideBarPanel.setHelpCenterContactFilledOut(true);
        emit(EMITSTATECONTACT, true);
      }
    });

    return {
      firstName: computed(() => user.value.firstName),
      account: computed(() => account.value),
      type,
      priority,
      priorities,
      message,
      summary,
      fileAttachments,
      success,
    };
  },
  data() {
    return {
      isUploading: false,
      active: false,
      isFormSubmitted: false,
      title: null,
      submitting: false,
      sendCopyToMe: false,
      types: [
        {
          id: 1,
          value: 'Feature Request',
          label: Vue.t('I want to request a feature'),
        },
        {
          id: 2,
          value: 'Bug Report',
          label: Vue.t('Bug Report'),
        },
        {
          id: 3,
          value: 'Testimonial',
          label: Vue.t('Testimonial'),
        },
        {
          id: 4,
          value: 'Just saying hi',
          label: Vue.t("Just saying 'Hi'"),
        },
        {
          id: 5,
          value: 'Question',
          label: Vue.t('I have a question'),
        },
        {
          id: 6,
          value: 'Interface Issue',
          label: Vue.t('Interface Issue'),
        },
      ],
    };
  },
  computed: {
    isFormValid() {
      return !this.$v.$invalid;
    },
  },
  methods: {
    onFileUploadError: debounce(
      function onFileUploadError(message) {
        this.$store.dispatch(
          'notifications/flashes/error',
          message || Vue.t('Failed to upload files'),
        );
      },
      300,
      { leading: true },
    ),
    onFileUploadSuccess: debounce(
      function onFileUploadSuccess(message) {
        this.$store.dispatch(
          'notifications/flashes/success',
          message || Vue.t('Files uploaded'),
        );
      },
      300,
      { leading: true },
    ),
    deleteFile(file) {
      const index = this.fileAttachments.findIndex(
        (attachment) => attachment.ref === file.ref,
      );
      this.fileAttachments.splice(index, 1);
    },
    uploadFiles(files) {
      this.isUploading = true;
      files.forEach((file) => {
        const form = new FormData();
        form.append('file', file);
        try {
          api
            .post('pendingfiles.json', form, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              this.fileAttachments.push({
                ref: response.data.pendingFile.ref,
                name: file.name,
              });
              this.onFileUploadSuccess();
              this.isUploading = false;
            })
            .catch((error) => {
              this.onFileUploadError(error);
              this.isUploading = false;
            });
        } catch {
          this.onFileUploadError();
          this.isUploading = false;
        }
      });
    },
    updateSummary() {
      let trialText = '';
      const hasActivePlanTrial =
        window.app.account.priceplanTrialData?.remainingDays &&
        window.app.account.priceplanTrialData.remainingDays() > 0;
      if (hasActivePlanTrial) {
        trialText = ` [Current trial: ${window.app.account.priceplanTrialData.name()} (${window.app.account.priceplanTrialData.remainingDays()} days left)]`;
      }
      return `${window.app.account.awsRegion()}: ${trialText} - ${
        this.summary
      }`;
    },
    async onSubmit() {
      this.submitting = true;
      this.isFormSubmitted = true;

      if (this.isFormValid) {
        const pendingFiles = this.fileAttachments.map((file) => file.ref);
        const formData = {
          feedback: {
            source: 'Teamwork Projects',
            summary: this.updateSummary(this.summary).trim(),
            message: this.message,
            type: this.type.value,
            sendACopy: this.sendCopyToMe,
            urgency: this.priority.value,
            fileurl: '',
            beatingType: '',
            buildInfo: `App ${window.appVersionId}; API ${window.cfVersionId}`,
            pendingFileAttachments: pendingFiles,
            updateFiles: true,
            attachments: '',
            removeOtherFiles: true,
          },
        };
        try {
          const response = await api.post('/feedback.json', formData);
          if (response.status === 200) {
            this.success = true;
            this.$emit('validFormSubmitted');
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.submitting = false;
        }
      } else {
        this.submitting = false;
      }
    },
  },
  validations: {
    type: {
      required,
    },
    summary: {
      required,
    },
    priority: {
      required,
    },
  },
};
</script>
