import { createListLoader, API } from '@/store/utils/loader';
import { createPagination } from '@/store/utils/loader/pagination/v3';

export default createListLoader({
  id: 'customTemplate/all',
  namespaced: true,
  config: {
    url: `${API.v3}/projects/templates.json`,
    params: ({ customTemplate }) => {
      const params = {
        orderMode: customTemplate.orderMode,
        include: 'companies,customfieldprojects,updatedby,tags,createdby',
      };

      // to avoid error if user clearing sorting through table view
      if (customTemplate.orderBy) {
        params.orderBy = customTemplate.orderBy;
      }

      return params;
    },
    module: 'customTemplate',
    sort: false,
    includedConfig: {
      customfieldProjects: 'customTemplateCustomFields',
      companies: 'company',
      users: 'people',
      tags: 'tag',
    },
    mapResponse: (ctx, rs) => {
      const includedTags = rs.data.included.tags || {};
      const includedCompanies = rs.data.included.companies || {};
      const includedCustomFields = rs.data.included.customfieldProjects || {};
      const result = rs.data.projects.map((proj) => {
        const tags = Object.values(includedTags).filter(
          (tag) => proj.tagIds && proj.tagIds.includes(tag.id),
        );
        const company = Object.values(includedCompanies)
          .filter((c) => proj.companyId === c.id)
          .map(({ id, name }) => ({ id, name }));
        const customFields = Object.values(includedCustomFields).filter(
          (field) =>
            proj.customFieldValueIds &&
            proj.customFieldValueIds.includes(field.id),
        );
        return { ...proj, tags, company, customFields };
      });
      return result;
    },
  },
  modules: {
    pagination: createPagination({
      config: { pageSize: 50 },
    }),
  },
});
