import { inject, provide } from 'vue-demi';

const useTableViewInternalSymbol = Symbol('useTableViewInternal');

export function provideTableViewInternal(tableView) {
  provide(useTableViewInternalSymbol, tableView);
}

export function useTableViewInternal() {
  return inject(useTableViewInternalSymbol, null);
}
