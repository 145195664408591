<template>
  <div>
    <Breadcrumb
      :breadcrumbs="breadcrumbs"
      :max="3"
      :data-identifier="dataIdentifier"
    />
  </div>
</template>

<script>
import Breadcrumb from '@widgets/Breadcrumb';

export default {
  name: 'BreadcrumbSection',
  components: {
    Breadcrumb,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    initialItem: {
      type: Object,
      default: () => {},
    },
    dataIdentifier: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    parentTasks: [],
    breadcrumbs: [],
  }),
  computed: {
    dependenciesCrumbs() {
      if (this.initialItem?.dependenciesCrumbs) {
        return this.initialItem.dependenciesCrumbs.map((dependency) => {
          return {
            text: dependency.text,
            onClick: () => this.$emit('replace-view-dependency', dependency.id),
          };
        });
      }
      return [];
    },
    projectCrumb() {
      if (this.task.project && this.task.projectId) {
        return {
          text: this.task.project.name,
          href: `#/projects/${this.task.projectId}`,
          onClick: () =>
            this.$router.push({
              path: `/projects/${this.task.projectId}`,
            }),
        };
      }
      return null;
    },
    tasklistCrumb() {
      if (this.task.taskList && this.task.taskListId) {
        return {
          text: this.task.taskList.name,
          href: `#/tasklists/${this.task.taskListId}/table`,
          onClick: () => {
            const targetPath = `/tasklists/${this.task.taskListId}/table`;
            // Avoid duplicate route change warning
            if (this.$router.history?.current?.path === targetPath) {
              this.$emit('close');
            } else {
              // we are targeting table view because it opens list view even if default is table. And TaskDetails quickview is supported there
              this.$router.push({
                path: `/tasklists/${this.task.taskListId}/table`,
              });
            }
          },
        };
      }
      return null;
    },
    projectAndTasklistCrumbs() {
      return [this.projectCrumb, this.tasklistCrumb].filter(Boolean);
    },
    parentTaskCrumbs() {
      return this.parentTasks
        .map((task) => ({
          text: task.name,
          href: `#/tasks/${task.id}`,
          onClick: () => this.$emit('replace-view', task.id),
        }))
        .reverse();
    },
    relatedTasks() {
      return this.task?.relatedTasks;
    },
  },
  watch: {
    relatedTasks: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.breadcrumbs = [];
          return;
        }
        if (Object.keys(newVal).length > 0) {
          this.parentTasks = [];
          this.orderParentTasks(this.relatedTasks[this.task.parentTaskId]);
        }

        if (this.dependenciesCrumbs.length) {
          this.breadcrumbs = [
            ...this.projectAndTasklistCrumbs,
            ...this.dependenciesCrumbs,
          ];
        } else if (this.parentTasks.length) {
          this.breadcrumbs = [
            ...this.projectAndTasklistCrumbs,
            ...this.parentTaskCrumbs,
          ];
        } else {
          this.breadcrumbs = this.projectAndTasklistCrumbs;
        }
      },
    },
  },
  methods: {
    orderParentTasks(task) {
      if (!task) {
        return;
      }
      this.parentTasks.push(task);
      if (task.parentTaskId && this.relatedTasks[task.parentTaskId]) {
        this.orderParentTasks(this.relatedTasks[task.parentTaskId]);
      }
    },
  },
};
</script>
