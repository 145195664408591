var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    { 'table-cell-date': !_vm.fromTaskDetails },
    _vm.color,
    { 'is-open': _vm.isOpen },
    { disabled: _vm.disabled } ],on:{"mouseenter":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false}}},[(_vm.disabled && _vm.disabledTooltip)?[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.disabledTooltip,
        classes: 'w-tooltip table-cell-date-disabled-tooltip',
        'handle-resize': false,
      }),expression:"{\n        content: disabledTooltip,\n        classes: 'w-tooltip table-cell-date-disabled-tooltip',\n        'handle-resize': false,\n      }"}],staticClass:"relative"},[(_vm.text)?[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")]:_c('empty-state-cell')],2)]:[_c('v-popover',{attrs:{"hide-on-target-click":false,"auto-hide":_vm.isMonthYearPopoverOpen,"open":_vm.isOpen,"placement":"bottom","popover-arrow-class":"hidden","popper-options":_vm.popperOptions},on:{"update:open":function($event){_vm.isOpen=$event}}},[_c('div',{class:[
          'date-label',
          _vm.fromTaskDetails ? 'task-details' : 'tasks-table',
          { 'is-open': _vm.isOpen } ],style:({ textAlign: _vm.alignText }),attrs:{"tabindex":!_vm.disabled ? 0 : -1},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.isOpen = false},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.togglePopover.apply(null, arguments)}],"click":_vm.togglePopover}},[(!_vm.text)?[(_vm.fromTaskDetails)?_c('AddButton',[_vm._v("\n            "+_vm._s(_vm.$t('Add date'))+"\n          ")]):(_vm.placeholder)?_c('span',{staticClass:"placeholder"},[_vm._v("\n            "+_vm._s(_vm.placeholder)+"\n          ")]):(_vm.isHovering)?_c('span',{staticClass:"fallback-label"},[_vm._v("\n            "+_vm._s(_vm.hoverLabelWithFallback)+"\n          ")]):_c('empty-state-cell')]:[_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.text))]),_vm._v(" "),(!_vm.fromTaskDetails)?_c('div',{staticClass:"edit-icon__wrapper"},[_c('CommonIcons',{staticClass:"edit-icon",attrs:{"color":"#555555","id":"edit"}})],1):_vm._e()]],2),_vm._v(" "),(_vm.isOpen)?_c('template',{slot:"popover"},[(_vm.isProjectTemplate)?_c('ProjectTemplateOffsetDayOptions',{attrs:{"date":_vm.date},on:{"change":_vm.onDateChange}}):_c('DatePicker',_vm._b({attrs:{"value":_vm.date,"show-default-shortcuts":_vm.quickSelect.length === 0,"shortcuts":_vm.quickSelect},on:{"input":_vm.onDateChange,"monthYearMenuVisibilityChange":function($event){_vm.isMonthYearPopoverOpen = $event}}},'DatePicker',Object.assign({}, _vm.pickerOptions),false))],1):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }