<template>
  <li class="w-item-picker__item">
    <Button
      ref="button"
      :style="{ backgroundColor: bgColor }"
      :class="{ 'is-active': !readOnly && isActive }"
      :disabled="readOnly"
    >
      <span
        @click="$emit('click')"
        v-tooltip="{
          content: isEllipsized ? text : null,
          placement: 'top',
          boundariesElement: 'document.body',
        }"
      >
        {{ text }}
      </span>
      <span v-if="$listeners && $listeners.delete" @click="$emit('delete')">
        <CommonIcon v-if="!readOnly && isActive" id="close" />
      </span>
    </Button>
  </li>
</template>

<script>
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import Button from '@widgets/Button';

// @vue/component
export default {
  name: 'Item',
  components: {
    CommonIcon,
    Button,
  },
  data() {
    return {
      isEllipsized: false,
    };
  },
  props: {
    readOnly: { type: Boolean, default: false },
    text: {
      type: String,
      default: '',
    },
    bgColor: {
      type: String,
      default: 'black',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const spanElement = this.$refs.button.$el.querySelector('span');
    if (!spanElement) {
      return;
    }

    // check if text ellipsis
    this.isEllipsized = spanElement.offsetWidth > 160;
  },
};
</script>

<style lang="scss">
.w-item-picker__item {
  .btn {
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 0 12px;
    margin-right: 8px;
    color: #fff;
    max-width: 210px;
    font-size: 12px;
    height: 24px;
    line-height: 24px;

    &.is-active {
      padding: 0 25px 0 12px;
    }

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    svg {
      fill: #fff;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
