<template>
  <div class="flex items-center gap-2">
    <FSwitch
      v-bind="$attrs"
      :disabled="disabled"
      :id="id"
      @change="$emit('change', $event)"
    />
    <FLabel
      v-bind="$attrs"
      :for="id"
      class="mb-0"
      :class="{ 'order-first': labelBeforeInput }"
    >
      <slot />
    </FLabel>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { FLabel, FSwitch } from '../../index';

export default {
  name: 'FSwitchField',
  model: {
    prop: 'checked',
    event: 'change',
  },
  inheritAttrs: false,
  components: {
    FSwitch,
    FLabel,
  },
  props: {
    labelBeforeInput: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup() {
    return {
      id: uuid(),
    };
  },
};
</script>
