import Vue from 'vue';
import SupersetIcon from '@teamwork/common-icons/dist/v-icon';
import EllipsisLabel from '@widgets/EllipsisLabel';

// @vue/component
export default {
  components: { SupersetIcon, EllipsisLabel },
  props: {
    value: { type: Boolean, default: false },
    enabled: { type: Boolean, default: true },
    label: { type: String, default: '' },
    id: { type: String, default: '' },
    clickBubble: { type: Boolean, default: true },
    showTitle: { type: Boolean, default: true },
    titleWhenSwitchOn: { type: String, default: '' },
    titleWhenSwitchOff: { type: String, default: '' },
    reversedOrder: { type: Boolean, default: false },
    tip: { type: String, default: '' },
    readOnly: { type: Boolean, default: false },
    attachTooltipToTrigger: { type: Boolean, default: false },
    // classes intentionally not included here, don't add it back in, it is an anti-pattern
  },
  data() {
    return {
      changing: false,
    };
  },
  computed: {
    title: ({ value, titleWhenSwitchOn, titleWhenSwitchOff }) =>
      value
        ? titleWhenSwitchOn || Vue.t('Yes')
        : titleWhenSwitchOff || Vue.t('No'),
  },
  methods: {
    toggle(e) {
      this.$emit('input', !this.value);
      if (!this.clickBubble) {
        e.stopPropagation();
      }
    },
  },
  watch: {
    value() {
      this.changing = true;
      setTimeout(() => {
        this.changing = false;
      }, 50);
    },
  },
};
