import moment from 'moment';
import lscache from 'lscache';

const cachedStart = lscache.get('schedule-start-date');
const cachedEnd = lscache.get('schedule-end-date');

export default {
  startDate: cachedStart
    ? moment(cachedStart).toDate()
    : moment.tz(moment.tz.guess()).startOf('isoWeek').toDate(),
  endDate: cachedEnd
    ? moment(cachedEnd).toDate()
    : moment.tz(moment.tz.guess()).endOf('isoWeek').toDate(),
  type: '',
};
