<template>
  <div class="date-label">
    <Date :date="getDate" hover-label="No date" @change="onChange" />
  </div>
</template>

<script>
import moment from 'moment';
import { Date } from '@widgets/TableCellWidgets';

export default {
  name: 'DateLabel',
  display: 'DateLabel',
  props: {
    date: { type: [String, Object], required: true, default: null },
    format: { type: String, required: false, default: 'YY/MM/DD' },
  },
  components: { Date },
  methods: {
    onChange(date) {
      this.$emit('change', date);
    },
  },
  computed: {
    getDate() {
      if (!this.date) {
        return moment();
      }

      if (this.date instanceof moment) {
        return this.date;
      }

      return moment();
    },
  },
};
</script>
<style lang="scss" scoped>
.date-label {
  ::v-deep .table-cell-date {
    display: inline;
    padding: 0;
    color: #000;

    * {
      width: auto !important;
    }

    .placeholder {
      font-size: inherit !important;
      line-height: inherit !important;
    }
  }
}

.date-label {
  text-align: left !important;
}

.w-empty-state-cell {
  justify-content: flex-start !important;
}
</style>
