/* eslint-disable no-param-reassign */
import Vue from 'vue';
import { MUTATIONS, STATUS } from '@/store/utils/loader/constants';

const initialise = (status, init) => (state) => {
  Vue.set(state, 'paginator', status);
  if (!state[status]) {
    Vue.set(state, status, { ...init });
  } else {
    Vue.assign(state[status], { ...init });
  }
};

export default (cfg) => {
  const init = {
    page: 1,
    pageSize: cfg.pageSize || 50,
    hasMore: false,
  };
  return {
    [MUTATIONS.INIT]: initialise(STATUS.loading, init),
    [MUTATIONS.UPDATING]: initialise(STATUS.updating, init),
    [MUTATIONS.PAGINATION.NEXT_PAGE](state) {
      state[state.paginator].page += 1;
    },
    [MUTATIONS.HEADERS](state, { records }) {
      Vue.set(
        state[state.paginator],
        'totalRecords',
        Number.parseInt(records, 10),
      );
    },
    // When the loading has finished, reset the paginator
    // to the loading one. This means the 'hasMore' getter
    // will work.
    [MUTATIONS.LOADED](state) {
      state.paginator = STATUS.loading;
    },
    [MUTATIONS.PAGINATION.LOADED](state, { count, hasMore }) {
      Vue.set(
        state[state.paginator],
        'totalRecords',
        Number.parseInt(count, 10),
      );
      Vue.set(state[state.paginator], 'hasMore', hasMore);
    },
  };
};
