<template>
  <SectionBlock :title="$t('Dependencies')" :small="true">
    <ButtonText
      v-if="task.predecessorIds"
      slot="accessory"
      @click="isDependenciesVisible = !isDependenciesVisible"
    >
      <span>{{ toggleButtontext }}</span>
      <CommonIcons color="#4461D7" :id="toggleButtonChevron" />
    </ButtonText>
    <CollapsePanel :is-open="isDependenciesVisible">
      <DependenciesItems
        class="dependencies-items"
        :task="task"
        :initial-item="initialItem"
        @remove-predecessor="onRemovePredecessor"
        @edit-predecessor="onEditPredecessor"
      />
    </CollapsePanel>
    <DependencyPicker
      :disabled="false"
      :open="isDependencyPickerOpen"
      :selected-task="task"
      @add-new-predecessors="onAddPredecessors"
      @open="isDependencyPickerOpen = true"
      @close="isDependencyPickerOpen = false"
    >
      <ButtonText ref="showAddNewDependency" underline>
        + {{ $t('Add dependency') }}
      </ButtonText>
    </DependencyPicker>
  </SectionBlock>
</template>

<script>
import { useTaskActions } from '@teamwork/use';
import { useLocalStorage } from '@vueuse/core';
import { computed, Vue2 as Vue } from 'vue-demi';
import DependencyPicker from '@widgets/DependencyPicker/DependencyPicker';
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import CollapsePanel from '@widgets/CollapsePanel';
import SectionBlock from '../SectionBlock';
import DependenciesItems from './DependenciesItems';
import ButtonText from '../ButtonText';

export default {
  name: 'Dependencies',
  props: {
    task: {
      type: Object,
      default: () => null,
    },
    initialItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDependencyPickerOpen: false,
    };
  },
  setup(props) {
    const isDependenciesVisible = useLocalStorage(
      'task-detail/show-dependencies',
      true,
    );

    const toggleButtontext = computed(() =>
      isDependenciesVisible.value ? Vue.t('Hide') : Vue.t('Show'),
    );
    const toggleButtonChevron = computed(() =>
      isDependenciesVisible.value ? 'angle-up' : 'angle-down',
    );

    const { updateTask } = useTaskActions();

    function onAddPredecessors(addPredecessors) {
      this.isDependencyPickerOpen = false;
      const predecessors = props.task.predecessorTasks
        ? Object.values(props.task.predecessorTasks)
        : [];

      addPredecessors.forEach((predecessor) => {
        if (predecessor.id && predecessor.name) {
          predecessors.push({
            id: predecessor.id,
            name: predecessor.name,
            type: 'start',
            status: 'new',
          });
        }
      });
      const updatedPredecessors = {
        id: props.task.id,
        predecessors,
      };
      isDependenciesVisible.value = true;
      updateTask(updatedPredecessors, props.task);
    }

    function onRemovePredecessor(removePredecessor) {
      const currentPredecessors = Object.values(props.task.predecessorTasks);
      if (currentPredecessors) {
        const updatedPredecessors = {
          id: props.task.id,
          predecessors: currentPredecessors.filter((predecessor) => {
            return predecessor.id !== removePredecessor.id;
          }),
        };
        updateTask(updatedPredecessors, props.task);
      }
    }

    function onEditPredecessor(editPredecessor) {
      const predecessors = Object.values(props.task.predecessorTasks);
      if (editPredecessor) {
        const editPredecessorIndex = predecessors.findIndex((predecessor) => {
          return predecessor.id === editPredecessor.id;
        });
        predecessors[editPredecessorIndex] = editPredecessor;
        const updatedPredecessors = {
          id: props.task.id,
          predecessors,
        };
        updateTask(updatedPredecessors, props.task);
      }
    }

    return {
      onAddPredecessors,
      onRemovePredecessor,
      onEditPredecessor,
      toggleButtontext,
      toggleButtonChevron,
      isDependenciesVisible,
    };
  },
  components: {
    SectionBlock,
    ButtonText,
    DependenciesItems,
    DependencyPicker,
    CommonIcons,
    CollapsePanel,
  },
};
</script>
<style lang="scss" scoped>
.dependencies-items {
  margin-bottom: 8px;
}
</style>
