var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Root",class:{ hidden: _vm.loading }},[(_vm.ready)?[_c('Layout',{scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('LayoutNavigation',{scopedSlots:_vm._u([{key:"base-part",fn:function(){return [_c('Logo')]},proxy:true},{key:"scrolling-top",fn:function(){return [_c('TopNavigationList')]},proxy:true},{key:"scrolling-bottom",fn:function(){return [_c('PromotionalArea'),_vm._v(" "),_c('BottomNavigationList')]},proxy:true},{key:"bottom-part",fn:function(){return [_c('Profile')]},proxy:true}],null,false,4255972335)})]},proxy:true},{key:"sidebar-pinned-panels",fn:function(){return [_c('TeamworkTransition',{attrs:{"name":_vm.isProjectsPanelPinned ? 'noop' : 'slide'}},[(_vm.isProjectsPanelOpen)?_c('ProjectsPanel'):_vm._e()],1)]},proxy:true},{key:"content",fn:function(){return [_c('App',{nativeOn:{"click":function($event){return _vm.hideActivePanel($event, true)}}})]},proxy:true}],null,false,1226638967)}),_vm._v(" "),[_c('TeamworkTransition',{attrs:{"name":"slide"}},[(_vm.isSearchPanelOpen)?_c('SearchPanel',{class:{
            'left-[208px]': !_vm.shouldMinimizeSidebar,
            'left-[84px]': _vm.shouldMinimizeSidebar,
          }}):_vm._e()],1),_vm._v(" "),_c('TeamworkTransition',{attrs:{"name":"slide"}},[(_vm.isHelpCenterPanelOpen)?_c('HelpCenterPanel',{class:{
            'left-[208px]': !_vm.shouldMinimizeSidebar,
            'left-[84px]': _vm.shouldMinimizeSidebar,
          }}):_vm._e()],1),_vm._v(" "),_c('TeamworkTransition',{attrs:{"name":"slide"}},[(_vm.isHybridReady)?_c('NotificationsPanel',{directives:[{name:"show",rawName:"v-show",value:(_vm.isNotificationsPanelOpen),expression:"isNotificationsPanelOpen"}],class:{
            'left-[208px]': !_vm.shouldMinimizeSidebar,
            'left-[84px]': _vm.shouldMinimizeSidebar,
          }}):_vm._e()],1)]]:(_vm.hasError)?_c('AppError'):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }