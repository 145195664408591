<template>
  <ItemList
    v-model="tags"
    :list-mode="listMode"
    :wrap-items="wrapItems"
    :read-only="readonly"
  />
</template>

<script>
// note:
// this component seems pointless for now since it just uses ItemList,
// but eventually tag specific style / layout should go in here via slots
import ItemList from '@widgets/ItemList';
// @vue/component
export default {
  name: 'TagList',
  components: {
    ItemList,
  },
  props: {
    listMode: { type: String, default: 'vertical' },
    value: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
    wrapItems: { type: Boolean, default: false },
  },
  computed: {
    tags: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
};
</script>
