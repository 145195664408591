<script>
import { shallowRef } from 'vue-demi';
import { useDelayLoader, useProjectsLoader } from '@teamwork/use';
import LoaderState from '@/platform/components/LoaderState';
import ProjectsPanelProjectList from './ProjectsPanelProjectList.vue';
import ProjectsPanelInformationalMessage from './ProjectsPanelInformationalMessage.vue';
import ProjectsPanelLoadingState from './ProjectsPanelLoadingState.vue';
import StarredProjectsBlankSlate from './StarredProjectsBlankSlate.vue';

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoaderState,
    ProjectsPanelProjectList,
    ProjectsPanelInformationalMessage,
    ProjectsPanelLoadingState,
    StarredProjectsBlankSlate,
  },

  setup() {
    const starredCount = shallowRef(20);

    const starredProjectsState = useDelayLoader(
      useProjectsLoader({
        count: starredCount,
        pageSize: shallowRef(20),
        params: shallowRef({
          searchTerm: null,
          include: 'companies',
          includeProjectUserInfo: true,
          'fields[project]': 'name, isStarred, companyId',
          orderBy: 'name',
          orderMode: 'asc',
          onlyStarredProjects: true,
        }),
      }),
    );

    return {
      starredCount,
      starredProjectsState,
    };
  },
};
</script>

<template>
  <LoaderState
    v-if="isActive"
    :count.sync="starredCount"
    :state="starredProjectsState"
    class="h-full recent-project-list--scroll-container"
  >
    <template #data="{ items }">
      <div class="w-full pb-2">
        <ProjectsPanelProjectList
          class="px-6"
          :class="{ 'min-h-[399px]': items.length > 3 }"
          :projects="items"
        />

        <ProjectsPanelInformationalMessage
          class="pt-8"
          v-if="items.length < 3"
        />
      </div>
    </template>
    <template #blank>
      <StarredProjectsBlankSlate />
    </template>
    <template #loading>
      <ProjectsPanelLoadingState />
    </template>
  </LoaderState>
</template>
