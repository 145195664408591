<template>
  <div
    class="flex items-center bg-white rounded-full text-text-tertiary h-[40px]"
    :data-identifier="identifierPrefix && `${identifierPrefix}__search-input`"
  >
    <div
      class="h-full inline-flex items-center pl-3 cursor-text flex-auto min-w-0"
      :class="[isFocused && 'text-text']"
      @click="focus"
    >
      <!-- icon-search -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        class="transition-colors fill-current"
        width="14"
        height="14"
      >
        <path
          d="M 17.876 16.484 L 13.608 12.216 C 13.527 12.135 13.422 12.093 13.309 12.093 L 12.845 12.093 C 13.953 10.81 14.624 9.14 14.624 7.312 C 14.624 3.273 11.351 0 7.312 0 C 3.273 0 0 3.273 0 7.312 C 0 11.351 3.273 14.624 7.312 14.624 C 9.14 14.624 10.81 13.953 12.093 12.846 L 12.093 13.31 C 12.093 13.422 12.139 13.528 12.216 13.608 L 16.484 17.876 C 16.649 18.041 16.916 18.041 17.082 17.876 L 17.876 17.082 C 18.041 16.916 18.041 16.649 17.876 16.484 Z M 7.312 12.937 C 4.204 12.937 1.687 10.42 1.687 7.312 C 1.687 4.204 4.204 1.687 7.312 1.687 C 10.42 1.687 12.937 4.204 12.937 7.312 C 12.937 10.42 10.42 12.937 7.312 12.937 Z"
        />
      </svg>
      <input
        v-model="search"
        type="search"
        autocomplete="off"
        ref="input"
        :placeholder="placeholder"
        class="
          inline-flex
          bg-transparent
          border-0
          text-[13px]
          ml-3
          p-0
          outline-none
          transition-colors
          flex-1
          min-w-0
          placeholder-text-tertiary
        "
        @input="handleInput"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @keydown.esc="onClearSearch"
        @keydown.enter="$emit('enter', $event)"
      />
      <button
        v-if="search"
        class="
          inline-flex
          items-center
          justify-center
          rounded-full
          bg-transparent
          border-none
          text-text-tertiary
          hover:text-text
          transition-colors
          w-[32px]
          h-[32px]
          p-2
          mr-1
          flex-none
        "
        title="Clear search"
        v-tooltip="{
          content: $t('Clear search'),
          placement: 'bottom',
          boundariesElement: 'document.body',
          disabled: !search,
        }"
        :tabindex="!search ? -1 : 0"
        :class="[...(!search ? ['opacity-0', 'cursor-text'] : ['opacity-1'])]"
        @click="search ? onClearSearch() : focus()"
        :disabled="!search"
        :data-identifier="
          identifierPrefix && `${identifierPrefix}__search-input__clear`
        "
      >
        <!-- icon-close -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
          class="fill-current"
          width="10"
          height="10"
        >
          <path
            d="M 6.061 5 L 9.266 1.795 L 9.927 1.134 C 10.024 1.036 10.024 0.879 9.927 0.781 L 9.219 0.074 C 9.122 -0.024 8.963 -0.024 8.866 0.074 L 5 3.94 L 1.134 0.073 C 1.036 -0.025 0.879 -0.025 0.781 0.073 L 0.073 0.78 C -0.025 0.878 -0.025 1.036 0.073 1.134 L 3.94 5 L 0.073 8.866 C -0.025 8.963 -0.025 9.122 0.073 9.219 L 0.78 9.927 C 0.878 10.024 1.036 10.024 1.134 9.927 L 5 6.061 L 8.205 9.266 L 8.866 9.927 C 8.963 10.024 9.122 10.024 9.219 9.927 L 9.927 9.219 C 10.024 9.122 10.024 8.963 9.927 8.866 L 6.061 5 Z"
          />
        </svg>
      </button>
    </div>
    <slot name="extra" />
  </div>
</template>
<script>
import debounce from 'lodash/debounce';
import { computed, ref, onMounted, shallowRef, watch } from 'vue-demi';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Search',
    },
    debounceTimeout: {
      type: Number,
      required: false,
      default: 350,
    },
    identifierPrefix: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const input = ref(null);
    const isFocused = shallowRef(false);
    const search = ref(null);

    const { hideActivePanel } = useSidebarPanels();

    const handleInput = computed(() => {
      const fn = () => emit('input', search.value);
      if (!props.debounceTimeout) {
        return fn;
      }
      return debounce(fn, props.debounceTimeout);
    });

    const onClearSearch = () => {
      if (search.value) {
        search.value = '';
        emit('input', '');
        return;
      }
      hideActivePanel();
    };

    function focus() {
      if (!(input.value instanceof HTMLElement)) {
        return;
      }
      input.value.focus();
    }

    onMounted(() => {
      focus();
    });

    // Ensure programatically set `value` is synced with `search`
    watch(
      () => props.value,
      () => {
        if (search.value !== props.value) {
          search.value = props.value;
        }
      },
      {
        immediate: true,
      },
    );

    return {
      input,
      isFocused,
      search,
      handleInput,
      onClearSearch,
      focus,
    };
  },
};
</script>
