export default {
  BulkEditUserBillableRatesModal: () =>
    import(
      './BulkEditUserBillableRatesModal/BulkEditUserBillableRatesModal.vue'
    ),
  ProjectBillableRatesModal: () =>
    import('./ProjectBillableRatesModal/ProjectBillableRatesModal.vue'),
  BudgetUserRatesModal: () =>
    import('./BudgetUserRatesModal/BudgetUserRatesModal.vue'),
};
