<template>
  <div class="table-cell-tick">
    <CommonIcon id="check" v-if="value" color="#3b93f7" />
  </div>
</template>

<script>
import SupersetIcon from '@teamwork/common-icons/dist/v-icon';

export default {
  name: 'Tick',
  display: 'Tick',
  components: {
    CommonIcon: SupersetIcon,
  },
  props: {
    value: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="scss">
.table-cell-tick {
  width: 100%;
  padding: 10px;
  text-align: center;
}
</style>
