import range from 'lodash/range';

// Array remove operation, assuming single occurrence
// will not fail if the element doesn't exist in the array
// eslint-disable-next-line import/prefer-default-export
export const remove = (arr, el) => {
  const index = arr.indexOf(el);
  if (index > -1) {
    arr.splice(index, 1);
  }
};

// return a random element from within an array
export const random = (arr) => {
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};

// port of app.utility.shuffle
// randomise or shuffle a given array
export const shuffle = (arr) => {
  const newArr = [...arr];
  let currentIndex = newArr.length;
  let randomIndex = 0;
  let temporaryValue = null;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = newArr[currentIndex];
    newArr[currentIndex] = newArr[randomIndex];
    newArr[randomIndex] = temporaryValue;
  }
  return newArr;
};

/**
 * Generate a range of integer
 * @param {Number} min range's minimum (start) value
 * @param {Number} max range's maximum (last) value
 * @param {Number} interval interval between values
 */
export const generateRange = (min, max, interval = 1) => {
  if (!max) {
    return [];
  }
  return [...range(min, max, interval), max];
};
