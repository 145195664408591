<template>
  <PopoverMenuActionItem @click="openMyShortcuts" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="16"
        viewBox="0 0 12 16"
      >
        <path
          d="M10.167.5H1.833C.917.5.167 1.25.167 2.167V15.5L6 13l5.833 2.5V2.167c0-.917-.75-1.667-1.666-1.667Zm0 12.5L6 11.183 1.833 13V2.167h8.334V13Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('My Shortcuts') }}
    </template>
  </PopoverMenuActionItem>
</template>

<script>
import PopoverMenuActionItem from './PopoverMenuActionItem.vue';

export default {
  components: {
    PopoverMenuActionItem,
  },
  methods: {
    openMyShortcuts() {
      this.$store.dispatch('quickViews/tko/open', { id: 'myShortcuts' });
    },
  },
};
</script>
