var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    {
      disabled: _vm.readonly,
    },
    'task-tags',
    _vm.view === 'tasks-table' ? 'table-cell-tags' : '' ]},[(!_vm.readonly || (_vm.view === 'task-details' && _vm.value.length > 0))?_c('TagPicker',{class:{
      'mr--small': _vm.view === 'tasks-table',
      'table-cell-tags__picker': _vm.view === 'tasks-table',
      'table-cell-tags__picker--inactive':
        _vm.view === 'tasks-table' && !_vm.pickerOpen,
    },attrs:{"project-ids":("0," + _vm.projectId),"limit":_vm.limit,"disabled":_vm.readonly},on:{"show":function($event){_vm.pickerOpen = true},"hide":function($event){_vm.pickerOpen = false}},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}},[(_vm.view === 'task-details')?_c('AddButton',{attrs:{"disabled":_vm.readonly}},[(_vm.value.length === 0)?[_vm._v(_vm._s(_vm.$t('Add tags')))]:_vm._e()],2):_vm._e()],1):_vm._e(),_vm._v(" "),_c('TagList',{attrs:{"readonly":_vm.readonly,"list-mode":"horizontal","wrap-items":_vm.view === 'task-details',"items-are-selected":true},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}}),_vm._v(" "),(_vm.view === 'tasks-table')?_c('div',{staticClass:"chevron"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }