var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{staticClass:"\n      rounded-full\n      w-8\n      h-8\n      flex\n      justify-center\n      items-center\n      grow\n      text-default\n      border-2 border-solid\n      border-[color:var(--token-c-stepper-color-border)]\n    ",class:{
      'bg-[color:var(--token-c-stepper-color-bubble-current)]': _vm.isActive,
      'bg-[color:var(--token-c-stepper-color-bubble)]': !_vm.isActive,
      'text-[color:var(--token-c-stepper-color-text-current)]': _vm.isActive,
      'text-[color:var(--token-c-stepper-color-text)]': !_vm.isActive,
      'cursor-pointer': _vm.$listeners.click,
    },on:{"click":function($event){return _vm.$emit('click', _vm.index)}}},[(_vm.isCurrent)?_vm._t("default"):_vm._t("complete",function(){return [_c('IconTick',{staticClass:"\n          fill-[color:var(--token-c-stepper-color-bubble-current)]\n          w-4\n          h-4\n        "})]})],2),_vm._v(" "),_c('div',{staticClass:"\n      absolute\n      text-xs\n      leading-5\n      text-center\n      min-w-[var(--token-c-stepper-size-min-width)]\n      max-w-[var(--token-c-stepper-size-max-width)]\n      left-1/2\n      top-10\n      -translate-x-2/4\n    ",class:{
      'text-[color:var(--token-c-stepper-color-label)]': !_vm.isActive,
      'text-[color:var(--token-c-stepper-color-label-current)]': _vm.isActive,
    }},[_vm._t("title",function(){return [_vm._v(_vm._s(_vm.title))]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }