<template>
  <div class="overflow-y-scroll inline-flex min-h-full">
    <div
      class="mx-6 mb-10 flex-auto"
      :class="{ 'h-[fit-content]': !isLoading }"
    >
      <HelpCenterArticleSkeleton v-if="isLoading" :title="title" />
      <div v-else>
        <div
          class="
            pb-6
            border-b border-x-0 border-t-0 border-[#C5CEE0] border-solid
          "
        >
          <h1
            :class="`text-[20px] text-ellipsis text-[#0B0E1F] font-[600] ${
              formattedLastModifiedDate ? 'mb-2' : '-mb-2'
            }`"
          >
            {{ title }}
          </h1>
          <span
            v-if="formattedLastModifiedDate"
            class="text-[14px] text-ellipsis text-[#646D7D]"
          >
            {{ $t('Last modified on') }}
            {{ formattedLastModifiedDate }}
          </span>
        </div>
        <div
          :class="{
            'pb-6 mb-6 border-b border-x-0 border-t-0 border-[#C5CEE0] border-solid':
              relatedArticles.length > 0,
          }"
        >
          <div class="my-6 text-[16px] leading-[20.8px]" v-html="content" />
          <HelpCenterMenuItem
            :is-button="false"
            icon="website"
            label="Open in our support website"
            :href="canonicalURL"
            target="_blank"
          />
        </div>
        <HelpCenterMenu
          v-if="relatedArticles.length > 0"
          :title="$t('Related articles')"
          :items="relatedArticles"
          @menuItemClicked="
            $emit(
              'relatedArticleClicked',
              $event.categorySlug,
              $event.slug,
              $event.title,
            )
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue-demi';
import { useLocalization } from '@/platform/composables/useLocalization';
import HelpCenterArticleSkeleton from './common/HelpCenterArticleSkeleton.vue';
import HelpCenterMenu from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenu.vue';
import HelpCenterMenuItem from '@/platform/components/side-nav/panels/help-center/common/HelpCenterMenuItem.vue';

export default {
  components: {
    HelpCenterArticleSkeleton,
    HelpCenterMenu,
    HelpCenterMenuItem,
  },
  setup(props) {
    const { languageCode } = useLocalization();

    const formatter = new Intl.DateTimeFormat(languageCode.value, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });

    const formatISODate = (dateString) => {
      try {
        return formatter.format(new Date(dateString));
      } catch {
        return undefined;
      }
    };

    const formattedLastModifiedDate = computed(() => {
      return formatISODate(props.lastModifiedISO);
    });

    const relatedArticles = computed(() => {
      return props.rawRelatedArticles.map((article) => ({
        ...article,
        label: article.title,
      }));
    });

    return {
      formatISODate,
      formattedLastModifiedDate,
      relatedArticles,
    };
  },
  props: {
    isLoading: { type: Boolean, default: true, required: true },
    title: { type: String, default: '' },
    lastModifiedISO: { type: String, default: '' },
    content: { type: String, default: '' },
    canonicalURL: { type: String, default: '' },
    rawRelatedArticles: { type: Array, default: () => [] },
  },
};
</script>
<style scoped>
::v-deep table {
  @apply bg-white;
}

::v-deep div > img {
  @apply static block max-w-full object-contain mx-auto #{!important};
}

::v-deep td {
  @apply overflow-x-scroll #{!important};
}
</style>
