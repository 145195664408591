export default function updateTaskAssignees({ task, assignees, updateTask }) {
  updateTask(
    {
      id: task.id,
      notify: task.notify,
      assignedTo: assignees
        .filter((x) => x.linkedItem.pickerItemType === 'user')
        .map((x) => ({
          id: x.id,
          avatarUrl: x.linkedItem.avatarUrl || x.linkedItem.defaultImageUrl,
          firstName: x.linkedItem.firstName,
          lastName: x.linkedItem.lastName,
        })),
      assignedToCompanies: assignees
        .filter((x) => x.linkedItem.pickerItemType === 'company')
        .map((x) => ({
          companyId: x.companyId / 1,
          companyLogoURL: x.linkedItem.defaultImageUrl,
          companyName: x.label,
        })),
      assignedToTeams: assignees
        .filter((x) => x.linkedItem.pickerItemType === 'team')
        .map((x) => ({
          teamId: x.teamId,
          teamLogo: x.logo,
          teamLogoColor: x.color,
          teamLogoIcon: x.icon,
          teamName: x.label,
        })),
    },
    task,
  );
}
