import { passive } from '@/utils/helpers/promise';
import { remove } from '@/utils/helpers/array';

const visibleNotifications = [];
let unloadEvent = false;

const removeNotification = (notification) =>
  remove(visibleNotifications, notification);

// Principally for the benefit of unit testing
let NotifImpl = window.Notification;
export const setNotificationImpl = (impl) => {
  NotifImpl = impl;
};

/**
 * If Notifications API is available
 # Detect if the app is currently hidden (active tab/window)
 # If it is hidden, show a desktop notification with a click handler
 *
 * @param {String} notif.title
 * @param {String} notif.description (from socket event)
 * @param {Object} notif.extraInfo.data (from socket event)
 * @return {Promise} resolved if the user clicks on the notification, rejected if the notification
 * is not displayed for any reason.
 */
export default (notif) => {
  if (!NotifImpl) {
    return Promise.reject();
  }

  if (NotifImpl.permission !== 'granted') {
    return Promise.reject();
  }

  if (!unloadEvent) {
    window.addEventListener('unload', () => {
      visibleNotifications.forEach((notification) => {
        try {
          notification.close();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.info("Couldn't programmatically close notification");
        }
      });
    });
    unloadEvent = true;
  }

  const click = passive();

  const { icon, tag, body } = notif;
  const notification = new NotifImpl(notif.title, { icon, tag, body });
  notification.onclick = (e) => {
    // firstly, focus the window and then go to the affected item
    window.focus();
    setTimeout(() => {
      e.target.close();
      // remove notification from collection
      removeNotification(notification);
    }, 500);
    click.resolve();
  };

  visibleNotifications.push(notification);

  // The default notification timeout seems to be at least 20 seconds.
  // This can be very annoying when working with other apps too,
  // so we close the notification after 10 seconds
  setTimeout(() => {
    try {
      notification.close();
      removeNotification(notification);
    } catch (e) {
      // do nothing
    }
  }, 10000);

  return click;
};
