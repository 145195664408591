var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label || _vm.$slots.default)?_c('label',{staticClass:"text-xs font-semibold mb-2",attrs:{"for":_vm.id}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"h-1 relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputProgress),expression:"inputProgress"}],staticClass:"rounded-full h-1 bg-transparent z-20 w-full absolute",attrs:{"type":"range","name":_vm.name,"id":_vm.id,"step":_vm.step,"max":_vm.max,"min":_vm.min,"required":_vm.required,"disabled":_vm.disabled},domProps:{"value":(_vm.inputProgress)},on:{"change":function($event){_vm.$emit('change', Number(_vm.inputProgress))},"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"__r":function($event){_vm.inputProgress=$event.target.value}}}),_vm._v(" "),(_vm.hover)?_c('span',{staticClass:"\n        p-2\n        rounded\n        z-30\n        bg-[color:var(--token-c-slider-color-tooltip)]\n        text-white\n        absolute\n        -translate-x-2/4\n        bottom-[16px]\n      ",style:({
        left: ("" + _vm.position),
      })},[_vm._v("\n      "+_vm._s(_vm.inputProgress)+"\n    ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"\n        rounded-full\n        h-1\n        absolute\n        bg-[color:var(--token-c-slider-color-progress)]\n        top-0\n        left-0\n        right-0\n        z-10\n      ",class:{
        'bg-[color:var(--token-c-slider-color-progress-disabled)]': _vm.disabled,
      },style:({
        width: (_vm.percent + "%"),
      })}),_vm._v(" "),_c('span',{staticClass:"\n        rounded-full\n        h-1\n        absolute\n        bg-[color:var(--token-c-slider-color-track)]\n        top-0\n        left-0\n        right-0\n        z-0\n      ",class:{
        'bg-[color:var(--token-c-slider-color-track-disabled)]': _vm.disabled,
      }})])])}
var staticRenderFns = []

export { render, staticRenderFns }