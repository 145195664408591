<template>
  <button
    :type="type"
    v-on="disabled || loading ? undefined : $listeners"
    :class="buttonClasses"
    :disabled="disabled"
  >
    <span
      v-if="$slots.default"
      :class="[
        'inline-flex justify-items-center fab-slot',
        { invisible: loading },
      ]"
    >
      <slot />
    </span>

    <span
      class="
        animate-spin
        absolute
        object-center
        flex
        justify-items-center
        content-center
        fab-slot
      "
      v-if="loading"
    >
      <IconSpinner />
    </span>
  </button>
</template>

<script>
import { computed } from 'vue-demi';
import { fabVariants, defaultFabVariant } from './consts';
import IconSpinner from '~icons/tw/spinner';
import { btnProps, useCommonBtn } from '../FButton/buttonCommon';
import optionPropValidator from '../../../helpers/optionPropValidator';

export default {
  name: 'FFabButton',
  components: { IconSpinner },
  props: {
    ...btnProps,
    variant: {
      type: String,
      default: defaultFabVariant,
      validator: optionPropValidator(fabVariants, 'variant'),
    },
    raised: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { buttonClasses } = useCommonBtn(
      computed(() => {
        return { ...props, fab: true };
      }),
    );

    return {
      buttonClasses,
    };
  },
};
</script>
<style scoped>
.fab-slot svg {
  height: 16px;
  fill: currentColor;
}

.variant-primary:focus-visible,
.variant-secondary:focus-visible,
.variant-warning:focus-visible,
.variant-slate:focus-visible,
.variant-ghost:focus-visible,
.variant-outline:focus-visible {
  position: relative;
}
.variant-primary:focus-visible::after,
.variant-secondary:focus-visible::after,
.variant-warning:focus-visible::after,
.variant-slate:focus-visible::after,
.variant-ghost:focus-visible::after,
.variant-outline:focus-visible::after {
  content: '';
  position: absolute;
  border: 1px solid var(--token-color-white);
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: var(--token-border-radius-full);
}

.variant-secondary:focus-visible::after,
.variant-outline:focus-visible::after {
  border-color: var(--token-color-primary);
}
</style>
