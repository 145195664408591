<template>
  <div class="layout-container">
    <div
      class="layout-sidebar"
      @click="hideActivePanel"
      :class="sidebarClasses"
      v-if="!shouldHideSidebar"
    >
      <slot name="sidebar">sidebar</slot>
      <ExpandCollapseButton />
    </div>
    <div
      class="h-screen top-0 bottom-0"
      :class="{
        sticky: !!sidebarPinnedPanelKey.name,
      }"
    >
      <slot name="sidebar-pinned-panels"></slot>
    </div>
    <div class="flex-grow overflow-x-clipped">
      <slot name="content"></slot>
    </div>

    <slot name="layout-popovers" multiple />
  </div>
</template>

<script>
import { computed } from 'vue-demi';
import ExpandCollapseButton from '@/platform/components/side-nav/ExpandCollapseButton.vue';
import { usePreferences } from '@/platform/composables/usePreferences';
import { useSidebarActiveRoute } from '@/platform/composables/useSidebarActiveRoute';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';
import { useLightspeedBridge } from '@/platform/composables/useLightspeedBridge';
import { useFullContentArea } from '@/platform/composables/useFullContentArea';

export default {
  components: {
    ExpandCollapseButton,
  },
  setup() {
    const { shouldMinimizeSidebar } = usePreferences();
    const fullContentAreaEnabled = useFullContentArea();
    const { isSidebarHidden } = useSidebarActiveRoute();
    const { hideActivePanel, sidebarPinnedPanelKey } = useSidebarPanels();
    const { isInLightspeed } = useLightspeedBridge();
    const sidebarClasses = computed(() => {
      return shouldMinimizeSidebar.value ? ['sidebar-minimized'] : [];
    });
    const shouldHideSidebar = computed(
      () =>
        fullContentAreaEnabled.value ||
        isSidebarHidden.value ||
        isInLightspeed.value,
    );

    return {
      sidebarClasses,
      hideActivePanel,
      isSidebarHidden,
      sidebarPinnedPanelKey,
      shouldHideSidebar,
    };
  },
};
</script>
<style scoped>
.layout-container {
  display: flex;
  --navigation-width--base: 208px;
  --navigation-width--minimized: 84px;
  --navigation-background: #0b0e1f;
}

.overflow-x-clipped {
  overflow-x: clip;
  width: 80%;
}

.layout-sidebar {
  color: #fff;
  background-color: var(--navigation-background);
  width: var(--navigation-width--base);
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  flex-shrink: 0;
}

.sidebar-minimized {
  width: var(--navigation-width--minimized);
}
</style>
