<template>
  <div class="h-[calc(100%-86px)] px-6 flex flex-col">
    <h1 class="text-md font-semibold leading-normal mb-6">
      {{ $t('Keyboard shortcuts') }}
    </h1>
    <FTabs
      v-model="activeTab"
      data-identifier-prefix="shortcuts-panel"
      :tabs="tabs"
    />
    <div class="grow overflow-y-auto min-h-full -mx-6">
      <div class="px-6 pt-6">
        <template v-if="activeTab === 'general'">
          <KeyboardShortcutList
            :title="$t('Quick add')"
            :shortcuts="shortcuts.quickAdd"
          />
          <KeyboardShortcutList
            :title="$t('Within a project')"
            :shortcuts="shortcuts.withinAProject"
          />
          <KeyboardShortcutList
            :title="$t('Global')"
            :shortcuts="shortcuts.global"
          />
        </template>
        <template v-if="activeTab === 'tasks'">
          <KeyboardShortcutList
            :shortcuts="shortcuts.whenMouseIsOverATask"
            :title="$t('When mouse is over a task')"
          />
          <KeyboardShortcutList
            :shortcuts="shortcuts.views"
            :title="$t('Views')"
          />
        </template>
        <template v-if="activeTab === 'board-view'">
          <KeyboardShortcutList
            :shortcuts="shortcuts.navigation"
            :title="$t('Navigation')"
          />
          <KeyboardShortcutList
            :shortcuts="shortcuts.withAColumnSelected"
            :title="$t('With a column selected')"
          />
          <KeyboardShortcutList
            :shortcuts="shortcuts.withACardSelected"
            :title="$t('With a card selected')"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { FTabs } from '@fragments';
import { ref, Vue2 as Vue } from 'vue-demi';
import KeyboardShortcutList from '@/platform/components/side-nav/panels/help-center/common/KeyboardShortcutList';

export default {
  name: 'HelpCenterKeyboardShortcutsState',
  components: { KeyboardShortcutList, FTabs },
  setup() {
    const activeTab = ref('general');

    const tabs = Object.freeze([
      {
        text: Vue.t('General'),
        value: 'general',
      },
      {
        text: Vue.t('Tasks'),
        value: 'tasks',
      },
      {
        text: Vue.t('Board View'),
        value: 'board-view',
      },
    ]);

    const shortcuts = {
      quickAdd: [
        {
          name: Vue.t('Open quick add menu'),
          keys: ['[Q]'],
        },
        {
          name: Vue.t('Add project'),
          keys: ['[Q]', '[P]'],
        },
        {
          name: Vue.t('Add milestone'),
          keys: ['[Q]', '[M]'],
        },
        {
          name: Vue.t('Add task'),
          keys: ['[Q]', '[T]'],
        },
        {
          name: Vue.t('Add message'),
          keys: ['[Q]', '[N]'],
        },
        {
          name: Vue.t('Add event'),
          keys: ['[Q]', '[E]'],
        },
        {
          name: Vue.t('Start timer'),
          keys: ['[Q]', '[S]'],
        },
        {
          name: Vue.t('Log time'),
          keys: ['[Q]', '[L]'],
        },
      ],
      withinAProject: [
        {
          name: Vue.t('Open gantt chart'),
          keys: ['[G]'],
        },
        {
          name: Vue.t('Choose project section'),
          keys: ['[0-9]'],
        },
      ],
      global: [
        {
          name: Vue.t('Switch project'),
          keys: ['[S]'],
        },
        {
          name: Vue.t('Open search'),
          keys: ['[F]'],
        },
        {
          name: Vue.t('My shortcuts'),
          keys: ['[Shift]', '+', '[S]'],
        },
      ],
      whenMouseIsOverATask: [
        {
          name: Vue.t('Edit task'),
          keys: ['[E]'],
        },
        {
          name: Vue.t('Set task to me'),
          keys: ['[J]'],
        },
        {
          name: Vue.t('Set task to anyone'),
          keys: ['[A]'],
        },
        {
          name: Vue.t('Add me to task'),
          keys: ['[+]'],
        },
        {
          name: Vue.t('Remove me from task'),
          keys: ['[-]'],
        },
        {
          name: Vue.t('Complete task'),
          keys: ['[C]'],
        },
        {
          name: Vue.t('Add files to task'),
          keys: ['[F]'],
        },
        {
          name: Vue.t('Duplicate task'),
          keys: ['[D]'],
        },
        {
          name: Vue.t('Move task'),
          keys: ['[M]'],
        },
        {
          name: Vue.t('Add comment'),
          keys: ['[M]'],
        },
        {
          name: Vue.t('Log time'),
          keys: ['[T]'],
        },
        {
          name: Vue.t('Start timer'),
          keys: ['[S]'],
        },
        {
          name: Vue.t('Open quick view'),
          keys: ['[V]'],
        },
        {
          name: Vue.t('Move task to the next level down'),
          keys: ['[Tab]'],
        },
        {
          name: Vue.t('Move task to the next level up'),
          keys: ['[Shift]', '+', '[Tab]'],
        },
      ],
      views: [
        {
          name: Vue.t('Switch to board view'),
          keys: ['[B]'],
        },
        {
          name: Vue.t('Switch to list view'),
          keys: ['[L]'],
        },
      ],
      navigation: [
        {
          name: Vue.t('Navigate columns'),
          keys: ['[←]', '/', '[→]'],
        },
        {
          name: Vue.t('Navigate cards'),
          keys: ['[↑]', '/', '[↓]'],
        },
        {
          name: Vue.t('Select multiple cards'),
          keys: ['[Shift]', '+', '[↑]', '/', '[↓]'],
        },
      ],
      withAColumnSelected: [
        {
          name: Vue.t('Edit column'),
          keys: ['[E]'],
        },
        {
          name: Vue.t('Filter cards'),
          keys: ['[F]'],
        },
      ],
      withACardSelected: [
        {
          name: Vue.t('Edit task'),
          keys: ['[E]'],
        },
        {
          name: Vue.t('Set task to me'),
          keys: ['[J]'],
        },
        {
          name: Vue.t('Set task to anyone'),
          keys: ['[A]'],
        },
        {
          name: Vue.t('Add me to task'),
          keys: ['[+]'],
        },
        {
          name: Vue.t('Remove me from task'),
          keys: ['[-]'],
        },
        {
          name: Vue.t('Complete task'),
          keys: ['[C]'],
        },
        {
          name: Vue.t('Duplicate task'),
          keys: ['[D]'],
        },
        {
          name: Vue.t('Add comment'),
          keys: ['[M]'],
        },
        {
          name: Vue.t('Log time'),
          keys: ['[T]'],
        },
        {
          name: Vue.t('Start timer'),
          keys: ['[S]'],
        },
        {
          name: Vue.t('Open quick view'),
          keys: ['[V]'],
        },
        {
          name: Vue.t('Move task to the next level down'),
          keys: ['[Tab]'],
        },
        {
          name: Vue.t('Move task to the next level up'),
          keys: ['[Shift]', '+', '[Tab]'],
        },
      ],
    };

    return {
      tabs,
      activeTab,
      shortcuts,
    };
  },
};
</script>
