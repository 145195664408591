<template>
  <SectionBlock :title="$t('Subtasks')" :small="true">
    <template v-if="hasActiveSubtasks">
      <SubtasksCount slot="icon" :count="activeSubtasksCount" />
      <ButtonText
        slot="accessory"
        @click="isSubtasksVisible = !isSubtasksVisible"
      >
        <span>{{ toggleButtontext }}</span>
        <CommonIcons color="#4461D7" :id="toggleButtonChevron" />
      </ButtonText>
    </template>
    <CollapsePanel :is-open="!hasSubtasks || isSubtasksVisible">
      <div class="subtasks">
        <SubtasksItems
          v-if="hasSubtasks"
          :task="task"
          :subtask-ids="task.subTaskIds"
        />
        <SubtasksNew v-if="shouldShowNew" @change="addNewSubtask" />
      </div>
    </CollapsePanel>
    <ButtonText
      ref="showAddNewSubtaskButton"
      v-if="task.userPermissions.canAddSubtasks"
      underline
      @click="showAddNewSubtask"
    >
      + {{ $t('Add subtask') }}
    </ButtonText>
  </SectionBlock>
</template>

<script>
import moment from 'moment';
import { defineComponent, computed, Vue2 as Vue } from 'vue-demi';
import { useLocalStorage } from '@vueuse/core';
import { useTaskActions } from '@teamwork/use';
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import CollapsePanel from '@widgets/CollapsePanel';

import SectionBlock from '../SectionBlock';
import ButtonText from '../ButtonText';
import SubtasksItems from '../SubtasksItems';
import SubtasksNew from '../SubtasksNew';
import SubtasksCount from '../SubtasksCount';

export default defineComponent({
  name: 'Subtasks',
  components: {
    CollapsePanel,
    ButtonText,
    SectionBlock,
    SubtasksItems,
    SubtasksNew,
    SubtasksCount,
    CommonIcons,
  },
  props: {
    task: {
      type: Object,
      default: () => null,
    },
    openNewSubtask: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showAddNewSubtask() {
      this.shouldShowNew = true;
      if (!this.isSubtasksVisible) {
        this.isSubtasksVisible = true;
        setTimeout(() => {
          this.$refs.showAddNewSubtaskButton.$el.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }, 500);
      }
    },
  },
  setup(props, { emit }) {
    const { createTask } = useTaskActions();

    const isSubtasksVisible = useLocalStorage(
      'task-detail/show-subtasks',
      true,
    );
    const subtasksCount = computed(() => {
      if (!props.task || (props.task && !props.task.subtaskStats)) {
        return 0;
      }

      const { subtaskStats } = props.task;
      return subtaskStats.active + subtaskStats.complete;
    });

    const hasSubtasks = computed(() => subtasksCount.value > 0);

    const activeSubtasksCount = computed(() =>
      subtasksCount.value > 0 ? props.task.subtaskStats.active : 0,
    );
    const hasActiveSubtasks = computed(() => activeSubtasksCount.value > 0);

    const shouldShowNew = computed({
      get() {
        return props.openNewSubtask;
      },
      set(newVal) {
        emit('update:openNewSubtask', newVal);
      },
    });

    const toggleButtontext = computed(() =>
      isSubtasksVisible.value ? Vue.t('Hide') : Vue.t('Show'),
    );
    const toggleButtonChevron = computed(() =>
      isSubtasksVisible.value ? 'angle-up' : 'angle-down',
    );

    function addNewSubtask(name) {
      if (name) {
        createTask({
          name,
          parentTaskId: props.task.id,
          taskListId: props.task.taskListId,
          projectId: props.task.projectId,
          priority: props.task.priority || '',
          startDate: props.task.startDate ? moment(props.task.startDate) : null,
          dueDate: props.task.dueDate ? moment(props.task.dueDate) : null,
          assignedTo: props.task.assigneeUsers,
          assignedToCompanies: props.task.assigneeCompanies,
          assignedToTeams: props.task.assigneeTeams,
          changeFollowers: props.task.changeFollowers,
          commentFollowers: props.task.commentFollowers,
        });
      } else {
        shouldShowNew.value = !hasSubtasks;
      }
    }

    return {
      isSubtasksVisible,
      subtasksCount,
      hasSubtasks,
      activeSubtasksCount,
      hasActiveSubtasks,
      shouldShowNew,
      toggleButtontext,
      toggleButtonChevron,
      addNewSubtask,
    };
  },
});
</script>

<style lang="scss" scoped>
.subtasks {
  margin-bottom: 8px;
}
</style>
