import { watch } from 'vue-demi';

// eslint-disable-next-line import/prefer-default-export
export function provideTotango({ account, accountInSync, user, userInSync }) {
  const unwatch = watch(
    [accountInSync, userInSync],
    () => {
      if (!accountInSync.value || !userInSync.value) {
        return;
      }
      unwatch();

      if (
        window.devMode ||
        account.value.isStaging ||
        !account.value.isPaid ||
        !account.value.installationDateFirstPayment ||
        account.value.id === 1 // Skip digitalcrew
      ) {
        return;
      }

      window.totango_options = {
        service_id: 'SP-61752-01',
        module: 'Teamwork (TWA)',
        region: 'eu1',
        user: {
          id: user.value.id,
          Name: `${user.value.firstName} ${user.value.lastName}`,
          'First Name': user.value.firstName,
          'Last Name': user.value.lastName,
          Email: user.value.emailAddress,
          isAdministrator: user.value.administrator,
          isSiteOwner: user.value.siteOwner,
          isClientUser: user.value.isClientUser,
          userType: user.value.userType,
          Contact: 'yes',
          inOwnerCompany: user.value.inOwnerCompany,
        },
        account: {
          id: account.value.id,
          name: `${account.value.name} (${account.value.id})`,
          'Account Type': 'Customer',
        },
      };

      const trackerName = window.totango_options.tracker_name || 'totango';
      window.totango_tmp_stack = [];
      window[trackerName] = {
        go() {
          return -1;
        },
        setAccountAttributes() {},
        identify() {},
        track(t, o, n, a) {
          window.totango_tmp_stack.push({
            activity: t,
            module: o,
            org: n,
            user: a,
          });
          return -1;
        },
      };

      const e = document.createElement('script');
      e.type = 'text/javascript';
      e.async = true;
      e.src = `${
        document.location.protocol === 'https:' ? 'https://' : 'http://'
      }tracker.totango.com/totango4.0.3.js`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(e, s);
    },
    { immediate: true },
  );
}
