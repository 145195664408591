<template>
  <div v-if="isHybridReady">
    <TkoComponent name="section-quick-views" />
    <QuickViewContainer />
  </div>
</template>
<script>
import TkoComponent from '@sections/TkoComponent';
import QuickViewContainer from '@sections/QuickViewContainer';
import { useHybrid } from '@/platform/composables/useHybrid';

export default {
  components: {
    QuickViewContainer,
    TkoComponent,
  },
  setup() {
    const { isHybridReady } = useHybrid();
    return {
      isHybridReady,
    };
  },
};
</script>
