<template>
  <div class="task-item" @mouseover="hover = true" @mouseleave="hover = false">
    <div
      class="task-item__icon"
      :class="{
        'task-item__icon--complete': item.completed,
        'task-item__icon--hover': hover && item.repeatable,
        'task-item__icon--hover--complete':
          hover && item.repeatable && item.completed,
      }"
    >
      <svg v-if="item.completed && item.repeatable && hover">
        <path
          d="M12 5V1L7 6L12 11V7C15.31 7 18 9.69 18 13C18 16.31 15.31 19 12 19C8.69 19 6 16.31 6 13H4C4 17.42 7.58 21 12 21C16.42 21 20 17.42 20 13C20 8.58 16.42 5 12 5Z"
          fill="#4461D7"
        />
      </svg>
      <CommonIcon v-else-if="item.completed" id="check" />
      <div v-else>{{ index + 1 }}</div>
    </div>
    <button
      @click="click"
      class="task-item__text"
      :class="{
        'task-item__text--complete': item.completed,
        'task-item__text--hover': hover && item.repeatable,
        'task-item__text--disabled': item.id < 3,
      }"
    >
      {{ item.description() }}
    </button>
  </div>
</template>

<script>
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import { ref } from 'vue-demi';

export default {
  components: {
    CommonIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
  setup() {
    const hover = ref(false);

    return {
      hover,
    };
  },
};
</script>

<style lang="scss" scoped>
.task-item {
  display: flex;
  list-style: none;
  padding: 13px;
  padding-left: 0;

  &__text {
    color: #0b0e1f;
    text-align: left;
    margin-left: 8px;
    padding: 2px;
    background: transparent;
    border: none;

    &--complete {
      fill: #fff;
      color: #318160;
      transition: 500ms;
    }

    &--hover {
      fill: #fff;
      color: #4461d7;
      transition: 500ms;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__icon {
    font-weight: 600;
    display: flex;
    width: 25px;
    height: 25px;
    background: #e1e6ee;
    border-radius: 50px;
    align-content: center;
    justify-content: center;
    align-items: center;
    fill: #646d7d;

    &--complete {
      background: #4ecd97;
      color: #fff;
      fill: #fff;
      transition: 500ms;
    }

    &--hover {
      background: #4461d7;
      color: #fff;
      fill: #fff;
      transition: 500ms;

      &--complete {
        background: none;
        color: #fff;
        fill: #fff;
        transition: 500ms;
        align-items: unset;
      }
    }
  }
}
</style>
