<template>
  <div class="w-spinner">
    <div class="circle circle-1" />
    <div class="circle circle-2" />
  </div>
</template>
<script>
const defaultSpinnerHeightWidth = 60;

// @vue/component
export default {
  props: {
    size: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const height = (() => {
        if (this.size) {
          return `${this.size}px`;
        }

        if (
          this.$parent &&
          this.$parent.$el.clientHeight <= defaultSpinnerHeightWidth
        ) {
          return `${this.$parent.$el.clientHeight / 2}px`;
        }

        return window.getComputedStyle(this.$el).getPropertyValue('height');
      })();

      if (!height) {
        return;
      }

      this.$el.style.width = height;
      this.$el.style.height = height;

      const circles = this.$el.getElementsByClassName('circle');

      for (let i = 0; i < circles.length; i += 1) {
        circles[i].style.borderWidth = `calc(${height} / 10)`;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
$size: 60px;

.w-spinner {
  width: $size;
  height: $size;
  border-radius: 100%;
  position: relative;

  .circle {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-style: solid;
    border-color: transparent;
  }

  .circle-1 {
    border-top-color: $tw-slate;
    animation: w-spinner-animation 1s infinite;
  }

  .circle-2 {
    border-bottom-color: $tw-slate;
    animation: w-spinner-animation 1s infinite alternate;
  }
}

@keyframes w-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
