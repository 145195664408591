<template>
  <SidebarPanel
    @close="onClose"
    class="h-screen font-sans top-0 bottom-0 z-10 w-[312px]"
    data-identifier="side-nav-jump-to-panel"
    :class="{
      relative: !!sidebarPinnedPanelKey.name,
      fixed: !sidebarPinnedPanelKey.name,
      'left-[208px]': !sidebarPinnedPanelKey.name && !shouldMinimizeSidebar,
      'left-[84px]': !sidebarPinnedPanelKey.name && shouldMinimizeSidebar,
    }"
    :title="$t('Jump to')"
  >
    <template #panel-header-actions>
      <ProjectsPanelHeaderAddProject @close="tryhideProjectsPanel" />
      <ProjectsPanelPinUnPin />
    </template>
    <template #default>
      <div class="flex flex-col w-full h-full overflow-hidden">
        <div class="px-6 mt-0 mb-6">
          <SearchInput v-model="searchTerm" />
        </div>

        <ProjectsPanelSearchResultsTitle
          v-if="isSearching && allProjectsTotalCount > 0"
        />
        <FTabs
          v-model="activeTab"
          class="mx-6 mb-6"
          data-identifier-prefix="projects-panel"
          v-if="!isSearching"
          :tabs="tabs"
        />

        <RecentProjectsLoaderState
          v-if="visitedTabs.recent"
          v-show="!isSearching && activeTab === 'recent'"
          :is-active="activeTab === 'recent'"
        />

        <StarredProjectsLoaderState
          v-if="visitedTabs.starred"
          v-show="!isSearching && activeTab === 'starred'"
          :is-active="activeTab === 'starred'"
        />

        <AllProjectsLoaderState
          v-if="visitedTabs.all"
          v-show="isSearching || activeTab === 'all'"
          :is-active="isSearching || activeTab === 'all'"
          :search-term="searchTerm"
          @update:total-count="allProjectsTotalCount = $event"
          @reset-search="onResetSearch"
        />
      </div>
    </template>
    <template #footer>
      <ProjectsPanelProjectsActions v-if="!isSearching" />
    </template>
  </SidebarPanel>
</template>

<script>
import { ref, computed, shallowRef, watch } from 'vue-demi';
import { FTabs } from '@fragments';
import SidebarPanel from '@/platform/components/side-nav/panels/SidebarPanel.vue';
import ProjectsPanelProjectsActions from './ProjectsPanelProjectsActions.vue';
import ProjectsPanelSearchResultsTitle from './ProjectsPanelSearchResultsTitle.vue';
import SearchInput from '@/platform/components/side-nav/SearchInput.vue';
import ProjectsPanelHeaderAddProject from './ProjectsPanelHeaderAddProject.vue';
import ProjectsPanelPinUnPin from './ProjectsPanelPinUnPin.vue';
import { usePreferences } from '@/platform/composables/usePreferences';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';
import AllProjectsLoaderState from './AllProjectsLoaderState.vue';
import StarredProjectsLoaderState from './StarredProjectsLoaderState.vue';
import RecentProjectsLoaderState from './RecentProjectsLoaderState.vue';

export default {
  name: 'ProjectsPanel',
  components: {
    FTabs,
    SidebarPanel,
    ProjectsPanelSearchResultsTitle,
    SearchInput,
    ProjectsPanelHeaderAddProject,
    ProjectsPanelPinUnPin,
    ProjectsPanelProjectsActions,
    AllProjectsLoaderState,
    RecentProjectsLoaderState,
    StarredProjectsLoaderState,
  },
  setup() {
    const { projectsPanelActiveTab: activeTab, shouldMinimizeSidebar } =
      usePreferences();

    if (!activeTab.value) {
      activeTab.value = 'recent';
    }

    const visitedTabs = ref({
      recent: false,
      starred: false,
      all: false,
    });

    const tabs = Object.freeze([
      {
        text: 'Recent',
        value: 'recent',
      },
      {
        text: 'Starred',
        value: 'starred',
      },
      {
        text: 'All',
        value: 'all',
      },
    ]);

    const allProjectsTotalCount = shallowRef(0);
    const searchTerm = ref(null);

    const { hideProjectsPanel, sidebarPinnedPanelKey, tryhideProjectsPanel } =
      useSidebarPanels();

    const onClose = () => {
      hideProjectsPanel();
    };

    const isSearching = computed(() => {
      return !!searchTerm.value;
    });

    const onResetSearch = () => {
      searchTerm.value = '';
    };

    watch(isSearching, (flag) => {
      if (flag && !visitedTabs.value.all) {
        visitedTabs.value.all = true;
      }
    });

    watch(
      activeTab,
      (tab) => {
        if (!visitedTabs.value[tab]) {
          visitedTabs.value[tab] = true;
        }
      },
      {
        immediate: true,
      },
    );

    return {
      activeTab,
      tryhideProjectsPanel,
      isSearching,
      allProjectsTotalCount,
      searchTerm,
      onClose,
      onResetSearch,
      shouldMinimizeSidebar,
      sidebarPinnedPanelKey,
      visitedTabs,
      tabs,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '~@tko/src/styles/mixins/mixins';
@import '~@tko/src/styles/variables/variables';

.recent-project-list--scroll-container {
  @include scrollbar();
  // max-height: calc(100vh - 220px);
  overflow: auto;
}

::v-deep .LoadOnScroll {
  height: 100%;
}

::v-deep .LoaderState:first-child {
  height: 100%;
}
</style>
