<template>
  <div class="flex w-full h-full relative select-none">
    <transition name="fade">
      <template v-if="shouldShowEmptyState">
        <div class="flex-1 min-h-0 pb-16 overflow-y-auto scrollbar">
          <SearchBlankState
            class="min-h-[355px] h-full p-16"
            :search-term="searchTerm"
            @advanced-search="onAdvancedSearchClicked"
            identifier-prefix="app-nav__search-panel__results"
          />
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col w-full h-full">
          <div class="flex-none flex items-center mb-3 px-6">
            <!-- icon-search -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 1.19 18 15.61"
              width="16"
              height="16"
              class="text-text-tertiary fill-current mr-3"
            >
              <path
                d="m17.926 16.04-2.779-2.709c.528-.661.855-1.482.855-2.383 0-.771-.236-1.526-.674-2.167-.439-.642-1.066-1.141-1.796-1.437-.731-.294-1.534-.373-2.312-.221-.775.15-1.487.52-2.046 1.066-.56.545-.942 1.241-1.095 1.998-.154.756-.075 1.541.227 2.255.303.712.815 1.322 1.472 1.751.659.427 1.433.655 2.223.655.925 0 1.764-.317 2.442-.829l2.776 2.709c.025.023.053.04.082.053.029.014.064.02.097.02.032 0 .063-.006.094-.02.032-.013.058-.03.082-.053l.354-.344c.045-.046.072-.108.072-.173-.002-.063-.027-.126-.074-.171Zm-5.925-2.166c-.594 0-1.174-.171-1.668-.494-.492-.321-.877-.776-1.104-1.313-.226-.533-.287-1.122-.171-1.691.116-.566.401-1.087.822-1.496.419-.409.954-.688 1.536-.801.582-.113 1.186-.055 1.732.166.549.221 1.018.597 1.346 1.077.331.482.506 1.047.506 1.626 0 .776-.317 1.52-.878 2.067-.563.549-1.326.859-2.121.859Zm-1.503 1.952H1.5c-.132 0-.26-.053-.353-.144-.095-.093-.147-.216-.147-.345V2.661c0-.128.052-.253.147-.346.093-.091.221-.141.353-.141h5.499v3.169c0 .096.019.191.056.281.039.088.094.169.162.236.072.069.153.123.246.159.09.037.189.057.287.055l4.249-.002v-.854c-.001-.387-.158-.761-.44-1.036L8.936 1.624c-.28-.274-.661-.427-1.059-.429H1.5c-.399.002-.78.156-1.06.431C.159 1.9.002 2.272 0 2.661v12.676c0 .388.157.76.44 1.034.28.274.662.43 1.06.43h8.998c.312-.002.614-.096.868-.274.253-.175.444-.424.545-.711-.357-.007-.713-.052-1.06-.134-.094.091-.22.144-.353.144ZM8 2.188c.089.022.167.066.231.129l2.622 2.557c.063.062.109.138.131.224H8v-2.91Z"
              />
            </svg>
            <h4 class="flex-1 text-default font-bold m-0 text-text">
              {{ $t('Search results') }}
            </h4>
          </div>
          <ul
            class="list-none text-xs p-0 m-0 px-3"
            :class="[
              ...(!shouldShowLoadingState
                ? ['overflow-y-scroll', 'scrollbar']
                : ['overflow-hidden']),
            ]"
          >
            <template v-if="shouldShowLoadingState">
              <SkeletonListItem
                v-for="index in skeletonCount"
                :key="index"
                :line1-right="false"
              />
            </template>
            <template v-else>
              <template v-for="item in items">
                <ListItem
                  :id="`${item.type}-${item.id}`"
                  :key="`${item.type}-${item.id}`"
                  :type="item.type"
                  :link="item.url"
                  :title="item.title"
                  :strikethrough="item.completed"
                  identifier-prefix="app-nav__search-panel__results"
                  @click="$emit('item-clicked', item, $event)"
                >
                  <template #icon>
                    <img
                      v-if="item.extra && typeof item.extra === 'string'"
                      class="w-full h-full"
                      :src="item.extra"
                      v-img-load-fail="'default'"
                    />
                    <TeamLogo
                      v-else-if="item.type === 'team'"
                      :team="{
                        name: item.extra.teamName,
                        logoColor: item.extra.teamLogoColor,
                        logoUrl: item.extra.teamLogo,
                        logoIcon: item.extra.teamLogoIcon,
                      }"
                      size="list-larger"
                      class="w-full h-full"
                    />
                  </template>
                  <template #line-1-left>
                    {{ item.details }}
                  </template>
                  <template #line-2>
                    {{ item.title }}
                  </template>
                </ListItem>
              </template>
            </template>
          </ul>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import { computed } from 'vue-demi';

import TeamLogo from '@widgets/TeamLogo';

import ListItem from '@/platform/components/side-nav/panels/search/common/ListItem.vue';
import SearchBlankState from '@/platform/components/common/SearchBlankState.vue';
import SkeletonListItem from '@/platform/components/side-nav/panels/search/common/SkeletonListItem.vue';

export default {
  name: 'SearchResults',
  components: {
    ListItem,
    SearchBlankState,
    SkeletonListItem,
    TeamLogo,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    searchTerm: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const skeletonCount = computed(() => 20);

    const shouldShowLoadingState = computed(() => {
      return !!props.loading;
    });

    const shouldShowEmptyState = computed(() => {
      return !shouldShowLoadingState.value && !props.items.length;
    });

    const onAdvancedSearchClicked = () => {
      emit('advanced-search');
    };

    return {
      skeletonCount,
      shouldShowLoadingState,
      shouldShowEmptyState,
      onAdvancedSearchClicked,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@tko/src/styles/mixins/mixins';

.scrollbar {
  @include scrollbar();
}

.fade-enter-active,
.fade-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
