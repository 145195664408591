import moment from 'moment';

export function getToday() {
  return moment().startOf('day');
}

/**
 *
 * @param {moment.Moment|undefined} date1
 * @param {moment.Moment|undefined} date2
 * @returns {Boolean}
 */
export function isSameDay(date1, date2) {
  return (
    (date1 && date2 && date1.isSame(date2, 'day')) ||
    (date1 == null && date2 == null)
  );
}

/**
 *
 * @param {moment.Moment|undefined} date1
 * @param {moment.Moment|undefined} date2
 * @returns {Boolean}
 */
export function isSameMonth(date1, date2) {
  return (
    (date1 && date2 && date1.isSame(date2, 'month')) ||
    (date1 == null && date2 == null)
  );
}
