var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-cell-time",on:{"mouseenter":function($event){_vm.isHovering = true},"mouseleave":function($event){_vm.isHovering = false}}},[(
      _vm.minutes === 0 &&
      !_vm.isHovering &&
      !_vm.timerActive &&
      _vm.elapsedTime === 0 &&
      !_vm.projectsWorkerBeeLogTimeCtaEnabled
    )?_c('div',{staticClass:"empty-button"},[_c('SupersetIcon',{staticClass:"empty-state-icon",attrs:{"id":"minus-solid","color":"#c5cee0"}})],1):_vm._e(),_vm._v(" "),(_vm.timerActive || (!_vm.timerActive && _vm.elapsedTime > 0))?_c('div',{staticClass:"container"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content:
          _vm.timerState === 'pause' ? _vm.$t('Pause timer') : _vm.$t('Start timer'),
        placement: 'top',
      }),expression:"{\n        content:\n          timerState === 'pause' ? $t('Pause timer') : $t('Start timer'),\n        placement: 'top',\n      }"}]},[_c('button',{staticClass:"btn btn-timer-toggle",class:{ 'btn-pause': _vm.timerState === 'pause' },on:{"click":_vm.timerToggle}},[(_vm.timerState === 'pause')?_c('SupersetIcon',{attrs:{"id":"timer-pause-solid","color":"#fff"}}):_c('SupersetIcon',{attrs:{"id":"timer-start-solid","color":"#fff"}})],1)]),_vm._v(" "),_c('button',{staticClass:"ml--medium btn btn-link",on:{"click":function($event){return _vm.onAddOrEdit()}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.minutes === 0 ? _vm.$t('Log time') : _vm.$t('Log more time'),
          placement: 'top',
        }),expression:"{\n          content: minutes === 0 ? $t('Log time') : $t('Log more time'),\n          placement: 'top',\n        }"}]},[_vm._v("\n        "+_vm._s(_vm.formattedElapsedTime)+"\n      ")])])]):_c('div',[(_vm.isHovering)?_c('div',{staticClass:"container w-14 !h-6",class:[
        'empty-button',
        { 'empty-button-logged-time': _vm.elapsedTime === 0 } ]},[_c('LogTimeButton',{attrs:{"button-classes":['direction-left', 'is-table-view'],"icon":"clock","tooltip":_vm.minutes === 0 ? _vm.$t('Log time') : _vm.$t('Log more time'),"data-identifier":"table-view-cell-log-time"},nativeOn:{"click":function($event){return _vm.onAddOrEdit(true)}}}),_vm._v(" "),_c('LogTimeButton',{attrs:{"button-classes":['direction-right', 'is-table-view'],"icon":_vm.buttonProps.icon,"tooltip":_vm.$t('Start timer'),"data-identifier":"table-view-cell-start-timer","disabled":_vm.timerBtnDisabled},nativeOn:{"click":function($event){return _vm.timerToggle.apply(null, arguments)}}})],1):[(_vm.minutes > 0)?[(_vm.task.userPermissions.canLogTime)?_c('div',[_c('button',{staticClass:"btn btn-reset",on:{"click":function($event){return _vm.onAddOrEdit()}}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.$t('Log More Time'),
                placement: 'top',
              }),expression:"{\n                content: $t('Log More Time'),\n                placement: 'top',\n              }"}]},[_vm._v("\n              "+_vm._s(_vm.formattedTime)+"\n            ")])])]):_c('span',[_vm._v("\n          "+_vm._s(_vm.formattedTime)+"\n        ")])]:(_vm.projectsWorkerBeeLogTimeCtaEnabled)?_c('div',{staticClass:"container w-14 !h-6",class:[
          'empty-button',
          { 'empty-button-logged-time': _vm.elapsedTime === 0 } ]},[_c('LogTimeButton',{attrs:{"button-classes":['direction-left', 'is-table-view'],"icon":"clock","tooltip":_vm.minutes === 0 ? _vm.$t('Log time') : _vm.$t('Log more time'),"data-identifier":"table-view-cell-log-time"},nativeOn:{"click":function($event){return _vm.onAddOrEdit(true)}}}),_vm._v(" "),_c('LogTimeButton',{attrs:{"button-classes":['direction-right', 'is-table-view'],"icon":_vm.buttonProps.icon,"tooltip":_vm.$t('Start timer'),"data-identifier":"table-view-cell-start-timer","disabled":_vm.timerBtnDisabled},nativeOn:{"click":function($event){return _vm.timerToggle.apply(null, arguments)}}})],1):_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }