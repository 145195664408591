export const avatarSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
};
export const defaultAvatarSize = avatarSizes.lg;

export const avatarVariants = {
  avatar: 'avatar',
  initials: 'initials',
  icon: 'icon',
};
export const defaultAvatarVariant = avatarVariants.avatar;
