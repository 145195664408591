<template>
  <div
    class="flex"
    :class="{
      'flex-col gap-4': !inline,
      'flex-row items-start gap-8 flex-wrap': inline,
    }"
    role="radiogroup"
    :aria-labelledby="`group_label_${name}`"
  >
    <slot />
  </div>
</template>
<script>
import { toRefs } from 'vue-demi';
import { provideRadioGroup } from './useRadioGroup';

export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  name: 'FRadioGroup',
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { name, modelValue } = toRefs(props);
    provideRadioGroup({
      groupName: name,
      groupValue: modelValue,
      groupOnChange: (value) => {
        emit('change', value);
      },
    });
  },
};
</script>
