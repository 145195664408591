var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TableViewSection"},[_c('div',{ref:"topElement",style:({
      position: 'relative',
      top: ("-" + _vm.headerHeight + "px"),
    })}),_vm._v(" "),_c('div',{class:{
      TableViewSection__header: true,
      'TableViewSection__header--shadow': _vm.showHeaderShadow,
    },style:({
      top: (_vm.headerHeight + "px"),
      width: (_vm.tableWidth + "px"),
    })},[_c('div',{class:{
        TableViewSection__toggle: true,
        'TableViewSection__toggle--open': _vm.open,
      },on:{"click":_vm.toggle}},[_c('CommonIcon',{attrs:{"id":"chevron-down-solid","color":"#969bb1"}})],1),_vm._v(" "),_vm._t("header")],2),_vm._v(" "),(_vm.open)?_c('div',{staticClass:"TableViewSection__body"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }