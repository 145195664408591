<template>
  <div>
    <span class="picker-label">{{ $t('Search results') }}</span>
    <div v-for="task of tasks" :key="task.id">
      <DependencyPickerItem
        :selected="excludeTaskIds.length && excludeTaskIds.includes(task.id)"
        :task="task"
        @add-new-predecessor="addNewPredecessor"
      />
    </div>
  </div>
</template>
<script>
import DependencyPickerItem from './DependencyPickerItem';

export default {
  name: 'DependenciesSearchItems',
  components: {
    DependencyPickerItem,
  },
  props: {
    tasks: { type: Array, default: () => [] },
    searchTerm: { type: String, default: '' },
    excludeTaskIds: { type: Array, default: () => [] },
  },
  methods: {
    addNewPredecessor(predecessor) {
      this.$emit('add-new-predecessor', predecessor);
    },
  },
};
</script>
<style lang="scss" scoped>
$neutral-60: #0b0e1f;
.picker-label {
  color: $neutral-60;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  margin-left: 12px;
}
</style>
