<template>
  <div class="table-cell-task-name-edit">
    <a href="javascript:;" @click="editTaskName">
      <span>{{ $t('Edit') }}</span>
    </a>
  </div>
</template>

<script>
// @vue/component
export default {
  name: 'EditTaskName',
  display: 'EditTaskName',
  methods: {
    editTaskName() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell-task-name-edit {
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 12px;
  a {
    cursor: pointer;
    color: #6d6f80;
    font-size: 13px;
    font-weight: 400;
    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
