<template>
  <div class="task-name">
    <textarea
      :placeholder="placeholder"
      v-model="taskName"
      v-autosize
      @keydown.stop="handleValueChange"
      @blur="handleBlur"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import autosize from '@/utils/directives/autosize';

export default {
  name: 'TaskName',
  display: 'TaskName',
  directives: { autosize },
  props: {
    value: { type: String, required: true, default: '' },
    placeholder: { type: String, required: false, default: '' },
    disabled: { type: Boolean, required: false },
  },
  data() {
    return {
      taskName: this.value,
    };
  },
  methods: {
    save(value) {
      if (this.value === value) {
        return;
      }
      this.$emit('change', value);
    },
    handleValueChange(event) {
      if (event.keyCode === 13) {
        event.target.blur();
      } else if (event.keyCode === 27) {
        this.taskName = this.value;
      }
    },
    handleBlur() {
      this.save(this.taskName);
      this.$emit('blur');
    },
  },
  watch: {
    value(value) {
      this.taskName = value;
    },
  },
};
</script>

<style lang="scss" scoped>
$hover-bg: #f5f7fa;
$focus-outline: #4461d7;
$text-color: #0b0e1f;

.task-name {
  flex-grow: 2;

  textarea {
    font-size: 20px;
    color: $text-color;
    border: none;
    outline: none;
    padding: 8px;
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    resize: none;

    &:hover,
    &:focus {
      background-color: $hover-bg;
    }

    &:focus {
      box-shadow: 0 0 0 1px $focus-outline;
    }

    &::placeholder {
      font-weight: 400;
    }
  }
}
</style>
