import finance from './finance';
import list from './list';
import notebooks from './notebooks';
import overview from './overview';
import tasks from './tasks';
import templates from './templates';

export default [
  ...finance,
  ...list,
  ...notebooks,
  ...overview,
  ...tasks,
  ...templates,
];
