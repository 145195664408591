import moment from 'moment';
import flatten from 'lodash/flatten';
import { getDatesInRange } from '@/utils/helpers/date';

const placeholderResources = () =>
  [...new Array(8)].map((item, index) => ({
    name: `${index + 1}`,
    isPlaceholder: true,
    children: [],
  }));

export default {
  dates({ startDate, endDate }) {
    return { startDate, endDate };
  },

  projectResources(state, { projects }, rootState, rootGetters) {
    const ids = [...new Set(projects.map((p) => p.id))];
    const isFilteredLoading =
      rootGetters['schedule/projects/filtered/isWorking'];
    const isLoaderLoading = !rootGetters['schedule/projects/isReady'];

    const resources = ids.map((id) => {
      const project = projects.find((p) => p.id === id);
      const { userIds } = project;

      return {
        ...project,
        children: userIds.map((userId) => ({
          resourceRef: JSON.stringify({
            userId: parseInt(userId, 10),
            projectId: parseInt(id, 10),
          }),
          ...rootState.people.records[userId],
        })),
      };
    });

    if (isFilteredLoading || isLoaderLoading) {
      resources.push(...placeholderResources());
    }

    return resources;
  },

  peopleResources(state, { people }, rootState, rootGetters) {
    const ids = [...new Set(people.map((p) => p.id))];
    const isFilteredLoading = rootGetters['schedule/people/filtered/isWorking'];
    const isLoaderLoading = !rootGetters['schedule/people/isReady'];
    const allocations = rootGetters['allocations/scheduled'];

    const resources = ids.map((id) => {
      const person = people.find((p) => p.id === id);
      const personAllocations = allocations.filter(
        (allocation) => allocation.assignedUserID === person.id,
      );
      const projectIds = person.projectIds.filter((pId) =>
        personAllocations.find((allocation) => allocation.projectId === pId),
      );

      const unavailableRow = {
        resourceRef: `unavailable-${id}`,
        name: 'Unavailable',
        isUnavailableTime: true,
      };

      return {
        ...person,
        children: [
          unavailableRow,
          ...projectIds
            .map((projectId) => {
              const project = rootState.project.records[projectId] || undefined;
              let companyName = '';

              if (project) {
                const company = rootState.company.records[project.company.id];
                companyName = company ? company.name : '';
              }

              return {
                resourceRef: JSON.stringify({
                  userId: parseInt(id, 10),
                  projectId: parseInt(projectId, 10),
                }),
                ...project,
                companyName,
              };
            })
            .filter((child) => child.name),
        ],
      };
    });

    if (isFilteredLoading || isLoaderLoading) {
      resources.push(...placeholderResources());
    }

    return resources;
  },

  unavailableTime({ people }) {
    const unavailableEvents = Object.values(people.records).map((item) =>
      Object.keys(item.schedule)
        .map((date) => ({
          ...item.schedule[date],
          resourceId: `unavailable-${item.userId}`,
          userId: item.userId,
          startedAt: moment.tz(date, moment.tz.guess()).toDate(),
          duration: 1,
          isUnavailableTime: true,
          resizable: false,
          draggable: false,
        }))
        .filter((u) => u.unavailableMinutes !== 0),
    );

    return flatten(unavailableEvents);
  },

  unavailableProjectTime(state, { projects, unavailableTime }) {
    const events = [];

    unavailableTime.forEach((event) => {
      projects.forEach((project) => {
        project.userIds.forEach((userId) => {
          if (event.userId === userId) {
            events.push({
              ...event,
              resourceId: JSON.stringify({ userId, projectId: project.id }),
            });
          }
        });
      });
    });

    return events;
  },

  people(state, getters, rootState) {
    const schedules = getters['people/list'].map(
      (id) => state.people.records[id],
    );

    return schedules.map(
      ({
        userId,
        projectIds,
        allocatedTotalMinutes,
        loggedTotalMinutes,
        unavailableTotalMinutes,
        budgetMinutes,
      }) => ({
        ...rootState.people.records[userId],
        projectIds: [...new Set(projectIds)],
        allocatedTotalMinutes,
        loggedTotalMinutes,
        unavailableTotalMinutes,
        budgetMinutes,
      }),
    );
  },

  projects(state, getters, rootState) {
    const schedules = getters['projects/list'].map(
      (id) => state.projects.records[id],
    );

    return schedules.map(
      ({
        projectId,
        userIds,
        allocatedTotalMinutes,
        loggedTotalMinutes,
        unavailableTotalMinutes,
        budgetMinutes,
        budget,
      }) => {
        const project = rootState.project.records[projectId];
        const company = rootState.company.records[project.company.id];

        return {
          ...project,
          userIds: [...new Set(userIds)],
          company,
          allocatedTotalMinutes,
          loggedTotalMinutes,
          unavailableTotalMinutes,
          budgetMinutes,
          budget,
        };
      },
    );
  },

  rollups(state, getters, rootState) {
    const { type } = state;
    const schedules = Object.values(state[type].records);

    const rollupGroups = schedules.map((item) =>
      Object.keys(item.schedule).map((startDate) => {
        let isInTimeline = false;

        if (type === 'projects') {
          const rollupDate = moment
            .tz(startDate, moment.tz.guess())
            .startOf('day');
          const project = rootState.project.records[item.projectId];

          if (project.startDate && project.endDate) {
            if (
              rollupDate.isBetween(project.startDate, project.endDate) ||
              rollupDate.isSame(project.startDate) ||
              rollupDate.isSame(project.endDate)
            ) {
              isInTimeline = true;
            }
          }
        }

        return {
          ...item.schedule[startDate],
          isInTimeline,
          duration: 1, // tell bryntum scheduler this takes up a day
          startedAt: moment.tz(startDate, moment.tz.guess()).toDate(),
          resourceId: type === 'projects' ? item.projectId : item.userId,
          isRollup: true,
          resizable: false,
          draggable: false,
          isProject: type === 'projects',
        };
      }),
    );

    const flatRollups = flatten(rollupGroups);

    return flatRollups.filter((rollup) => {
      const hasAllocations =
        rollup.allocationIds && rollup.allocationIds.length;
      const hasMilestones = rollup.milestoneIds && rollup.milestoneIds.length;
      const hasUnavailableTime = !!rollup.unavailableMinutes;

      return (
        type === 'people' ||
        hasAllocations ||
        hasMilestones ||
        hasUnavailableTime ||
        rollup.isInTimeline
      );
    });
  },

  unavailableDatesForUser: (state, getters) => (id) =>
    getters.unavailableTime
      .filter((ut) => {
        const unavailbleHours = ut.unavailableMinutes / 60;
        return ut.userId === id && unavailbleHours === 8;
      })
      .map((unavailable) => moment(unavailable.startedAt).format('YYYY-MM-DD')),

  daysInRange: (state) => {
    const dates = getDatesInRange(state.startDate, state.endDate);
    return dates.map((d) =>
      moment(d).locale('en').format('dddd').toLowerCase(),
    );
  },
};
