<template>
  <div
    :class="[
      'btn-group',
      {
        fill: this.isFullWidth,
        'inline-flex': !this.isFullWidth,
        flex: this.isFullWidth,
        'w-full': this.isFullWidth,
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FButtonGroup',
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.btn-group button {
  position: relative;
}

.btn-group.fill button {
  width: 100%;
}

.btn-group button::before {
  content: '';
  width: 1px;
  height: 80%;
  position: absolute;
  right: 0;
  background-color: var(--token-color-white);
}

.btn-group button:last-child::before {
  display: none;
}

.btn-group button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
