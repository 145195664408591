<template>
  <v-popover
    v-if="columnsLevel1.length > 0"
    class="TableViewColumnPicker"
    :open.sync="openLevel1"
    :auto-hide="!openLevel2"
    :boundaries-element="boundariesElement"
    placement="bottom-end"
    popover-arrow-class="hidden"
    v-tooltip="{
      content: $t('Add or remove columns'),
      placement: 'top',
      boundariesElement: boundariesElement,
    }"
  >
    <template #popover>
      <div class="TableViewColumnPicker__title">{{ $t('Customize view') }}</div>
      <TableViewColumnPickerFieldItems
        :columns="columnsLevel1"
        :data-identifier-prefix="dataIdentifierPrefix"
        @on-show-edit-custom-field="editCustomFieldModal"
        @on-column-reorder="onColumnReorder"
        @on-drag-start="onDragStart"
        @on-drag-end="onDragEnd"
      >
        <v-popover
          v-if="columnsLevel2.length > 0"
          :class="{
            TableViewColumnPicker__more: true,
            'TableViewColumnPicker__more--open': openLevel2,
          }"
          :open.sync="openLevel2"
          :delay="{ hide: 300 }"
          boundaries-element=".TableViewColumnPicker"
          placement="left-end"
          popover-arrow-class="hidden"
          trigger="manual"
          offset="4"
        >
          <template #popover>
            <TableViewColumnPickerFieldItems
              secondary
              :columns="columnsLevel2"
              :data-identifier-prefix="dataIdentifierPrefix"
              @on-show-edit-custom-field="editCustomFieldModal"
              @mouseenter.native="openLevel2 = true"
              @mouseleave.native="closeLevel2Menu"
              @on-column-reorder="onColumnReorder"
            />
          </template>
          <TableViewColumnPickerItem
            :data-identifier="
              dataIdentifierPrefix ? `${dataIdentifierPrefix}-more` : undefined
            "
            @mouseenter.native="openLevel2 = true"
            @mouseleave.native="closeLevel2Menu"
          >
            {{ $t('More...') }}
            <CommonIcon id="chevron-right-solid" />
          </TableViewColumnPickerItem>
        </v-popover>
        <TableViewColumnPickerItem
          v-if="canManageCustomFields && canAccessCustomFields"
          line-over
          v-close-popover
        >
          <button
            class="TableViewColumnPicker__add-custom-field"
            data-identifier="column-picker-add-custom-field"
            @click="openAddCustomFieldModal"
          >
            <span class="plus-sign">
              <CommonIcon id="add" />
            </span>
            {{ $t('Add field') }}
          </button>
        </TableViewColumnPickerItem>
        <TableViewColumnPickerItem v-if="canResetColumns" line-over>
          <template #toggle-switch>
            <ToggleSwitch
              v-model="canAutoResetColumns"
              :reversed-order="true"
              :label="$t('Shared view')"
              :tip="
                canUpdateColumnDefaults
                  ? $t('Save as the shared view for everyone on this project')
                  : $t('See the shared view for everyone on this project')
              "
            />
          </template>
        </TableViewColumnPickerItem>
      </TableViewColumnPickerFieldItems>
    </template>
    <div
      :class="{
        TableViewColumnPicker__trigger: true,
        'TableViewColumnPicker__trigger--open': openLevel1,
      }"
      :data-identifier="
        dataIdentifierPrefix ? `${dataIdentifierPrefix}-add-columns` : undefined
      "
    >
      <CommonIcon id="add" />
    </div>
  </v-popover>
</template>

<script>
import { computed, defineComponent, shallowRef } from 'vue-demi';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';
import ToggleSwitch from '@widgets/ToggleSwitch';
import { useTableView } from './useTableView';
import { useFeatures } from '@/platform/composables/useFeatures';
import TableViewColumnPickerFieldItems from './TableViewColumnPickerFieldItems';
import TableViewColumnPickerItem from './TableViewColumnPickerItem';
import useStore from '@/platform/composables/useStore';

export default defineComponent({
  name: 'TableViewColumnPicker',
  props: {
    dataIdentifierPrefix: {
      type: String,
      default: null,
    },
  },
  components: {
    TableViewColumnPickerFieldItems,
    TableViewColumnPickerItem,
    CommonIcon,
    ToggleSwitch,
  },

  setup() {
    const store = useStore();
    const { customfieldsProjectsEnabled, customfieldsTasksEnabled } =
      useFeatures();
    const {
      columns,
      enabledColumns,
      updateColumn,
      canResetColumns,
      canAutoResetColumns,
      canUpdateColumnDefaults,
    } = useTableView();
    const toggleableColumns = computed(() =>
      columns.value.filter(({ toggleable }) => toggleable),
    );
    const countLevel1 = computed(() =>
      toggleableColumns.value.length === 9 ? 9 : 8,
    );
    const openLevel1 = shallowRef(false);
    const openLevel2 = shallowRef(false);
    const columnsLevel1 = computed(() =>
      toggleableColumns.value.slice(0, countLevel1.value),
    );
    const columnsLevel2 = computed(() =>
      toggleableColumns.value.slice(countLevel1.value),
    );

    const isProjectListView = computed(
      // eslint-disable-next-line no-underscore-dangle
      () => store.state.routes?.tkoCurrentRoute?.request_ === 'projects/list',
    );

    const currentProject = computed(() => store.getters['project/current']);
    const canManageCustomFields = computed(() => {
      // have global permission or project specific permission
      const hasPermission =
        store.state.permissions.canManageCustomFields ||
        currentProject.value?.permissions?.canManageCustomFields;
      // should be project list view or projects/:id/tasks
      return (isProjectListView.value || currentProject.value) && hasPermission;
    });
    const canAccessCustomFields = computed(() => {
      return (
        customfieldsProjectsEnabled.value || customfieldsTasksEnabled.value
      );
    });
    const canSetProjectSpecific = computed(() =>
      currentProject.value
        ? currentProject.value.type !== 'tasklists-template'
        : false,
    );
    const getCustomFieldData = (result) => {
      // if it's an object of TKO customField class (added through TKO modal)
      // then method 'toJSON' will be available
      // TODO: remove condition once we depricate TKO modal
      const customField =
        typeof result.customField.toJSON === 'function'
          ? result.customField.toJSON()
          : result.customField;
      const columnId = canSetProjectSpecific.value
        ? `customField-${customField.id}`
        : `custom-field-${customField.id}`;

      return { customField, columnId };
    };

    const onEditCloseCallback = (result) => {
      if (result.wasCancelled) {
        return;
      }
      const { columnId, customField } = getCustomFieldData(result);
      updateColumn(columnId, 'customField', customField);
      updateColumn(columnId, 'name', customField.name);
      updateColumn(columnId, 'description', customField.description);
    };

    const onAddCloseCallback = (result) => {
      if (result.wasCancelled) {
        return;
      }
      const { columnId } = getCustomFieldData(result);
      // add column to bottom of the enabled cols list
      updateColumn(columnId, 'order', enabledColumns.value.length);
      updateColumn(columnId, 'enabled', true);
    };

    function openAddCustomFieldModal() {
      store.dispatch('modals/open', {
        name: 'add-edit-custom-field',
        props: {
          mode: 'add', // default
          entity: canSetProjectSpecific.value ? 'task' : 'project',
          projectId: canSetProjectSpecific.value
            ? currentProject.value.id
            : null,
          canToggleProjectSpecific: canSetProjectSpecific.value,
          onAdd: onAddCloseCallback,
        },
      });
    }

    function editCustomFieldModal(field) {
      openLevel1.value = false;
      openLevel2.value = false;

      const customField = field.customField;
      store.dispatch('modals/open', {
        name: 'add-edit-custom-field',
        props: {
          mode: 'edit',
          field: {
            id: customField.id,
            type: customField.type,
            name: customField.name,
            options: customField.options?.choices,
            description: customField.description,
          },
          entity: canSetProjectSpecific.value ? 'task' : 'project',
          projectId: canSetProjectSpecific.value
            ? currentProject.value.id
            : null,
          onUpdate: onEditCloseCallback,
        },
      });
    }

    function onColumnReorder() {
      const primaryPopoverCols = columnsLevel1.value || [];
      const secondaryPopoverCols = columnsLevel2.value || [];
      const allColumns = [...primaryPopoverCols, ...secondaryPopoverCols];
      allColumns.forEach((col, order) => updateColumn(col.id, 'order', order));
    }

    const dragging = shallowRef(false);
    function closeLevel2Menu() {
      if (!dragging.value) {
        openLevel2.value = false;
      }
    }
    function onDragStart() {
      dragging.value = true;
      openLevel2.value = true;
    }
    function onDragEnd() {
      dragging.value = false;
      closeLevel2Menu();
    }

    return {
      openLevel1,
      openLevel2,
      closeLevel2Menu,
      columnsLevel1,
      columnsLevel2,
      updateColumn,
      canResetColumns,
      canAutoResetColumns,
      canUpdateColumnDefaults,
      canManageCustomFields,
      canAccessCustomFields,
      boundariesElement: document.body,
      openAddCustomFieldModal,
      editCustomFieldModal,
      onColumnReorder,
      onDragStart,
      onDragEnd,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';

.TableViewColumnPicker {
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 16px 16px 0;
  }

  &__more {
    display: flex;
    flex-direction: column;
    cursor: default;
    fill: #8a8d99;
    &:hover,
    &--open {
      background-color: $token-ui-bg-hover;
    }
  }

  &__trigger {
    font-size: 12px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $token-size-px solid $token-ui-primary;
    border-radius: 12px;
    background: $token-ui-bg;
    fill: $token-ui-black;

    &:hover,
    &--open {
      background: $token-ui-primary;
      fill: $token-ui-white;
    }
  }

  &__add-custom-field {
    padding: 0;
    outline: none;
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    color: #4461d7;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }

    .plus-sign {
      display: inline-flex;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      position: relative;
      align-items: center;

      svg {
        fill: #4461d7;
      }
    }
  }
}
</style>
