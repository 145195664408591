import { v1 as fetcher } from '@teamwork/fetcher';
import api from '@/services/api';

const defaultParams = {
  pageSize: 1,
  orderBy: 'date',
  sortOrder: 'desc',
};

const normalize = ({ data }) => data;

const fetchByType = async ({ id, type, params, pagination }) =>
  fetcher(
    `${type}/${id}/comments`,
    { ...defaultParams, ...params, ...pagination },
    normalize,
  );
const markAsRead = async ({ id, params, pagination }) =>
  api.put(
    `comments/${id}/markread.json`,
    { ...defaultParams, ...params, ...pagination },
    normalize,
  );

// eslint-disable-next-line import/prefer-default-export
export default {
  fetchByType,
  markAsRead,
};
