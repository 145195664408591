<template>
  <div>
    <div v-show="!isFollowersListVisible">
      <AddButton @clicked="modifyFollowers" :disabled="!canEdit">
        {{ $t('Add followers') }}
      </AddButton>
    </div>
    <c-people-picker
      v-show="isFollowersListVisible"
      ref="peoplePicker"
      :can-add-users="true"
      :project-id="projectId"
      :task-id="task.id"
      :add-user-label-text="$t(`Can't find user`)"
      :add-user-button-text="$t('Add user')"
      :assign-button-text="$t('Add Follower')"
      :search-label-text="$t('Search')"
      :empty-list-text="$t('List is empty')"
      :no-results-text="$t('No results')"
      :choose-more-text="$t('Add more')"
      :clear-text="$t('Clear')"
      :should-use-recently-assigned="true"
      size="small"
      :disabled="!canEdit"
      @click.self="modifyFollowers"
    ></c-people-picker>
  </div>
</template>

<script>
import { computed, ref, watch, toRefs } from 'vue-demi';
import AddButton from '@widgets/TaskDetailWidgets/AddButton';
import usePeoplePicker from '@/platform/composables/usePeoplePicker';
import toTaskModel from '@/utils/helpers/toTaskModel';
import useStore from '@/platform/composables/useStore';

export default {
  name: 'FollowersSection',
  components: {
    AddButton,
  },
  props: {
    assignees: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: Number,
      required: true,
    },
    task: {
      type: Object,
      default: () => ({}),
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const peoplePicker = ref(null);

    const { assignees, canEdit, task: taskProp } = toRefs(props);

    const isFollowersListVisible = computed(() => assignees.value.length);

    const { peoplePickerItems } = usePeoplePicker(assignees);

    watch(
      assignees,
      () => {
        if (
          peoplePicker.value &&
          Object.prototype.hasOwnProperty.call(peoplePicker.value, 'assignees')
        ) {
          peoplePicker.value.assignees = peoplePickerItems.value;
        }
      },
      {
        immediate: true,
      },
    );

    function modifyFollowers() {
      if (canEdit.value) {
        const task = {
          ...taskProp.value,
          dateCreated: taskProp.value.createdAt,
        };

        store.dispatch('modals/tko/open', {
          id: 'taskFollowers',
          args: {
            task: toTaskModel(task),
          },
        });
      }
    }

    return {
      peoplePicker,
      isFollowersListVisible,
      modifyFollowers,
    };
  },
};
</script>

<style lang="scss" scoped></style>
