<template>
  <div
    v-show="hover"
    class="fullscreen-toggl-button"
    data-identifier="toggle-fullscreen"
    @click="toggleFullscreen"
  >
    <CommonIcons
      :id="isFullscreen ? 'chevron-right-light' : 'chevron-left-light'"
    />
  </div>
</template>
<script>
import CommonIcons from '@teamwork/common-icons/dist/v-icon';

export default {
  name: 'FullscreenToggle',
  props: {
    isFullscreen: { type: Boolean, required: true },
    hover: { type: Boolean, default: true },
  },
  components: { CommonIcons },
  methods: {
    toggleFullscreen() {
      this.$emit('toggle-fullscreen');
    },
  },
};
</script>
<style lang="scss" scoped>
.fullscreen-toggl-button {
  height: 80px;
  width: 16px;
  background: #e1e6ee;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(11, 14, 31, 0.12);
  border-radius: 8px 0px 0px 8px;

  &:hover {
    cursor: pointer;
  }
}
</style>
