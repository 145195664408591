/* eslint-disable global-require, no-plusplus, max-len */
import CommonIcons from '@teamwork/common-icons/dist/v-common-icon';
import { mapGetters, mapState } from 'vuex';
import tours from '../../../data/onboarding/tours/ProductTours';
import useProductTour from '@/views/onboarding/composables/productTour';
import getLastUpdatedProjectId from '../../../utils/helpers/getLastUpdatedProjectId';

// @vue/component
export default {
  components: {
    CommonIcons,
  },
  setup() {
    const {
      activeProductTourId,
      setActiveProductTourId,
      updateTourPreferences,
    } = useProductTour();

    return {
      activeProductTourId,
      setActiveProductTourId,
      updateTourPreferences,
    };
  },
  data() {
    return {
      cachedProjectId: null,
      currentActive: 0,
      hasScrolledToBottom: true,
    };
  },
  async mounted() {
    this.cachedProjectId = this.currentProjectId || this.lastActiveProjectId;
    if (!this.cachedProjectId) {
      this.cachedProjectId = await getLastUpdatedProjectId();
    }
    this.moveTo(this.productTour.steps[0], 0);
  },
  computed: {
    ...mapState('project', ['currentProjectId']),
    ...mapState('project', ['lastActiveProjectId']),
    ...mapState('account', ['isLiveChatEnabled']),
    ...mapGetters('account', ['isPlanTrialActive', 'isPlanTypeMaxProjects']),
    onFirstStep: (vm) => vm.currentActive === 0,
    onLastStep: (vm) => vm.currentActive >= vm.productTour.steps.length - 1,
    productTour() {
      const tourId = this.activeProductTourId;
      return Object.values(tours).find((tour) => tour.id === tourId);
    },
    showProductTour() {
      return (
        !this.productTour.projectSpecific ||
        (this.productTour.projectSpecific && this.cachedProjectId)
      );
    },
  },
  watch: {
    $route() {
      const pathIsOnTour = this.productTour?.steps?.find((step) =>
        this.$route.path[step.matchIncludes ? 'includes' : 'endsWith'](
          step.stepRoute,
        ),
      );
      if (!pathIsOnTour) {
        this.dismissTour();
      }
    },
  },
  methods: {
    moveTo(step, index) {
      const { stepRoute, routeInProject } = step;
      this.trackStep(step.name());

      this.currentActive = index;
      if (!stepRoute) {
        return;
      }
      if (routeInProject) {
        this.$router.push(`/projects/${this.cachedProjectId}${stepRoute}`);
      } else {
        this.$router.push(stepRoute);
      }
    },
    moveToPrev() {
      if (this.onFirstStep) {
        return;
      }
      const index = this.currentActive - 1;
      this.moveTo(this.productTour.steps[index], index);
    },
    moveToNext() {
      if (this.onLastStep) {
        this.dismissTour();
        return;
      }
      const index = this.currentActive + 1;
      this.moveTo(this.productTour.steps[index], index);
    },
    async finished() {
      if (this.cachedProjectId) {
        this.$router.push(`/projects/${this.cachedProjectId}`);
      }

      await this.updateTourPreferences(this.productTour.id);

      this.trackTourCompleted();

      this.dismissTour();
    },
    dismissTour() {
      this.setActiveProductTourId(null);

      this.$emit('dismissed');
    },
    handleScroll() {
      const elementToTrack = this.$refs.description[0];
      if (elementToTrack.offsetHeight > elementToTrack.scrollHeight) {
        this.hasScrolledToBottom = true;
        return;
      }
      if (
        elementToTrack.offsetHeight + elementToTrack.scrollTop >=
        elementToTrack.scrollHeight
      ) {
        this.hasScrolledToBottom = true;
        return;
      }
      this.hasScrolledToBottom = false;
    },
    trackStep(stepName) {
      if (!window.pendo || !window.pendo.track) {
        return;
      }

      const stepInfo = {
        tourId: this.productTour.id,
        tourName: this.productTour.name,
        stepName,
      };

      window.pendo.track('TOUR_STEP', stepInfo);
    },
    trackTourCompleted() {
      if (!window.pendo || !window.pendo.track) {
        return;
      }

      const tourInfo = {
        tourId: this.productTour.id,
        tourName: this.productTour.name,
      };

      window.pendo.track('TOUR_COMPLETED', tourInfo);
    },
  },
};
