<template>
  <div class="table-cell-time">
    {{ formattedTime }}
  </div>
</template>

<script>
import { formatMinutes } from '@/utils/helpers/time';

// @vue/component
export default {
  name: 'Time',
  display: 'Time',
  props: {
    minutes: { type: Number, required: true, default: 0 },
  },
  computed: {
    formattedTime() {
      return formatMinutes(this.minutes);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell-time {
  padding: 0 10px;
}
</style>
