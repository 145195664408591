import { shallowRef } from 'vue-demi';
import { useAxiosResponseInterceptor } from '@teamwork/use';

const hasSetFailIncrement = shallowRef(false);

function redirectToLogin() {
  const fails = +sessionStorage.getItem('projects-auth-fails') || 0;

  if (fails >= 3) {
    sessionStorage.removeItem('projects-auth-fails');
    window.top.location.href = '/launchpad/logout/projects?r=authFail';
  } else {
    if (!hasSetFailIncrement.value) {
      sessionStorage.setItem('projects-auth-fails', fails + 1);
      hasSetFailIncrement.value = true;
    }
    console.info('useAppErrorHandler - redirecting to login');
    window.top.location.href = `/launchpad/login/projects?continue=${encodeURIComponent(
      window.top.location.href,
    )}`;
  }
}

export default function useErrorHandler() {
  useAxiosResponseInterceptor(null, (error) => {
    const url = error.request?.url || '';

    const isIntegrationsRequest = url.includes('integrations/');
    const isChatRequest = url.includes('chat/');
    const isSpacesRequest = url.includes('spaces/');

    // Ignore failed spaces requests.
    if (isSpacesRequest || isChatRequest || isIntegrationsRequest) {
      return Promise.reject(error);
    }

    // Ignore cached responses
    if (error.config?.cache === 'only-if-cached') {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      if (error.request?.suppressLoginModal) {
        return Promise.reject(error);
      }

      // If the TKO modal is ready, use it.
      if (window.app && !window.app.isShowingLoginModal()) {
        window.app.showLoginModal();
        return Promise.reject(error);
      }

      // Redirect to launchpad if TKO modal isn't available
      redirectToLogin();
    }
    return Promise.reject(error);
  });
}
