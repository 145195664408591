<template>
  <div class="table-cell-task-subtasks" @click="subtaskOpenChange">
    <button class="subtask-btn">
      <svg
        aria-hidden="true"
        data-prefix="tw"
        data-icon="subtasks"
        class="svg-inline--fa fa-subtasks fa-w-16 fa-1x color--inherit"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 12"
      >
        <path
          fill="currentColor"
          d="M.5 0a.5.5 0 0 1 .5.5V4h8.085a1.5 1.5 0 1 1 0 1H1v.5c0 2.097.802 3.18 1.71 3.768.943.61 2.08.732 2.79.732h3.585a1.5 1.5 0 1 1 0 1H5.494c-.79 0-2.15-.13-3.328-.893C.948 9.32 0 7.903 0 5.5m0 0v-5A.5.5 0 0 1 .5 0"
        ></path>
      </svg>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-697.000000, -1088.000000)" fill="#0B0E1F">
          <g transform="translate(459.000000, 928.000000)">
            <!-- eslint-disable max-len -->
            <path
              d="M244.333333,161.25 C243.4125,161.25 242.666667,161.949219 242.666667,162.8125 C242.666667,163.523438 243.172917,164.123047 243.864583,164.3125 C243.852083,164.626953 243.777083,164.869141 243.635417,165.033203 C243.314583,165.408203 242.608333,165.470703 241.860417,165.535156 C241.272917,165.585937 240.664583,165.640625 240.166667,165.865234 L240.166667,163.052734 C240.84375,162.853516 241.333333,162.261719 241.333333,161.5625 C241.333333,160.699219 240.5875,160 239.666667,160 C238.745833,160 238,160.699219 238,161.5625 C238,162.261719 238.489583,162.853516 239.166667,163.052734 L239.166667,166.945313 C238.489583,167.146484 238,167.738281 238,168.4375 C238,169.300781 238.745833,170 239.666667,170 C240.5875,170 241.333333,169.300781 241.333333,168.4375 C241.333333,167.773438 240.891667,167.205078 240.266667,166.980469 C240.33125,166.878906 240.429167,166.789062 240.577083,166.71875 C240.914583,166.558594 241.41875,166.515625 241.954167,166.46875 C242.833333,166.392578 243.829167,166.304687 244.416667,165.621094 C244.708333,165.28125 244.85625,164.84375 244.866667,164.294922 C245.525,164.083984 246,163.5 246,162.8125 C246,161.949219 245.254167,161.25 244.333333,161.25 Z M240,161.5625 C240,161.734375 239.85,161.875 239.666667,161.875 C239.483333,161.875 239.333333,161.734375 239.333333,161.5625 C239.333333,161.390625 239.483333,161.25 239.666667,161.25 C239.85,161.25 240,161.390625 240,161.5625 Z M239.333333,168.4375 C239.333333,168.265625 239.483333,168.125 239.666667,168.125 C239.85,168.125 240,168.265625 240,168.4375 C240,168.609375 239.85,168.75 239.666667,168.75 C239.483333,168.75 239.333333,168.609375 239.333333,168.4375 Z M244.666667,162.8125 C244.666667,162.984375 244.516667,163.125 244.333333,163.125 C244.15,163.125 244,162.984375 244,162.8125 C244,162.640625 244.15,162.5 244.333333,162.5 C244.516667,162.5 244.666667,162.640625 244.666667,162.8125 Z"
            />
          </g>
        </g>
      </g>

      <span class="subtask-number">{{ subtasks }}</span>
    </button>
  </div>
</template>

<script>
// @vue/component
export default {
  name: 'Subtasks',
  display: 'Subtasks',
  props: {
    subtasks: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const subtaskOpenChange = () => {
      emit('subtaskOpenChange');
    };
    return { subtaskOpenChange };
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
.table-cell-task-subtasks {
  display: flex;
  align-items: center;
  margin-right: 12px;
  height: 28px;
  svg {
    font-size: 12.5px;
    margin-right: $margin--small;
    margin-left: $margin--tiny;
  }
}
.subtask-btn {
  position: relative;
  background: none;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
}
.subtask-number {
  font-size: $font-size--h4;
  font-weight: $font-weight--normal;
  color: $color--text-tinted-dark;
}
</style>
