export const buttonSizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};
export const defaultButtonSize = buttonSizes.md;

export const buttonVariants = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  warning: 'warning',
  slate: 'slate',
  ghost: 'ghost',
  outline: 'outline',
};
export const defaultButtonVariant = buttonVariants.primary;

export const buttonTypes = {
  button: 'button',
  submit: 'submit',
  reset: 'reset',
};
export const defaultButtonType = buttonTypes.button;

export const buttonAlignments = {
  left: 'left',
  center: 'center',
  right: 'right',
};
export const defaultButtonAlignment = buttonAlignments.center;
