export default ({ dispatch, commit }, { subType, actionType, extraInfo }) => {
  if (
    ['permissions', 'archived'].includes(subType) ||
    actionType === 'deleted'
  ) {
    commit('stats/resetPermissions');
    dispatch('stats/changeNotification'); // app.loggedInUser.GetStats(true)
  }
  // if this is a progressNotice, use it to update the progress on our test page
  if (actionType === 'progressNotice') {
    const data = extraInfo.data;
    const projectId = extraInfo.objectId;
    const type = data.type || data.TYPE;
    const progressMessage =
      data.progressmessage || data.progressMessage || data.PROGRESSMESSAGE;
    commit('sampleProjects/setLastUpdated', new Date());
    if (type === 'error') {
      commit('sampleProjects/updateStatus', 'error');
      if (progressMessage) {
        commit('sampleProjects/addErrors', progressMessage);
      }
    } else if (type === 'complete') {
      commit('sampleProjects/updateStatus', 'complete');
    } else {
      if (progressMessage) {
        commit('sampleProjects/updateProgress', progressMessage);
      }
      if (projectId && parseInt(projectId, 10) > 0) {
        commit(
          'sampleProjects/updateLastCreatedProjectId',
          parseInt(projectId, 10),
        );
      }
    }
  }
  // If you edit/update a template reload the custom templates data
  if (
    actionType === 'edited' &&
    extraInfo &&
    extraInfo.data &&
    extraInfo.data.isTemplate
  ) {
    dispatch('customTemplate/all/changeNotification');
  }
};
