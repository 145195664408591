export const validateHex = (color) => /^([A-Fa-f0-9]{6})$/.test(color);

export const hexComponents = (color) => ({
  r: parseInt(color.substr(0, 2), 16),
  g: parseInt(color.substr(2, 2), 16),
  b: parseInt(color.substr(4, 2), 16),
});

export const isColorLight = (color) => {
  const { r, g, b } = hexComponents(color);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return 255 - brightness < 20;
};

export const darken = (color, percent) => {
  const calc = (val) => Math.floor(val * percent);
  const { r, g, b } = hexComponents(color);
  return `rgb(${calc(r)}, ${calc(g)}, ${calc(b)})`;
};

export const strToColor = (str) => {
  let hash = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
};

export const categoryColors = [
  '27AE60',
  '99DF72',
  '1ABC9C',
  '6866D0',
  '8E44AD',
  '0AD2F5',
  '3498DB',
  '3D82DE',
  'C0392B',
  'E74C3C',
  'A94136',
  '660A00',
  'F39C12',
  'F1C40F',
  '34495E',
  '7F8C8D',
  'D35400',
  'B49255',
  'D870AD',
  'BDC3C7',
  '9B59B6',
];

export const tagColors = [
  '#d84640',
  '#f78234',
  '#f4bd38',
  '#b1da34',
  '#53c944',
  '#37ced0',
  '#2f8de4',
  '#9b7cdb',
  '#f47fbe',
  '#a6a6a6',
  '#4d4d4d',
  '#9e6957',
];

export const allocationColors = {
  // dark: ['4f8bE6', '3e8ea6', '8ccda8', 'f4b747', 'aa531f', 'eb4cae', '5c42d7', '4864cf'],
  dark: [],
  light: [
    'afdff9',
    'c5f2f2',
    'c7f6df',
    'fceabc',
    'e1b892',
    'efabbf',
    'f1cce6',
    'd3c9f7',
    'c4cff7',
  ],
};
