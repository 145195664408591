<template>
  <div>
    <SubtasksItem
      v-for="(subtask, index) in subtasks"
      :key="subtask.id"
      :parent="task"
      :last="index === subtasks.length - 1"
      :subtask="subtask"
      :level="level"
      :leaf-levels="leafLevels"
    />
  </div>
</template>

<script>
import { useTasksLoader } from '@teamwork/use';
import { defineComponent, computed } from 'vue-demi';
import SubtasksItem from '../SubtasksItem';

export default defineComponent({
  name: 'SubtasksItems',
  components: {
    SubtasksItem,
  },
  props: {
    task: { type: Object, required: true },
    subtaskIds: { type: Array, default: () => [] },
    level: { type: Number, required: false, default: 0 },
    leafLevels: { type: Array, default: () => [] },
  },
  setup(props) {
    const subtaskParams = {
      include: 'projects,taskLists,users,companies,teams,subTaskstats',
      includeLoggedTime: true,
      includeCustomFields: true,
      getSubTasks: true,
      includeRelatedTasks: true,
      checkForReminders: true,
      includeCompletedTasks: true,
      // orderBy: 'dateadded',
    };

    const taskId = computed(() => props.task.id);
    const { items: subtasks, error: subtasksError } = useTasksLoader({
      taskId,
      params: subtaskParams,
      count: Infinity,
    });

    return {
      subtasks,
      subtasksError,
    };
  },
});
</script>
<style lang="scss" scoped>
.subtasks {
  .subtask {
    border: 1px solid #f5f7fa;
    border-bottom: 0;
    padding: 14px 12px;
    font-size: 12px;
    display: flex;
    align-items: center;

    &:last-child {
      border: 1px solid #f5f7fa;
    }

    &__title {
      font-size: 14px;
      line-height: 24px;
      color: #0b0e1f;
    }
  }
}
</style>
