var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'custom-field-input',
    _vm.fromTaskDetails ? 'task-details' : 'table-cell-input',
    { multiline: _vm.isMultiline } ]},[(!_vm.value && !_vm.isEditing && !_vm.fromTaskDetails)?_c('div',{class:['custom-field-input__placeholder', { disabled: _vm.disabled }]},[_c('EmptyStateCell',{staticClass:"empty-state-icon",attrs:{"left-align":""}}),_vm._v(" "),_c('button',{staticClass:"empty-button",class:{ 'from-custom-field-modal': _vm.fromCustomFieldModal },on:{"click":_vm.handleEditing}},[_vm._v("\n      "+_vm._s(_vm.placeholder || _vm.$t('Enter text'))+"\n    ")])],1):_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      boundariesElement: 'body',
      container: _vm.tooltipContainer,
      content: _vm.textOverflows || _vm.isMultiline ? _vm.tooltip : undefined,
      html: true,
      placement: _vm.tooltipPlacement,
      classes: 'w-tooltip w-tooltip__description',
      'handle-resize': false,
    }),expression:"{\n      boundariesElement: 'body',\n      container: tooltipContainer,\n      content: textOverflows || isMultiline ? tooltip : undefined,\n      html: true,\n      placement: tooltipPlacement,\n      classes: 'w-tooltip w-tooltip__description',\n      'handle-resize': false,\n    }"}]},[(_vm.isMultiline)?_c('textarea',{ref:"input",class:[
        _vm.fromTaskDetails ? 'task-details' : 'tasks-table',
        { disabled: _vm.disabled } ],attrs:{"placeholder":_vm.placeholder || _vm.$t('Enter text'),"tabindex":_vm.disabled ? -1 : 0,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"keydown":function($event){$event.stopPropagation();return _vm.handleValueChange.apply(null, arguments)},"blur":_vm.handleBlur,"focus":_vm.handleFocus}}):_c('input',{ref:"input",class:[
        _vm.fromTaskDetails ? 'task-details' : 'tasks-table',
        { disabled: _vm.disabled } ],attrs:{"type":"text","placeholder":_vm.placeholder || _vm.$t('Enter text'),"tabindex":_vm.disabled ? -1 : 0,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"keydown":function($event){$event.stopPropagation();return _vm.handleValueChange.apply(null, arguments)},"blur":_vm.handleBlur,"focus":_vm.handleFocus,"click":_vm.handleEditing}})])])}
var staticRenderFns = []

export { render, staticRenderFns }