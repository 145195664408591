<template>
  <Chips :overlapping="true">
    <Chip image="https://i.pravatar.cc/150?img=5" />
    <Chip image="https://i.pravatar.cc/150?img=15" />
    <Chip image="https://i.pravatar.cc/150?img=25" />
    <Chip image="https://i.pravatar.cc/150?img=35" />
    <Chip image="https://i.pravatar.cc/150?img=45" />
    <Chip icon="add" />
  </Chips>
</template>

<script>
import { Chips, Chip } from '..';

export default {
  name: 'Followers',
  display: 'Followers',
  props: {
    overlapping: { type: Boolean, required: false, default: false },
  },
  components: { Chips, Chip },
};
</script>
