<template>
  <Button @click="$emit('click')" class="getting-started-btn">
    <div class="content">
      <CommonIcon id="check-circle" class="content__icon" />
      <h3 class="content__title">{{ $t('Getting Started') }}</h3>
      <div class="content__tasks-indicator">{{ tasksRemaining }}</div>
    </div>
  </Button>
</template>
<script>
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';

export default {
  components: {
    CommonIcon,
  },
  props: {
    tasksRemaining: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.getting-started-btn {
  bottom: 24px;
  right: 24px;
  z-index: 199;
  height: 48px;
  width: 212px;
  border-radius: 56px;
  background-color: #4461d7;
  border: none;
  box-shadow: 0px 4px 12px rgba(11, 14, 31, 0.12);
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  .content {
    display: flex;
    position: relative;
    padding: 10px;
    padding-left: 0;

    &__icon {
      margin: 2px 0px 0px 6px;
      fill: #fff;
      font-size: 20px;
    }

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 24px;
      font-size: 14px;
      margin-right: 12px;
      padding: 0px 20px 0px 10px;
      color: #fff;
    }

    &__tasks-indicator {
      position: absolute;
      right: 10px;
      min-width: 33px;
      width: 33px;
      height: 24px;
      background: #fff;
      border-radius: 50px;
      color: #4461d7;
    }
  }
}
</style>
