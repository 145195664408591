<template>
  <div class="c-people-picker__wrapper">
    <c-people-picker
      ref="peoplePicker"
      :can-add-users="canAddUsers"
      :disabled="disabled"
      :project-id="projectId"
      :task-id="taskId"
      :assign-button-title="$t('Assign someone to this task')"
      :add-user-label-text="$t(`Can't find user`)"
      :add-user-button-text="$t('Add user')"
      :assign-button-text="$t('Add Assignee')"
      :search-label-text="$t('Search')"
      :empty-list-text="$t('List is empty')"
      :no-results-text="$t('No results')"
      :choose-more-text="$t('Add more')"
      :clear-text="$t('Clear')"
      :should-use-recently-assigned="true"
      :size="size"
      :class="{ disabled }"
      @assignees:choose-more="onChooseMore"
      @assignees:updated="onAssigneesUpdated"
      @assignees:add-new-user="onAddNewUser"
    ></c-people-picker>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, toRefs } from 'vue-demi';
// @TODO - move to main.js
import { registerDS } from '@teamwork/design-system-components';
import ProjectModel from 'projectModel';
import useStore from '@/platform/composables/useStore';
import usePeoplePicker from '@/platform/composables/usePeoplePicker';

registerDS();

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      required: true,
    },
    taskId: {
      type: Number,
      required: true,
    },
    assignees: {
      type: Array,
      default: () => [],
      required: false,
    },
    companyPrivacyIds: {
      type: Array,
      default: () => [],
    },
    userPrivacyIds: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const peoplePicker = ref(null);

    const { assignees: assigneesItems } = toRefs(props);

    const { peoplePickerItems } = usePeoplePicker(assigneesItems);

    const currentProject = computed(() => store.state.project.records);
    const assigneesForPeoplePicker = peoplePickerItems;

    const user = computed(() => store.state.user);
    const type = computed(() =>
      user.value.userType === 'account' ||
      user.value.userType === 'collaborator'
        ? 'user'
        : user.value.userType,
    );
    const currentUser = computed(() => ({
      ...user.value,
      image: user.value.avatarUrl,
      name: `${user.value.firstName} ${user.value.lastName}`,
      type: type.value,
      value: `${type.value[0]}-${user.value.id}`,
      initials: `${user.value.firstName[0].toUpperCase()}${user.value.lastName[0].toUpperCase()}`,
    }));

    const canAddUsers = computed(
      () => store.getters['permissions/canManagePeople'],
    );

    onMounted(() => {
      peoplePicker.value.assignees = assigneesForPeoplePicker.value;
      peoplePicker.value.currentUser = currentUser.value;
      // Privacy // e.g. [1,2,3];
      peoplePicker.value.companyPrivacyIds = props.companyPrivacyIds;
      peoplePicker.value.userPrivacyIds = props.userPrivacyIds;
    });

    watch(
      () => props.assignees,
      () => {
        peoplePicker.value.assignees = assigneesForPeoplePicker.value;
      },
    );

    watch(
      () => props.userPrivacyIds,
      () => {
        peoplePicker.value.userPrivacyIds = props.userPrivacyIds;
      },
    );

    watch(
      () => props.companyPrivacyIds,
      () => {
        peoplePicker.value.companyPrivacyIds = props.companyPrivacyIds;
      },
    );

    const onChooseMore = () => {
      emit('choose-more');
    };

    const onAddNewUser = () => {
      const project = new ProjectModel(currentProject.value[props.projectId]);

      store.dispatch('modals/tko/open', {
        id: 'addPeopleToProject',
        args: { project },
      });
    };

    const onAssigneesUpdated = (event) => {
      const [assignees] = event.detail;
      // can only be user or team
      const userAssigneeMap = assignees
        .filter((a) => a.type === 'user')
        .map(({ id, image, firstName, lastName }) => ({
          id,
          linkedItem: {
            avatarUrl: image,
            firstName,
            lastName,
            pickerItemType: 'user',
          },
        }));

      const teamAssigneeMap = assignees
        .filter((a) => a.type === 'team')
        .map(({ id, image, name, icon }) => ({
          teamId: id,
          logo: image,
          color: icon.color,
          icon: icon.name,
          label: name,
          linkedItem: {
            pickerItemType: 'team',
          },
        }));

      // Optimistic update
      peoplePicker.value.assignees = assignees;
      emit('assignees-updated', [...userAssigneeMap, ...teamAssigneeMap]);
    };

    return {
      canAddUsers,
      peoplePicker,
      onChooseMore,
      onAddNewUser,
      onAssigneesUpdated,
      currentUser,
    };
  },
};
</script>

<style lang="scss" scoped>
.c-people-picker {
  margin: auto;
  &__wrapper {
    height: 24px;
  }
}
.disabled {
  opacity: 0.5;
}
</style>
