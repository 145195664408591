<template>
  <div
    :class="[
      { 'task-details': fromTaskDetails },
      type === 'status' ? 'custom-field-status' : 'custom-field-dropdown',
    ]"
  >
    <OptionPicker
      :value="value"
      :options="choices"
      :placeholder="$t('None')"
      :no-arrow="true"
      :show-dot="type === 'dropdown'"
      :show-tooltip="type === 'dropdown'"
      :disabled="disabled"
      @change="change"
      :open.sync="isDropDownOpen"
      :block="!fromTaskDetails"
    >
      <template v-if="fromTaskDetails" #trigger>
        <AddButton v-if="!value" :disabled="disabled" :active="isDropDownOpen">
          {{ placeholder }}
        </AddButton>
        <div
          v-else
          :class="['priority-icon-container', { active: isDropDownOpen }]"
        >
          <div :class="['priority-icon', 'text-center', { disabled }]">
            <span
              v-if="type === 'dropdown'"
              class="dot"
              :style="{ background: color }"
            />
            <CommonIcon
              v-else-if="type === 'status'"
              id="exclamation-triangle-solid"
              :style="{ fill: color }"
            />
          </div>
          {{ value }}
        </div>
      </template>
    </OptionPicker>
  </div>
</template>

<script>
import Vue from 'vue';
import OptionPicker from '@widgets/OptionPicker';
import AddButton from '@widgets/TaskDetailWidgets/AddButton';
import CommonIcon from '@teamwork/common-icons/dist/v-icon';

export default {
  name: 'CustomDropDown',
  display: 'CustomDropDown',
  components: {
    OptionPicker,
    AddButton,
    CommonIcon,
  },
  data() {
    return {
      isDropDownOpen: false,
    };
  },
  props: {
    value: { type: String, default: '' },
    type: { type: String, default: 'dropdown' },
    options: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    fromTaskDetails: { type: Boolean, default: false },
  },
  computed: {
    placeholder() {
      const taskDetailsPlaceholder =
        this.type === 'status' ? Vue.t('Add status') : Vue.t('Select option');
      return this.fromTaskDetails ? taskDetailsPlaceholder : Vue.t('None');
    },
    choices() {
      if (!this.options.choices) {
        return [];
      }
      const choices = this.options.choices.map((choice) => ({
        id: choice.value,
        label: choice.value,
        background: this.type === 'dropdown' ? '#F5F7FA' : choice.color,
        color: this.type === 'status' ? 'white' : 'inherit',
        dotColor: choice.color,
      }));
      choices.unshift({
        id: '',
        label: Vue.t('None'),
        background: '#F5F7FA',
        dotColor: 'transparent',
      });
      return choices;
    },
    color() {
      const selectedChoice = this.options.choices.find(
        (choice) => choice.value === this.value,
      );
      return selectedChoice?.color ?? 'white';
    },
  },
  methods: {
    change(value) {
      this.$emit('change', value.id || null);
    },
  },
};
</script>

<style lang="scss" scoped>
$hover-color: #4461d7;

.task-details {
  margin: 0px;
  padding: 0px 10px;
  margin-left: -10px;
}

.priority-icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  &.active {
    color: $hover-color;
    .priority-icon {
      border: 1px solid $hover-color;
    }
  }
  &:hover {
    color: $hover-color;

    .priority-icon {
      border: 1px solid $hover-color;
    }
  }
}
.priority-icon {
  width: 24px;
  height: 24px;
  border: 1px solid #e1e6ee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  margin-right: 8px;
  &.disabled {
    cursor: not-allowed;
  }

  svg {
    font-size: 13.5px;
    max-width: initial; // we need this to align it perfectly
  }
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 0px;
  border-radius: 4px;
  flex-shrink: 0;
}
.custom-field-status {
  &:not(.task-details) {
    margin: 2px;
    justify-content: center;
  }
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
