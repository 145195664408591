<template>
  <NavItemsContainer class="mb-250 block">
    <VPopover
      ref="popover"
      placement="right-end"
      popover-arrow-class="hidden"
      popover-inner-class=""
      :offset="0"
      @show="onShow"
      @hide="onHide"
      :open.sync="shouldShowPopover"
      :auto-hide="shouldHide"
    >
      <template #default>
        <ProfileNavItem
          ref="navItem"
          :aria-label="$t('Profile menu')"
          aria-controls="side-nav-profile-menu"
          aria-haspopup="menu"
          :aria-expanded="opened"
          role="menu-item"
          tabindex="0"
          @keydown.native.right.prevent.stop="showPopoverMenu"
          @click="shouldShowPopover = !shouldShowPopover"
        />
      </template>
      <template #popover>
        <ProfilePopoverContent
          ref="navItemsMenu"
          @more="onToggleMore"
          @keydown.native.left.prevent.stop="hidePopoverMenu"
          @keydown.native.up.prevent.stop="() => moveActive(-1)"
          @keydown.native.shift.tab.prevent="() => moveActive(-1)"
          @keydown.native.down.prevent.stop="() => moveActive(1)"
          @keydown.native.tab.exact.prevent="() => moveActive(1)"
        />
      </template>
    </VPopover>
  </NavItemsContainer>
</template>

<script>
import { ref } from 'vue-demi';
import { VPopover } from 'v-tooltip';
import useSideNavPopoverKeyboardAccessibility from '@/platform/composables/useSideNavPopoverKeyboardAccessibility';
import ProfilePopoverContent from './nav-items/ProfileMenuContent.vue';
import ProfileNavItem from './nav-items/ProfileNavItem.vue';
import NavItemsContainer from './nav-items/NavItemsContainer.vue';

export default {
  components: {
    NavItemsContainer,
    ProfilePopoverContent,
    ProfileNavItem,
    VPopover,
  },
  setup() {
    const {
      hidePopoverMenu,
      navItem,
      navItemsMenu,
      opened,
      popover,
      onShow,
      onHide,
      showPopoverMenu,
      moveActive,
    } = useSideNavPopoverKeyboardAccessibility();

    const shouldShowPopover = ref(false);
    const shouldHide = ref(true);

    const onToggleMore = (isOpen) => {
      // if the more popover was closed (either from clicking outside or other reason) the parent one should also close
      if (isOpen === false && shouldHide.value === false) {
        shouldShowPopover.value = false;
      }

      shouldHide.value = !isOpen;
    };

    return {
      hidePopoverMenu,
      navItem,
      navItemsMenu,
      opened,
      popover,
      onShow,
      onHide,
      showPopoverMenu,
      moveActive,

      shouldShowPopover,
      shouldHide,
      onToggleMore,
    };
  },
};
</script>

<style lang="scss" scoped>
/* Had no choice but to access the trigger class here */
::v-deep .trigger {
  width: 100%;
  height: 100%;
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
