import moment from 'moment';
import { stringify } from 'qs';
import { API } from '@/store/utils/loader';
import api from '@/services/api';
import { createFilteredListLoader } from '@/store/utils/loader/filtered';
import { createPagination } from '@/store/utils/loader/pagination/v3';

export default {
  namespaced: true,

  state: {
    records: {},
    searchTerm: null,
  },

  mutations: {
    records(state, schedules) {
      const hash = {};
      schedules.forEach((schedule) => {
        hash[schedule.projectId] = { ...schedule };
      });
      state.records = { ...state.records, ...hash };
    },

    searchTerm(state, searchTerm) {
      state.searchTerm = searchTerm;
    },
  },

  actions: {
    loadProjectSchedule({ commit, rootState }, id) {
      const opts = {
        startDate: moment(rootState.schedule.startDate).format('YYYY-MM-DD'),
        endDate: moment(rootState.schedule.endDate).format('YYYY-MM-DD'),
        projectIds: id,
        include: 'allocations,assignee.calendarEvents',
        orderBy: 'project',
      };
      const query = stringify(opts);

      return api
        .get(`${API.v3}/schedule/projects.json?${query}`)
        .then((res) => {
          const { data } = res;
          commit('records', data.projectSchedules);

          if (data.included.allocations) {
            commit(
              'allocations/records',
              Object.values(data.included.allocations),
              { root: true, recordMap: false },
            );
          }

          if (data.included.calendarEvents) {
            commit(
              'calendarEvent/records',
              Object.values(data.included.calendarEvents),
              { root: true, recordMap: false },
            );
          }
        });
    },
  },

  modules: {
    loader: createFilteredListLoader({
      modules: {
        pagination: createPagination({
          config: { pageSize: 30 },
        }),
      },
      config: {
        sort: false,
        url: `${API.v3}/schedule/projects.json`,
        params: ({ schedule }) => ({
          include:
            'allocations,projects,projects.milestones,projects.companies,projects.users,projects.budgets,assignee.timelogs,assignee.calendarEvents',
          startDate: moment(schedule.startDate).format('YYYY-MM-DD'),
          endDate: moment(schedule.endDate).format('YYYY-MM-DD'),
          orderBy: 'project',
        }),
        // Filter Params contains all the params that apply to the filtered view only,
        // the params above will affect the default and filtered loaders.
        filterParams: ({ schedule }, getters) => {
          const { searchTerm = schedule.projects.searchTerm, ...apiParams } =
            getters['filter/current/apiParameters'];

          return {
            searchTerm,
            ...apiParams,
          };
        },
        // Let's the loader know if we should be working with the filtered data or the full set
        isFiltered: (state, getters) =>
          getters['filter/current/active'] ||
          state.schedule.projects.searchTerm !== null,
        silentAjaxErrors: true,
        includedConfig: {
          allocations: 'allocations',
          projects: 'project',
          milestones: 'milestone',
          companies: 'company',
          timelogs: 'timelog',
          users: 'people',
          calendarEvents: 'calendarEvent',
        },
        mapResponse: (_, rs) =>
          rs.data.projectSchedules.map((el) => {
            const budgetId = el.budgetIds?.at(0);
            const budget = rs.data.included.projectBudgets?.[budgetId] || '';
            const id = el.projectId; // all records are expected to have an ID field
            return {
              id,
              ...el,
              budget,
            };
          }),
        module: 'schedule/projects',
      },
    }),
  },
};
