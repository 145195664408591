<template>
  <button
    v-if="canAddProjectsPermission"
    class="
      flex
      items-center
      justify-center
      rounded-full
      bg-transparent
      border-none
      text-text-secondary
      hover:text-text
      transition-colors
      cursor-pointer
      w-[30px]
      h-[30px]
      p-2
    "
    title="Add Project"
    @click="addProjectHeaderAction"
    v-tooltip="{
      content: $t('Add Project'),
      placement: 'bottom',
      boundariesElement: 'document.body',
    }"
  >
    <svg
      class="fill-current"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1112 5.33317H6.66675V0.888726C6.66675 0.765948 6.5673 0.666504 6.44453 0.666504H5.55564C5.43286 0.666504 5.33342 0.765948 5.33342 0.888726V5.33317H0.88897C0.766193 5.33317 0.666748 5.43261 0.666748 5.55539V6.44428C0.666748 6.56706 0.766193 6.6665 0.88897 6.6665H5.33342V11.1109C5.33342 11.2337 5.43286 11.3332 5.55564 11.3332H6.44453C6.5673 11.3332 6.66675 11.2337 6.66675 11.1109V6.6665H11.1112C11.234 6.6665 11.3334 6.56706 11.3334 6.44428V5.55539C11.3334 5.43261 11.234 5.33317 11.1112 5.33317Z"
      />
    </svg>
  </button>
</template>

<script>
import { computed } from 'vue-demi';
import { useCurrentUser } from '@teamwork/use';
import useStore from '@/platform/composables/useStore';

export default {
  name: 'PanelHeaderAddProject',
  setup(props, { emit }) {
    const user = useCurrentUser();
    const store = useStore();

    const canAddProjectsPermission = computed(() => {
      return user.value.permissions.canAddProjects || user.value.administrator;
    });

    function addProjectHeaderAction() {
      store.dispatch('modals/open', {
        name: 'add-edit-project-wizard-modal',
        props: {
          clickOutsideToClose: false,
          escToClose: false,
        },
      });
      emit('close');
    }

    return {
      addProjectHeaderAction,
      canAddProjectsPermission,
    };
  },
};
</script>
