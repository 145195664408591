<template>
  <div
    class="border border-solid px-3 flex items-center gap-2 py-2 rounded"
    :class="wrapperClasses"
  >
    <textarea
      class="
        w-full
        m-0
        p-0
        border-0
        outline-none
        text-default
        leading-6
        bg-transparent
        appearance-none
      "
      :class="textareaClasses"
      :value="modelValue"
      :disabled="disabled"
      :required="required"
      ref="textAreaRef"
      v-bind="$attrs"
      v-on="{ ...$listeners, input: onInput, blur: onBlur, focus: onFocus }"
      v-autosize="autoresize"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue-demi';
import autosize from './autosize';
import {
  textareaSizes,
  textareaVariants,
  defaultTextareaSize,
  defaultTextareaVariant,
} from './consts';
import optionPropValidator from '../../../helpers/optionPropValidator';

const variantClassesMap = {
  [textareaVariants.outline]: {
    background:
      'bg-[color:var(--token-c-input-color-background-outline-default)]',
    border: {
      default:
        'border-[color:var(--token-c-input-color-border-outline-default)]',
      disabled:
        'border-[color:var(--token-c-input-color-border-outline-disabled)]',
      focus: 'border-[color:var(--token-c-input-color-border-outline-focus)]',
      hover:
        'hover:border-[color:var(--token-c-input-color-border-outline-hover)]',
      invalid:
        'border-[color:var(--token-c-input-color-border-outline-invalid)]',
    },
  },
  [textareaVariants.filled]: {
    background:
      'bg-[color:var(--token-c-input-color-background-filled-default)]',
    border: {
      default:
        'border-[color:var(--token-c-input-color-border-filled-default)]',
      disabled:
        'border-[color:var(--token-c-input-color-border-filled-disabled)]',
      focus: 'border-[color:var(--token-c-input-color-border-filled-focus)]',
      hover:
        'hover:border-[color:var(--token-c-input-color-border-filled-hover)]',
      invalid:
        'border-[color:var(--token-c-input-color-border-filled-invalid)]',
    },
  },
};

const sizeClassesMap = {
  [textareaSizes.sm]: 'h-[var(--token-c-input-size-small)]',
  [textareaSizes.md]: 'h-[var(--token-c-input-size-medium)]',
  [textareaSizes.lg]: 'h-[var(--token-c-input-size-large)]',
};

export default {
  name: 'FTextarea',
  directives: { autosize },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: defaultTextareaSize,
      validator: optionPropValidator(textareaSizes, 'size'),
    },
    variant: {
      type: String,
      default: defaultTextareaVariant,
      validator: optionPropValidator(textareaVariants, 'variant'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autoresize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isFocused = ref(false);
    const variant = computed(() => variantClassesMap[props.variant]);
    const sizeClass = computed(() => sizeClassesMap[props.size]);

    const borderClasses = computed(() => {
      const { border } = variant.value;
      if (props.disabled) return border.disabled;
      if (isFocused.value) return border.focus;
      if (props.invalid) return border.invalid;
      return [border.default, border.hover];
    });

    const wrapperClasses = computed(() => [
      variant.value.background,
      borderClasses.value,
    ]);

    const textareaClasses = computed(() => {
      return props.disabled
        ? [
            sizeClass.value,
            'text-[color:var(--token-c-input-color-text-disabled)]',
            'placeholder:color-[color:var(--token-c-input-color-text-placeholder-disabled)]',
          ]
        : [
            sizeClass.value,
            'text-[color:var(--token-c-input-color-text-default)]',
            'placeholder:color-[color:var(--token-c-input-color-text-placeholder-default)]',
          ];
    });

    const textAreaRef = ref(null);
    function focus() {
      textAreaRef.value?.focus();
    }

    function onFocus(event) {
      isFocused.value = true;
      emit('focus', event);
    }

    function onBlur(event) {
      isFocused.value = false;
      emit('blur', event);
    }

    const onInput = (event) => {
      emit('input', event.currentTarget.value);
    };

    return {
      focus,
      isFocused,
      textareaClasses,
      wrapperClasses,
      onFocus,
      onBlur,
      onInput,
    };
  },
};
</script>
