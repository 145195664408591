<template>
  <ul
    class="w-item-picker__list"
    :class="[
      `list-mode--${listMode}`,
      `list-wrap--${wrapItems ? 'wrap' : 'nowrap'}`,
    ]"
  >
    <Item
      v-for="item in value"
      :key="item.id"
      :text="item.name"
      :bg-color="item.color"
      :is-active="itemsAreSelected"
      :read-only="readOnly"
      @click="selectItem(item)"
      @delete="!readOnly ? deleteItem(item) : undefined"
    />
  </ul>
</template>

<script>
import Item from './Item';

// @vue/component
export default {
  name: 'ItemList',
  components: {
    Item,
  },
  props: {
    readOnly: { type: Boolean, default: false },
    itemsAreSelected: { type: Boolean, default: true },
    listMode: {
      type: String,
      default: 'vertical',
      validator: (v) => ['vertical', 'horizontal'].includes(v),
    },
    wrapItems: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
  },
  methods: {
    selectItem(item) {
      if (!this.readOnly) {
        this.$emit('select', item);
      }
    },
    deleteItem(item) {
      this.$emit(
        'input',
        this.value.filter((x) => x.id !== item.id),
      );
    },
  },
};
</script>

<style lang="scss">
.w-item-picker__list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.list-mode--horizontal {
    display: flex;
    flex-direction: row;
    row-gap: 8px;
  }

  &.list-mode--vertical {
    margin: 0 8px;

    .w-item-picker__item {
      margin: 8px 0;
    }
  }

  &.list-wrap--wrap {
    flex-wrap: wrap;
  }
}
</style>
