function resize(element) {
  // eslint-disable-next-line no-param-reassign
  element.style.height = 'auto';
  // eslint-disable-next-line no-param-reassign
  element.style.height = `${element.scrollHeight}px`;
}

// Automatically resizes a textarea to match its content.
export default {
  bind(element, binding) {
    if (binding.value === false) return;
    // eslint-disable-next-line no-param-reassign
    element.style.resize = 'none';
    // eslint-disable-next-line no-param-reassign
    element.rows = 1;
  },
  inserted(element, binding) {
    if (binding.value === false) return;
    resize(element);
  },
  update(element, binding) {
    if (binding.value === false) return;
    resize(element);
  },
};
