<template>
  <SidebarPanel
    class="fixed h-screen font-sans top-0 bottom-0 z-[11] w-[404px] text-text"
    data-identifier="side-nav-search-panel"
    :title="$t('Search')"
    @close="close"
  >
    <template #default>
      <div class="w-full h-full">
        <div class="flex flex-col w-full h-full">
          <div class="flex flex-col px-6 mt-0 mb-6 flex-none">
            <FilterableSearchInput
              v-model="searchTerm"
              ref="input"
              :filter="searchFilterId"
              :filter-options="filterOptions"
              :placeholder="$t('Search Teamwork')"
              :debounce-timeout="0"
              identifier-prefix="app-nav__search-panel"
              @update:filter="onFilterSelected"
              @enter="onEnterKeyPressedInSearchInput"
            />
          </div>
          <div class="relative flex-1 min-h-0">
            <transition name="fade">
              <template v-if="isSearchPanelOpen && shouldRenderSearchResults">
                <SearchResults
                  class="w-full h-full"
                  :loading="isLoadingSearchResults"
                  :items="quickSearchResultsLoader.state.items.value"
                  :search-term="searchTerm"
                  @item-clicked="onItemClicked"
                  @advanced-search="onAdvancedSearchClicked"
                />
              </template>
              <template v-else>
                <Recents class="w-full h-full" @item-clicked="onItemClicked" />
              </template>
            </transition>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-between select-none w-full p-4">
        <div class="inline-flex items-center">
          <router-link
            :to="advancedSearchLink"
            class="
              transition-colors
              text-default
              font-semibold
              text-text
              hover:underline hover:text-text
              max-w-full
              inline-block
            "
            @click.native="onAdvancedSearchClicked"
            data-identifier="app-nav__search-panel__footer__advanced-search"
          >
            {{ $t('Advanced search') }}
          </router-link>
        </div>
        <div
          class="inline-flex items-center"
          v-if="shouldRenderOnlyCurrentProjectToggle"
        >
          <ToggleSwitch
            class="mr-3"
            v-model="onlyCurrentProject"
            data-identifier="app-nav__search-panel__footer__only-current-project"
          />
          <span
            :class="[
              'transition-colors',
              'text-default',
              onlyCurrentProject ? 'text-text' : 'text-text-secondary',
            ]"
          >
            Only current project
          </span>
        </div>
      </div>
    </template>
  </SidebarPanel>
</template>

<script>
/* eslint-disable no-underscore-dangle, no-use-before-define */

import {
  computed,
  onMounted,
  ref,
  shallowRef,
  Vue2 as Vue,
  watch,
} from 'vue-demi';
import { useDebounce } from '@vueuse/core';
import { isFunction, pick } from 'lodash-es';

import SidebarPanel from '@/platform/components/side-nav/panels/SidebarPanel.vue';
import Recents from '@/platform/components/side-nav/panels/search/Recents.vue';
import SearchResults from '@/platform/components/side-nav/panels/search/SearchResults.vue';
import FilterableSearchInput from '@/platform/components/common/FilterableSearchInput.vue';
import ToggleSwitch from '@/platform/components/common/ToggleSwitch.vue';

import { useCurrentProject } from '@/platform/composables/useCurrentProject';
import { useQuickSearchResultsLoader } from '@/platform/composables/useQuickSearchResultsLoader';
import useStore from '@/platform/composables/useStore';
import useRouter from '@/platform/composables/useRouter';
import { useSidebarPanels } from '@/platform/composables/useSidebarPanels';

const FILTER_ID = Object.freeze({
  EVERYTHING: 'everything',
  TASKS: 'tasks',
  PROJECTS: 'projects',
});

const MIN_CHARS_FOR_SEARCH = 2;
const MAX_CHARS_FOR_SEARCH = 100;

const DEFAULT_FILTER_ID = FILTER_ID.EVERYTHING;

const FILTER = Object.freeze({
  [FILTER_ID.EVERYTHING]: {
    id: FILTER_ID.EVERYTHING,
    entityType: '',
    get label() {
      return Vue.t('Everything');
    },
    icon: 'everything-tw',
  },
  [FILTER_ID.TASKS]: {
    id: FILTER_ID.TASKS,
    entityType: 'tasks',
    get label() {
      return Vue.t('Tasks');
    },
    icon: 'check-circle',
  },
  [FILTER_ID.PROJECTS]: {
    id: FILTER_ID.PROJECTS,
    entityType: 'projects',
    get label() {
      return Vue.t('Projects');
    },
    icon: 'project',
  },
});

export default {
  name: 'SearchPanel',
  components: {
    SidebarPanel,
    Recents,
    FilterableSearchInput,
    SearchResults,
    ToggleSwitch,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const currentProject = useCurrentProject();
    const { hideActivePanel, isSearchPanelOpen } = useSidebarPanels();

    const input = ref(null);

    const searchTerm = shallowRef('');
    const searchFilterId = shallowRef(DEFAULT_FILTER_ID);
    const searchFilter = computed(() => {
      return FILTER[searchFilterId.value] || FILTER[DEFAULT_FILTER_ID];
    });

    const preferenceId = 'searchSelectedProject';
    const onlyCurrentProject = shallowRef(
      store.getters[`preferences/${preferenceId}`],
    );

    // Seems bizarre that the legacy code is utilising user preferences to achieve this but hey-ho
    watch(onlyCurrentProject, (_onlyCurrentProject) => {
      store.dispatch('preferences/change', {
        prefs: {
          [preferenceId]:
            _onlyCurrentProject &&
            currentProject.value &&
            currentProject.value.id &&
            currentProject.value.name
              ? pick(currentProject.value, ['id', 'name'])
              : { id: 0, name: Vue.t('All Projects') },
        },
        scope: 'user',
      });
    });

    const count = ref(-1);
    const searchResultsPageSize = 20;

    const shouldRenderOnlyCurrentProjectToggle = computed(() => {
      return (
        Boolean(currentProject.value) &&
        searchFilterId.value !== FILTER_ID.PROJECTS
      );
    });

    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    watch(debouncedSearchTerm, () => {
      if (canPerformSearch.value) {
        count.value = searchResultsPageSize;
      } else {
        count.value = -1;
      }
    });

    const isSearchTermTooShort = computed(() => {
      return searchTerm.value.length < MIN_CHARS_FOR_SEARCH;
    });

    const isSearchTermTooLong = computed(() => {
      return searchTerm.value.length > MAX_CHARS_FOR_SEARCH;
    });

    const canPerformSearch = computed(() => {
      return !isSearchTermTooShort.value && !isSearchTermTooLong.value;
    });

    function focusInput() {
      if (!(input.value instanceof Vue) || !isFunction(input.value.focus)) {
        return;
      }

      input.value.focus();
    }

    function onFilterSelected(id) {
      searchFilterId.value = FILTER[id] ? id : DEFAULT_FILTER_ID;

      focusInput();

      return false;
    }

    watch(searchFilterId, () => {
      if (searchFilterId.value === FILTER_ID.PROJECTS) {
        onlyCurrentProject.value = false;
      }
    });

    // Current parameter business logic:
    // #1 - Outside any project
    //  - `projectId: 0`
    //  - `searchAllProjects: true`
    // #2 - Inside a project & `Only current project` disabled
    //  - `projectId: 0`
    //  - `searchAllProjects: false`
    // #3 - Inside a project & `Only current project` enabled
    //  - `projectId: ${projectId}`
    //  - `searchAllProjects: false`
    const params = computed(() => {
      return {
        searchTerm: debouncedSearchTerm,
        projectId:
          (currentProject.value &&
            onlyCurrentProject.value &&
            currentProject.value.id) ||
          0,
        searchAllProjects: !currentProject.value,
      };
    });

    const quickSearchResultsLoader = useQuickSearchResultsLoader({
      params,
      count,
      pageSize: searchResultsPageSize,
      entities: computed(() => {
        return searchFilter.value && searchFilter.value.entityType
          ? [searchFilter.value.entityType]
          : [];
      }),
    });

    const timeout = 1000;

    const isSearchPending = ref(false);
    const shouldRenderSearchResults = ref(false);

    let timeoutFn = null;

    // This watcher ensures a smooth transition between the various states of this component
    watch(searchTerm, () => {
      clearTimeout(timeoutFn);

      shouldRenderSearchResults.value = !isSearchTermTooShort.value;

      // No search is pending when raw search query drops below the minimum requirement
      if (!shouldRenderSearchResults.value) {
        isSearchPending.value = false;
        return;
      }

      // When the current raw search query matches the debounced value the request parameters
      // will not change, and therefore no search is pending
      if (searchTerm.value === debouncedSearchTerm.value) {
        isSearchPending.value = false;
        return;
      }

      if (isSearchTermTooLong.value) {
        store.dispatch(
          'notifications/flashes/info',
          Vue.t('Search term is too long'),
        );

        isSearchPending.value = false;
        return;
      }

      isSearchPending.value = true;

      timeoutFn = setTimeout(() => {
        isSearchPending.value = false;
      }, timeout);
    });

    const isLoadingSearchResults = computed(() => {
      return (
        isSearchPending.value ||
        quickSearchResultsLoader.state.loading.value ||
        !quickSearchResultsLoader.state.inSync.value ||
        !!quickSearchResultsLoader.state.error.value
      );
    });

    const advancedSearchLink = computed(() => {
      const query = encodeURIComponent(searchTerm.value);

      return `/search${query ? `/${query}` : ''}`;
    });

    const close = () => {
      hideActivePanel();
    };

    function onAdvancedSearchClicked() {
      close();
    }

    function onEnterKeyPressedInSearchInput() {
      if (isSearchTermTooShort.value) {
        store.dispatch(
          'notifications/flashes/info',
          Vue.t('Search term is too short'),
        );

        return;
      }

      if (isSearchTermTooLong.value) {
        store.dispatch(
          'notifications/flashes/info',
          Vue.t('Search term is too long'),
        );

        return;
      }

      router.push({ path: advancedSearchLink.value });

      onAdvancedSearchClicked();
    }

    function onItemClicked(item, event) {
      if (event.shiftKey || event.ctrlKey || event.metaKey) {
        return;
      }

      close();
    }

    onMounted(() => {
      input.value?.focus();
    });

    return {
      input,
      onlyCurrentProject,
      searchTerm,
      searchFilterId,
      searchFilter,
      filterOptions: Object.values(FILTER),
      close,
      shouldRenderOnlyCurrentProjectToggle,
      shouldRenderSearchResults,
      quickSearchResultsLoader,
      onAdvancedSearchClicked,
      onEnterKeyPressedInSearchInput,
      onFilterSelected,
      advancedSearchLink,
      isLoadingSearchResults,
      isSearchPanelOpen,
      onItemClicked,
    };
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
