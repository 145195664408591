<template>
  <div
    :class="[
      'custom-field-input',
      fromTaskDetails ? 'task-details' : 'table-cell-input',
      { multiline: isMultiline },
    ]"
  >
    <div
      :class="['custom-field-input__placeholder', { disabled }]"
      v-if="!value && !isEditing && !fromTaskDetails"
    >
      <EmptyStateCell left-align class="empty-state-icon" />
      <button
        @click="handleEditing"
        class="empty-button"
        :class="{ 'from-custom-field-modal': fromCustomFieldModal }"
      >
        {{ placeholder || $t('Enter text') }}
      </button>
    </div>
    <div
      v-else
      v-tooltip="{
        boundariesElement: 'body',
        container: tooltipContainer,
        content: textOverflows || isMultiline ? tooltip : undefined,
        html: true,
        placement: tooltipPlacement,
        classes: 'w-tooltip w-tooltip__description',
        'handle-resize': false,
      }"
    >
      <textarea
        v-if="isMultiline"
        ref="input"
        :placeholder="placeholder || $t('Enter text')"
        :value="value"
        @keydown.stop="handleValueChange"
        @blur="handleBlur"
        @focus="handleFocus"
        :tabindex="disabled ? -1 : 0"
        :class="[
          fromTaskDetails ? 'task-details' : 'tasks-table',
          { disabled },
        ]"
        :disabled="disabled"
      />
      <input
        v-else
        type="text"
        ref="input"
        :placeholder="placeholder || $t('Enter text')"
        :value="value"
        @keydown.stop="handleValueChange"
        @blur="handleBlur"
        @focus="handleFocus"
        :tabindex="disabled ? -1 : 0"
        :disabled="disabled"
        :class="[
          fromTaskDetails ? 'task-details' : 'tasks-table',
          { disabled },
        ]"
        @click="handleEditing"
      />
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import EmptyStateCell from '@widgets/EmptyStateCell';
import { stripMarkdown } from '@/utils/helpers/strings';

export default {
  name: 'CustomText',
  display: 'CustomText',
  components: { EmptyStateCell },
  props: {
    value: { type: String, required: false, default: null },
    placeholder: { type: String, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    isMarkdown: { type: Boolean, required: false, default: false },
    isMultiline: { type: Boolean, required: false, default: false },
    tooltipContainer: { type: [Boolean, String], default: 'body' },
    tooltipPlacement: { type: String, default: 'top' },
    fromTaskDetails: { type: Boolean, default: false },
    fromCustomFieldModal: { type: Boolean, default: false },
  },
  data() {
    return {
      isEditing: false,
      isFocused: false,
      textOverflows: false,
    };
  },
  mounted() {
    this.$watch('theValue', () => {
      this.handleTooltip();
    }); // $refs aren't loaded until mounted()
  },
  computed: {
    theValue() {
      if (this.isFocused) {
        return this.value || '';
      }
      return this.isMarkdown
        ? stripMarkdown(this.value) || ''
        : this.value || '';
    },
    tooltip() {
      return marked(this.value);
    },
  },
  methods: {
    save(value) {
      if (this.isMultiline) {
        this.$refs.input.scrollTop = 0;
      }
      if (this.theValue === value) {
        return;
      }
      this.$emit('change', value);
      this.isFocused = false;
      this.isEditing = false;
    },
    handleValueChange(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
        this.$refs.input.blur();
      } else if (event.key === 'Escape') {
        this.save(this.theValue);
        if (this.$refs.input) {
          this.$refs.input.value = this.theValue;
        }
      }
    },
    handleBlur() {
      if (this.$refs.input) {
        this.save(this.$refs.input.value);
      }
      this.isEditing = false;
      this.isFocused = false;
      this.$emit('blur');
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleEditing() {
      if (!this.disabled) {
        this.isEditing = true;
      }
    },
    handleTooltip() {
      const el = this.$refs?.input;
      if (el) {
        if (el.offsetWidth < el.scrollWidth) {
          this.textOverflows = true;
        } else {
          this.textOverflows = false;
        }
      }
    },
  },
  updated() {
    if (this.isEditing) {
      setTimeout(() => {
        if (this.$refs.input) {
          this.$refs.input.focus();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
@import '@tko/src/styles/variables/colors/_branding.colors.scss';

.custom-field-input {
  width: 100%;
  height: 100%;
  display: flex;
  &.table-cell-input {
    align-items: center;
    padding: $padding--medium;
    justify-content: center;
  }
  &.multiline {
    position: relative;
  }

  div {
    width: 100%;
    &.has-tooltip {
      max-height: 34px;
    }

    textarea {
      height: 34px;
      overflow: hidden;
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: rgba(66, 82, 110, 0.36);
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
      }
    }
  }

  input,
  textarea {
    width: 100%;
    padding: 7px 10px;
    border-radius: 4px;
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: transparent;
    resize: none;
    &:focus {
      cursor: text;
    }
    &.tasks-table {
      &:focus,
      &:hover {
        border: 1px solid #f5f7fa;
      }
      &:focus {
        cursor: text;
        background-color: #fff;
      }
      &:not(:focus) {
        color: black;
        // for whatever reason numbers were showing as gray in TasksTableTasks otherwise.
      }
      &:not(:focus)::first-line {
        color: $color--text-tinted-dark;
      }
    }
    &.task-details {
      width: 100%;
      padding: 7px 10px;
      margin-left: -11px;
      &:hover {
        background: #f5f7fa;
      }
      &:focus {
        background: #f5f7fa;
        border: 1px solid #4461d7;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }

  input::-webkit-outer-spin-button, /* Hide Arrows From Input Number*/
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    color: #646d7d;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-state-icon {
      color: $table-cell--blank-slate-color;
    }
    button {
      background: none;
      display: none;
      color: #4461d7;
      border: none;
      text-align: center;
      font-size: 14px;
      border-radius: $border-radius--default;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .empty-button {
      width: 100%;
      height: 100%;
      background-color: $white;
      color: $indigo-30;
      font-size: $font-size--btn;
      &.from-custom-field-modal {
        height: 30px;
        background-color: #f5f7fa;
      }
    }
  }
  &:hover {
    .custom-field-input__placeholder:not(.disabled) {
      .empty-state-icon {
        display: none;
      }
      button {
        display: block;
      }
    }
  }
}
</style>
