/* eslint-disable consistent-return */
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import api from '@/services/api';
import annex from '@/services/vuex-annex';
import { watch, unwatch } from '@/services/vuex-dynamic-watches';
import { access, release, check } from '@/services/vuex-visible-data';
import { debounce } from '@/utils/helpers/promise';
import { STATUS, MUTATIONS, ACTIONS, GETTERS } from './constants';
import SocketNotifications from '@/services/socket-notifications';

// Shorthand for recordMapped getters
const recordMapped = (getter, id) => (id ? getter(id) : getter);

/**
 * A collection of functions providing pipeline functionality
 * for loader response processing.
 *
 * Intended to be composed using FP. They aren't used directly
 * below, but rather merged with the configuration, so they can
 * also be overridden in the loader construction.
 */
export const optional = {
  // Map/Filter expressions
  isDeleted: (record) => record.status && record.status === 'deleted',
  toId: (record) => record.id,
  sort: (a, b) => a.id - b.id, // newest last

  // Processes
  mapToRecords: ({ state }, list) => list.map((id) => state.records[id]),
  mapResponse: (ctx, rs) => {
    const firstNotStatus = Object.keys(rs.data).find((key) => key !== 'STATUS');
    return firstNotStatus && rs.data[firstNotStatus];
  },
  mapToIncluded: (ctx, rs) => {
    const includedKey = Object.keys(rs.data).find((key) => key === 'included');
    return includedKey && rs.data[includedKey];
  },
  // If the loader is flagged for additive results, then the existing
  // list entries need to be merged with these results.
  processAdditive({ state, commit }, list) {
    const fullList = state.additive ? [...(state.list || []), ...list] : list;
    // maybe the same ID got updated, ensure there's no duplicates
    // uniqBy with toString will ensure number and string IDs won't be duplicated
    const uniqList = uniqBy(fullList, (i) => i.toString());
    // once the first non-additive response is received,
    // all further responses are additive unless there
    // is a reset.
    commit(MUTATIONS.ADDITIVE);
    return uniqList;
  },
};

export const base = (cfg) => {
  // eslint-disable-next-line no-param-reassign
  cfg = { ...optional, ...cfg };
  return {
    /**
     * General Public access - whenever a component wants to
     * register access to the data - it will trigger a load if
     * necessary - handles data lifecycle
     */
    [ACTIONS.ACCESS](
      { dispatch, commit, state, id },
      { registerAccess = true } = {},
    ) {
      if (registerAccess && cfg.id) {
        access(cfg.id, id);
      }
      if (!state) {
        // record mapped and record doesn't exist
        commit(MUTATIONS.INIT);
      } else if (
        state.status === STATUS.loading ||
        state.status === STATUS.updating
      ) {
        return annex.promises(state, 'load');
      } else if (state.status === STATUS.loaded) {
        return;
      }
      const stale = state && state.status === STATUS.stale;
      return dispatch(stale ? ACTIONS.LOAD_UPDATES : ACTIONS.LOAD_WAIT);
    },
    /**
     * General Public access - whenever a component wants to
     * release access to the data
     */
    [ACTIONS.RELEASE]({ id }) {
      if (cfg.id) {
        release(cfg.id, id);
      }
    },
    /**
     * Public trigger to indicate data has changed - fired from the notifications service
     * will trigger an update or mark data as stale based on visibility. If the loader
     * is not initialised but the data is visible, this will perform an access. Useful
     * if a list loader loaded the record, but we want to update it with a record loader.
     */
    [ACTIONS.DATA_CHANGE]({ dispatch, commit, getters, id }) {
      const initialised = !recordMapped(getters[GETTERS.UNINIT], id);
      if (!cfg.id) {
        if (initialised) {
          return dispatch(ACTIONS.LOAD_UPDATES);
        }
        return;
      }
      const visible = check(cfg.id, id);
      if (!visible) {
        if (initialised) {
          commit(MUTATIONS.STALE);
        }
        return;
      }
      return initialised
        ? dispatch(ACTIONS.LOAD_UPDATES)
        : dispatch(ACTIONS.ACCESS, { registerAccess: false });
    },
    /**
     * Load action - only place you'll find an Ajax request
     */
    [ACTIONS.LOAD]({
      dispatch,
      commit,
      state,
      getters,
      rootGetters,
      rootState,
      id,
    }) {
      if (!recordMapped(getters[GETTERS.WORKING], id)) {
        commit(MUTATIONS.LOADING);
      }
      dispatch(ACTIONS.BEFORE_LOAD);

      if (cfg.reactiveParams) {
        const action = () => dispatch(ACTIONS.PARAM_CHANGE);
        watch({ state, getter: cfg.params, action, json: true });
      }

      let url;
      if (cfg.url) {
        url = typeof cfg.url === 'string' ? cfg.url : cfg.url(id);
      } else {
        url = recordMapped(getters.url, id);
      }
      const headers = {
        'Triggered-By': SocketNotifications.handlingWebSocketEvent
          ? 'event'
          : 'user',
        'Sent-By': 'vuex',
      };
      const params = { ...cfg.params(rootState, rootGetters), ...state.params };

      const aborter = api.aborter();
      annex.funcs(state, { aborter });
      let success = false;
      const load = api
        .get(url, {
          headers,
          params,
          aborter,
          noErrorHandling: cfg.silentAjaxErrors,
        })
        .then((rs) => {
          success = true; // needed as the finally block can't detect success otherwise
          return dispatch(ACTIONS.LOADED_SUCCESS, rs);
        })
        .catch((err) => dispatch(ACTIONS.LOADED_ERROR, err))
        .finally(() => {
          annex.remove(state, { aborter });
          // If we're on the last load, and we had success, set the status to loaded
          if (annex.count(state, 'load') === 1 && success) {
            commit(MUTATIONS.LOADED);
          }
        });
      return annex.promises(state, { load });
    },
    /**
     * Dispatch a load and return a promise for when all data is loaded.
     */
    [ACTIONS.LOAD_WAIT]({ dispatch, state }) {
      dispatch(ACTIONS.LOAD); // run synchronously, not waiting
      return annex.promises(state, 'load');
    },
    /**
     * Reload - cancel, reset, load
     */
    [ACTIONS.RELOAD]({ dispatch, commit }, { allowConcurrentReloads } = {}) {
      if (!allowConcurrentReloads) {
        dispatch(ACTIONS.LOAD_CANCEL);
      }
      commit(MUTATIONS.INIT);
      return dispatch(ACTIONS.LOAD_WAIT);
    },

    /**
     * Reset - sets the load back to uninitialised and
     * turns off the reactive parameter watch, if it is on
     */
    [ACTIONS.RESET]({ state, commit }) {
      unwatch({ state, getter: cfg.params });
      commit(MUTATIONS.INIT);
    },
    /**
     * Distinguishes a stale data load vs a full load - default to a full reload
     *
     * Each update is queued, but also only runs after the current loads have finished
     *
     * The debounce of 1sec may seem very long, but remember this will trigger on leading
     * edge and the effects of the update will be seen before this debounce returns.
     */
    [ACTIONS.LOAD_UPDATES]: debounce(
      ({ dispatch, commit, state }) =>
        annex.promises(state, 'load').then(() => {
          commit(cfg.updater ? MUTATIONS.UPDATING : MUTATIONS.INIT);
          return dispatch(ACTIONS.LOAD_WAIT);
        }),
      1000,
    ),
    /**
     * Error - triggered for a failed ajax request
     */
    [ACTIONS.LOADED_ERROR]({ commit }, err) {
      let error = err;
      if (api.isAborted(err)) {
        error = new Error('cancelled');
        error.innerError = err;
        error.isAborted = true;
      }

      commit(MUTATIONS.LOADED_ERROR, error);
      // Always rethrow for downstream callbacks or console output
      throw error;
    },
    /**
     * Request parameters have changed (load watch)
     * will trigger a reload or mark data as stale based on visibility.
     */
    [ACTIONS.PARAM_CHANGE]({ dispatch, commit, getters, id }) {
      if (
        recordMapped(getters[GETTERS.WORKING], id) ||
        !cfg.id ||
        !id ||
        check(cfg.id, id)
      ) {
        return dispatch(ACTIONS.RELOAD);
      }
      // rather than stale, wipe it clean, means a full fresh load will trigger for access
      commit(MUTATIONS.INIT);
    },
    /**
     * Success - triggered for successful ajax request, processes response
     */
    [ACTIONS.LOADED_SUCCESS]({ dispatch }, rs) {
      dispatch(ACTIONS.LOADED_HEADERS, rs);

      if (get(rs, 'data.meta.page.count') !== undefined) {
        dispatch(ACTIONS.LOADED_PAGINATION, rs);
      }

      if (cfg.onLoadAction) {
        dispatch('onLoad', rs);
      } else {
        dispatch(
          cfg.listLoader ? ACTIONS.LOADED_LIST : ACTIONS.LOADED_RECORD,
          rs,
        );
      }
      if (cfg.includedConfig) {
        dispatch(ACTIONS.LOADED_INCLUDED, rs);
      }
      return rs;
    },
    /**
     * Process a Record response
     */
    [ACTIONS.LOADED_RECORD](ctx, rs) {
      const record = cfg.mapResponse(ctx, rs);
      ctx.commit(cfg.commit, record, { recordMap: false });
    },
    /**
     * Process a List response
     */
    [ACTIONS.LOADED_LIST](ctx, rs) {
      let records = cfg.mapResponse(ctx, rs);
      ctx.commit(cfg.commit, records, { root: true, recordMap: false });
      const ids = records.map(cfg.toId);
      const list = cfg.processAdditive(ctx, ids);
      const sort = cfg.sort === false ? () => 0 : cfg.sort;

      records = cfg
        .mapToRecords(ctx, list)
        // remove deleted needs to be after merging with existing,
        // as update might return some deleted entries
        .filter((record) => !cfg.isDeleted(record))
        .sort(sort);
      ctx.commit(cfg.listMutation, records.map(cfg.toId), {
        root: cfg.listMutation.includes('/'),
      });
    },
    /**
     * Process an included (side-loading) list response
     */
    [ACTIONS.LOADED_INCLUDED](ctx, rs) {
      const includedLists = cfg.mapToIncluded(ctx, rs);
      Object.entries(cfg.includedConfig).forEach(([key, mod]) => {
        if (includedLists[key]) {
          const includedList = Object.values(includedLists[key]);
          ctx.commit(`${mod}/records`, includedList, {
            root: true,
            recordMap: false,
          });
        }
      });
    },
    /** ******************************************
     * Synchronous Actions - or rather actions
     * that should be maintained as synchronous
     ****************************************** */

    /**
     * Cancel any load in progress (cancels the ajax)
     */
    [ACTIONS.LOAD_CANCEL]({ state }) {
      const aborter = annex.funcs(state, 'aborter');
      if (aborter.length > 0) {
        aborter.forEach((token) => token.abort());
        annex.remove(state, 'load');
        annex.remove(state, 'aborter');
      }
    },
    /**
     * Placeholder for extension - triggered prior to all loads
     */
    [ACTIONS.BEFORE_LOAD]() {},
    /**
     * Stores off X Headers from responses
     */
    [ACTIONS.LOADED_HEADERS]({ commit }, rs) {
      const xHeaders = Object.keys(rs.headers)
        .filter((key) => key.startsWith('x-'))
        .reduce(
          (headers, key) => ({ ...headers, [key.slice(2)]: rs.headers[key] }),
          {},
        );
      commit(MUTATIONS.HEADERS, xHeaders);
    },

    [ACTIONS.LOADED_PAGINATION]({ commit }, rs) {
      const { count, hasMore } = get(rs, 'data.meta.page');
      commit(MUTATIONS.PAGINATION.LOADED, { count, hasMore });
    },
  };
};
