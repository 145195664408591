<template>
  <PopoverMenuItem :route="`/people/${user.id}/boards`" v-close-popover>
    <template #icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="16"
        viewBox="0 0 18 16"
      >
        <path
          d="M15.77.708H2.23c-.864 0-1.563.7-1.563 1.563v11.458c0 .863.7 1.563 1.562 1.563h13.542c.863 0 1.562-.7 1.562-1.563V2.271c0-.863-.7-1.563-1.562-1.563ZM8.22 13.73H2.424a.195.195 0 0 1-.195-.195V3.312h5.99V13.73Zm7.357 0H9.78V3.312h5.99v10.222a.195.195 0 0 1-.196.195Z"
        />
      </svg>
    </template>
    <template #title>
      {{ $t('My Boards') }}
    </template>
  </PopoverMenuItem>
</template>

<script>
import { useCurrentUser } from '@teamwork/use';
import PopoverMenuItem from './PopoverMenuItem.vue';

export default {
  components: {
    PopoverMenuItem,
  },
  setup() {
    const user = useCurrentUser();

    return {
      user,
    };
  },
};
</script>

<style></style>
