/* eslint-disable no-param-reassign */
import Vue from 'vue';
import moment from 'moment';
import flow from 'lodash/flow';
import { MUTATIONS } from '../constants';

const subtract = (mmnt, secs) => mmnt.clone().subtract(secs, 's');
const format = (mmnt) => mmnt.locale('en').utc().format('YYYYMMDDHHmmss');
const subtractAndFmt = flow(subtract, format);

export default {
  [MUTATIONS.HEADERS](state, { lastupdated }) {
    state.lastUpdated = lastupdated;
  },
  [MUTATIONS.UPDATING](state) {
    const lastUpdated = moment(state.lastUpdated, 'YYYY-MM-DDTHH:mm:ssZ');
    Vue.assign(state, {
      showDeleted: 1,
      deletedAfterDate: subtractAndFmt(lastUpdated, 10),
      updatedAfterDate: subtractAndFmt(lastUpdated, 1),
    });
  },
};
