<template>
  <div
    :class="[
      'flex',
      'flex-col',
      'items-center',
      'justify-center',
      'text-center',
      'w-full',
    ]"
  >
    <div class="max-w-md">
      <div
        v-if="$slots.illustration"
        class="flex items-center justify-center mb-6"
      >
        <slot name="illustration" />
      </div>

      <div class="p-0 m-0 text-center">
        <template v-if="$slots.heading">
          <h4
            class="
              p-0
              m-0
              mb-2
              text-h-04
              font-semibold
              text-text
              dark:text-white
            "
          >
            <slot name="heading" />
          </h4>
        </template>

        <template v-if="$slots['line-1']">
          <p class="mb-2 text-default font-normal text-text-secondary">
            <slot name="line-1" />
          </p>
        </template>

        <template v-if="$slots['line-2']">
          <p class="mb-2 text-default font-normal text-text-secondary">
            <slot name="line-2" />
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
// @vue/component
export default {
  name: 'BlankState',
};
</script>
