// The v-resize directive calls the provided callback whenever the element is resized.
// Usage:
// <div v-resize="onResize"> - does nothing
// <div v-resize.content-box="onResize"> - calls onResize on content-box size changes
// <div v-resize.border-box="onResize"> - calls onResize on border-box size changes
// <div v-resize.immediate="onResize"> - calls onResize in the next animation frame
// <div v-resize.content-box.border-box.immediate="onResize"> - all of the above
//
// The onResize function is called with an object containing the following properties:
// - element: Element - The DOM elemement which was resized.

const key = Symbol('v-resize callback key');
function noop() {}

// Batch animation frame callbacks to improve performance.
let animationFrameCallbacks = null;
function processAnimationFrameCallbacks() {
  const callbacks = animationFrameCallbacks;
  animationFrameCallbacks = null;
  callbacks.forEach((callback) => callback());
}
function requestBatchedAnimationFrame(callback) {
  if (animationFrameCallbacks) {
    animationFrameCallbacks.push(callback);
  } else {
    animationFrameCallbacks = [callback];
    requestAnimationFrame(processAnimationFrameCallbacks);
  }
}

function init(el, binding) {
  const element = el;
  const callback = binding.value || noop;
  const contentBox = !!binding.modifiers['content-box'];
  const borderBox = !!binding.modifiers['border-box'];
  const immediate = !!binding.modifiers.immediate;
  const state = {
    callback,
    contentBox,
    borderBox,
    immediate,
    observer: null,
  };
  function onResize() {
    state.callback({ element });
  }
  const observer = new ResizeObserver(onResize);
  state.observer = observer;
  element[key] = state;

  if (contentBox) {
    observer.observe(element, { box: 'content-box' });
  }
  if (borderBox) {
    observer.observe(element, { box: 'border-box' });
  }
  if (immediate) {
    requestBatchedAnimationFrame(onResize);
  }
}

export default {
  bind: init,
  update(el, binding) {
    const element = el;
    const callback = binding.value || noop;
    const contentBox = !!binding.modifiers['content-box'];
    const borderBox = !!binding.modifiers['border-box'];
    const immediate = !!binding.modifiers.immediate;
    const state = element[key];
    if (
      state.contentBox === contentBox &&
      state.borderBox === borderBox &&
      state.immediate === immediate
    ) {
      state.callback = callback;
    } else {
      state.observer.disconnect();
      init(el, binding);
    }
  },
  unbind(el) {
    const element = el;
    const state = element[key];
    state.callback = noop;
    state.observer.disconnect();
    element[key] = null;
  },
};
