import moment from 'moment';
import { v2 as v2fetcher, v3 as v3fetcher, put, post } from '@teamwork/fetcher';
import mapById from '@/utils/helpers/mapById';
import store from '@/store/index';

const defaultTasklistParams = {
  getFiles: 1,
  includeBlockedTasks: true,
  onlyTasksWithTickets: false,
  onlyTasksWithUnreadComments: false,
  include: 'taskListNames,projectNames',
  ignoreStartDates: false,
  includeCompletedTasks: false,
  getSubTasks: false,
  onlyUntaggedTasks: false,
  matchAllTags: true,
  includeCustomFields: true,
  matchAllExcludedTags: false,
  includeAssigneeCompanies: true,
  includeAssigneeTeams: true,
  searchAssignees: true,
  searchCompaniesTeams: true,
  filter: 'anytime',
  createdFilter: 'anytime',
};

const defaultProjectParams = {
  getFiles: 1,
  includeBlockedTasks: true,
  onlyTasksWithTickets: false,
  onlyTasksWithUnreadComments: false,
  include: 'taskListNames,projectNames',
  ignoreStartDates: false,
  includeCompletedTasks: false,
  getSubTasks: false,
  onlyUntaggedTasks: false,
  matchAllTags: true,
  includeCustomFields: true,
  matchAllExcludedTags: false,
  includeAssigneeCompanies: true,
  includeAssigneeTeams: true,
  filter: 'anytime',
  createdFilter: 'anytime',
};

const defaultSubtasksParams = {
  include: 'taskListNames,projectNames',
  includeTasksWithCards: true,
  includeCompletedTasks: false,
  getSubTasks: true,
  filter: 'anytime',
  createdFilter: 'anytime',
  includeCustomFields: true,
};

function defineTaskGetters(task, taskListsById, projectsById) {
  Object.defineProperty(task, 'taskList', {
    get() {
      return taskListsById.get(task.taskListId);
    },
    configurable: true,
    enumerable: true,
  });
  Object.defineProperty(task, 'project', {
    get() {
      return projectsById.get(task.projectId);
    },
    configurable: true,
    enumerable: true,
  });
  return task;
}

function normalizeTask(task, taskListsById, projectsById) {
  const normalizedTask = {
    ...task,
    startDate: moment(task.startDate),
    dueDate: moment(task.dueDate),
    dateCreated: moment(task.dateCreated),
    tags: task.tags, // ensure `tags` exists to make Vue reactivity work
  };

  if (Array.isArray(normalizedTask.customFields)) {
    normalizedTask.customFields.forEach((customField) => {
      normalizedTask[`customField${customField.customFieldId}`] =
        customField.value;
    });
  }

  return defineTaskGetters(normalizedTask, taskListsById, projectsById);
}

const normalize = ({
  data: {
    tasks: rawTasks,
    taskLists,
    projects,
    customFields: rawCustomFields = {},
  },
}) => {
  const taskListsById = mapById(taskLists);
  const projectsById = mapById(projects);
  const tasks = rawTasks.map((task) =>
    normalizeTask(task, taskListsById, projectsById),
  );
  const tasksById = mapById(tasks);
  const customFields = Object.keys(rawCustomFields).map(
    (key) => rawCustomFields[key],
  );
  const customFieldsById = mapById(customFields);
  return {
    tasks,
    tasksById,
    projects,
    projectsById,
    taskLists,
    taskListsById,
    customFields,
    customFieldsById,
  };
};

const normalizeOne = ({ data: { task: rawTask, taskLists, projects } }) => {
  const taskListsById = mapById(taskLists);
  const projectsById = mapById(projects);
  const task = normalizeTask(rawTask, taskListsById, projectsById);
  return {
    task,
    projects,
    projectsById,
    taskLists,
    taskListsById,
  };
};

const fetchAllByTasklist = async (id, params, pagination) =>
  v2fetcher(
    `tasklists/${id}/tasks`,
    { ...defaultTasklistParams, ...params, ...pagination },
    normalize,
  );
const fetchAllByProject = async (id, params, pagination) =>
  v2fetcher(
    `projects/${id}/tasks`,
    { ...defaultProjectParams, ...params, ...pagination },
    normalize,
  );
const fetchSubtasks = async (id, params, pagination) =>
  v2fetcher(
    `tasks/${id}/subtasks`,
    { ...defaultSubtasksParams, ...params, ...pagination },
    normalize,
  );
const fetchSingleTask = (id, params = {}) =>
  v2fetcher(
    `tasks/${id}`,
    { ...defaultTasklistParams, ...params },
    normalizeOne,
  );

function update(task, params) {
  const normalizedTask = {
    ...params,
    'todo-item': {
      content: task.name,
      'responsible-party-id': '',
      tagIds: '',
      'estimated-minutes': task.numEstMins,
      progress: task.progress,
    },
  };

  if (task.startDate && task.startDate.isValid()) {
    normalizedTask['todo-item']['start-date'] =
      task.startDate.format('YYYYMMDD');
  } else if (task.startDate === null) {
    normalizedTask['todo-item']['start-date'] = '';
  }
  if (task.dueDate && task.dueDate.isValid()) {
    normalizedTask['todo-item']['due-date'] = task.dueDate.format('YYYYMMDD');
  } else if (task.dueDate === null) {
    normalizedTask['todo-item']['due-date'] = '';
  }
  if (
    normalizedTask['todo-item']['due-date'] !== '' ||
    normalizedTask['todo-item']['start-date'] !== ''
  ) {
    if (task.numActiveSubTasks > 0 || task.numPredecessors > 0) {
      normalizedTask['todo-item']['push-subtasks'] =
        store.state.preferences.user.taskPushSubTasks;
    }
    if (task.numDependencies > 0) {
      normalizedTask['todo-item']['push-dependents'] =
        store.state.preferences.user.taskPushDependentTasks;
    }
  }
  normalizedTask['todo-item'].priority = task.priority;
  normalizedTask['todo-item'].description = task.description;

  if (task.assignedTo || task.assignedToCompanies || task.assignedToTeams) {
    normalizedTask['todo-item']['responsible-party-id'] = [
      ...(task.assignedTo || []).map((m) => m.id),
      ...(task.assignedToCompanies || []).map((m) => `c${m.companyId}`),
      ...(task.assignedToTeams || []).map((m) => `t${m.teamId}`),
    ].join(',');
  }

  if (task.tags) {
    normalizedTask['todo-item'].tagIds = task.tags.map((x) => x.id).join(',');
  }

  if (task.customFields) {
    normalizedTask['todo-item'].customFields = task.customFields;
  }

  if (task.boardColumn) {
    normalizedTask['todo-item'].columnId = task.boardColumn.id || 0;
  }

  return put(`tasks/${task.id}.json`, normalizedTask);
}

const reorder = (taskId, taskListId, positionAfterTask) =>
  put(`tasks/${taskId}.json`, {
    'todo-item': {
      taskListId,
      positionAfterTask,
    },
  });

function create(task) {
  const normalizedTask = {
    'todo-item': {
      content: task.name,
    },
  };
  return post(`tasklists/${task.taskListId}/tasks.json`, normalizedTask).then(
    (response) => fetchSingleTask(response.data.id),
  );
}

function complete(taskId) {
  if (!taskId) {
    throw new Error('complete: Please provide task id!');
  }
  return put(`tasks/${taskId}/complete.json`).then((response) => response.data);
}

function uncomplete(taskId) {
  if (!taskId) {
    throw new Error('uncomplete: Please provide task id!');
  }
  return put(`tasks/${taskId}/uncomplete.json`).then(
    (response) => response.data,
  );
}

function createRecurringInstance(taskId, date) {
  if (!taskId) {
    throw new Error('create instance: Please provide task id!');
  }
  return post(`tasks/${taskId}/recurring.json`, {
    dueDate: moment(date).format('YYYYMMDD'),
  });
}

function follow(taskId) {
  if (!taskId) {
    throw new Error('uncomplete: Please provide task id!');
  }
  const data = {
    'follow-changes': true,
    'follow-comments': true,
  };
  return put(`tasks/${taskId}/follow.json`, data).then(
    (response) => response.data,
  );
}

function unfollow(taskId) {
  if (!taskId) {
    throw new Error('uncomplete: Please provide task id!');
  }
  const data = {
    'follow-changes': false,
    'follow-comments': false,
  };
  return put(`tasks/${taskId}/unfollow.json`, data).then(
    (response) => response.data,
  );
}

function getLockdown(id) {
  const params = {
    page: 1,
    pageSize: 50,
    include: 'users.companies,companies,teams',
  };
  return v3fetcher(`lockdowns/${id}`, params, (response) => {
    const legacyFormat = {
      data: {
        STATUS: 'OK',
        lockdown: {
          items: [],
        },
      },
    };
    if (response.statusCode >= 400) {
      legacyFormat.data.STATUS = 'ERROR';
      return legacyFormat;
    }
    if (
      response.data.lockdown.grantAccessTo.companies !== undefined &&
      response.data.included.companies !== undefined
    ) {
      response.data.lockdown.grantAccessTo.companies.forEach((companyItem) => {
        const company = response.data.included.companies[companyItem.id];
        legacyFormat.data.lockdown.items.push({
          type: 'company',
          id: company.id,
          name: company.name,
          logo: company.logoUrl,
        });
      });
    }
    if (
      response.data.lockdown.grantAccessTo.users !== undefined &&
      response.data.included.users !== undefined
    ) {
      response.data.lockdown.grantAccessTo.users.forEach((userItem) => {
        const user = response.data.included.users[userItem.id];
        let companyName = '';
        if (response.data.included.companies !== undefined) {
          companyName = response.data.included.companies[user.companyId].name;
        }
        legacyFormat.data.lockdown.items.push({
          type: 'user',
          id: user.id,
          companyName,
          avatar: user.avatarUrl,
          name: `${user.firstName} ${user.lastName}`,
        });
      });
    }
    if (
      response.data.lockdown.grantAccessTo.teams !== undefined &&
      response.data.included.teams !== undefined
    ) {
      response.data.lockdown.grantAccessTo.teams.forEach((teamItem) => {
        const team = response.data.included.teams[teamItem.id];
        legacyFormat.data.lockdown.items.push({
          type: 'team',
          id: team.id,
          name: team.name,
          logo: team.teamLogo,
          logoIcon: team.teamLogoIcon,
          logoColor: team.teamLogoColor,
        });
      });
    }
    return legacyFormat;
  });
}

// eslint-disable-next-line import/prefer-default-export
export default {
  fetchAllByTasklist,
  fetchAllByProject,
  fetchSubtasks,
  fetchSingleTask,
  createRecurringInstance,
  update,
  create,
  complete,
  uncomplete,
  follow,
  unfollow,
  getLockdown,
  reorder,
};
