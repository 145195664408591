import { shallowRef } from 'vue-demi';

/*
 * Adds accessibility navigation to the side nav popovers
 * Side Nav Popover menus are hidden from the user, to manage tabbing through these
 * items we need to implement this with javascript.
 * The parent items tabindex will equal 0, and all the submenu items will have a tabindex of -1
 * On activating the the sub menu inside the popover we set tabindex on the parent menu item to -1 and
 * set tabindex = 0 on the first child menu item and focus on the element.
 * The moveActive function can be used to focus on the next or previous menu item using keyboard events.
 */
export default function useSideNavPopoverKeyboardAccessibility() {
  const popover = shallowRef(null);
  const opened = shallowRef(false);
  const navItem = shallowRef(null);
  const navItemsMenu = shallowRef(null);
  let isKeyboardNavigated = false;
  let menuItems = null;
  let menuItemActiveIndex = -1;
  return {
    navItem,
    navItemsMenu,
    opened,
    popover,
    hidePopoverMenu: () => {
      isKeyboardNavigated = false;
      popover.value.hide();
    },
    showPopoverMenu: (isKeyboardActive = true) => {
      isKeyboardNavigated = isKeyboardActive;
      popover.value.show();
    },
    onHide: () => {
      isKeyboardNavigated = false;
      opened.value = false;
      navItem.value.$el.setAttribute('tabindex', '0');
      menuItemActiveIndex = -1;
      navItem.value.$el.focus();
    },
    onShow: () => {
      opened.value = true;

      setTimeout(() => {
        menuItems = Array.from(
          navItemsMenu.value.$el.querySelectorAll('[role="menuitem"]'),
        );
        if (!menuItems.length) {
          return;
        }
        menuItems.forEach((el) => el.setAttribute('tabindex', '-1'));
        menuItems[0].setAttribute('tabindex', '0');
        navItem.value.$el.setAttribute('tabindex', '-1');
        if (isKeyboardNavigated) {
          menuItems[0].focus();
        }
        menuItemActiveIndex = 0;
      }, 150);
    },
    moveActive(incr) {
      menuItems[menuItemActiveIndex].setAttribute('tabindex', '-1');
      if (menuItemActiveIndex + incr > menuItems.length - 1) {
        menuItemActiveIndex = 0;
      } else if (incr + menuItemActiveIndex < 0) {
        menuItemActiveIndex = menuItems.length - 1;
      } else {
        menuItemActiveIndex += incr;
      }
      menuItems[menuItemActiveIndex].focus();
      menuItems[menuItemActiveIndex].setAttribute('tabindex', '0');
    },
  };
}
