<template>
  <div class="TableViewSection">
    <div
      ref="topElement"
      :style="{
        position: 'relative',
        top: `-${headerHeight}px`,
      }"
    />
    <div
      :class="{
        TableViewSection__header: true,
        'TableViewSection__header--shadow': showHeaderShadow,
      }"
      :style="{
        top: `${headerHeight}px`,
        width: `${tableWidth}px`,
      }"
    >
      <div
        :class="{
          TableViewSection__toggle: true,
          'TableViewSection__toggle--open': open,
        }"
        @click="toggle"
      >
        <CommonIcon id="chevron-down-solid" color="#969bb1" />
      </div>
      <slot name="header" />
    </div>
    <div v-if="open" class="TableViewSection__body">
      <slot />
    </div>
  </div>
</template>

<script>
import { useIntersectionObserver } from '@vueuse/core';
import { computed, defineComponent, onUnmounted, shallowRef } from 'vue-demi';
import CommonIcon from '@teamwork/common-icons/dist/v-common-icon';
import { useTableViewInternal } from './useTableViewInternal';

export default defineComponent({
  name: 'TableViewSection',

  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },

  emits: {
    'update:open': function updateOpen(open) {
      return typeof open === 'boolean';
    },
  },

  components: {
    CommonIcon,
  },

  setup(props, { emit }) {
    const topElement = shallowRef();
    const { headerHeight, sectionCount, tableElement, tableWidth } =
      useTableViewInternal();
    const isIntersecting = shallowRef(true);
    const showHeaderShadow = computed(
      () => props.open && !isIntersecting.value,
    );

    sectionCount.value += 1;
    onUnmounted(() => {
      sectionCount.value -= 1;
    });

    function toggle() {
      emit('update:open', !props.open);
    }

    useIntersectionObserver(
      topElement,
      (entries) => {
        isIntersecting.value = entries[0].isIntersecting;
      },
      {
        root: tableElement,
        threshold: 0,
      },
    );

    return {
      topElement,
      headerHeight,
      tableElement,
      tableWidth,
      showHeaderShadow,
      toggle,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';

.TableViewSection {
  &__header {
    display: flex;
    align-items: center;
    position: sticky;
    left: 0;
    z-index: 50;
    background: $token-ui-white;
    font-size: 18px;
    line-height: 48px;
    font-weight: 600;
    color: #0b0e1f;
    &--shadow {
      box-shadow: 0 8px 20px rgba(11, 14, 31, 0.06);
      border-bottom: 1px solid #f5f7fa;
    }
  }
  &__toggle {
    cursor: pointer;
    margin-right: 8px;
    transition: all 0.3s ease;
    transform: rotate(-90deg);
    &--open {
      transform: rotate(0deg);
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
</style>
