<template>
  <div class="skeleton-header-segment">
    <div v-for="i in count" :key="i" class="skeleton-header-segment__content">
      <Skeleton
        class="skeleton-header-segment__icon"
        width="25"
        height="25"
        radius="50%"
      />
      <Skeleton height="15" width="180" />
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/widgets/Skeleton';

export default {
  name: 'ItemPickerSkeleton',
  props: {
    count: { type: Number, default: 5 },
  },
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
.skeleton-header-segment {
  margin-right: 20px;

  &__content {
    margin-top: 4px;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>
