<template>
  <div class="w-full mb-6">
    <p class="text-[#646d7d] font-bold text-[14px] leading-5 mb-[18px]">
      {{ $t('Resources') }}
    </p>
    <IconListItem
      v-for="resource in resources"
      :key="resource.id"
      :title="resource.title"
      :icon-css="resource.iconCss"
      :description="resource.description"
      :href="resource.href"
      :target="resource.href ? '_blank' : undefined"
      :icon-size="'md'"
      @click="
        $parent.$emit('resourcesNavItemClicked', resource.id, resource.title)
      "
    >
      <template #icon><component :is="resource.icon" /></template>
    </IconListItem>
  </div>
</template>
<script>
import IconListItem from './IconListItem.vue';
import WebinarsIcon from '@/assets/images/features/sidenav/webinarsIcon.svg';
import KnowledgeBaseIcon from '@/assets/images/features/sidenav/knowledgeBaseIcon.svg';
import WhatsNewIcon from '@/assets/images/features/sidenav/whatsNewIcon.svg';
import ContactSupportIcon from '@/assets/images/features/sidenav/contactSupportIcon.svg';
import TeamworkAcademyIcon from '@/assets/images/features/sidenav/teamworkAcademyIcon.svg';

export default {
  name: 'ResourcesListNavItem',
  components: {
    IconListItem,
    WebinarsIcon,
    KnowledgeBaseIcon,
    WhatsNewIcon,
    TeamworkAcademyIcon,
    ContactSupportIcon,
  },
  data() {
    return {
      resources: [
        {
          id: 'webinars',
          title: 'Webinars',
          description: 'In-depth training & walkthroughs',
          icon: 'WebinarsIcon',
          iconCss: 'bg-[#4ECD97]',
        },
        {
          id: 'knowledgeBase',
          title: 'Knowledge Base',
          description: 'Tutorials and Help Docs',
          icon: 'KnowledgeBaseIcon',
          iconCss: 'bg-[#94C7FB]',
        },
        {
          id: 'whatsNew',
          title: 'What’s New',
          description: 'See what’s new in Teamwork',
          icon: 'WhatsNewIcon',
          iconCss: 'bg-[#FFAD8D]',
        },
        {
          id: 'teamworkacademy',
          title: 'Teamwork Academy',
          description: 'Learn all about Teamwork',
          icon: 'TeamworkAcademyIcon',
          href: 'https://academy.teamwork.com/',
          hideRightCaret: false,
          iconCss: 'bg-[#FFE0A3]',
        },
        {
          id: 'contactSupport',
          title: 'Contact Support',
          description: 'Raise a ticket or ask a question',
          icon: 'ContactSupportIcon',
          iconCss: 'bg-[#BBA1FF]',
        },
      ],
    };
  },
};
</script>
