// @vue/component
export default {
  name: 'BlankSlate',
  props: {
    isInPopover: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    msg: {
      type: String,
      default: '',
    },
    msg2: {
      type: String,
      default: '',
    },
    hasActiveFilter: {
      type: Boolean,
      default: false,
    },
    allowClearFilter: {
      type: Boolean,
      default: false,
    },
    clearFilter: {
      type: Function,
      default: () => {},
    },
    itemTextPlural: {
      type: String,
      default: '',
    },
  },
};
