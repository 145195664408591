import Vue from 'vue';

export default (store, router) => {
  router.beforeEach(async (to, from, next) => {
    const { allMeta } = router;
    // Check permissions
    if (allMeta.permission || allMeta.activePage) {
      const perm = allMeta.permission.id || allMeta.permission;
      let isActive = true;
      await store.when(
        (state, getters) =>
          getters['user/isReady'] && getters['account/isFullyReady'],
      );
      // If this is a project route, we update the current project, which will
      // trigger an access to the record, only requesting the data if not loaded.
      // This is only run here because right now TKO handles loading the project
      // generally. This should be handled more centrally in the Vue router in future.
      if (to.params.project) {
        await store.dispatch(
          'project/current',
          parseInt(to.params.project, 10),
        );
        // If a route is a tab that can be disabled, check that it is enabled
        if (allMeta.activePage) {
          isActive =
            store.getters['project/current'].activePages[allMeta.activePage];
        }
      }
      const hasPermission = store.getters[`permissions/${perm}`];
      if (!hasPermission || !isActive) {
        // eslint-disable-next-line no-console
        console.info(`Blocked request is: ${to.path}`, {
          hasPermission,
          isActive,
        });

        if (allMeta.permission.warn !== false) {
          // defaults to true
          store.dispatch(
            'notifications/flashes/warn',
            Vue.t("You don't have permission to view this page") ||
              "You don't have permission to view this page",
          ); // translations may not be loaded yet
        }

        if (allMeta.pageName === 'Reports') {
          router.push('/reports/gallery');
        } else if (allMeta.permission.redirect) {
          router.push(allMeta.permission.redirect);
        } else if (to.params.project) {
          router.push(`/projects/${to.params.project}/overview`);
        } else {
          router.goHome();
        }
        return;
      }
    }

    next();
  });
};
