<template>
  <PopoverMenuItem :route="`/people/${user.id}/tasks`" v-close-popover>
    <template #icon>
      <i class="fa fa-tasks" />
    </template>
    <template #title>
      {{ $t('My Tasks') }}
    </template>
  </PopoverMenuItem>
</template>

<script>
import { useCurrentUser } from '@teamwork/use';
import PopoverMenuItem from './PopoverMenuItem.vue';

export default {
  components: {
    PopoverMenuItem,
  },
  setup() {
    const user = useCurrentUser();

    return {
      user,
    };
  },
};
</script>

<style></style>
