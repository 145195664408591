<template>
  <div
    :class="[
      'custom-field-input',
      fromTaskDetails ? 'task-details' : 'table-cell-input',
    ]"
  >
    <div
      :class="['custom-field-input__placeholder', { disabled }]"
      v-if="value == null && !isEditing && !fromTaskDetails"
    >
      <EmptyStateCell left-align class="empty-state-icon" />
      <button
        @click="handleEditing"
        class="empty-button"
        :class="{ 'from-custom-field-modal': fromCustomFieldModal }"
      >
        {{ $t('Enter number') }}
      </button>
    </div>
    <input
      v-else
      ref="input"
      type="number"
      :min="min"
      :max="max"
      :placeholder="$t('Enter number')"
      :value="value != null ? value : ''"
      @input="handleValueInput"
      @keydown.stop="handleValueChange"
      @blur="handleBlur"
      :tabindex="disabled ? -1 : 0"
      :disabled="disabled"
      :class="[fromTaskDetails ? 'task-details' : 'tasks-table', { disabled }]"
    />
  </div>
</template>

<script>
import EmptyStateCell from '@widgets/EmptyStateCell';

const MAX_SAFE_INTEGER = 2 ** 31 - 1; // 2147483647
const MIN_SAFE_INTEGER = -(2 ** 31); // -2147483648
const MAX_SAFE_POSITIVE_NUMBER_OF_DIGITS = 9;
const MAX_SAFE_NEGATIVE_NUMBER_OF_DIGITS = 10; // Contains "-"

export default {
  name: 'CustomNumber',
  display: 'CustomNumber',
  components: { EmptyStateCell },
  props: {
    value: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    max: {
      type: Number,
      required: false,
      default: MAX_SAFE_INTEGER,
    },
    min: {
      type: Number,
      required: false,
      default: MIN_SAFE_INTEGER,
    },
    fromCustomFieldModal: {
      type: Boolean,
      default: false,
    },
    fromTaskDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    save(value) {
      if (this.value === value) {
        return;
      }
      this.$emit('change', value.length ? Number(value) : undefined);
      this.$refs.input.blur();
      this.isEditing = false;
    },
    handleValueInput(event) {
      this.$emit('input', event.target.value);
      if (event.target.value > MAX_SAFE_INTEGER) {
        this.$refs.input.value = event.target.value.slice(
          0,
          MAX_SAFE_POSITIVE_NUMBER_OF_DIGITS,
        );
      }
      if (event.target.value < MIN_SAFE_INTEGER) {
        this.$refs.input.value = event.target.value.slice(
          0,
          MAX_SAFE_NEGATIVE_NUMBER_OF_DIGITS,
        );
      }
    },
    handleValueChange(event) {
      if (event.key === 'e') {
        event.preventDefault(); // "e" key not allowed for Number Custom Fields
      } else if (event.key === 'Enter' && !event.shiftKey) {
        this.$refs.input.blur();
      } else if (event.key === 'Escape') {
        this.save(this.value);
        if (this.$refs.input) {
          this.$refs.input.value = this.value;
        }
      }
    },
    handleBlur(event) {
      this.save(event.currentTarget.value);
    },
    handleEditing() {
      if (!this.disabled) {
        this.isEditing = true;
      }
    },
  },
  updated() {
    if (this.isEditing) {
      setTimeout(() => {
        if (this.$refs.input) {
          this.$refs.input.focus();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
@import '@tko/src/styles/variables/colors/_branding.colors.scss';

.custom-field-input {
  width: 100%;
  height: 100%;
  display: flex;
  &.table-cell-input {
    align-items: center;
    padding: $padding--medium;
    justify-content: center;
  }

  div {
    width: 100%;
  }

  input {
    width: 100%;
    padding: 7px 10px;
    border-radius: 4px;
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: transparent;
    resize: none;
    &:focus {
      cursor: text;
    }
    &.tasks-table {
      &:focus,
      &:hover {
        border: 1px solid #f5f7fa;
      }
      &:focus {
        cursor: text;
        background-color: #fff;
      }
      &:not(:focus) {
        color: black;
        // for whatever reason numbers were showing as gray in TasksTableTasks otherwise.
      }
      &:not(:focus)::first-line {
        color: $color--text-tinted-dark;
      }
    }
    &.task-details {
      width: 100%;
      padding: 7px 10px;
      margin-left: -11px;
      &:hover {
        background: #f5f7fa;
      }
      &:focus {
        background: #f5f7fa;
        border: 1px solid #4461d7;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }

  input::-webkit-outer-spin-button, /* Hide Arrows From Input Number*/
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    color: #646d7d;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-state-icon {
      color: $table-cell--blank-slate-color;
    }
    button {
      background: none;
      display: none;
      color: #4461d7;
      border: none;
      text-align: center;
      font-size: 14px;
      border-radius: $border-radius--default;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .empty-button {
      width: 100%;
      height: 100%;
      background-color: $white;
      color: $indigo-30;
      font-size: $font-size--btn;
      &.from-custom-field-modal {
        height: 30px;
        background-color: #f5f7fa;
      }
    }
  }
  &:hover {
    .custom-field-input__placeholder:not(.disabled) {
      .empty-state-icon {
        display: none;
      }
      button {
        display: block;
      }
    }
  }
}
</style>
