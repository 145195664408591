import lscache from 'lscache';

export default {
  path: '/planning',
  meta: {
    pageName: 'Planning',
  },
  component: 'planning-page',
  children: [
    {
      path: 'overview',
      meta: {
        pageName: 'Overview',
      },
    },
    {
      path: 'workload',
      component: 'planning-workload',
      meta: {
        permission: {
          id: 'canAccessWorkloadPlannerTab',
          redirect: '/planning/chart',
        },
        noSpacing: true,
        name: 'workload',
        pageName: 'Workload',
        isPlanningPage: true, // temporary TKO hack
        canViewFullScreen: true,
        menuPermission: 'canAccessWorkloadPlannerTab',
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-planning-section', 'workload');
        next();
      },
    },
    {
      path: 'portfolio',
      meta: {
        pageName: 'Portfolio',
      },
    },
    {
      path: 'chart',
      meta: {
        pageName: 'Chart',
      },
    },
    {
      path: 'schedule',
      component: 'planning-schedule',
      props: {
        pageName: 'Schedule',
        name: 'schedule',
      },
      meta: {
        pageName: 'Schedule',
        permission: { id: 'canViewSchedule', redirect: '/planning/chart' },
        hasSecondaryMenu: true, // temporary TKO hack?
        isPlanningPage: true, // temporary TKO hack
      },
      redirect() {
        const scheduleType =
          lscache.get('twProjects-last-schedule-section') || 'projects';
        return `/planning/schedule/${scheduleType}`;
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-planning-section', 'schedule');
        next();
      },
      children: [
        {
          path: 'projects',
          component: 'projects-schedule',
          props: {
            name: 'projects-schedule',
          },
          meta: {
            permission: { id: 'canViewSchedule', redirect: '/planning/chart' },
            pageName: 'Planning',
            displayName: 'Projects',
            pageSection: 'projects',
            filters: 'section-projects-list-filter',
            filterKey: 'projectsList',
            canViewFullScreen: true,
          },
          beforeEnter({ commit }, to, from, next) {
            const { pageSection } = to.meta;
            commit('schedule/type', pageSection);
            lscache.set('twProjects-last-schedule-section', pageSection);
            next();
          },
        },
        {
          path: 'people',
          component: 'people-schedule',
          props: {
            name: 'people-schedule',
          },
          meta: {
            permission: {
              id: 'canAccessSchedulePeopleTab',
              redirect: '/planning/schedule/projects',
            },
            pageName: 'Planning',
            displayName: 'People',
            pageSection: 'people',
            filters: 'section-workload-people-filter',
            filterKey: 'schedulerPeople',
            canViewFullScreen: true,
            menuPermission: 'canAccessSchedulePeopleTab',
          },
          beforeEnter({ commit }, to, from, next) {
            const { pageSection } = to.meta;
            commit('schedule/type', pageSection);
            lscache.set('twProjects-last-schedule-section', pageSection);
            next();
          },
        },
      ],
    },
  ],
};
