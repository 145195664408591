<template>
  <div class="LoadOnScroll" ref="rootElement">
    <slot />
  </div>
</template>
<script>
import { useIntersectionObserver } from '@vueuse/core';
import { defineComponent, shallowRef, toRef, watch } from 'vue-demi';
import useNextCycle from '@/platform/composables/useNextCycle';

export default defineComponent({
  name: 'LoadOnScroll',
  props: {
    hasMore: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: {
    load: null,
  },

  setup(props, { emit }) {
    const hasMore = toRef(props, 'hasMore');
    const loading = toRef(props, 'loading');
    const visible = shallowRef(false);
    const rootElement = shallowRef();

    useIntersectionObserver(
      rootElement,
      (entries) => {
        const isVisible = entries[entries.length - 1].isIntersecting;
        if (isVisible !== visible.value) {
          visible.value = isVisible;
          emit('visible', visible.value);
        }
      },
      { threshold: 0 },
    );

    const { schedule: scheduleLoad } = useNextCycle(() => {
      if (hasMore.value && !loading.value && visible.value) {
        emit('load');
        scheduleLoad();
      }
    });

    watch([hasMore, loading, visible], scheduleLoad);

    return {
      rootElement,
    };
  },
});
</script>
<style lang="scss" scoped>
.LoadOnScroll {
  min-width: 1px;
  min-height: 1px;
}
</style>
