<template>
  <div class="w-full h-full flex justify-center items-center">
    <div :class="maxWidthClass">
      <div
        v-if="$slots.image"
        class="image-slot mb-6 flex justify-center items-center"
      >
        <!-- @slot image -->
        <slot name="image" />
      </div>
      <div
        v-if="$slots.title || title"
        class="text-text text-center text-h-04 font-semibold mb-2"
      >
        <!-- @slot title -->
        <slot name="title">{{ title }}</slot>
      </div>
      <div
        v-if="$slots.subtitle || subtitle"
        class="text-text-tertiary text-center text-base font-semibold mb-2"
      >
        <!-- @slot subtitle -->
        <slot name="subtitle">{{ subtitle }}</slot>
      </div>
      <div
        v-if="$slots.message || message"
        class="text-text text-center text-default"
      >
        <!-- @slot message -->
        <slot name="message">{{ message }}</slot>
      </div>
      <!-- @slot action - Slot for CTA's -->
      <div v-if="$slots.action" class="flex flex-col items-center gap-2 mt-6">
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FEmptyState',
  props: {
    title: {
      type: String,
      default: () => null,
    },
    subtitle: {
      type: String,
      default: () => null,
    },
    message: {
      type: String,
      default: () => null,
    },
    maxWidthClass: {
      type: String,
      default: 'max-w-sm',
    },
  },
};
</script>

<style scoped>
.image-slot img,
.image-slot svg {
  max-height: 180px;
  max-width: 240px;
}
.image-slot img {
  object-fit: contain;
}
</style>
