<template>
  <div :class="view === 'tasks-table' ? 'table-cell-priority' : ''">
    <OptionPicker
      @change="change"
      :open.sync="isPriorityOpen"
      :value="priority"
      :options="options"
      :placeholder="placeholder"
      :disabled="disabled"
      :no-arrow="true"
      :block="view !== 'task-details'"
    >
      <template v-if="view === 'task-details'" #trigger>
        <AddButton
          v-if="!priority"
          :disabled="disabled"
          :active="isPriorityOpen"
        >
          {{ label }}
        </AddButton>
        <div
          v-if="priority"
          :class="['priority-icon-container', { active: isPriorityOpen }]"
        >
          <div :class="['priority-icon', { disabled }]">
            <CommonIcons :color="color" id="priority-solid" />
          </div>
          {{ currentOption.label }}
        </div>
      </template>
    </OptionPicker>
  </div>
</template>

<script>
import Vue from 'vue';
import OptionPicker from '@widgets/OptionPicker';
import CommonIcons from '@teamwork/common-icons/dist/v-icon';
import AddButton from '@widgets/TaskDetailWidgets/AddButton/AddButton';

// @vue/component
export default {
  name: 'Priority',
  display: 'Priority',
  components: {
    OptionPicker,
    CommonIcons,
    AddButton,
  },
  props: {
    priority: { type: String, default: '' },
    task: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: { type: Boolean, default: false },
    view: { type: String, default: 'tasks-table' },
  },
  data() {
    return {
      isPriorityOpen: false,
    };
  },
  methods: {
    change(priority) {
      this.$emit('change', priority.id);
    },
  },
  computed: {
    placeholder() {
      return this.view === 'task-details'
        ? Vue.t('Add priority')
        : Vue.t('None');
    },
    options() {
      return [
        {
          id: '',
          label: Vue.t('None'),
          background: '#E1E6EE',
          color: '#0B0E1F',
        },
        {
          id: 'low',
          label: Vue.t('Low'),
          background: '#4ECD97',
          color: 'white',
        },
        {
          id: 'medium',
          label: Vue.t('Medium'),
          background: ' #FFC63C',
          color: 'white',
        },
        {
          id: 'high',
          label: Vue.t('High'),
          background: '#E12D42',
          color: 'white',
        },
      ];
    },
    currentOption() {
      return this.options.find((o) => o.id === this.priority);
    },
    label() {
      const option = this.currentOption;
      if (!option || (option && !option.id)) {
        return this.placeholder;
      }
      return option.label;
    },
    color() {
      const option = this.currentOption;
      if (!option) {
        return null;
      }
      return option.background;
    },
  },
};
</script>

<style lang="scss" scoped>
$hover-color: #4461d7;

.priority-icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  &.active {
    color: $hover-color;
    .priority-icon {
      border: 1px solid $hover-color;
    }
  }
  &:hover {
    color: $hover-color;

    .priority-icon {
      border: 1px solid $hover-color;
    }
  }
}
.priority-icon {
  width: 24px;
  height: 24px;
  border: 1px solid #e1e6ee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  margin-right: 8px;
  &.disabled {
    cursor: not-allowed;
  }

  svg {
    font-size: 13.5px;
    max-width: initial; // we need this to align it perfectly
  }
}

.table-cell-priority {
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
