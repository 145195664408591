/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */

export const Tags = require('../Tags/Tags').default;
export const Date = require('./Date/Date').default;
export const Priority = require('../Priority/Priority').default;
export const Assignees = require('../../widgets/Assignees').default;
export const TaskName = require('./TaskName/TaskName').default;
export const BoardColumn = require('./BoardColumn/BoardColumn').default;
export const Time = require('./Time/Time').default;
export const EstimateTime = require('./EstimateTime/EstimateTime').default;
export const LoggedTime = require('./LoggedTime/LoggedTime').default;
export const Progress = require('./Progress/Progress').default;
export const Repeats = require('./Repeats/Repeats').default;
export const Privacy = require('./Privacy/Privacy').default;
export const CellText = require('./CellText/CellText').default;
export const Tick = require('./Tick/Tick').default;
export const CustomDate = require('./CustomDate/CustomDate').default;
export const CustomDropDown =
  require('./CustomDropDown/CustomDropDown').default;
export const CustomNumber = require('./CustomNumber/CustomNumber').default;
export const CustomText = require('./CustomText/CustomText').default;
