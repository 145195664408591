"use strict";

/*
    Task Model
*/
var indexOf = [].indexOf;
define(['moment', 'stringHelper', 'knockout', 'knockout-mapping', 'projectModel'], function (moment, strings, ko, mapping, ProjectModel) {
  var Task;

  Task = function () {
    class Task {
      /*  ____       _
                / ___|  ___| |_ _   _ _ __
                \___ \ / _ \ __| | | |  _ \
                 ___) |  __/ |_| |_| | |_) |
                |____/ \___|\__|\__,_| .__/
                                     |_|
       * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */
      constructor(data, taskList, parentTask) {
        /*  _____                 _     _   _                 _ _
                  | ____|_   _____ _ __ | |_  | | | | __ _ _ __   __| | | ___ _ __ ___
                  |  _| \ \ / / _ \  _ \| __| | |_| |/ _  |  _ \ / _  | |/ _ \  __/ __|
                  | |___ \ V /  __/ | | | |_  |  _  | (_| | | | | (_| | |  __/ |  \__ \ - On...
                  |_____| \_/ \___|_| |_|\__| |_| |_|\__,_|_| |_|\__,_|_|\___|_|  |___/
         * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */
        this.OnClickEdit = this.OnClickEdit.bind(this);
        this.OnClickToggleShowSubTasks = this.OnClickToggleShowSubTasks.bind(this);
        this.OnClickComplete = this.OnClickComplete.bind(this);
        this.Delete = this.Delete.bind(this);
        this.Duplicate = this.Duplicate.bind(this);
        this.OnClickEditAssigned = this.OnClickEditAssigned.bind(this);
        this.LogTime = this.LogTime.bind(this);
        this.ToggleTimer = this.ToggleTimer.bind(this);
        this.OnUpdatePriority = this.OnUpdatePriority.bind(this);
        this.OnUpdateProgress = this.OnUpdateProgress.bind(this);
        this.OnUpdateFollowing = this.OnUpdateFollowing.bind(this);
        this.EditInModal = this.EditInModal.bind(this);
        this.OnQuickView = this.OnQuickView.bind(this);
        this.ResetData = this.ResetData.bind(this);
        this.setupComputedsAndObservables = this.setupComputedsAndObservables.bind(this);
        this.ResetRemindersToDefault = this.ResetRemindersToDefault.bind(this);
        this.GetFiles = this.GetFiles.bind(this);
        this.LoadUpdate = this.LoadUpdate.bind(this);
        this.GetAutocompleteProperties = this.GetAutocompleteProperties.bind(this);
        this.DownloadAllAttachments = this.DownloadAllAttachments.bind(this);
        this.RetryAPI = this.RetryAPI.bind(this);
        this.ToggleComplete = this.ToggleComplete.bind(this);
        this.Toggle = this.Toggle.bind(this);
        this.componentName = 'Task';
        this.taskList = taskList != null ? taskList : null;

        if (parentTask != null && parentTask instanceof Task) {
          this.parentTask = parentTask;
        } else {
          if ((data != null ? data.parentTask : void 0) == null) {
            this.parentTask = null;
          }
        }

        if (data == null) {
          data = this.GetNewJSON();
        } // short term Desk S3 malicious code workaround


        if (data.description != null) {
          data.description = data.description.replace(/https:\/\/s3.amazonaws.com\/tw-desk\/i\//gi, "https://tw-desk-files.teamwork.com/i/");
        }

        data.uniqueTaskId = `task-${data.id}-${Date.now()}`;
        data = this.renameData(data);
        data = this.updateProperties(data); // Map the data across

        this.mapping = {
          key: function (data) {
            return ko.unwrap(data.id);
          },
          reminders: {
            create: function (options) {
              var _data, dateTimeUtc; // Because we're excluding dateTimeUtc from the map below, we will need to add it back afterwards
              // Deconstructing it out makes sure we're assigning the value and not the reference


              if (options.data.dateTimeUtc != null) {
                ({
                  dateTimeUtc
                } = options.data);
              } // Exclude dateTimeUtc to keep it is a moment object because it get's deep wrapped in observables which causes errors


              _data = mapping.fromJS(options.data, {
                ignore: ['dateTimeUtc']
              }); // If there's a dateTimeUtc, put it back and make it an observable

              if (dateTimeUtc) {
                _data.dateTimeUtc = ko.observable(dateTimeUtc);
              }

              return _data;
            }
          }
        };
        mapping.fromJS(data, this.mapping, this);
        this.setupComputedsAndObservables();
        return;
      }

      OnClickEdit() {
        var confirmCallback;

        if (!(this.canEdit() && this.status() !== 'completed')) {
          return;
        } // if we are looking at this task in the context of the tasks breakdown feature
        // then we need to open a quickview, and set it to be editing immediately


        if (app.quickView.peek() != null && app.quickView.peek().type === "breakdown") {
          return this.OnQuickView(null, true);
        } // asks for confirmation before losing unsaved changes


        confirmCallback = result => {
          if (result) {
            //user accepts changes will be lost, close all modals
            this.isEditMode(!this.isEditMode());
            return app.ko.postbox.publish("close-all-other-task-forms", this.id());
          }
        };

        $(".hasChanged").confirmCancel({
          callback: confirmCallback
        });
      }

      OnClickToggleShowSubTasks() {
        this.displayPreferences.isDisplayingSubTasksSection(!this.displayPreferences.isDisplayingSubTasksSection());
        app.cache.set(`task-${this.id()}-displaying-subtasks`, this.displayPreferences.isDisplayingSubTasksSection());
      }

      OnClickComplete(data, event) {
        var oldStatus, url;
        Tipped.hideAll();

        if (this.numActiveSubTasks() > 0) {
          this.displayPreferences.isDisplayingSubTasksSection(!this.displayPreferences.isDisplayingSubTasksSection());
          app.cache.set(`task-${this.id()}-displaying-subtasks`, this.displayPreferences.isDisplayingSubTasksSection());
          return;
        }

        if (!(this.canComplete() && this.canStart())) {
          return;
        } // once completed set these to false by default


        this.displayPreferences.isDisplayingSubTasksSection(false);
        this.displayPreferences.isDisplayingDescription(false);
        this.displayPreferences.isDisplayingFiles(false);
        this.displayPreferences.isDisplayingComments(false);
        this.isUpdating(true);
        url = `tasks/${this.id()}/${this.status() === 'completed' ? 'uncomplete' : 'complete'}.json`;
        oldStatus = this.status();
        app.api.put(url).then(result => {
          var affectedTaskIds, eventData, linkedCardId, linkedColumnId, linkedColumnName, ref, ref1, ref2, ref3;

          if (result.response.STATUS === 'OK') {
            affectedTaskIds = (ref = result.response.affectedTaskIds) != null ? ref : "";
            linkedCardId = (ref1 = result.response.linkedCardId) != null ? ref1 : 0;
            linkedColumnId = (ref2 = result.response.linkedColumnId) != null ? ref2 : 0;
            linkedColumnName = (ref3 = result.response.linkedColumnName) != null ? ref3 : '';
            this.isUpdating(false);

            if (oldStatus === "completed") {
              this.status("reopened");
              this.progress(0);
            } else {
              this.completedBy.firstName(app.loggedInUser.firstName());
              this.completedBy.lastName(app.loggedInUser.lastName());
              this.completedBy.avatarUrl(app.loggedInUser.avatarUrl());
              this.completedOn(moment(new Date()));
              this.showWhenComplete(true);
              this.status("completed");

              if ((event != null ? event.ctrlKey : void 0) || (event != null ? event.metaKey : void 0)) {
                app.modal.Show('addOrEditComment', {
                  item: this,
                  projectId: this.projectId(),
                  type: 'task'
                });
              }
            }

            eventData = {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.UPDATED,
              itemId: this.id(),
              projectId: this.projectId(),
              taskListId: this.taskListId(),
              subType: `task-${this.status()}`,
              extraInfo: {
                data: {
                  hasEstimatedTime: this.estimatedMinutes() > 0,
                  hasTags: this.tags().length > 0,
                  affectedTaskIds: affectedTaskIds,
                  cardId: linkedCardId,
                  columnId: linkedColumnId,
                  columnName: linkedColumnName
                }
              }
            };

            if (this.parentTaskId() !== 0) {
              eventData.parentTaskId = this.parentTaskId();
            }

            return app.ko.postbox.publish('allTWEvents', eventData);
          }
        });
      }

      Delete(opts = {}) {
        var affectedTaskIds, deleteTask, linkedCardId, linkedColumnId, linkedColumnName, undo, url;
        Tipped.hideAll();
        affectedTaskIds = "";
        linkedCardId = 0;
        linkedColumnId = 0;
        linkedColumnName = 0; // Setup the ability to user to undo

        undo = () => {
          var options;
          app.flash.Info(app.tl("Restoring") + app.localizationPreferences().ellipsis);
          options = {
            'includeComments': true
          };
          return app.api.put(`trashcan/tasks/${this.id()}/restore.json`, options).then(apiresult => {
            var msg;
            msg = app.tl("Task [_s] has been restored", '"' + app.utility.SafeHTML(ko.unwrap(this.name())) + '"');
            app.flash.Success(msg);
            app.ko.postbox.publish('allTWEvents', {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.UPDATED,
              itemId: this.id(),
              projectId: this.projectId(),
              taskListId: this.taskListId(),
              parentTaskId: this.parentTaskId(),
              subType: 'task-reopened',
              extraInfo: {
                data: {
                  hasEstimatedTime: this.estimatedMinutes() > 0,
                  hasTags: this.tags().length > 0,
                  affectedTaskIds: affectedTaskIds,
                  cardId: linkedCardId,
                  columnId: linkedColumnId,
                  columnName: linkedColumnName
                }
              }
            });
            return this.status('reopened');
          });
        };

        if (this.isRecurring()) {
          deleteTask = opts => {
            var options, url;

            if (opts.wasCancelled) {
              return;
            }

            options = {
              'repeatOptions': {
                'editOption': opts.editRecurringOption.toLowerCase()
              }
            };
            url = app.prefixApi(`tasks/${this.id()}.json`, 'API_PREFIX_V3', 'API_PREFIX_V3');
            return app.api.delete(url, options).then(result => {
              var eventData, ref, ref1;
              affectedTaskIds = (ref = (ref1 = result.response.affectedTaskIds) != null ? ref1.join(',') : void 0) != null ? ref : '';
              app.flash.Success(app.tl("Task [_s] has been deleted", '"' + app.utility.SafeHTML(ko.unwrap(this.name())) + '"'), {
                undo: undo
              });
              eventData = {
                eventTime: new Date(),
                itemType: 'task',
                actionType: app.consts.ACTIONTYPE.DELETED,
                itemId: this.id(),
                projectId: this.projectId(),
                taskListId: this.taskListId(),
                parentTaskId: this.parentTaskId(),
                extraInfo: {
                  data: {
                    hasEstimatedTime: this.estimatedMinutes() > 0,
                    hasTags: this.tags().length > 0,
                    affectedTaskIds: ""
                  }
                }
              };
              return app.ko.postbox.publish('allTWEvents', eventData);
            });
          };

          if (opts.isTemplateTask) {
            deleteTask({
              editRecurringOption: 'all'
            });
          } else {
            app.modal.Show('recurringTasksPrompt', {
              action: 'delete',
              task: this,
              onCloseCallBack: deleteTask
            });
          }
        } else {
          this.isUpdating(true);
          url = app.prefixApi(`tasks/${this.id()}.json`, 'API_PREFIX_V3', 'API_PREFIX_V3');
          return app.api.delete(url).then(result => {
            var eventData, ref, ref1, ref2, ref3, ref4;
            affectedTaskIds = (ref = (ref1 = result.response.affectedTaskIds) != null ? ref1.join(',') : void 0) != null ? ref : '';
            linkedCardId = (ref2 = result.response.affected.linkedCardId) != null ? ref2 : 0;
            linkedColumnId = (ref3 = result.response.affected.linkedColumnId) != null ? ref3 : 0;
            linkedColumnName = (ref4 = result.response.affected.linkedColumnName) != null ? ref4 : 0;
            app.flash.Success(app.tl("Task [_s] has been deleted", '"' + app.utility.SafeHTML(ko.unwrap(this.name())) + '"'), {
              undo: undo
            });
            eventData = {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.DELETED,
              itemId: this.id(),
              projectId: this.projectId(),
              taskListId: this.taskListId(),
              taskStatus: this.status(),
              parentTaskId: this.parentTaskId(),
              extraInfo: {
                data: {
                  hasEstimatedTime: this.estimatedMinutes() > 0,
                  hasTags: this.tags().length > 0,
                  affectedTaskIds: affectedTaskIds,
                  cardId: linkedCardId,
                  columnId: linkedColumnId,
                  columnName: linkedColumnName
                }
              }
            };
            this.status('deleted');
            return app.ko.postbox.publish('allTWEvents', eventData);
          }).finally(() => {
            return this.isUpdating(false);
          });
        }
      }

      Duplicate() {
        app.api.post(`./tasks/${this.id()}/duplicate.json`).then(result => {
          var opts;
          app.flash.Success(app.tl('Task Duplicated'));
          opts = {
            eventTime: new Date(),
            actionType: app.consts.ACTIONTYPE.UPDATED,
            itemType: 'task',
            itemId: this.id(),
            taskListId: parseInt(result.response.taskListId, 10),
            newTaskListId: parseInt(result.response.taskListId, 10),
            oldTaskListId: parseInt(result.response.taskListId, 10),
            moveOrCopyMode: 'copy',
            subType: 'moveOrCopy',
            action: 'copy',
            extraInfo: {
              data: {
                hasEstimatedTime: this.estimatedMinutes() > 0,
                hasTags: this.tags().length > 0,
                affectedTaskIds: ""
              }
            }
          };

          if (this.parentTaskId() > 0) {
            opts.parentTaskId = this.parentTaskId();
          }

          return app.ko.postbox.publish('allTWEvents', opts);
        }).catch(r => {
          return app.flash.Error(app.tl('An error has occured when duplicating this task. Please try again'));
        });
      }

      OnClickEditAssigned() {
        var confirmCallback;

        if (!(this.canEdit() && this.status() !== 'completed')) {
          return false;
        } // if we are looking at this task in the context of the tasks breakdown feature
        // then we need to open a quickview, and set it to be editing immediately


        if (app.quickView.peek() != null && app.quickView.peek().type === "breakdown") {
          return this.OnQuickView(null, true);
        } // asks for confirmation before losing unsaved changes


        confirmCallback = result => {
          if (result) {
            //user accepts changes will be lost, close all modals
            this.isEditMode(!this.isEditMode());
            return app.ko.postbox.publish("close-all-other-task-forms", this.id());
          }
        };

        $(".hasChanged").confirmCancel({
          callback: confirmCallback
        });
      }

      LogTime() {
        var onTimeLogged;

        onTimeLogged = opts => {
          if (opts.wasCancelled) {
            return;
          }

          return this.hasLoggedTime(true);
        };

        Tipped.hideAll();
        return app.modal.Show('addOrEditTimeEntry', {
          task: this,
          projectId: this.projectId(),
          onCloseCallBack: onTimeLogged
        });
      }

      ToggleTimer() {
        var data, filtered, project, ref, ref1, ref2, taskList, url;

        if (!this.canLogTime()) {
          return;
        }

        filtered = app.timers().filter(item => {
          return item.taskId() === this.id();
        });

        if (filtered.length > 0) {
          return filtered[0].Toggle();
        } else {
          taskList = this.taskList != null ? this.taskList : (ref = app.shared.getValue(`viewModel/tasksList/${this.taskListId()}`)) != null ? ref.taskList : void 0;
          project = app.cachedProjects[this.projectId()];
          data = {
            timer: {
              taskId: parseInt(this.id(), 10),
              projectId: parseInt(this.projectId(), 10),
              isBillable: (ref1 = (ref2 = taskList != null ? typeof taskList.isBillable === "function" ? taskList.isBillable() : void 0 : void 0) != null ? ref2 : project != null ? typeof project.isBillable === "function" ? project.isBillable() : void 0 : void 0) != null ? ref1 : false
            }
          };
          url = app.prefixApi("me/timers.json", 'API_PREFIX_V3', 'API_PREFIX_V3');
          return app.api.post(url, data).then(result => {
            var id;
            id = result.response.id;
            return ko.postbox.publish('allTWEvents', {
              eventTime: new Date(),
              actionType: app.consts.ACTIONTYPE.ADDED,
              itemType: 'userTimer',
              itemId: id,
              projectId: this.projectId(),
              taskId: this.id()
            });
          }).catch(err => {
            var ref3, ref4;

            if (err.status != null && err.status === 403 && ((ref3 = err.responseJSON) != null ? (ref4 = ref3.errors) != null ? ref4.length : void 0 : void 0)) {
              if (err.responseJSON.errors[0].detail.includes("timer running")) {
                return app.flash.Error(app.tl("You have a timer running on this task already."));
              }
            }
          });
        }
      }

      OnUpdatePriority(p) {
        var _currentPriority, data, priorityCode;

        if (p === this.priority()) {
          return;
        }

        Tipped.hideAll();
        _currentPriority = this.priority();
        this.priority(p.toLowerCase());

        priorityCode = function () {
          switch (p) {
            case 'none':
              return 0;

            case 'low':
              return 200;

            case 'medium':
              return 100;

            case 'high':
              return 1;

            default:
              return -1;
          }
        }();

        data = {
          'todo-item': {
            priority: priorityCode
          }
        };
        app.api.put(`tasks/${this.id()}.json`, data).then(result => {
          var eventData, linkedCardId, linkedColumnId, linkedColumnName, ref, ref1, ref2;
          linkedCardId = (ref = result.response.linkedCardId) != null ? ref : 0;
          linkedColumnId = (ref1 = result.response.linkedColumnId) != null ? ref1 : 0;
          linkedColumnName = (ref2 = result.response.linkedColumnName) != null ? ref2 : '';

          if (result.response.STATUS === 'OK') {
            eventData = {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.UPDATED,
              itemId: this.id(),
              projectId: this.projectId(),
              taskListId: this.taskListId(),
              extraInfo: {
                data: {
                  cardId: linkedCardId,
                  columnId: linkedColumnId,
                  columnName: linkedColumnName
                }
              }
            };

            if (this.parentTaskId() !== 0) {
              eventData.parentTaskId = this.parentTaskId();
            }

            return app.ko.postbox.publish('allTWEvents', eventData);
          } else {
            return this.priority(_currentPriority);
          }
        }).catch(() => {
          return this.priority(_currentPriority);
        });
      }

      OnUpdateProgress() {
        var data;
        data = {
          'progress': this.progress()
        };
        return app.api.put(`tasks/${this.id()}/progress.json`, data).then(result => {
          var eventData, linkedCardId, linkedColumnId, linkedColumnName, ref, ref1, ref2;
          linkedCardId = (ref = result.response.linkedCardId) != null ? ref : 0;
          linkedColumnId = (ref1 = result.response.linkedColumnId) != null ? ref1 : 0;
          linkedColumnName = (ref2 = result.response.linkedColumnName) != null ? ref2 : '';

          if (result.response.STATUS === 'OK') {
            eventData = {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.UPDATED,
              itemId: this.id(),
              projectId: this.projectId(),
              taskListId: this.taskListId(),
              extraInfo: {
                data: {
                  cardId: linkedCardId,
                  columnId: linkedColumnId,
                  columnName: linkedColumnName
                }
              }
            };

            if (this.parentTaskId() !== 0) {
              eventData.parentTaskId = this.parentTaskId();
            }

            return app.ko.postbox.publish('allTWEvents', eventData);
          }
        });
      }

      OnUpdateFollowing(type) {
        var action, data, followingChanges, followingComments;
        action = type === 'none' || !type ? "unfollow" : "follow";
        followingChanges = type === 'changes' || type === 'all' ? true : false;
        followingComments = type === 'comments' || type === 'all' ? true : false;
        data = {
          'follow-changes': followingChanges,
          'follow-comments': followingComments
        }; // route = if (type is 'none' or !type) then "tasks/#{@id()}/unfollow.json" else "tasks/#{@id()}/follow.json"

        return app.api.put(`tasks/${this.id()}/${action}.json`, data).then(result => {
          // These values are updated immediately rather than waiting on the response from the server
          // It means that we can update button states immediately
          this.userFollowingChanges(followingChanges);
          this.userFollowingComments(followingComments); // When a user chooses to follow or unfollow an item we make the above API request
          // However the task itself hasn't been updated, so when you choose to edit the task
          // your status as a follow won't have changed.
          // An easier solution is to simply insert your user id into the list of change/comment
          // follower ids, but this will ensure that all changes are synced correctly

          return this.LoadUpdate();
        });
      }

      EditInModal(callback) {
        var onCloseCallBack;

        if (!(callback != null && typeof callback === 'function')) {
          callback = function () {};
        }

        onCloseCallBack = opts => {
          if (opts.wasCancelled) {
            app.ko.postbox.publish(`tasklist-${this.taskListId()}-cancel-adding-task`);
          }

          return callback();
        };

        return app.modal.Show('addOrEditTask', {
          task: this,
          mode: this.isProjectTemplate() ? 'template' : void 0,
          onCloseCallBack: onCloseCallBack
        });
      }

      OnQuickView(tab, isEditing, dataIdentifierPrefix) {
        if (!(this.id() > 0)) {
          return;
        }

        return app.quickView.show('task', {
          id: this.id(),
          isSubtask: this.parentTaskId() > 0,
          taskId: this.id(),
          projectId: this.projectId(),
          task: this,
          tab: tab,
          wrapperName: tab != null ? 'task-view' : null,
          title: tab != null ? app.utility.Truncate(`${this.parentTaskId() > 0 ? app.tl('Subtask') : app.tl('Task')}: ` + app.utility.HTMLToString(this.name()), 60) : void 0,
          isQuickView: true,
          isEditing: isEditing,
          dataIdentifierPrefix: dataIdentifierPrefix
        });
      }

      ResetData(description) {
        this.name('');

        if (description != null) {
          this.description(description);
        } else {
          this.description('');
        }

        this.predecessors([]);
        this.progress(0);
        return this.reminders([]);
      }

      setupComputedsAndObservables() {
        var dateType, j, len, ref;

        if (this.isAssignedToMe == null || !ko.isComputed(this.isAssignedToMe)) {
          this.isAssignedToMe = ko.pureComputed(() => {
            var foundTeam, idsAsArray, j, len, ref, ref1, ref2, ref3, tId;

            if (this.responsiblePartyIds() == null) {
              return false;
            }

            idsAsArray = app.utility.ConvertToArray(this.responsiblePartyIds()).map(String);

            if (ref = app.loggedInUser.id().toString(), indexOf.call(idsAsArray, ref) >= 0) {
              return true;
            }

            if (ref1 = `c${app.loggedInUser.companyId()}`, indexOf.call(idsAsArray, ref1) >= 0) {
              return true;
            }

            foundTeam = false;
            ref2 = app.loggedInUser.teamIds();

            for (j = 0, len = ref2.length; j < len; j++) {
              tId = ref2[j];

              if (ref3 = `t${tId}`, indexOf.call(idsAsArray, ref3) >= 0) {
                foundTeam = true;
              }
            }

            if (foundTeam) {
              return true;
            }

            return false;
          });
        } // Extended data


        if (this.isEditMode == null || !ko.isObservable(this.isEditMode)) {
          this.isEditMode = ko.observable(this.id() === 0);
        }

        if (this.showMoreOptions == null || !ko.isObservable(this.showMoreOptions)) {
          this.showMoreOptions = ko.observable(this.id() > 0);
        }

        if (this.isSelected == null || !ko.isObservable(this.isSelected)) {
          this.isSelected = ko.observable(false);
        }

        if (this.isUpdating == null || !ko.isObservable(this.isUpdating)) {
          this.isUpdating = ko.observable(this.id() < 0);
        }

        if (this.isTemporary == null) {
          this.isTemporary = ko.pureComputed(() => {
            if (this.id() / 1 === 0) {
              return true;
            }

            if (isNaN(this.id() / 1)) {
              return true;
            }

            return false;
          });
        }

        if (this.displayPreferences == null) {
          this.displayPreferences = {
            isDisplayingSubTasksSection: ko.observable(app.cache.get(`task-${this.id()}-displaying-subtasks`) != null && this.numActiveSubTasks() > 0 ? app.cache.get(`task-${this.id()}-displaying-subtasks`) : false),
            isDisplayingDescription: ko.observable(false),
            isDisplayingFiles: ko.observable(false),
            isDisplayingComments: ko.observable(false)
          };
        }

        if (this.activeSubTasksTipped == null || !ko.isComputed(this.activeSubTasksTipped)) {
          this.activeSubTasksTipped = ko.pureComputed(() => {
            var string;

            if (this.numActiveSubTasks() === 0) {
              return '';
            }

            string = `${this.numActiveSubTasks()} `;
            string += this.numActiveSubTasks() > 1 ? app.tl('subtasks') : app.tl('subtask');
            string += ` ${app.tl('incomplete')} `;
            return string;
          });
        }

        if (this.canStart == null || !ko.isComputed(this.canStart)) {
          this.canStart = ko.pureComputed(() => {
            if (this.numPredecessors() > 0 && this.numPredecessors() > this.numActiveSubTasks()) {
              return false;
            }

            return true;
          });
        }

        ref = ['start', 'due']; // computeds

        for (j = 0, len = ref.length; j < len; j++) {
          dateType = ref[j];

          (dateType => {
            if (this[`${dateType}DateAsMoment`] == null || !ko.isComputed(this[`${dateType}DateAsMoment`])) {
              return this[`${dateType}DateAsMoment`] = ko.pureComputed({
                read: () => {
                  // Return the startDate string as a moment
                  var sdt;
                  sdt = ko.unwrap(this[`${dateType}Date`]);

                  if (sdt == null) {
                    return null;
                  }

                  if (sdt.length !== 8) {
                    return null;
                  }

                  return moment(sdt, 'YYYYMMDD');
                },
                write: value => {
                  // Update the startDate string from the moment value
                  var sd;

                  if (!moment.isMoment(value)) {
                    sd = "";
                  } else {
                    sd = value.format('YYYYMMDD');
                  }

                  this[`${dateType}Date`](sd);
                },
                owner: this
              });
            }
          })(dateType);
        }

        if (this.nameWithLinks == null || !ko.isComputed(this.nameWithLinks)) {
          this.nameWithLinks = ko.pureComputed(() => {
            return this.nameWithLinksFN();
          });
        }

        if (this.projectId() === 0 || isNaN(this.projectId())) {
          this.project = ko.observable(null);
        } else {
          this.project = app.projectInfo.get(this.projectId());
        }

        this.isProjectTemplate = ko.pureComputed(() => {
          var ref1;
          return (ref1 = this.project()) != null ? ref1.isTemplate() : void 0;
        }); // If this is a project template set canEdit according to template permissions

        if (this.isProjectTemplate()) {
          this.canEdit(app.loggedInUser.permissions.canManageProjectTemplates() || app.loggedInUser.permissions.administrator());
        }

        this.canViewMessagesAndFiles = ko.pureComputed(() => {
          return this.project() != null && this.project().permissions.viewMessagesAndFiles() && this.project().activePages.files().toString() === "1";
        });

        if (this.harvestProjectData == null || !ko.isComputed(this.harvestProjectData)) {
          this.harvestProjectData = ko.pureComputed(() => {
            var projectName, ref1;
            projectName = this.projectName() || ((ref1 = app.currentRoute().project) != null ? typeof ref1.name === "function" ? ref1.name() : void 0 : void 0) || '';
            projectName = projectName.replace(/"/g, " ").replace(/'{3,}/g, " ").replace(/\s{2,}/g, " ");
            return `{"id":"` + this.projectId() + `","name":"` + projectName + `"}`;
          });
        }

        if (this.harvestItemData == null || !ko.isComputed(this.harvestItemData)) {
          this.harvestItemData = ko.pureComputed(() => {
            var taskName;
            taskName = this.name().replace(/"/g, " ").replace(/'{3,}/g, " ").replace(/\s{2,}/g, " ");
            return `{"id":"` + this.id() + `","name":"` + taskName + `"}`;
          });
        }

        if (this.responsibleSummaryFormatted == null || !ko.isComputed(this.responsibleSummaryFormatted)) {
          return this.responsibleSummaryFormatted = ko.pureComputed(() => {
            var assigneesAsArray, hasIds, hasNames, numberOfAssignees, ref1, ref2, remainingCount, str, threshold;
            hasIds = (typeof this.responsiblePartyIds === "function" ? (ref1 = this.responsiblePartyIds()) != null ? ref1.length : void 0 : void 0) > 0 && Array.isArray(this.responsiblePartyIds()) && this.responsiblePartyIds().toString() !== '0';
            hasNames = ((ref2 = ko.unwrap(this.responsiblePartyNames)) != null ? ref2.length : void 0) > 0;

            if (hasIds && hasNames) {
              threshold = 50;
              assigneesAsArray = ko.unwrap(this.responsiblePartyNames).toString().split('|');
              numberOfAssignees = assigneesAsArray.length;

              if (numberOfAssignees > threshold) {
                remainingCount = numberOfAssignees - threshold;
                str = app.tl('[_s] and [_s] more', assigneesAsArray.splice(0, threshold).join(', '), remainingCount);
              } else {
                str = assigneesAsArray.join(', ');
              }
            } else {
              str = app.tl('Unassigned');
            }

            if (!ko.unwrap(this.canEdit)) {
              str += "\n";
              str += app.tl('Editing not allowed');
            }

            return str;
          });
        }
      }

      UpdateData(task) {
        if (task == null) {
          return;
        }

        this.isUpdating(true); // overwrite incoming projectName and companyName values

        if (task.projectName != null && task.projectName === '') {
          if (this.projectName != null && ko.unwrap(this.projectName !== '')) {
            task.projectName = ko.unwrap(this.projectName);
          }
        }

        if (task.companyName != null && task.companyName === '') {
          if (this.companyName != null && ko.unwrap(this.companyName !== '')) {
            task.companyName = ko.unwrap(this.companyName);
          }
        }

        if (task.taskListName != null && task.taskListName === '') {
          if (this.taskListName != null && ko.unwrap(this.taskListName !== '')) {
            task.taskListName = ko.unwrap(this.taskListName);
          }
        }

        if ((typeof this.reminders === "function" ? this.reminders().length : void 0) > 0) {
          task.reminders = ko.unwrap(this.reminders);
        }

        if (this.remindersLoaded != null) {
          task.remindersLoaded = ko.unwrap(this.remindersLoaded);
        }

        if (this.visible != null) {
          task.visible = ko.unwrap(this.visible);
        }

        if (this.showWhenComplete != null) {
          task.showWhenComplete = ko.unwrap(this.showWhenComplete);
        }

        task = this.renameData(task);
        task = this.updateProperties(task);
        mapping.fromJS(task, {}, this);
        this.setupComputedsAndObservables();

        if (this.numActiveSubTasks() === 0) {
          this.displayPreferences.isDisplayingSubTasksSection(false);
          app.cache.set(`task-${this.id()}-displaying-subtasks`, false);
        }

        this.isUpdating(false);

        if (task.isPrivate != null && typeof task.isPrivate === 'string') {
          if (task.isPrivate === 'inherited') {
            return this.private(2);
          } else if (task.isPrivate === 'true') {
            return this.private(1);
          } else {
            return this.private(0);
          }
        }
      }

      ResetRemindersToDefault(reminders, date) {
        var dateMoment, hoursPart, j, len, minutesPart, r, timeParts;

        for (j = 0, len = reminders.length; j < len; j++) {
          r = reminders[j]; // This block takes a date and adds the relative number of days set for the reminder
          // to calaculate the new reminders due date. It then takes this date and aplies the time set for the reminder to the date.

          dateMoment = ko.observable(moment(date));
          dateMoment(dateMoment().add(ko.unwrap(r.relativeNumberOfDays) * -1, 'days'));
          timeParts = ko.unwrap(r.time).split(':', 2);
          hoursPart = timeParts[0];
          minutesPart = timeParts[1];
          dateMoment(dateMoment().set({
            hour: hoursPart,
            minute: minutesPart
          }));
          r.dateTimeUtc = dateMoment;
          r.fromTaskListDefault = true;
        }

        this.reminders(reminders);
      }

      GetFiles(showLoader = true) {
        if (this.id() === 0) {
          return;
        }

        this.isUpdating(showLoader);
        return app.api.get(`v/2/tasks/${this.id()}/files.json`).then(result => {
          this.attachments = ko.observableArray([]);
          mapping.fromJS(result.response.files, {}, this.attachments);
          return this.isUpdating(false);
        });
      }

      EditColumn() {
        if (!this.canEdit()) {
          return;
        }

        return app.modal.Show('changeBoardColumn', {
          boardId: 0,
          boardType: 'task',
          item: this
        });
      }

      LoadUpdate(showLoader = true) {
        var params, url;

        if (this.id() === 0) {
          return;
        }

        this.isUpdating(showLoader);
        params = {
          getFiles: 1,
          getSubTasks: 0,
          includeLoggedTime: true,
          include: 'taskListNames,projectNames',
          includeCustomFields: true
        };
        url = app.prefixApi(`tasks/${this.id()}.json`, 'API_PREFIX_V2', 'API_PREFIX_V2');
        return app.api.get(url, params).then(result => {
          var task;
          task = result.response.task;
          task.taskListName = result.response.taskLists[0].name;
          task.projectName = result.response.projects[0].name;
          task.companyName = result.response.projects[0].companyName;

          if (result.response.customFields != null) {
            task.customFields = app.customFieldsHelper.normalizeFields(result.response.customFields, task.customFields);
          }

          return this.UpdateData(task);
        });
      }

      nameWithLinksFN() {
        return String(this.name()).replace(/\"/g, '&quot;').replace(/\'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(this.nwlExp, '<a href=\'$1\' style=\'word-break: break-word\' target=_blank class=blue>$1</a>');
      }
      /* __        __         _
                 \ \      / /__  _ __| | _____ _ __ ___
                  \ \ /\ / / _ \|  __| |/ / _ \  __/ __|
                   \ V  V / (_) | |  |   <  __/ |  \__ \
                    \_/\_/ \___/|_|  |_|\_\___|_|  |___/
       * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */
      // newData is used when setting up a new user


      GetNewJSON() {
        var _base, _data, _defaults;

        _data = {
          'id': 0,
          'cardId': 0,
          'name': "",
          'canComplete': true,
          'description': "",
          'order': 2000,
          'private': 0,
          'taskListId': 0,
          'tags': [],
          'has-predecessors': 0,
          'dueDate': "",
          'dueDateFromMilestone': false,
          'userFollowingChanges': false,
          'progress': "0",
          'priority': "",
          'timeIsLogged': '0',
          'hasLoggedTime': false,
          'hasFollowers': false,
          'lockdownId': "",
          'status': 'new',
          'startDate': '',
          'predecessors': [],
          'canEdit': false,
          'completed': false,
          'attachments-count': 0,
          'comments-count': 0,
          'company-id': 0,
          'creator-id': app.loggedInUser.id(),
          'lockdown-id': 0,
          'position': 1000000,
          'projectId': 0,
          'parentTaskId': 0,
          'numEstMins': 0,
          'estimated-minutes': 0,
          'responsiblePartyIds': [],
          'responsiblePartySummary': 'Anyone',
          'hasReminders': false,
          'canLogTime': false,
          'userFollowingComments': false,
          'hasTickets': false,
          'customFields': [],
          'boardColumn': {
            'id': 0,
            'name': "",
            'color': ""
          }
        }; // if parent task is supplied, set parent task id
        // also set privacy and taskListId values

        _base = strings.camelize(_data);
        _defaults = {};

        if (this.taskList !== null) {
          _defaults = app.ko.mapping.toJS(this.taskList.newTaskDefaults); // ensure description is blank
          // if _defaults.description? and _defaults.description isnt ''
          //     _defaults.description = ''

          if (this.parentTask !== null && this.taskList.id() === 0) {
            _base.taskListId = ko.unwrap(this.parentTask.taskListId);
          } else {
            _base.taskListId = ko.unwrap(this.taskList.id);
          }

          if (parseInt(_defaults.startDateOffset, 10) >= 0) {
            _base.startDate = moment().add(_defaults.startDateOffset, 'days').format('YYYYMMDD');
          }

          if (parseInt(_defaults.dueDateOffset, 10) >= 0) {
            _base.dueDate = moment().add(_defaults.dueDateOffset, 'days').format('YYYYMMDD');
          }

          _base.numEstMins = _defaults.estimatedMinutes;
          _base.estimatedMinutes = _defaults.estimatedMinutes;
          _base.projectId = ko.unwrap(this.taskList.projectId);

          if (ko.unwrap(this.taskList.isPrivate) === 1) {
            _base.private = 2;
            _defaults.private = 2;
          }
        }

        jQuery.extend(_base, _defaults);

        if (this.parentTask !== null) {
          _base.parentTaskId = this.parentTask.id();
          _base.projectId = this.parentTask.projectId();
          _base.taskListId = this.parentTask.taskListId();
          _base.private = this.parentTask.private();
          _base.priority = this.parentTask.priority();
          _base.startDate = this.parentTask.startDate();
          _base.dueDate = this.parentTask.dueDate();
          _base.responsiblePartyIds = this.parentTask.responsiblePartyIds();
          _base.responsiblePartySummary = this.parentTask.responsiblePartySummary();
        } // Disabling this for the moment
        // _base.boardColumn = jQuery.extend {}, @parentTask.boardColumn


        return _base;
      } // renames


      renameData(data) {
        if (data.content != null && data.name == null) {
          data.name = data.content;
          data.content = void 0;
        }

        if (data.description == null) {
          data.description = '';
        }

        if (data['todoListId'] != null && !data['taskListId']) {
          data.taskListId = data.todoListId;
          data.todoListId = void 0;
        }

        if (data.creatorFirstName != null || data.creatorFirstname != null) {
          // capitalisation in the API is all over the place...
          // use latest format (GO API createdBy object)
          data.createdBy = {
            id: data.creatorId,
            firstName: data.creatorFirstName ? data.creatorFirstName : data.creatorFirstname,
            lastName: data.creatorLastName ? data.creatorLastName : data.creatorLastname,
            avatarUrl: data.creatorAvatarUrl
          };
          delete data.creatorId;
          delete data.creatorFirstName;
          delete data.creatorLastName;
          delete data.creatorFirstname;
          delete data.creatorLastname;
          delete data.creatorAvatarUrl;
        }

        if (data.completer_id != null || data.completerId != null) {
          // tasks/:id.json returns completer_id, completedTasks.json returns completerId
          // and new GO API calls return completedBy object, so let's use the latest scheme
          data.completedBy = {
            id: data.completer_id ? data.completer_id : data.completerId,
            firstName: data.completerFirstName,
            lastName: data.completerLastName,
            avatarUrl: data.completerAvatarUrl
          };

          if (data.completedOn == null) {
            data.completedOn = data.completed_on;
          } // remove dupes


          delete data.completer_id;
          delete data.completerId;
          delete data.completerFirstName;
          delete data.completerLastName;
          delete data.completerAvatarUrl;
          delete data.completed_on;
        }

        if (data.completedBy == null) {
          data.completedBy = {
            id: 0,
            firstName: "",
            lastName: "",
            avatarUrl: ""
          };
        }

        if (data.completedOn == null) {
          data.completedOn = null;
        }

        if (data['followingChanges'] != null && data['userFollowingChanges'] == null) {
          data.userFollowingChanges = data.followingChanges;
          delete data.followingChanges;
        }

        if (data['followingComments'] != null && data['userFollowingComments'] == null) {
          data.userFollowingComments = data.followingComments;
          delete data.followingComments;
        }

        return data;
      }

      updateProperties(data) {
        var attachment, base, base1, base2, base3, base4, j, len, ref, ref1, ref2;
        data.id = parseInt(data.id, 10);

        if (data.isActive == null) {
          data.isActive = true;
        }

        if (data.isArchived == null) {
          data.isArchived = false;
        }

        if (data.canEdit == null) {
          data.canEdit = false;
        }

        data.projectId = parseInt(data.projectId, 10);
        data.order = parseInt(data.order, 10);

        if (data.numComments != null) {
          data.commentsCount = parseInt(data.numComments, 10);
        } else {
          data.commentsCount = parseInt(data.commentsCount, 10);
        }

        if (data.private != null) {
          data.private = parseInt(data.private, 10);
        } else {
          data.private = 0;
        }

        data.taskListId = parseInt(data.taskListId, 10);
        data.hasDependencies = parseInt(data.hasDependencies, 10);
        data.hasPredecessors = parseInt(data.hasPredecessors, 10);
        data.position = parseInt(data.position, 10);
        data.estimatedMinutes = parseInt(data.numEstMins, 10);
        data.attachmentsCount = parseInt(data.numAttachments, 10);
        data.progress = parseInt(data.progress, 10);
        data.parentTaskId = data.parentTaskId === '' ? -1 : parseInt(data.parentTaskId, 10);
        data.companyId = parseInt(data.companyId, 10);
        data.timeIsLogged = parseInt(data.timeIsLogged, 10);
        data.lockdownId = parseInt(data.lockdownId, 10);

        if (data.failedAPIAttempt == null) {
          data.failedAPIAttempt = null;
        }

        if (data.isRetryingFailedAPIAttempt == null) {
          data.isRetryingFailedAPIAttempt = false;
        }

        if (data.boardColumn == null) {
          data.boardColumn = {
            id: 0,
            name: "",
            color: ""
          };
        }

        if (data.cardId == null) {
          data.cardId = 0;
        } else {
          data.cardId = parseInt(data.cardId, 10);
        }

        if (data.reminders == null) {
          data.reminders = [];
        }

        if (data.remindersLoaded == null) {
          data.remindersLoaded = false;
        }

        if (data.startDate == null) {
          data.startDate = '';
        }

        if (data.dueDate == null) {
          data.dueDate = '';
        }

        if (data.priority == null) {
          data.priority = '';
        }

        if (data.changeFollowerIds != null && typeof data.changeFollowerIds === 'string') {
          if (data.changeFollowerIds === '') {
            data.changeFollowerIds = [];
          } else {
            data.changeFollowerIds = data.changeFollowerIds.split(',');
          }
        }

        if (data.commentFollowerIds != null && typeof data.commentFollowerIds === 'string') {
          if (data.commentFollowerIds === '') {
            data.commentFollowerIds = [];
          } else {
            data.commentFollowerIds = data.commentFollowerIds.split(',');
          }
        } // Need createdBy.id default for new task


        if (!data.createdBy) {
          data.createdBy = {
            id: 0,
            firstName: '',
            lastName: ''
          };
        }

        app.utility.PopulateDynamicFields(data);

        if (!data.responsiblePartyIds.length) {
          data.responsiblePartyIds = ['0'];
        }

        if (data.isPrivate != null && typeof data.isPrivate === 'boolean' && data.isPrivate) {
          data.private = 1;
        }

        if (data.privacyIsInherited != null && data.privacyIsInherited) {
          data.private = 2;
        } // isPrivate is used by comments widgets
        // when passed in as item, isPrivate is used to determine whether or not
        // privacy picker is displayed on the UI


        if (data.isPrivate == null) {
          data.isPrivate = data.private > 0;
        }

        if (data.hasLoggedTime == null && data.timeIsLogged != null) {
          data.hasLoggedTime = data.timeIsLogged === 1;
        }

        if (data.defaultsTaskId == null) {
          data.defaultsTaskId = 0;
        }

        if (data.isPrivate != null && typeof data.isPrivate === 'string') {
          if (data.isPrivate === 'inherited') {
            data.private = 2;
          } else if (data.isPrivate === 'true') {
            data.private = 1;
          } else {
            data.private = 0;
          }
        }

        if (data.status == null) {
          data.status = data.completed ? 'completed' : 'new';
        }

        if (data.showWhenComplete == null) {
          data.showWhenComplete = data.status !== 'completed';
        }

        if (isNaN(data.estimatedMinutes)) {
          data.estimatedMinutes = 0;
        }

        if (data.numActiveSubTasks == null) {
          data.numActiveSubTasks = 0;
        }

        if (data.numCompletedSubTasks == null) {
          data.numCompletedSubTasks = 0;
        }

        if (data.projectName == null) {
          data.projectName = '';
        }

        if (data.companyName == null) {
          data.companyName = '';
        }

        if (data.todoListName != null) {
          data.taskListName = data.todoListName;
        }

        if (data.taskListName == null) {
          data.taskListName = '';
        }

        if (data.taskListDisplayOrder == null) {
          data.taskListDisplayOrder = 4000;
        } // if responsible summary isn't returned
        // set it to 'Anyone' by default


        if (data.responsiblePartySummary == null) {
          data.responsiblePartySummary = app.tl('Anyone');
        } // set hasUnreadComments


        data.hasUnreadComments = data.numCommentsRead < data.numComments;

        if (data.tags == null) {
          data.tags = [];
        }

        if (data.canStart == null) {
          data.canStart = true;
        }

        if (data.canComplete == null) {
          data.canComplete = true;
        }

        if (data.predecessors == null) {
          data.predecessors = [];
        }

        if (data.numDependents == null) {
          data.numDependents = data.numDependencies != null ? data.numDependencies : 0;
        }

        if (data.numPredecessors == null) {
          data.numPredecessors = data.predecessors != null ? data.predecessors.length : 0;
        } // Conversions


        data.responsiblePartyIds = app.utility.ConvertToArray(data.responsiblePartyIds);
        console.assert(typeof data.responsiblePartyIds === "object"); // Fix some bad data in the recurring sequence
        // Set default properties on different sub-properties of @recurring
        // so that the UI can be more reactive when the API updates this model

        if (data.recurring == null) {
          data.recurring = {};
        }

        data.isRecurring = Object.keys(data.recurring).length > 0 && data.recurring.frequency !== 'norepeat';

        if ((base = data.recurring).endOn == null) {
          base.endOn = '';
        }

        if ((base1 = data.recurring).frequency == null) {
          base1.frequency = 'norepeat';
        }

        if ((base2 = data.recurring).days == null) {
          base2.days = [];
        }

        if ((base3 = data.recurring).monthlyOn == null) {
          base3.monthlyOn = 'monthDay';
        }

        if ((base4 = data.recurring).endDateOption == null) {
          base4.endDateOption = data.recurring.endOn.length ? 'ends' : 'noEndDate';
        }

        if (typeof data.recurring.days === 'string') {
          data.recurring.days = data.recurring.days.split(',');
        }

        if (data.availablePeople == null) {
          data.availablePeople = [];
        } // Contrived Data
        // data.isAssignedToMe = data.responsiblePartyIds.indexOf(app.loggedInUser.id()) > -1


        if (data.visible == null) {
          data.visible = true;
        } //make attached comments files checked by default, set failover props, get cached CatName


        if (data.attachmentsCount > 0 && data['attachments'] != null) {
          ref = data['attachments'];

          for (j = 0, len = ref.length; j < len; j++) {
            attachment = ref[j];
            attachment.checked = true;

            if (attachment.extension == null) {
              attachment.isDisplayableImage = false;
              attachment.isPlayableVideo = false;
              attachment.isExternalVideo = false;
              attachment.isBlockedVideo = false;
            } else {
              attachment.isDisplayableImage = ["png", "jpg", "jpeg", "gif", "webp", "svg"].indexOf(attachment.extension.toLowerCase()) > -1;
              attachment.isPlayableVideo = (ref1 = attachment.extension.toLowerCase(), indexOf.call(app.consts.FILES.PLAYABLEVIDEO, ref1) >= 0);
              attachment.isExternalVideo = attachment.isPlayableVideo && attachment.fileSource !== 'teamworkpm';
              attachment.isBlockedVideo = (ref2 = attachment.extension.toLowerCase(), indexOf.call(app.consts.FILES.BLOCKEDVIDEOS, ref2) >= 0);
            }
          }
        } else {
          //if returned attachments count is 0, ensure attachments object is cleared
          if (data.attachmentsCount === 0) {
            data['attachments'] = [];
          }
        }

        return data;
      }

      GetProjectDetails() {
        return app.GetProjectAndPermissions(this.projectId()).then(result => {
          var project;

          if (result.componentName != null && result.componentName === 'Project') {
            project = result;
          } else {
            project = new ProjectModel(result.response.project);
          }

          this.projectName(project.name());
          this.companyName(project.company.name());

          if (app.cachedProjects[this.projectId()] == null) {
            return app.cachedProjects[this.projectId()] = project;
          }
        });
      }

      GetAutocompleteProperties() {
        var arr, dateAsString;
        arr = [];
        dateAsString = this.dueDateAsMoment() == null ? '' : this.dueDateAsMoment().format('YYYYMMDD') === moment().format('YYYYMMDD') ? app.tl('Today') : this.dueDateAsMoment().format('YYYYMMDD') === moment().add(1, 'day').format('YYYYMMDD') ? app.tl('Tomorrow') : this.dueDateAsMoment().format('ddd MMM Do');

        if (this.priority().length && this.priority().toLowerCase() !== 'none') {
          switch (this.priority()) {
            case 'low':
              arr.push({
                id: 1,
                type: 'priority',
                label: app.tl('Low priority'),
                color: app.consts.UICOLORS.lowpriority
              });
              break;

            case 'medium':
              arr.push({
                id: 2,
                type: 'priority',
                label: app.tl('Medium priority'),
                color: app.consts.UICOLORS.mediumpriority
              });
              break;

            case 'low':
              arr.push({
                id: 3,
                type: 'priority',
                label: app.tl('High priority'),
                color: app.consts.UICOLORS.highpriority
              });
          }
        }

        if (this.dueDate() != null && this.dueDate().toString().length) {
          arr.push({
            id: 0,
            type: 'date',
            subtype: 'due',
            label: app.tl("Due: [_s]", dateAsString)
          });
        }

        return arr;
      }

      Clone() {
        var data;
        data = ko.mapping.toJS(this);
        return new Task(data);
      }

      DownloadAllAttachments() {
        var data, ids, url;
        ids = this.attachments().map(function (i) {
          return i.id();
        }).map(Number);

        if (!ids.length) {
          return false;
        } // we are now hitting the Go API, and there's a small format
        // difference, the legacy one accepts a list as a string "1,2,3",
        // while the Go API is a JSON array [1,2,3].


        data = {
          fileIds: ids
        };
        url = app.prefixApi('files/archive.json', 'API_PREFIX_V3', 'API_PREFIX_V3');
        app.api.post(url, data).then(function (result) {
          return window.location.href = result.response.downloadURL;
        });
        return true;
      }

      RetryAPI(data, event) {
        var attempt;
        Tipped.hideAll();

        if (this.failedAPIAttempt() == null) {
          return;
        }

        attempt = this.failedAPIAttempt();
        this.failedAPIAttempt(null);
        this.isUpdating(true);
        this.isRetryingFailedAPIAttempt(true);
        app.api[attempt.method](attempt.url, attempt.data).then(attempt.callback).catch(() => {
          return this.failedAPIAttempt(attempt);
        }).finally(() => {
          this.isUpdating(false);
          return this.isRetryingFailedAPIAttempt(false);
        });

        if (event != null) {
          event.stopPropagation();
        }

        return false;
      }

      ToggleComplete(i, event) {
        var markingComplete, oldStatus, url;
        Tipped.hideAll();
        markingComplete = true;

        if (this.status() === 'completed') {
          markingComplete = false;
        }

        this.isUpdating(true);
        url = `tasks/${this.id()}/${this.status() === 'completed' ? 'uncomplete' : 'complete'}.json`;
        oldStatus = this.status();

        if (oldStatus === "completed") {
          this.progress(0);
          this.status("reopened");
        } else {
          this.progress(100);
          this.status("completed");
        }

        app.api.put(url).then(result => {
          var eventData;

          if (result.response.STATUS === 'OK') {
            eventData = {
              eventTime: new Date(),
              itemType: 'task',
              actionType: app.consts.ACTIONTYPE.UPDATED,
              itemId: this.id(),
              projectId: 0,
              subType: `task-${this.status()}`,
              extraInfo: {
                data: {
                  isPersonalTask: true
                }
              }
            };
            return app.ko.postbox.publish('allTWEvents', eventData);
          }
        }).catch(() => {
          return this.status(oldStatus);
        }).finally(() => {
          return this.isUpdating(false);
        });

        if (event != null) {
          event.stopPropagation();
        }

        return false;
      }

      Toggle() {
        if (this.isUpdating()) {
          return;
        }

        if (this.failedAPIAttempt() != null) {
          return this.RetryAPI();
        }

        if (this.isTemporary()) {
          return;
        }

        return this.ToggleComplete();
      }

    }

    ;
    /* _____
               |  _ \(_)___ _ __ | | __ _ _   _
               | | | | / __|  _ \| |/ _  | | | |
               | |_| | \__ \ |_) | | (_| | |_| |
               |____/|_|___/ .__/|_|\__,_|\__, |
                           |_|            |___/
     * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */

    Task.prototype.nwlExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/\*%?=~_|!:,.;\]\[]*[-A-Z0-9+&@#\/\*%=~_|\]\[])/gi;
    return Task;
  }.call(this);
  /*  ____            _
            |  _ \  ___  ___| |_ _ __ ___  _   _
            | | | |/ _ \/ __| __|  __/ _ \| | | |
            | |_| |  __/\__ \ |_| | | (_) | |_| |
            |____/ \___||___/\__|_|  \___/ \__, |
                                           |___/
   * # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #  */


  return Task;
});