/* eslint-disable camelcase */
import { inject, provide, ref, computed } from 'vue-demi';
import moment from 'moment';
import { useCurrentAccount, useCurrentUser } from '@teamwork/use';
import useCompanyRoleId from '@/platform/composables/useCompanyRoleId';
import useRouter from '@/platform/composables/useRouter';

const baseMetricsSymbol = Symbol('useBaseMetrics');

/** @typedef {'timestamp' | 'account_id' | 'on_trial' | 'is_paid' | 'start_date' | 'plan_type' | 'currency' | 'initial_plan_id' | 'initial_plan_name' | 'initial_payment_period' | 'days_since_purchase' | 'product_code' | 'initial_number_of_seats' | 'company_size_id' | 'company_size' | 'company_user_role_id' | 'company_user_role' | 'company_sector' | 'user_role' | 'plan_name' | 'page' | 'page_tab'} CommonMetric */

const COMPANY_SIZES = [
  { id: 1, label: '1-5' },
  { id: 2, label: '6-10' },
  { id: 3, label: '11-20' },
  { id: 4, label: '21-35' },
  { id: 5, label: '36-50' },
  { id: 6, label: '51-200' },
  { id: 7, label: '201-500' },
  { id: 8, label: '501+' },
];

const USER_ROLES = [
  { id: 1, label: 'C-Level, Partner, Founder, Director, VP' },
  { id: 2, label: 'Project Manager' },
  { id: 3, label: 'Operations Manager' },
  { id: 4, label: 'Team Lead/Manager' },
  { id: 5, label: 'Team Member' },
  { id: 6, label: 'Contractor' },
  { id: 7, label: 'Other' },
  { id: 8, label: 'Student' },
];

export function provideBaseMetrics() {
  const account = useCurrentAccount();
  const user = useCurrentUser();
  const router = useRouter();

  const cachedCompanyRoleId = ref(undefined);

  const { getCompanyRoleId: getRoleId } = useCompanyRoleId();
  const getCompanyRoleId = () => {
    if (cachedCompanyRoleId.value != null) {
      return Promise.resolve(cachedCompanyRoleId.value);
    }

    return getRoleId().then((data) => {
      cachedCompanyRoleId.value = data;
      return cachedCompanyRoleId.value;
    });
  };

  const planName = computed(() => account.value?.pricePlanData?.name);
  const planId = computed(() => account.value?.pricePlanData?.id);
  const daysSincePurchase = computed(() => {
    if (account.value?.installationDateFirstPayment) {
      const firstPaymentMoment = moment(
        account.value.installationDateFirstPayment,
      );
      if (moment.isMoment(firstPaymentMoment)) {
        const currentDate = moment().set({
          hour: 24,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        return currentDate.diff(
          account.value.installationDateFirstPayment,
          'days',
        );
      }
    }
    return undefined;
  });

  const userRole = computed(() => {
    if (!user.value) {
      return undefined;
    }

    if (user.value.siteOwner) {
      return 'Site Owner';
    }
    if (user.value.administrator) {
      return 'Administrator';
    }
    if (user.value.isClientUser) {
      return 'Client User';
    }
    if (user.value.isCollaborator) {
      return 'Collaborator';
    }

    return 'Standard User';
  });

  const getCommonFields = async (fields = []) => {
    const dataObject = {
      timestamp: new Date().toISOString(),
      ...(fields.includes('account_id') && {
        account_id: account.value.id.toString(),
      }),
      ...(fields.includes('on_trial') && {
        on_trial: account.value.trialDaysRemaining > 0,
      }),
      ...(fields.includes('is_paid') && {
        is_paid: account.value.isPaid,
      }),
      ...(fields.includes('start_date') && {
        // probably already a moment, but might have to change this if we lose moment
        start_date: account.value.createdAt.format('YYYY-MM-DD'),
      }),
      ...(fields.includes('plan_type') && {
        plan_type: account.value.pricePlanType,
      }),
      ...(fields.includes('currency') && {
        currency: account.value.currency.code,
      }),
      ...(fields.includes('initial_plan_id') && {
        initial_plan_id: account.value.pricePlanId,
      }),
      ...(fields.includes('initial_plan_name') && {
        initial_plan_name: account.value.pricePlanName,
      }),
      ...(fields.includes('initial_payment_period') && {
        initial_payment_period: account.value.billingPeriod,
      }),
      ...(fields.includes('days_since_purchase') && {
        days_since_purchase: daysSincePurchase.value,
      }),
      ...(fields.includes('product_code') && {
        product_code: 'projects', // maybe this feeds in?
      }),
      ...(fields.includes('initial_number_of_seats') && {
        initial_number_of_seats: account.value.paidForUsers,
      }),
      ...(fields.includes('company_size_id') && {
        company_size_id: parseInt(account.value?.companysizesId, 10),
      }),
      ...(fields.includes('company_size') && {
        company_size: COMPANY_SIZES.find(
          (i) => i.id === parseInt(account.value?.companysizesId, 10),
        )?.label,
      }),
      ...(fields.includes('company_user_role_id') && {
        company_user_role_id: await getCompanyRoleId(),
      }),
      ...(fields.includes('company_user_role') && {
        company_user_role: USER_ROLES.find(
          async (i) => i.id === (await getCompanyRoleId()),
        )?.label,
      }),
      ...(fields.includes('company_sector') && {
        company_sector: account.value.industryCategoryName,
      }),
      ...(fields.includes('user_role') && { user_role: userRole.value }),
      ...(fields.includes('plan_name') && { plan_name: planName.value }),
      ...(fields.includes('plan_id') && { plan_id: planId.value }),
      ...(fields.includes('page') && { page: router.currentRoute.path }),
      ...(fields.includes('page_tab') && {
        page_tab: router.currentRoute.path?.split('/')?.at(-1),
      }),
    };
    return Promise.resolve(dataObject);
  };

  provide(baseMetricsSymbol, {
    getCommonFields,
  });
}

export function useBaseMetrics() {
  return inject(baseMetricsSymbol);
}
