<template>
  <div class="h-[calc(100%-60px)] px-6 flex flex-col">
    <h1 class="text-md font-semibold leading-normal">
      {{ $t("What's new") }}
    </h1>
    <div class="grow overflow-y-auto min-h-full -mx-6">
      <div class="px-6 pt-6">
        <ProductUpdateItem
          v-for="update in productUpdateItems"
          :key="update.title"
          :date="update.date"
          :link-text="update.linkText"
          :href="update.link"
          :title="update.title"
          :text="update.text"
          :type="update.type"
          @click="$emit('linkTextClicked', update.title)"
        >
          <template #icon>
            <component :is="whatsNewIconMapping[update.type]" />
          </template>
        </ProductUpdateItem>
        <HelpCenterProductUpdateSkeleton v-if="productUpdatesLoading" />
        <p
          v-if="!productUpdatesLoading && !productUpdateItems.length"
          class="italic"
        >
          {{ $t('There are no available updates.') }}
        </p>

        <button
          :v-bind="$attrs"
          class="
            flex
            items-center
            transition-colors
            duration-100
            bg-transparent
            hover:bg-palette-neutral-20/80
            rounded-full
            hover:text-text
            text-text text-default
            font-normal
            appearance-none
            border-none
            gap-4
            p-0
            justify-between
            text-left
            w-full
          "
          v-on="{ ...$listeners }"
          v-if="
            productUpdateItems.length > 0 &&
            hasMoreProductUpdates &&
            !productUpdatesLoading
          "
          @click="loadMoreUpdates()"
        >
          <div class="flex-none flex items-center justify-center">
            <MoreDownArrowIcon />
          </div>
          <div class="flex-auto w-full truncate">
            {{ $t('Load More') }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue-demi';
import ProductUpdateItem from '@/platform/components/side-nav/panels/help-center/common/ProductUpdateItem.vue';
import HelpCenterProductUpdateSkeleton from '@/platform/components/side-nav/panels/help-center/common/HelpCenterProductUpdateSkeleton.vue';
import AnnouncementIcon from '@/assets/images/features/sidenav/announcementIcon.svg';
import FeatureIcon from '@/assets/images/features/sidenav/featureIcon.svg';
import WebinarIcon from '@/assets/images/features/sidenav/webinarArticleIcon.svg';
import TipIcon from '@/assets/images/features/sidenav/tipArticleIcon.svg';
import EnhancementIcon from '@/assets/images/features/sidenav/enhancementIcon.svg';
import BetaIcon from '@/assets/images/features/sidenav/betaIcon.svg';
import BugFixIcon from '@/assets/images/features/sidenav/bugFixIcon.svg';
import MaintenanceIcon from '@/assets/images/features/sidenav/maintenanceIcon.svg';
import MoreDownArrowIcon from '@/assets/images/features/sidenav/moreDownArrowIcon.svg';
import { useProductUpdatesLoader } from '@/platform/composables/useProductUpdatesLoader';

export default {
  name: 'HelpCenterWhatsNewState',
  components: {
    ProductUpdateItem,
    AnnouncementIcon,
    FeatureIcon,
    WebinarIcon,
    BetaIcon,
    BugFixIcon,
    MaintenanceIcon,
    TipIcon,
    EnhancementIcon,
    MoreDownArrowIcon,
    HelpCenterProductUpdateSkeleton,
  },
  setup() {
    const pageSize = 10;
    const updatesCount = ref(pageSize);
    const productUpdatesState = useProductUpdatesLoader({
      params: {},
      pageSize,
      count: updatesCount,
    });

    const hasMoreProductUpdates = computed(() =>
      productUpdatesState.items.value
        ? productUpdatesState.totalCount.value === undefined ||
          productUpdatesState.totalCount.value >
            productUpdatesState.items.value.length
        : false,
    );

    const loadMoreUpdates = () => {
      updatesCount.value += pageSize;
    };

    const whatsNewIconMapping = {
      announcement: 'AnnouncementIcon',
      feature: 'FeatureIcon',
      webinar: 'WebinarIcon',
      enhancement: 'EnhancementIcon',
      tips: 'TipIcon',
      maintenance: 'MaintenanceIcon',
      fix: 'BugFixIcon',
      beta: 'BetaIcon',
    };

    return {
      whatsNewIconMapping,
      productUpdateItems: productUpdatesState.items,
      productUpdatesLoading: productUpdatesState.loading,
      hasMoreProductUpdates,
      updatesCount,
      loadMoreUpdates,
    };
  },
};
</script>

<style lang="scss" scoped>
.load-more-button {
  position: relative;
  top: -12px;
  margin-left: -10px;
  width: calc(100% + 10px);
}
</style>
