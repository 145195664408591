<template>
  <v-popover
    :open.sync="isOpen"
    trigger="click"
    offset="2"
    placement="top-end"
    popover-arrow-class="hidden"
    boundaries-element="body"
  >
    <slot />
    <template slot="popover">
      <ul class="task-context-menu__list">
        <li
          v-close-popover
          class="task-context-menu__item"
          @click="$emit('remove-dependency')"
        >
          {{ $t('Remove Dependency') }}
        </li>
      </ul>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'DependencyOptionsMenu',
  props: {
    open: { type: Boolean, default: false },
  },
  computed: {
    isOpen: {
      get() {
        return this.open;
      },
      set(newVal) {
        this.$emit('update:open', newVal);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.task-context-menu {
  &__list {
    list-style-type: none;
    padding: 5px 0 5px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 35px;
    cursor: pointer;

    &:hover {
      background-color: #f5f7fa;
    }
  }
}
</style>
