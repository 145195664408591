/* eslint-disable import/prefer-default-export */
export const modalSizes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
};

export const defaultModalSize = modalSizes.MD;
