import lscache from 'lscache';

export default {
  path: '/reports',
  meta: {
    pageName: 'Reports',
    permission: 'canAccessReportsTab',
    menuPermission: 'canAccessReportsTab',
  },
  component: 'reports-page',
  children: [
    {
      path: 'gallery',
      component: 'reports-gallery',
      props: { pageName: 'reports-gallery', name: 'gallery' },
      meta: {
        pageName: 'Reports',
        displayName: 'Reports',
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'gallery');
        next();
      },
    },
    {
      path: 'health',
      component: 'projects-health',
      props: {
        pageName: 'projects-health',
        name: 'health',
      },
      meta: {
        pageName: 'Reports',
        noSpacing: true,
        displayName: 'Project Health',
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'health');
        next();
      },
    },
    {
      path: 'utilization',
      component: 'utilization-report',
      props: {
        pageName: 'utilization-report',
        name: 'utilization',
      },
      meta: {
        pageName: 'Reports',
        displayName: 'Utilization',
        permission: 'canAccessUtilizationReportTab',
        menuPermission: 'canAccessUtilizationReportTab',
        noSpacing: true,
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'utilization');
        next();
      },
    },
    {
      path: 'plannedvsactual',
      component: 'reports-page',
      children: [
        {
          path: 'tasks',
          component: 'planned-vs-actual-tasks',
          meta: {
            noSpacing: true,
          },
          beforeEnter(root, to, from, next) {
            lscache.set('twProjects-last-pva-visited-report', 'tasks');
            next();
          },
        },
        {
          path: 'milestones',
          component: 'planned-vs-actual-milestones',
          meta: {
            pageName: 'Reports',
            displayName: 'Planned vs Actual',
            isAccount: true,
            noSpacing: true,
            menuPermission: 'canAccessPlannedActualMilestoneReportTab',
          },
          beforeEnter(root, to, from, next) {
            lscache.set('twProjects-last-pva-visited-report', 'milestones');
            next();
          },
        },
        {
          path: '*',
          component: 'planned-vs-actual-tasks',
          redirect: () => '/reports/plannedvsactual/tasks',
        },
      ],
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'plannedactualreports');
        next();
      },
    },
    {
      path: 'profitability',
      component: 'reports-page',
      children: [
        {
          path: 'users',
          component: 'profitability-report-user',
          meta: {
            pageName: 'Reports',
            displayName: 'Planned vs Actual',
            isAccount: true,
            permission: 'canAccessProfitabilityReportTab',
            menuPermission: 'canAccessProfitabilityReportTab',
            noSpacing: true,
          },
          beforeEnter(root, to, from, next) {
            lscache.set('twProjects-last-visited-profit-report', 'users');
            next();
          },
        },
        {
          path: 'projects',
          component: 'profitability-report-project',
          meta: {
            pageName: 'Reports',
            displayName: 'Planned vs Actual',
            isAccount: true,
            permission: 'canAccessProfitabilityReportTab',
            menuPermission: 'canAccessProfitabilityReportTab',
            noSpacing: true,
          },
          beforeEnter(root, to, from, next) {
            lscache.set('twProjects-last-visited-profit-report', 'projects');
            next();
          },
        },
        {
          path: '*',
          component: 'profitability-report-project',
          redirect: () => '/reports/profitability/projects',
        },
      ],
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'profitability');
        next();
      },
    },
    {
      path: 'time',
      component: 'time-report',
      props: {
        pageName: 'time-report',
        name: 'time',
      },
      meta: {
        pageName: 'Reports',
        displayName: 'Time',
        permission: 'canAccessTimeReport',
        menuPermission: 'canAccessTimeReport',
        nextRoute: false,
        noSpacing: true,
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'time');
        next();
      },
    },
    {
      path: ':customReport(\\d+)',
      component: 'custom-reports',
      props: (route) => ({
        reportId: Number(route.params.customReport) || null,
      }),
      meta: {
        noSpacing: true,
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'custom');
        lscache.set('twProjects-last-custom-report', to.params.customReport);
        next();
      },
    },
    {
      path: 'projectstime',
      component: 'projects-time-report',
      props: {
        pageName: 'projects-time',
        name: 'projectstime',
      },
      meta: {
        pageName: 'Reports',
        noSpacing: true,
        displayName: 'Project time',
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'projectstime');
        next();
      },
    },
    {
      path: 'usertime',
      component: 'user-time-report',
      props: {
        pageName: 'user-time',
        name: 'usertime',
      },
      meta: {
        pageName: 'Reports',
        noSpacing: true,
        displayName: 'User time',
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'usertime');
        next();
      },
    },
    {
      path: 'tasktime',
      component: 'task-time-report',
      props: {
        pageName: 'task-time',
        name: 'tasktime',
      },
      meta: {
        pageName: 'Reports',
        noSpacing: true,
        displayName: 'Task time',
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'tasktime');
        next();
      },
    },
    {
      path: 'usertaskcompletion',
      component: 'user-task-completion-report',
      props: {
        pageName: 'user-task-completion',
        name: 'usertaskcompletion',
      },
      meta: {
        pageName: 'Reports',
        noSpacing: true,
        displayName: 'Task completion per user ',
      },
      beforeEnter(_, to, from, next) {
        lscache.set('twProjects-last-reports-section', 'usertime');
        next();
      },
    },
  ],
};
