var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VPopover',{ref:"popover",attrs:{"open":_vm.isOpen,"boundaries-element":"body","auto-hide":false,"popover-arrow-class":"hidden"},on:{"update:open":function($event){_vm.isOpen=$event}},scopedSlots:_vm._u([{key:"default",fn:function(activator){return [_vm._t("activator",null,null,activator)]}},{key:"popover",fn:function(){return [_c('div',{staticClass:"max-h-80 w-48 overflow-auto bg-default shadow-2",style:({
        '--lsds-a-color-primary-default': '#3C55BD',
        '--lsds-a-color-surface-emphasis-selected': '#D8E0FF',
      })},[_c('div',{staticClass:"m-2 flex flex-col items-stretch gap-2"},_vm._l((_vm.months),function(month){return _c('div',{key:month.format('YYYY-MM'),ref:"monthRefs",refInFor:true,staticClass:"rounded-md p-2 hover:bg-surface-hover",class:{
            '!bg-[var(--lsds-a-color-surface-emphasis-selected)]':
              _vm.isSelected(month),
            'font-semibold': _vm.isSelected(month),
            'text-primary': _vm.isSelected(month),
          },attrs:{"role":"button","aria-selected":_vm.isSelected(month)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectMonth(month, $event)}}},[_c('div',{staticClass:"flex items-center !justify-between text-body-1"},[_vm._v("\n            "+_vm._s(_vm.moment(month).format('MMMM YYYY'))+"\n            "),(_vm.isSelected(month))?_c('CommonIcon',{staticClass:"text-[var(--lsds-a-color-primary-default)]",attrs:{"id":"check"}}):_vm._e()],1)])}),0)])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }