import { unref } from 'vue-demi';
import { useLaunchDarkly } from '@/platform/composables/useLaunchDarkly';
import { useBaseMetrics } from '@/platform/composables/useBaseMetrics';
import useTrackingDebugQuery from '@/platform/composables/useTrackingDebugQuery';

export default function usePendo() {
  const { client: ldClient } = useLaunchDarkly();
  const { getCommonFields } = useBaseMetrics();
  const { isBypassExperimentsQueryEnabled, isLogTrackEventsEnabled } =
    useTrackingDebugQuery();

  const MAX_RETRIES = 10;
  const RETRY_TIME_IN_MILLIS = 1000;

  // try to stringify
  const stringify = (value) => {
    if (typeof value === 'string') {
      return value;
    }

    try {
      return JSON.stringify(value);
    } catch {
      return value;
    }
  };

  /**
   * @returns {Boolean}
   */
  function isPendoReady() {
    return window.pendo?.isReady?.();
  }

  /**
   * Logs Pendo Track Event name and metadata to the console.
   *
   * @param {string} eventName Name of the LaunchDarkly key
   * @param {Object} metadata Object to be passed to pendo.track
   */
  function logExperimentEvent(eventName, metadata) {
    if (metadata && Object.entries(metadata).length > 0) {
      console.info(`=== Pendo TWA: ${eventName}`, metadata);
    } else {
      console.info(`=== Pendo TWA: ${eventName}`);
    }
  }

  function track(eventName, metadata, retryCount = 0, skipTimeout = false) {
    if (isPendoReady()) {
      if (isLogTrackEventsEnabled.value) {
        logExperimentEvent(eventName, metadata);
        return;
      }
      window.pendo.track(eventName, metadata);
      return;
    }

    if (retryCount >= MAX_RETRIES) {
      if (isLogTrackEventsEnabled.value) {
        console.info(
          `=== Pendo retry limit(${retryCount}) reached for: ${eventName}`,
        );
        console.info(`=== Pendo event not sent: ${eventName}`, metadata);
      }
      return;
    }

    setTimeout(
      () => {
        track(eventName, metadata, retryCount + 1);
      },
      skipTimeout ? 0 : RETRY_TIME_IN_MILLIS,
    );
  }

  /**
   * Push experiment participation and variation to Pendo, if user is part of an experiment. Only one call per LD flag will be passed through during lifetime of service.
   * @param {Object} options Experiment options object
   *
   * `options.launchDarklyFlagKey` Name of the LaunchDarkly key for the experiment, the experiment track event will only be sent if current user evaluation of this flag is inExperiment or if it is bypassed by the `bypassExperiments` query parameter as defined in `useTrackingDebugQuery.js`.
   *
   * `options.defaultValue` Default value for the variation to use if value could not be fetched.
   *
   * `options.ignoreInExperimentOnRuleMatch` Parameter to bypass reason?.inExperiment check and send event to Pendo regardless of LD experiment status. `EXPERIMENT` event will only be sent if reason.kind is `RULE_MATCH` and ignoreInExperimentOnRuleMatch is true.
   *
   * `options.appLevelTargeting` Optional boolean parameter to check if user matches app level targeting. Defaults to `true` therefore if not passed, the check will be skipped.
   *
   * @param {string} options.launchDarklyFlagKey
   * @param {*} options.defaultValue
   * @param {boolean} [options.ignoreInExperimentOnRuleMatch]
   * @param {boolean} [options.appLevelTargeting]
   *
   * More info on {@link https://docs.launchdarkly.com/sdk/concepts/evaluation-reasons#understanding-the-reason-data LaunchDarkly Evaluation Reasons}
   */
  const trackExperimentInPendo = ({
    launchDarklyFlagKey,
    defaultValue,
    ignoreInExperimentOnRuleMatch = false,
    appLevelTargeting = true,
  }) => {
    ldClient.value.waitUntilReady().then(() => {
      const expVariationDetail = ldClient.value.variationDetail(
        launchDarklyFlagKey,
        defaultValue,
      );
      const matchAppLevelTargetting = unref(appLevelTargeting);
      const ruleMatchAndIgnoreInExperimentOnRuleMatch =
        expVariationDetail.reason?.kind === 'RULE_MATCH' &&
        ignoreInExperimentOnRuleMatch;

      if (
        isBypassExperimentsQueryEnabled.value ||
        (matchAppLevelTargetting &&
          (expVariationDetail.reason?.inExperiment ||
            ruleMatchAndIgnoreInExperimentOnRuleMatch))
      ) {
        let experimentVariationName = '';

        switch (expVariationDetail.value) {
          case true:
            experimentVariationName = 'test';
            break;
          case false:
            experimentVariationName = 'control';
            break;
          default:
            experimentVariationName = stringify(expVariationDetail.value);
        }

        track('EXPERIMENT', {
          experiment_id: launchDarklyFlagKey,
          experiment_variation_index: expVariationDetail.variationIndex,
          experiment_variation_value: stringify(expVariationDetail.value),
          experiment_variation_name: experimentVariationName,
          experiment_rule_id: expVariationDetail.reason?.ruleId,
        });
      }
    });
  };

  /**
   * Method for tracking event in Pendo.
   * @param {Object} dataObject Pendo data object
   *
   * `dataObject.eventName` Event name
   *
   * `dataObject.metadata` Metadata object
   *
   * `dataObject.commonMetrics` Common metrics that will be loaded and added to metadata object. List of available common metrics can be found in `useBaseMetrics.js` -> `getCommonFields`
   *
   * `dataObject.launchDarklyFlagKey` LaunchDarkly flag key. When the key is passed, the event track action will only be sent if current user evaluation of this flag is in experiment or if it is bypassed by the `bypassExperiments` query parameter.
   *
   * `options.appLevelTargeting` Optional boolean parameter to check if user matches app level targeting. Defaults to `true` therefore if not passed, the check will be skipped.
   *
   * @param {string} dataObject.eventName
   * @param {import('./useBaseMetrics').CommonMetric[]} [dataObject.commonMetrics]
   * @param {Object} [dataObject.metadata]
   * @param {string} [dataObject.launchDarklyFlagKey]
   * @param {boolean} [options.appLevelTargeting]
   */
  async function trackPendoEvent({
    launchDarklyFlagKey,
    eventName,
    metadata,
    commonMetrics,
    appLevelTargeting = true,
  }) {
    if (launchDarklyFlagKey) {
      await ldClient.value.waitUntilReady();
      const matchesAppLevelTargetting = unref(appLevelTargeting);
      const expVariationDetail =
        ldClient.value.variationDetail(launchDarklyFlagKey);

      if (
        !matchesAppLevelTargetting &&
        !expVariationDetail.reason?.inExperiment &&
        !isBypassExperimentsQueryEnabled.value
      ) {
        return;
      }
    }

    const combinedFields = commonMetrics
      ? {
          ...(await getCommonFields(commonMetrics)),
          ...metadata,
        }
      : metadata;

    track(eventName, combinedFields);
  }

  return {
    trackExperimentInPendo,
    trackPendoEvent,
    isPendoReady,
  };
}
