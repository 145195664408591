<template>
  <div class="createdBy">
    <div class="createdBy__tooltip" v-tooltip="{ content: createdByFullName }">
      <img
        class="createdBy__img"
        :src="task.createdBy.avatarUrl || '/images/noPhoto2.png'"
        :alt="createdByFullName"
      />
    </div>

    <p class="createdBy__time">{{ dateTime }}</p>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'CreatedBy',
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdByFullName() {
      return `${this.task.createdBy.firstName} ${this.task.createdBy.lastName}`;
    },
    dateTime() {
      return moment(this.task.createdAt).format('ddd Do MMMM YYYY, HH:MM');
    },
  },
};
</script>

<style lang="scss">
$neutral-60: #0b0e1f;

.createdBy {
  display: flex;
  align-items: center;

  &__tooltip {
    cursor: pointer;
  }

  &__img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    pointer-events: none;
  }

  &__time {
    font-size: 14px;
    color: $neutral-60;
  }
}
</style>
