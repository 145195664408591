<template>
  <div class="custom-field-dropdown">
    <OptionPicker
      v-on="$listeners"
      :value="column ? column.id : ''"
      :options="options"
      :placeholder="$t('None')"
      :no-arrow="true"
      :show-dot="true"
      :show-tooltip="true"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { useSharedBoardColumnsLoader } from '@teamwork/use';
import { computed, defineComponent, toRef, Vue2 as Vue } from 'vue-demi';
import OptionPicker from '@widgets/OptionPicker';

export default defineComponent({
  name: 'BoardColumn',

  props: {
    projectId: { type: Number, required: true },
    column: { type: Object, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
  },

  components: {
    OptionPicker,
  },

  setup(props) {
    const projectId = toRef(props, 'projectId');
    const { items: boardColumns } = useSharedBoardColumnsLoader({
      projectId,
      count: 100,
    });

    return {
      options: computed(() => [
        {
          id: 0,
          label: Vue.t('None'),
          background: '#F5F7FA',
          dotColor: 'transparent',
        },
        ...boardColumns.value.map((column) => ({
          id: column.id,
          label: column.name,
          background: '#F5F7FA',
          dotColor: column.color,
        })),
      ]),
    };
  },
});
</script>

<style lang="scss">
.custom-field-dropdown {
  width: 100%;
  padding: 0 10px;
}
</style>
