<template>
  <div class="table-cell-text">
    <template v-if="!url">{{ text ? text.toString() : '' }}</template>
    <router-link
      class="table-cell-text__link"
      v-if="url"
      :to="url"
      v-tooltip="{
        content: $t(text ? text.toString() : ''),
        placement: 'left',
        boundariesElement: 'document.body',
      }"
    >
      {{ text ? text.toString() : '' }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CellText',
  display: 'CellText',
  props: {
    text: { type: [String, Number], required: false, default: '' },
    url: { type: String, required: false, default: null },
  },
};
</script>

<style lang="scss">
.table-cell-text {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0b0e1f;

  &__link {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}
</style>
