<template>
  <button
    class="dependency-toggle-button"
    :class="{
      'is-blocked': type === 'blocked',
      'waiting-on': type === 'waiting',
    }"
  >
    <CommonIcon id="minus" />
  </button>
</template>
<script>
import CommonIcon from '@teamwork/common-icons/dist/v-icon';

export default {
  name: 'DependenciesIndicator',
  props: {
    type: { type: String, default: '' },
  },
  components: {
    CommonIcon,
  },
};
</script>
<style lang="scss" scoped>
$blocked-color: #e64d4d;
$waiting-color: #ffb300;
$text-color: #0b0e1f;
$border-color: #c5cee0;

.dependency-toggle-button {
  display: inline-flex;
  border: 1px solid $border-color;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: $text-color;
  border-radius: 50%;

  width: 20px;
  height: 20px;

  svg {
    width: 12px;
    height: 12px;
    fill: #8f9bb3;
  }
  .w-svg-sprite {
    display: inherit !important;
  }

  &:hover {
    svg {
      fill: $text-color;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
  }

  &.is-blocked {
    border-color: $blocked-color !important;
    .w-svg-sprite {
      fill: $blocked-color !important;
    }
  }

  &.waiting-on {
    border-color: $waiting-color !important;
    .w-svg-sprite {
      fill: $waiting-color !important;
    }
  }
}
</style>
