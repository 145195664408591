<template>
  <div>
    <ul
      :data-identifier="`${dataIdentifierPrefix}-tabs`"
      class="
        w-full
        list-none
        flex flex-nowrap
        gap-4
        justify-start
        text-default text-text-secondary
        p-0
      "
      ref="tabList"
      role="tablist"
    >
      <li
        v-for="(tab, currentIndex) of tabs"
        :key="tab.value"
        :data-identifier="`${dataIdentifierPrefix}-tab-${tab.value}`"
        tabindex="0"
        role="tab"
        :aria-selected="value === tab.value"
        class="
          group
          relative
          select-none
          cursor-pointer
          border-solid border-0
          outline-none
          pb-2
          hover:text-primary
          focus:text-primary
        "
        :class="[
          { 'text-primary': value === tab.value },
          { 'font-semibold': value === tab.value },
          ...(value === tab.value ? tabBorder : []),
        ]"
        @click="selectTab(tab.value)"
        @keydown.enter="selectTab(tab.value)"
        @keydown.space="selectTab(tab.value)"
        @keydown.home="focusFirstTab"
        @keydown.end="focusLastTab"
        @keydown.left="focusPreviousTab(currentIndex)"
        @keydown.right="focusNextTab(currentIndex)"
      >
        <span
          class="
            rounded-sm
            px-1
            border-solid border border-transparent
            group-focus:border-primary
          "
        >
          {{ tab.text }}
        </span>
      </li>
    </ul>

    <FTabsBorder v-if="!disableIntegratedBorder" />
  </div>
</template>

<script>
import { ref } from 'vue-demi';

import FTabsBorder from './FTabsBorder.vue';

export default {
  name: 'FTabs',
  components: {
    FTabsBorder,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
      validator(tabs) {
        return tabs?.every((tab) => {
          const hasValidValue =
            typeof tab.value === 'string' || typeof tab.value === 'number';
          const hasValidText =
            typeof tab.text === 'string' || typeof tab.text === 'number';
          return hasValidValue && hasValidText;
        });
      },
    },
    dataIdentifierPrefix: {
      type: [String, Number],
      required: true,
    },
    disableIntegratedBorder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const tabList = ref(null);

    const tabBorder = [
      "after:content-['']",
      'after:absolute',
      'after:w-full',
      'after:h-0.5',
      'after:-bottom-0.5',
      'after:left-0',
      'after:bg-primary',
      'after:rounded-sm',
    ];

    const selectTab = (value) => emit('input', value);

    const focusTab = (index) => {
      // Parent ref is used to target rather than child ref array
      // as the ref array does not guarantee the same order as the source.
      tabList.value.children[index].focus();
    };

    const focusFirstTab = () => focusTab(0);

    const focusLastTab = () => focusTab(props.tabs.length - 1);

    const focusPreviousTab = (currentIndex) => {
      focusTab(currentIndex === 0 ? props.tabs.length - 1 : currentIndex - 1);
    };

    const focusNextTab = (currentIndex) => {
      focusTab(currentIndex === props.tabs.length - 1 ? 0 : currentIndex + 1);
    };

    return {
      tabList,
      tabBorder,
      selectTab,
      focusFirstTab,
      focusLastTab,
      focusPreviousTab,
      focusNextTab,
    };
  },
};
</script>
