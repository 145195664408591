<template>
  <div class="w-full contact-upload" ref="thumbnails-grid">
    <input
      multiple
      @change="onFileChange"
      ref="fileInput"
      type="file"
      class="hidden"
    />
    <FileThumbnail
      v-for="(file, index) in files"
      :key="file.id || -index"
      :file="file"
      @delete-file="onDeleteFile"
    />
    <button
      @click="$refs.fileInput.click()"
      class="
        w-full
        bg-transparent
        border-2
        rounded-sm
        border-dashed
        p-9
        file-upload
      "
    >
      <template v-if="isUploading">
        <FileLoadingIcon />
      </template>
      <div
        v-html="$t('Drag and drop your files here or <span>browse</span>')"
      ></div>
      <div
        class="pt-2 text-xs text-[#646D7D]"
        v-html="
          $t('A screenshot would be helpful for us to figure out the problem.')
        "
      ></div>
    </button>
  </div>
</template>

<script>
import { shallowRef, watch } from 'vue-demi';
import FileLoadingIcon from '@widgets/TaskDetailWidgets/FileUpload/FileLoadingIcon/FileLoadingIcon.vue';
import FileThumbnail from './FileThumbnail.vue';

export default {
  name: 'FileUpload',
  components: {
    FileThumbnail,
    FileLoadingIcon,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    isUploading: { type: Boolean, default: false },
  },
  setup() {
    const isUploading = shallowRef('');
    function onDeleteFile(file) {
      this.$emit('delete-file', file);
    }
    function loadMoreFiles() {
      if (this.count < this.totalFilesCount) {
        this.count += 10;
      }
    }

    function onFileChange(event) {
      this.count = Infinity;
      this.$emit('on-file-change', event);
    }

    function scrollIntoView() {
      this.$refs['thumbnails-grid'].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
    watch(isUploading, async (newVal) => {
      if (newVal) {
        this.scrollIntoView();
      }
    });

    return {
      onDeleteFile,
      loadMoreFiles,
      onFileChange,
      scrollIntoView,
    };
  },
};
</script>

<style lang="scss" scoped>
.file-upload {
  border-color: #c5cee0;
  font-size: 14px;

  div span {
    color: #3f51b5;
    text-decoration: underline;
  }
}
</style>
