var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border border-solid px-3 flex items-center gap-2 rounded-full",class:_vm.wrapperClasses,on:{"click":_vm.onClick}},[_c('div',{staticClass:"inline-flex items-center gap-2",class:{
      'fill-[color:var(--token-c-input-color-placeholder)]': !_vm.disabled,
      'fill-[color:var(--token-c-input-color-placeholder-disabled)]':
        _vm.disabled,
    }},[_c('IconSearch')],1),_vm._v(" "),_c('input',_vm._g(_vm._b({ref:"inputRef",staticClass:"\n      w-full\n      m-0\n      p-0\n      border-0\n      outline-none\n      text-default\n      leading-6\n      bg-transparent\n      appearance-none\n    ",class:_vm.inputClasses,attrs:{"disabled":_vm.disabled,"type":"search"},domProps:{"value":_vm.modelValue}},'input',_vm.$attrs,false),Object.assign({}, _vm.$listeners, {input: _vm.onInput, blur: _vm.onBlur, focus: _vm.onFocus}))),_vm._v(" "),(_vm.showClear)?_c('div',{staticClass:"inline-flex items-center gap-2",class:{
      'fill-[color:var(--token-c-input-color-placeholder)]': !_vm.disabled,
      'fill-[color:var(--token-c-input-color-placeholder-disabled)]':
        _vm.disabled,
    }},[(_vm.showClearButton)?_c('button',{staticClass:"\n        border-0\n        p-0\n        m-0\n        appearance-none\n        outline-none\n        inline-flex\n        items-center\n        justify-center\n        bg-transparent\n      ",on:{"click":function($event){$event.stopPropagation();return _vm.onClickClear.apply(null, arguments)}}},[_c('IconClear',{staticClass:"w-4 h-4",class:{
          'fill-[color:var(--token-c-input-color-border-invalid)]': _vm.invalid,
        }})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }