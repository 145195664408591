import { v3Url } from '@teamwork/fetcher';
import { useItemLoader, useRealTimeUpdates } from '@teamwork/use';
import moment from 'moment';
import { computed, shallowRef } from 'vue-demi';

function responseToItem({ data: { features } }) {
  for (let i = 0; i < features.length; i += 1) {
    const feature = features[i];

    feature.startAt = feature.startAt ? moment(feature.startAt) : null;
    feature.endAt = feature.endAt ? moment(feature.endAt) : null;
  }

  return features;
}

export default function useFeaturesLoader({ count: _count = 0, params }) {
  const count = shallowRef(_count);

  // Use useItemLoader because the features API does not support pagination.
  const {
    state: { item, inSync, loading, meta, response, error },
    refresh,
  } = useItemLoader({ url: v3Url('features'), params, responseToItem });

  useRealTimeUpdates((event) => {
    if (event.type === 'account') {
      refresh();
    }
  });

  return {
    items: computed(() => {
      if (item.value && count.value > 0) {
        if (item.value.length <= count.value) {
          return item.value;
        }
        return item.value.slice(0, count.value);
      }
      return [];
    }),
    totalCount: computed(() => item.value && item.value.length),
    itemInSync: () => inSync.value,
    inSync,
    loading,
    meta,
    response,
    error,
  };
}
