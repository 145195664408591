<template>
  <div
    class="flex items-center flex-col px-150 group"
    :class="{
      'mt-300': !shouldHide,
      'mb-400': !shouldHide,
      'h-[30px]': !shouldHide,
      'h-[22px]': shouldHide,
    }"
    v-if="shouldMinimizeSidebar"
  >
    <a
      href="/#/home/work"
      :title="$t('Home')"
      :alt="$t('Home')"
      :aria-label="$t('Home')"
      class="flex items-center"
      v-if="!shouldHide"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7087 13.075C17.0324 13.075 17.7913 12.3539 17.7913 11.0959C17.7913 9.91205 16.9752 9.11623 15.7604 9.11623H12.7576V6.89316C12.7576 5.28978 11.6063 4.4502 10.4694 4.4502C9.33196 4.4502 8.18066 5.28978 8.18066 6.89316V22.2283C8.18066 25.9138 9.64901 27.5572 12.9428 27.5572C14.7276 27.5572 15.7482 27.0988 16.5066 26.6525C17.0868 26.3302 17.2928 25.6464 17.2928 25.1143C17.2928 24.1279 16.6336 23.0295 15.6868 23.0295C15.556 23.0295 15.4236 23.0513 15.2811 23.0994C15.231 23.1197 15.1754 23.1447 15.1141 23.173C14.8648 23.2878 14.5237 23.4442 14.0252 23.4442C13.4349 23.4442 12.7576 23.236 12.7576 21.6102V13.075H15.7087Z"
          fill="white"
        />
        <path
          d="M19.9414 23.5514C19.9414 25.7654 21.7423 27.5663 23.9557 27.5663C26.1692 27.5663 27.9701 25.7654 27.9701 23.5514C27.9701 21.338 26.1692 19.5371 23.9557 19.5371C21.7423 19.5371 19.9414 21.338 19.9414 23.5514Z"
          fill="#FF22B1"
        />
      </svg>
    </a>
  </div>
  <div
    class="mx-auto justify-between flex items-center w-[164px] pl-[5px]"
    :class="{
      'mt-300': !shouldHide,
      'mb-400': !shouldHide,
      'h-[30px]': !shouldHide,
      'h-[22px]': shouldHide,
    }"
    v-else
  >
    <a
      href="/#/home/work"
      :title="$t('Home')"
      :alt="$t('Home')"
      :aria-label="$t('Home')"
      class="flex items-center"
      v-if="!shouldHide"
    >
      <svg
        width="124"
        height="18"
        viewBox="0 0 124 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="fill-current text-white w-[124px] h-[23px]"
      >
        <path
          d="M5.43426 6.99404C6.38968 6.99404 6.93868 6.4729 6.93868 5.56501C6.93868 4.70955 6.34871 4.13597 5.46376 4.13597H3.30382V2.52995C3.30382 1.37296 2.47295 0.766602 1.65191 0.766602C0.830872 0.766602 0 1.37296 0 2.52995V13.6017C0 16.2631 1.0603 17.4496 3.44148 17.4496C4.72958 17.4496 5.46704 17.1218 6.0144 16.7941C6.43229 16.5614 6.58142 16.0681 6.58142 15.683C6.58142 14.9717 6.10617 14.1785 5.42279 14.1785C5.32289 14.1793 5.22379 14.1965 5.12944 14.2293L5.00817 14.2818C4.76564 14.4082 4.49668 14.4756 4.22319 14.4784C3.7971 14.4784 3.3071 14.3277 3.3071 13.1543V6.99404H5.43426Z"
          fill="white"
        />
        <path
          d="M19.6886 12.692C19.3464 12.6976 19.0164 12.8196 18.7528 13.0378L18.7053 13.0788C18.1132 13.6027 17.4254 14.0072 16.6797 14.2702C16.1323 14.4781 15.5527 14.589 14.9672 14.5979C14.2852 14.6074 13.6152 14.418 13.0392 14.0527C12.4632 13.6874 12.0062 13.1622 11.724 12.5412L19.3198 9.77985C20.5162 9.34557 20.6768 8.46881 20.4277 7.77724C19.5017 5.2289 17.3139 3.64746 14.7263 3.64746C13.8346 3.65776 12.9516 3.82415 12.1173 4.1391C10.1704 4.83887 8.78234 6.11713 8.10551 7.8264C7.4926 9.37671 7.50735 11.1974 8.14648 12.9558C9.17729 15.8024 11.3864 17.4347 14.192 17.4347C14.7478 17.4323 15.3019 17.373 15.8456 17.2577C16.3398 17.1559 16.8261 17.019 17.3008 16.848C18.5247 16.4121 19.6591 15.7574 20.6489 14.9159H20.6571C20.8026 14.7642 20.9096 14.5798 20.9689 14.3781C21.0282 14.1765 21.0382 13.9635 20.998 13.7572C20.9318 13.458 20.7665 13.19 20.5288 12.9966C20.2911 12.8032 19.995 12.6959 19.6886 12.692ZM13.076 6.5465C13.4713 6.39827 13.8895 6.32061 14.3117 6.31707C15.6866 6.31707 16.6469 7.20366 17.1238 8.09025L10.9669 10.3305C10.685 8.99978 11.0717 7.27413 13.076 6.5465Z"
          fill="white"
        />
        <path
          d="M34.1622 3.72656C33.179 3.72656 32.4726 4.42141 32.4726 5.37847V5.43091C31.5008 4.328 30.0587 3.72656 28.3576 3.72656C24.5212 3.72656 21.9434 6.48466 21.9434 10.5882C21.9434 14.6918 24.4769 17.4499 28.2462 17.4499C30.0947 17.4499 31.6008 16.778 32.548 15.5472V15.798C32.548 17.0156 33.3822 17.4499 34.1622 17.4499C35.1357 17.4499 35.7404 16.8173 35.7404 15.798V5.37847C35.7404 4.37225 35.1209 3.72656 34.1622 3.72656ZM32.4366 10.5882C32.4366 12.5253 31.337 14.4804 28.8788 14.4804C26.7729 14.4804 25.3586 12.9317 25.3586 10.6259C25.3586 8.27587 26.7893 6.69279 28.9165 6.69279C30.6683 6.69607 32.4366 7.90059 32.4366 10.5882Z"
          fill="white"
        />
        <path
          d="M53.1516 3.72656C51.2112 3.72656 49.7101 4.58529 48.9776 6.09954C48.1745 4.54432 46.7308 3.72656 44.779 3.72656C43.327 3.72656 42.0274 4.30178 41.2588 5.2572C41.2359 4.36733 40.5492 3.72656 39.6069 3.72656C38.7662 3.72656 37.9189 4.26081 37.9189 5.45386V15.798C37.9189 16.8632 38.5056 17.4499 39.5709 17.4499C40.6361 17.4499 41.2605 16.8271 41.2605 15.798V9.61641C41.2605 7.5761 42.1684 6.54202 43.9612 6.54202C45.7213 6.54202 46.4391 7.5253 46.4391 9.91303V15.7603C46.4391 16.8501 47.0258 17.4499 48.091 17.4499C49.1562 17.4499 49.7806 16.8271 49.7806 15.7603V9.99333C49.7806 7.67443 50.6754 6.55185 52.519 6.55185C54.2561 6.55185 54.9969 7.58266 54.9969 9.99333V15.7603C54.9969 16.8501 55.5836 17.4499 56.6488 17.4499C57.714 17.4499 58.3417 16.8271 58.3417 15.7603V9.99333C58.3417 5.8357 56.5931 3.72656 53.1516 3.72656Z"
          fill="white"
        />
        <path
          d="M78.4989 3.72656C77.7647 3.72656 77.1502 4.25589 76.8601 5.13757L74.0381 13.1087L71.2521 5.1769C70.949 4.3067 70.5671 3.72656 69.6838 3.72656C68.7382 3.72656 68.3908 4.23787 68.0778 5.17526L65.2918 13.1087L62.4731 5.14085C62.1781 4.25589 61.5636 3.72656 60.8343 3.72656C60.1313 3.72656 59.4053 4.30342 59.4053 5.26703C59.4152 5.62161 59.4948 5.97077 59.6396 6.29456L63.4367 15.9717C63.7989 16.8583 64.2561 17.4466 65.2984 17.4466C66.4456 17.4466 66.8766 16.6534 67.1273 15.9618L69.6822 8.98711L72.1715 15.9602C72.4993 16.9435 73.1286 17.4499 74.0381 17.4499C74.9067 17.4499 75.5458 16.9582 75.9391 15.975L79.6936 6.26998C79.8438 5.95883 79.9254 5.61903 79.9329 5.27359C79.9329 4.38864 79.3183 3.72656 78.4989 3.72656Z"
          fill="white"
        />
        <path
          d="M86.9993 3.76367C84.8328 3.76367 83.0367 4.47491 81.8092 5.822C80.7079 7.02816 80.1016 8.72104 80.1016 10.5876C80.1016 12.4542 80.7079 14.1569 81.8076 15.3713C83.0383 16.7315 84.8344 17.4493 86.9993 17.4493C92.091 17.4493 93.897 13.7522 93.897 10.5876C93.897 7.42311 92.091 3.76367 86.9993 3.76367ZM86.9993 14.8141C84.8049 14.8141 83.4431 13.195 83.4431 10.5876C83.4431 8.06552 84.8393 6.43492 86.9993 6.43492C89.1592 6.43492 90.5571 8.06552 90.5571 10.5876C90.5571 13.1556 89.1609 14.8141 86.9993 14.8141Z"
          fill="white"
        />
        <path
          d="M101.969 3.72662C101.335 3.7233 100.709 3.8574 100.132 4.11967C99.5556 4.38194 99.0428 4.76615 98.629 5.24579C98.5953 4.83206 98.4072 4.44616 98.1021 4.16472C97.7969 3.88328 97.3971 3.7269 96.982 3.72662C96.7646 3.72488 96.549 3.76641 96.3478 3.84881C96.1466 3.93121 95.9638 4.05282 95.8101 4.20656C95.6563 4.36031 95.5347 4.54311 95.4523 4.74431C95.3699 4.94552 95.3284 5.16111 95.3301 5.37853V15.798C95.3301 16.8829 96.161 17.4499 96.982 17.4499C97.8031 17.4499 98.6339 16.8829 98.6339 15.798V10.8488C98.6339 7.85968 99.4533 6.58797 101.372 6.58797C101.593 6.58654 101.814 6.61352 102.028 6.66827C102.188 6.71189 102.353 6.73502 102.52 6.7371C103.308 6.7371 103.831 6.14713 103.831 5.27037C103.844 4.33134 103.108 3.72662 101.969 3.72662Z"
          fill="white"
        />
        <path
          d="M111.463 10.6223L115.068 6.86949C115.569 6.36638 116.051 5.88621 116.051 5.23069C116.055 5.03245 116.019 4.83543 115.945 4.65143C115.871 4.46742 115.761 4.3002 115.621 4.15977C115.481 4.01933 115.314 3.90856 115.131 3.83408C114.947 3.75959 114.75 3.72293 114.552 3.72627C113.88 3.72627 113.405 4.2425 112.942 4.74069L108.568 9.25231V2.41851C108.568 2.20158 108.526 1.98677 108.443 1.78635C108.36 1.58593 108.238 1.40383 108.085 1.25043C107.931 1.09704 107.749 0.975362 107.549 0.892346C107.348 0.809329 107.133 0.766602 106.917 0.766602C106.7 0.766602 106.485 0.809329 106.284 0.892346C106.084 0.975362 105.902 1.09704 105.748 1.25043C105.595 1.40383 105.473 1.58593 105.39 1.78635C105.307 1.98677 105.265 2.20158 105.265 2.41851V15.7977C105.265 16.0146 105.307 16.2294 105.39 16.4298C105.473 16.6302 105.595 16.8124 105.748 16.9657C105.902 17.1191 106.084 17.2408 106.284 17.3238C106.485 17.4069 106.7 17.4496 106.917 17.4496C107.133 17.4496 107.348 17.4069 107.549 17.3238C107.749 17.2408 107.931 17.1191 108.085 16.9657C108.238 16.8124 108.36 16.6302 108.443 16.4298C108.526 16.2294 108.568 16.0146 108.568 15.7977V11.7466L112.941 16.2926C113.018 16.3696 113.093 16.4565 113.169 16.5269C113.606 16.9842 114.06 17.4578 114.73 17.4578C115.119 17.4526 115.491 17.2953 115.765 17.0196C116.039 16.7439 116.194 16.3717 116.197 15.9829C116.197 15.406 115.805 14.9652 115.404 14.5653L111.463 10.6223Z"
          fill="white"
        />
        <path
          d="M121.012 11.6582C120.439 11.6582 119.878 11.8282 119.402 12.1468C118.925 12.4653 118.553 12.9181 118.334 13.4478C118.115 13.9776 118.057 14.5605 118.169 15.1228C118.281 15.6852 118.557 16.2017 118.962 16.6072C119.368 17.0126 119.884 17.2887 120.447 17.4006C121.009 17.5124 121.592 17.455 122.122 17.2356C122.651 17.0162 123.104 16.6446 123.423 16.1679C123.741 15.6911 123.911 15.1306 123.911 14.5572C123.91 13.7886 123.605 13.0518 123.061 12.5083C122.518 11.9648 121.781 11.6591 121.012 11.6582Z"
          fill="#FF22B1"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import { useBranding } from '@teamwork/use';
import { computed } from 'vue-demi';
import { usePreferences } from '@/platform/composables/usePreferences';

export default {
  props: {
    logo: {
      type: String,
      default: 'cdn.xyx/logo',
    },
    minLogo: {
      type: String,
      default: 'cdn.xyx/min-logo',
    },
  },
  setup() {
    const { shouldMinimizeSidebar } = usePreferences();
    const branding = useBranding();
    const shouldHide = computed(() => !branding.value.hasTeamworkBranding);

    return {
      shouldMinimizeSidebar,
      shouldHide,
    };
  },
};
</script>
<style lang="scss" scoped>
.nested-hover:hover {
  path {
    opacity: 1;
  }
}
</style>
