<template>
  <div
    :class="{
      LoaderState: true,
      [`LoaderState--loadOnScroll-${loadOnScroll}`]: canLoadOnScroll,
    }"
  >
    <div v-if="uiState === 'data'">
      <slot
        name="data"
        :item="item"
        :items="items"
        :totalCount="totalCount"
        :inSync="inSync"
        :itemInSync="itemInSync"
        :loading="loading"
        :meta="meta"
        :response="response"
        :error="error"
        :hasMore="hasMore"
        :loadingCount="loadingCount"
        :isEndVisible="isEndVisible"
      >
        <component :is="inTableView ? 'TableViewBlock' : 'div'">
          <BlankSlate
            :title="$t('Error')"
            :msg="$t('Data rendering has not been implemented.')"
          >
            <template #image>
              <ErrorImage :style="{ width: '160px' }" />
            </template>
          </BlankSlate>
        </component>
      </slot>
    </div>

    <component
      v-if="uiState !== 'data' || canLoadOnScroll"
      :is="canLoadOnScroll ? 'LoadOnScroll' : 'div'"
      :has-more="hasMore"
      :loading="!inSync"
      @load="loadMore"
      @visible="onVisibilityChange"
    >
      <slot
        v-if="uiState === 'blank'"
        name="blank"
        :item="item"
        :items="items"
        :totalCount="totalCount"
        :inSync="inSync"
        :itemInSync="itemInSync"
        :loading="loading"
        :meta="meta"
        :response="response"
        :error="error"
        :hasMore="hasMore"
        :loadingCount="loadingCount"
        :isEndVisible="isEndVisible"
      >
        <component :is="inTableView ? 'TableViewBlock' : 'div'">
          <BlankSlate
            :title="$t('No results')"
            :msg="$t('The requested data could not be found.')"
          >
            <template #image>
              <BlankImage :style="{ width: '160px' }" />
            </template>
          </BlankSlate>
        </component>
      </slot>

      <slot
        v-if="uiState === 'error'"
        name="error"
        :item="item"
        :items="items"
        :totalCount="totalCount"
        :inSync="inSync"
        :itemInSync="itemInSync"
        :loading="loading"
        :meta="meta"
        :response="response"
        :error="error"
        :hasMore="hasMore"
        :loadingCount="loadingCount"
        :isEndVisible="isEndVisible"
      >
        <component :is="inTableView ? 'TableViewBlock' : 'div'">
          <BlankSlate
            :title="$t('Error')"
            :msg="$t('Loading failed and will be retried automatically.')"
          >
            <template #image>
              <ErrorImage :style="{ width: '160px' }" />
            </template>
          </BlankSlate>
        </component>
      </slot>

      <slot
        v-if="uiState === 'loading'"
        name="loading"
        :item="item"
        :items="items"
        :totalCount="totalCount"
        :inSync="inSync"
        :itemInSync="itemInSync"
        :loading="loading"
        :meta="meta"
        :response="response"
        :error="error"
        :hasMore="hasMore"
        :loadingCount="loadingCount"
        :isEndVisible="isEndVisible"
      >
        <component :is="inTableView ? 'TableViewBlock' : 'div'">
          <ListLoading />
        </component>
      </slot>
    </component>
  </div>
</template>

<script>
import { computed, defineComponent, shallowRef, unref, watch } from 'vue-demi';
import BlankSlate from '@widgets/BlankSlate';
import ListLoading from '@widgets/ListLoading';
import BlankImage from '@/assets/images/blankslates/search-empty.svg';
import ErrorImage from '@/assets/images/blankslates/error.svg';
import LoadOnScroll from './LoadOnScroll';
import { TableViewBlock } from './table-view';

/**
 * Helps to manage and render the state of a single item or list loader.
 */
export default defineComponent({
  name: 'LoaderState',

  props: {
    // The `count` param for `useListLoader`.
    count: {
      type: Number,
      default: undefined,
    },
    // The `state` object from `useItemLoader` or `useListLoader`.
    state: {
      type: Object,
      required: true,
    },
    // The default value of the `loadingCount` passed into the slot.
    defaultLoadingCount: {
      type: Number,
      default: 3,
    },
    // Specifies if the loader is inside a table view
    inTableView: {
      type: Boolean,
      default: false,
    },
    // Specifies the side of the content at which items are added by load on scroll.
    // Load-on-scroll works only if `props.state.items.value` is an array.
    // The value "none" disables load on scroll.
    loadOnScroll: {
      type: (type) =>
        type === 'top' ||
        type === 'right' ||
        type === 'bottom' ||
        type === 'left' ||
        type === 'none',
      default: 'bottom',
    },
    // The min value of the `count` prop used for load-on-scroll.
    loadMoreMin: {
      type: Number,
      default: 15,
    },
    // The `count` prop increment used for load-on-scroll.
    loadMoreStep: {
      type: Number,
      default: 5,
    },
  },

  emits: {
    // Requests an update to the `count` prop.
    'update:count': (count) => Number.isInteger(count),
  },

  components: {
    BlankImage,
    BlankSlate,
    ErrorImage,
    ListLoading,
    LoadOnScroll,
    TableViewBlock,
  },

  setup(props, { emit }) {
    const item = computed(() => unref(props.state.item));
    const items = computed(() => unref(props.state.items));
    const totalCount = computed(() => unref(props.state.totalCount));
    const inSync = computed(() => unref(props.state.inSync));
    const itemInSync = computed(() => unref(props.state.itemInSync));
    const loading = computed(() => unref(props.state.loading));
    const meta = computed(() => unref(props.state.meta));
    const response = computed(() => unref(props.state.response));
    const error = computed(() => unref(props.state.error));

    const uiState = computed(() => {
      if (items.value ? items.value.length > 0 : item.value != null) {
        return 'data';
      }
      if (items.value ? totalCount.value === 0 : item.value === null) {
        return 'blank';
      }
      if (
        error.value &&
        (!error.value.config || error.value.config.cache !== 'only-if-cached')
      ) {
        return 'error';
      }
      return 'loading';
    });

    const hasMore = computed(() =>
      items.value
        ? totalCount.value === undefined ||
          totalCount.value > items.value.length
        : false,
    );

    const loadingCount = computed(() => {
      if (!items.value || !Number.isInteger(props.count)) {
        return undefined;
      }
      const validCount = Math.max(0, props.count);
      const validTotalCount = Number.isInteger(totalCount.value)
        ? totalCount.value
        : items.value.length + props.defaultLoadingCount;
      const targetCount = Math.min(validCount, validTotalCount);
      return Math.max(0, targetCount - items.value.length);
    });

    const canLoadOnScroll = computed(
      () => props.loadOnScroll !== 'none' && Boolean(items.value),
    );

    function loadMore() {
      if (canLoadOnScroll.value) {
        emit(
          'update:count',
          props.count <= 0
            ? props.loadMoreMin
            : props.count + props.loadMoreStep,
        );
      }
    }

    watch(totalCount, () => {
      if (
        canLoadOnScroll.value &&
        totalCount.value == null &&
        props.count > props.loadMoreMin
      ) {
        emit('update:count', props.loadMoreMin);
      }
    });

    const isEndVisible = shallowRef(false);

    function onVisibilityChange(isVisible) {
      isEndVisible.value = isVisible;
    }

    return {
      item,
      items,
      totalCount,
      inSync,
      itemInSync,
      loading,
      meta,
      response,
      error,
      uiState,
      hasMore,
      loadingCount,
      isEndVisible,
      onVisibilityChange,
      canLoadOnScroll,
      loadMore,
    };
  },
});
</script>

<style scoped lang="scss">
.LoaderState {
  display: flex;

  &--loadOnScroll-top {
    flex-direction: column-reverse;
  }
  &--loadOnScroll-right {
    flex-direction: row;
  }
  &--loadOnScroll-bottom {
    flex-direction: column;
  }
  &--loadOnScroll-left {
    flex-direction: row-reverse;
  }
}
</style>
