<template>
  <NavItemsContainer class="flex flex-col bg-[#0b0e1f]">
    <!-- Nav items support their own permissions for rendering -->
    <div
      tabindex="-1"
      class="sticky top-0 z-10 bg-[#0b0e1f] pt-[2px] outline-none"
    >
      <QuickAddNavItem />
      <SearchNavItem />
      <WelcomeNavItem />
    </div>
    <JumpToNavItem />
    <MyWorkNavItem />
    <ProjectsNavItem />
    <TimeNavItem />
    <EverythingNavItem />
    <PlanningNavItem />
    <ReportsNavItem />
    <CalendarNavItem />
    <PeopleNavItem />
    <MoreNavItem />
  </NavItemsContainer>
</template>

<script>
export default {
  components: {
    NavItemsContainer: () => import('./nav-items/NavItemsContainer.vue'),
    QuickAddNavItem: () => import('./nav-items/QuickAddPopoverNavItem.vue'),
    WelcomeNavItem: () => import('./nav-items/WelcomeNavItem.vue'),
    SearchNavItem: () => import('./nav-items/SearchNavItem.vue'),
    JumpToNavItem: () => import('./nav-items/JumpToNavItem.vue'),
    MyWorkNavItem: () => import('./nav-items/MyWorkNavItem.vue'),
    ProjectsNavItem: () => import('./nav-items/ProjectsNavItem.vue'),
    TimeNavItem: () => import('./nav-items/TimeNavItem.vue'),
    EverythingNavItem: () => import('./nav-items/EverythingNavItem.vue'),
    PlanningNavItem: () => import('./nav-items/PlanningNavItem.vue'),
    ReportsNavItem: () => import('./nav-items/ReportsNavItem.vue'),
    CalendarNavItem: () => import('./nav-items/CalendarNavItem.vue'),
    PeopleNavItem: () => import('./nav-items/PeopleNavItem.vue'),
    MoreNavItem: () => import('./nav-items/MorePopoverNavItem.vue'),
  },
};
</script>
