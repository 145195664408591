<template>
  <div ref="fileUploadRoot">
    <FileThumbnailGrid
      :is-uploading="isUploading"
      @set-is-uploading="$emit('update:is-uploading')"
      @on-file-change="onFileChange"
      @delete-file="deleteFile"
      class="relative"
      :files="files"
    />
    <FileDragArea @on-drop-files="onDropFiles" />
  </div>
</template>

<script>
import FileThumbnailGrid from './FileThumbnailGrid.vue';
import FileDragArea from './FileDragArea.vue';

export default {
  name: 'FileUpload',
  components: {
    FileThumbnailGrid,
    FileDragArea,
  },
  props: {
    isUploading: { type: Boolean, default: false },
    files: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    function deleteFile(file) {
      this.$emit('delete-file', file);
    }
    function onFileChange(event) {
      if (!event.target.files) {
        return;
      }
      const files = [...event.target.files];
      this.$emit('upload-files', files);
    }
    function onDropFiles(event) {
      if (!event.dataTransfer.files.length) {
        return;
      }
      const files = [...event.dataTransfer.files];
      this.$emit('upload-files', files);
    }
    return {
      deleteFile,
      onFileChange,
      onDropFiles,
    };
  },
};
</script>
