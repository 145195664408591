<template functional>
  <div
    :class="[
      'progressTableCell',
      props.clickable && 'clickable',
      !props.enabled && 'disabled',
    ]"
    @click="props.clickable && listeners['click']($event)"
    @keydown.enter="props.clickable && listeners['click']($event)"
    :style="{ 'background-color': props.completionColor }"
    tabindex="0"
    :aria-label="props.ariaText"
  >
    <div
      class="progress"
      @click.stop="props.clickable && listeners['click']($event)"
      :style="{
        width: `${props.value}%`,
        'background-color': props.color,
      }"
    />
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#d8e0ff',
    },
    completionColor: {
      type: String,
      default: '#f2f4fc',
    },
    value: {
      type: Number,
      default: 0,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: true,
      required: false,
    },
    ariaText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@tko/src/styles/variables/variables';
@import '@/styles/sections/_w-reporting.scss';

.progressTableCell {
  width: 100%;
  height: 70%;
  display: flex;
  position: relative;
  overflow: hidden;
  margin: $padding--default-horizontal;
  border-radius: $border-radius--default;

  &.clickable {
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
  }

  .progress {
    border-radius: $border-radius--default;
    position: absolute;
    left: 0;
    height: 100%;
    z-index: 2;
    animation: slide-in 1000ms cubic-bezier(0.33, 0, 0.18, 0.99);
  }

  .content {
    pointer-events: none;
    z-index: 3;
    display: flex;
    padding: 14px;
    align-items: center;
    width: 100%;
    color: $text-color;
  }
}
@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
</style>
