import Vue from 'vue';
import api, { API_PREFIXES } from '@/services/api';
import { responseErrors, generateMessages } from './errors';

export default {
  confirmCollisionForUser(
    { dispatch, rootState },
    { action, userId, messages, callback },
  ) {
    const { firstName, lastName } = rootState.people.records[userId];

    dispatch(
      'modals/open',
      {
        name: 'allocation-confirm-modal',
        props: {
          title: 'Are you sure?',
          text: `${action} this allocation has conflicts for ${firstName} ${lastName}.`,
          messages,
          callback: (result) => {
            callback(result);
          },
        },
      },
      { root: true, recordMap: false },
    );
  },

  add({ state, commit, dispatch }, itemData) {
    const payload = {
      allocation: {
        ...itemData,
        ignoreCollisions: state.ignoreCollisionsOnce || state.ignoreCollisions,
      },
    };
    const url = `${API_PREFIXES.v3}/allocations.json`;

    return api
      .post(url, payload, { noErrorHandling: true })
      .then((res) => commit('record', res.data.allocation))
      .then(() => {
        dispatch('notifications/flashes/success', Vue.t('Allocation Created'), {
          root: true,
        });
        dispatch('schedule/projects/loadProjectSchedule', itemData.projectId, {
          root: true,
        });
        dispatch(
          'schedule/people/loadPersonSchedule',
          itemData.assignedUserId,
          { root: true },
        );
        dispatch('modals/close', null, { root: true, recordMap: false });
        if (state.ignoreCollisionsOnce) {
          commit('ignoreCollisionsOnce', false);
        }
      })
      .catch((error) => {
        const { errors = [] } = error.response.data;

        if (errors.length) {
          const notificationText = errors.map((err) => err.detail).join(' ');

          if (error.response.status === 503) {
            dispatch(
              'notifications/flashes/error',
              Vue.t('Service Unavailable'),
              { root: true },
            );
          }

          if (notificationText.includes(responseErrors.UNAVAILABLE)) {
            const messages = generateMessages(errors);
            const callback = (result) => {
              if (result.OK) {
                commit('ignoreCollisionsOnce', true);
                dispatch('add', itemData, true);
              }

              if (result.shouldIgnoreFutureCollisions) {
                commit('ignoreCollisions', true);
              }
            };

            dispatch('confirmCollisionForUser', {
              action: 'Adding',
              userId: itemData.assignedUserId,
              messages,
              callback,
            });
          }

          return notificationText;
        }

        return error;
      });
  },

  update({ commit, state, dispatch }, { id, ...updates }) {
    const payload = {
      allocation: {
        ...updates,
        ignoreCollisions: state.ignoreCollisionsOnce || state.ignoreCollisions,
      },
    };
    const url = `${API_PREFIXES.v3}/allocations/${id}.json`;
    const original = state.records[id];

    return api
      .patch(url, payload, { noErrorHandling: true })
      .then(({ data }) => {
        commit('update', { id, updates });

        let projectIdsToUpdate = data.allocation.projectId;
        let peopleIdsToUpdate = data.allocation.assignedUserID;

        if (updates.projectId && updates.projectId !== original.projectId) {
          projectIdsToUpdate += `,${original.projectId}`;
        }

        if (
          (updates.assignedUserId &&
            updates.assignedUserId !== original.assignedUserID) ||
          (updates.assignedUserID &&
            updates.assignedUserID !== original.assignedUserID)
        ) {
          peopleIdsToUpdate += `,${original.assignedUserID}`;
        }

        if (state.ignoreCollisionsOnce) {
          commit('ignoreCollisionsOnce', false);
        }

        dispatch('schedule/projects/loadProjectSchedule', projectIdsToUpdate, {
          root: true,
        });
        dispatch('schedule/people/loadPersonSchedule', peopleIdsToUpdate, {
          root: true,
        });
        dispatch('notifications/flashes/success', Vue.t('Allocation Updated'), {
          root: true,
        });
        dispatch('modals/close', null, { root: true, recordMap: false });
      })
      .catch((error) => {
        const { errors = [] } = error.response.data;

        if (error.response.status === 503) {
          dispatch(
            'notifications/flashes/error',
            Vue.t('Service Unavailable'),
            { root: true },
          );
        }

        const notificationText = errors.map((err) => err.detail).join(' ');

        if (notificationText.includes(responseErrors.UNAVAILABLE)) {
          const { assignedUserID } = state.records[id];
          const messages = generateMessages(errors);
          const callback = (result) => {
            if (result.OK) {
              commit('ignoreCollisionsOnce', true);
              dispatch('update', { id, ...updates }, true);
            } else {
              dispatch('restoreFromState', id);
            }

            if (result.shouldIgnoreFutureCollisions) {
              commit('ignoreCollisions', true);
            }
          };
          dispatch('confirmCollisionForUser', {
            action: 'Updating',
            userId: assignedUserID,
            messages,
            callback,
          });
        } else if (notificationText.includes(responseErrors.NOT_ON_PROJECT)) {
          dispatch(
            'notifications/flashes/error',
            Vue.t('User is not a member of this project'),
            { root: true },
          );
        } else {
          dispatch('restoreFromState', id);
        }

        return error;
      });
  },

  delete({ state, commit, dispatch }, id) {
    const allocation = state.records[id];
    return api
      .delete(`${API_PREFIXES.v3}/allocations/${id}.json`)
      .then(() => {
        dispatch(
          'schedule/projects/loadProjectSchedule',
          allocation.projectId,
          { root: true },
        );
        dispatch(
          'schedule/people/loadPersonSchedule',
          allocation.assignedUserID,
          { root: true },
        );
        commit('delete', id);
        dispatch('notifications/flashes/info', Vue.t('Allocation Deleted'), {
          root: true,
        });
      })
      .catch((error) => {
        if (error.response.status === 503) {
          dispatch(
            'notifications/flashes/error',
            Vue.t('Service Unavailable'),
            { root: true },
          );
        }
      });
  },

  split({ commit, dispatch }, { id, at }) {
    const url = `${API_PREFIXES.v3}/allocations/${id}/split.json`;

    return api
      .put(url, { at })
      .then((res) => {
        res.data.allocations.forEach((alloc) => {
          commit('record', alloc);
        });
      })
      .catch((error) => {
        if (error.response.status === 503) {
          dispatch(
            'notifications/flashes/error',
            Vue.t('Service Unavailable'),
            { root: true },
          );
        }
      });
  },

  // when something is changed in the scheduler, it isn't reflected in state
  // until it's confirmed by the api
  // sometimes we'll want to undo this, and restore from the state
  // this will force the scheduler to revert a change
  restoreFromState({ state, commit }, id) {
    const allocation = state.records[id];
    const values = Object.keys(allocation).map((prop) => ({
      [prop]: allocation[prop],
    }));

    commit('update', { id, values });
  },
};
