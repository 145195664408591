<template>
  <div
    @click.self="quickView"
    :class="[
      'table-cell-task',
      {
        editing: task.id == null || editing,
        'is-new': task.id == null,
      },
    ]"
  >
    <TaskToggleStatus
      v-if="task.id > 0"
      placement="top-start"
      :task="task"
      size="small"
      :data-identifier="
        dataIdentifierPrefix
          ? `${dataIdentifierPrefix}-task-completion-toggle`
          : undefined
      "
      :margin-right="0"
      @open="onDependencyOpen"
    />

    <template v-if="editing || task.id == null">
      <div
        :style="{
          minWidth: task.id == null && `${tableWidth - 45}px`,
          paddingLeft: task.id == null && taskInputPadding,
        }"
        class="table-cell-task__form-container"
      >
        <input
          type="text"
          ref="taskName"
          :placeholder="
            task.parentTaskId ? $t('Add a subtask') : $t('Add a task')
          "
          :value="task.name"
          @keydown.stop="handleKeyDown"
          @blur="onBlur"
          v-click-outside="cancel"
          tabindex="0"
        />
      </div>
    </template>

    <template v-else>
      <div class="table-cell-task-name" @click="quickView">
        <a
          class="table-cell-task-name-link"
          :title="task.name"
          :href="`#/tasks/${task.id}`"
        >
          <span
            :class="{
              'is-completed': task.status === 'completed',
            }"
          >
            {{ task.name }}
          </span>
        </a>
      </div>

      <EditTaskName
        @click="edit"
        v-if="
          task.status !== 'completed' && canEdit && projectStatus !== 'inactive'
        "
      />
      <div
        v-if="task.lockdown && task.lockdown.id && task.id != null"
        class="table-cell-task-lockdown"
      >
        <Privacy :args="privacyArgs" :show-label="false" />
      </div>
      <Comments :item="task" type="task" v-if="numComments > 0" />
      <Attachments v-if="numAttachments" :task="task" />
      <div
        v-if="subtasksVisible && numActiveSubTasks"
        v-tooltip="{
          placement: 'top',
          content: subtaskOpen ? $t(`Hide Subtasks`) : $t(`View Subtasks`),
          boundariesElement: 'document.body',
        }"
      >
        <Subtasks
          :subtasks="numActiveSubTasks"
          @subtaskOpenChange="subtaskOpenChange"
        />
      </div>
      <SubtaskPopover v-if="showParent" :task="task" />
      <QuickView :task-id="task.id" @click="quickView" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Privacy from '@widgets/TableCellWidgets/Privacy/Privacy';
import { computed } from 'vue-demi';
import { useTableViewInternal } from '@/platform/components/table-view/useTableViewInternal';
import { useFeatures } from '@/platform/composables/useFeatures';
import TaskToggleStatus from '@/components/widgets/TaskToggleStatus';
import { useQuickViews } from '@/platform/composables/useQuickViews';
import Comments from './Comments/Comments';
import Attachments from './Attachments/Attachments';
import QuickView from './QuickView/QuickView';
import Subtasks from './Subtasks/Subtasks';
import SubtaskPopover from './SubtaskPopover/SubtaskPopover';
import EditTaskName from './EditTaskName/EditTaskName';
import useCurrentRoute from '@/platform/composables/useCurrentRoute';
import { useAppShell } from '@/platform/composables/useAppShell';
import useStore from '@/platform/composables/useStore';
import { postMessageToLightspeed } from '@/scaffolding/tko-lightspeed';

// @vue/component
export default {
  name: 'TaskName',
  display: 'TaskName',
  props: {
    task: { type: Object, required: true },
    showParent: { type: Boolean, default: true },
    subtaskOpen: { type: Boolean, default: false },
    subtasksVisible: { type: Boolean, default: true },
    dataIdentifierPrefix: { type: String, default: null },
  },
  components: {
    Comments,
    Attachments,
    QuickView,
    Subtasks,
    SubtaskPopover,
    TaskToggleStatus,
    Privacy,
    EditTaskName,
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    privacyArgs() {
      return {
        lockdownId: this.task.lockdown.id,
        privacyIsInherited:
          this.task.privacyIsInherited || this.task.isPrivate === 2,
        private: this.task.isPrivate,
        name: this.task.name,
        itemId: this.task.id,
        projectId: this.task.projectId,
        type: 'task',
      };
    },

    numActiveSubTasks() {
      return this.task.subTaskIds ? this.task.subTaskIds.length : 0;
    },
    numAttachments() {
      return this.task.attachments ? this.task.attachments.length : 0;
    },
    numComments() {
      let count = 0;

      if (this.task.comments) {
        count = this.task.comments.length;
      }

      if (this.task.meta?.commentStats?.total) {
        count = this.task.meta?.commentStats?.total;
      }
      return count;
    },
    ...mapState({
      installationId: ({ account }) => account.id,
    }),
    canEdit() {
      if (this.task.userPermissions) {
        return this.task.userPermissions.canEdit;
      }
      return false;
    },
    projectStatus() {
      if (this.task.project) {
        return this.task.project.status;
      }
      return '';
    },
    taskInputPadding() {
      if (this.task.parentTaskId > 0 && this.task.level >= 0) {
        return `${20 + 20 * (this.task.level + 1)}px`;
      }
      return '19px';
    },
  },
  methods: {
    edit() {
      if (
        this.task.status === 'completed' ||
        !this.task.userPermissions.canEdit
      ) {
        return;
      }
      this.editing = true;
      this.focusNameInput();
    },
    save() {
      const name = this.$refs.taskName.value.trim();
      if (name && name !== this.task.name) {
        this.$emit('save', name);
      }
    },
    cancel() {
      this.$refs.taskName.value = '';
      this.editing = false;
      this.$emit('cancel');
    },
    onBlur() {
      this.save();
      if (this.task.id) {
        this.cancel();
      }
    },
    focusNameInput() {
      this.$nextTick(() => {
        if (this.$refs.taskName) {
          this.$refs.taskName.focus();
        }
      });
    },
    handleKeyDown(event) {
      if (event.keyCode === 13) {
        this.save();
        if (this.task.id == null) {
          this.$refs.taskName.value = '';
        } else {
          this.cancel();
        }
      } else if (event.keyCode === 27) {
        this.cancel();
      }
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const isProjectPage = computed(
      () => store.state.routes.tkoCurrentRoute.projectId != null,
    );
    const isTemplate = computed(
      () => store.getters['project/current/isTemplate'],
    );

    const { tableWidth } = useTableViewInternal();
    const currentRoute = useCurrentRoute();

    const { replaceQuickView } = useQuickViews();

    const { projectsLightspeedTaskDetails } = useFeatures();
    const { shouldUseAppShell } = useAppShell();

    const isMyWorkPage = computed(() =>
      currentRoute.value.path.includes('home/work'),
    );

    const subtaskOpenChange = () => {
      emit('subtaskOpenChange');
    };

    const shouldShowNewTaskDetails = computed(
      () =>
        (isProjectPage.value || isMyWorkPage.value) &&
        !isTemplate.value &&
        shouldUseAppShell.value,
    );

    return {
      tableWidth,
      subtaskOpenChange,
      quickView(e) {
        if (!this.task) {
          return;
        }
        if (!e.metaKey) {
          if (projectsLightspeedTaskDetails.value) {
            postMessageToLightspeed('twa:task-details', {
              taskId: Number(this.task.id),
            });
          } else if (shouldShowNewTaskDetails.value) {
            replaceQuickView({
              type: 'task',
              id: this.task.id,
              initialItem: this.task,
              props: {
                dataIdentifierPrefix: props.dataIdentifierPrefix,
              },
            });
          } else {
            this.$store.dispatch('quickViews/tko/open', {
              id: 'task',
              record: this.task.id,
              args: {
                projectId: this.task.projectId,
                taskId: this.task.id,
                id: this.task.id,
              },
            });
          }
          e.preventDefault();
        }
      },
      onDependencyOpen(taskId) {
        if (projectsLightspeedTaskDetails.value) {
          postMessageToLightspeed('twa:task-details', {
            taskId: Number(taskId),
          });
        } else {
          replaceQuickView({
            type: 'task',
            id: Number(taskId),
            initialItem: this.task,
          });
        }
      },
    };
  },

  mounted() {
    this.focusNameInput();
  },
};
</script>

<style src="./TaskName.scss" lang="scss"></style>
