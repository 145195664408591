import { computed } from 'vue-demi';
import {
  avatarSizes,
  defaultAvatarSize,
  avatarVariants,
  defaultAvatarVariant,
} from './consts.js';
import optionPropValidator from '../../../helpers/optionPropValidator';

export const avatarProps = {
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: defaultAvatarSize,
    validator: optionPropValidator(avatarSizes, 'size'),
  },
  variant: {
    type: String,
    default: defaultAvatarVariant,
    validator: optionPropValidator(avatarVariants, 'variant'),
  },
  background: {
    type: String,
    default: () => null,
  },
  url: {
    type: String,
    default: () => null,
  },
  hasTooltip: {
    type: Boolean,
    default: true,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
  stacked: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
};

export const useCommonAvatar = (props, isIcon) => {
  const stringToHslColor = (str, s, l) => {
    if (!str) return 'hsl(0, 0%, 0%)';
    const name = str.toLowerCase().replace(/ /g, '');
    let hash = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < name.length; i++) {
      const char = name.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = char + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return `hsl(${Math.abs(h)}, ${s}%, ${l}%)`;
  };

  const initials = computed(() => {
    const name = props.name.split(' ');
    let nameInitials = '';
    if (name.length > 1) {
      nameInitials = name.shift().charAt(0) + name.pop().charAt(0);
    } else {
      nameInitials = props.name.substring(0, 2);
    }

    return nameInitials.toUpperCase();
  });

  const avatarStyles = computed(() => {
    if (props.background) {
      return {
        backgroundColor: props.background,
      };
    }

    return {
      backgroundColor: isIcon
        ? 'var(--token-c-avatar-color-background)'
        : stringToHslColor(props.name, 88, 80),
    };
  });

  const avatarClasses = computed(() => {
    const classes = [
      'avatar',
      `variant-${props.variant}`,
      `size-${props.size}`,
      'inline-flex',
      'items-center',
      'justify-center',
      'text-white',
      'outline-none',
      'rounded-full',
      'relative',
      'shrink-0',
      'fill-current',
      {
        // text sizes
        'text-xs': props.size === avatarSizes.SMALL,
        'text-default': props.size === avatarSizes.MEDIUM,
        'text-base':
          props.size !== avatarSizes.SMALL && props.size !== avatarSizes.MEDIUM,
        'leading-6':
          props.size === avatarSizes.SMALL || props.size === avatarSizes.MEDIUM,
        'leading-7':
          props.size !== avatarSizes.SMALL && props.size !== avatarSizes.MEDIUM,

        // size
        'h-[var(--token-c-avatar-size-small)]':
          props.size === avatarSizes.SMALL,
        'w-[var(--token-c-avatar-size-small)]':
          props.size === avatarSizes.SMALL,
        'h-[var(--token-c-avatar-size-medium)]':
          props.size === avatarSizes.MEDIUM,
        'w-[var(--token-c-avatar-size-medium)]':
          props.size === avatarSizes.MEDIUM,
        'h-[var(--token-c-avatar-size-large)]':
          props.size === avatarSizes.LARGE,
        'w-[var(--token-c-avatar-size-large)]':
          props.size === avatarSizes.LARGE,
        'h-[var(--token-c-avatar-size-x-large)]':
          props.size === avatarSizes.EXTRA_LARGE,
        'w-[var(--token-c-avatar-size-x-large)]':
          props.size === avatarSizes.EXTRA_LARGE,
        'h-[var(--token-c-avatar-size-xx-large)]':
          props.size === avatarSizes.EXTRA_EXTRA_LARGE,
        'w-[var(--token-c-avatar-size-xx-large)]':
          props.size === avatarSizes.EXTRA_EXTRA_LARGE,

        // colors
        'text-[color:var(--token-c-avatar-color-icon)]': isIcon,
        'text-[color:var(--token-c-avatar-color-initials)]': !isIcon,

        // stacked
        'ml-[var(--token-c-avatar-size-stack-small)]':
          props.stacked && props.size === avatarSizes.SMALL,
        'ml-[var(--token-c-avatar-size-stack-medium)]':
          props.stacked && props.size === avatarSizes.MEDIUM,
        'ml-[var(--token-c-avatar-size-stack-large)]':
          props.stacked && props.size === avatarSizes.LARGE,
        'ml-[var(--token-c-avatar-size-stack-x-large)]':
          props.stacked && props.size === avatarSizes.EXTRA_LARGE,
        'ml-[var(--token-c-avatar-size-stack-xx-large)]':
          props.stacked && props.size === avatarSizes.EXTRA_EXTRA_LARGE,
        'border border-solid border-[color:var(--token-c-avatar-color-border)]':
          props.bordered,

        // interraction
        'cursor-pointer': props.clickable,
      },
    ];

    return classes;
  });

  const tooltipClasses = computed(() => {
    const classes = [
      'avatar-tooltip absolute text-white p-2 rounded text-xs text-center bg-black w-max max-w-[var(--token-c-tooltips-size-max-width)] text-ellipsis overflow-hidden whitespace-nowrap',
    ];
    return classes;
  });

  const badgeClasses = computed(() => {
    const classes = [
      'absolute',
      'flex justify-center items-center',
      'rounded-full',
      'bg-white',
      'text-[color:var(--token-c-avatar-color-initials)]',
      'fill-current',
      {
        'p-0.5': props.size === avatarSizes.SMALL,
        'p-[var(--token-c-avatar-size-badge-padding)]':
          props.size !== avatarSizes.SMALL,

        'h-[var(--token-c-avatar-size-badge-small)] w-[var(--token-c-avatar-size-badge-small)]':
          props.size === avatarSizes.SMALL,
        '-bottom-[var(--token-c-avatar-size-badge-small)/5] -right-[var(--token-c-avatar-size-badge-small)/5]':
          props.size === avatarSizes.SMALL,
        'h-[var(--token-c-avatar-size-badge-medium)] w-[var(--token-c-avatar-size-badge-medium)]':
          props.size === avatarSizes.MEDIUM,
        '-bottom-[var(--token-c-avatar-size-badge-medium)/5] -right-[var(--token-c-avatar-size-badge-medium)/5]':
          props.size === avatarSizes.MEDIUM,
        'h-[var(--token-c-avatar-size-badge-large)] w-[var(--token-c-avatar-size-badge-large)]':
          props.size === avatarSizes.LARGE,
        '-bottom-[var(--token-c-avatar-size-badge-large)/5] -right-[var(--token-c-avatar-size-badge-large)/5]':
          props.size === avatarSizes.LARGE,
        'h-[var(--token-c-avatar-size-badge-x-large)] w-[var(--token-c-avatar-size-badge-x-large)]':
          props.size === avatarSizes.EXTRA_LARGE,
        '-bottom-[var(--token-c-avatar-size-badge-x-large)/5] -right-[var(--token-c-avatar-size-badge-x-large)/5]':
          props.size === avatarSizes.EXTRA_LARGE,
        'h-[var(--token-c-avatar-size-badge-xx-large)] w-[var(--token-c-avatar-size-badge-xx-large)]':
          props.size === avatarSizes.EXTRA_EXTRA_LARGE,
        '-bottom-[var(--token-c-avatar-size-badge-xx-large)/5] -right-[var(--token-c-avatar-size-badge-xx-large)/5]':
          props.size === avatarSizes.EXTRA_EXTRA_LARGE,
      },
    ];
    return classes;
  });

  return {
    initials,
    avatarClasses,
    tooltipClasses,
    badgeClasses,
    avatarStyles,
  };
};
